import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import Reducer from "./reducers";
import { isArray } from "./utils/GeneralUtils";

const appReducer = combineReducers({
  ...Reducer,
});
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    if (isArray(state.common.chatList)) {
      state.common.chatList.map(x => {
        x.subscription.unsubscribe();
        x.socketClient.disconnect();
      });
    }
    state = undefined;
  }
  return appReducer(state, action);
};

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
// export default createStore(rootReducer, composeWithDevTools(applyMiddleware()));
