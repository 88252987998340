import React from "react";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Collapse, Badge } from "reactstrap";
import { Input, TextArea, ScrollBox, Button, Select, CopyToClipboard } from "../../../common/components";
import { isArray } from "../../../utils/GeneralUtils";
import CommonUtils from "../../../utils/CommonUtils";
import classnames from "classnames";
import {
  SelectElement,
  SelectAttribute,
  ElementForm,
  SubmitElement,
  SelectIntent,
  ActionSetUserData,
  ActionDecision,
} from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { get } from "lodash";
import { getBotElementData, getValidateJs } from "../../../utils/BotUtils";
import { connect } from "react-redux";
import Workbench from "./components/Workbench.js";

class JavaScript extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptVerified: false,
      scriptTextTarget: 0,
      value: "",
      scriptText: "",
      activeTab: "input",
      step: 1,
      jsonResult: "",
      outputResult: "",
      jsonStructure: [],
      prevElementStructure: [],
      nextIntent: "",
      intent: null,
      entityEntries: [],
      entity: null,
      fetchEntityList: true,
      entityList: [],
      errors: {
        scriptText: null,
      },
      actionAttr: null,
      actionDecision: null,
      isAdvSettings: false,
      environmentList: [{ key: "", value: "" }],
      isTestloading: false,
      showWorkBench: false,
    };
    this.verificationKey = null;
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.verifyScript = this.verifyScript.bind(this);
    this.onChangeEntity = this.onChangeEntity.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.setIsAdvSettings = this.setIsAdvSettings.bind(this);
    this.updateSchemaJson = this.updateSchemaJson.bind(this);
    this.closeWorkBench = this.closeWorkBench.bind(this);
  }

  componentDidMount() {
    this.getEntityList();
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { scriptText, nextIntent, intent, jsonStructure, entity, actionAttr, actionDecision, environmentList } =
        box.payload;
      const isEnvListAvailable = environmentList
        ? environmentList.filter(x => x.key !== "" && x.value !== "").length
        : false;
      this.setState(
        {
          scriptText,
          nextIntent,
          intent,
          jsonStructure,
          entity,
          actionAttr,
          actionDecision,
          isAdvSettings: Boolean(isEnvListAvailable),
          environmentList: isArray(environmentList) ? environmentList : [{ key: "", value: "" }],
        },
        () => this.verifyScript(true)
      );
    }
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  getEntityList() {
    this.setState({ fetchEntityList: true });
    CommonUtils.getEntityByMerchantId().then(response => {
      if (response.error) {
        this.setState({ entityList: [], fetchEntityList: false });
        return false;
      }
      if (response && response.entities) {
        const list = response.entities.map(e => {
          return {
            entityId: e.entityId,
            entries: e.entries,
            name: e.name,
          };
        });
        let { entity } = this.state;
        if (entity) {
          this.onChangeEntity(
            entity,
            list.find(x => x.entityId === entity)
          );
        }
        this.setState({ entityList: list, fetchEntityList: false }, () => this.getPrevElementData());
      }
    });
  }

  verifyScript(isVerified = false) {
    const { scriptText, prevElement } = this.state;
    let scriptVerified = isVerified;

    const verificationKey = JSON.stringify({
      scriptText,
      prevElement,
    });

    if (scriptText && this.verificationKey === verificationKey) {
      scriptVerified = true;
    } else if (scriptVerified) {
      this.verificationKey = verificationKey;
    }

    this.setState({ scriptVerified });
  }

  closeWorkBench() {
    this.setState({ showWorkBench: false });
  }

  updateSchemaJson(schema) {
    this.setState(
      {
        scriptText: schema,
      },
      () => {
        this.closeWorkBench();
        this.handleVerify();
      }
    );
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }
    if (step === 3 && this.actionSetUserDataRef) {
      const obj = this.actionSetUserDataRef.getData();
      if (!obj) return false;
      stateObj.actionAttr = obj.skip ? null : obj.data;
      if (!obj.skip && obj.attrStructure) {
        stateObj.prevElementStructure = [...this.state.prevElementStructure, ...obj.attrStructure];
      }
    }
    if (step === 4 && this.actionDecisionRef) {
      const obj = this.actionDecisionRef.getData();
      if (!obj) return false;
      stateObj.actionDecision = obj.skip ? null : obj;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      scriptText,
      prevElement,
      nextIntent,
      intent,
      jsonStructure,
      entity,
      actionAttr,
      actionDecision,
      environmentList,
    } = this.state;
    const { handleSubmitElement } = this.props;

    let data = {
      input_type: "javaScript",
      next_intent: nextIntent,
      request_params: [
        {
          scriptText,
          entity,
        },
      ],
    };

    if (isArray(actionAttr) || actionDecision) {
      data.customAction = {};

      if (isArray(actionAttr)) {
        data.customAction.attributes = actionAttr;
      }
      if (actionDecision) {
        data.customAction.decision = actionDecision;
      }
    }

    const aqr = get(data, `customAction.decision.qr`, []);

    const payload = {
      scriptText,
      nextIntent,
      intent,
      jsonStructure,
      entity,
      qr: [...aqr],
      actionAttr,
      actionDecision,
      environmentList: environmentList.filter(x => x.key && x.value),
    };
    handleSubmitElement(data, prevElement, payload);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  async handleVerify() {
    this.setState({ isTestloading: true });
    const { scriptText, jsonResult, entity, entityList, environmentList } = this.state;
    const customConfigData = this.props.merchantAttr.data.customConfig;

    const res = await getValidateJs(scriptText, jsonResult, entity, environmentList, customConfigData);
    this.verifyScript(res.jsonResult);
    this.toggle("output");
    this.setState({ jsonStructure: res.jsonStructure, outputResult: res.jsonResult, isTestloading: false });
  }

  onChangePrevElement(name, value) {
    this.setState({ [name]: value }, () => {
      this.verifyScript();
      this.getPrevElementData();
    });
  }

  setIsAdvSettings() {
    this.setState({ isAdvSettings: !this.state.isAdvSettings });
  }

  async getPrevElementData() {
    if (this.state.fetchEntityList) return;
    const customConfigData = this.props.merchantAttr.data.customConfig;
    const res = await getBotElementData(
      this.state.prevElement,
      this.props.boxes,
      this.props.connectors,
      this.state.entityList,
      customConfigData
    );
    const stateObj = { jsonResult: res.jsonResult };
    if (isArray(res.jsonStructure)) {
      stateObj.prevElementStructure = [...this.state.prevElementStructure, ...res.jsonStructure];
    }
    this.setState(stateObj, () => this.toggle("input"));
  }

  onChangeEntity(value, obj) {
    const stateObj = {
      entity: value,
      entityEntries: [],
    };
    if (obj && isArray(obj.entries)) {
      stateObj.entityEntries = obj.entries;
    }
    this.setState(stateObj, this.toggle("input"));
  }

  onChangeFeature(name, value, index, listName) {
    let list = this.state[listName];
    list[index][name] = value;
    this.setState({ [listName]: list });
  }

  addFeature(listName) {
    let list = this.state[listName];
    list.push({
      key: "",
      value: "",
    });
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  removeFeature(listName, index) {
    let list = this.state[listName];
    list.splice(index, 1);
    if (listName == "paramsList") {
      this.updateUrl(list);
    }
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  renderStep1() {
    const {
      scriptText,
      prevElement,
      scriptTextTarget,
      entityList,
      entity,
      errors,
      fetchEntityList,
      isAdvSettings,
      environmentList,
      isTestloading,
    } = this.state;
    const { boxes, botElementId, connectors, attributeList, isSmallMenu } = this.props;
    return (
      <div className="ele-content">
        <label>
          Write your JavaScript function where the result of the previous element will be obtained in the `data`
          variable of the function. Use curly barces to refer to user inputs or parameters. For example -{" "}
          {"{{ First Name }}"}
        </label>
        <div className="b-form mt10">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              outerWidth={!isSmallMenu ? 0 : undefined}
              outerClassName={!isSmallMenu ? "" : undefined}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Entity">
            <Select
              options={entityList}
              name="entity"
              value={entity}
              labelKey="name"
              valueKey="entityId"
              outerWidth={300}
              outerClassName="mb0"
              isLoading={fetchEntityList}
              onChangeFunc={(name, value, error, obj) => this.onChangeEntity(value, obj)}
            />
          </ElementForm>
          <ElementForm rowClassName="js-function" title="JavaScript">
            <label>
              {`function myFunction(data${entity ? ", entity" : ""})`}
              {" {"}
            </label>
            <div className="position-relative">
              <TextArea
                value={scriptText}
                rows={6}
                title="JavaScript Code"
                placeholder="Write your javascript code..."
                name="scriptText"
                isReq={true}
                error={errors.scriptText}
                appendIcon={
                  <SelectAttribute
                    outerAttriClass="text-area-icon"
                    attributeList={attributeList}
                    onChangeFunc={(value, inputName) =>
                      this.setState({ scriptText: value }, () => {
                        this.verifyScript(), this.onInputValidate(inputName, null);
                      })
                    }
                    setAttribute={[scriptTextTarget, scriptText]}
                  />
                }
                validationFunc={this.onInputValidate}
                onChangeFunc={(name, value, e) =>
                  this.setState({ [name]: value, scriptTextTarget: e.target }, () => this.verifyScript())
                }
              />
            </div>
            <label>{"}"}</label>
          </ElementForm>
          <ElementForm title="">
            <Button submitBtn loading={isTestloading} text="Test" onClickFunc={this.handleVerify} />
            <Button
              submitBtn
              className="ml10"
              text="Workbench"
              displayIcon={false}
              onClickFunc={() => this.setState({ showWorkBench: true })}
            />
          </ElementForm>
          <div className="env-title" onClick={this.setIsAdvSettings}>
            <label className="name mt10 cursor-pointer" style={{ lineHeight: "33px" }}>
              Environments{" "}
              <Badge pill color="orange">
                {environmentList.filter(x => x.key && x.value).length}
              </Badge>
            </label>
            <i className={`fa cursor-pointer ${isAdvSettings ? "fa-minus" : "fa-plus"}`} />
          </div>
          <div id="collapse">
            <Collapse isOpen={isAdvSettings}>
              {environmentList.map((obj, index) => {
                return (
                  <div key={index} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "environmentList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control pr35"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "environmentList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === environmentList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("environmentList")} />
                            ) : null}
                            {index !== 0 || environmentList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("environmentList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  renderStepSetAttr() {
    return (
      <ActionSetUserData
        {...this.props}
        value={this.state.actionAttr}
        attributeList={this.state.jsonStructure}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionSetUserDataRef = e)}
      />
    );
  }

  renderStepDecision() {
    return (
      <ActionDecision
        {...this.props}
        value={this.state.actionDecision}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionDecisionRef = e)}
      />
    );
  }

  render() {
    const {
      scriptText,
      scriptVerified,
      jsonResult,
      outputResult,
      activeTab,
      step,
      showWorkBench,
      environmentList,
      entity,
    } = this.state;
    const { resetElement, botElementId } = this.props;
    const customConfigData = this.props.merchantAttr.data.customConfig;
    const workbenchProps = {
      closeWorkBench: this.closeWorkBench,
      updateSchemaJson: this.updateSchemaJson,
      type: "javascript",
      code: jsonResult,
      schema: scriptText,
      environmentList,
      customConfigData,
      entity,
    };

    return (
      <React.Fragment>
        {scriptText &&
          (scriptVerified ? (
            <span className="verify-lbl text-success">
              <i className="fa fa-check-circle"></i>Verified
            </span>
          ) : (
            <span className="verify-lbl text-danger">
              <i className="fa fa-exclamation-circle"></i>Not Verified
            </span>
          ))}
        <ScrollBox scrollClass="bt-javascript" boxHeight={400}>
          {step === 1 && (
            <Col>
              <Row>
                <Col md="8">{this.renderStep1()}</Col>
                <Col md="4">
                  <div className="display-head">
                    <Nav className="nav-tab">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "input" })}
                          onClick={() => {
                            this.toggle("input");
                          }}
                        >
                          Input
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "output" })}
                          onClick={() => {
                            this.toggle("output");
                          }}
                        >
                          Output
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="input">
                        <TextArea
                          rows={14}
                          value={typeof jsonResult === "string" ? jsonResult : JSON.stringify(jsonResult, null, 2)}
                        />
                        <CopyToClipboard
                          clsName="float-right pr-0"
                          value={typeof jsonResult === "string" ? jsonResult : JSON.stringify(jsonResult, null, 2)}
                        />
                      </TabPane>
                      <TabPane tabId="output">
                        <TextArea
                          rows={14}
                          value={
                            typeof outputResult === "string" ? outputResult : JSON.stringify(outputResult, null, 2)
                          }
                        />
                        <CopyToClipboard
                          clsName="float-right pr-0"
                          value={
                            typeof outputResult === "string" ? outputResult : JSON.stringify(outputResult, null, 2)
                          }
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          {step === 2 && this.renderStepSetAttr()}
          {step === 3 && this.renderStepDecision()}
          {step === 4 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          step={step}
          totalSteps={4}
          resetElement={resetElement}
          botElementId={botElementId}
          handleSubmit={this.handleSubmit}
          handleStep={this.handleStep}
        />
        {showWorkBench && <Workbench {...workbenchProps} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    merchantAttr: state.common.merchantAttr,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(JavaScript);
