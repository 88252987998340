import React from "react";
import { Button, TextArea, TextEditor, Input, ScrollBox, Select } from "../../../common/components";
import { Row, Col, Label, Input as InputStrap } from "reactstrap";
import { getRandomNumber, isArray, addErrorObj } from "../../../utils/GeneralUtils";
import {
  getAttrByPrevElement,
  getBotIntent,
  loadBotPayload,
  loadDefaultBotPayload,
  updateBotPayload,
} from "../../../utils/BotUtils";
import {
  SelectElement,
  SelectIntent,
  SelectAttribute,
  ElementForm,
  SubmitElement,
  AdvanceOptions,
  AdvanceOptionsExpand,
} from "./components";
import { cloneDeep } from "lodash";
import CommonUtils from "../../../utils/CommonUtils";
import { getFormDetails } from "../../../common/components/helper.js";
import { keys } from "lodash";

const initOptions = (id = getRandomNumber(6)) => ({
  id,
  text: "",
  value: "",
  errors: {
    text: null,
    value: null,
  },
});

const LANG_STRUCTURE = {
  messageText: "",
  optionList: [],
  dynamicOptionList: [],
};

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPos: 0,
      messageText: "",
      triggerPath: true,
      isRich: false,
      setAttr: false,
      waTemplate: false,
      legacy_message: false,
      activity: false,
      attributeName: "",
      optionKey: Math.random(),
      optionList: [cloneDeep(initOptions())],
      dynamicOptionList: [cloneDeep(initOptions())],
      intent: null,
      step: 1,
      source: "static",
      isDynamic: false,
      prevElementStructure: [],
      externalAttributes: [],
      entity: null,
      entityList: [],
      nextIntent: "",
      errors: {
        messageText: null,
        attributeName: null,
      },
      langPayload: loadDefaultBotPayload(LANG_STRUCTURE, props.lang),
    };

    this.sourceList = [
      { label: "Static List", value: "static" },
      { label: "Dynamic List", value: "dynamic" },
    ];

    this.getValidation = this.getValidation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onChangeEntity = this.onChangeEntity.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.onListValidate = this.onListValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onAdvanceOptionChange = this.onAdvanceOptionChange.bind(this);
  }

  componentDidMount() {
    this.getEntityList();

    const { boxes, botElementId, lang } = this.props;
    const box = boxes.find(b => b.id === botElementId);

    if (box) {
      const {
        messageText,
        attributeName,
        intent,
        source,
        isDynamic,
        isRich,
        entity,
        nextIntent,
        options,
        waTemplate,
        legacy_message,
        activity,
      } = box.payload;
      const stateObj = {
        messageText,
        attributeName,
        intent,
        source,
        isDynamic,
        isRich,
        entity,
        nextIntent,
        waTemplate,
        legacy_message,
        activity,
      };
      stateObj[isDynamic ? "dynamicOptionList" : "optionList"] = options;
      this.setState(stateObj, () =>
        this.setState({ ...loadBotPayload(LANG_STRUCTURE, box.payload, this.state, lang) })
      );
    }
  }

  updatePayload(prevLang = this.props.lang, callback = () => {}) {
    const props = {
      boxes: this.props.boxes,
      botVersion: this.props.botVersion,
      isInteractive: this.state.waTemplate,
    };
    const langPayload = updateBotPayload(LANG_STRUCTURE, this.state, prevLang, props);
    this.setState(
      {
        langPayload,
        ...langPayload[this.props.lang.selectedLang],
      },
      callback
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang.selectedLang !== prevProps.lang.selectedLang) {
      this.updatePayload(prevProps.lang);
    }
  }

  getEntityList() {
    CommonUtils.getEntityByMerchantId().then(response => {
      if (response.error) {
        this.setState({ entityList: [] });
        return false;
      }
      if (response && response.entities) {
        const list = response.entities.map(e => {
          return {
            entityId: e.entityId,
            entries: e.entries,
            name: e.name,
          };
        });
        let { entity } = this.state;
        if (entity) {
          this.onChangeEntity(
            entity,
            list.find(x => x.entityId === entity)
          );
        }
        this.setState({ entityList: list });
      }
    });
  }

  onChangeEntity(value, obj) {
    const stateObj = {
      entity: value,
      externalAttributes: [],
      entityEntries: [],
    };
    if (obj && isArray(obj.entries)) {
      stateObj.externalAttributes = Object.keys(obj.entries[0]).map(x => ({
        label: `entity.${x}`,
        value: `{{entity_${value}.${x}}}`,
      }));
      stateObj.entityEntries = obj.entries;
    }
    this.setState(stateObj);
  }

  getValidation(e) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  onAdvanceOptionChange(name, value, err, obj, e) {
    this.setState({ [name]: e.target.checked });
  }

  onListValidate(name, error, index) {
    const { isDynamic, dynamicOptionList, optionList } = this.state;
    const optList = isDynamic ? dynamicOptionList : optionList;
    optList[index]["errors"][name] = error;
    if (isDynamic) {
      this.setState({ dynamicOptionList: optList });
    } else this.setState({ optionList: optList });
  }

  handleStep(step, isSubmit = false, prevLang) {
    const { isDynamic, dynamicOptionList, optionList, messageText, errors } = this.state;
    const obj = getFormDetails(
      {
        messageText,
        errors: {
          messageText: errors.messageText,
        },
      },
      this.onInputValidate
    );
    if (!obj) return false;

    if (step - 1 === 2) {
      let isError = false;

      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) isError = true;

      const optList = isDynamic ? dynamicOptionList : optionList;
      optList.map((a, i) => {
        const list = getFormDetails(a, (n, e) => this.onListValidate(n, e, i));
        if (!list) {
          isError = true;
          return false;
        }
      });
      if (isError) return false;
    }

    if (isSubmit) step--;

    this.setState({ step }, () => this.updatePayload(prevLang, isSubmit ? this.handleSubmit : null));
  }

  handleSubmit() {
    const {
      attributeName,
      prevElement,
      intent,
      source,
      isDynamic,
      isRich,
      entity,
      nextIntent,
      waTemplate,
      legacy_message,
      activity,
      langPayload,
    } = this.state;
    const { handleSubmitElement, lang } = this.props;

    const submitCb = () => {
      const langDataObj = {};
      const langPayloadObj = {};
      let defaultPayloadOption = [];

      keys(langPayload).map(x => {
        const { optionList, dynamicOptionList, messageText } = langPayload[x];
        const options = cloneDeep(isDynamic ? dynamicOptionList : optionList);
        if (x === lang.defaultLang) {
          defaultPayloadOption = cloneDeep(options);
        }

        langDataObj[x] = {
          input_type: "multiSelect",
          next_intent: nextIntent,
          request_params: [
            {
              text: messageText,
              attribute: attributeName,
              options: options.map(x => ({
                text: x.text,
                value: x.value,
              })),
              entityId: isDynamic ? entity : null,
              waTemplate,
              legacy_message,
            },
          ],
        };

        langPayloadObj[x] = {
          messageText: messageText,
          options,
        };
      });

      return { langDataObj, langPayloadObj, defaultPayloadOption };
    };

    const { langDataObj, langPayloadObj, defaultPayloadOption } = submitCb();
    const { messageText } = langPayloadObj[lang.defaultLang];

    const payload = {
      messageText,
      attributeName,
      isDynamic,
      isRich,
      intent,
      source,
      isDynamic,
      entity: isDynamic ? entity : null,
      options: defaultPayloadOption,
      nextIntent,
      waTemplate,
      legacy_message,
      activity,
      langPayload: langPayloadObj,
    };

    /* OLD CODE */
    // const data = {
    //   input_type: "multiSelect",
    //   next_intent: nextIntent,
    //   request_params: [{
    //     text: messageText,
    //     attribute: attributeName,
    //     options: cloneDeep(isDynamic ? dynamicOptionList : optionList).map(x => ({
    //       text: x.text,
    //       value: x.value
    //     })),
    //     entityId: isDynamic ? entity : null,
    //     waTemplate,
    //     legacy_message
    //   }]
    // };
    // const payloads = {
    //   messageText,
    //   attributeName,
    //   isDynamic,
    //   isRich,
    //   intent,
    //   source,
    //   isDynamic,
    //   entity: isDynamic ? entity : null,
    //   options: isDynamic ? dynamicOptionList : optionList,
    //   nextIntent,
    //   waTemplate,
    //   legacy_message,
    //   activity
    // };

    //handleSubmitElement(data, prevElement, payload);
    /* OLD CODE END */

    handleSubmitElement(langDataObj[lang.defaultLang], prevElement, payload, langDataObj);
  }

  //#region attribute events

  addAttribute(optionIndex) {
    const { isDynamic, optionList, dynamicOptionList } = this.state;
    const list = isDynamic ? cloneDeep(dynamicOptionList) : cloneDeep(optionList);
    list[optionIndex].attributes.push({
      attributeKey: "",
      attributeValue: "",
      errors: { attributeKey: null, attributeValue: null },
    });
    this.setState({ [`${isDynamic ? "dynamicOptionList" : "optionList"}`]: list });
  }

  removeAttribute(attrIndex, optionIndex) {
    const { isDynamic, optionList, dynamicOptionList } = this.state;
    const list = isDynamic ? cloneDeep(dynamicOptionList) : cloneDeep(optionList);
    list[optionIndex].attributes.splice(attrIndex, 1);
    this.setState({ [`${isDynamic ? "dynamicOptionList" : "optionList"}`]: list });
  }

  onChangeAttribute(name, value, attrIndex, optionIndex) {
    const { isDynamic, optionList, dynamicOptionList } = this.state;
    const list = isDynamic ? cloneDeep(dynamicOptionList) : cloneDeep(optionList);
    list[optionIndex].attributes[attrIndex][name] = value;
    this.setState({ [`${isDynamic ? "dynamicOptionList" : "optionList"}`]: list });
  }

  //#endregion

  //#region option events

  addOption() {
    const list = cloneDeep(this.state.optionList);
    list.push(cloneDeep(initOptions()));
    this.setState({ optionList: list, optionKey: Math.random() });
  }

  removeOption(index) {
    const list = cloneDeep(this.state.optionList);
    list.splice(index, 1);
    this.setState({ optionList: list, optionKey: Math.random() });
  }

  onChangeOption(name, value, index) {
    const { isDynamic, optionList, dynamicOptionList } = this.state;
    const list = isDynamic ? cloneDeep(dynamicOptionList) : cloneDeep(optionList);
    list[index][name] = value;
    this.setState({ [`${isDynamic ? "dynamicOptionList" : "optionList"}`]: list });
  }

  //#endregion

  //#region render steps

  renderStep3() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  renderStep2() {
    const {
      optionKey,
      optionList,
      dynamicOptionList,
      isDynamic,
      prevElementStructure,
      externalAttributes,
      errors,
      attributeName,
    } = this.state;
    const { attributeList, lang } = this.props;
    return (
      <div className="ele-content">
        <label className="name">Add Attribute</label>
        <Row>
          <Col md={3}>
            <Input
              type="text"
              placeholder="Enter Attribute"
              title="Attribute"
              name="attributeName"
              isReq={true}
              error={errors.attributeName}
              className={`form-control ${lang.className}`}
              rowClassName={lang.className}
              value={attributeName}
              validationFunc={this.onInputValidate}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </Col>
        </Row>
        <label className="name">Add Options</label>
        {(isDynamic ? dynamicOptionList : optionList).map((obj, index) => {
          return (
            <div key={index + optionKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
              <div className="input-box">
                <Row>
                  <Col md={4}>
                    <Input
                      type="text"
                      placeholder="Enter Title"
                      title="Title"
                      name="text"
                      isReq={true}
                      error={obj.errors.text}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...(isDynamic ? externalAttributes : []), ...prevElementStructure]}
                          onChangeFunc={value => this.onChangeOption("text", value, index)}
                          setAttribute={[null, obj.text]}
                        />
                      }
                      className={`form-control${isDynamic ? " pr35" : ""}`}
                      value={obj.text}
                      onChangeFunc={(name, value) => this.onChangeOption(name, value, index)}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      type="text"
                      placeholder="Enter Value"
                      title="Value"
                      name="value"
                      isReq={true}
                      error={obj.errors.value}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...(isDynamic ? externalAttributes : []), ...prevElementStructure]}
                          onChangeFunc={value => this.onChangeOption("value", value, index)}
                          setAttribute={[null, obj.value]}
                        />
                      }
                      className={`form-control${isDynamic ? " pr35" : ""}`}
                      value={obj.value}
                      onChangeFunc={(name, value) => this.onChangeOption(name, value, index)}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    />
                  </Col>
                  <Col md={2}>
                    {!isDynamic && (
                      <div className="btn-box">
                        {index === optionList.length - 1 ? (
                          <Button plusBtn className="d-inline mr10" onClickFunc={() => this.addOption()} />
                        ) : null}
                        {optionList.filter(x => !x.isDefault).length > 1 ? (
                          <Button minusBtn className="d-inline" onClickFunc={() => this.removeOption(index)} />
                        ) : null}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="clearfix"></div>
            </div>
          );
        })}
      </div>
    );
  }

  renderStep1() {
    const { boxes, botElementId, connectors, attributeList, lang } = this.props;
    const {
      messageText,
      prevElement,
      selectPos,
      source,
      prevElementStructure,
      isRich,
      isDynamic,
      entityList,
      entity,
      errors,
      waTemplate,
      legacy_message,
      activity,
    } = this.state;
    return (
      <div className="ele-content">
        <label>Have your users to select mulitple options and trigger an appropriate action.</label>
        <div className="b-form mt10">
          <ElementForm rowClassName={lang.className} prevElement>
            <SelectElement
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm rowClassName={lang.className} title="Source">
            <Select
              name="source"
              outerClassName="mb0"
              outerWidth={300}
              value={source}
              isClearable={false}
              options={this.sourceList}
              onChangeFunc={(name, value) => this.setState({ [name]: value, isDynamic: value === "dynamic" })}
            />
          </ElementForm>
          {isDynamic && (
            <ElementForm rowClassName={lang.className} title="Entity">
              <Select
                options={entityList}
                name="entity"
                value={entity}
                labelKey="name"
                valueKey="entityId"
                outerWidth={300}
                outerClassName="mb0"
                onChangeFunc={(name, value, error, obj) => this.onChangeEntity(value, obj)}
              />
            </ElementForm>
          )}
          <ElementForm title="Message">
            <div className="position-relative">
              {isRich ? (
                <TextEditor
                  title="Message"
                  name="messageText"
                  value={messageText}
                  isReq={true}
                  error={errors.messageText}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={(value, inputName) =>
                        this.setState({ messageText: value }, () => {
                          this.onInputValidate(inputName, null);
                        })
                      }
                      setAttribute={[selectPos, messageText]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              ) : (
                <TextArea
                  title="Message"
                  value={messageText}
                  name="messageText"
                  placeholder="Welcome to Purple grids !!"
                  isReq={true}
                  error={errors.messageText}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={(value, inputName) =>
                        this.setState({ messageText: value }, () => {
                          this.onInputValidate(inputName, null);
                        })
                      }
                      setAttribute={[selectPos, messageText]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value, e) => this.setState({ [name]: value, selectPos: e.target })}
                />
              )}
            </div>
            <label className={`mb0 ${lang.className}`} style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>

          <AdvanceOptionsExpand
            waTemplate={waTemplate}
            legacy_message={legacy_message}
            activity={activity}
            onChangeFunc={this.onAdvanceOptionChange}
            displayList={["waTemplate", "legacy_message", "activity"]}
            lang={lang}
          />
        </div>
      </div>
    );
  }

  //#endregion

  render() {
    const { step } = this.state;
    const { resetElement, botElementId, lang } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-send-msg-group" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
          {step === 3 && this.renderStep3()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={() => this.updatePayload(lang, this.handleSubmit)}
          step={step}
          totalSteps={3}
          botElementId={botElementId}
          handleStep={this.handleStep}
          lang={lang}
        />
      </React.Fragment>
    );
  }
}

export default MultiSelect;
