import React from "react";
import { Button, Input, ScrollBox, TextArea, Select } from "../../../common/components";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Badge } from "reactstrap";
import classnames from "classnames";
import {
  ElementForm,
  SelectElement,
  SelectAttribute,
  SubmitElement,
  SelectIntent,
  ActionJavaScript,
  ActionSetUserData,
  ActionDecision,
} from "./components";
import { getRandomNumber, isArray, mapEnvironment } from "../../../utils/GeneralUtils";
import CommonUtils from "../../../utils/CommonUtils";
import { getAttrByPrevElement, getBotIntent, prepareStructure } from "../../../utils/BotUtils";
import { getFormDetails } from "../../../common/components/helper.js";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";
import { get } from "lodash";
import { connect } from "react-redux";

class SoapApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiVerified: false,
      url: "",
      urlTarget: 0,
      activeTab: "body",
      listKey: Date.now(),
      headersList: [{ key: "", value: "" }],
      environmentList: [{ key: "", value: "" }],
      apiStatusCode: "",
      response: null,
      objResponse: null,
      authType: "no auth",
      bearerToken: "",
      rawData: "",
      rawDataTarget: 0,
      responsedisplay: "pretty",
      apiProcess: false,
      requestFailed: false,
      errorFlow: "stopFlow",
      defaultError: "",
      errorElementId: getRandomNumber(6),
      errorElement: "",
      timeout: null,
      intent: null,
      step: 1,
      nextIntent: "",
      jsonStructure: [],
      prevElementStructure: [],
      errors: {
        url: null,
      },
      actionJS: null,
      actionAttr: null,
      actionDecision: null,
    };

    this.verificationKey = null;
    this.authTypeOpt = [
      { label: "No Auth", value: "no auth" },
      { label: "Bearer Token", value: "bearer token" },
    ];

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getValidation = this.getValidation.bind(this);
    this.handleSendUrl = this.handleSendUrl.bind(this);
    this.verifyAPI = this.verifyAPI.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onChangeErrorElement = this.onChangeErrorElement.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        url,
        bearerToken,
        headersList,
        environmentList,
        rawData,
        authType,
        intent,
        nextIntent,
        jsonStructure,
        errorElementId,
        timeout,
        errorElement,
        actionJS,
        actionAttr,
        actionDecision,
      } = box.payload;
      this.setState(
        {
          url,
          bearerToken,
          headersList,
          rawData,
          authType,
          jsonStructure,
          errorElementId,
          timeout,
          errorElement,
          actionJS,
          actionAttr,
          actionDecision,
          environmentList: isArray(environmentList) ? environmentList : [{ key: "", value: "" }],
          nextIntent,
          intent: intent || null,
          activeTab: "body",
        },
        () => this.verifyAPI(true)
      );
    }
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }
    if (step === 3 && this.actionJavaScriptRef) {
      const obj = this.actionJavaScriptRef.getData();
      if (!obj) return false;
      stateObj.actionJS = obj.skip ? null : obj.data;
      if (!obj.skip && obj.jsonStructure) {
        stateObj.jsonStructure = obj.jsonStructure;
        this.generateElementStructure(obj.jsonStructure);
      }
    }
    if (step === 4 && this.actionSetUserDataRef) {
      const obj = this.actionSetUserDataRef.getData();
      if (!obj) return false;
      stateObj.actionAttr = obj.skip ? null : obj.data;
      if (!obj.skip && obj.attrStructure) {
        stateObj.jsonStructure = [...this.state.jsonStructure, ...obj.attrStructure];
        this.generateElementStructure(stateObj.jsonStructure);
      }
    }
    if (step === 5 && this.actionDecisionRef) {
      const obj = this.actionDecisionRef.getData();
      if (!obj) return false;
      stateObj.actionDecision = obj.skip ? null : obj;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      rawData,
      authType,
      headersList,
      environmentList,
      bearerToken,
      prevElement,
      intent,
      nextIntent,
      jsonStructure,
      errorElementId,
      timeout,
      errorElement,
      actionJS,
      actionAttr,
      actionDecision,
    } = this.state;
    let { url } = this.state;
    const { handleSubmitElement, selectedElement, boxes, botVersion } = this.props;
    let newHeaders = [];

    if (!url) return false;

    if (authType === "bearer token") newHeaders.push(`Authorization:${bearerToken}`);

    headersList.map(obj => {
      if (obj.key) newHeaders.push(`${obj.key}:${obj.value}`);
    });

    const elError = boxes.find(x => x.id === errorElement);
    let data = {
      input_type: "soap",
      next_intent: nextIntent,
      request_params: [
        {
          url: url,
          apiName: selectedElement.label, // - name given by user for node
          headersList: newHeaders,
          merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
          errorIntent: getBotIntent(elError, botVersion),
          timeout,
          template: rawData,
        },
      ],
    };

    if (actionJS || isArray(actionAttr) || actionDecision) {
      data.customAction = {};

      if (actionJS) {
        data.customAction.javascript = actionJS;
      }
      if (isArray(actionAttr)) {
        data.customAction.attributes = actionAttr;
      }
      if (actionDecision) {
        data.customAction.decision = actionDecision;
      }
    }

    const aqr = get(data, `customAction.decision.qr`, []);

    const payload = {
      url,
      bearerToken,
      headersList,
      environmentList: environmentList.filter(x => x.key && x.value),
      rawData,
      authType,
      intent,
      nextIntent,
      errorElementId,
      errorElement,
      timeout,
      jsonStructure,
      qr: [...aqr],
      actionJS,
      actionAttr,
      actionDecision,
    };
    if (data.request_params[0].errorIntent) {
      payload.qr.unshift({
        id: errorElementId,
        text: "Error",
        connection: errorElement,
      });
    }
    handleSubmitElement(data, prevElement, payload);
  }

  verifyAPI(isVerified = false) {
    const { url, rawData, headersList, authType, bearerToken } = this.state;
    let apiVerified = isVerified;

    const verificationKey = JSON.stringify({
      url,
      rawData,
      headersList,
      authType,
      bearerToken,
    });

    if (url && this.verificationKey === verificationKey) {
      apiVerified = true;
    } else if (apiVerified) {
      this.verificationKey = verificationKey;
    }

    this.setState({ apiVerified });
  }

  getValidation(e) {
    const { name, value } = e.currentTarget;

    const stateObj = { [name]: value };

    this.setState(stateObj, () => {
      if (name === "responsedisplay") {
        this.setResponse();
      }
    });
  }

  setResponse() {
    const { responsedisplay, objResponse, requestFailed } = this.state;
    let response;
    if (!requestFailed)
      switch (responsedisplay) {
        case "pretty":
          response = JSON.stringify(objResponse, null, 2);
          break;
        case "raw":
          response = JSON.stringify(objResponse);
          break;
        default:
          response = objResponse;
          break;
      }
    this.setState({ response });
  }

  handleSendUrl() {
    const { headersList, rawData, authType, bearerToken, environmentList } = this.state;
    const customConfigData = this.props.merchantAttr.data.customConfig;

    let { url } = this.state;
    if (!url) return;

    let newHeaders = [];

    if (authType === "bearer token") {
      newHeaders.push(`Authorization:${bearerToken}`);
    }
    headersList.map(obj => {
      if (obj.key) newHeaders.push(`${obj.key}:${obj.value}`);
    });

    this.setState({ apiProcess: true });
    const body = {
      template: rawData || "",
      headersList: newHeaders,
      url: url,
    };
    const mappedBody = mapEnvironment(
      environmentList.filter(x => x.key && x.value),
      body,
      customConfigData
    );
    CommonUtils.getOrchestratorApi(mappedBody, true).then(res => {
      const stateObj = { apiStatusCode: res.status + " " + res.statusText };
      if (!res.success) {
        this.setState(
          { ...stateObj, jsonStructure: [], response: res.error, apiProcess: false, requestFailed: true },
          () => this.verifyAPI()
        );
        return;
      }

      const jsonStructure = prepareStructure(res.data);
      this.setState(
        { ...stateObj, jsonStructure, objResponse: res.data, apiProcess: false, requestFailed: false },
        () => {
          this.generateElementStructure(jsonStructure);
          this.verifyAPI(true);
          this.setResponse();
        }
      );
    });
  }

  onChangeErrorElement(name, value) {
    this.setState({ [name]: value });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  addFeature(listName) {
    let list = this.state[listName];
    list.push({
      key: "",
      value: "",
    });
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  removeFeature(listName, index) {
    let list = this.state[listName];
    list.splice(index, 1);
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  onChangeFeature(name, value, index, listName) {
    let list = this.state[listName];
    list[index][name] = value;
    this.setState({ [listName]: list }, () => {
      this.verifyAPI();
    });
  }

  generateElementStructure(externalStructure = []) {
    const { boxes, elementType } = this.props;
    const structure = getAttrByPrevElement(this.state.prevElement, boxes, elementType);
    this.setState({ prevElementStructure: [...structure, ...externalStructure] });
  }

  onChangePrevElement(name, value) {
    this.setState({ [name]: value }, () => this.generateElementStructure());
  }

  renderTab() {
    const {
      activeTab,
      listKey,
      authType,
      bearerToken,
      prevElementStructure,
      rawData,
      rawDataTarget,
      errorFlow,
      defaultError,
      headersList,
      environmentList,
      timeout,
      errorElement,
    } = this.state;
    const { botElementId, boxes, connectors, attributeList } = this.props;

    return (
      <div className="postman-head">
        <Nav className="nav-tab">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "authorization" })}
              onClick={() => {
                this.toggle("authorization");
              }}
            >
              {" "}
              Authorization
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "headers" })}
              onClick={() => {
                this.toggle("headers");
              }}
            >
              {" "}
              Headers
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "body" })}
              onClick={() => {
                this.toggle("body");
              }}
            >
              {" "}
              Body
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "errors" })}
              onClick={() => {
                this.toggle("errors");
              }}
            >
              Errors
            </NavLink>
          </NavItem>
          {environmentList.length > 0 && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "environment" })}
                onClick={() => {
                  this.toggle("environment");
                }}
              >
                Environment{" "}
                <Badge pill color="orange">
                  {environmentList.filter(x => x.key && x.value).length}
                </Badge>
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="authorization">
            {activeTab === "authorization" && (
              <div>
                <label>Type</label>
                <br />
                <Row>
                  <Col md="3" style={{ borderRight: "1px" }}>
                    <Select
                      name="authType"
                      value={authType}
                      options={this.authTypeOpt}
                      isClearable={false}
                      onChangeFunc={(name, value) => this.setState({ [name]: value }, () => this.verifyAPI())}
                    />
                  </Col>
                  <Col md="9">
                    {authType === "no auth" && (
                      <label className="mt5">This request does not use any authorization.</label>
                    )}
                    {authType === "bearer token" && (
                      <Input
                        placeholder="Token"
                        name="bearerToken"
                        value={bearerToken}
                        onChangeFunc={(name, value) => this.setState({ [name]: value }, () => this.verifyAPI())}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </TabPane>
          <TabPane tabId="headers">
            {activeTab === "headers" &&
              headersList.map((obj, index) => {
                return (
                  <div key={index + listKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "headersList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Value"
                            name="value"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "headersList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === headersList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("headersList")} />
                            ) : null}
                            {index !== 0 || headersList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("headersList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
          <TabPane tabId="body">
            {activeTab === "body" && (
              <TextArea
                name="rawData"
                value={rawData}
                appendIcon={
                  <SelectAttribute
                    attributeList={attributeList}
                    outerAttriClass="text-area-icon"
                    externalAttributes={prevElementStructure}
                    onChangeFunc={value => this.setState({ rawData: value }, () => this.verifyAPI())}
                    setAttribute={[rawDataTarget, rawData]}
                  />
                }
                onChangeFunc={(name, value, e) =>
                  this.setState({ [name]: value, rawDataTarget: e.target }, () => this.verifyAPI())
                }
              />
            )}
          </TabPane>
          <TabPane tabId="errors">
            {activeTab === "errors" && (
              <div>
                <Row>
                  <Col md={4}>
                    <label>Timeout</label>
                    <br />
                    <Input
                      type="text"
                      placeholder="Enter Timeout"
                      name="timeout"
                      value={timeout}
                      onChangeFunc={this.onChangeErrorElement}
                    />
                  </Col>
                  <Col md={4}>
                    <label>Error Element</label>
                    <br />
                    <SelectElement
                      value={errorElement}
                      boxes={boxes}
                      name="errorElement"
                      placeholder="Select Error Element"
                      // botElementId={botElementId}
                      connectors={connectors}
                      defaultSelected={false}
                      onChangeFunc={this.onChangeErrorElement}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </TabPane>
          <TabPane tabId="environment">
            {activeTab === "environment" &&
              environmentList.map((obj, index) => {
                return (
                  <div key={index} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="key"
                            placeholder="Enter Key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "environmentList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control pr35"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "environmentList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === environmentList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("environmentList")} />
                            ) : null}
                            {index !== 0 || environmentList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("environmentList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
        </TabContent>
      </div>
    );
  }

  renderStep1() {
    const {
      url,
      urlTarget,
      response,
      responsedisplay,
      apiProcess,
      requestFailed,
      apiStatusCode,
      prevElement,
      prevElementStructure,
      errors,
    } = this.state;
    const { botElementId, boxes, connectors, attributeList } = this.props;

    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
        </div>
        <Row>
          <Col md="9" className="pr0">
            <Input
              name="url"
              title="URL"
              placeholder="Enter url"
              value={url}
              className="form-control pr35"
              outerClassName="mt10"
              isReq={true}
              error={errors.url}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={(value, inputName) =>
                    this.setState({ url: value }, () => {
                      this.verifyAPI(), this.onInputValidate(inputName, null);
                    })
                  }
                  setAttribute={[urlTarget, url]}
                />
              }
              onClickFunc={e => this.setState({ urlTarget: e.target })}
              validationFunc={this.onInputValidate}
              onChangeFunc={(name, value, err, obj, e) =>
                this.setState({ [name]: value, urlTarget: e.target }, () => {
                  this.verifyAPI();
                })
              }
            />
          </Col>
          <Col md="3" className="mt10">
            <Button
              submitBtn
              displayIcon={false}
              style={{ height: "33px" }}
              text="Send"
              loading={apiProcess}
              onClickFunc={this.handleSendUrl}
            />
          </Col>
        </Row>
        <Row>
          <Col>{this.renderTab()}</Col>
        </Row>
        <Row>
          <Col md="12">
            <label className="mt20 float-left font-weight-bold">Response</label>
          </Col>
        </Row>
        {response !== null && (
          <Row>
            <Col md="12">
              <input
                type="radio"
                name="responsedisplay"
                checked={responsedisplay === "pretty"}
                value="pretty"
                className="mr5 cursor-pointer"
                id="pretty"
                onChange={this.getValidation}
              />
              <label className="cursor-pointer mr20" htmlFor="pretty">
                Pretty
              </label>

              <input
                type="radio"
                name="responsedisplay"
                checked={responsedisplay === "raw"}
                value="raw"
                className="mr5 cursor-pointer"
                id="raw"
                onChange={this.getValidation}
              />
              <label className="cursor-pointer mr20" htmlFor="raw">
                Raw
              </label>

              {apiStatusCode && (
                <div className="float-right">
                  Status:
                  <label className={`ml5 ${requestFailed ? "text-danger" : "text-success"}`}>{apiStatusCode}</label>
                </div>
              )}
            </Col>
          </Row>
        )}

        <TextArea value={response} rows={20} name="response" onChangeFunc={() => false} />
      </div>
    );
  }
  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  renderStepJS() {
    return (
      <ActionJavaScript
        {...this.props}
        value={this.state.actionJS}
        dataParam={this.state.objResponse}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionJavaScriptRef = e)}
      />
    );
  }

  renderStepSetAttr() {
    return (
      <ActionSetUserData
        {...this.props}
        value={this.state.actionAttr}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionSetUserDataRef = e)}
      />
    );
  }

  renderStepDecision() {
    return (
      <ActionDecision
        {...this.props}
        value={this.state.actionDecision}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionDecisionRef = e)}
      />
    );
  }

  render() {
    const { apiVerified, url, step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        {url &&
          (apiVerified ? (
            <span className="verify-lbl text-success">
              <i className="fa fa-check-circle"></i>Verified
            </span>
          ) : (
            <span className="verify-lbl text-danger">
              <i className="fa fa-exclamation-circle"></i>Not Verified
            </span>
          ))}
        <ScrollBox key={step} scrollClass="bt-soap-api" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStepJS()}
          {step === 3 && this.renderStepSetAttr()}
          {step === 4 && this.renderStepDecision()}
          {step === 5 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          step={step}
          totalSteps={5}
          resetElement={resetElement}
          botElementId={botElementId}
          handleSubmit={this.handleSubmit}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    merchantAttr: state.common.merchantAttr,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SoapApi);
