import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import "../../css/react-datetime.css";
import { getDate } from "../../utils/GeneralUtils";
import moment from "moment";

class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const {
      timeFormat,
      dateFormat,
      placeholder,
      name,
      value,
      onChange,
      outerClassName,
      disabled,
      closeOnSelect,
      isValidDate,
    } = this.props;
    const dtProps = {
      timeFormat,
      dateFormat,
      isValidDate,
      inputProps: {
        placeholder,
        disabled,
      },
      closeOnSelect,
    };
    const inputDateFormat = `${dateFormat}${timeFormat ? ` ${timeFormat}` : ""}`;
    return (
      <div className={`dp-box ${outerClassName}`}>
        <DatePicker
          {...dtProps}
          ref={e => (this.dtRef = e)}
          value={value && moment(value, inputDateFormat)}
          onChange={e => onChange(name, getDate(e, inputDateFormat))}
        />
        <i
          className="fa fa-calendar"
          onClick={() => {
            this.setState({ isOpen: !this.state.isOpen }, () =>
              !this.state.isOpen ? this.dtRef._openCalendar() : this.dtRef._closeCalendar()
            );
          }}
        ></i>
      </div>
    );
  }
}

DateTimePicker.defaultProps = {
  timeFormat: false,
  dateFormat: "YYYY-MM-DD",
  onChange: () => {},
  placeholder: "Select Date",
  outerClassName: "",
  disabled: false,
  closeOnSelect: true,
};

DateTimePicker.propTypes = {
  timeFormat: PropTypes.bool,
  dateFormat: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  outerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
};

export default DateTimePicker;
