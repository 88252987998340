import React, { Component } from "react";
import { cloneDeep, isArray } from "lodash";
import { TextArea, Input, ScrollBox } from "../../../common/components";
import { convertPayloadToData, getAttrByPrevElement } from "../../../utils/BotUtils";
import { SelectElement, SelectAttribute, ElementForm, SubmitElement, SelectIntent, OptionsElement } from "./components";
import { getFormDetails } from "../../../common/components/helper";
import { actionMeetingList } from "../../../utils/ConstUtils";

class Meeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPos: 0,

      loading: "",
      title: "",
      description: "",
      imageUrl: "",

      dataQR: [],
      payloadQR: [],

      nextIntent: "",
      intent: null,
      step: 1,
      prevElementStructure: [],
      errors: {
        title: null,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { loading, title, description, imageUrl, nextIntent, intent, qr } = box.payload;
      this.setState({
        loading,
        title,
        description,
        imageUrl,
        nextIntent,
        intent: intent || null,
        payloadQR: cloneDeep(qr),
        dataQR: convertPayloadToData(qr, this.props).dataQR,
      });
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }
    if (step === 3) {
      const obj = this.optionRef.getOptions();
      if (!obj) return false;
      else {
        stateObj.dataQR = obj.dataQR;
        stateObj.payloadQR = obj.payloadQR;
      }
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { loading, title, description, imageUrl, prevElement, nextIntent, intent, payloadQR, dataQR } = this.state;
    const { handleSubmitElement } = this.props;
    const data = {
      input_type: "meeting",
      next_intent: nextIntent,
      request_params: [
        {
          loading,
          title,
          description,
          imageUrl,
          buttons: dataQR,
        },
      ],
    };
    const payload = { loading, title, description, imageUrl, nextIntent, intent, qr: payloadQR };
    handleSubmitElement(data, prevElement, payload);
  }

  handleChange(name, value, e) {
    const stateObj = { [name]: value };
    if (name === "description") {
      stateObj.selectPos = e.target;
    }
    this.setState(stateObj);
  }

  renderStep1() {
    const { title, description, imageUrl, prevElement, selectPos, prevElementStructure, errors } = this.state;
    const { boxes, connectors, attributeList, botElementId } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              menuPlacement="top"
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Title">
            <Input
              type="text"
              name="title"
              placeholder="Enter Title"
              outerClassName="mb0"
              title="Title"
              value={title}
              isReq={true}
              error={errors.title}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={(value, inputName) =>
                    this.setState({ title: value }, () => {
                      this.onInputValidate(inputName, null);
                    })
                  }
                  setAttribute={[null, title]}
                />
              }
              validationFunc={this.onInputValidate}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Description">
            <TextArea
              placeholder="Enter Description"
              name="description"
              title="Description"
              value={description}
              appendIcon={
                <SelectAttribute
                  outerAttriClass="text-area-icon"
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ description: value })}
                  setAttribute={[selectPos, description]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Image URL">
            <Input
              type="text"
              name="imageUrl"
              placeholder="Enter Image URL"
              outerClassName="mb0"
              title="Image URL"
              value={imageUrl}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ imageUrl: value })}
                  setAttribute={[null, imageUrl]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { prevElementStructure, payloadQR } = this.state;
    return (
      <div className="ele-content">
        <OptionsElement
          {...this.props}
          actions={actionMeetingList}
          payloadQR={isArray(payloadQR) ? payloadQR : undefined}
          ref={e => (this.optionRef = e)}
          prevElementStructure={prevElementStructure}
        />
      </div>
    );
  }

  renderStep3() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox scrollClass="bt-send-msg" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
          {step === 3 && this.renderStep3()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          totalSteps={3}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default Meeting;
