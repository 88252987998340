import React from "react";
import PropTypes from "prop-types";
import ElementForm from "./ElementForm";
import { Input } from "../../../../common/components";

const AdvanceOptions = props => {
  const { waTemplateChecked, interactiveChecked, onChangeFunc } = props;
  return (
    <React.Fragment>
      <label className="name mt10" style={{ lineHeight: "33px" }}>
        Advance Options
      </label>
      <ElementForm title="WhatsApp Template" rowClassName="mb0">
        <Input
          type="checkbox"
          name="waTemplate"
          outerClassName="float-left mr10 mb0"
          style={{ width: 20 }}
          checked={waTemplateChecked}
          onChangeFunc={onChangeFunc}
        />
      </ElementForm>
      <ElementForm title="Interactive Message" rowClassName="mb10">
        <Input
          type="checkbox"
          name="interactive"
          outerClassName="float-left mr10 mb0"
          style={{ width: 20 }}
          checked={interactiveChecked}
          onChangeFunc={onChangeFunc}
        />
      </ElementForm>
    </React.Fragment>
  );
};

AdvanceOptions.defaultProps = {
  waTemplateChecked: false,
  interactiveChecked: false,
};

AdvanceOptions.propTypes = {
  waTemplateChecked: PropTypes.bool,
  interactiveChecked: PropTypes.bool,
  onChangeFunc: PropTypes.func,
};

export default AdvanceOptions;
