import React, { Component } from "react";
import CommonUtils from "../../../../utils/CommonUtils";
import {
  SendMessage,
  QReplyV2,
  MultiSelect,
  SendImage,
  Identity,
  SendVideo,
  TriggerPath,
  SendAudio,
  RequestUserData,
  SendCarousel,
  SendEmail,
  JsonApi,
  SoapApi,
  SetUserData,
  SetPageData,
  Decision,
  LiveAgent,
  JavaScript,
  WebView,
  Product,
  Task,
  Payment,
  Form,
  FileUpload,
  Meeting,
  UpdateVisitor,
  CustomerRating,
  MLModels,
  RPAEngine,
  DataSource,
  IntegrationApi,
  JsonTransformation,
  CustomComponent,
  FormBuilder,
  Prompt,
} from "../index";
import flatten from "lodash/flatten";
import { ScrollBox } from "../../../../common/components";
import { ELEMENT_TYPE, cloneBotDetails, getClipboardData } from "../../../../utils/BotUtils";
import { getStatus, isArray, isJsonString } from "../../../../utils/GeneralUtils";
import { cloneDeep, get } from "lodash";
import { BotPopupContext } from "./BotPopupContext";

class BotPopup extends Component {
  constructor(props) {
    super(props);
    const { botElementId, boxes } = this.props;
    const element = boxes.find(x => x.id === botElementId);
    this.childRef = React.createRef();
    this.state = {
      selectedElement: botElementId ? { ...element.typeData } : null,
      botElementId: botElementId,
      activeCategory: element && element.typeData.category ? element.typeData.category : 1,
      intentList: [],
      attributeList: [],
      isSmallMenu: botElementId ? true : false,
      langList: props.lang.languages,
      selectedLang: props.lang.defaultLanguage,
      defaultLang: props.lang.defaultLanguage,
      isClipboardEnabled: false,
      isClone: false,
      isClonePaste: false,
      cloneType: null,
      clonedBotType: null,
      isInternalClone: false,
      isSubmitLoader: false,
      submitError: "",
    };
  }

  componentDidMount() {
    this.getIntentList();
    this.getDynamicParams();
    this.getClipboardStatus();
    this.getClonedBotDetails();
  }

  getClipboardStatus() {
    if (navigator.clipboard && navigator.clipboard.readText) {
      navigator.permissions
        .query({ name: "clipboard-read" })
        .then(permissionStatus => {
          const updateClipboardStatus = () => {
            if (permissionStatus.state === "granted") {
              this.setState({ isClipboardEnabled: true });
            }
          };

          permissionStatus.onchange = updateClipboardStatus;
          updateClipboardStatus();
        })
        .catch(error => {
          console.log("Error checking clipboard permission:", error);
        });
    }
  }

  getIntentList() {
    CommonUtils.getKnwIntent().then(response => {
      if (response.error) {
        this.setState({ intentList: [] });
        return false;
      }
      const intentList = flatten(response.knwDocIntents.map(d => d.intents)).map(i => ({
        intentId: i.intentId,
        intent: i.intent,
      }));
      this.setState({ intentList: intentList });
    });
  }

  async getClonedBotDetails() {
    const clipboardData = await getClipboardData();
    if (clipboardData && isJsonString(clipboardData)) {
      const stateValue = JSON.parse(clipboardData);
      this.setState({ clonedBotType: stateValue.clonedBotType });
    }
  }

  internalCloneBot() {
    this.setState({ botElementId: 0, isInternalClone: true });
    this.externalCloneBot();
    this.pasteBotDetails();
  }

  externalCloneBot() {
    if (this.childRef.current) {
      const childStateData = cloneDeep(this.childRef.current.state);
      const botStateData = { clonedBotType: this.state.selectedElement.id, clonedData: childStateData };
      cloneBotDetails(botStateData);
      this.setState({ isClone: true });
    }
  }

  async pasteBotDetails() {
    const clipboardData = await getClipboardData();
    if (clipboardData && isJsonString(clipboardData)) {
      const stateValue = JSON.parse(clipboardData);
      this.childRef.current.setState(stateValue.clonedData);
      this.setState({ isClonePaste: true });
    }
  }

  getDynamicParams() {
    if (this.props.botName) {
      CommonUtils.getDynamicParams(this.props.botName).then(response => {
        if (response.error) {
          this.setState({ attributeList: [] });
          return false;
        }
        this.setState({
          attributeList: response.map(x => ({
            label: x.attributeName,
            value: `\${${x.type === "BotParam" ? "userInput." : ""}${x.attributeName}!}`,
          })),
        });
      });
    }
  }

  renderElementConfig() {
    const { handleSubmitElement, closePopup, boxes, connectors, botName, merchantBotList, elementList, botVersion } =
      this.props;
    const {
      SEND_MESSAGE,
      SEND_IMAGE,
      SEND_MESSAGE_WITH_OPT,
      MULTI_SELECT,
      REQ_USER_DATA,
      SEND_VIDEO,
      SEND_CAROUSEL,
      SEND_EMAIL,
      JSON_API,
      SOAP_API,
      SET_USER_DATA,
      SET_PAGE_DATA,
      DECISION,
      TRIGGER_PATH,
      SEND_AUDIO,
      IDENTITY,
      WEBVIEW,
      PRODUCT,
      TASK,
      LIVE_AGENT,
      JAVA_SCRIPT,
      JSON_TRANSFORMATION,
      PAYMENT,
      FORM,
      FILE_UPLOAD,
      MEETING,
      RPA,
      UPDATE_VISITOR,
      CUSTOMER_RATING,
      ML_MODELS,
      DATA_SOURCE,
      CUSTOM_COMPONENT,
      FORM_BUILDER,
      PROMPT,
    } = ELEMENT_TYPE;
    const {
      selectedElement,
      botElementId,
      intentList,
      attributeList,
      isSmallMenu,
      selectedLang,
      defaultLang,
      langList,
      isClone,
      isClonePaste,
      cloneType,
      isSubmitLoader,
    } = this.state;
    const messageProps = {
      lang: {
        handleLang: lang => this.setState({ selectedLang: lang }),
        list: langList,
        selectedLang,
        defaultLang,
        isSecondaryLang: selectedLang !== defaultLang,
        className: selectedLang !== defaultLang ? "no-click-v1" : "",
      },
      handleSubmitElement: async (data, prevElementId, payload, langData) => {
        this.setState({ isSubmitLoader: true, submitError: "" });
        const res = await handleSubmitElement(data, langData, prevElementId, payload, botElementId, selectedElement);
        this.setState({ isSubmitLoader: false });
        if (res.error) {
          this.setState({ submitError: res.message || "Sorry, something went wrong: Please try again later." });
          return false;
        }
        closePopup();
      },
      resetElement: () => closePopup(),
      boxes,
      connectors,
      botElementId,
      intentList,
      isSmallMenu,
      botName,
      selectedElement,
      elementType: ELEMENT_TYPE,
      merchantBotList,
      attributeList,
      elementList,
      botVersion,
      isClone,
      isClonePaste,
      cloneType,
      ref: this.childRef,
      isSubmitLoader,
    };
    let _component = null;
    switch (selectedElement.id) {
      case SEND_MESSAGE:
        _component = SendMessage;
        break;
      case SEND_MESSAGE_WITH_OPT:
        _component = QReplyV2;
        break;
      case MULTI_SELECT:
        _component = MultiSelect;
        break;
      case SEND_IMAGE:
        _component = SendImage;
        break;
      case SEND_VIDEO:
        _component = SendVideo;
        break;
      case SEND_AUDIO:
        _component = SendAudio;
        break;
      case TRIGGER_PATH:
        _component = TriggerPath;
        break;
      case REQ_USER_DATA:
        _component = RequestUserData;
        break;
      case SEND_CAROUSEL:
        _component = SendCarousel;
        break;
      case SEND_EMAIL:
        _component = SendEmail;
        break;
      case JSON_API:
        _component = JsonApi;
        break;
      case RPA:
        _component = RPAEngine;
        break;
      case ML_MODELS:
        _component = MLModels;
        break;
      case PROMPT:
        _component = Prompt;
        break;
      case DATA_SOURCE:
        _component = DataSource;
        break;
      case SOAP_API:
        _component = SoapApi;
        break;
      case IDENTITY:
        _component = Identity;
        break;
      case LIVE_AGENT:
        _component = LiveAgent;
        break;
      case SET_USER_DATA:
        _component = SetUserData;
        break;
      case SET_PAGE_DATA:
        _component = SetPageData;
        break;
      case DECISION:
        _component = Decision;
        break;
      case JAVA_SCRIPT:
        _component = JavaScript;
        break;
      case JSON_TRANSFORMATION:
        _component = JsonTransformation;
        break;
      case PAYMENT:
        _component = Payment;
        break;
      case FORM:
        _component = Form;
        break;
      case FILE_UPLOAD:
        _component = FileUpload;
        break;
      case WEBVIEW:
        _component = WebView;
        break;
      case PRODUCT:
        _component = Product;
        break;
      case TASK:
        _component = Task;
        break;
      case MEETING:
        _component = Meeting;
        break;
      case UPDATE_VISITOR:
        _component = UpdateVisitor;
        break;
      case CUSTOMER_RATING:
        _component = CustomerRating;
        break;
      case CUSTOM_COMPONENT:
        _component = CustomComponent;
        break;
      case FORM_BUILDER:
        _component = FormBuilder;
        break;
      case selectedElement.id.startsWith("INT_API_") ? selectedElement.id : "":
        _component = IntegrationApi;
        break;

      default:
        break;
    }

    if (!_component) {
      return null;
    }
    return <_component {...messageProps} />;
  }
  render() {
    const { elementList, closePopup } = this.props;
    const {
      selectedElement,
      botElementId,
      activeCategory,
      isSmallMenu,
      isClone,
      isClonePaste,
      clonedBotType,
      isInternalClone,
      isClipboardEnabled,
      isSubmitLoader,
      submitError,
    } = this.state;
    const title = selectedElement
      ? (elementList.find(x => x.id === selectedElement.id) || selectedElement).name
      : "Bot Element";
    return (
      <BotPopupContext.Provider
        value={{
          isSubmitLoader,
        }}
      >
        <div className="bot-popup">
          <div className="bot-popup-inner">
            <div className="bt-title">
              <span className="heading">
                {selectedElement && selectedElement.image && (
                  <img src={selectedElement.image.src} height="20px" className="mr-2" style={{ marginTop: -4 }} />
                )}
                {title}
                {submitError ? (
                  <span className="font-weight-normal fs13 ml-2 text-danger">
                    <i className="fa fa-exclamation-triangle mr-1"></i>
                    {submitError}
                  </span>
                ) : null}
              </span>
              <span className="close" onClick={() => closePopup()}>
                <i className="fa fa-times"></i>
              </span>
              {isClipboardEnabled && (
                <React.Fragment>
                  {botElementId && !isClone ? (
                    <React.Fragment>
                      <button
                        className="close mr10 btn-clone"
                        onClick={() => this.internalCloneBot()}
                        title="Internal Clone"
                      >
                        <i className="fa fa-clone" aria-hidden="true"></i>
                      </button>
                      <button
                        className="close mr10 btn-clone"
                        onClick={() => this.externalCloneBot()}
                        title="External Clone"
                      >
                        <i className="fa fa-clipboard" aria-hidden="true"></i>
                      </button>
                    </React.Fragment>
                  ) : null}
                  {!botElementId && !isClonePaste && selectedElement && clonedBotType === selectedElement.id ? (
                    <button
                      className="close mr10 btn-clone"
                      onClick={() => this.pasteBotDetails()}
                      title="Paste Component"
                    >
                      <i className="fa fa-sticky-note" aria-hidden="true"></i>
                    </button>
                  ) : null}
                  {isClone && (
                    <div className="float-right mr10 clone-text-msg">
                      <i className="fa fa-check-circle" aria-hidden="true"></i> Clone Bot
                    </div>
                  )}
                  {isClonePaste && !isInternalClone ? (
                    <div className="float-right mr10 clone-text-msg">
                      <i className="fa fa-check-circle" aria-hidden="true"></i> Component Pasted
                    </div>
                  ) : null}
                </React.Fragment>
              )}
              <span className="clearfix"></span>
            </div>
            {!botElementId && (
              <div className={`bt-left${isSmallMenu ? " bt-left-sm" : ""}`}>
                <div className="cat-title">
                  {isSmallMenu ? (
                    <img
                      className="cursor-pointer"
                      src="/img/bot-elements/list.svg"
                      onClick={() => this.setState({ isSmallMenu: false })}
                    />
                  ) : (
                    <React.Fragment>
                      <span
                        className={activeCategory === 1 ? "active" : ""}
                        onClick={() => this.setState({ activeCategory: 1 })}
                      >
                        General
                      </span>
                      <span
                        className={activeCategory === 2 ? "active" : ""}
                        onClick={() => this.setState({ activeCategory: 2 })}
                      >
                        Advance
                      </span>
                      <span
                        className={activeCategory === 3 ? "active" : ""}
                        onClick={() => this.setState({ activeCategory: 3 })}
                      >
                        Integration
                      </span>
                      <img
                        className="action-icon cursor-pointer"
                        src="/img/bot-elements/collapse.svg"
                        onClick={() => this.setState({ isSmallMenu: true })}
                      />
                    </React.Fragment>
                  )}
                </div>
                <ScrollBox key={activeCategory} scrollClass="bt-menu-list" className="bt-menu-list" boxHeight={465}>
                  {elementList
                    .filter(x => !x.isHide && x.category === activeCategory)
                    .map((x, i) => {
                      return (
                        <div
                          key={i}
                          title={x.name}
                          className={`bt-msg-box${selectedElement && selectedElement.id === x.id ? " active" : ""}`}
                          onClick={() => {
                            if (botElementId === 0) {
                              this.setState({ selectedElement: x, isSmallMenu: true });
                            }
                          }}
                        >
                          <div className="item w-100">
                            <img src={x.image.src} />
                            <div className="name" style={{ wordWrap: "break-word" }}>
                              {x.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </ScrollBox>
              </div>
            )}
            <div className={`bt-right${isSmallMenu ? " bt-right-lg" : ""}${botElementId ? " w-100" : ""}`}>
              {!selectedElement ? (
                <div className="no-element">
                  <i className="icon-info icon"></i>
                  <p className="title">Select element from left menu.</p>
                </div>
              ) : (
                <React.Fragment>
                  <div className="ele-title">
                    <input
                      type="text"
                      value={selectedElement.label}
                      onChange={e => this.setState({ selectedElement: { ...selectedElement, label: e.target.value } })}
                    />
                    <i className="fa fa-pencil" />
                  </div>
                  {this.renderElementConfig()}
                </React.Fragment>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </BotPopupContext.Provider>
    );
  }
}

export default BotPopup;
