import React, { Component } from "react";
import { connect } from "react-redux";
// import { withRouter } from "../withRouter";
import { Nav, NavbarBrand, NavbarToggler, NavItem, NavLink, Badge } from "reactstrap";
import isEqual from "lodash/isEqual";
import HeaderDropdown from "./Headerdropdown";
import CommonUtils from "../../../utils/CommonUtils.js";
import Breadcrumb from "./../Breadcrumb";
import {
  logoutUser,
  setTabIndex,
  getMessageList,
  getActiveVisitorList,
  getAgentTeamList,
  getResponseReviewMessages,
  handlePopup,
  getMerchantAttr,
  getMerchantIntegrationList,
  getAgentMetrics,
  getLiveAgents,
  getMerchantProfile,
  getRolePermission,
} from "../../../actions";

import { MENU_TYPE, MENU_ACCESS, BRAND_LOGO_URL } from "../../../utils/Config";
import {
  sessionMerchantId,
  sessionAccessToken,
  sessionUser,
  sessionUserId,
  sessionFirstName,
  sessionLastName,
  sessionExpiresAt,
  sessionRole,
  sessionClear,
  sessionStayAlive,
} from "../../../utils/SessionUtils";
import { logoutApp, optimizeCloudinaryImage, userLogout } from "../../../utils/GeneralUtils";
import IdleTimer from "react-idle-timer";
import TimeoutBox from "../../../components/TimeoutBox";
import KnowledgeHub from "../../../components/KnowledgeHub";
import { get } from "lodash";
import { CircularProgressBar } from "../../components";
import { updateUserAgent } from "../../../utils/LoginUtil.js";
import { withRouter } from "../../../withRouter.js";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAssist: [],
      currentStep: 0,
      totalSteps: 0,
      sidebarOpen: true,

      timeout: 1000 * 60 * 14,
      showTimeoutBox: false,
      showKnowledgeHub: false,
      stayAliveKey: Math.random(),
    };
    this.tabObj = MENU_ACCESS[`${MENU_TYPE.visitor}-tabs`];
    this.goToVisitor = this.goToVisitor.bind(this);
    this.goToLiveAgents = this.goToLiveAgents.bind(this);
    this.toggleKnowledgeHub = this.toggleKnowledgeHub.bind(this);
    this.deleteUserAgent = this.deleteUserAgent.bind(this);

    this.setRefreshTokenInterval = this.setRefreshTokenInterval.bind(this);
    this.getExpiresTime = this.getExpiresTime.bind(this);
    this.refreshToken = this.refreshToken.bind(this);

    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleStayLoggedIn = this.handleStayLoggedIn.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (sessionExpiresAt()) {
      this.setRefreshTokenInterval();
    }
    this.updateUserAgentInfo();
  }

  updateUserAgentInfo() {
    this.updateAgentInfo = setInterval(() => {
      updateUserAgent();
    }, 540 * 1000); // 9 minutes to update user agent
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, fetching } = this.props.merchantAttr;
    if (!isEqual(nextProps.merchantAttr.data, data) || nextProps.merchantAttr.fetching !== fetching) {
      let obj = {
        userAssist: [],
        currentStep: 0,
        totalSteps: 0,
      };
      if (
        nextProps.merchantAttr.data &&
        nextProps.merchantAttr.data.user_assist &&
        nextProps.merchantAttr.data.user_assist.length > 0
      ) {
        obj.userAssist = nextProps.merchantAttr.data.user_assist;
        obj.userAssist.map(p => {
          if (p.status == "completed") {
            obj.currentStep = obj.currentStep + 1;
          }
          obj.totalSteps = obj.totalSteps + 1;
        });
      }
      this.setState(obj);
    }
  }

  deleteUserAgent() {
    if (!sessionAccessToken()) return undefined;
    userLogout(false);
    for (var i = 0; i < 500000000; i++) {}
    return undefined;
  }

  componentDidMount() {
    if (!sessionStayAlive()) {
      this.idleTimer.start();
    }
    window.addEventListener("beforeunload", this.deleteUserAgent);
    this.props.getRolePermission();
    this.props.getAgentTeamList();
    this.props.getActiveVisitorList();
    if (sessionRole() !== "PHYSICIAN") {
      // this.props.getMessageList(true);
      this.props.getResponseReviewMessages();
      this.props.getAgentMetrics();
      this.props.getLiveAgents();
    }
    this.props.getMerchantAttr();
    this.props.getMerchantIntegrationList();
    this.props.getMerchantProfile();
    this.visitorTimer = setInterval(
      () => {
        this.props.getActiveVisitorList(true, false);
        // if (sessionRole() === 'PHYSICIAN') {
        //   this.props.getActiveVisitorList(true, false);
        // }
        // else {
        //   this.props.getMessageList();
        // }
      },
      window.location.href.includes("localhost") ? 15000 : 15000
    ); // localhost ? 25 mins : 15 sec
  }

  // manualCheck() {
  //   if (sessionRole() === 'PHYSICIAN') {
  //     this.props.getActiveVisitorList(true, false);
  //   }
  //   else {
  //     this.props.getMessageList();
  //   }
  // }

  setRefreshTokenInterval() {
    if (this.refreshTokenTimer) {
      clearTimeout(this.refreshTokenTimer);
      this.refreshTokenTimer = null;
    }

    const expiresTime = this.getExpiresTime();
    if (!expiresTime) return false;

    this.refreshTokenTimer = setTimeout(() => {
      this.refreshToken();
    }, expiresTime * 1000);
  }

  getExpiresTime() {
    const expires_at = parseFloat(sessionExpiresAt() || "0");
    if (!expires_at || isNaN(expires_at)) return false;
    let expires_in = expires_at - Math.floor(Date.now() / 1000);
    if (expires_in && expires_in > 203) {
      expires_in = expires_in - 200;
      return expires_in;
    } else {
      this.refreshToken();
      return false;
    }
  }

  getLogoUrl() {
    const { merchantAttr } = this.props;
    const defaultLogo = BRAND_LOGO_URL;
    if (merchantAttr.data && merchantAttr.data.images && merchantAttr.data.images.img1) {
      return merchantAttr.data.images.img1;
    } else return defaultLogo;
  }

  refreshToken() {
    CommonUtils.refreshToken().then(response => {
      if (response.error || !response.access_token) {
        if (this.refreshTokenTimer) {
          clearTimeout(this.refreshTokenTimer);
          this.refreshTokenTimer = null;
        }
        this.handleLogout();
      }

      if (response.access_token) sessionStorage.setItem("access_token", response.access_token);
      if (response.expires_in)
        sessionStorage.setItem("expires_at", Math.floor(Date.now() / 1000) + response.expires_in);
      if (response.refresh_token) sessionStorage.setItem("refresh_token", response.refresh_token);
      this.setRefreshTokenInterval();
    });
  }

  componentWillUnmount() {
    if (this.visitorTimer) {
      clearInterval(this.visitorTimer);
      this.visitorTimer = null;
    }
    if (this.refreshTokenTimer) {
      clearTimeout(this.refreshTokenTimer);
      this.refreshTokenTimer = null;
    }
    if (this.updateAgentInfo) {
      clearInterval(this.updateAgentInfo);
      this.updateAgentInfo = null;
    }
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
  }

  sidebarMinimize(e) {
    e.preventDefault();
    let el = document.body.getElementsByClassName("show");
    if (el.length > 0) el[0].classList.remove("show");
    document.body.classList.toggle("sidebar-minimized");
    this.props.setSidebarOpen(document.body.classList.contains("sidebar-minimized"));
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
    this.props.setSidebarOpen(!document.body.classList.contains("sidebar-mobile-show"));
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  }

  goToVisitor() {
    this.props.setTabIndex("online-visitors");
    this.props.navigate("/visitors/online-visitors");
  }

  goToLiveAgents() {
    this.props.setTabIndex("userAgent", { activeTab: "userAgent" });
    this.props.navigate("/visitors/teams");
  }

  handleProgressWizard() {
    this.props.handlePopup({
      showModal: true,
      component: this.renderProgressWizard(),
      size: "lg",
      modalClass: "modal-progress-wizard",
    });
  }

  renderProgressWizard() {
    const { totalSteps, currentStep } = this.state;
    return (
      <div className="progress-wizard">
        <span className="btn-close" onClick={() => this.props.handlePopup({ showModal: false })}>
          ×
        </span>
        <div className="head">
          <div className="progress-box">
            <CircularProgressBar
              value={(currentStep / totalSteps) * 100}
              styles={{
                path: {
                  // Path color
                  stroke: `#76b75d`,
                },
                text: {
                  // Text color
                  fill: "#666",
                },
              }}
            />
          </div>
          <div className="head-box">
            <div className="title">Setup Progress</div>
            <div className="desc">You're on your way!</div>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="content-box">
          <div className="wizard-box">
            {this.state.userAssist.map(data => (
              <div className={`w-box ${data.status == "completed" ? "done" : ""}`}>
                <div className="circle">
                  {data.status == "completed" ? (
                    <i className="fa fa-check"></i>
                  ) : data.icon ? (
                    <img src={data.icon} />
                  ) : (
                    <i className="fa fa-exclamation-triangle"></i>
                  )}
                </div>
                <NavLink className="title">{data.task}</NavLink>
                <div className="desc">{data.task_desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  onIdle(e) {
    this.setState({ showTimeoutBox: true });
    this.logoutTimer = setTimeout(
      () => {
        this.handleLogout();
      },
      1000 * 60 * 1
    );
  }

  handleLogout() {
    sessionClear();
    this.props.handlePopup();
    logoutApp(this.props.navigate);
  }

  handleStayLoggedIn() {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
    this.idleTimer.reset();
    this.setState({ showTimeoutBox: false });
  }

  toggleKnowledgeHub() {
    this.setState({ showKnowledgeHub: !this.state.showKnowledgeHub });
  }

  render() {
    const { userAssist, currentStep, totalSteps, timeout, showTimeoutBox, showKnowledgeHub, stayAliveKey } = this.state;
    const { merchantAttr, agentMetrics, liveAgents } = this.props;
    const enableGenerativeAI = get(merchantAttr, "data.customConfig.enableGenerativeAI", null);
    const updatedVisitors = [];
    const logoUrl = this.getLogoUrl();
    this.props.activeVisitors.list.map(a => {
      if (updatedVisitors.findIndex(x => x.referenceID == a.referenceID) === -1) updatedVisitors.push(a);
    });

    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={e => this.mobileSidebarToggle(e)}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
        <NavbarBrand href="">
          {logoUrl && <img src={optimizeCloudinaryImage(logoUrl, 155)} className="navbar-logo-image" />}
        </NavbarBrand>
        <div
          className={`nav-item-left ${false && !merchantAttr.fetching && userAssist.length > 0 && currentStep !== totalSteps ? "mr0" : ""}`}
        >
          <NavbarToggler className="d-md-down-none" onClick={e => this.sidebarMinimize(e)}>
            <span className="navbar-toggler-icon"></span>
          </NavbarToggler>
          <Breadcrumb />
        </div>

        {false && !merchantAttr.fetching && userAssist.length > 0 && currentStep !== totalSteps && (
          <div className="progress-wizard-highlight" onClick={() => this.handleProgressWizard()}>
            <img src="img/studio/agent-designer.svg" />
            <span className="title">
              You have completed{" "}
              <span className="counter">
                {currentStep}/{totalSteps}
              </span>{" "}
              steps<span className="progress-bar" style={{ width: `${(currentStep / totalSteps) * 100}%` }}></span>
            </span>
          </div>
        )}

        {/* {window.location.href.includes("localhost") && (
          <input type="button" value="check" onClick={() => this.manualCheck()}></input>
        )} */}

        <Nav className="ml-auto ml0" navbar>
          {enableGenerativeAI && (
            <NavItem>
              <NavLink title="Knowledge Hub" onClick={() => this.toggleKnowledgeHub()}>
                <img height="42" src="/img/ai.gif" />
              </NavLink>
            </NavItem>
          )}
          {sessionRole() === "ADMIN" && this.tabObj[MENU_TYPE.visitorTabOnlineVisitor] && (
            <NavItem className="px-2">
              <NavLink title="Online Visitor" onClick={() => this.goToVisitor()}>
                <img height="38" src="/img/Users.svg" />
                {updatedVisitors.length > 0 && (
                  <Badge pill color="danger">
                    {updatedVisitors.length}
                  </Badge>
                )}
              </NavLink>
            </NavItem>
          )}
          {sessionRole() !== "PHYSICIAN" && sessionRole() !== "CALLCENTER_USER2" && sessionRole() !== "M_USER" && (
            <React.Fragment>
              {this.tabObj[MENU_TYPE.visitorTabUserAgent] && (
                <NavItem className="px-2">
                  <NavLink title="Live Agent" onClick={() => this.goToLiveAgents()}>
                    <img height="38" src="/img/Agent.svg" />
                    {liveAgents.list.length > 0 && (
                      <Badge pill color="orange">
                        {liveAgents.list.length}
                      </Badge>
                    )}
                  </NavLink>
                </NavItem>
              )}
              <HeaderDropdown {...this.props} mssgs />
            </React.Fragment>
          )}
          <HeaderDropdown
            {...this.props}
            key={stayAliveKey}
            handleStayAlive={() => {
              if (sessionStayAlive()) this.idleTimer.pause();
              else this.idleTimer.reset();
              this.setState({ stayAliveKey: Math.random() });
            }}
            accnt
          />
        </Nav>

        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          stopOnIdle={true}
          startManually={true}
          onIdle={this.onIdle}
          debounce={250}
          timeout={timeout}
        />

        {showTimeoutBox && <TimeoutBox handleClick={this.handleStayLoggedIn} />}
        <TimeoutBox isUnauth={true} handleClick={this.handleLogout} />
        {showKnowledgeHub && <KnowledgeHub handleClose={() => this.toggleKnowledgeHub()} />}
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    assignedList: state.common.messages.assignedList,
    activeVisitors: state.common.activeVisitors,
    merchantAttr: state.common.merchantAttr,
    merchantProfile: state.common.merchantProfile,
    agentMetrics: state.common.agentMetrics,
    liveAgents: state.common.liveAgents,
  };
};

const mapDispatchToProps = {
  logoutUser,
  setTabIndex,
  getMessageList,
  getResponseReviewMessages,
  getActiveVisitorList,
  getAgentTeamList,
  handlePopup,
  getMerchantAttr,
  getAgentMetrics,
  getLiveAgents,
  getMerchantProfile,
  getRolePermission,
  getMerchantIntegrationList,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
