import React, { useState, useRef, useEffect, memo } from "react";
import PropTypes from "prop-types";

const LoadingBox = props => {
  const { minHeight, autoHeight, list, loading, waitMsg, noRecMsg, noBox, boxClassName, imgSrc, noImg, renderAction } =
    props;

  const boxEl = useRef(null);
  const [boxElHeight, setBoxElHeight] = useState(minHeight);

  useEffect(() => {
    if (autoHeight && boxEl && boxEl.current)
      setBoxElHeight(window.innerHeight - boxEl.current.getBoundingClientRect().top - props.offset - 50);
  }, [boxEl]);

  return (
    (loading || (list && list.length == 0)) && (
      <div
        className={`d-flex justify-content-center${noBox ? "" : " m-box"}${boxClassName ? ` ${boxClassName}` : ""}`}
        style={{ height: boxElHeight, minHeight }}
        ref={boxEl}
      >
        {loading ? (
          <div className="rb-no-record">{waitMsg}</div>
        ) : (
          <div className="rb-no-record">
            {!noImg && <img src={imgSrc}></img>}
            <span className="mt10">{noRecMsg}</span>
            {renderAction && renderAction()}
          </div>
        )}
      </div>
    )
  );
};

LoadingBox.defaultProps = {
  list: [],
  loading: false,
  autoHeight: true,
  minHeight: 300,
  waitMsg: "Please wait while fetching records...",
  noRecMsg: "No records found",
  noBox: false,
  noImg: false,
  boxClassName: "",
  imgSrc: "/img/v1/common/no_data.svg",
  offset: 0,
};

LoadingBox.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  autoHeight: PropTypes.bool,
  minHeight: PropTypes.number,
  waitMsg: PropTypes.string,
  noRecMsg: PropTypes.string,
  noBox: PropTypes.bool,
  noImg: PropTypes.bool,
  boxClassName: PropTypes.string,
  imgSrc: PropTypes.string,
  offset: PropTypes.number,
};

export default memo(LoadingBox);
