import React, { Fragment, useEffect, useState } from "react";
import { Button, Input, PageHead, Select } from "../../../../common/components";
import CommonUtils from "../../../../utils/CommonUtils.js";
import { Col, Label, Row } from "reactstrap";
import { sessionMerchantId } from "../../../../utils/SessionUtils";
import { getUtcDate, isArray } from "../../../../utils/GeneralUtils";
import LoadingBox from "../../../../common/v1/LoadingBox.js";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { handlePopup } from "../../../../actions";
import { withRouter } from "../../../../withRouter.js";

const FeatureEngineering = props => {
  const dispatch = useDispatch();

  const { modelId } = props.match.params;
  const [dataAttributesList, setDataAttributesList] = useState([]);
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);

  const [dataset, setDataset] = useState(null);

  const [featuresMappingList, setFeaturesMappingList] = useState([{ feature: "", dataAttribute: "" }]);

  const [featuresList, setFeaturesList] = useState([]);
  const [modelLoading, setModelLoading] = useState(false);

  const [datasetList, setDatasetList] = useState([]);
  const [datasetLoading, setDatasetLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setModelLoading(true);
      const request = {
        merchantId: sessionMerchantId(),
        modelId: modelId,
      };
      const res = await CommonUtils.getModelList(request);
      if (res.error || !isArray(res.models)) {
        setModel(null);
        setFeaturesList([]);
        return false;
      }
      setModel(res.models[0]);

      const features = isArray(res.models[0].features)
        ? res.models[0].features.map(d => ({ label: d.feature, value: d.feature }))
        : [];
      setFeaturesList(features);

      const mapping = get(res.models[0], "featureEngineering.featureDataSetMapping", null);
      if (mapping) {
        const mappingList = [];
        mapping.map(m => {
          Object.keys(m).map(x => mappingList.push({ feature: x, dataAttribute: m[x] }));
        });
        setFeaturesMappingList(mappingList.length ? mappingList : [{ feature: "", dataAttribute: "" }]);
      }

      setDataset(get(res.models[0], "featureEngineering.dataset", null));
      setTimeout(() => {
        setModelLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        merchantId: sessionMerchantId(),
        dataSource: "Dataset",
      };
      const res = await CommonUtils.getDocuments(params);
      setDatasetLoading(false);
      if (res.error) {
        setDatasetList([]);
        return false;
      }
      const dataSets = res.documents.map(d => ({
        label: d.documentName,
        value: d.documentId,
        dataAttributes: d.dataAttributes || [],
      }));
      setDatasetList(dataSets);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const attributes = get(
      datasetList.find(x => x.value == dataset),
      "dataAttributes",
      []
    ).map(x => ({
      label: x.attribute,
      value: x.attribute,
    }));
    setDataAttributesList(attributes);
    setFeaturesMappingList(prev =>
      prev.map(x => {
        if (!modelLoading) {
          x.dataAttribute = "";
        }
        return x;
      })
    );
  }, [dataset, JSON.stringify(datasetList)]);

  const onChangeFeature = (name, value, index) => {
    const newList = [...featuresMappingList];
    newList[index] = { ...newList[index], [name]: value };
    setFeaturesMappingList(newList);
  };
  const addFeature = () => {
    const newList = [...featuresMappingList, { feature: "", dataAttribute: "" }];
    setFeaturesMappingList(newList);
  };

  const removeFeature = index => {
    const filteredList = featuresMappingList.filter((_, i) => i !== index);
    setFeaturesMappingList(filteredList);
  };

  const handleSubmit = async () => {
    const request = Object.assign({}, model, {
      modifiedDate: getUtcDate(),
      featureEngineering: {
        dataset,
        featureDataSetMapping: featuresMappingList
          .filter(x => x.feature && x.dataAttribute)
          .map(x => ({ [x.feature]: x.dataAttribute })),
      },
    });
    setLoading(true);
    const result = await CommonUtils.updateModel(model.modelId, request);

    setLoading(false);
    if (result.responseCode === 500) {
      dispatch(handlePopup({ isError: true }));
      return false;
    }

    dispatch(
      handlePopup({
        isSuccess: true,
        message: `Model has been updated successfully.`,
      })
    );
    props.navigate("/model-studio/ml-model/");
  };

  return (
    <div>
      <PageHead
        title="Feature Engineering"
        desc="Enhance your data's predictive power in our Feature Engineering section. Create new features from existing data, select relevant attributes, and transform data to improve model performance. Seamlessly integrate feature selection and extraction techniques, customize preprocessing steps, and track changes. Ensure data quality and boost model accuracy before deploying for real-world applications."
        rightSec={
          model && (
            <React.Fragment>
              <Button submitBtn className="btn-v1 mr-2" text="Submit" loading={loading} onClickFunc={handleSubmit} />
              <Button
                cancelBtn
                color="danger"
                className="btn-v1"
                text="Cancel"
                onClickFunc={() => props.navigate("/model-studio/ml-model/")}
              />
            </React.Fragment>
          )
        }
      />
      {model ? (
        <div className="m-box">
          <Row>
            <Col md="2" className="text-right">
              <Label>Model Name</Label>
            </Col>
            <Col md="3">
              <Input value={model.modelName} disabled />
            </Col>
          </Row>
          <Row>
            <Col md="2" className="text-right">
              <Label>Version</Label>
            </Col>
            <Col md="3">
              <Input value={model.modelVersion} disabled />
            </Col>
          </Row>
          <Row>
            <Col md="2" className="text-right">
              <Label>Dataset</Label>
            </Col>
            <Col md="3">
              <Select
                title="Dataset"
                name="dataSets"
                placeholder="Select Dataset"
                value={dataset}
                options={datasetList}
                isLoading={datasetLoading}
                onChangeFunc={(_, value) => setDataset(value)}
                outerClassName="w-100 mb-2"
              />
            </Col>
          </Row>
          <Row className="mt-1 mb-2">
            <Col md="2" className="text-right">
              <Label>Ontology Mapping</Label>
            </Col>
            <Col md="3">
              <Button submitBtn displayIcon={false} className="btn-v1" text="Use LLM" onClickFunc={() => {}} />
            </Col>
          </Row>

          <Row className="mt-1">
            <Col md="2" className="text-right">
              {" "}
            </Col>
            <Col md="10" style={{ maxWidth: 800 }}>
              <div>
                <Label className="fw-semibold" style={{ width: "50%" }}>
                  Features
                </Label>
                <Label className="fw-semibold" style={{ marginLeft: -25 }}>
                  Data Attributes
                </Label>
              </div>
              {isArray(featuresMappingList) &&
                featuresMappingList.map((obj, index) => {
                  return (
                    <Fragment>
                      <div className="attribute-box d-flex">
                        <div className="d-flex w-100" style={{ gap: 20 }}>
                          <Select
                            title="Feature"
                            name="feature"
                            placeholder="Select Feature"
                            value={obj.feature}
                            options={featuresList}
                            onChangeFunc={(name, value) => {
                              onChangeFeature(name, value, index);
                            }}
                            outerWidth="50%"
                            isClearable={false}
                          />
                          <Select
                            title="Data Attribute"
                            name="dataAttribute"
                            placeholder="Select Data Attribute"
                            value={obj.dataAttribute}
                            options={dataAttributesList}
                            onChangeFunc={(name, value) => {
                              onChangeFeature(name, value, index);
                            }}
                            outerWidth="50%"
                            isClearable={false}
                          />
                        </div>
                        <div className="d-flex align-items-center attributes-actions">
                          {index === featuresMappingList.length - 1 ? (
                            <Button plusBtn className="ml10" onClickFunc={addFeature} />
                          ) : null}
                          {index !== 0 || featuresMappingList.length > 1 ? (
                            <Button minusBtn className="ml10" onClickFunc={() => removeFeature(index)} />
                          ) : null}
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </Col>
          </Row>
        </div>
      ) : (
        <LoadingBox loading={modelLoading} />
      )}
    </div>
  );
};

export default withRouter(FeatureEngineering);
