import React from "react";
import PropTypes from "prop-types";
import { Select, Button } from "../../../../common/components";

class SelectAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAttr: false,
    };
  }

  setAttribute(target, messageText, value, isRich, richPosition) {
    let msg = `${messageText || ""}${value}`;
    if (target && target.selectionStart != null && target.selectionStart != undefined) {
      let index = target.selectionStart;
      msg = `${(messageText || "").substr(0, index)}${value}${(messageText || "").substr(index)}`;
    }
    // if (!!isRich) {
    //   let index = richPosition;
    //   msg = `${(messageText || '').substr(0, index)}${value}${(messageText || '').substr(index)}`;
    // }
    return msg;
  }

  render() {
    const { openAttr } = this.state;
    const { className, customIcon } = this.props;

    const { onChangeFunc, setAttribute, externalAttributes, attributeList, outerAttriClass, inputRef } = this.props;
    return (
      <div className={outerAttriClass}>
        <Button
          customIcon={customIcon ? customIcon : openAttr ? "fa-minus-circle" : "fa-bolt"}
          className={`select-attr-btn ${className}`}
          style={{ color: openAttr ? "#666" : "#20a8d8" }}
          onClickFunc={e => {
            this.setState({ openAttr: !openAttr });
          }}
        />
        {openAttr && (
          <Select
            placeholder="Search..."
            name="attributeList"
            options={[...externalAttributes, ...attributeList]}
            outerClassName="select-attr"
            menuPlacement="bottom"
            onChangeFunc={(name, value) => {
              value = this.setAttribute(setAttribute[0], setAttribute[1], value, setAttribute[2], setAttribute[3]);
              onChangeFunc(value, inputRef ? inputRef.current.name : null);
              this.setState({ openAttr: false });
            }}
            menuIsOpen
          />
        )}
      </div>
    );
  }
}

SelectAttribute.defaultProps = {
  externalAttributes: [],
  attributeList: [],
  setAttribute: [],
};

SelectAttribute.propTypes = {
  externalAttributes: PropTypes.array,
  attributeList: PropTypes.array,
};

export default SelectAttribute;
