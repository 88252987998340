import CommonUtils from "./../utils/CommonUtils";
import { getAllQueryParams, getBrowserType, getCreatedBy, getDeviceName, getUtcDate, getVersion } from "./GeneralUtils";
import {
  sessionFirstName,
  sessionLastName,
  sessionMerchantId,
  sessionUser,
  sessionUserId,
  sessionUserTemp,
} from "./SessionUtils";

export const getMerchantDetails = async (response, userName) => {
  let merchantRes = response;
  let merchantUsername = userName;
  const res = await CommonUtils.getMerchantDetails();
  if (res.error) {
    return false;
  }
  if (res.merchant && res.merchant.contacts && res.merchant.contacts.length > 0) {
    merchantRes.firstName = merchantRes.firstName;
    merchantRes.lastName = merchantRes.lastName;
  }
  if (res.merchant && res.merchant.type) sessionStorage.setItem("businessType", res.merchant.type || "");
  if (res.merchant && res.merchant.timeZone) sessionStorage.setItem("timeZone", res.merchant.timeZone || "");
  if (res.merchant && res.merchant.settings)
    if (localStorage) localStorage.setItem("wdb", res.merchant.settings.widgetDisableBranding ? "1" : "0");
  return { merchantRes, merchantUsername };
};

export const updateUserAgent = async obj => {
  const {
    firstName = sessionFirstName(),
    lastName = sessionLastName(),
    merchantID = sessionMerchantId(),
    id = sessionUserId(),
    loginStatus = "Active",
    email = sessionUser(),
  } = obj || {};
  const data = {
    firstName,
    lastName,
    merchantID,
    id,
    // department: '',
    loginStatus,
    email,
  };
  await CommonUtils.updateUserAgent(data);
};

export const createUserAgents = async (response, userName) => {
  let obj = {
    channel: "Web",
    createdBy: getCreatedBy(),
    department: "Management",
    device: `${getBrowserType()} - ${getDeviceName()}`,
    email: userName,
    firstName: response.firstName,
    ip_Address: "",
    languages: response.languages,
    languageCodes: response.languageCodes,
    lastLoginDt: getUtcDate(),
    lastName: response.lastName,
    loginStatus: "login",
    logoutDt: "",
    merchantID: response.merchant.merchantId,
    version: getVersion(),
  };
  // const res = await CommonUtils.getIPAddress();
  // if (res && res.ip) {
  //   obj.ip_Address = res.ip;
  // }
  await CommonUtils.createUserAgents(obj);
  await updateUserAgent({ email: userName || sessionUser() });
};

export const redirectToAccount = props => {
  const isTempUserId = sessionUserTemp();
  if (isTempUserId) {
    sessionStorage.setItem("saveUser", isTempUserId);
    sessionStorage.removeItem("saveUserTemp");
  }
  let params = getAllQueryParams(props.location.search);
  if (params.redirect === "unsubscribe") {
    sessionStorage.setItem("tabId", "unsubscribe");
    props.navigate("/settings");
  } else {
    props.navigate("/");
  }
};
