import React, { useState, useCallback, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { TextArea, ScrollBox, Button, Select } from "../../../../common/components";
import CommonUtils from "../../../../utils/CommonUtils";
import ElementForm from "./ElementForm";
import { getValidateJs } from "../../../../utils/BotUtils";
import { get } from "lodash";
import Workbench from "./Workbench";

const ActionJavaScript = forwardRef((props, ref) => {
  const { attributeList, prevElementStructure, lang } = props;
  const [activeTab, setActiveTab] = useState("input");
  const [entityList, setEntityList] = useState([]);

  const [entity, setEntity] = useState(null);

  const [scriptText, setScriptText] = useState("");
  const [scriptTextTarget, setScriptTextTarget] = useState(0);
  const [jsonStructure, setJsonStructure] = useState([]);

  const [jsonResult, setJsonResult] = useState("");
  const [outputResult, setOutputResult] = useState("");

  const [isTestloading, setIsTestloading] = useState(false);

  const [showWorkBench, setShowWorkBench] = useState(false);
  const [workBenchSchema, setWorkBenchSchema] = useState("");

  useEffect(() => {
    const getEntityList = async () => {
      const response = await CommonUtils.getEntityByMerchantId();
      if (response.error) {
        setEntityList([]);
        return false;
      }
      if (response && response.entities) {
        const list = response.entities.map(e => {
          return {
            entityId: e.entityId,
            entries: e.entries,
            name: e.name,
          };
        });
        if (entity) {
          onChangeEntity(
            entity,
            list.find(x => x.entityId == entity)
          );
        }
        setEntityList(list);
      }
    };
    getEntityList();
  }, []);

  useEffect(() => {
    if (props.value) {
      setScriptText(props.value.scriptText || "");
      onChangeEntity(
        props.value.entity,
        entityList.find(x => x.entityId == props.value.entity)
      );
    } else {
      setScriptText("");
      setEntity("");
    }
  }, [JSON.stringify(props.value)]);

  useEffect(() => {
    setJsonResult(props.dataParam || "");
  }, [JSON.stringify(props.dataParam)]);

  useEffect(() => {
    if (workBenchSchema) {
      handleVerify();
    }
  }, [workBenchSchema]);

  const onChangeEntity = useCallback((value, obj) => {
    setEntity(value || "");
  }, []);

  const handleVerify = async () => {
    setIsTestloading(true);
    const customConfig = get(props, "merchantAttr.data.customConfig");
    const res = await getValidateJs(scriptText, jsonResult, entity, [], customConfig);
    toggle("output");
    setJsonStructure(res.jsonStructure);
    setOutputResult(res.jsonResult);
    setIsTestloading(false);
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getData = () => {
    if (!scriptText.trim()) return { skip: true };

    return {
      data: {
        scriptText,
        entity,
      },
      jsonStructure,
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const closeWorkBench = () => {
    setShowWorkBench(false);
  };

  const updateSchemaJson = schema => {
    setScriptText(schema);
    setWorkBenchSchema(schema);
    closeWorkBench();
  };

  const workbenchProps = {
    closeWorkBench: closeWorkBench,
    updateSchemaJson: updateSchemaJson,
    type: "javascript",
    code: jsonResult,
    schema: scriptText,
    entity,
  };

  return (
    <div className={`ele-content ${lang.className}`}>
      <ScrollBox scrollClass="bt-javascript" boxHeight={400}>
        <Col>
          <Row>
            <Col md="8">
              <div className="b-form mt10">
                <ElementForm title="Entity">
                  <Select
                    options={entityList}
                    name="entity"
                    value={entity}
                    labelKey="name"
                    valueKey="entityId"
                    outerWidth={300}
                    outerClassName="mb0"
                    onChangeFunc={(name, value, error, obj) => onChangeEntity(value, obj)}
                  />
                </ElementForm>
                <ElementForm rowClassName="js-function" title="JavaScript">
                  <label>
                    {`function myFunction(data${entity ? ", entity" : ""})`}
                    {" {"}
                  </label>
                  <div className="position-relative">
                    <TextArea
                      value={scriptText}
                      rows={8}
                      title="JavaScript Code"
                      placeholder="Write your javascript code..."
                      name="scriptText"
                      // isReq={true}
                      // error={errors.scriptText}
                      // appendIcon={<SelectAttribute
                      //   outerAttriClass="text-area-icon"
                      //   attributeList={attributeList}
                      //   onChangeFunc={(value, inputName) => this.setState({ scriptText: value }, () => { this.verifyScript(), this.onInputValidate(inputName, null) })}
                      //   setAttribute={[scriptTextTarget, scriptText]}
                      // />}
                      // validationFunc={this.onInputValidate}
                      // onChangeFunc={(name, value, e) => this.setState({ [name]: value, scriptTextTarget: e.target }, () => this.verifyScript())}
                      onChangeFunc={(name, value, e) => {
                        setScriptText(value);
                        setScriptTextTarget(e.target);
                      }}
                    />
                  </div>
                  <label>{"}"}</label>
                </ElementForm>
                <ElementForm title="">
                  <Button submitBtn loading={isTestloading} text="Test" onClickFunc={handleVerify} />
                  <Button
                    submitBtn
                    className="ml10"
                    text="Workbench"
                    displayIcon={false}
                    onClickFunc={() => setShowWorkBench(true)}
                  />
                </ElementForm>
              </div>
            </Col>
            <Col md="4">
              <div className="display-head mt-0">
                <Nav tab className="nav-tab">
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === "input" })} onClick={() => toggle("input")}>
                      Input
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "output" })}
                      onClick={() => toggle("output")}
                    >
                      Output
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="input">
                    <TextArea
                      rows={14}
                      value={typeof jsonResult === "string" ? jsonResult : JSON.stringify(jsonResult, null, 2)}
                    />
                  </TabPane>
                  <TabPane tabId="output">
                    <TextArea
                      rows={14}
                      value={typeof outputResult === "string" ? outputResult : JSON.stringify(outputResult, null, 2)}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Col>
      </ScrollBox>
      {showWorkBench && <Workbench {...workbenchProps} />}
    </div>
  );
});

ActionJavaScript.defaultProps = {
  lang: {
    className: "",
  },
  dataParam: "",
};

ActionJavaScript.propTypes = {
  lang: PropTypes.object,
  dataParam: PropTypes.any,
};

export default ActionJavaScript;
