export const SET_TAB_INDEX = "SET_TAB_INDEX";
export const SET_MESSAGE_LIST = "SET_MESSAGE_LIST";
export const SET_RESPONSE_REVIEW_LIST = "SET_RESPONSE_REVIEW_LIST";
export const SET_RAW_VISITOR_LIST = "SET_RAW_VISITOR_LIST";
export const SET_RAW_VISITOR_REFERENCE_LIST = "SET_RAW_VISITOR_REFERENCE_LIST";
export const SET_RAW_VISITOR_PAGINATION_LIST = "SET_RAW_VISITOR_PAGINATION_LIST";
export const SET_RAW_VISITOR_EXPORT_CSV = "SET_RAW_VISITOR_EXPORT_CSV";
export const MODAL_POPUP = "MODAL_POPUP";
export const SET_ACTIVE_VISITOR_LIST = "SET_ACTIVE_VISITOR_LIST";
export const SET_ACTIVE_LIVE_VISITOR_LIST = "SET_ACTIVE_LIVE_VISITOR_LIST";
export const SET_VISITOR_LIST = "SET_VISITOR_LIST";
export const SET_MERCHANT_ATTR = "SET_MERCHANT_ATTR";
export const SET_MERCHANT_INTEGRATION_LIST = "SET_MERCHANT_INTEGRATION_LIST";
export const SET_FETCHING_MERCHANT_INTEGRATION_LIST = "SET_FETCHING_MERCHANT_INTEGRATION_LIST";
export const SET_CURRENT_INTEGRATION_DETAIL = "SET_CURRENT_INTEGRATION_DETAIL";
export const SET_INTEGRATION_API_DETAIL = "SET_INTEGRATION_API_DETAIL";
export const SET_TASK_TYPE = "SET_TASK_TYPE";
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const SET_MERCHANT_PROFILE = "SET_MERCHANT_PROFILE";
export const SET_AGENT_METRICS = "SET_AGENT_METRICS";
export const SET_LIVE_AGENTS = "SET_LIVE_AGENTS";
export const SET_AGENT_TEAM_LIST = "SET_AGENT_TEAM_LIST";
export const SET_ACTIVE_AGENT_LIST = "SET_ACTIVE_AGENT_LIST";
export const SET_ROLE_PERMISSION_LIST = "SET_ROLE_PERMISSION_LIST";
export const SET_CHAT_LIST = "SET_CHAT_LIST";
export const REMOVE_CHAT_LIST = "REMOVE_CHAT_LIST";
export const SET_CHAT_MESSAGE_LIST = "SET_CHAT_MESSAGE_LIST";
export const SET_BOT_ELEMENTS = "SET_BOT_ELEMENTS";
