import React from "react";
import { Select } from "../../../../common/components";

class SelectIntent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.checkIntent = this.checkIntent.bind(this);
  }

  checkIntent(name, value) {
    const { boxes, onChangeFunc, botElementId } = this.props;
    if (boxes && value) {
      let usedIntentBox = null;
      value.split(",").map(x => {
        usedIntentBox = boxes.find(
          b => b.id !== botElementId && b.payload.intent && b.payload.intent.split(",").includes(x)
        );
      });
      if (usedIntentBox) {
        let error = `${usedIntentBox.payload.intent} is already used ${usedIntentBox.typeData.label ? `in ${usedIntentBox.typeData.label}` : ""}`;
        this.setState({ error });
        return;
      }
    }
    this.setState({ error: null });
    onChangeFunc(name, value);
  }

  render() {
    const { name, placeholder, value, outerClassName, outerWidth, onChangeFunc, intentList, lang } = this.props;
    const { error } = this.state;
    return (
      <div className={lang.className}>
        <label>
          Simply select the intentions or intents defined in your knowledge and that intent(s) would trigger this node.
        </label>
        <Select
          placeholder={placeholder}
          outerClassName={`${outerClassName} mb5`}
          outerWidth={outerWidth}
          value={value}
          name={name}
          options={intentList}
          labelKey="intent"
          valueKey="intent"
          onChangeFunc={this.checkIntent}
          isMulti
        />
        {error && <span className="req-msg">{error}</span>}
      </div>
    );
  }
}

SelectIntent.defaultProps = {
  name: "intent",
  placeholder: "Select Intent",
  outerWidth: 530,
  outerClassName: "mt10",
  defaultSelected: true,
  lang: {
    className: "",
  },
};

export default SelectIntent;
