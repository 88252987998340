import React from "react";
import { Row, Col } from "reactstrap";
import { Button, TextArea, Select, Input, ScrollBox, TextEditor } from "../../../common/components";
import ImageUpload from "../../../common/components/ImageUpload";
import { getRandomNumber, isArray, addErrorObj } from "../../../utils/GeneralUtils";
import CommonUtils from "../../../utils/CommonUtils";
import {
  getAttrByPrevElement,
  getBotIntent,
  loadBotPayload,
  loadDefaultBotPayload,
  updateBotPayload,
} from "../../../utils/BotUtils";
import {
  SubmitElement,
  SelectElement,
  SelectIntent,
  SelectAttribute,
  ElementForm,
  AdvanceOptionsExpand,
} from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { actionList, BOT_ATTRIBUTES } from "../../../utils/ConstUtils";
import { cloneDeep, keys, uniqBy } from "lodash";

const initOptions = (id = getRandomNumber(6)) => ({
  id,
  value: "",
  title: "",
  type: "",
  attributes: [{ attributeKey: "", attributeValue: "" }],
  errors: {
    title: null,
    value: null,
  },
});

const initAttributes = () => [
  { attributeKey: "", attributeValue: "", errors: { attributeKey: null, attributeValue: null } },
];

const LANG_STRUCTURE = {
  text: "",
  help_text: "",
  carouselList: [],
  category: "",
  subCategory: "",
  dynamicCarousel: {
    buttons: [],
    productAttributes: [],
  },
};

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waTemplate: false,
      legacy_message: false,
      activity: false,
      help_text: "",
      // isDynamic: false,
      text: "",
      isRich: false,
      textTarget: 0,
      source: "static-card",
      entityList: [],
      entity: null,
      carouselListKey: Date.now(),
      carouselList: [],
      dynamicCarousel: {
        image: "",
        title: "",
        titleTarget: null,
        description: "",
        descriptionTarget: null,
        currency: "",
        currencyTarget: null,
        price: "",
        priceTarget: null,
        buttons: [],
        productAttributes: [],
        errors: {
          title: null,
          description: null,
        },
      },
      subScreen: false,
      subScreenAttri: false,
      selectedAction: null,
      listIndex: 0,
      productAttriListIndex: 0,
      buttonList: [],
      productAttriList: [],
      intent: null,
      nextIntent: "",
      step: 1,
      externalAttributes: [],
      prevElementStructure: [],
      categoryList: [],
      subCategoryList: [],
      langPayload: loadDefaultBotPayload(LANG_STRUCTURE, props.lang),
    };

    this.sourceList = [
      { label: "Custom Catalog", value: "static-card" },
      { label: "Dynamic Catalog", value: "dynamic-entity" },
      { label: "Product Catalog", value: "catalog" },
    ];
    this.products = [];
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.onAdvanceOptionChange = this.onAdvanceOptionChange.bind(this);
  }

  componentDidMount() {
    this.getEntityList();
    const { boxes, botElementId, lang } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      let {
        text,
        carousel,
        intent,
        source,
        entity,
        isDynamic,
        isRich,
        nextIntent,
        waTemplate = false,
        legacy_message = false,
        activity = false,
        help_text = "",
        category,
        subCategory,
      } = box.payload;
      const errors = { title: null, description: null };
      carousel = addErrorObj(carousel, errors);

      let carouselList = cloneDeep(this.state.carouselList);
      if (source === "static-card") {
        carouselList = cloneDeep(carousel);
        carouselList.map(o => {
          o.buttons.map(b => {
            if (!b.attributes) {
              b.attributes = [{ attributeKey: "", attributeValue: "" }];
            }
          });
        });
      }

      let dynamicCarousel = cloneDeep(this.state.dynamicCarousel);
      if (source === "dynamic-entity") {
        dynamicCarousel = cloneDeep(carousel)[0];
        dynamicCarousel.buttons.map(b => {
          if (!b.attributes) {
            b.attributes = [{ attributeKey: "", attributeValue: "" }];
          }
        });
        if (dynamicCarousel.productAttributes.length === 0) {
          dynamicCarousel.productAttributes = [{ attributeKey: "", attributeValue: "" }];
        }
      }
      if (source === "catalog") {
        this.getProducts();
      }
      this.setState(
        {
          text,
          carouselList,
          isRich,
          intent,
          nextIntent,
          source: source || this.state.source,
          entity: entity,
          dynamicCarousel,
          // isDynamic: isDynamic ? true : false,
          waTemplate,
          legacy_message,
          activity,
          help_text,
          category,
          subCategory,
        },
        () => this.setState({ ...loadBotPayload(LANG_STRUCTURE, box.payload, this.state, lang) })
      );
    }
  }

  updatePayload(prevLang = this.props.lang, callback = () => {}) {
    const props = {
      boxes: this.props.boxes,
      botVersion: this.props.botVersion,
      isInteractive: this.state.waTemplate,
    };
    const langPayload = updateBotPayload(LANG_STRUCTURE, this.state, prevLang, props);
    this.setState(
      {
        langPayload,
        ...langPayload[this.props.lang.selectedLang],
      },
      callback
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang.selectedLang !== prevProps.lang.selectedLang) {
      this.updatePayload(prevProps.lang);
    }
  }

  getEntityList() {
    CommonUtils.getEntityByMerchantId().then(response => {
      if (response.error) {
        this.setState({ entityList: [] });
        return false;
      }
      if (response && response.entities) {
        const list = response.entities.map(e => {
          return {
            entityId: e.entityId,
            entries: e.entries,
            name: e.name,
          };
        });
        let { entity } = this.state;
        if (entity) {
          this.onChangeEntity(
            entity,
            list.find(x => x.entityId === entity)
          );
        }
        this.setState({ entityList: list });
      }
    });
  }

  handleStep(step, isSubmit = false, prevLang) {
    const { dynamicCarousel, carouselList, source } = this.state;
    const carousel = cloneDeep(carouselList);
    if (source === "dynamic-entity") {
      const obj = getFormDetails(dynamicCarousel, this.onInputValidate);
      if (!obj) return false;
    } else if (source === "static-card") {
      let isError = false;
      if (carousel.length === 0) return false;
      carousel.map((a, i) => {
        const list = getFormDetails(a, (n, e) => this.onListValidate(n, e, i));
        if (!list) {
          isError = true;
          return false;
        }
      });

      if (isError) {
        return false;
      }
    }

    if (isSubmit) step--;

    this.setState({ step }, () => this.updatePayload(prevLang, isSubmit ? this.handleSubmit : null));
  }
  onListValidate(name, error, index) {
    const { carouselList } = this.state;
    carouselList[index]["errors"][name] = error;
    this.setState({ carouselList });
  }
  onButtonListValidate(name, error, index) {
    const { buttonList } = this.state;
    buttonList[index]["errors"][name] = error;
    this.setState({ buttonList });
  }
  onInputValidate(name, error) {
    //make it generic when other payment methods are added
    let { dynamicCarousel } = this.state;
    dynamicCarousel.errors[name] = error;
    this.setState({ dynamicCarousel });
  }

  handleSubmit() {
    const { handleSubmitElement, boxes, botVersion, lang } = this.props;
    const {
      prevElement,
      intent,
      source,
      entity,
      isRich,
      nextIntent,
      waTemplate,
      legacy_message,
      activity,
      langPayload,
    } = this.state;

    const submitCb = () => {
      const langDataObj = {};
      const langPayloadObj = {};
      let defaultCarousel = [];

      keys(langPayload).map(x => {
        const { text, help_text, carouselList, dynamicCarousel, category, subCategory } = langPayload[x];
        langDataObj[x] = {
          input_type: "product",
          next_intent: nextIntent,
          request_params: [
            {
              text,
              elements: [],
              help_text,
              waTemplate,
              category,
              subCategory,
              legacy_message,
              source,
              pgLanguage: BOT_ATTRIBUTES.LANGUAGE,
              accessToken: BOT_ATTRIBUTES.ACCESS_TOKEN,
              merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
            },
          ],
        };

        langPayloadObj[x] = {
          text,
          help_text,
          category,
          subCategory,
          carouselList: [],
        };

        let carousel = cloneDeep(carouselList);
        if (source === "dynamic-entity") {
          carousel = [cloneDeep(dynamicCarousel)];
          delete carousel[0].descriptionTarget;
          delete carousel[0].titleTarget;
          delete carousel[0].currencyTarget;
          delete carousel[0].priceTarget;
        }
        carousel.map(c => {
          delete c.descriptionTarget;
          delete c.titleTarget;
          delete c.currencyTarget;
          delete c.priceTarget;
        });

        if (x === lang.defaultLang) {
          defaultCarousel = carousel;
        }
        carousel.map(c => {
          const obj = {
            image_url: c.image,
            title: c.title,
            subtitle: c.description,
            price: c.price,
            currency: c.currency,
            buttons: [],
            attributes: {},
          };
          const payloadObj = {
            title: c.title,
            description: c.description,
            price: c.price,
            currency: c.currency,
            buttons: [],
            attributes: {},
          };
          c.buttons.map(b => {
            const btnObj = {
              title: b.title,
              type: b.type,
            };
            const btnPayloadObj = {
              title: b.title,
            };
            if (b.type && (b.type.includes("web_url") || b.type === "call")) {
              btnObj.url = b.value;
              b.attributes = cloneDeep(initOptions().attributes);
            } else {
              const element = boxes.find(box => box.id === b.value);
              const payload = {
                intent: getBotIntent(element, botVersion),
                displayString: b.title,
              };
              b.attributes.map(a => {
                if (a.attributeKey && a.attributeValue) {
                  payload[a.attributeKey] = a.attributeValue;

                  if (!isArray(btnPayloadObj.attributes)) {
                    btnPayloadObj.attributes = [];
                  }
                  btnPayloadObj.attributes.push({ attributeValue: a.attributeValue });
                }
              });
              btnObj.payload = JSON.stringify(payload);
            }
            obj.buttons.push(btnObj);
            payloadObj.buttons.push(btnPayloadObj);
          });

          c.productAttributes.map(p => {
            obj.attributes[p.attributeKey] = p.attributeValue;
            payloadObj.attributes[p.attributeKey] = p.attributeValue;
          });

          if (source === "catalog") {
            langDataObj[x].request_params[0] = { ...langDataObj[x].request_params[0], category, subCategory };
          } else {
            langDataObj[x].request_params[0].elements.push(obj);
          }

          if (source === "dynamic-entity") {
            langPayloadObj[x].dynamicCarousel = payloadObj;
          } else if (source === "static-card") {
            langPayloadObj[x].carouselList.push(payloadObj);
          } else {
            langPayloadObj[x] = {
              ...langPayloadObj[x],
              category,
              subCategory,
            };
          }
        });
      });

      return { langDataObj, langPayloadObj, defaultCarousel };
    };

    const { langDataObj, langPayloadObj, defaultCarousel } = submitCb();
    const { text, help_text, category, subCategory } = langPayloadObj[lang.defaultLang];
    const payload = {
      carousel: defaultCarousel,
      text,
      help_text,
      intent,
      nextIntent,
      slideIndex: 0,
      isRich,
      source,
      entity: source === "dynamic-entity" ? entity : null,
      waTemplate,
      legacy_message,
      activity,
      langPayload: langPayloadObj,
      category,
      subCategory,
      ...(source !== "catalog" ? { carouselBox: true } : []),
    };

    handleSubmitElement(langDataObj[lang.defaultLang], prevElement, payload, langDataObj);
  }

  getProducts() {
    this.setState({ fetchProducts: true });
    CommonUtils.getProductsList().then(response => {
      if (response.error) {
        this.setState({ fetchProducts: false });
        return false;
      }
      this.products = response && response.products ? response.products : [];
      this.setState({
        categoryList: uniqBy(
          this.products.filter(p => p.active).map(p => ({ label: p.category, value: p.category })),
          "label"
        ),
        subCategoryList: uniqBy(
          this.products.map(p => ({ label: p.subCategory, value: p.subCategory })),
          "label"
        ),
      });
    });
  }

  //#region onChange element

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onChangeEntity(value, obj) {
    const stateObj = {
      entity: value,
      externalAttributes: [],
    };
    if (obj && isArray(obj.entries)) {
      stateObj.externalAttributes = Object.keys(obj.entries[0]).map(x => ({
        label: `entity.${x}`,
        value: `{{entity_${value}.${x}}}`,
      }));
    }
    this.setState(stateObj);
  }

  //#endregion

  //#region attribute events

  addAttribute(btnIndex) {
    const list = cloneDeep(this.state.buttonList);
    list[btnIndex].attributes.push({ attributeKey: "", attributeValue: "" });
    this.setState({ buttonList: list });
  }

  removeAttribute(attrIndex, btnIndex) {
    const list = cloneDeep(this.state.buttonList);
    list[btnIndex].attributes.splice(attrIndex, 1);
    this.setState({ buttonList: list });
  }

  onChangeAttribute(name, value, attrIndex, buttonIndex) {
    const list = cloneDeep(this.state.buttonList);
    list[buttonIndex].attributes[attrIndex][name] = value;
    this.setState({ buttonList: list });
  }

  //#endregion

  //#region feature events

  addProductAttri() {
    const list = cloneDeep(this.state.productAttriList);
    list.push({ attributeKey: "", attributeValue: "" });
    this.setState({ productAttriList: list });
  }

  removeProductAttri(index) {
    const list = cloneDeep(this.state.productAttriList);
    list.splice(index, 1);
    this.setState({ productAttriList: list });
  }

  onChangeProductAttri(name, value, index) {
    const list = cloneDeep(this.state.productAttriList);
    list[index][name] = value;
    this.setState({ productAttriList: list });
  }

  //#endregion

  //#region carousel change event

  onDynamicCarouselChange(name, value, e, isValid) {
    const carousel = { ...this.state.dynamicCarousel, [name]: value };
    if (isValid) {
      carousel.errors[name] = null;
    }
    if (e) {
      carousel[`${name}Target`] = e.target;
    }
    if (name === "image" && !value) {
      delete carousel.imageObj;
      carousel.image = "";
    }
    this.setState({ dynamicCarousel: carousel });
    if (name === "image" && value && (!value.startsWith("{") || !value.startsWith("$"))) {
      const image = new Image();
      image.src = value;
      image.onload = () => {
        carousel.imageObj = image;
        this.setState({ dynamicCarousel: carousel });
      };
    }
    if (name === "buttons") {
      this.setState({
        subScreen: true,
        buttonList: value.length ? [...value] : [cloneDeep(initOptions())],
      });
    }
    if (name === "productAttributes") {
      this.setState({
        subScreenAttri: true,
        productAttriList: value.length ? [...value] : cloneDeep(initAttributes()),
      });
    }
  }

  removeFeature(listName, index) {
    let list = cloneDeep(this.state[listName]);
    list.splice(index, 1);
    this.setState({ [listName]: list, carouselListKey: Date.now() }, () => {
      if (listName === "carouselList") {
        this.updatePayload();
      }
    });
  }

  onChangeFeature(name, value, index, listName) {
    const list = cloneDeep(this.state[listName]);
    list[index][name] = value;
    if (name === "image" && !value) {
      delete list[index]["imageObj"];
    }
    this.setState({ [listName]: list });
    if (name === "image" && value) {
      const image = new Image();
      image.src = value;
      image.onload = () => {
        list[index]["imageObj"] = image;
        this.setState({ [listName]: list });
      };
    }
    if (name === "buttons") {
      this.setState({
        subScreen: true,
        listIndex: index,
        buttonList: value.length ? [...value] : [{ ...cloneDeep(initOptions()), title: "" }],
      });
    }

    if (name === "productAttributes") {
      this.setState({
        subScreenAttri: true,
        productAttriListIndex: index,
        productAttriList: value.length ? [...value] : cloneDeep(initAttributes()),
      });
    }
  }

  onAdvanceOptionChange(name, value, err, obj, e) {
    if (["waTemplate", "legacy_message", "activity"].includes(name)) {
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: value });
    }
  }

  addButtonOrCarousel() {
    const { carouselList, buttonList, productAttriList, subScreen, subScreenAttri } = this.state;
    if (subScreen) {
      buttonList.push(cloneDeep(initOptions()));
    } else if (subScreenAttri) {
      productAttriList.push(cloneDeep(initAttributes()));
    } else {
      carouselList.push({
        id: getRandomNumber(6),
        image: null,
        title: "",
        description: "",
        buttons: [],
        productAttributes: [],
        errors: {
          title: null,
          description: null,
        },
      });
    }
    this.setState({ carouselList, carouselListKey: Date.now(), buttonList, productAttriList });
  }

  handleSaveCancel(save, isButton) {
    const { carouselList, dynamicCarousel, listIndex, buttonList, productAttriList, productAttriListIndex, source } =
      this.state;
    if (save) {
      const buttons = cloneDeep(buttonList);
      let isError = false;
      if (isButton) {
        if (buttons.length === 0) return false;
        buttons.map((a, i) => {
          const list = getFormDetails(a, (n, e) => this.onButtonListValidate(n, e, i));
          if (!list) {
            isError = true;
            return false;
          }
        });
      }

      if (isError) {
        return false;
      }
      const filteredProductAttriList = productAttriList.filter(attr => attr.attributeKey !== "");
      if (source === "dynamic-entity") {
        if (isButton) {
          dynamicCarousel.buttons = cloneDeep(buttonList);
        } else {
          dynamicCarousel.productAttributes = cloneDeep(filteredProductAttriList);
        }
      } else if (source === "static-card") {
        if (isButton) {
          carouselList[listIndex].buttons = cloneDeep(buttonList);
        } else {
          carouselList[productAttriListIndex].productAttributes = cloneDeep(filteredProductAttriList);
        }
      }
    }

    this.setState({
      subScreen: false,
      subScreenAttri: false,
      buttonList: [],
      filteredProductAttriList: [],
      carouselList,
      dynamicCarousel,
    });
  }

  //#endregion

  //#region render elements

  addButton() {
    const { buttonList, carouselList, listIndex, externalAttributes, prevElementStructure, source } = this.state;
    const { boxes, botElementId, attributeList, lang } = this.props;
    return (
      <div className="ele-content">
        <label className="name">
          Add Buttons{" "}
          {source !== "dynamic-entity" && carouselList[listIndex].title && `(${carouselList[listIndex].title})`}
        </label>
        {buttonList &&
          buttonList.map((obj, index) => {
            if (!obj.errors) {
              obj.errors = {
                title: null,
              };
            }
            return (
              <div key={index}>
                <Row>
                  <Col sm={3}>
                    <div className="position-relative">
                      <Input
                        name="title"
                        title="Title"
                        isReq={true}
                        placeholder="Enter Title"
                        className="form-control pr35"
                        value={obj.title}
                        error={obj.errors.title}
                        appendIcon={
                          <SelectAttribute
                            attributeList={attributeList}
                            externalAttributes={[...externalAttributes, ...prevElementStructure]}
                            onChangeFunc={value => this.onChangeFeature("title", value, index, "buttonList")}
                            setAttribute={[null, obj.title]}
                          />
                        }
                        validationFunc={(n, e) => this.onButtonListValidate(n, e, index)}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                      />
                    </div>
                  </Col>
                  <Col className={lang.className} sm={4}>
                    <Select
                      outerWidth={0}
                      outerClassName=""
                      name="type"
                      placeholder="Select Action"
                      value={obj.type}
                      options={actionList}
                      smallSize
                      height={35}
                      onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                    />
                  </Col>
                  {obj.type === "postback" && (
                    <Col className={lang.className} sm={3}>
                      <SelectElement
                        outerWidth={0}
                        smallSize
                        height={35}
                        outerClassName=""
                        value={obj.value}
                        botElementId={botElementId}
                        boxes={boxes}
                        connectors={buttonList}
                        name="value"
                        placeholder="Select Node"
                        defaultSelected={false}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                      />
                    </Col>
                  )}
                  {obj.type.includes("web_url") && (
                    <Col className={lang.className} sm={3}>
                      <Input
                        name="value"
                        title="Enter url"
                        placeholder="Enter Url"
                        style={{ minWidth: "80px" }}
                        className="form-control pr35"
                        appendIcon={
                          <SelectAttribute
                            attributeList={attributeList}
                            externalAttributes={[...externalAttributes, ...prevElementStructure]}
                            onChangeFunc={value => this.onChangeFeature("value", value, index, "buttonList")}
                            setAttribute={[null, obj.value]}
                          />
                        }
                        value={obj.value}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                      />
                    </Col>
                  )}
                  {obj.type === "call" && (
                    <Col className={lang.className} sm={3}>
                      <Input
                        name="value"
                        title="Phone Number"
                        placeholder="Enter Phone Number"
                        style={{ minWidth: "80px" }}
                        isReq={true}
                        className="form-control pr35"
                        appendIcon={
                          <SelectAttribute
                            attributeList={attributeList}
                            externalAttributes={[...externalAttributes, ...prevElementStructure]}
                            onChangeFunc={value => this.onChangeFeature("value", value, index, "buttonList")}
                            setAttribute={[null, obj.value]}
                          />
                        }
                        error={obj.errors.value}
                        value={obj.value}
                        validationFunc={(n, e) => this.onButtonListValidate(n, e, index)}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                      />
                    </Col>
                  )}
                  <Col sm={2}>
                    <div className={`btn-box mb5 ${lang.className}`} style={{ width: "60px" }}>
                      {index === buttonList.length - 1 ? (
                        <Button plusBtn className=" mr10" onClickFunc={() => this.addButtonOrCarousel()} />
                      ) : null}
                      {index !== 0 || buttonList.length > 1 ? (
                        <Button minusBtn onClickFunc={() => this.removeFeature("buttonList", index)} />
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {!(obj.type === "call" || obj.type.includes("web_url")) &&
                  obj.attributes.map((attr, attrIndex) => {
                    return (
                      <Row key={attrIndex}>
                        <Col sm={3} className="text-right">
                          <label className="name mt5">Set Attribute</label>
                        </Col>
                        <Col className={lang.className} sm={4}>
                          <Input
                            name="attributeKey"
                            placeholder="Enter Key"
                            value={attr.attributeKey}
                            onChangeFunc={(name, value) => this.onChangeAttribute(name, value, attrIndex, index)}
                          />
                        </Col>
                        <Col sm={3}>
                          <Input
                            name="attributeValue"
                            placeholder="Enter Value"
                            className="form-control pr35"
                            appendIcon={
                              <SelectAttribute
                                attributeList={attributeList}
                                externalAttributes={[...externalAttributes, ...prevElementStructure]}
                                onChangeFunc={value =>
                                  this.onChangeAttribute("attributeValue", value, attrIndex, index)
                                }
                                setAttribute={[null, attr.attributeValue]}
                              />
                            }
                            value={attr.attributeValue}
                            onChangeFunc={(name, value) => this.onChangeAttribute(name, value, attrIndex, index)}
                          />
                        </Col>
                        <Col className={lang.className} sm={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {attrIndex === obj.attributes.length - 1 ? (
                              <Button plusBtn className=" mr10" onClickFunc={() => this.addAttribute(index)} />
                            ) : null}
                            {attrIndex !== 0 || obj.attributes.length > 1 ? (
                              <Button minusBtn onClickFunc={() => this.removeAttribute(attrIndex, index)} />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            );
          })}
        <Button text="Save" submitBtn className="mr10" onClickFunc={() => this.handleSaveCancel(true, true)} />
        <Button text="Cancel" cancelBtn onClickFunc={() => this.handleSaveCancel(false, true)} />
      </div>
    );
  }

  renderProductAttri() {
    const { productAttriList, carouselList, productAttriListIndex, externalAttributes, prevElementStructure, source } =
      this.state;
    const { boxes, botElementId, attributeList, lang } = this.props;
    return (
      <div className="ele-content">
        <label className="name">
          Add Attributes
          {source !== "dynamic-entity" &&
            carouselList[productAttriListIndex].title &&
            ` (${carouselList[productAttriListIndex].title})`}
        </label>
        {productAttriList &&
          productAttriList.map((obj, index) => {
            if (!obj.errors) {
              obj.errors = {
                title: null,
              };
            }
            return (
              <Row key={index}>
                <Col className={lang.className} sm={4}>
                  <Input
                    name="attributeKey"
                    placeholder="Enter Key"
                    value={obj.attributeKey}
                    onChangeFunc={(name, value) => this.onChangeProductAttri(name, value, index)}
                  />
                </Col>
                <Col sm={3}>
                  <Input
                    name="attributeValue"
                    placeholder="Enter Value"
                    className="form-control pr35"
                    appendIcon={
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onChangeProductAttri("attributeValue", value, index)}
                        setAttribute={[null, obj.attributeValue]}
                      />
                    }
                    value={obj.attributeValue}
                    onChangeFunc={(name, value) => this.onChangeProductAttri(name, value, index)}
                  />
                </Col>
                <Col className={lang.className} sm={2}>
                  <div className="btn-box mb5" style={{ width: "60px" }}>
                    {index === productAttriList.length - 1 ? (
                      <Button plusBtn className=" mr10" onClickFunc={() => this.addProductAttri()} />
                    ) : null}
                    {index !== 0 || productAttriList.length > 1 ? (
                      <Button minusBtn onClickFunc={() => this.removeProductAttri(index)} />
                    ) : null}
                  </div>
                </Col>
              </Row>
            );
          })}

        <Button text="Save" submitBtn className="mr10" onClickFunc={() => this.handleSaveCancel(true)} />
        <Button text="Cancel" cancelBtn onClickFunc={() => this.handleSaveCancel(false)} />
      </div>
    );
  }

  renderError(carousel) {
    const { errors } = carousel;
    if (errors) {
      if (errors.title && errors.description) return <span className="req-msg">Please enter Details.</span>;
      if (errors.title) return <span className="req-msg">Please enter Title.</span>;
      if (errors.description) return <span className="req-msg">Please enter Description.</span>;
      if (!errors.title && !errors.description) return "";
    }
  }

  renderCatalog() {
    const { category, subCategory, categoryList, subCategoryList } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col>
            <label>Category</label>
            <Select
              options={categoryList}
              name="category"
              value={category}
              isClearable={false}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </Col>
          <Col>
            <label>Sub Category</label>
            <Select
              options={subCategoryList}
              name="subCategory"
              value={subCategory}
              isClearable={false}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderStaticCard() {
    const { carouselList, carouselListKey, externalAttributes, prevElementStructure } = this.state;
    const { isSmallMenu, lang, attributeList } = this.props;
    return (
      <React.Fragment>
        <Row>
          {carouselList.map((obj, index) => {
            return (
              <Col md={isSmallMenu ? 3 : 4} key={index + carouselListKey} className="mb10">
                <div className="c-box">
                  <span
                    className={`c-close ${lang.className}`}
                    onClick={() => this.removeFeature("carouselList", index)}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                  <div className="position-relative">
                    {obj.image && (obj.image.startsWith("{") || obj.image.startsWith("$")) ? (
                      <React.Fragment>
                        <Input
                          name="image"
                          placeholder="Enter Image"
                          value={obj.image}
                          className="textBox pr35 img-box align-items-center d-flex justify-content-center text-center slider-img-box"
                          outerClassName="mb0"
                          onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                        />
                        <Button
                          customIcon="fa-minus-circle"
                          className="select-attr-btn"
                          style={{ color: "#666" }}
                          onClickFunc={value => this.onChangeFeature("image", "", index, "carouselList")}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ImageUpload
                          imagePath={obj.image}
                          defaultImg=""
                          isSmall={true}
                          outerClassName={`slider-img-box ${lang.className}`}
                          getImagePath={value => this.onChangeFeature("image", value, index, "carouselList")}
                        />
                        {/* {!obj.image && */}
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...externalAttributes, ...prevElementStructure]}
                          onChangeFunc={value => this.onChangeFeature("image", value, index, "carouselList")}
                          setAttribute={[null, obj.image]}
                        />
                        {/* } */}
                      </React.Fragment>
                    )}
                  </div>
                  <Input
                    name="title"
                    placeholder="Enter Title"
                    value={obj.title}
                    title="Title"
                    isReq={true}
                    className="textBox"
                    outerClassName="mb0"
                    appendIcon={
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onChangeFeature("title", value, index, "carouselList")}
                        setAttribute={[obj.titleTarget, obj.title]}
                      />
                    }
                    validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                  />
                  <TextArea
                    rows={4}
                    placeholder="Enter Description"
                    name="description"
                    isReq={true}
                    outerClassName="mb0"
                    className="textBox"
                    value={obj.description}
                    appendIcon={
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onChangeFeature("description", value, index, "carouselList")}
                        setAttribute={[obj.descriptionTarget, obj.description]}
                      />
                    }
                    validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                  />
                  <Input
                    name="currency"
                    placeholder="Enter Currency"
                    value={obj.currency}
                    className="textBox"
                    outerClassName="mb0"
                    appendIcon={
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onChangeFeature("currency", value, index, "carouselList")}
                        setAttribute={[obj.currencyTarget, obj.currency]}
                      />
                    }
                    onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                  />
                  <Input
                    name="price"
                    placeholder="Enter Price"
                    value={obj.price}
                    className="textBox"
                    outerClassName="mb0"
                    appendIcon={
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onChangeFeature("price", value, index, "carouselList")}
                        setAttribute={[obj.priceTarget, obj.price]}
                      />
                    }
                    onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                  />
                  <Button
                    className="cb-submit"
                    text={
                      <>
                        Add Attributes
                        {carouselList[index].productAttributes.length > 0 && (
                          <span className="badge badge-yellow badge-pill ml-1">
                            {carouselList[index].productAttributes.length}
                          </span>
                        )}
                      </>
                    }
                    name="productAttributes"
                    onClickFunc={() =>
                      this.onChangeFeature(
                        "productAttributes",
                        carouselList[index].productAttributes,
                        index,
                        "carouselList"
                      )
                    }
                  />
                  <Button
                    className="cb-submit"
                    text={
                      <>
                        Add Buttons
                        {isArray(carouselList[index].buttons) && (
                          <span className="badge badge-yellow badge-pill ml-1">
                            {carouselList[index].buttons.length}
                          </span>
                        )}
                      </>
                    }
                    name="buttons"
                    onClickFunc={() =>
                      this.onChangeFeature("buttons", carouselList[index].buttons, index, "carouselList")
                    }
                  />
                </div>
                {this.renderError(obj)}
              </Col>
            );
          })}
          <Col md={isSmallMenu ? 3 : 4} className={`mb10 ${lang.className}`}>
            <div className="c-box">
              <Button className="plus" text="+" onClickFunc={() => this.addButtonOrCarousel()} />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderDynamicEntity() {
    const { entityList, entity, dynamicCarousel, externalAttributes, prevElementStructure } = this.state;
    const { attributeList } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <div className="c-box">
              <div className="position-relative">
                {dynamicCarousel.image &&
                (dynamicCarousel.image.startsWith("{") || dynamicCarousel.image.startsWith("$")) ? (
                  <React.Fragment>
                    <Input
                      name="image"
                      placeholder="Enter Image"
                      value={dynamicCarousel.image}
                      className="textBox pr35 img-box align-items-center d-flex justify-content-center text-center slider-img-box"
                      outerClassName="mb0"
                      onChangeFunc={(name, value) => this.onDynamicCarouselChange(name, value)}
                    />
                    <Button
                      customIcon="fa-minus-circle"
                      className="select-attr-btn"
                      style={{ color: "#666" }}
                      onClickFunc={() => this.onDynamicCarouselChange("image", "")}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ImageUpload
                      imagePath={dynamicCarousel.image}
                      defaultImg=""
                      isSmall={true}
                      outerClassName="slider-img-box"
                      getImagePath={value => this.onDynamicCarouselChange("image", value)}
                    />
                    {!dynamicCarousel.image && (
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onDynamicCarouselChange("image", value)}
                        setAttribute={[null, dynamicCarousel.image]}
                      />
                    )}
                  </React.Fragment>
                )}
              </div>

              <div className="position-relative">
                <Input
                  name="title"
                  title="Title"
                  placeholder="Enter Title"
                  className="textBox pr35"
                  outerClassName="mb0"
                  isReq={true}
                  value={dynamicCarousel.title}
                  appendIcon={
                    <SelectAttribute
                      attributeList={attributeList}
                      externalAttributes={[...externalAttributes, ...prevElementStructure]}
                      onChangeFunc={value => this.onDynamicCarouselChange("title", value, null, true)}
                      setAttribute={[dynamicCarousel.titleTarget, dynamicCarousel.title]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                  onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                />
              </div>

              <div className="position-relative">
                <TextArea
                  value={dynamicCarousel.description}
                  placeholder="Enter Description"
                  rows={4}
                  name="description"
                  outerClassName="mb0"
                  isReq={true}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={[...externalAttributes, ...prevElementStructure]}
                      onChangeFunc={value => this.onDynamicCarouselChange("description", value, null, true)}
                      setAttribute={[dynamicCarousel.descriptionTarget, dynamicCarousel.description]}
                    />
                  }
                  className="textBox"
                  validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value, e) => this.onDynamicCarouselChange(name, value, e)}
                />
              </div>

              <div className="position-relative">
                <Input
                  name="currency"
                  title="Currency"
                  placeholder="Enter Currency"
                  className="textBox pr35"
                  outerClassName="mb0"
                  isReq={true}
                  value={dynamicCarousel.currency}
                  appendIcon={
                    <SelectAttribute
                      attributeList={attributeList}
                      externalAttributes={[...externalAttributes, ...prevElementStructure]}
                      onChangeFunc={value => this.onDynamicCarouselChange("currency", value, null, true)}
                      setAttribute={[dynamicCarousel.currencyTarget, dynamicCarousel.currency]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                  onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                />
              </div>

              <div className="position-relative">
                <Input
                  name="price"
                  title="Price"
                  placeholder="Enter Price"
                  className="textBox pr35"
                  outerClassName="mb0"
                  isReq={true}
                  value={dynamicCarousel.price}
                  appendIcon={
                    <SelectAttribute
                      attributeList={attributeList}
                      externalAttributes={[...externalAttributes, ...prevElementStructure]}
                      onChangeFunc={value => this.onDynamicCarouselChange("price", value, null, true)}
                      setAttribute={[dynamicCarousel.priceTarget, dynamicCarousel.price]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                  onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                />
              </div>

              <Button
                className="cb-submit"
                text={
                  <>
                    Add Attributes
                    {dynamicCarousel.productAttributes.length > 0 && (
                      <span className="badge badge-yellow badge-pill ml-1">
                        {dynamicCarousel.productAttributes.length}
                      </span>
                    )}
                  </>
                }
                name="productAttributes"
                onClickFunc={() => this.onDynamicCarouselChange("productAttributes", dynamicCarousel.productAttributes)}
              />
              <Button
                className="cb-submit"
                text={
                  <>
                    Add Buttons
                    {isArray(dynamicCarousel.buttons) && (
                      <span className="badge badge-yellow badge-pill ml-1">{dynamicCarousel.buttons.length}</span>
                    )}
                  </>
                }
                name="buttons"
                onClickFunc={() => this.onDynamicCarouselChange("buttons", dynamicCarousel.buttons)}
              />
            </div>
            {this.renderError(dynamicCarousel)}
          </Col>
          {/* <Col md={8}>
          <Row>
            <Col md={6}>

            </Col>
            <Col md={6}>
              <label>Entity</label>
              <Select
                options={entityList}
                name="entity"
                value={entity}
                labelKey="name"
                valueKey="entityId"
                onChangeFunc={(name, value, error, obj) => this.onChangeEntity(value, obj)}
              />
            </Col>
          </Row>
        </Col> */}
        </Row>
      </React.Fragment>
    );
  }

  //#endregion

  //#region render steps

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId, lang } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
          lang={lang}
        />
      </div>
    );
  }

  renderStep1() {
    const {
      text,
      textTarget,
      source,
      prevElement,
      subScreen,
      subScreenAttri,
      externalAttributes,
      prevElementStructure,
      isRich,
      waTemplate,
      legacy_message,
      activity,
      help_text,
    } = this.state;
    const { boxes, botElementId, connectors, attributeList, lang } = this.props;

    if (subScreen) return this.addButton();
    if (subScreenAttri) return this.renderProductAttri();
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm title="Message">
            <div className="position-relative">
              {isRich ? (
                <TextEditor
                  title="Message"
                  name="text"
                  value={text}
                  appendIcon={
                    <SelectAttribute
                      attributeList={attributeList}
                      externalAttributes={[...externalAttributes, ...prevElementStructure]}
                      onChangeFunc={value => this.setState({ text: value })}
                      setAttribute={[textTarget, text]}
                    />
                  }
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              ) : (
                <Input
                  name="text"
                  placeholder="Enter message"
                  outerClassName="mb0"
                  appendIcon={
                    <SelectAttribute
                      attributeList={attributeList}
                      externalAttributes={[...externalAttributes, ...prevElementStructure]}
                      onChangeFunc={value => this.setState({ text: value })}
                      setAttribute={[textTarget, text]}
                    />
                  }
                  value={text}
                  onChangeFunc={(name, value, err, obj, e) => this.setState({ [name]: value, textTarget: e.target })}
                />
              )}
            </div>
            <label className={`mb0 ${lang.className}`} style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>
        </div>
        <Row className={`mb10 ${lang.className}`}>
          <Col md={4}>
            <label>Source</label>
            <Select
              options={this.sourceList}
              name="source"
              value={source}
              isClearable={false}
              onChangeFunc={(name, value) =>
                this.setState({ [name]: value, isDynamic: value === "dynamic-entity" }, () => this.getProducts())
              }
            />
          </Col>
          <Col md={4}>
            <label>Previous Element</label>
            <SelectElement
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              outerWidth={0}
              outerClassName=""
              onChangeFunc={this.onChangePrevElement}
            />
          </Col>
          <Col md={4}></Col>
        </Row>
        {source === "static-card" && this.renderStaticCard()}
        {source === "dynamic-entity" && this.renderDynamicEntity()}
        {source === "catalog" && this.renderCatalog()}

        <div className="b-form">
          <AdvanceOptionsExpand
            help_text={help_text}
            waTemplate={waTemplate}
            legacy_message={legacy_message}
            activity={activity}
            onChangeFunc={this.onAdvanceOptionChange}
            displayList={["waTemplate", "legacy_message", "activity", "help_text"]}
          />
        </div>
      </div>
    );
  }

  //#endregion

  render() {
    const { step, subScreen, subScreenAttri } = this.state;
    const { resetElement, botElementId, lang } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step + subScreen + subScreenAttri} scrollClass="bt-send-carousel" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={() => this.updatePayload(lang, this.handleSubmit)}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
          hideButtons={subScreen || subScreenAttri}
          lang={lang}
        />
      </React.Fragment>
    );
  }
}

export default Product;
