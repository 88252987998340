import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "./Select";
import Input from "./Input";
import Button from "./Button";
import isEqual from "lodash/isEqual";
import DateTimePicker from "./DateTimePicker";
import moment from "moment";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumn: null,
      selectedOption: null,
      selectedType: null,
      columnLoading: props.columnLoading,
      columnList: props.columnList,
      searchValue: "",
      filterCriteria: props.filterCriteria,
      startDate: props.startDate,
      endDate: props.endDate,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.applyFilterClick = this.applyFilterClick.bind(this);
    this.cancelFilterClick = this.cancelFilterClick.bind(this);
    this.filterOptions = [
      { label: "greater than", value: "gt" },
      { label: "greater than or equal to", value: "gte" },
      { label: "less than", value: "lt" },
      { label: "less than or equal to", value: "lte" },
      { label: "is empty", value: "na" },
      { label: "is not empty", value: "av" },
      { label: "equals", value: "av#" },
    ];
    this.ignoreSearch = ["na", "av"];
  }

  UNSAFE_componentWillReceiveProps(nextProp) {
    if (!isEqual(this.props.columnList, nextProp.columnList) || this.props.columnLoading !== nextProp.columnLoading) {
      this.setState({
        columnLoading: nextProp.columnLoading,
        columnList: nextProp.columnList,
      });
    }
    if (!isEqual(this.props.filterCriteria, nextProp.filterCriteria)) {
      this.setState({ filterCriteria: nextProp.filterCriteria });
    }
  }

  handleOnChange(name, value) {
    this.setState({ [name]: value });
  }

  applyFilterClick() {
    let { searchValue, selectedColumn, selectedOption, selectedType, filterCriteria, startDate, endDate } = this.state;
    const { dtColumns, strictFilter } = this.props;
    if (selectedType) {
      searchValue = selectedType;
    }

    if (strictFilter) {
      if (
        (dtColumns.includes(selectedColumn)
          ? dtColumns.includes("apptDate")
            ? !startDate
            : !startDate || !endDate
          : searchValue === "") ||
        selectedColumn === null
      )
        return;
    }

    if (selectedColumn) {
      filterCriteria[selectedColumn] = {
        value: dtColumns.includes(selectedColumn) ? [startDate, endDate] : searchValue,
      };
    }

    if (selectedOption) {
      filterCriteria[selectedColumn].option = selectedOption;
    }
    this.setState(
      {
        filterCriteria,
        searchValue: "",
        selectedColumn: null,
        selectedOption: null,
        selectedType: null,
        startDate: null,
        endDate: null,
      },
      () => {
        if (this.props.handleFilter) this.props.handleFilter(this.state.filterCriteria);
      }
    );
  }

  cancelFilterClick(key) {
    const { filterCriteria } = this.state;
    delete filterCriteria[key];
    this.setState({ filterCriteria }, () => {
      if (this.props.handleFilter) this.props.handleFilter(this.state.filterCriteria);
    });
  }

  getValue(k) {
    const { dtColumns } = this.props;
    const { filterCriteria, columnList } = this.state;
    if (dtColumns.includes(k)) {
      const ddlObj = columnList.find(c => c.value === k);
      const isTimePicker = ddlObj ? ddlObj.isTimePicker : false;
      const fromData = isTimePicker
        ? moment(filterCriteria[k].value[0]).format("DD MMM YYYY hh:mm A")
        : filterCriteria[k].value[0];
      const toData = filterCriteria[k].value[1]
        ? isTimePicker
          ? moment(filterCriteria[k].value[1]).format("DD MMM YYYY hh:mm A")
          : filterCriteria[k].value[1]
        : "";
      return `${fromData} ${toData ? `to ${toData}` : ""}`;
    } else {
      const ddlObj = columnList.find(x => x.isSelect && x.value === k);
      if (ddlObj) {
        return ddlObj.isMulti
          ? filterCriteria[k].value
              .split(",")
              .map(v => (ddlObj.options.find(x => x.value === v) || {}).label || "")
              .join(", ")
          : (ddlObj.options.find(x => x.value === filterCriteria[k].value) || {}).label || "";
      }
      return filterCriteria[k].value;
    }
  }

  render() {
    const {
      selectedColumn,
      columnList,
      columnLoading,
      searchValue,
      filterCriteria,
      startDate,
      endDate,
      selectedOption,
      selectedType,
    } = this.state;
    const {
      labelKey,
      valueKey,
      dtColumns,
      fixedColumns,
      rightSec,
      filterLoading,
      isValidateStartDate,
      isValidateEndDate,
      extraSec,
      filterType,
      showInput,
      isMulti,
      btnText,
      outerWidth,
      btnClassName,
    } = this.props;
    let filterOptions = [];
    if (selectedColumn) {
      const obj = columnList.find(x => x.filterOtions && x[valueKey] === selectedColumn);
      if (obj && Array.isArray(obj.filterOtions)) {
        filterOptions = this.filterOptions.filter(x => obj.filterOtions.includes(x.value));
      }
    }

    const ddlObj = columnList.find(x => x[valueKey] === selectedColumn) || {};
    return (
      <div className="mf-box">
        <div className="f-info">
          <span className="f-title">Filter:</span>
          <span className="f-col-box">
            {Object.keys(filterCriteria).map(
              k =>
                k && (
                  <span key={k} className="f-col">
                    <span className="f-col-label">
                      <React.Fragment>{(columnList.find(c => c[valueKey] === k) || {})[labelKey] || ""}</React.Fragment>
                      {filterCriteria[k].option ? (
                        <React.Fragment>
                          {" "}
                          {this.filterOptions.find(f => f.value === filterCriteria[k].option).label}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>:</React.Fragment>
                      )}
                    </span>
                    {this.getValue(k)}
                    {!fixedColumns.includes(k) && (
                      <i className="fa fa-times ml5" onClick={() => this.cancelFilterClick(k)}></i>
                    )}
                  </span>
                )
            )}
          </span>
        </div>
        <div className="f-filter-search">
          <div className="ff-left">
            <Select
              placeholder="Select Column"
              outerClassName="mr10"
              name="selectedColumn"
              outerWidth={outerWidth}
              value={selectedColumn}
              onChangeFunc={this.handleOnChange}
              options={columnList.map(x => ({ [valueKey]: x[valueKey], [labelKey]: x[labelKey] }))}
              isLoading={columnLoading}
              valueKey={valueKey}
              labelKey={labelKey}
            />
            {ddlObj.isSelect && (
              <Select
                placeholder="Select Type"
                outerClassName="mr10 f-filter-select"
                name="selectedType"
                isMulti={ddlObj.isMulti}
                value={selectedType}
                onChangeFunc={this.handleOnChange}
                options={ddlObj.options}
              />
            )}
            {filterOptions.length > 0 && (
              <Select
                placeholder="Select Option"
                outerClassName="mr10"
                name="selectedOption"
                outerWidth={outerWidth}
                value={selectedOption}
                onChangeFunc={this.handleOnChange}
                options={filterOptions}
              />
            )}
            {dtColumns.includes(selectedColumn) ? (
              <React.Fragment>
                <DateTimePicker
                  name="startDate"
                  outerClassName="mr10"
                  placeholder="Select start date"
                  isValidDate={isValidateStartDate}
                  value={startDate}
                  onChange={this.handleOnChange}
                  timeFormat={ddlObj.isTimePicker ? "hh:mm A" : false}
                />
                <div className="d-flex flex-column">
                  <DateTimePicker
                    name="endDate"
                    outerClassName="mr10"
                    placeholder="Select end date"
                    isValidDate={isValidateEndDate}
                    value={endDate}
                    onChange={this.handleOnChange}
                    timeFormat={ddlObj.isTimePicker ? "hh:mm A" : false}
                  />
                  <span className="req-msg">{startDate && !endDate && "please enter end date"}</span>
                </div>
              </React.Fragment>
            ) : (
              !ddlObj.isSelect &&
              !this.ignoreSearch.includes(selectedOption) && (
                <Input
                  placeholder="Type to search"
                  outerClassName="mr10"
                  reqType={ddlObj.type}
                  outerStyle={{ width: "250px" }}
                  name="searchValue"
                  value={searchValue}
                  inputRefFunc={e => (this.inputRef = e)}
                  onKeyUpFunc={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      this.applyFilterClick();
                      this.inputRef.blur();
                    }
                  }}
                  onChangeFunc={this.handleOnChange}
                />
              )
            )}
            <Button
              size={null}
              backBtn
              displayIcon={filterLoading}
              onClickFunc={this.applyFilterClick}
              loading={filterLoading}
              className={btnClassName}
              text={btnText ? btnText : "Filter"}
            />
          </div>
          {extraSec}
          {rightSec && <div className="ff-right">{rightSec}</div>}
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

Filter.defaultProps = {
  columnList: [],
  filterType: [],
  columnLoading: false,
  labelKey: "label",
  valueKey: "value",
  dtColumns: [],
  rightSec: null,
  extraSec: null,
  startDate: null,
  endDate: null,
  filterCriteria: {},
  filterLoading: false,
  showInput: false,
  strictFilter: true,
  fixedColumns: [],
  btnText: null,
  btnClassName: null,
  outerWidth: 180,
};

Filter.propTypes = {
  columnList: PropTypes.array,
  columnLoading: PropTypes.bool,
  handleFilter: PropTypes.func.isRequired,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  dtColumns: PropTypes.array,
  rightSec: PropTypes.any,
  extraSec: PropTypes.any,
  filterCriteria: PropTypes.object,
  filterLoading: PropTypes.bool,
  strictFilter: PropTypes.bool,
  fixedColumns: PropTypes.array,
  btnText: PropTypes.string,
  btnClassName: PropTypes.string,
  outerWidth: PropTypes.any,
};

export default Filter;
