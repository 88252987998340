import React from "react";
import CommonUtils from "./../utils/CommonUtils";
import {
  setTabIndexPayload,
  setMessageListPayload,
  handleModalPopup,
  setActiveVisitorPayload,
  setActiveLiveVisitorPayload,
  setRawListPayload,
  setRawReferenceListPayload,
  setMerchantAttrPayload,
  setTaskTypePayload,
  setMerchantProfilePayload,
  setAgentMetricsPayload,
  setLiveAgentsPayload,
  setRawListPaginationPayload,
  setRawListExportCSVPayload,
  setResponseReviewListPayload,
  setAgentTeamPayload,
  setRolePermissionPayload,
  setRoleListPayload,
  removeChatListPayload,
  setBotElementPayload,
  setActiveAgentPayload,
  setMerchantIntegrationList,
  setCurrentIntegrationDetail,
  setIntegrationApiDetail,
  setFetchingMerchantIntegrationList,
} from "./common";
import {
  messageStatus,
  getVisitorId,
  isArray,
  visitorCheck,
  checkCondition,
  taskTypeList,
  roleStaticList,
  isURL,
  statusList,
  getStatus,
  filterAppStore,
} from "../utils/GeneralUtils";
import { sessionMerchantId, sessionRole, sessionUser, sessionUserId } from "../utils/SessionUtils";
import { getIntegrationImage } from "../utils/IconUtils";
import { cloneDeep, orderBy, get, differenceBy, uniq } from "lodash";
import { getAccessList, menuItems, menuType } from "../common/Layouts/Sidebar/menuUtils";

const defaultPopupObj = {
  showModal: false,
  title: null,
  centered: false,
  message: null,
  loading: false,
  okBtnText: null,
  cancelBtnText: null,
  onOkClick: null,
  onCancelClick: null,
  isSuccess: false,
  isWarning: false,
  isError: false,
  component: null,
  size: null,
  modalClass: "",
};

// reset store on user logout
export const logoutUser = () => {
  return dispatch => {
    dispatch({ type: "USER_LOGOUT" });
  };
};

// set tab index for any pages
export const setTabIndex =
  (index = null, data = null) =>
  dispatch => {
    return dispatch(setTabIndexPayload(index, data));
  };
// get Response Review Messages
export const getResponseReviewMessages =
  (fetching = true) =>
  dispatch => {
    dispatch(setResponseReviewListPayload({ fetching }));
    CommonUtils.getChatMessage().then(res => {
      if (res.error) {
        dispatch(setResponseReviewListPayload());
        return false;
      }
      dispatch(setResponseReviewListPayload({ list: res, fetching }));
    });
  };

// get message list for message center
export const getMessageList =
  (fetching = false) =>
  (dispatch, preProps) => {
    dispatch(setMessageListPayload({ fetching }));
    return CommonUtils.getInboxMessages().then(msgResponse => {
      if (msgResponse.error) {
        dispatch(setMessageListPayload({ fetching: false }));
        return false;
      }

      let messageList = msgResponse.noContent ? [] : msgResponse;
      CommonUtils.getActiveVisitors().then(visitorRes => {
        // data manipulation for visitorRes
        if (visitorRes.error) {
          return false;
        }

        const { common, reducer } = preProps();
        const currentVisitor = reducer.visitor;

        const previousVisitors = common.activeVisitors.list;
        const visitorResponse = filterUniqueVisitor(visitorRes || [], previousVisitors, currentVisitor, null, dispatch);
        // dispatch(setActiveVisitorPayload(visitorResponse));

        const allMessageList = common.messages.list;
        messageList.map(m => {
          if (m.conversationIds && m.conversationIds.length > 0) {
            m.sessionID = null;
            m.conversationID = m.conversationIds[m.conversationIds.length - 1];

            visitorResponse.map(v => {
              if (!m.visitor && v.referenceID === m.visitorId) {
                m.visitor = v;
                m.isOnline = true;
                if (v.sessionID) m.sessionID = v.sessionID;
              }
            });

            if (allMessageList && !m.visitor) {
              const obj = allMessageList.find(x => x.visitorId === m.visitorId);
              if (obj) m.visitor = obj.visitor;
            }
          }
        });

        // find out the live chat visitor
        const messageListPayload = getMessageListObj(messageList);
        // const previousLiveVisitors = common.activeLiveVisitors.list;
        // const liveVisitorFilter = {
        //   needAgentList: messageListPayload.needAgentList,
        //   sessionID: common.tabData && common.tabData.sessionID ? common.tabData.sessionID : null
        // }
        // const uniqueLiveVisitors = filterUniqueVisitor(visitorRes || [], previousLiveVisitors, currentVisitor, liveVisitorFilter);
        // dispatch(setActiveLiveVisitorPayload(uniqueLiveVisitors));

        dispatch(setMessageListPayload(messageListPayload));
      });

      if (fetching) return dispatch(setMessageListPayload(getMessageListObj(messageList)));
    });
  };

const getMessageListObj = (messageList, fetching = false) => {
  let pendingList = messageList.filter((message, i) => message.replyStatus === messageStatus.PENDING);
  let pendingStatusList = messageList.filter((message, i) => message.status === messageStatus.PENDING);
  let repliedList = messageList.filter((message, i) =>
    [messageStatus.REPLIED, messageStatus.ANSWERED].includes(message.replyStatus)
  );
  let deletedList = messageList.filter((message, i) => message.replyStatus === messageStatus.DELETED);
  let junkList = messageList.filter((message, i) => message.replyStatus === messageStatus.JUNK);
  let draftList = messageList.filter((message, i) => message.replyStatus === messageStatus.DRAFT);
  let assignedList = messageList.filter(
    (message, i) =>
      message.status === "Pending" &&
      (message.assignmentInfo || {}).assignedTo == sessionUserId() &&
      message.replyStatus === messageStatus.ASSIGNED
  );
  let needAgentList = pendingList.filter(a => {
    return (
      a.isOnline &&
      !(a.visitor && isArray(a.visitor.agents) && a.visitor.agents.filter(x => x.type === "User").length > 0)
    );
  });
  return {
    list: messageList,
    fetching,
    pendingList,
    pendingStatusList,
    repliedList,
    deletedList,
    junkList,
    draftList,
    needAgentList,
    assignedList,
  };
};

// handle modal popup
export const handlePopup = obj => dispatch => {
  if (!obj) {
    obj = { showModal: false };
    setTimeout(() => {
      dispatch(handleModalPopup(defaultPopupObj));
    }, 1000);
  }
  if (obj.isSuccess) {
    obj = { ...defaultPopupObj, ...obj };
    obj.showModal = true;
    obj.title = "Success";
  } else if (obj.isError) {
    obj = { ...defaultPopupObj, ...obj };
    obj.showModal = true;
    obj.title = "Error";
    obj.message = obj.message || "Sorry, something went wrong: Please try again later.";
  } else if (obj.isWarning) {
    obj = { ...defaultPopupObj, ...obj };
    obj.showModal = true;
    obj.title = "Warning";
    obj.message = obj.message || "Sorry, something went wrong: Please try again later.";
  }
  dispatch(handleModalPopup(obj));
};

const filterUniqueVisitor = (list, preList, currentVisitor, liveVisitorFilter, dispatch) => {
  let updatedVisitors = [];
  list.map(a => {
    a.engagementID = a.activeEngagementID;
    a.viewMessageCount = a.viewMessageCount || 0;
    const vName = getVisitorId(a);
    a.company = a.contacts ? a.contacts.company : "";
    a.aiAgent = a.engagements && isArray(a.engagements) && a.engagements.length > 0 ? a.engagements[0].aiAgent : "";

    const eng = a.engagements && a.engagements.length > 0 ? a.engagements[0] : null;
    a.channel = eng ? eng.channel : "";
    a.engagementName = eng.engagementName || eng.engagementID || "";

    if (updatedVisitors.findIndex(x => x.referenceID == a.referenceID) === -1) {
      const preVisitor = preList.find(pv => pv.referenceID === a.referenceID && pv.sessionID === a.sessionID);
      if (
        currentVisitor &&
        currentVisitor.referenceID &&
        currentVisitor.sessionID &&
        currentVisitor.referenceID === a.referenceID &&
        currentVisitor.sessionID === a.sessionID &&
        document.hasFocus()
      ) {
        a.viewMessageCount = a.totalMessages || 0;
        const userAgentdata = {
          merchantID: sessionMerchantId(),
          id: sessionUserId(),
          visitorReadMessages: [
            {
              visitorSessionId: currentVisitor.sessionID,
              msgReadCount: a.viewMessageCount,
            },
          ],
        };
        CommonUtils.updateUserAgent(userAgentdata).then(response => {
          if (response.error) {
            return false;
          }
          if (dispatch) {
            dispatch(setActiveAgentPayload({ data: response, fetching: false }));
          }
        });
      } else if (preVisitor) {
        a.viewMessageCount = preVisitor.viewMessageCount || 0;
      }
      updatedVisitors.push({ ...a, vName });
    }
  });
  if (liveVisitorFilter) {
    updatedVisitors = updatedVisitors.filter(
      (x, i) =>
        x.totalMessages > 0 ||
        liveVisitorFilter.needAgentList.filter(r => r.visitorId === x.referenceID).length > 0 ||
        (liveVisitorFilter && x.sessionID === liveVisitorFilter.sessionID) ||
        (isArray(x.agents) && x.agents.filter(a => a.type === "User" && a.lastName === "**").length > 0)
    );
  }
  return updatedVisitors;
};

// get active visitor list
export const getActiveVisitorList =
  (setPrevVisitors = false, loading = true) =>
  (dispatch, preProps) => {
    const { common, reducer } = preProps();
    const previousVisitors = common.activeVisitors.list;
    dispatch(setActiveVisitorPayload(setPrevVisitors ? previousVisitors : [], loading));

    const previousLiveVisitors = common.activeLiveVisitors.list;
    dispatch(setActiveLiveVisitorPayload(setPrevVisitors ? previousLiveVisitors : [], loading));

    const previousMessageList = common.messages.pendingList;
    let agentTeam = common.agentTeam.loading ? null : common.agentTeam.data;

    const visitorData = [CommonUtils.getActiveVisitors()];
    if (!agentTeam) {
      visitorData.push(CommonUtils.getMerchantDepartment());
    } else {
      visitorData.push(new Promise(resolve => resolve(false)));
    }

    const previousActiveAgent = common.activeAgent && common.activeAgent.data ? common.activeAgent.data : null;

    if (!previousActiveAgent) {
      visitorData.push(CommonUtils.getUserAgentById());
    } else {
      visitorData.push(new Promise(resolve => resolve(false)));
    }
    Promise.all(visitorData).then(([visitorRes, teamRes, agentRes]) => {
      if (visitorRes.error) {
        dispatch(setActiveVisitorPayload());
        dispatch(setActiveLiveVisitorPayload());
        return false;
      }

      if (teamRes && teamRes.error) {
        dispatch(setAgentTeamPayload());
      } else if (teamRes) {
        agentTeam = getDepartmentInfo(teamRes);
        dispatch(setAgentTeamPayload({ data: agentTeam, fetching: false }));
      }

      const visitorResponse = visitorRes || [];
      const currentVisitor = reducer.visitor;

      if (agentRes && !agentRes.error) {
        visitorResponse.map(x => {
          const matchedSession =
            agentRes &&
            agentRes.visitorReadMessages &&
            agentRes.visitorReadMessages.find(v => v.visitorSessionId === x.sessionID);
          if (matchedSession) {
            x.viewMessageCount = matchedSession.msgReadCount;
          }
        });
        dispatch(setActiveAgentPayload({ data: agentRes, fetching: false }));
      }

      const previousVisitors = common.activeVisitors.list;
      const uniqueVisitors = filterUniqueVisitor(visitorResponse, previousVisitors, currentVisitor, null, dispatch);
      const updatedUniqueVisitors = updateVisitors(uniqueVisitors, previousMessageList, agentTeam);
      const inActiveSessionList = differenceBy(
        common.chatList.map(x => x.sessionId),
        updatedUniqueVisitors.map(x => x.sessionID)
      );
      if (isArray(inActiveSessionList)) {
        dispatch(removeChatListPayload(inActiveSessionList));
      }
      dispatch(setActiveVisitorPayload(updatedUniqueVisitors));

      // find out the live chat visitor
      const previousLiveVisitors = common.activeLiveVisitors.list;
      const liveVisitorFilter = {
        needAgentList: common.messages.needAgentList,
        sessionID: common.tabData && common.tabData.sessionID ? common.tabData.sessionID : null,
      };
      const uniqueLiveVisitors = filterUniqueVisitor(
        visitorResponse,
        previousLiveVisitors,
        currentVisitor,
        liveVisitorFilter,
        dispatch
      );
      const updatedUniqueLiveVisitors = updateVisitors(uniqueLiveVisitors, previousMessageList, agentTeam);
      dispatch(setActiveLiveVisitorPayload(updatedUniqueLiveVisitors));
    });
  };

const updateVisitors = (response, messageList = [], team) => {
  // filter unique visitors
  let updatedVisitorList = [];
  cloneDeep(response).map(a => {
    const vName = getVisitorId(a);
    if (updatedVisitorList.findIndex(x => x.referenceID == a.referenceID) === -1)
      updatedVisitorList.push({ ...a, vName });
  });
  updatedVisitorList.map(v => {
    v.createdDateValue = new Date(v.createdDate).getTime();
    const reqObj = visitorCheck(v);
    Object.keys(reqObj.vObj).map(x => (v[x] = reqObj.vObj[x]));
  });

  updatedVisitorList = orderBy(updatedVisitorList, ["sortColumn", "createdDateValue"], ["asc"]);

  if (team) {
    updatedVisitorList = updatedVisitorList.filter(v => {
      switch (sessionRole()) {
        case "CALLCENTER_SUPERVISOR":
          return (
            team.members.includes(v.userAgentId) ||
            team.departments.some(d => {
              return (d.rules || []).every(r => {
                const attr1 = get(v, r.metric) || "";
                return checkCondition(attr1, r.value || "", r.condition);
              });
            })
          );
        case "CALLCENTER_USER3":
          return v.userAgentId === sessionUserId();
        default:
          return true;
      }
    });
  }
  return updatedVisitorList;
};

// get Raw visitor list
export const getRawVisitorList =
  (rawVisitorsObj, fetching = true, activeVisitorList = null, listType = "") =>
  dispatch => {
    let setPayload = null;
    switch (listType) {
      case "REFERENCE_LIST":
        setPayload = setRawReferenceListPayload;
        break;
      case "PAGINATION_LIST":
        setPayload = setRawListPaginationPayload;
        break;
      case "EXPORT_CSV":
        setPayload = setRawListExportCSVPayload;
        break;
      default:
        setPayload = setRawListPayload;
        break;
    }

    dispatch(setPayload({ fetching }));
    return CommonUtils.getRawVisitorsList(rawVisitorsObj).then(rawResponse => {
      if (rawResponse.error) {
        dispatch(setPayload());
        return false;
      }
      let rawList = rawResponse.rawVisitors;
      const totalVisitorPages = rawResponse.totalVisitorPages || 0;
      const totalRecords = rawResponse.totalElements || 0;
      rawList.map(r => {
        r.engagementID = r.activeEngagementID;
        r.lastAccessedDate = r.lastAccessedDate;
      });

      if (activeVisitorList && activeVisitorList.length > 0) {
        rawList.map(m => {
          if (m.sessionID && m.sessionID.length > 0) {
            activeVisitorList.map(v => {
              if (v.sessionID === m.sessionID) {
                m.isOnline = true;
              }
            });
          }
        });
      }

      CommonUtils.getActiveVisitors().then(visitorRes => {
        if (visitorRes.error) {
          return false;
        }
        const visitorResponse = visitorRes || [];
        rawList.map(m => {
          if (m.sessionID && m.sessionID.length > 0) {
            visitorResponse.map(v => {
              if (v.sessionID === m.sessionID) {
                m.isOnline = true;
              }
            });
          }
        });
        dispatch(setPayload({ list: rawList }));
      });

      dispatch(setPayload({ list: rawList, fetching: false, totalVisitorPages, totalRecords }));
    });
  };

// get merchant attr list
export const getMerchantAttr =
  (fetching = true) =>
  dispatch => {
    dispatch(setMerchantAttrPayload({ fetching }));
    dispatch(setTaskTypePayload({ fetching }));
    CommonUtils.getMerchantDetails().then(res => {
      if (res.error) {
        dispatch(setMerchantAttrPayload());
        dispatch(setTaskTypePayload());
        return false;
      }
      dispatch(setMerchantAttrPayload({ data: res.merchant, fetching: false }));

      if (res.merchant.roles) {
        const userRoleList = cloneDeep(roleStaticList);
        Object.keys(res.merchant.roles).map(x => {
          userRoleList.push({ label: x, value: x, description: x });
        });
        dispatch(setRoleListPayload(userRoleList));
      }

      let list = taskTypeList;
      if (res.merchant.customConfig && res.merchant.customConfig.taskType) {
        if (typeof res.merchant.customConfig.taskType === "object") {
          list = [];
          Object.keys(res.merchant.customConfig.taskType).map(k => {
            list.push({
              label: k.trim(),
              value: k.trim(),
              subTypeList: res.merchant.customConfig.taskType[k]
                .split(",")
                .map(x => ({ label: x.trim(), value: x.trim() })),
            });
          });
        } else if (typeof res.merchant.customConfig.taskType === "string") {
          list = res.merchant.customConfig.taskType.split(",").map(x => ({ label: x.trim(), value: x.trim() }));
        }
      }
      dispatch(setTaskTypePayload({ list, fetching: false }));

      const integrations = get(res, "merchant.integrations", [])
        .filter(x => x.status === "Active")
        .map(x => x.service);

      const configList = get(res, "merchant.integrationConfig", {});
      Object.keys(configList).map(k => {
        const isExist = (isArray(configList[k]) ? configList[k] : []).some(x => x.status === "Active");
        if (isExist && !integrations.includes(k)) {
          integrations.push(k);
        }
      });
    });
  };

export const getMerchantIntegrationList = () => async dispatch => {
  dispatch(setFetchingMerchantIntegrationList(true));
  const response = await CommonUtils.getMerchantIntegrationList();
  dispatch(setFetchingMerchantIntegrationList(false));
  if (response.error || response.noContent) {
    dispatch(setMerchantIntegrationList([]));
    return false;
  }
  const processedData = filterAppStore(response || []).map(item => ({
    ...item,
    category: item.type,
    icon: getIntegrationImage(item),
  }));
  dispatch(setMerchantIntegrationList(processedData));

  const integrations = filterAppStore(response.filter(x => x.status === getStatus()));
  const merchantIntegrations = integrations.map(service => {
    const name = service.name;
    const id = `INT_API_${name.toLowerCase().replace(/ /g, "-")}`;
    const intent = `INT_API_${name.toLowerCase().replace(/ /g, "")}`;
    return {
      id,
      name: name,
      label: name,
      intent,
      templateName: intent,
      type: intent,
      image: getIntegrationImage(service),
      category: 3,
      height: 115,
      isFlow: true,
      integrationId: service.id,
    };
  });
  dispatch(setBotElementPayload(merchantIntegrations));
};

export const getAppstoreIntegrationDetail = id => async dispatch => {
  const response = await CommonUtils.getAppstoreIntegrationDetail(id);
  if (response.error) {
    dispatch(setCurrentIntegrationDetail(null));
    return false;
  }
  dispatch(setCurrentIntegrationDetail(response));
};

export const getIntegrationApisById = id => async dispatch => {
  const response = await CommonUtils.getIntegrationApisById(id);
  if (response.error) {
    dispatch(setIntegrationApiDetail([]));
    return false;
  }
  dispatch(setIntegrationApiDetail(response));
};
// get merchant attr list
export const getMerchantProfile =
  (id, fetching = true) =>
  dispatch => {
    dispatch(setMerchantProfilePayload({ fetching }));
    CommonUtils.getMerchantId(id || sessionUser()).then(res => {
      if (res.error) {
        dispatch(setMerchantProfilePayload());
        return false;
      }
      dispatch(setMerchantProfilePayload({ data: res, fetching: false }));
    });
  };

// get list of AI Agent Metrics
export const getAgentMetrics =
  (fetching = true, startDate, endDate) =>
  dispatch => {
    dispatch(setAgentMetricsPayload({ fetching }));
    CommonUtils.getAgentMetrics(startDate, endDate).then(res => {
      if (res.error) {
        dispatch(setAgentMetricsPayload());
        return false;
      }
      const obj = { list: res, fetching: false };
      if (!startDate || !endDate) {
        obj.totalList = res;
      }
      dispatch(setAgentMetricsPayload(obj));
    });
  };

// get list of Live Agents
export const getLiveAgents =
  (fetching = true) =>
  (dispatch, preProps) => {
    dispatch(setLiveAgentsPayload({ fetching }));

    const { common } = preProps();
    let agentTeam = common.agentTeam.loading ? null : common.agentTeam.data;

    const userAgentData = [CommonUtils.getUserAgents()];
    if (!agentTeam) {
      userAgentData.push(CommonUtils.getMerchantDepartment());
    }

    Promise.all(userAgentData).then(([resUserAgents, teamRes]) => {
      if (resUserAgents.error) {
        dispatch(setLiveAgentsPayload());
        return false;
      }

      if (teamRes && teamRes.error) {
        dispatch(setAgentTeamPayload());
      } else if (teamRes) {
        agentTeam = getDepartmentInfo(teamRes);
        dispatch(setAgentTeamPayload({ data: agentTeam, fetching: false }));
      }

      let arr = [];
      resUserAgents
        .filter(x => {
          switch (sessionRole()) {
            case "CALLCENTER_SUPERVISOR":
              return agentTeam.members.includes(x.id);
            case "CALLCENTER_USER3":
              return x.id === sessionUserId();
            default:
              return true;
          }
        })
        .map(r => {
          const obj = arr.find(a => a.email.trim().toLowerCase() == r.email.trim().toLowerCase());
          if (!obj) {
            r.departments = agentTeam && agentTeam.allDepartments ? agentTeam.allDepartments : [];
          }
          arr.push(r);
        });

      dispatch(setLiveAgentsPayload({ list: arr, fetching: false }));
    });
  };

export const updateMessageViewCount = (referenceID, sessionID, msgCount) => (dispatch, preProps) => {
  const previousVisitors = preProps().common.activeLiveVisitors.list;
  const preVisitorIndex = previousVisitors.findIndex(
    pv => pv.referenceID === referenceID && pv.sessionID === sessionID
  );
  if (preVisitorIndex != -1) {
    previousVisitors[preVisitorIndex].viewMessageCount = msgCount;
    const userAgentdata = {
      merchantID: sessionMerchantId(),
      id: sessionUserId(),
      visitorReadMessages: [
        {
          visitorSessionId: sessionID,
          msgReadCount: msgCount,
        },
      ],
    };
    CommonUtils.updateUserAgent(userAgentdata).then(response => {
      if (response.error) {
        return false;
      }
      dispatch(setActiveAgentPayload({ data: response, fetching: false }));
    });
    dispatch(setActiveLiveVisitorPayload(previousVisitors));
  }
};

// get list of Agent Team
export const getAgentTeamList =
  (fetching = true) =>
  dispatch => {
    dispatch(setAgentTeamPayload({ fetching }));

    CommonUtils.getMerchantDepartment().then(response => {
      if (response.error) {
        dispatch(setAgentTeamPayload());
        return false;
      }

      const team = getDepartmentInfo(response);
      dispatch(setAgentTeamPayload({ data: team, fetching: false }));
    });
  };

const getDepartmentInfo = response => {
  let team = {
    departments: [],
    members: [],
  };
  if (response.departments) {
    team.departments = response.departments.filter(d => d.deptContact && d.deptContact.includes(sessionUserId()));
    team.members = [
      ...new Set(
        response.departments
          .map(d => d.deptContact && d.deptContact.includes(sessionUserId()) && d.deptContact.split(","))
          .flat()
          .filter(e => e)
      ),
    ];
    team.allDepartments = response.departments;
  }
  return team;
};

// get list of Menus by role
export const getRolePermission =
  (fetching = true) =>
  dispatch => {
    dispatch(setRolePermissionPayload({ fetching }));

    CommonUtils.getRolePermissionByRole().then(response => {
      if (response.error) {
        dispatch(setRolePermissionPayload());
        return false;
      }

      const menu = [];
      const flatMenu = [];
      const routes = [];

      response
        .sort((a, b) => a.order - b.order)
        .map(m => {
          const { mPid, mType, mAccessList } = getAccessList(m);

          const flatMenuObj = {
            pageId: mPid,
            isSubMenu: false,
            type: mType,
            permission: m.permission,
            accessList: mAccessList,
            menuName: m.name,
            menuPageTitle: m.pageTitle,
            menuPageDesc: m.pageDescription,
          };

          flatMenu.push(flatMenuObj);
          let item = menuItems.find(a => a.pageId === mPid);

          if (!item && isURL(mPid)) {
            item = {
              url: mPid,
            };
          }
          const menuObj = {
            name: m.name,
            pageTitle: m.pageTitle,
            pageDescription: m.pageDescription,
            pageId: mPid,
            type: mType,
            icon: m.icon,
            iconImg: m.iconImg,
            subMenu: [],
            accessList: mAccessList,
          };

          if (item) {
            menuObj.url = item.url;
            flatMenuObj.target = item.url;
            if (m.submenu) menuObj.target = item.url;
            if (item.component) {
              routes.push({
                url: item.url,
                path: item.path || item.url,
                component: item.component,
                exact: item.exact,
                page: { title: menuObj.pageTitle || item.title, desc: menuObj.pageDescription || item.desc },
              });

              if (item.subRoutes) {
                routes.push({
                  url: item.subRoutes.url,
                  path: item.subRoutes.path | item.subRoutes.url,
                  component: item.subRoutes.component,
                  exact: item.subRoutes.exact,
                  page: { title: item.subRoutes.title, desc: item.subRoutes.desc },
                });
              }
            }
          }

          if (m.submenu) {
            m.submenu
              .sort((a, b) => a.order - b.order)
              .map(s => {
                const { sPid, sType, sAccessList } = getAccessList(s, true);
                const submenuItem = menuItems.find(b => b.pageId === sPid);
                if (submenuItem) {
                  flatMenu.push({
                    pageId: sPid,
                    isSubMenu: true,
                    type: sType,
                    permission: s.permission,
                    accessList: sAccessList,
                    parentMenuName: m.name,
                    parentMenuPageTitle: m.pageTitle,
                    parentMenuPageDesc: m.pageDescription,
                    menuName: s.name,
                    menuPageTitle: s.pageTitle,
                    menuPageDesc: s.pageDescription,
                    target: submenuItem.url,
                    ...(item ? { parentTarget: item.url } : []),
                  });
                  menuObj.subMenu.push({
                    title: s.name,
                    pageTitle: s.pageTitle,
                    pageDescription: s.pageDescription,
                    pageId: sPid,
                    target: submenuItem.url,
                  });
                  routes.push({
                    url: submenuItem.url,
                    path: submenuItem.path || submenuItem.url,
                    component: submenuItem.component,
                    page: { title: s.pageTitle || submenuItem.title, desc: s.pageDescription || submenuItem.desc },
                  });
                  if (submenuItem.subRoutes) {
                    routes.push({
                      url: submenuItem.subRoutes.url,
                      path: submenuItem.subRoutes.path || submenuItem.subRoutes.url,
                      component: submenuItem.subRoutes.component,
                      exact: submenuItem.subRoutes.exact,
                      page: { title: submenuItem.subRoutes.title, desc: submenuItem.subRoutes.desc },
                    });
                  }
                }
              });
          }

          if (menuObj.subMenu.length === 0) delete menuObj.subMenu;
          menu.push(menuObj);
        });

      dispatch(setRolePermissionPayload({ menu, routes, flatMenu, fetching: false }));
    });
  };
