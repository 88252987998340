import React from "react";
import { Row, Col, Collapse, Card, CardBody } from "reactstrap";
import { Button, Select, Input, ScrollBox, Attributes } from "../../../common/components/index.js";
import { getRandomNumber, isArray } from "../../../utils/GeneralUtils.js";
import CommonUtils from "../../../utils/CommonUtils.js";
import { getAttrByPrevElement } from "../../../utils/BotUtils.js";
import {
  SubmitElement,
  SelectElement,
  SelectIntent,
  SelectAttribute,
  ElementForm,
  OptionsElement,
} from "./components/index.js";
import { getFormDetails } from "../../../common/components/helper.js";
import { sourceList } from "../../../utils/ConstUtils.js";
import { cloneDeep } from "lodash";
import Sortable from "sortablejs";
import { combineObjectsFromArray, convertToKeyvalue } from "../../ModelStudio/helper.js";

const initGroup = {
  isOpen: true,

  text: "",
  groupName: "",

  source: "static-card",
  entity: null,
  externalAttributes: [],

  dynamicComponent: {
    attributes: [{ key: "", value: "" }],
  },
  customComponentList: [],
  customComponentListKey: Date.now(),

  errors: {
    groupName: null,
  },
};

class CustomComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      name: "",

      errorName: "",
      textTarget: 0,
      groups: [initGroup],

      intent: null,
      nextIntent: "",

      step: 1,

      externalAttributes: [],
      prevElementStructure: [],

      groupIndex: 0,
      listIndex: 0,
      subScreen: false,
    };

    this.listRef = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.setList = this.setList.bind(this);
    this.setDynamicList = this.setDynamicList.bind(this);
  }

  componentDidMount() {
    this.getEntityList();
    this.initializeSortable();
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      let { text, name, intent, nextIntent, groups } = box.payload;

      this.setState({
        text,
        name,
        intent,
        groups,
        nextIntent,
      });
    }
  }

  componentWillUnmount() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  }

  initializeSortable() {
    if (this.listRef.current) {
      this.sortable = new Sortable(this.listRef.current, {
        onEnd: this.handleDrop,
        animation: 200,
        filter: ".non-draggable-item",
      });
    }
  }

  handleDrop(event) {
    this.setState(prevState => {
      const { oldIndex, newIndex } = event;
      const { groups } = prevState;

      const updatedGroups = groups.map(group => {
        const { customComponentList } = group;
        const updatedList = cloneDeep(customComponentList);

        if (oldIndex < 0 || oldIndex >= updatedList.length || newIndex < 0 || newIndex >= updatedList.length) {
          return group;
        }

        const [removedItem] = updatedList.splice(oldIndex, 1);
        updatedList.splice(newIndex, 0, removedItem);

        return {
          ...group,
          customComponentList: updatedList,
          customComponentListKey: Date.now(),
        };
      });

      return { groups: updatedGroups };
    });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.step !== this.state.step) {
      this.initializeSortable();
    }
  }

  getEntityList() {
    CommonUtils.getEntityByMerchantId().then(response => {
      if (response.error) {
        this.setState({ entityList: [] });
        return false;
      }
      if (response && response.entities) {
        const list = response.entities.map(e => {
          return {
            entityId: e.entityId,
            entries: e.entries,
            name: e.name,
          };
        });
        let { entity } = this.state;
        if (entity) {
          this.onChangeEntity(
            entity,
            list.find(x => x.entityId === entity),
            0
          );
        }
        this.setState({ entityList: list });
      }
    });
  }

  handleStep(step, isSubmit = false) {
    const { name } = this.state;

    if (!name) {
      this.setState({ errorName: "Please Enter Name" });
      return;
    }
    let isError = false;

    this.state.groups.map((group, i) => {
      const validGroups = getFormDetails(group, (n, e) => this.onGroupChangeValidate(n, e, i));
      if (!validGroups) {
        isError = true;
        return false;
      }
    });

    if (isError) {
      return false;
    }

    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { handleSubmitElement } = this.props;
    const { prevElement, intent, nextIntent, text, name, groups } = this.state;

    const data = {
      input_type: "customComponent",
      request_params: [
        {
          text,
          name,
          groups: [],
        },
      ],
      next_intent: nextIntent,
    };

    groups.map((group, i) => {
      const list = cloneDeep(group.source === "dynamic-entity" ? [group.dynamicComponent] : group.customComponentList);

      data.request_params[0].groups.push({
        elements: list.map(c => ({
          ...combineObjectsFromArray(convertToKeyvalue(c.attributes)),
          buttons: c.dataQR,
        })),
        text: group.text,
        groupName: group.groupName,
      });
    });

    const qr = [];
    groups.map((g, gi) => {
      return (g.source === "dynamic-entity" ? [g.dynamicComponent] : g.customComponentList).map((c, ci) =>
        (c.payloadQR || []).map(b => {
          if (qr.findIndex(q => q.text === b.text && q.connection === b.connection && q.type === b.type) === -1) {
            qr.push({
              ...b,
              groupName: g.groupName,
            });
          }
        })
      );
    });

    const payload = {
      groups,
      qr,
      text,
      name,
      intent,
      nextIntent,
    };
    handleSubmitElement(data, prevElement, payload);
  }

  //#region onChange element

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onChangeEntity(value, obj, index) {
    const stateObj = {
      entity: value,
      externalAttributes: [],
    };
    if (obj && isArray(obj.entries)) {
      stateObj.externalAttributes = Object.keys(obj.entries[0]).map(x => ({
        label: `entity.${x}`,
        value: `{{entity_${value}.${x}}}`,
      }));
    }
    const list = cloneDeep(this.state.groups);
    list[index].entity = value;
    list[index].externalAttributes = stateObj.externalAttributes;
    this.setState({ groups: list, externalAttributes: stateObj.externalAttributes });
  }

  //#endregion

  //#region attribute events

  removeGroupItem(listName, index, groupIndex) {
    const groups = cloneDeep(this.state.groups);
    groups[groupIndex][listName].splice(index, 1);
    this.setState({ groups, customComponentListKey: Date.now() });
  }

  setList(list, type, index, groupIndex) {
    let arr = cloneDeep(this.state.groups);
    arr[groupIndex]["customComponentList"][index][type] = list;
    this.setState({
      groups: arr,
    });
  }

  setDynamicList(list, type, groupIndex) {
    let groups = cloneDeep(this.state.groups);
    groups[groupIndex].dynamicComponent = { ...groups[groupIndex].dynamicComponent, [type]: list };
    this.setState({ groups });
  }

  addButtonOrComponent(index) {
    const { groups } = this.state;
    const updatedGroups = cloneDeep(groups);

    updatedGroups[index].customComponentList = [
      ...updatedGroups[index].customComponentList,
      {
        id: getRandomNumber(6),
        attributes: [{ key: "", value: "" }],
        buttons: [],
      },
    ];
    updatedGroups[index].customComponentListKey = Date.now();
    this.setState({ groups: updatedGroups });
  }

  handleActionSave() {
    const { groups, listIndex, groupIndex } = this.state;
    const { source, customComponentList, dynamicComponent } = groups[groupIndex];

    const obj = this.optionRef.getOptions();
    if (!obj) return false;
    else {
      (source === "dynamic-entity" ? dynamicComponent : customComponentList[listIndex]).dataQR = obj.dataQR;
      (source === "dynamic-entity" ? dynamicComponent : customComponentList[listIndex]).payloadQR = obj.payloadQR;
    }

    groups[groupIndex] = {
      ...groups[groupIndex],
      customComponentList,
      dynamicComponent,
    };

    this.setState({
      groups,
      subScreen: false,
    });
  }
  handleActionCancel() {
    this.setState({
      subScreen: false,
    });
  }

  onGroupChange(name, value, index) {
    this.setState(prevState => {
      const updatedGroups = [...prevState.groups];
      updatedGroups[index] = { ...updatedGroups[index], [name]: value };
      return { groups: updatedGroups };
    });
  }
  onGroupChangeValidate(name, error, groupIndex) {
    this.setState(prevState => {
      const updatedGroups = cloneDeep(prevState.groups);
      error = error ? `Please Enter ${name.charAt(0).toUpperCase() + name.slice(1)}` : null;
      updatedGroups[groupIndex].errors[name] = error;
      return { groups: updatedGroups };
    });
  }

  addGroup() {
    this.setState(prevState => {
      return { groups: [...prevState.groups, initGroup] };
    });
  }

  toggleGroup(index) {
    this.setState(prevState => {
      const updatedGroups = prevState.groups.map((group, i) => {
        if (i === index) {
          return { ...group, isOpen: !group.isOpen };
        }
        return group;
      });
      return { groups: updatedGroups };
    });
  }

  deleteGroup(index) {
    this.setState(prevState => {
      const updatedGroups = [...prevState.groups.slice(0, index), ...prevState.groups.slice(index + 1)];
      return { groups: updatedGroups };
    });
  }

  //#endregion

  //#region render elements

  renderActions() {
    const { prevElementStructure, groups, groupIndex, listIndex } = this.state;
    const { source, dynamicComponent, customComponentList, externalAttributes } = groups[groupIndex];
    const { payloadQR } = source === "dynamic-entity" ? dynamicComponent : customComponentList[listIndex];

    return (
      <div className="ele-content">
        <OptionsElement
          pageTitle="Add Actions"
          {...this.props}
          payloadQR={isArray(payloadQR) ? payloadQR : undefined}
          ref={e => (this.optionRef = e)}
          prevElementStructure={[...externalAttributes, ...prevElementStructure]}
        />
        <div className="mt-2">
          <Button text="Save" submitBtn className="mr10" onClickFunc={() => this.handleActionSave(groupIndex)} />
          <Button text="Cancel" cancelBtn onClickFunc={() => this.handleActionCancel()} />
        </div>
      </div>
    );
  }

  renderStaticCard(group, groupIndex) {
    const { attributeList } = this.props;
    const { externalAttributes } = group;
    const { prevElementStructure } = this.state;
    return (
      <React.Fragment>
        <div ref={this.listRef} className="row draggable">
          {group.customComponentList.map((obj, index) => {
            return (
              <Col md={4} key={index + group.customComponentListKey} className="mb10 position-relative">
                <div className="c-box">
                  <span
                    className="c-close cp-action-box-small"
                    onClick={() => this.removeGroupItem("customComponentList", index, groupIndex)}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                  <div style={{ margin: "25px 12px 50px 12px" }}>
                    <Attributes
                      size="sm"
                      type="attributes"
                      keyWidth={40}
                      list={obj.attributes}
                      setList={(list, type) => this.setList(list, type, index, groupIndex)}
                      fileUploadDir="botStudio"
                      attributeButton={{
                        attributeList,
                        externalAttributes,
                        prevElementStructure,
                      }}
                    />
                  </div>
                  <hr className="mt0" style={{ position: "absolute", bottom: 20, left: 0, right: 0 }} />
                  <Button
                    className="cb-submit"
                    text={`Add Actions${
                      isArray(group.customComponentList[index].payloadQR)
                        ? ` (${group.customComponentList[index].payloadQR.length})`
                        : ""
                    }`}
                    name="buttons"
                    onClickFunc={() => this.setState({ groupIndex, listIndex: index, subScreen: true })}
                    style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                  />
                </div>
              </Col>
            );
          })}

          <Col md={4} className="mb10 non-draggable-item">
            <div className="c-box">
              <Button className="plus" text="+" onClickFunc={() => this.addButtonOrComponent(groupIndex)} />
            </div>
          </Col>
        </div>
      </React.Fragment>
    );
  }

  renderDynamicEntity(group, groupIndex) {
    const { prevElementStructure } = this.state;
    const { externalAttributes } = group;

    return (
      <React.Fragment>
        <div className="row draggable">
          <Col md={4} className="mb10 position-relative">
            <div className="c-box">
              <div style={{ margin: "25px 12px 50px 12px" }}>
                <Attributes
                  size="sm"
                  type="attributes"
                  keyWidth={40}
                  list={group.dynamicComponent.attributes}
                  setList={(list, type) => this.setDynamicList(list, type, groupIndex)}
                  fileUploadDir="botStudio"
                  attributeButton={{
                    attributeList: this.props.attributeList,
                    externalAttributes,
                    prevElementStructure,
                  }}
                />
              </div>

              <hr className="mt0" style={{ position: "absolute", bottom: 20, left: 0, right: 0 }} />
              <Button
                className="cb-submit"
                text={`Add Actions${isArray(group.dynamicComponent.payloadQR) ? ` (${group.dynamicComponent.payloadQR.length})` : ""}`}
                name="buttons"
                onClickFunc={() => {
                  this.setState({ groupIndex, subScreen: true });
                }}
                style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
              />
            </div>
          </Col>
        </div>
      </React.Fragment>
    );
  }

  //#endregion

  //#region render steps

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  renderStep1() {
    const {
      text,
      name,
      errorName,
      textTarget,
      prevElement,
      externalAttributes,
      prevElementStructure,
      entityList,
      groups,
      groupIndex,
      subScreen,
    } = this.state;

    const { boxes, botElementId, connectors, attributeList } = this.props;

    if (subScreen) {
      return this.renderActions();
    }
    // if (this.state.groups && this.state.groups.length && this.state.groups[groupIndex].subScreen) {
    //   return this.addButton(groupIndex);
    // }

    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm title="Name" flClassName="req-lbl">
            <div className="position-relative">
              <Input
                name="name"
                placeholder="Enter Name"
                outerClassName="mb0"
                value={name}
                onChangeFunc={(name, value, err, obj, e) => this.setState({ [name]: value, errorName: "" })}
                isReq={true}
                error={errorName}
              />
            </div>
          </ElementForm>
        </div>
        <div className="b-form">
          <ElementForm title="Message">
            <div className="position-relative">
              <Input
                name="text"
                placeholder="Enter message"
                outerClassName="mb0"
                appendIcon={
                  <SelectAttribute
                    attributeList={attributeList}
                    externalAttributes={[...externalAttributes, ...prevElementStructure]}
                    onChangeFunc={value => this.setState({ text: value })}
                    setAttribute={[textTarget, text]}
                  />
                }
                value={text}
                onChangeFunc={(name, value, err, obj, e) => this.setState({ [name]: value, textTarget: e.target })}
              />
            </div>
          </ElementForm>
        </div>
        <div className="b-form">
          <ElementForm title="Previous Element">
            <div className="position-relative">
              <SelectElement
                value={prevElement}
                botElementId={botElementId}
                boxes={boxes}
                connectors={connectors}
                outerWidth={0}
                outerClassName=""
                onChangeFunc={this.onChangePrevElement}
              />
            </div>
          </ElementForm>
        </div>

        {groups.map((group, index) => (
          <React.Fragment key={index}>
            <Row className={index !== 0 ? "mt20" : ""}>
              <Col xs="9" className="d-flex align-items-center">
                <div className="b-form" style={{ marginLeft: -90, marginBottom: -25 }}>
                  <ElementForm title="Group Name" flClassName="req-lbl">
                    <div className="position-relative">
                      <Input
                        name="groupName"
                        placeholder="Group Name"
                        outerClassName="mb2"
                        value={group.groupName}
                        isReq={true}
                        error={group.errors && group.errors.groupName ? group.errors.groupName : null}
                        onChangeFunc={(name, value, err, obj, e) => this.onGroupChange(name, value, index)}
                        validationFunc={(n, e) => this.onGroupChangeValidate(n, e, index)}
                      />
                    </div>
                  </ElementForm>
                </div>
              </Col>
              <Col xs="3" className="text-right mt20">
                {groups.length !== 1 && (
                  <Button
                    outline
                    title="Delete"
                    className="action-btn mr5"
                    customIcon="fa-trash-o font-sm"
                    onClickFunc={() => this.deleteGroup(index)}
                  />
                )}

                <i
                  className={`btn btn-outline-secondary btn-sm arrow font-weight-bold fa ${
                    group.isOpen ? "fa-angle-up" : "fa-angle-down"
                  }`}
                  onClick={() => this.toggleGroup(index)}
                ></i>
              </Col>
            </Row>

            <Collapse isOpen={group.isOpen}>
              <Card className="light-box mb0">
                <CardBody>
                  <Row>
                    <Col md={5}>
                      <div className="b-form" style={{ marginLeft: -113 }}>
                        <ElementForm title="Message">
                          <div className="position-relative">
                            <Input
                              name="text"
                              placeholder="Enter Message"
                              outerClassName="mb0"
                              value={group.text}
                              onChangeFunc={(name, value) => {
                                this.onGroupChange(name, value, index);
                              }}
                              appendIcon={
                                <SelectAttribute
                                  attributeList={attributeList}
                                  externalAttributes={[...group.externalAttributes, ...prevElementStructure]}
                                  onChangeFunc={value => {
                                    this.onGroupChange("text", value, index);
                                  }}
                                  setAttribute={[null, group.text]}
                                />
                              }
                            />
                          </div>
                        </ElementForm>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={5}>
                      <div className="b-form" style={{ marginLeft: -113 }}>
                        <ElementForm title="Source">
                          <div className="position-relative">
                            <Select
                              options={sourceList}
                              name="source"
                              value={group.source}
                              isClearable={false}
                              onChangeFunc={(name, value) => {
                                this.onGroupChange(name, value, index);
                              }}
                            />
                          </div>
                        </ElementForm>
                      </div>
                    </Col>
                    <Col md={5}>
                      {group.source !== "static-card" && (
                        <div className="b-form" style={{ marginLeft: -113 }}>
                          <ElementForm title="Entity">
                            <div className="position-relative">
                              <Select
                                options={entityList}
                                name="entity"
                                value={group.entity}
                                labelKey="name"
                                valueKey="entityId"
                                onChangeFunc={(name, value, error, obj) => this.onChangeEntity(value, obj, index)}
                              />
                            </div>
                          </ElementForm>
                        </div>
                      )}
                    </Col>
                  </Row>

                  {group.source === "static-card"
                    ? this.renderStaticCard(group, index)
                    : this.renderDynamicEntity(group, index)}
                </CardBody>
              </Card>
            </Collapse>
          </React.Fragment>
        ))}

        <Card className="mb0 mt20 v1-design">
          <CardBody>
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <p style={{ color: "rgb(153, 153, 153)" }}>
                If you like to add attribute list group, you may create Group attribute by clicking the below button.
              </p>
              <Button onClickFunc={() => this.addGroup()} text="Add Group" className="btn-v2" />
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }

  render() {
    const { step, subScreen } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step + subScreen} scrollClass="bt-send-carousel" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
          hideButtons={subScreen}
        />
      </React.Fragment>
    );
  }
}

export default CustomComponent;
