import React from "react";
import { Button, Input } from "../../../../../common/components";
import { isArray } from "../../../../../utils/GeneralUtils";

function Submit(props) {
  const { obj, setAction } = props;
  return (
    <React.Fragment>
      <Button
        className="cb-submit"
        text={`Add Actions${isArray(obj.payloadQR) ? ` (${obj.payloadQR.length})` : ""}`}
        name="buttons"
        onClickFunc={setAction}
      />
    </React.Fragment>
  );
}

export default Submit;
