import React from "react";
import { Button, Input, ScrollBox, Select } from "../../../common/components";
import { ElementForm, SelectElement, SubmitElement, SelectIntent } from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { dataSourceList } from "../../../utils/ConstUtils";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { cloneDeep } from "lodash";
import CommonUtils from "./../../../utils/CommonUtils.js";

const dataSourceToSetLabels = ["purple_cloud_persistent"];
const keysToDisplayAsLabels = {
  url: "URL",
  database: "Database",
  collection: "Collection Name",
};

const defaultDataSourceKeys = [
  {
    key: "url",
    value: "",
  },
  {
    key: "database",
    value: "",
  },
  {
    key: "collection",
    value: "",
  },
];
class DataSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intent: null,
      step: 1,
      nextIntent: "",
      dataSource: null,
      activeTab: "inputParams",
      inputParamsList: [{ key: "", value: "" }],
      asyncExecution: false,
      messageInfo: "",
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onChangeDataSource = this.onChangeDataSource.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.checkMongodbConnection = this.checkMongodbConnection.bind(this);
  }

  async checkMongodbConnection() {
    this.setState({ messageInfo: "", loading: true });
    const mongoDBUrl = this.state.inputParamsList.find(inputParam => inputParam.key === "url").value;
    const result = await CommonUtils.checkMongodbConnection({ mongoDBUrl });
    this.setState({ loading: false });
    if (result.success) {
      this.setState({ messageInfo: "Test connection succeeded." });
    } else {
      this.setState({ messageInfo: `Test connection failed. ${result.errorMessage}` });
    }
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { intent, nextIntent, dataSource, inputParamsList, asyncExecution = false } = box.payload;
      this.setState({ nextIntent, intent: intent || null, dataSource, inputParamsList, asyncExecution });
    }
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { intent, nextIntent, prevElement, dataSource, inputParamsList, asyncExecution } = this.state;
    const { handleSubmitElement } = this.props;
    let data = {
      input_type: "dataSource",
      next_intent: nextIntent,
      request_params: [
        {
          asyncExecution,
          dataSource,
          inputParamsList,
        },
      ],
    };
    const payload = {
      intent,
      nextIntent,
      dataSource,
      inputParamsList,
      asyncExecution,
    };
    handleSubmitElement(data, prevElement, payload);
  }

  onChangePrevElement(name, value) {
    this.setState({ [name]: value });
  }

  onChangeDataSource(name, value) {
    this.setState({ inputParamsList: [{ key: "", value: "" }] }, () => {
      const { inputParamsList } = this.state;
      if (dataSourceToSetLabels.includes(value)) {
        if (inputParamsList[0].key != "url") {
          this.setState({
            inputParamsList: [...defaultDataSourceKeys, ...inputParamsList],
          });
        }
      }
    });
    this.setState({ [name]: value });
  }

  addFeature(listName) {
    let list = cloneDeep(this.state[listName]);
    list.push({
      key: "",
      value: "",
    });
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  removeFeature(listName, index) {
    let list = cloneDeep(this.state[listName]);
    list.splice(index, 1);
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  onChangeFeature(name, value, index, listName) {
    let list = cloneDeep(this.state[listName]);
    list[index][name] = value;
    this.setState({ [listName]: list });
  }

  displayKeyAsLabel(key, index) {
    const { dataSource } = this.state;
    return keysToDisplayAsLabels.hasOwnProperty(key) && dataSourceToSetLabels.includes(dataSource) && index < 3;
  }

  displayMinusButton(key, index) {
    const { dataSource, inputParamsList } = this.state;
    return (
      !this.displayKeyAsLabel(key, index) &&
      (dataSourceToSetLabels.includes(dataSource)
        ? index == 3
          ? inputParamsList[index + 1]
          : true
        : index !== 0 || inputParamsList.length > 1)
    );
  }

  renderTab() {
    const { activeTab, inputParamsList, listKey } = this.state;
    return (
      <div className="postman-head">
        <Nav className="nav-tab">
          <NavItem>
            <NavLink className={classnames({ active: activeTab === "inputParams" })}> Input Parameters</NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="inputParams">
            {activeTab === "inputParams" &&
              inputParamsList.map((obj, index) => {
                return (
                  <div key={`i${index}${listKey}`} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5} className="text-right">
                          {this.displayKeyAsLabel(obj.key, index) ? (
                            <label>{keysToDisplayAsLabels[obj.key]}</label>
                          ) : (
                            <Input
                              type="text"
                              placeholder="Enter Key"
                              name="key"
                              value={obj.key}
                              onChangeFunc={(name, value) =>
                                this.onChangeFeature(name, value, index, "inputParamsList")
                              }
                            />
                          )}
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "inputParamsList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === inputParamsList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("inputParamsList")} />
                            ) : null}
                            {this.displayMinusButton(obj.key, index) ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("inputParamsList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
        </TabContent>
      </div>
    );
  }

  renderStep1() {
    const { prevElement, dataSource, asyncExecution, messageInfo, loading } = this.state;
    const { botElementId, boxes, connectors } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Data Source">
            <Select
              name="dataSource"
              outerClassName="mb0"
              title="Data Source"
              outerWidth={300}
              value={dataSource}
              options={dataSourceList}
              onChangeFunc={this.onChangeDataSource}
            />
          </ElementForm>
          {dataSource && (
            <React.Fragment>
              <ElementForm title="Async Execution">
                <Input
                  type="checkbox"
                  name="asyncExecution"
                  outerClassName="float-left mb0"
                  style={{ width: 20 }}
                  checked={asyncExecution}
                  onChangeFunc={name => this.setState({ [name]: !asyncExecution })}
                />
              </ElementForm>
              <ElementForm title="" frMaxWidth="unset">
                {this.renderTab()}
                {dataSource === "purple_cloud_persistent" && (
                  <div>
                    <Button
                      text="Test Connection"
                      submitBtn
                      displayIcon={false}
                      className="btn-v1 mt10"
                      loading={loading}
                      onClickFunc={this.checkMongodbConnection}
                    />

                    {messageInfo && (
                      <p
                        className={` bg-gray-100 border border-gray-200 br4 fs13 fw-semibold mt-2 px-2 py-1 ${
                          messageInfo.includes("succeeded") ? "text-success" : "text-danger"
                        }`}
                      >
                        {messageInfo}
                      </p>
                    )}
                  </div>
                )}
              </ElementForm>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { botElementId, resetElement } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-json-api" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          step={step}
          botElementId={botElementId}
          handleSubmit={this.handleSubmit}
          handleStep={this.handleStep}
          resetElement={resetElement}
        />
      </React.Fragment>
    );
  }
}

export default DataSource;
