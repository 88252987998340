import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useSelector } from "react-redux";

const getBreadcrumbItems = pathname => {
  const rolePermission = useSelector(state => state.common.rolePermission);
  const items = [BreadcrumbsItem("Home", "/")];
  if (rolePermission.flatMenu) {
    rolePermission.flatMenu.map(x => {
      if (x.isSubMenu) {
        if (pathname.startsWith(x.target)) {
          items.push(BreadcrumbsItem(x.parentMenuName, x.parentTarget));
          items.push(BreadcrumbsItem(x.menuName, x.target));
        }
      } else {
        if (x.target == pathname || pathname.startsWith(`${x.target}/`)) {
          items.push(BreadcrumbsItem(x.menuName, x.target));
        }
      }
    });
  }
  return items;
};

const BreadcrumbsItem = (routeName, url) => {
  if (routeName) {
    return routeName !== "Home" ? (
      <BreadcrumbItem active>{routeName}</BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        <Link to={url || ""}>{routeName}</Link>
      </BreadcrumbItem>
    );
  }
  return null;
};

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  return <Breadcrumb>{getBreadcrumbItems(pathname)}</Breadcrumb>;
};

export default Breadcrumbs;
