import React from "react";
// import PropTypes from 'prop-types';
import { Button } from "./../common/components";

const handleSubmit = props => {
  if (props.handleClick) props.handleClick();
  let element = props.isUnauth ? document.getElementById("unauthorized-box") : document.getElementById("timeout-box");
  if (element) {
    element.style.display = "none";
  }
};

const TimeoutBox = props => {
  return (
    <div id={props.isUnauth ? "unauthorized-box" : "timeout-box"} className="unauthorized-box">
      <div className="inner-box">
        <div className="d-flex justify-content-center ">
          <img src="/img/v1/common/timeout.svg"></img>
        </div>
        <div className="title text-center">Session Timeout!</div>
        <div className="message text-center">
          {props.isUnauth
            ? "Your session is unauthorized please log out"
            : "Your session is about to expire in 1 minute due to inactivity. You will be redirected to the login page."}
        </div>
        <div className="text-center">
          <Button
            submitBtn
            size="normal"
            displayIcon={false}
            text={props.isUnauth ? "Log out " : "Stay Logged In"}
            className="btn btn-themes waves-effect waves-light cb cb-back"
            onClickFunc={() => handleSubmit(props)}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeoutBox;
