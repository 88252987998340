import React from "react";
import { Button, Input, ScrollBox } from "../../../common/components";
import { Row, Col } from "reactstrap";
import { SelectElement, ElementForm, SubmitElement, SelectAttribute } from "./components";
import SelectIntent from "./components/SelectIntent";
import { addErrorObj } from "../../../utils/GeneralUtils";
import { getAttrByPrevElement } from "../../../utils/BotUtils";
import { getFormDetails } from "../../../common/components/helper.js";

class SetUserData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attrKey: Date.now(),
      attributes: [{ key: "", value: "", errors: { key: null, value: null } }],
      nextIntent: "",
      intent: null,
      step: 1,
      prevElementStructure: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onListValidate = this.onListValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    const { errors } = this.state.attributes[0];
    if (box) {
      const { attributes, nextIntent, intent } = box.payload;
      this.setState({
        attributes: attributes.length > 0 ? addErrorObj(attributes, errors) : this.state.attributes,
        nextIntent,
        intent,
      });
    }
  }

  addAttr() {
    const { attributes } = this.state;
    attributes.push({ key: "", value: "", errors: { key: null, value: null } });
    this.setState({ attributes, attrKey: Date.now() });
  }

  removeAttr(index) {
    const { attributes } = this.state;
    attributes.splice(index, 1);
    this.setState({ attributes, attrKey: Date.now() });
  }

  onChangeAttr(name, value, index) {
    const { attributes } = this.state;
    attributes[index][name] = value;
    this.setState({ attributes });
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onListValidate(name, error, index) {
    let attributes = this.state.attributes;
    attributes[index]["errors"][name] = error;
    this.setState({ attributes });
  }

  handleStep(step, isSubmit = false) {
    const { attributes } = this.state;
    let isError = false;
    attributes.map((a, i) => {
      const list = getFormDetails(a, (n, e) => this.onListValidate(n, e, i));
      if (!list) {
        isError = true;
        return false;
      }
    });
    if (isError) {
      return false;
    }

    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { prevElement, attributes, nextIntent, intent } = this.state;
    const { handleSubmitElement } = this.props;

    const data = {
      input_type: "setUserData",
      next_intent: nextIntent,
      request_params: [
        {
          attributes: attributes.map(x => ({ value: x.value, key: x.key })),
        },
      ],
    };
    const payload = {
      attributes,
      nextIntent,
      intent,
    };
    handleSubmitElement(data, prevElement, payload);
  }

  renderStep1() {
    const { attrKey, attributes, prevElement, prevElementStructure } = this.state;
    const { boxes, botElementId, connectors, attributeList } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
        </div>
        <label className="name mt10">Add Attributes</label>
        {attributes.map((obj, index) => {
          return (
            <div key={index + attrKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
              <div className="input-box">
                <Row>
                  <Col md={5}>
                    <Input
                      type="text"
                      placeholder="Enter Key"
                      title="Key"
                      name="key"
                      isReq={true}
                      error={obj.errors.key}
                      value={obj.key}
                      onChangeFunc={(name, value) => this.onChangeAttr(name, value, index)}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    />
                  </Col>
                  <Col md={5}>
                    <Input
                      type="text"
                      placeholder="Enter Value"
                      title="Value"
                      name="value"
                      isReq={true}
                      error={obj.errors.value}
                      value={obj.value}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={prevElementStructure}
                          onChangeFunc={(value, inputName) => {
                            this.onChangeAttr("value", value, index), this.onListValidate(inputName, null, index);
                          }}
                          setAttribute={[null, obj.value]}
                        />
                      }
                      onChangeFunc={(name, value) => this.onChangeAttr(name, value, index)}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    />
                  </Col>
                  <Col md={2}>
                    <div className="btn-box">
                      {index === attributes.length - 1 ? (
                        <Button plusBtn className="d-inline mr10" onClickFunc={() => this.addAttr()} />
                      ) : null}
                      {attributes.filter(x => !x.isDefault).length > 1 ? (
                        <Button minusBtn className="d-inline" onClickFunc={() => this.removeAttr(index)} />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="clearfix"></div>
            </div>
          );
        })}
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { resetElement, botElementId } = this.props;
    const { step } = this.state;

    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-send-msg-group" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default SetUserData;
