import React from "react";
import { useSelector } from "react-redux";
import { getCountryFlag, getDevice, getOS, getEmojiFace } from "../../../utils/IconUtils";
import {
  emailLink,
  phoneLink,
  LeadStatusFormatter,
  UrlFormatter,
  ChannelFormatter,
  getCompany,
  getAgentDetails,
} from "../../../utils/Formatter";
import {
  convertSecToMin,
  convertUTCtoLocal,
  getValue,
  getVisitorAddress,
  getVisitorLocation,
  splitDate,
} from "../../../utils/GeneralUtils";
import { dateComparator, dateFilterOptions } from "./RowFilter";
import TimeAgo from "react-timeago";
import { get, isEqual } from "lodash";

const infoCell = ({ data }) => {
  if (!data) return "";

  return (
    <div className="cell-info-icon">
      {data.country ? <span className={getCountryFlag(data.country, "")} title={data.country}></span> : <span></span>}
      {data.browser ? (
        <img
          src={"/img/browser/" + (data.browser != "unknown" ? data.browser : "Browser") + ".png"}
          title={data.browser != "unknown" ? data.browser : "Browser"}
        />
      ) : (
        <span></span>
      )}
      {data.os ? <span className={getOS(data.os, "")} title={data.os}></span> : <span></span>}
      {data.device ? <span className={getDevice(data.device, "")} title={data.device}></span> : <span></span>}
    </div>
  );
};

const csrCell = ({ value }) => {
  const csrObj = typeof value === "object" ? value : { score: value };
  return (
    <div className="text-center">
      <img
        src={getEmojiFace(csrObj)}
        title={`Score: ${csrObj ? csrObj.score : 0}`}
        width={20}
        alt={`Score: ${csrObj ? csrObj.score : 0}`}
      />
    </div>
  );
};

const channelCell = ({ value }) => {
  return (
    <div className="cell-channel">
      <ChannelFormatter value={value} />
    </div>
  );
};

const emailCell = ({ value }) => {
  return <div className="cell-email">{emailLink(value)}</div>;
};

const phoneCell = ({ value, data }) => {
  if (!data) return "";

  return <div className="cell-phone">{phoneLink(value, data["country"])}</div>;
};

const urlCell = ({ value }) => {
  return (
    <div className="cell-url">
      <UrlFormatter value={value} />
    </div>
  );
};

const secToMinCell = ({ value }) => convertSecToMin(value) || "NA";

const durationCell = ({ value }) => <TimeAgo date={splitDate(value)} />;

const utcToLocalCell = ({ value }) => convertUTCtoLocal(value) || "";

const commaSeperator = ({ value }) => {
  return value.split(",").join(", ");
};

const numberCell = ({ value }) => value || 0;

const companyCell = ({ value }) => getCompany(value || "");

const actionCell = ({ colDef, data }) => {
  return (
    <div className="cell-action-icon">
      {colDef.editFunc ? (
        <i className="icon-note cursor-pointer" title="Edit" onClick={() => colDef.editFunc(data)}></i>
      ) : null}
      {colDef.deleteFunc ? (
        <i className="icon-trash cursor-pointer" title="Delete" onClick={() => colDef.deleteFunc(data)}></i>
      ) : null}
      {colDef.navFunc ? (
        <i className="icon-info cursor-pointer" title="Information" onClick={() => colDef.navFunc(data)}></i>
      ) : null}
      {colDef.cloneFunc ? (
        <i className="fa fa-clone cursor-pointer" title="Clone" onClick={() => colDef.cloneFunc(data)}></i>
      ) : null}
    </div>
  );
};

const taskTypeCell = ({ value }) => {
  const taskTypeList = useSelector(state => state.common.taskTypeList.list, isEqual);
  return (taskTypeList.find(x => x.value === value) || { label: value }).label || "";
};

const locationCell = ({ data }) => getVisitorLocation(data);

const addressCell = ({ value }) => getVisitorAddress(value);

const engAgentCell = ({ data }) => {
  if (!data) return "";

  const agentObj = getAgentDetails(data);
  if (!agentObj.agent) return null;
  return (
    <div title={`${agentObj.humanAgent ? "User" : "AI"} Agent`}>
      <img
        height="25"
        width="25"
        className="img-avatar mr5"
        style={{ border: "1px solid #999" }}
        src={agentObj.agentImg}
      />
      {agentObj.agent}
    </div>
  );
};

export const handleCellRendering = (x, enableServerSide) => {
  const lowerField = x.field.toLowerCase();
  const lowerHeader = x.headerName.toLowerCase();

  if ((lowerField.includes("date") || lowerField.includes("time")) && x.filter !== false) {
    x.filter = "agDateColumnFilter";
  } else if (lowerField.includes("email")) {
    x.cellRenderer = "emailCell";
  } else if (lowerField.includes("recipients")) {
    x.cellRenderer = "commaSeperator";
  } else if (lowerField.includes("info")) {
    x.cellRenderer = "infoCell";
  } else if (lowerField.includes("company") || lowerField.includes("business")) {
    x.cellRenderer = "companyCell";
  } else if (lowerField.includes("channel")) {
    x.cellRenderer = "channelCell";
  } else if (
    lowerField.includes("cell") ||
    lowerField.includes("home") ||
    lowerField.includes("phone") ||
    lowerHeader.includes("phone")
  ) {
    x.cellRenderer = "phoneCell";
  }

  if (x.field === "action") {
    x.cellRenderer = "actionCell";
  }
  if (x.filter === "agDateColumnFilter") {
    x.filterParams = {
      ...dateFilterOptions,
      ...(x.filterParams || {}),
    };
    if (x.comparator !== false) {
      x.filterParams.comparator = dateComparator;
    }
    if (!enableServerSide) {
      delete x.filterParams.filterOptions;
    }
  }

  switch (x.cellRenderer) {
    case "actionCell":
      x.minWidth = x.minWidth || 140;
      x.maxWidth = x.maxWidth || 140;
      x.cellClass = "border-0";
      x.sortable = false;
      x.filter = false;
      break;
    case "infoCell":
      x.minWidth = x.minWidth || 130;
      x.cellClass = "border-0";
      x.sortable = false;
      x.filter = false;
      break;
    case "csrCell":
      x.minWidth = x.minWidth || 100;
      break;
    case "channelCell":
      x.minWidth = x.minWidth || 180;
      break;
    case "emailCell":
      x.minWidth = x.minWidth || 240;
      break;
    case "phoneCell":
      x.minWidth = x.minWidth || 200;
      break;
    case "urlCell":
      x.minWidth = x.minWidth || 300;
      break;
    case "secToMinCell":
      x.minWidth = x.minWidth || 160;
      break;
    case "numberCell":
      x.minWidth = x.minWidth || 120;
      x.filter = "agNumberColumnFilter";
      break;
    case "companyCell":
      x.minWidth = x.minWidth || 350;
      break;
    default:
      break;
  }

  x.unSortIcon = x.sortable !== false;

  if (x.fields) {
    x.valueGetter = v => {
      return getValue(v.data, v.column.colDef.fields, x.delimiter);
    };
  }
  if (x.field.includes(".")) {
    x.valueGetter = v => get(v.data, v.column.colDef.field);
  }
  if (x.checkBox) {
    if (!enableServerSide) {
      x.headerCheckboxSelection = x.headerCheckboxSelection || false;
      x.headerCheckboxSelectionFilteredOnly = true;
    }
    x.checkboxSelection = true;
  }
  return x;
};

export default {
  infoCell,
  csrCell,
  channelCell,
  emailCell,
  phoneCell,
  durationCell,
  urlCell,
  secToMinCell,
  numberCell,
  companyCell,
  actionCell,
  taskTypeCell,
  locationCell,
  addressCell,
  engAgentCell,
  commaSeperator,
  utcToLocalCell,
};
