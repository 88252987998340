import React, { Component } from "react";
import PropTypes from "prop-types";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      position: 0,
    };
    this.modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", { color: [] }, { background: [] }],
        ["clean"],
      ],
    };
  }
  // check quill methods for selection
  // componentDidMount() {
  //   if (typeof this.editorRef.getEditor === 'function') {
  //     let lol = this.editorRef
  //     let range = this.editorRef.getEditorSelection();
  //     let position = range ? range.index : 0;
  //     this.setState({ position })
  //   }
  // }

  componentDidMount() {
    if (this.props.editorRef) {
      this.props.editorRef(this.editorRef);
    }
  }

  handleChange(name, value) {
    // if (this.props.onChangeFunc) this.props.onChangeFunc(name, value === "<p><br></p>" ? '' : value);
    const { onChangeFunc, validationFunc, title, isReq } = this.props;
    const { position } = this.state;
    if (onChangeFunc) onChangeFunc(name, value === "<p><br></p>" ? "" : value, position);
    if ((isReq, validationFunc))
      validationFunc(name, value == "" || value == "<p><br></p>" ? `Please enter ${title}` : null);
  }

  handleBlur() {
    const { onChangeFunc, validationFunc, name, title, value, isReq } = this.props;
    if (onChangeFunc) onChangeFunc(name, value === "<p><br></p>" ? "" : value);
    if ((isReq, validationFunc))
      validationFunc(name, value == "" || value == "<p><br></p>" ? `Please enter ${title}` : null);
  }

  render() {
    const { title, error, value, name, appendIcon, readOnly } = this.props;
    return (
      <React.Fragment>
        <div className="d-flex position-relative" style={{ flexDirection: "column" }}>
          <ReactQuill
            theme="snow"
            ref={el => {
              this.editorRef = el;
            }}
            modules={this.modules}
            value={value}
            readOnly={readOnly}
            onChange={val => this.handleChange(name, val)}
            // onBlur={this.handleBlur}
          />
          {appendIcon && appendIcon}
        </div>
        {error && <span className="req-msg">{error === true ? `Please enter ${title}` : error}</span>}
      </React.Fragment>
    );
  }
}

TextEditor.defaultProps = {
  title: "value",
  value: "",
  isReq: false,
  readOnly: false,
};

TextEditor.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isReq: PropTypes.bool,
  onChangeFunc: PropTypes.func,
  validationFunc: PropTypes.func,
  editorRef: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default TextEditor;
