import React from "react";
import PropTypes from "prop-types";

const PageHead = props => {
  const { title, details, desc, descColor, descIcon, rightSec, headerWidth, className } = props;
  return (
    <div className={`m-head-box m-box mb-2 ${className ? className : ""}`}>
      <div>
        <div className="m-box-title-xl">
          {title}
          {details && (
            <span className="fs15 ml-2 vc-secondary">
              <i className="fa fa-angle-double-right mr-1 vc-secondary"></i>
              {details}
            </span>
          )}
        </div>
        {desc && (
          <div className="m-box-desc" style={{ color: descColor ? descColor : "" }}>
            <i className={`fa ${descIcon || "fa-hand-o-right"}`}></i>
            {desc}
          </div>
        )}
      </div>
      {rightSec && <div className="flex-shrink-0 ml-3">{rightSec}</div>}
    </div>
  );
};

PageHead.defaultProps = {
  title: null,
  desc: null,
  details: null,
  rightSec: null,
  headerWidth: null,
  descColor: null,
};

PageHead.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  details: PropTypes.any,
  rightSec: PropTypes.any,
  headerWidth: PropTypes.string,
  descColor: PropTypes.string,
};

export default PageHead;
