import React from "react";
import { Input, ScrollBox } from "../../../common/components";
import { getAttrByPrevElement } from "../../../utils/BotUtils";
import { SelectElement, SelectAttribute, ElementForm, SubmitElement, SelectIntent } from "./components";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";

class UpdateVisitor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPos: 0,
      firstName: "",
      lastName: "",
      cell: "",
      email: "",
      company: "",
      city: "",
      state: "",
      nextIntent: "",
      intent: null,
      step: 1,
      prevElementStructure: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        firstName,
        lastName,
        sessionID,
        email,
        state,
        city,
        country,
        postalCode,
        company,
        cell,
        nextIntent,
        intent,
      } = box.payload;
      this.setState({
        firstName,
        lastName,
        sessionID,
        email,
        state,
        city,
        country,
        postalCode,
        company,
        cell,
        nextIntent,
        intent: intent || null,
      });
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  handleStep(step, isSubmit = false) {
    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      firstName,
      lastName,
      sessionID,
      email,
      state,
      city,
      country,
      postalCode,
      company,
      cell,
      nextIntent,
      intent,
      prevElement,
    } = this.state;
    const { handleSubmitElement } = this.props;

    const data = {
      input_type: "updateVisitor",
      next_intent: nextIntent,
      request_params: [
        {
          firstName,
          lastName,
          email,
          state,
          city,
          country,
          postalCode,
          company,
          cell,
          sessionID: BOT_ATTRIBUTES.SESSION_ID,
        },
      ],
    };
    const payload = {
      firstName,
      lastName,
      sessionID,
      email,
      state,
      city,
      country,
      postalCode,
      company,
      cell,
      nextIntent,
      intent,
    };
    handleSubmitElement(data, prevElement, payload);
  }

  handleChange(name, value, e) {
    const stateObj = { [name]: value };
    if (name === "message") {
      stateObj.selectPos = e.target;
    }
    this.setState(stateObj);
  }
  renderStep1() {
    const {
      firstName,
      lastName,
      cell,
      email,
      company,
      city,
      state,
      country,
      postalCode,
      prevElement,
      selectPos,
      prevElementStructure,
    } = this.state;
    const { boxes, connectors, attributeList, botElementId } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              menuPlacement="top"
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="First Name">
            <Input
              type="text"
              placeholder="First Name"
              name="firstName"
              outerClassName="mb0"
              title="First Name"
              value={firstName}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ firstName: value })}
                  setAttribute={[null, firstName]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Last Name">
            <div className="position-relative">
              <Input
                type="text"
                placeholder="Last Name"
                name="lastName"
                outerClassName="mb0"
                title="Last Name"
                value={lastName}
                appendIcon={
                  <SelectAttribute
                    attributeList={attributeList}
                    externalAttributes={prevElementStructure}
                    onChangeFunc={value => this.setState({ lastName: value })}
                    setAttribute={[null, lastName]}
                  />
                }
                onChangeFunc={this.handleChange}
              />
            </div>
          </ElementForm>
          <ElementForm title="Phone">
            <Input
              type="text"
              placeholder="Phone"
              name="cell"
              outerClassName="mb0"
              value={cell}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ cell: value })}
                  setAttribute={[null, cell]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Email">
            <Input
              type="text"
              placeholder="Email"
              name="email"
              outerClassName="mb0"
              value={email}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ email: value })}
                  setAttribute={[null, email]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Company">
            <Input
              type="text"
              placeholder="Company"
              name="company"
              outerClassName="mb0"
              value={company}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ company: value })}
                  setAttribute={[null, company]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="City">
            <Input
              type="text"
              placeholder="City"
              name="city"
              outerClassName="mb0"
              value={city}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ city: value })}
                  setAttribute={[null, city]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="State">
            <Input
              type="text"
              placeholder="State"
              name="state"
              outerClassName="mb0"
              value={state}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ state: value })}
                  setAttribute={[null, state]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Postal code">
            <Input
              type="text"
              placeholder="Postal code"
              name="postalCode"
              outerClassName="mb0"
              value={postalCode}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ postalCode: value })}
                  setAttribute={[null, postalCode]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Country">
            <Input
              type="text"
              placeholder="Country"
              name="country"
              outerClassName="mb0"
              value={country}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ country: value })}
                  setAttribute={[null, country]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox scrollClass="bt-send-msg" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default UpdateVisitor;
