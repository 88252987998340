import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import PropTypes from "prop-types";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { isArray } from "../../../utils/GeneralUtils";
import CellFormatter, { handleCellRendering } from "./CellFormatter";
import { AG_GRID_LICENSE_KEY } from "../../../utils/Config";

import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

const AgGrid = props => {
  const gridEl = useRef(null);
  const [api, setApi] = useState({ gridApi: null, gridColumnApi: null });
  const [gridHeight, setGridHeight] = useState(props.height);
  const [columns, setColumns] = useState([]);

  const onGridReady = params => {
    setApi({ gridApi: params.api, gridColumnApi: params.columnApi });
  };

  useEffect(() => {
    if (props.autoHeight) setGridHeight(window.innerHeight - gridEl.current.eGridDiv?.offsetTop - props.offset - 55);
  }, [gridEl, props.reCalculateHeight, props.offset]);

  useEffect(() => {
    if (isArray(props.dynamicColumns)) {
      api.gridApi.setColumnDefs(props.dynamicColumns.map(x => handleCellRendering(x, props.enableServerSide)));
    }
  }, [JSON.stringify(props.dynamicColumns)]);

  useEffect(() => {
    if (isArray(props.columns)) {
      setColumns(
        props.columns.map(x => ({
          sortable: true,
          filter: true,
          minWidth: 200,
          ...handleCellRendering(x, props.enableServerSide),
        }))
      );
    }
  }, [JSON.stringify(props.columns)]);

  const handleLoading = () => {
    if (props.loading) {
      api.gridApi.showLoadingOverlay();
    } else if (props.enableServerSide) {
      const firstRow = api.gridApi.getDisplayedRowAtIndex(0).data;
      if (firstRow) api.gridApi.hideOverlay();
      else api.gridApi.showNoRowsOverlay();
    } else {
      if (props.rowData.length) api.gridApi.hideOverlay();
      else api.gridApi.showNoRowsOverlay();
    }
  };

  const onSelectionChanged = useCallback(() => {
    let selectedRows = gridEl.current.api.getSelectedRows();
    props.getSelectedRows(selectedRows);
  }, [gridEl]);

  useEffect(() => {
    if (!api.gridApi) return;
    handleLoading();
  }, [JSON.stringify(props.dynamicColumns), props.loading]);

  useEffect(() => {
    if (api.gridApi && props.getRows) {
      api.gridApi.setDatasource({
        getRows: params => props.getRows({ ...api, params, perPage: props.perPage }),
      });
    } else if (api.gridApi && !props.enableServerSide) {
      handleLoading();
    }

    if (api.gridApi && props.getGridApi) {
      props.getGridApi(api.gridApi);
    }
  }, [api.gridApi]);

  let gridProps = {
    reactUi: true,
    suppressPropertyNamesCheck: true,
    defaultColDef: {
      flex: 1,
      resizable: true,
      minWidth: 120,
    },
    toolPanel: "columns",
    frameworkComponents: { ...CellFormatter, ...props.frameworkComponents },

    pagination: true,
    paginationPageSize: props.perPage,
    cacheBlockSize: props.perPage,
    onPaginationChanged: props.onPaginationChanged,

    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while fetching records...</span>',
    overlayNoRowsTemplate: `<div class="l-no-record"><img src="/img/v1/common/no_data.svg"></img><span class="mt10">No records found</span></div>`,
    rowModelType: "clientSide",
    onGridReady,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressRowGroups: true,
          },
        },
      ],
      position: "right",
    },
    getRowStyle: params => {
      if (params.data === undefined) return { display: "none" };
      else return { display: "block" };
    },
  };

  if (props.rowHeight) {
    gridProps.rowHeight = props.rowHeight;
  }

  if (props.getSelectedRows) {
    gridProps.rowSelection = props.rowSelection;
    gridProps.onSelectionChanged = onSelectionChanged;
  }

  if (!props.hideSideBarFilter) {
    gridProps.sideBar.toolPanels.push({
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    });
  }

  if (props.enableServerSide) {
    gridProps = {
      ...gridProps,
      rowModelType: "infinite",
    };
  } else {
    gridProps = {
      ...gridProps,
      rowData: props.rowData,
      rowBuffer: props.perPage,
      suppressColumnVirtualisation: true,
    };
  }

  return (
    <div className="App">
      <div className="ag-theme-alpine ag-style" style={{ height: gridHeight, minHeight: props.minHeight }}>
        <AgGridReact ref={gridEl} {...gridProps}>
          {columns.map((x, i) => (
            <AgGridColumn key={i} {...x} />
          ))}
        </AgGridReact>
      </div>
    </div>
  );
};

AgGrid.defaultProps = {
  columns: [],
  rowData: [],
  dynamicColumns: [],
  autoHeight: true,
  height: 400,
  frameworkComponents: {},
  perPage: 50,
  enableServerSide: false,
  reCalculateHeight: 1,
  offset: 0,
  hideSideBarFilter: false,
  rowSelection: "multiple",
};

AgGrid.propTypes = {
  enableServerSide: PropTypes.bool,
  getRows: PropTypes.func,
  getGridApi: PropTypes.func,
  perPage: PropTypes.number,
  columns: PropTypes.array,
  rowda: PropTypes.array,
  dynamicColumns: PropTypes.array,
  autoHeight: PropTypes.bool,
  height: PropTypes.number,
  minHeight: PropTypes.number,
  frameworkComponents: PropTypes.object,
  reCalculateHeight: PropTypes.any,
  offset: PropTypes.number,
  hideSideBarFilter: PropTypes.bool,
  getSelectedRows: PropTypes.func,
  rowSelection: PropTypes.string,
  onPaginationChanged: PropTypes.func,
  rowHeight: PropTypes.number,
};

export default memo(AgGrid);
