import React, { useState, useEffect } from "react";
import { cloneDeep, get } from "lodash";
import { getStatus, isArray } from "../../utils/GeneralUtils";
import { sessionFullName, sessionMerchantId } from "../../utils/SessionUtils";
import CommonUtils from "../../utils/CommonUtils";
import { map, uniq } from "lodash";

export const initDocumentForm = {
  documentName: "",
  documentType: "Website",
  modelId: null,
  prompts: [{ message: "" }],
  status: getStatus(),
  url: "",
  depth: "1",
  rateLimit: "2",
  scheduleFrequency: "Now",
  startDate: "",
  executionType: "Static",
  uploadedFileValue: null,
  uploadedFile: null,
  dataLoaded: null,
  invalidFile: null,
  recordNotFound: null,
  duplicateEntity: null,
  bulkUploadData: null,
  dataSource: null,
  createIntent: null,
  docType: null,
  docLocation: null,
  requestBody: null,
  requestHeaders: [{ key: "", value: "" }],
  requestType: "GET",
  requestParams: [{ key: "", value: "" }],
  environments: [{ key: "", value: "" }],
  activeFlowBotTab: "flowbot",
  type: null,
  file: null,
  fileUrl: "",

  botId: null,
  attributes: [{ attributeKey: "", attributeValue: "" }],

  isOpen: true,

  errors: {
    documentName: null,
  },
};
export const initDatasetForm = {
  documentName: "",
  documentType: "API",
  modelId: null,
  prompts: [{ message: "" }],
  status: getStatus(),
  url: "",
  depth: "1",
  rateLimit: "2",
  scheduleFrequency: "Now",
  startDate: "",
  executionType: "Static",
  uploadedFileValue: null,
  uploadedFile: null,
  dataLoaded: null,
  invalidFile: null,
  recordNotFound: null,
  duplicateEntity: null,
  bulkUploadData: null,
  dataSource: null,
  createIntent: null,
  docType: null,
  docLocation: null,
  requestBody: null,
  requestHeaders: [{ key: "", value: "" }],
  requestType: "GET",
  requestParams: [{ key: "", value: "" }],
  activeFlowBotTab: "flowbot",
  type: null,
  file: null,
  fileUrl: "",

  botId: null,
  attributes: [{ attributeKey: "", attributeValue: "" }],
  dataAttributes: [{ attribute: "", description: "" }],

  isOpen: true,

  errors: {
    documentName: null,
  },
};

export const initIntentForm = {
  knowledgeBaseId: "",
  documentId: "",
  description: "",
  intent: "",
  phrases: "",
  response: "",
  phrasesList: [],
  responsesList: [],
  openPhrases: true,
  openResponses: true,
  tooltipPhrasesOpen: false,
  tooltipResponsesOpen: false,
  serviceRequestType: "",
  serviceResponseType: "",
  knowledgeGraphId: "",
  errors: {
    knowledgeBaseId: null,
    documentId: null,
    intent: null,
  },
};

export const initKnowledgeForm = {
  knowledgeBaseName: "",
  knowledgeBaseDesc: "",
  modelId: null,
  documents: [{ ...initDocumentForm }],
  errors: {
    knowledgeBaseName: null,
    modelId: null,
  },
};

export const initKnowledgeDocForm = {
  knowledgeBaseId: null,
  documents: [{ ...initDocumentForm }],
  errors: {
    knowledgeBaseId: null,
  },
};

export const initKnowledgeDatasetForm = {
  documents: [{ ...initDatasetForm }],
  errors: {},
};

export const convertToKeyvalue = list => {
  if (isArray(list) && list.filter(x => x.key !== "" && x.value !== "").length) {
    return list
      .filter(x => x.key !== "" && x.value !== "")
      .map(attribute => ({
        [attribute.key]: attribute.value,
      }));
  }
  return list;
};

export const convertArrayToObject = list => {
  if (!isArray(list)) return list;
  let mergedObject = {};
  for (let i = 0; i < list.length; i++) {
    const obj = list[i];
    if (obj.key !== "" && obj.value !== "") {
      mergedObject[obj.key] = obj.value;
    }
  }
  return mergedObject;
};

export const convertArrayToObjectKey = list => {
  if (Array.isArray(list) && list.filter(x => x.key !== "").length) {
    return list
      .filter(x => x.key !== "")
      .reduce((acc, attribute) => {
        acc[attribute.key] = attribute.value;
        return acc;
      }, {});
  }
  return {};
};

export const convertObjectToArray = (obj, keyName = "key", valueName = "value") => {
  if (!obj) return obj;
  const transformedData = Object.keys(obj).map(key => ({
    [keyName]: key,
    [valueName]: obj[key],
  }));
  return transformedData;
};

export const prepareDocuments = async (documents, dataSource = "Document") => {
  const docListPromises = documents.map(async doc => {
    let docObj = {
      status: doc.status,
      documentName: doc.documentName,
      documentType: doc.documentType,
      description: doc.description,
      dataSource: dataSource,
      documentParams: {
        docType: doc.documentType === "Document" ? doc.type : doc.documentType,
      },
      scheduler: {
        startDate: doc.startDate ? formatDate(doc.startDate) : "",
        scheduleFrequency: doc.scheduleFrequency,
      },
      documentCreatedBy: sessionFullName(),
    };

    if (isArray(doc.prompts) && doc.prompts.filter(x => x.message).length) {
      docObj.prompts = doc.prompts.filter(x => x.message).map(p => p.message);
    }

    if (dataSource === "Dataset") {
      docObj.dataAttributes = isArray(doc.dataAttributes) ? doc.dataAttributes.filter(d => d.attribute) : [];
    }

    if (doc.modelId) {
      docObj.documentParams = {
        ...docObj.documentParams,
        modelId: doc.modelId,
      };
    }

    switch (doc.documentType) {
      case "Website":
      case "AWS S3":
      case "Azure Blob":
      case "One Drive":
      case "GCS":
      case "Google Drive":
      case "Huggingface":
      case "Kaggle":
        docObj.documentParams = {
          ...docObj.documentParams,
          docLocation: doc.url,
          depth_limit: doc.depth,
          rate_limit: doc.rateLimit,
        };
        break;

      case "Document":
      case "CSV Files":
      case "Zip Files":
        if (doc.file && typeof doc.file === "object") {
          const fileData = new FormData();
          fileData.append("file", doc.file);
          const fileRes = await CommonUtils.uploadGCS(fileData, "model");
          if (fileRes && fileRes.fileUrl) {
            doc.fileUrl = fileRes.fileUrl || "";
          } else if (fileRes && fileRes.error && fileRes.data && fileRes.data.error) {
            return fileRes.data;
          } else {
            return { error: "File not loaded successfully." };
          }
        } else if (doc.file && typeof doc.file === "string") {
          doc.fileUrl = doc.file;
        }

        docObj.documentParams = {
          ...docObj.documentParams,
          docLocation: doc.fileUrl,
        };
        break;

      case "API":
        docObj.executionType = doc.executionType;
        if (doc.activeFlowBotTab === "flowbot") {
          if (doc.botId) {
            docObj.documentParams.botId = doc.botId;
          }
          if (isArray(doc.attributes) && doc.attributes.filter(x => x.attributeKey && x.attributeValue).length) {
            docObj.documentParams.attributes = doc.attributes
              .filter(x => x.attributeKey && x.attributeValue)
              .map(attribute => ({
                [attribute.attributeKey]: attribute.attributeValue,
              }));
          }
        }

        if (doc.activeFlowBotTab === "api") {
          docObj.documentParams = {
            ...docObj.documentParams,
            docLocation: doc.docLocation,
            requestBody: doc.requestBody,
            requestType: doc.requestType,
          };
          if (convertToKeyvalue(doc.requestHeaders)) {
            docObj.documentParams = {
              ...docObj.documentParams,
              requestHeaders: convertToKeyvalue(doc.requestHeaders),
            };
          }
          if (convertToKeyvalue(doc.requestParams)) {
            docObj.documentParams = {
              ...docObj.documentParams,
              requestParams: convertToKeyvalue(doc.requestParams),
            };
          }
          if (convertToKeyvalue(doc.environments)) {
            docObj.documentParams = {
              ...docObj.documentParams,
              environments: convertToKeyvalue(doc.environments),
            };
          }
        }

        break;
      case "Template File":
        const groupedIntents = Object.values(
          doc.bulkUploadData.reduce((acc, obj) => {
            const key = obj.intent;
            if (!acc[key]) {
              acc[key] = { intent: key, question: [], response: [] };
            }
            acc[key].question.push(obj.trainingPhrases);
            if (obj.responses) {
              acc[key].response.push(obj.responses);
            }
            return acc;
          }, {})
        );
        docObj.intents = groupedIntents;
        break;

      default:
        break;
    }

    return docObj;
  });

  return Promise.all(docListPromises);
};

//#region Actions

export const onInputChange = (setterFunc, name, value) => {
  setterFunc(prev => ({
    ...prev,
    [name]: value,
  }));
};

export const onInputValidate = (setterFunc, name, error) => {
  setterFunc(prev => ({
    ...prev,
    errors: { ...prev.errors, [name]: error },
  }));
};

const clearFileInput = (index, list) => {
  list[index]["bulkUploadData"] = [];
  list[index]["uploadedFile"] = null;
  list[index]["uploadedFileValue"] = "";
  list[index]["invalidFile"] = false;
  list[index]["recordNotFound"] = false;
  list[index]["dataLoaded"] = false;
  list[index]["duplicateIntent"] = false;
  list[index]["createIntent"] = false;
  // document.getElementById("uploadcsv" + index)["value"] = "";
  return list;
};

const updateValue = (value, index, documents) => {
  documents = documents.map((d, docIndex) => {
    if (index === docIndex) {
      return {
        ...d,
        ...value,
      };
    }
    return d;
  });

  CommonUtils.getModelIntent().then(response => {
    if (response.error) {
      return false;
    }

    const updatedKnwIntentList = [];
    response.knowledgeBaseDocIntents.forEach(i => {
      if (i.intents && i.intents.length > 0) {
        i.intents.forEach(d => {
          //if (d.status !== "5") {
          const intentObj = cloneDeep(d);
          intentObj.documentId = i.documentId;
          intentObj.documentName = i.documentName;
          intentObj.knowledgeBaseId = i.knowledgeBaseId;
          intentObj.knowledgeBaseName = i.knowledgeBaseName;
          intentObj.statusForFilter = i.status;
          updatedKnwIntentList.push(intentObj);
          //}
        });
      }
    });
    const knwIntentList = convertIntentGridList(updatedKnwIntentList);
    uniq(map(value.bulkUploadData, "intent")).forEach(intent => {
      if (knwIntentList.some(x => x.intent.toLowerCase() === intent.toLowerCase())) {
        documents = documents.map((d, docIndex) => {
          if (index === docIndex) {
            return {
              ...d,
              ...value,
              invalidFile: false,
              recordNotFound: false,
              dataLoaded: false,
              duplicateIntent: true,
            };
          }
          return d;
        });
      }
    });
  });
  return documents;
};

const updateApiDoc = (value, index, documents) => {
  documents = documents.map((d, docIndex) => {
    if (index === docIndex) {
      return {
        ...d,
        ...value,
      };
    }
    return d;
  });
  return documents;
};

export const onChangeDoc = (setterFunc, name, value, index, subIndex) => {
  setterFunc(prev => {
    let documents = cloneDeep(prev.documents);
    switch (name) {
      case "attributeKey":
      case "attributeValue":
        documents[index].attributes[subIndex][name] = value;
        break;
      case "uploadedFileValue":
        documents = updateValue(value, index, documents);
        break;
      case "clearFileInput":
        documents = clearFileInput(index, documents);
        break;
      case "createIntent":
        documents[index]["createIntent"] = true;
        break;
      case "prompt":
        documents[index].prompts[subIndex].message = value;
        break;
      case "updateApiDoc":
        documents = updateApiDoc(value, index, documents);
        break;
      default:
        documents[index][name] = value;
        break;
    }
    return { ...prev, documents };
  });
};

export const onDocumentValidate = (setterFunc, name, error, index) => {
  setterFunc(prev => {
    const documents = cloneDeep(prev.documents);
    documents[index].errors[name] = error;

    return {
      ...prev,
      documents,
    };
  });
};

export const addDocument = setterFunc => {
  setterFunc(prev => {
    const documents = cloneDeep(prev.documents);
    documents.push(initDocumentForm);

    return {
      ...prev,
      documents,
    };
  });
};

export const deleteDocument = (setterFunc, index) => {
  setterFunc(prev => {
    const documents = cloneDeep(prev.documents);
    documents.splice(index, 1);
    return { ...prev, documents };
  });
};

export const toggleDocument = (setterFunc, index) => {
  setterFunc(prev => {
    const documents = cloneDeep(prev.documents);
    documents[index].isOpen = !documents[index].isOpen;

    return {
      ...prev,
      documents,
    };
  });
};

export const addAttribute = (setterFunc, docIndex) => {
  setterFunc(prev => {
    const documents = cloneDeep(prev.documents);
    documents[docIndex].attributes.push(initDocumentForm.attributes[0]);

    return {
      ...prev,
      documents,
    };
  });
};

export const removeAttribute = (setterFunc, docIndex, attrIndex) => {
  setterFunc(prev => {
    const documents = cloneDeep(prev.documents);
    documents[docIndex].attributes.splice(attrIndex, 1);
    return { ...prev, documents };
  });
};

export const addPrompt = (setterFunc, docIndex) => {
  setterFunc(prev => {
    const documents = cloneDeep(prev.documents);
    documents[docIndex].prompts.push(initDocumentForm.prompts[0]);

    return {
      ...prev,
      documents,
    };
  });
};

export const removePrompt = (setterFunc, docIndex, promptIndex) => {
  setterFunc(prev => {
    const documents = cloneDeep(prev.documents);
    documents[docIndex].prompts.splice(promptIndex, 1);
    return { ...prev, documents };
  });
};

//#endregion

//#region Get Data

export const useModelData = filterFunc => {
  const [modelList, setModelList] = useState([]);
  const [fetchModelList, setFetchModelList] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const obj = {
        merchantId: sessionMerchantId(),
      };

      setFetchModelList(true);
      const result = await CommonUtils.getModelList(obj);

      if (result.error) {
        setModelList([]);
        setFetchModelList(false);
        return;
      }
      const list = filterFunc
        ? filterFunc(result)
        : result.models.map(x => ({
            label: x.modelName,
            value: x.modelId,
            modelType: x.modelType,
            modelVersion: x.modelVersion,
            modelParams: x.modelParams,
            description: x.description,
            aiSystems: x.aiSystems,
          }));
      setModelList(list);
      setFetchModelList(false);
    };

    fetchData();
  }, []);

  return { modelList, fetchModelList };
};

export const useFlowbotData = (valueAttr = "botId") => {
  const [flowbotList, setFlowbotList] = useState([]);
  const [chatbotList, setChatbotList] = useState([]);
  const [fetchFlowbotList, setFetchFlowbotList] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setFetchFlowbotList(true);

      const result = await CommonUtils.getMerchantBot();
      if (result.error) {
        setFlowbotList([]);
        setFetchFlowbotList(false);
        return false;
      }

      const flowbotList = get(result, "myBots[0].bot", [])
        .filter(x => x.type === "flowbot")
        .map(x => {
          return {
            label: (
              <span>
                <img src={x.botImage1 || "img/chatbot-template.png"} width={20} height={20} className="mr-1" />
                {x.title}
              </span>
            ),
            value: x[valueAttr],
            botId: x.botId,
            botName: x.botName,
          };
        });

      const chatbotList = get(result, "myBots[0].bot", [])
        .filter(x => x.type === "chatbot")
        .map(x => {
          return {
            label: (
              <span>
                <img src={x.botImage1 || "img/chatbot-template.png"} width={20} height={20} className="mr-1" />
                {x.title}
              </span>
            ),
            value: x[valueAttr],
            botId: x.botId,
            botName: x.botName,
          };
        });

      setChatbotList(chatbotList);
      setFlowbotList(flowbotList);
      setFetchFlowbotList(false);
    };

    fetchData();
  }, []);

  return { flowbotList, chatbotList, fetchFlowbotList };
};

export const initModelManagementForm = {
  modelName: "",
  description: "",
  modelType: "PRIVATE_LLM",
  foundationModel: null,
  ai: null,
  version: "",
  temperature: "0.1",
  tokenLength: 1024,
  topk: "1",
  topp: "0.1",
  status: "Active",
  trainingStatus: "",
  default: false,
  errors: {
    ai: null,
    version: null,
    modelName: null,
    description: null,
  },
};

export const buildEditFormData = selectedModel => {
  return {
    ...cloneDeep(initModelManagementForm),
    modelName: selectedModel.modelName,
    description: selectedModel.description,
    modelType: selectedModel.modelType || "PRIVATE_LLM",
    version: selectedModel.modelVersion,
    status: selectedModel.status,
    default: selectedModel.default,
    foundationModel: selectedModel.modelParams ? selectedModel.modelParams.foundationModel : null,
    temperature: selectedModel.modelParams ? selectedModel.modelParams.temperature : "0.1",
    tokenLength: selectedModel.modelParams ? selectedModel.modelParams.tokenLength : 1024,
    topk: selectedModel.modelParams ? selectedModel.modelParams.topk : "1",
    topp: selectedModel.modelParams ? selectedModel.modelParams.topp : "0.1",
    n: selectedModel.modelParams ? selectedModel.modelParams.n : "1",
    size: selectedModel.modelParams ? selectedModel.modelParams.size : "",
    ai:
      Array.isArray(selectedModel.aiSystems) && selectedModel.aiSystems.length ? selectedModel.aiSystems[0].aiId : null,
  };
};

export const initMLModelManagementForm = {
  modelName: "",
  description: "",
  modelType: null,
  ai: null,
  version: "",
  status: "Active",
  trainingStatus: "",
  clusters: "",
  initMethod: "",
  maxIterations: "",
  distanceMetric: "",
  featureScaling: "",
  features: [{ feature: "", description: "" }],
  errors: {
    ai: null,
    version: null,
    modelName: null,
    description: null,
  },
};

export const buildEditMLFormData = selectedModel => {
  return {
    ...cloneDeep(initMLModelManagementForm),
    modelName: selectedModel.modelName,
    description: selectedModel.description,
    modelType: selectedModel.modelType,
    features: isArray(selectedModel.features) ? selectedModel.features : [{ feature: "", description: "" }],
    version: selectedModel.modelVersion,
    status: selectedModel.status,
    clusters: selectedModel.modelParams ? selectedModel.modelParams.clusters : "",
    initMethod: selectedModel.modelParams ? selectedModel.modelParams.initMethod : "",
    maxIterations: selectedModel.modelParams ? selectedModel.modelParams.maxIterations : "",
    distanceMetric: selectedModel.modelParams ? selectedModel.modelParams.distanceMetric : "",
    featureScaling: selectedModel.modelParams ? selectedModel.modelParams.featureScaling : "",
    ai:
      Array.isArray(selectedModel.aiSystems) && selectedModel.aiSystems.length ? selectedModel.aiSystems[0].aiId : null,
    framework: selectedModel.framework,
  };
};

const formatData = list => {
  const formattedList = list
    ? list.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return {
          key: key,
          value: value,
        };
      })
    : [{ key: "", value: "" }];

  return formattedList;
};

const formatDate = originalDateStr => {
  let [datePart, timePart, period] = originalDateStr.split(" ");

  const timeParts = timePart.split(":");
  const formattedTimePart = timeParts.length === 3 ? timePart : `${timeParts[0]}:${timeParts[1]}:00`;

  return `${datePart} ${formattedTimePart} ${period}`.toLowerCase();
};

export const buildSelectedDocument = (selectedDocument, knowledgeBaseId) => {
  const document = {
    ...initDocumentForm,
    documentName: selectedDocument.documentName,
    documentType: selectedDocument.documentType,
    modelId: selectedDocument.documentParams.modelId,
    type: selectedDocument.documentParams.docType,
    prompts: selectedDocument.prompts
      ? selectedDocument.prompts.map(value => ({
          message: value,
        }))
      : [{ message: "" }],
    description: selectedDocument.description,
    status: selectedDocument.status,
    url: selectedDocument.documentParams.docLocation,
    fileUrl: selectedDocument.documentType === "Document" ? selectedDocument.documentParams.docLocation : "",
    depth: selectedDocument.documentParams.depth_limit || "1",
    rateLimit: selectedDocument.documentParams.rate_limit || "2",
    botId: selectedDocument.documentParams.botId,
    dataAttributes: selectedDocument.dataAttributes || [{ attribute: "", description: "" }],
    attributes: selectedDocument.documentParams.attributes
      ? selectedDocument.documentParams.attributes.map(obj => {
          const key = Object.keys(obj)[0];
          const value = obj[key];
          return { attributeKey: key, attributeValue: value };
        })
      : [{ attributeKey: "", attributeValue: "" }],
    startDate: (selectedDocument.scheduler || { startDate: "" }).startDate || "",
    scheduleFrequency: (selectedDocument.scheduler || { scheduleFrequency: "Now" }).scheduleFrequency,
    docLocation: selectedDocument.documentParams.docLocation,
    docType: selectedDocument.documentParams.docType,
    requestBody: selectedDocument.documentParams.requestBody,
    requestParams: formatData(selectedDocument.documentParams.requestParams) || [{ key: "", value: "" }],
    requestHeaders: formatData(selectedDocument.documentParams.requestHeaders) || [{ key: "", value: "" }],
    environments: formatData(selectedDocument.documentParams.environments) || [{ key: "", value: "" }],
    requestType: selectedDocument.documentParams.requestType,
    response: selectedDocument.documentParams.response,
  };

  return {
    knowledgeBaseId,
    documents: [{ ...document }],
    errors: {
      knowledgeBaseId,
    },
  };
};

export const convertIntentGridList = list => {
  return list.map(k => {
    const obj = {
      intent: k.intent,
      question: get(k, "question[0]", null),
      questionCount: (k.question || []).length,
      response: get(k, "response[0]", null),
      responseCount: (k.response || []).length,
      document: k.documentName ? k.documentName : k.documentId,
      knowledge: k.knowledgeBaseName ? k.knowledgeBaseName : k.knowledgeBaseId,
      fulldata: k,
      searchData: `${k.intent} ${Array.isArray(k.question) ? k.question.join(" , ") : ""} ${
        Array.isArray(k.response) ? k.response.join(" , ") : ""
      }`.toLowerCase(),
    };
    return obj;
  });
};

export const combineObjectsFromArray = array => {
  if (Array.isArray(array)) {
    return array.reduce((combinedObject, currentObject) => {
      Object.keys(currentObject).forEach(key => {
        combinedObject[key] = currentObject[key];
      });
      return combinedObject;
    }, {});
  } else {
    return null;
  }
};
