import React from "react";
import PropTypes from "prop-types";

const ElementForm = props => {
  const { children, title, prevElement, rowClassName, flClassName } = props;
  const frStyle = {};
  if (props.frMaxWidth) {
    frStyle.maxWidth = props.frMaxWidth;
  }
  return (
    <div className={`bf-row ${rowClassName}`}>
      <div className={`fl ${flClassName}`}>{!title && prevElement ? "Previous Element" : title}</div>
      <div className="fr" style={frStyle}>
        {children}
      </div>
    </div>
  );
};

ElementForm.defaultProps = {
  title: "",
  prevElement: false,
  rowClassName: "",
  flClassName: "",
  frMaxWidth: "",
};

ElementForm.propTypes = {
  title: PropTypes.string,
  prevElement: PropTypes.bool,
  rowClassName: PropTypes.string,
  flClassName: PropTypes.string,
  frMaxWidth: PropTypes.string,
};

export default ElementForm;
