import React from "react";
import { Button, Input, ScrollBox, TextArea, TextEditor } from "../../../common/components";
import { CLOUDINARY_DATA } from "../../../utils/Config";
import {
  SelectElement,
  SelectIntent,
  SelectAttribute,
  ElementForm,
  SubmitElement,
  OptionsElement,
  AdvanceOptionsExpand,
} from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { cloneDeep, isArray, keys } from "lodash";
import { createCloudinaryWidget } from "../../../utils/GeneralUtils";
import { getButtonPayload, loadBotPayload, loadDefaultBotPayload, updateBotPayload } from "../../../utils/BotUtils";

const LANG_STRUCTURE = {
  text: "",
  help_text: "",
  header: "",
  footer: "",
  dataQR: [],
  payloadQR: [],
};

class SendVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      videoURL: "",
      waTemplate: false,
      legacy_message: false,
      activity: false,
      help_text: "",
      header: "",
      footer: "",
      intent: null,
      nextIntent: "",
      isRich: false,
      prevElementStructure: [],
      externalAttributes: [],
      step: 1,
      dataQR: [],
      payloadQR: [],
      errors: {
        videoURL: null,
      },
      langPayload: loadDefaultBotPayload(LANG_STRUCTURE, props.lang),
    };
    this.widget = null;
    this.openCloudinaryWidget = this.openCloudinaryWidget.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onAdvanceOptionChange = this.onAdvanceOptionChange.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId, lang } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        text,
        url,
        intent,
        nextIntent,
        isRich,
        waTemplate = false,
        legacy_message = false,
        activity = false,
        header,
        footer,
        help_text,
      } = box.payload;
      this.setState({
        text,
        videoURL: url,
        intent,
        nextIntent,
        isRich,
        waTemplate,
        legacy_message,
        activity,
        header,
        footer,
        help_text,
        ...loadBotPayload(LANG_STRUCTURE, box.payload, this.state, lang),
      });
    }
  }

  updatePayload(prevLang = this.props.lang, callback = () => {}) {
    const props = {
      boxes: this.props.boxes,
      botVersion: this.props.botVersion,
      isInteractive: this.state.waTemplate,
    };
    const langPayload = updateBotPayload(LANG_STRUCTURE, this.state, prevLang, props);
    this.setState(
      {
        langPayload,
        ...langPayload[this.props.lang.selectedLang],
      },
      callback
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang.selectedLang !== prevProps.lang.selectedLang) {
      if (this.state.step === 2) {
        this.handleStep(3, false, prevProps.lang);
      } else {
        this.updatePayload(prevProps.lang);
      }
    }
  }

  openCloudinaryWidget() {
    if (this.widget) {
      this.widget.open();
      return;
    }
    this.widget = createCloudinaryWidget(CLOUDINARY_DATA.UPLOAD_PRODUCT_PRESET, result => {
      if (result.event == "success") {
        this.setState({ videoURL: result.info.secure_url }, () => {
          setTimeout(() => {
            this.widget.close({ quiet: true });
          }, 300);
        });
      }
    });
    this.widget.open();
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  onAdvanceOptionChange(name, value, err, obj, e) {
    if (["waTemplate", "legacy_message", "activity"].includes(name)) {
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleStep(step, isSubmit = false, prevLang) {
    const stateObj = prevLang ? {} : { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }
    if (step === 3) {
      const obj = this.optionRef.getOptions();
      if (!obj) return false;
      else {
        stateObj.dataQR = obj.dataQR;
        stateObj.payloadQR = obj.payloadQR;
      }
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => this.updatePayload(prevLang, isSubmit ? this.handleSubmit : null));
  }

  handleSubmit() {
    const { videoURL, prevElement, intent, nextIntent, isRich, waTemplate, legacy_message, activity, langPayload } =
      this.state;
    const { handleSubmitElement, lang } = this.props;

    const submitCb = () => {
      const langDataObj = {};
      const langPayloadObj = {};
      let defaultPayloadQR = [];

      keys(langPayload).map(x => {
        const { dataQR, payloadQR, text, header, footer, help_text } = langPayload[x];
        const sQR = cloneDeep(dataQR);
        const sPQR = cloneDeep(payloadQR);

        if (x === lang.defaultLang) {
          defaultPayloadQR = cloneDeep(sPQR);
        }

        langDataObj[x] = {
          text,
          url: videoURL,
          next_intent: nextIntent,
          quick_replies: sQR,
          waTemplate,
          legacy_message,
          header,
          footer,
          help_text,
        };

        langPayloadObj[x] = {
          text,
          header,
          footer,
          help_text,
          payloadQR: sPQR.map(p => getButtonPayload(p)),
        };
      });

      return { langDataObj, langPayloadObj, defaultPayloadQR };
    };

    const { langDataObj, langPayloadObj, defaultPayloadQR } = submitCb();
    const { text, header, footer, help_text } = langPayloadObj[lang.defaultLang];

    const payload = {
      text,
      url: videoURL,
      intent,
      nextIntent,
      isRich,
      qr: defaultPayloadQR,
      waTemplate,
      legacy_message,
      activity,
      header,
      footer,
      help_text,
      langPayload: langPayloadObj,
    };

    handleSubmitElement(langDataObj[lang.defaultLang], prevElement, payload, langDataObj);
  }

  renderStep1() {
    const { boxes, botElementId, connectors, attributeList, lang } = this.props;
    const {
      videoURL,
      prevElement,
      text,
      waTemplate,
      legacy_message,
      activity,
      header,
      footer,
      help_text,
      errors,
      isRich,
      prevElementStructure,
      selectPos,
    } = this.state;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm rowClassName={lang.className} prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </ElementForm>
          <ElementForm rowClassName={lang.className} title="URL">
            <div>
              <Input
                title="Video URL"
                name="videoURL"
                placeholder="Enter video file URL"
                className="form-control"
                outerClassName="float-left mb5"
                outerStyle={{ width: "calc(100% - 70px)" }}
                value={videoURL}
                isReq={true}
                error={errors.videoURL}
                onChangeFunc={(name, value) => this.setState({ [name]: value })}
                validationFunc={this.onInputValidate}
              />
              <Button text="Browse" className="btn-browse" onClickFunc={this.openCloudinaryWidget} />
              <div className="clearfix" />
            </div>
            <label className="mb0">Allows sending videos. Supports mp4 upto 25MB in size</label>
          </ElementForm>

          <ElementForm title="Message">
            <div className="position-relative">
              {isRich ? (
                <TextEditor
                  title="Message"
                  name="text"
                  value={text}
                  // isReq={true}
                  // error={errors.text}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={value => this.setState({ text: value })}
                      setAttribute={[selectPos, text]}
                    />
                  }
                  // validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              ) : (
                <TextArea
                  title="Message"
                  value={text}
                  name="text"
                  placeholder="Welcome to Purple grids !!"
                  // isReq={true}
                  // error={errors.text}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={(value, inputName) =>
                        this.setState({ text: value }, () => this.onInputValidate(inputName, null))
                      }
                      setAttribute={[selectPos, text]}
                    />
                  }
                  // validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value, e) => this.setState({ [name]: value, selectPos: e.target })}
                />
              )}
            </div>
            <label className={`mb0 ${lang.className}`} style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>

          <AdvanceOptionsExpand
            help_text={help_text}
            header={header}
            footer={footer}
            waTemplate={waTemplate}
            legacy_message={legacy_message}
            activity={activity}
            displayList={["waTemplate", "legacy_message", "activity", "help_text", "header", "footer"]}
            onChangeFunc={this.onAdvanceOptionChange}
            lang={lang}
          />
        </div>
      </div>
    );
  }

  renderStep2() {
    const { prevElementStructure, payloadQR, waTemplate } = this.state;
    const { lang } = this.props;
    const isInteractive = waTemplate;
    return (
      <div className="ele-content">
        <OptionsElement
          {...this.props}
          isInteractive={isInteractive}
          waTemplate
          payloadQR={isArray(payloadQR) ? payloadQR : undefined}
          ref={e => (this.optionRef = e)}
          prevElementStructure={prevElementStructure}
          lang={lang}
        />
      </div>
    );
  }

  renderStep3() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId, lang } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
          lang={lang}
        />
      </div>
    );
  }

  render() {
    const { resetElement, botElementId, lang } = this.props;
    const { step } = this.state;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-req-user-data" className="adv-settings" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
          {step === 3 && this.renderStep3()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={() => this.updatePayload(lang, this.handleSubmit)}
          step={step}
          totalSteps={3}
          botElementId={botElementId}
          handleStep={this.handleStep}
          lang={lang}
        />
      </React.Fragment>
    );
  }
}

export default SendVideo;
