const socketState = {
  socketConnection: {
    connection: false,
    subscribedSessions: [],
  },
  socketInfo: {
    client: null,
    currentSessionId: null,
  },
};

//Actions

export const socketConn = socketConnection => {
  return {
    type: "SOCKET_CONN",
    socketConnection,
  };
};

export const setSocketInfo = socketInfo => {
  return {
    type: "SOCKET_INFO",
    payload: { socketInfo },
  };
};

// Reducers
export default (state = socketState, action) => {
  switch (action.type) {
    case "SOCKET_CONN":
      return {
        ...state,
        socketConnection: action.socketConnection,
      };
    case "SOCKET_INFO":
      const { socketInfo } = action.payload;
      return {
        ...state,
        socketInfo: { ...state.socketInfo, ...socketInfo },
      };
    default:
      return state;
  }
};
