import reducer from "./redux";
import socket from "./socketRedux";
import savesocketInstance from "./saveInstRedux";
import common from "./commonReducer";

export default {
  reducer,
  socket,
  savesocketInstance,
  common,
};
