import React from "react";
import Input from "./Input";
import { InputGroupText } from "reactstrap";

const QuickSearch = ({ value, onChangeFunc, name, placeholder = "Search..." }) => {
  return (
    <Input
      outerStyle={{ width: 300 }}
      outerClassName="border br4"
      className="form-control border-0 pl-0"
      prependIcon={
        <InputGroupText className="bg-white border-0 br4">
          <i
            className={`fa s-icon ${value ? "fa-times" : "fa-search"} cursor-pointer`}
            onClick={() => {
              if (value) {
                onChangeFunc("");
              }
            }}
          ></i>
        </InputGroupText>
      }
      name={name}
      placeholder={placeholder}
      value={value}
      onChangeFunc={(_, fieldValue) => onChangeFunc(fieldValue)}
    />
  );
};

export default QuickSearch;
