import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Label } from "reactstrap";
import { Select } from "../../../../common/components";
import { LANGUAGE_LIST } from "../../../../utils/ConstUtils";

const LanguageSelection = props => {
  const { selectedLanguages, defaultLanguage, onChange, isDefaultDisabled } = props;
  const [defaultLanguageList, setDefaultLanguageList] = useState([]);
  const [languageList, setLanguageList] = useState(LANGUAGE_LIST);

  useEffect(() => {
    const defaultLanguageList =
      selectedLanguages && selectedLanguages.length > 0
        ? selectedLanguages.split(",").map(x => {
            return LANGUAGE_LIST.find(l => l.value === x) || {};
          })
        : [];
    setDefaultLanguageList(defaultLanguageList);
  }, [props.selectedLanguages]);

  useEffect(() => {
    const languageList = LANGUAGE_LIST.map(l => {
      if (l.value === defaultLanguage) {
        l.isFixed = true;
      }
      return l;
    });
    setLanguageList(languageList);
  }, [props.defaultLanguage]);

  return (
    <Fragment>
      <Row>
        <Col md="3 text-right">
          <Label className="text-right" htmlFor="channel">
            Languages
          </Label>
        </Col>
        <Col>
          <Select
            title="Languages"
            name="selectedLanguages"
            isMulti={true}
            value={props.selectedLanguages}
            options={languageList}
            onChangeFunc={(name, value) => onChange(name, value)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3 text-right">
          <Label className="text-right" htmlFor="channel">
            Default Language
          </Label>
        </Col>
        <Col>
          <Select
            title="Default Language"
            name="defaultLanguage"
            value={defaultLanguage}
            options={defaultLanguageList}
            isDisabled={isDefaultDisabled}
            onChangeFunc={(name, value) => onChange(name, value)}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

LanguageSelection.defaultProps = {
  selectedLanguages: "en",
  defaultLanguage: "en",
  isDefaultDisabled: false,
};

LanguageSelection.propTypes = {
  selectedLanguages: PropTypes.string,
  defaultLanguage: PropTypes.string,
  isDefaultDisabled: PropTypes.bool,
};

export default LanguageSelection;
