import React from "react";
import { useLocation } from "react-router-dom";
import AADLogin from "../Login/AADLogin";
import PGRegister from "./PGRegister";

const Register = props => {
  const location = useLocation();
  const authType = new URLSearchParams(location.search).get("authType");

  if (authType === "AZURE_AD") {
    return <AADLogin {...props} isSignup={true} />;
  }

  return <PGRegister {...props} />;
};

export default Register;
