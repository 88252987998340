const initialState = {
  user: {},
  businessType: "",
  mesgBox: false,
  visitor: {},
  visitorChatClick: false,
};

//Actions

export const saveUser = user => {
  return {
    type: "SAVE_USER",
    user,
  };
};

export const saveBusinessType = businessType => {
  return {
    type: "BUSINESS_TYPE",
    businessType,
  };
};

export const saveVisitor = visitor => {
  return {
    type: "SAVE_VISITOR",
    visitor,
  };
};

export const visitorChatEvent = visitorChatClick => {
  return {
    type: "VISITOR_CHAT_CLICK",
    visitorChatClick,
  };
};

export const mesBoxEvent = mesgBox => {
  return {
    type: "MESG_BOX",
    mesgBox,
  };
};

// Reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_USER":
      return Object.assign({}, state, {
        user: action.user,
      });
    case "BUSINESS_TYPE":
      return Object.assign({}, state, {
        businessType: action.businessType,
      });
    case "MESG_BOX":
      return Object.assign({}, state, {
        mesgBox: action.mesgBox,
      });
    case "VISITOR_CHAT_CLICK":
      return Object.assign({}, state, {
        visitorChatClick: action.visitorChatClick,
      });
    case "SAVE_VISITOR":
      return Object.assign({}, state, {
        visitor: action.visitor,
      });
    default:
      return state;
  }
};
