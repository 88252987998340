import React from "react";
import { toast } from "react-toastify";
import { hashMobileAccess } from "./GeneralUtils";
import CommonUtils from "./CommonUtils";
import { sessionMerchantId } from "./SessionUtils";

const toastConfig = {
  position: "bottom-right",
  autoClose: 20000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: "toast-style",
  bodyClassName: "toast-body-style",
  progressClassName: "toast-progress-style",
};

export const popNotification = (vId, notificationMsg, isAgentTranfer = false) => {
  CommonUtils.getAllUsers().then(response => {
    let deviceId = "";
    if (response.error) {
      console.log(response.error);
      return false;
    }
    response.map(l => {
      if (l.id == vId) {
        deviceId = l.deviceId || "";
      }
    });
    if (!isAgentTranfer && !deviceId) {
      return;
    }
    const connector = {
      device_type: deviceId ? "IOS" : "Android",
      device_id: deviceId || null,
      message: notificationMsg,
      title: "Agent Assistance Requested",
      type: "Mobile",
    };

    if (isAgentTranfer && connector.device_type === "Android") {
      connector.merchantId = sessionMerchantId();
      connector.agentId = [vId];
    }

    const popObj = {
      request_id: "10001",
      gateway_actions: [{ connector }],
    };
    CommonUtils.sendEmailAPI(popObj).then(res => {
      if (res.error) {
        console.log("error");
      }
    });
  });
};

export const toastLiveChat = (visitor, vId, channel, multiple = false, handleClick) => {
  toast.info(
    <div onClick={handleClick}>
      <i className="fa fa-exclamation-triangle"></i>
      {multiple ? (
        <span className="toast-content">Multiple Visitors needs assistance.</span>
      ) : (
        <span className="toast-content">
          Visitor <span className="hl">{visitor}</span> from <span className="hl">{channel}</span> needs assistance.
        </span>
      )}
    </div>,
    toastConfig
  );

  const notificationMsg = multiple
    ? "Multiple Visitors needs assistance."
    : `Visitor ${visitor} from ${channel} needs assistance.`;

  const { isIOS, isAndroid } = hashMobileAccess();
  // Show notification in IOS devices
  if (isIOS) {
    popNotification(vId, notificationMsg);
  }

  // Show notification in Android devices
  if (isAndroid) {
    window.Android.showToast(notificationMsg);
  }
};
