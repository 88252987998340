import React, { useState, useEffect, Fragment, useRef } from "react";
import Button from "./Button";
import { isBase64Image } from "../../utils/GeneralUtils";

function SingleImageUploader({
  imgData,
  loading,
  defaultImage = "./img/integrations/no-img.svg",
  outerClassName,
  imageClassName,
  btnClassName,
  isSmall,
  errorMsg,
  onFileChange,
}) {
  const [imagePath, setImagePath] = useState({
    file: null,
    previewImg: null,
  });
  const inputRef = useRef(null);

  useEffect(() => {
    setImagePath(imgData);
  }, [imgData]);

  // remove image
  function clearImage() {
    setImagePath(null);
    onFileChange(null);
  }

  // start upload image
  function startUpload() {
    inputRef.current.click();
  }

  const getFileUrl = value => {
    return isBase64Image(value) ? value : "/img/doc.svg";
  };

  function handleFileChange(e) {
    const file = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = e => {
      const { result } = e.target;
      if (result) {
        const previewImg = getFileUrl(result);
        setImagePath(prevData => ({
          ...prevData,
          previewImg,
        }));
        if (file) {
          onFileChange({
            file,
            previewImg,
          });
        }
      }
    };
    fileReader.readAsDataURL(file);
  }

  return (
    <Fragment>
      <div className={`c-img-box ${outerClassName || ""}`}>
        <input type="file" accept="image/*" multiple={false} ref={inputRef} hidden onChange={handleFileChange} />
        {loading ? (
          <Loading />
        ) : imagePath?.file ? (
          <>
            <div className="c-action-btn-box">
              <Button
                text=""
                customIcon="fa-cloud-upload"
                outline
                color="secondary"
                title="Upload"
                className={`${btnClassName} upload m0 ${isSmall ? "small" : ""}`}
                onClickFunc={startUpload}
              />
              <Button
                text=""
                customIcon="fa-trash-o"
                outline
                color="secondary"
                title="Clear"
                className={`${btnClassName} delete ${isSmall ? "small" : ""}`}
                onClickFunc={clearImage}
              />
            </div>
            {imagePath || defaultImage ? (
              <img className={imageClassName} src={imagePath.previewImg || defaultImage} />
            ) : null}
          </>
        ) : (
          <Button
            outline
            title="Upload"
            color="secondary"
            className="c-upload-alone"
            customIcon="fa-cloud-upload"
            onClickFunc={startUpload}
          />
        )}
      </div>
      {errorMsg ? <div className="req-msg">{errorMsg}</div> : null}

      <div className="clearfix"></div>
    </Fragment>
  );
}

export default SingleImageUploader;

const Loading = () => {
  return (
    <div class="d-flex justify-content-center align-items-center h-100">
      <i className="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
  );
};
