import React from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

export const TooltipLink = props => {
  return (
    <Tooltip placement={props.placement} trigger={props.trigger} overlay={props.overlay}>
      {props.link ? props.link : props.icon ? <i className={`fa ${props.icon}`}></i> : null}
    </Tooltip>
  );
};

TooltipLink.defaultProps = {
  link: null,
  icon: "fa-info-circle",
  overlay: null,
  placement: "top",
  trigger: undefined,
};

TooltipLink.propTypes = {
  link: PropTypes.any,
  icon: PropTypes.string,
  overlay: PropTypes.any,
  placement: PropTypes.string,
  trigger: PropTypes.array,
};

export default TooltipLink;
