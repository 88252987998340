import React from "react";
import { Input, ScrollBox, Select } from "../../../common/components";
import { SelectElement, SelectIntent, ElementForm, SubmitElement, SelectAttribute } from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { ProductView } from "./components";
import { getAttrByPrevElement } from "../../../utils/BotUtils";
import { viewSizes, botViewType } from "../../../utils/GeneralUtils";

class WebView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      size: "M",
      webViewURL: "",
      intent: null,
      nextIntent: "",
      step: 1,
      prevElementStructure: [],
      errors: {
        webViewURL: null,
      },
    };
    this.widget = null;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { text, url, size, intent, nextIntent } = box.payload;
      this.setState({ text, webViewURL: url, size, intent, nextIntent });
    }
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  handleStep(step, isSubmit = false) {
    const obj = getFormDetails(this.state, this.onInputValidate);
    if (!obj) return false;

    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { webViewURL, text, prevElement, intent, size, nextIntent } = this.state;
    const { handleSubmitElement } = this.props;
    const data = {
      input_type: "webview",
      next_intent: nextIntent,
      request_params: [
        {
          title: text,
          url: webViewURL,
          size: size,
        },
      ],
    };

    const payload = {
      url: webViewURL,
      text,
      size,
      intent,
      nextIntent,
    };
    if (text) {
      data.text = text;
      payload.text = text;
    }
    handleSubmitElement(data, prevElement, payload);
  }

  renderStep1() {
    const { boxes, botElementId, connectors, attributeList } = this.props;
    const { webViewURL, prevElement, text, errors, size, prevElementStructure } = this.state;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </ElementForm>
          <ElementForm title="Title">
            <Input
              name="text"
              placeholder="Enter Title"
              outerClassName="mb0"
              value={text}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </ElementForm>
          <ElementForm title="Size">
            <Select
              options={viewSizes}
              name="size"
              value={size}
              outerWidth={300}
              outerClassName="mb0"
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </ElementForm>
          <ElementForm title="URL">
            <div>
              <Input
                title="WebView URL"
                name="webViewURL"
                placeholder="Enter WebView URL"
                className="form-control"
                outerClassName=" mb5"
                value={webViewURL}
                isReq={true}
                error={errors.webViewURL}
                onChangeFunc={(name, value) => this.setState({ [name]: value })}
                validationFunc={this.onInputValidate}
                appendIcon={
                  <SelectAttribute
                    attributeList={attributeList}
                    externalAttributes={prevElementStructure}
                    onChangeFunc={(value, inputName) =>
                      this.setState({ webViewURL: value }, () => {
                        this.onInputValidate(inputName, null);
                      })
                    }
                    setAttribute={[null, webViewURL]}
                  />
                }
              />
              <div className="clearfix" />
            </div>
          </ElementForm>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { resetElement, botElementId } = this.props;
    const { step } = this.state;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-webview" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default WebView;
