import React from "react";
import { Input } from "../../../../../common/components";
import { Label } from "reactstrap";

function Dropdown(props) {
  const { propertyList, onChangeProperties } = props;

  return (
    <React.Fragment>
      <div>
        <Label className="mb-1 fw-semibold">Label</Label>
        <Input
          type="text"
          placeholder="Enter Label"
          name="label"
          outerClassName="mb-2"
          value={propertyList.label}
          onChangeFunc={onChangeProperties}
        />
      </div>
      <div>
        <Label className="mb-1 fw-semibold">Name</Label>
        <Input
          type="text"
          placeholder="Enter Name"
          name="name"
          outerClassName="mb-2"
          value={propertyList.name}
          onChangeFunc={onChangeProperties}
        />
      </div>
      <div>
        <Label className="mb-1 fw-semibold">Placeholder</Label>
        <Input
          type="text"
          placeholder="Enter Placeholder"
          name="placeholder"
          outerClassName="mb-2"
          value={propertyList.placeholder}
          onChangeFunc={onChangeProperties}
        />
      </div>
      <div>
        <Label className="mb-1 fw-semibold">Options</Label>
        <Input
          type="text"
          placeholder="Enter Options"
          name="options"
          outerClassName="mb-2"
          value={propertyList.options}
          onChangeFunc={onChangeProperties}
        />
      </div>
      <div>
        <Label className="mb-1 fw-semibold">Value</Label>
        <Input
          type="text"
          placeholder="Enter Value"
          name="value"
          outerClassName="mb-2"
          value={propertyList.value}
          onChangeFunc={onChangeProperties}
        />
      </div>
      <div>
        <Label className="mb-1 fw-semibold">Width (%)</Label>
        <Input
          type="text"
          placeholder="Enter Width in %"
          name="width"
          outerClassName="mb-2"
          value={propertyList.width}
          onChangeFunc={onChangeProperties}
        />
      </div>
    </React.Fragment>
  );
}

export default Dropdown;
