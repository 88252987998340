const instanceState = {
  newSocketInst: "",
};

export const socketInstance = newSocketInst => {
  return {
    type: "SOCKET_INSTANCE",
    newSocketInst,
  };
};

// Reducers
export default (state = instanceState, action) => {
  switch (action.type) {
    case "SOCKET_INSTANCE":
      return {
        newSocketInst: action.newSocketInst,
      };
    default:
      return state;
  }
};
