import React from "react";
import { Input, ScrollBox, Select, TextEditor } from "../../../common/components";
import { SelectElement, ElementForm, SubmitElement, SelectIntent, SelectAttribute } from "./components";
import { fileUploadType } from "../../../utils/GeneralUtils";
import { getAttrByPrevElement, loadBotPayload, loadDefaultBotPayload, updateBotPayload } from "../../../utils/BotUtils";
import { getFormDetails } from "../../../common/components/helper.js";
import { API_PRODUCTION, MESSENGER_URL } from "../../../utils/Config";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";
import { cloneDeep, keys } from "lodash";

const LANG_STRUCTURE = {
  text: "",
};

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRich: false,
      text: "",
      uploadType: null,
      fileTypes: "",
      uploadPath: "",
      folder: "",
      intent: null,
      nextIntent: "",
      step: 1,
      prevElementStructure: [],
      errors: {
        uploadType: null,
        uploadPath: null,
        folder: null,
      },
      customFileUpload: {
        postUrl: "",
        fileKey: "",
        isMultiple: true,
        maxSize: null,
        maxValidationText: "",
        errors: {
          postUrl: null,
          fileKey: null,
        },
      },
      langPayload: loadDefaultBotPayload(LANG_STRUCTURE, props.lang),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);

    this.onChangeInputType = this.onChangeInputType.bind(this);
    this.onInputTypeValidate = this.onInputTypeValidate.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId, lang } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        text,
        isRich,
        intent,
        uploadType,
        fileTypes,
        folder,
        token,
        uploadPath,
        nextIntent,
        customFileUpload,
        ...rest
      } = box.payload;
      const stateObj = {
        folder: folder ? folder : "",
        uploadType: uploadType === "fileuploadonedrive" ? "fileuploadonedrivev2" : uploadType,
        text,
        isRich,
        fileTypes,
        uploadPath,
        nextIntent,
        intent: intent || null,
        ...rest,
        ...loadBotPayload(LANG_STRUCTURE, box.payload, this.state, lang),
      };
      if (uploadType === "customfileupload" && customFileUpload) {
        stateObj.customFileUpload = { ...customFileUpload, errors: { ...this.state.customFileUpload.errors } };
      }
      this.setState(stateObj, () => this.updatePayload());
    }
  }

  updatePayload(prevLang = this.props.lang, callback = () => {}) {
    const props = {
      boxes: this.props.boxes,
      botVersion: this.props.botVersion,
    };
    const langPayload = updateBotPayload(LANG_STRUCTURE, this.state, prevLang, props);
    this.setState(
      {
        langPayload,
        ...langPayload[this.props.lang.selectedLang],
      },
      callback
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang.selectedLang !== prevProps.lang.selectedLang) {
      this.updatePayload(prevProps.lang);
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onChangeInputType(name, value, type) {
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        [name]: value,
      },
    }));
  }

  onInputTypeValidate(name, error, type) {
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        errors: { ...prevState[type].errors, [name]: error },
      },
    }));
  }

  onChangeInput(name, value) {
    this.setState({ [name]: value });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false, prevLang) {
    const { uploadType, customFileUpload } = this.state;

    const form = uploadType === "customfileupload" ? customFileUpload : this.state;
    const fn = uploadType === "customfileupload" ? this.onInputTypeValidate : this.onInputValidate;

    const obj = getFormDetails(form, fn);
    if (!obj) return false;

    if (isSubmit) step--;

    this.setState({ step }, () => this.updatePayload(prevLang, isSubmit ? this.handleSubmit : null));
  }

  handleSubmit() {
    const {
      prevElement,
      isRich,
      uploadType,
      intent,
      uploadPath,
      fileTypes,
      folder,
      nextIntent,
      customFileUpload,
      langPayload,
    } = this.state;
    const { handleSubmitElement, lang } = this.props;

    if (!uploadType) return false;

    const submitCb = () => {
      const langDataObj = {};
      const langPayloadObj = {};

      keys(langPayload).map(x => {
        const { text } = langPayload[x];

        if (uploadType === "fileuploadonedrivev2") {
          langDataObj[x] = {
            input_type: "fileuploadonedrivev2",
            next_intent: nextIntent,
            request_params: [
              {
                text,
                pathToUpload: uploadPath,
                maxSize: 4194304,
                fileTypes,
                folder,
                displayString: "File Upload:",
                accessToken: BOT_ATTRIBUTES.ACCESS_TOKEN,
                merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
                oneDriveURL: `${MESSENGER_URL}libs/onedrivefileupload.html?q=`,
                engagementId: BOT_ATTRIBUTES.ENGAGEMENT_ID,
                sessionId: BOT_ATTRIBUTES.SESSION_ID,
                serviceId: BOT_ATTRIBUTES.SERVICE_ID,
                serviceName: BOT_ATTRIBUTES.SERVICE_NAME,
                apiEndPoint: API_PRODUCTION,
              },
            ],
          };
          langPayloadObj[x] = {
            text: text,
          };
        } else if (uploadType === "customfileupload") {
          langDataObj[x] = {
            input_type: "customfileupload",
            next_intent: nextIntent,
            request_params: [
              {
                text,
                postUrl: customFileUpload.postUrl,
                fileKey: customFileUpload.fileKey,
                isMultiple: customFileUpload.isMultiple,
                maxSize: customFileUpload.maxSize,
                maxValidationText: customFileUpload.maxSize ? customFileUpload.maxValidationText : "",
                displayString: "File Upload:",
                merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
                fileUploadURL: `${MESSENGER_URL}libs/fileupload.html?q=`,
                engagementId: BOT_ATTRIBUTES.ENGAGEMENT_ID,
                sessionId: BOT_ATTRIBUTES.SESSION_ID,
                serviceId: BOT_ATTRIBUTES.SERVICE_ID,
                serviceName: BOT_ATTRIBUTES.SERVICE_NAME,
              },
            ],
          };
          langPayloadObj[x] = {
            text: text,
          };
        }
      });

      return { langDataObj, langPayloadObj };
    };

    const { langDataObj, langPayloadObj } = submitCb();
    const { text } = langPayloadObj[lang.defaultLang];

    let payload = {
      text,
      isRich,
      uploadType,
      intent,
      nextIntent,
      langPayload: langPayloadObj,
    };

    if (uploadType === "fileuploadonedrivev2") {
      payload = { ...payload, folder, fileTypes, uploadPath };
    } else if (uploadType === "customfileupload") {
      payload = { ...payload, customFileUpload: cloneDeep(customFileUpload) };
      delete payload.customFileUpload.errors;
    }

    handleSubmitElement(langDataObj[lang.defaultLang], prevElement, payload, langDataObj);
  }

  renderOneDriveUpload() {
    const { prevElementStructure, folder, uploadPath, fileTypes, errors } = this.state;
    const { attributeList, lang } = this.props;
    return (
      <div className={`b-form ${lang.className}`}>
        <ElementForm title="Upload Path">
          <Input
            type="text"
            placeholder="Enter the path to upload"
            name="uploadPath"
            outerClassName="mb0"
            title="Upload path"
            value={uploadPath}
            isReq={true}
            error={errors.uploadPath}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.onChangeInput}
          />
        </ElementForm>

        <ElementForm title="Folder">
          <Input
            type="text"
            placeholder="Enter the one drive folder"
            name="folder"
            outerClassName="mb0"
            title="Folder"
            value={folder}
            isReq={true}
            error={errors.folder}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) =>
                  this.setState({ folder: value }, () => {
                    this.onInputValidate(inputName, null);
                  })
                }
                setAttribute={[null, folder]}
              />
            }
            validationFunc={this.onInputValidate}
            onChangeFunc={this.onChangeInput}
          />
        </ElementForm>

        <ElementForm title="Upload File Types">
          <Input
            type="text"
            placeholder="Enter the file types"
            name="fileTypes"
            outerClassName="mb5"
            value={fileTypes}
            onChangeFunc={this.onChangeInput}
          />
          <label className="mb0">Eg: image/*, text/*, .doc, .docx, .pdf, .csv</label>
        </ElementForm>
      </div>
    );
  }

  renderAPIUpload() {
    const { lang } = this.props;
    const { customFileUpload } = this.state;
    return (
      <div className={`b-form ${lang.className}`}>
        <ElementForm title="POST URL">
          <Input
            type="text"
            placeholder="Enter the POST URL"
            name="postUrl"
            outerClassName="mb0"
            title="POST URL"
            value={customFileUpload.postUrl}
            isReq={true}
            error={customFileUpload.errors.postUrl}
            validationFunc={(name, error) => this.onInputTypeValidate(name, error, "customFileUpload")}
            onChangeFunc={(name, value) => this.onChangeInputType(name, value, "customFileUpload")}
          />
        </ElementForm>

        <ElementForm title="File Key">
          <Input
            type="text"
            placeholder="Enter the File Key"
            name="fileKey"
            outerClassName="mb0"
            title="File Key"
            value={customFileUpload.fileKey}
            isReq={true}
            error={customFileUpload.errors.fileKey}
            validationFunc={(name, error) => this.onInputTypeValidate(name, error, "customFileUpload")}
            onChangeFunc={(name, value) => this.onChangeInputType(name, value, "customFileUpload")}
          />
        </ElementForm>
        <ElementForm title="Max File Size">
          <Input
            reqType="number"
            placeholder="Enter max file size"
            name="maxSize"
            outerClassName="mb5"
            title="Max File Size"
            value={customFileUpload.maxSize}
            appendIcon={<span style={{ display: "table", margin: "auto", marginLeft: "10px" }}>MB</span>}
            error={customFileUpload.errors.maxSize}
            validationFunc={(name, error) => this.onInputTypeValidate(name, error, "customFileUpload")}
            onChangeFunc={(name, value) => this.onChangeInputType(name, value, "customFileUpload")}
          />
          <label className="mb0">Please enter the size in MB</label>
        </ElementForm>
        <ElementForm title="Max Validation Text">
          <Input
            type="text"
            placeholder="Enter max size validation text"
            name="maxValidationText"
            outerClassName="mb0"
            title="Validation Text"
            value={customFileUpload.maxValidationText}
            error={customFileUpload.errors.maxValidationText}
            validationFunc={(name, error) => this.onInputTypeValidate(name, error, "customFileUpload")}
            onChangeFunc={(name, value) => this.onChangeInputType(name, value, "customFileUpload")}
          />
        </ElementForm>
        <ElementForm title="Multiple Files">
          <Input
            type="checkbox"
            name="isMultiple"
            outerClassName="float-left mr10 mb0"
            style={{ width: 20 }}
            checked={customFileUpload.isMultiple}
            onChangeFunc={(name, value) =>
              this.onChangeInputType(name, !customFileUpload.isMultiple, "customFileUpload")
            }
          />
        </ElementForm>
      </div>
    );
  }

  renderStep1() {
    const { prevElement, isRich, text, uploadType, errors } = this.state;
    const { boxes, botElementId, connectors, lang } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm rowClassName={lang.className} prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Message">
            {isRich ? (
              <TextEditor title="Message" name="text" value={text} onChangeFunc={this.onChangeInput} />
            ) : (
              <Input
                type="text"
                value={text}
                placeholder="Enter text message"
                outerClassName="mb0"
                name="text"
                onChangeFunc={this.onChangeInput}
              />
            )}
            <label className={`mb0 ${lang.className}`} style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>

          <ElementForm rowClassName={lang.className} title="Type">
            <Select
              name="uploadType"
              value={uploadType}
              title="Upload Type"
              outerClassName="mb0"
              options={fileUploadType}
              isClearable={false}
              isReq={true}
              error={errors.uploadType}
              validationFunc={this.onInputValidate}
              onChangeFunc={this.onChangeInput}
            />
          </ElementForm>
        </div>

        {uploadType === "fileuploadonedrivev2" && this.renderOneDriveUpload()}
        {uploadType === "customfileupload" && this.renderAPIUpload()}
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId, lang } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
          lang={lang}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId, lang } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-send-msg-group" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
          lang={lang}
        />
      </React.Fragment>
    );
  }
}

export default FileUpload;
