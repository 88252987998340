import React from "react";
import { Input, Select, ScrollBox } from "../../../common/components";
import { Row, Col } from "reactstrap";
import CommonUtils from "../../../utils/CommonUtils";
import { getRandomNumber, isArray } from "../../../utils/GeneralUtils";
import { SelectElement, SubmitElement, SelectIntent } from "./components";
import { getBotIntent } from "../../../utils/BotUtils";
import { cloneDeep, get } from "lodash";

class TriggerPath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBotName: null,
      botIntent: null,
      version: null,
      nextIntent: "",
      intent: null,
      invokeCbIntent: false,
      cbIntent: null,
      step: 1,
      botIntentList: [],
      payloadQR: [],
      type: "chatbot",
      extensionIntent: null,
      extensionIntentList: [],
    };
    this.handleBotChange = this.handleBotChange.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getExtensions = this.getExtensions.bind(this);
  }
  componentDidMount() {
    this.getExtensions();
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        type = "chatbot",
        extensionIntent,
        botIntent,
        selectedBotName,
        nextIntent,
        intent,
        version,
        invokeCbIntent,
        cbIntent,
        qr = [],
      } = box.payload;
      this.setState(
        {
          type,
          extensionIntent,
          botIntent,
          selectedBotName,
          nextIntent,
          intent: intent || null,
          version,
          invokeCbIntent,
          cbIntent,
          payloadQR: cloneDeep(qr),
        },
        () => {
          this.getBotIntentList(selectedBotName);
        }
      );
    }
  }

  handleSubmit() {
    const {
      prevElement,
      invokeCbIntent,
      cbIntent,
      botIntent,
      selectedBotName,
      nextIntent,
      version,
      payloadQR,
      intent,
      type,
      extensionIntent,
    } = this.state;
    const { handleSubmitElement, boxes, botVersion } = this.props;

    const data = {
      input_type: "triggerPath",
      next_intent: nextIntent,
      request_params: [
        {
          attributes: [
            {
              type,
            },
          ],
        },
      ],
    };

    let payload = {
      type,
      nextIntent,
      intent,
      qr: [],
    };

    if (type === "extension") {
      data.request_params[0].attributes[0].extensionIntent = extensionIntent;
      payload.extensionIntent = extensionIntent;
    } else {
      data.request_params[0].attributes[0] = {
        ...data.request_params[0].attributes[0],
        selectedBotName: invokeCbIntent ? null : selectedBotName,
        botIntent: invokeCbIntent ? null : botIntent,
        version: invokeCbIntent ? null : version,
        cbIntent: invokeCbIntent
          ? null
          : getBotIntent(
              boxes.find(x => x.id === cbIntent),
              botVersion
            ),
        invokeCbIntent,
      };
      payload = {
        ...payload,
        botIntent: invokeCbIntent ? null : botIntent,
        selectedBotName: invokeCbIntent ? null : selectedBotName,
        version: invokeCbIntent ? null : version,
        cbIntent: invokeCbIntent ? null : cbIntent,
        invokeCbIntent,
      };

      if (!invokeCbIntent && payload.cbIntent) {
        payload.qr = [
          {
            id: payloadQR[0] ? payloadQR[0].id : getRandomNumber(6),
            connection: cbIntent,
            text: "Callback",
          },
        ];
      }
    }

    handleSubmitElement(data, prevElement, payload);
  }
  handleStep(step, isSubmit = false) {
    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }
  handleBotChange(name, value) {
    this.setState({ [name]: value }, () => {
      this.getBotIntentList(value);
    });
  }

  getBotIntentList(botName) {
    if (!botName) return;
    CommonUtils.getStoryDialog(botName).then(res => {
      const list = [];
      let version;
      if (isArray(res) && res[0].story && isArray(res[0].story.storyBoardList)) {
        version = res[0].version;
        res[0].story.storyBoardList.map(x => {
          const template = (x.template || []).join();
          if (list.findIndex(l => l.template === template && l.description === x.description) === -1) {
            list.push({
              value: x.intent,
              label: `${x.description} (${x.intent})`,
              description: x.description,
              template: template,
            });
          }
        });
        // res[0].uiPosition.boxes.map(x => {
        //   // if (x.payload && x.payload.intent) {
        //   //   x.payload.intent.split(',').map(intent => list.push({ value: intent, label: `${x.typeData.label} (${intent})` }));
        //   // }
        //   const intent = getBotIntent(x, this.props.botVersion);
        //   if (res[0].story.storyBoardList.findIndex(s => s.intent === intent) !== -1) {
        //     list.push({ value: intent, label: `${x.typeData.label || x.typeData.name} (${intent})` });
        //   }
        // });
      }
      this.setState({ botIntentList: list, version });
    });
  }

  async getExtensions() {
    const result = await CommonUtils.getModelIntent();
    if (result.error) return;

    const list = [];
    get(result, "knowledgeBaseDocIntents", []).map(x => {
      get(x, "intents", []).map(i => {
        if (i.intentType === "Extension" && i.intent !== this.props.botName) {
          list.push({
            label: i.intent,
            value: i.intent,
          });
        }
      });
    });
    this.setState({ extensionIntentList: list });
  }

  renderStep1() {
    const { boxes, merchantBotList, botName, botElementId, connectors } = this.props;
    const {
      botIntent,
      botIntentList,
      selectedBotName,
      prevElement,
      invokeCbIntent,
      cbIntent,
      type,
      extensionIntent,
      extensionIntentList,
    } = this.state;
    let bot_list = merchantBotList.filter(m => m.botName !== botName).map(m => ({ value: m.botName, label: m.title }));
    return (
      <React.Fragment>
        <div className="ele-content">
          <Row className="mb-2">
            <Col md="3" className="text-right">
              <label className="mt5">Type</label>
            </Col>
            <Col md="4" className="pl-0">
              <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
                <Input
                  type="radio"
                  name="type"
                  outerClassName="float-left mr10 mb0"
                  style={{ width: 20 }}
                  value="chatbot"
                  checked={type === "chatbot"}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
                Chatbot
              </label>
              <label className="mb0" style={{ lineHeight: "33px" }}>
                <Input
                  type="radio"
                  name="type"
                  outerClassName="float-left mr10 mb0"
                  style={{ width: 20 }}
                  value="extension"
                  checked={type === "extension"}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
                Extension
              </label>
            </Col>
          </Row>
          {type === "chatbot" ? (
            <React.Fragment>
              <Row>
                <Col md="3" className="text-right">
                  <label className="mt5">Invoke Callback Intent</label>
                </Col>
                <Col md="4" className="pl-0">
                  <Input
                    type="checkbox"
                    name="invokeCbIntent"
                    outerClassName="float-left mr10"
                    style={{ width: 20 }}
                    checked={invokeCbIntent}
                    onChangeFunc={(name, value, err, obj, e) => this.setState({ [name]: e.target.checked })}
                  />
                </Col>
              </Row>
              {!invokeCbIntent && (
                <React.Fragment>
                  <Row>
                    <Col md="3" className="text-right">
                      <label className="mt5">Chatbot Template</label>
                    </Col>
                    <Col md="4" className="pl-0">
                      <Select
                        placeholder="Select Chatbot template"
                        name="selectedBotName"
                        value={selectedBotName}
                        options={bot_list}
                        outerWidth={330}
                        onChangeFunc={this.handleBotChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" className="text-right">
                      <label className="mt5">Intent</label>
                    </Col>
                    <Col md="4" className="pl-0">
                      <Select
                        placeholder="Select Bot Intent"
                        name="botIntent"
                        value={botIntent}
                        options={botIntentList}
                        outerWidth={330}
                        onChangeFunc={(name, value) => this.setState({ [name]: value })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" className="text-right">
                      <label className="mt5">Callback Intent</label>
                    </Col>
                    <Col md="4" className="pl-0">
                      <SelectElement
                        name="cbIntent"
                        placeholder="Select Callback Intent"
                        value={cbIntent}
                        boxes={boxes}
                        outerWidth={330}
                        outerClassName={"mt0"}
                        onChangeFunc={(name, value) => this.setState({ [name]: value })}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <Row>
              <Col md="3" className="text-right">
                <label className="mt5">Extension</label>
              </Col>
              <Col md="4" className="pl-0">
                <Select
                  placeholder="Select Extension"
                  name="extensionIntent"
                  value={extensionIntent}
                  options={extensionIntentList}
                  outerWidth={330}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md="3" className="text-right">
              <label className="mt5">Select Previous Element</label>
            </Col>
            <Col md="4" className="pl-0">
              <SelectElement
                value={prevElement}
                boxes={boxes}
                botElementId={botElementId}
                connectors={connectors}
                outerWidth={330}
                onChangeFunc={(name, value) => this.setState({ [name]: value })}
              />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }
  render() {
    const { resetElement, botElementId } = this.props;
    const { step } = this.state;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-trigger-path" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          step={step}
          resetElement={resetElement}
          botElementId={botElementId}
          handleSubmit={this.handleSubmit}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default TriggerPath;
