import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
// import { withRouter } from "../../withRouter";
import { Input, Button } from "../../common/components";
import { BRAND_COMPANY_NAME, BRAND_DOMAIN, BRAND_DOMAIN_LINK, BRAND_LOGO_URL } from "../../utils/Config";
import { withRouter } from "../../withRouter";

class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
    };
  }

  render() {
    return (
      <div className="login page-layout">
        <div className="layout">
          <img src={BRAND_LOGO_URL} />
          <div className="info">
            World's most advanced
            <br />
            Conversation Computing Cloud
            <br />
            Platform for all Customer
            <br />
            Engagements.
            <br />
            <span>One Platform, All Channels, All Engagements</span>
          </div>
        </div>
        <div className="content">
          <div className="box">
            <Card className="br4">
              <CardBody>
                <form>
                  <h1>Unsubscribe</h1>
                  <p className="text-muted">Why are you unsubscribing?</p>

                  <div>
                    <input type="radio" name="unsubscribe" className="mr5 cursor-pointer" id="opt1" />
                    <label className="cursor-pointer" htmlFor="opt1">
                      I'm not interested anymore
                    </label>
                  </div>
                  <div>
                    <input type="radio" name="unsubscribe" className="mr5 cursor-pointer" id="opt2" />
                    <label className="cursor-pointer" htmlFor="opt2">
                      I receive too many of these emails
                    </label>
                  </div>
                  <div>
                    <input type="radio" name="unsubscribe" className="mr5 cursor-pointer" id="opt3" />
                    <label className="cursor-pointer" htmlFor="opt3">
                      This content is not relevant to me
                    </label>
                  </div>
                  <div>
                    <input type="radio" name="unsubscribe" className="mr5 cursor-pointer" id="opt4" />
                    <label className="cursor-pointer" htmlFor="opt4">
                      This is spam / I never subscribed to this email list
                    </label>
                  </div>
                  <div>
                    <input type="radio" name="unsubscribe" className="mr5 cursor-pointer" id="opt5" />
                    <label className="cursor-pointer" htmlFor="opt5">
                      My inbox is too full
                    </label>
                  </div>
                  <div>
                    <input type="radio" name="unsubscribe" className="cursor-pointer mr5" id="opt6" />
                    <label className="cursor-pointer" htmlFor="opt6">
                      Other
                    </label>
                  </div>
                  <hr />
                  <div>
                    <label className="mr10">Email</label>
                    <Input outerClassName="unsubscribe-input" />
                  </div>
                  <Button text="Submit" className="px-4 cb cb-back" style={{ marginLeft: 45 }} />
                </form>
              </CardBody>
            </Card>
          </div>
          <div className="footer">
            © 2017-{new Date().getFullYear()} All Rights Reserved.
            <br />
            <a href={BRAND_DOMAIN_LINK} target="_blank">
              {BRAND_DOMAIN}
            </a>
            ® is a registered trademark of {BRAND_COMPANY_NAME}
            <br />
            <span>Cookie Preferences, Privacy, and Terms.</span>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default withRouter(Unsubscribe);
