import React from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink as RLink, Badge, UncontrolledTooltip } from "reactstrap";
import { NavLink } from "react-router-dom";
import { withRouter } from "../../../withRouter";

let i = 0;

export class SubMenu extends React.Component {
  constructor(props) {
    super(props);
    this._id = `toolTip-${i++}`;
  }

  // badge addon to NavItem
  badge(badge) {
    if (badge) {
      const classes = classNames(badge.class);
      return (
        badge.text && (
          <Badge className={classes} color={badge.variant}>
            {badge.text}
          </Badge>
        )
      );
    }
  }

  toggleMenu(e, index) {
    e.stopPropagation();
    this.props.toggleMenu(index);
  }

  render() {
    const { classes, item, index, collapsed, sidebarOpen } = this.props;
    return (
      <div key={index}>
        <NavItem
          onClick={e => {
            if (item.target) {
              this.props.navigate(item.target);
            }
            this.props.toggleMenu(index);
          }}
          className={`cursor-pointer ${classes.item}`}
          title={item.name}
        >
          <RLink className={classes.link} to={item.target}>
            <i className={`${classes.icon}`} id={this._id}></i>
            {item.name}
            {!sidebarOpen && (
              <i
                onClick={e => (item.target ? this.toggleMenu(e, index) : null)}
                className={`fa ${collapsed ? ` fa-chevron-down` : `fa-chevron-right`} mt-1 float-right`}
              ></i>
            )}
          </RLink>
        </NavItem>
        {sidebarOpen && (
          <UncontrolledTooltip
            modifiers={[{ preventOverflow: { boundariesElement: "viewport" } }]}
            placement="right-start"
            target={this._id}
            autohide={false}
          >
            <div className="submenu-nav scroll-v1">
              {item.subMenu.map((item, i) => (
                <NavLink activeClassName="active" key={i} to={item.target} className={classes.link}>
                  {item.title}
                  {item.badge && <span className="mr20"></span>}
                  {this.badge(item.badge)}
                </NavLink>
              ))}
            </div>
          </UncontrolledTooltip>
        )}
        <Collapse isOpen={!sidebarOpen && collapsed} navbar className={classNames("active")}>
          {item.subMenu.map((item, i) => (
            <NavLink key={i} activeClassName="active" to={item.target} className={classes.link}>
              <i></i>
              {/* <i className={classes.icon}></i> */}
              {item.title}
              {this.badge(item.badge)}
            </NavLink>
          ))}
        </Collapse>
      </div>
    );
  }
}
export default withRouter(SubMenu);
