import "./polyfill";
import "isomorphic-fetch";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import { Provider } from "react-redux";
// Styles
// Import Flag Icons Set
//import "flag-icon-css/css/flag-icon.min.css";
import "flag-icons/css/flag-icons.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "../scss/style.scss";
// Temp fix for reactstrap
import "../scss/core/_dropdown-menu-right.scss";
// Import OS font icon
import "./css/font-os.css";

// Containers
import Full from "./App";
import store from "./store";

import version from "../package.json";
import { createRoot } from "react-dom/client";
//console.log("APP VERSION :::", version);

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Full />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
