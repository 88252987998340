import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef, Fragment } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Input, Button } from "./index";
import classnames from "classnames";
import { isImgURL, isURL, isVideoURL, isBase64Image } from "../../utils/GeneralUtils";

const FileContainer = forwardRef(
  (
    {
      previewFiles,
      onFileUpload,
      viewOnly = false,
      isMultiple = false,
      isReq = false,
      docType,
      tabOptions = ["UPLOAD", "URL"],
    },
    ref
  ) => {
    const acceptText = ".txt,.rtf,.doc,.docx,.odt,.pdf";
    const acceptImage = "image/*";
    const acceptDoc = ".doc,.docx,.odt";
    const acceptPdf = ".pdf";
    switch (docType) {
      case "Text":
        docType = acceptText;
        break;
      case "PDF":
        docType = acceptPdf;
        break;
      case "Image":
        docType = acceptImage;
        break;
      case "Doc":
        docType = acceptDoc;
        break;

      default:
        docType = `${acceptText},${acceptImage},${acceptDoc},${acceptPdf}`;
        break;
    }
    const mediaRef = useRef(null);
    const [data, setData] = useState({
      mediaFiles: [],
      previewFiles: [],
    });
    const [imgUrl, setImgUrl] = useState("");
    const [activeTab, setActiveTab] = useState("browse");
    const [showIcons, setShowIcons] = useState(false);
    const [error, setError] = useState({
      mediaFiles: null,
    });

    useEffect(() => {
      setData({
        mediaFiles: !previewFiles ? [] : isMultiple ? previewFiles : [previewFiles],
        previewFiles: !previewFiles ? [] : isMultiple ? previewFiles : [previewFiles],
      });
    }, [previewFiles]);

    const handleUpload = (data, deleteInfo) => {
      if (mediaRef.current) {
        mediaRef.current.value = "";
      }
      if (onFileUpload) {
        onFileUpload(data, deleteInfo);
      }
    };

    const getMediaFiles = () => {
      if (isReq) {
        setError({
          mediaFiles: data.mediaFiles.length > 0 ? null : "Please select file.",
        });

        if (!data.mediaFiles.length || error.post) {
          return false;
        }
      }

      return data.mediaFiles;
    };

    useImperativeHandle(ref, () => ({
      getMediaFiles,
    }));

    const handleAddImg = () => {
      const previousData = data;
      const updatedData = {
        ...previousData,
        mediaFiles: [...previousData.mediaFiles, imgUrl],
        previewFiles: [...previousData.previewFiles, getFileUrl(imgUrl)],
      };
      setError(prevData => ({ ...prevData, mediaFiles: null }));
      setData(updatedData);
      handleUpload(updatedData);
      setImgUrl("");
    };

    const handleFileChange = e => {
      const previousData = data;
      const file = e.target.files[0];
      setError(prevData => ({ ...prevData, mediaFiles: null }));
      setData(prevData => ({
        ...prevData,
        mediaFiles: [...prevData.mediaFiles, file],
      }));
      let fileReader = new FileReader();
      fileReader.onload = e => {
        const { result } = e.target;
        if (result) {
          setData(prevData => ({
            ...prevData,
            previewFiles: [...prevData.previewFiles, getFileUrl(result, file.name)],
          }));
          handleUpload({
            ...previousData,
            mediaFiles: [...previousData.mediaFiles, file],
            previewFiles: [...previousData.previewFiles, getFileUrl(result, file.name)],
          });
        }
      };
      fileReader.readAsDataURL(file);
    };

    const handleFileDelete = index => {
      const previousData = data;
      const updatedData = {
        ...previousData,
        mediaFiles: previousData.mediaFiles.filter((x, k) => k !== index),
        previewFiles: previousData.previewFiles.filter((x, k) => k !== index),
      };
      setData(updatedData);
      handleUpload(updatedData, { deleteIndex: index }); // incase of delete we also send the deleted index
    };

    const getFileUrl = (value, name) => {
      return isVideoURL(name || value)
        ? "/img/bot-elements/video-img.svg"
        : isImgURL(name || value)
          ? value
          : isBase64Image(name || value)
            ? value
            : "/img/doc.svg";
    };

    const getFilePath = value => {
      return isURL(value) ? value : "";
    };

    return (
      <div className="media-fileupload">
        {!viewOnly && (isMultiple ? true : data.previewFiles.length < 1) && (
          <React.Fragment>
            <Nav className="nav-tab">
              {tabOptions.includes("UPLOAD") && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "browse" })}
                    onClick={() => setActiveTab("browse")}
                  >
                    Upload
                  </NavLink>
                </NavItem>
              )}
              {tabOptions.includes("URL") && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "imgUrl" })}
                    onClick={() => setActiveTab("imgUrl")}
                  >
                    Import from URL
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane className="pl-0 pr-0 pb-2" tabId="browse">
                {activeTab === "browse" && (
                  <Fragment>
                    <button
                      className="border-blue btn mb-2"
                      disabled={viewOnly}
                      onClick={e => {
                        e.preventDefault();
                        mediaRef.current.click();
                      }}
                    >
                      <i className="fa fa-picture-o mr-2" />
                      Browse
                    </button>
                    <input type="file" ref={mediaRef} className="d-none" accept={docType} onChange={handleFileChange} />
                  </Fragment>
                )}
              </TabPane>
              <TabPane className="pl-0 pr-0 pb-3" tabId="imgUrl">
                {activeTab === "imgUrl" && (
                  <div className="d-flex">
                    <Input
                      name="imgUrl"
                      placeholder="Enter URL"
                      outerClassName="mb0 mr10"
                      outerStyle={{ width: "100%" }}
                      value={imgUrl}
                      onChangeFunc={(n, v) => setImgUrl(v)}
                    />
                    <Button
                      text="Add"
                      size="sm"
                      className="w-25"
                      displayIcon={false}
                      submitBtn
                      onClickFunc={handleAddImg}
                    />
                  </div>
                )}
              </TabPane>
            </TabContent>
            {error.mediaFiles && <div className="req-msg">{error.mediaFiles}</div>}
          </React.Fragment>
        )}
        {data.previewFiles.length > 0 && (
          <div className="fu-preview-box">
            {data.previewFiles.map((p, i) => {
              return (
                <div
                  key={i}
                  className="preview-img-box"
                  onMouseEnter={() => setShowIcons(true)}
                  onMouseLeave={() => setShowIcons(false)}
                >
                  {!viewOnly && showIcons && (
                    <React.Fragment>
                      <i
                        className="fa fa-times fu-btn-close"
                        onClick={() => handleFileDelete(i)}
                        style={{ marginTop: 25, marginRight: 10 }}
                      />
                      <a href={getFilePath(p)} target="_blank" rel="noopener noreferrer">
                        <i
                          style={{ marginTop: 25, marginRight: 35 }}
                          className="fa fa-cloud-download fu-btn-download"
                          aria-hidden="true"
                          color="secondary"
                        ></i>
                      </a>
                    </React.Fragment>
                  )}
                  <img key={i} src={getFileUrl(p)} width={100} height={100} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

export default FileContainer;
