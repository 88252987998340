import React, { useState, memo } from "react";
import { InputGroup, InputGroupText } from "reactstrap";
import Input from "./Input";

const PasswordInput = props => {
  const [showPwd, setShowPwd] = useState(false);

  return (
    <InputGroup>
      <Input
        outerClassName="border-right-0 w-100"
        {...props}
        autoComplete="new-password"
        type={showPwd ? "text" : "password"}
        appendIcon={
          <InputGroupText
            title={showPwd ? "Hide" : "Show"}
            className="icon-pwd"
            addonType="append"
            onClick={() => setShowPwd(!showPwd)}
          >
            <i className={`fa fa-eye${showPwd ? "-slash" : ""}`}></i>
          </InputGroupText>
        }
      />
    </InputGroup>
  );
};

export default memo(PasswordInput);
