import React, { useState } from "react";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";
import Button from "./Button";

const CopyToClipboard = props => {
  const [isCopied, setIsCopied] = useState(false);

  if (isCopied) {
    return (
      <span
        className={props.clsName}
        style={{ padding: "0.375rem 0.75rem", fontSize: "0.875rem", lineHeight: "1.5" }}
        title="Copied"
      >
        <i className="fa fa-check"></i>
        {!props.showOnlyIcon && <span className="ml-1">Copied</span>}
      </span>
    );
  }

  return (
    <Button
      linkBtn
      size="md"
      className={props.clsName}
      onClickFunc={() => {
        copy(props.value);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      }}
      title="Copy to clipboard"
      text={
        <React.Fragment>
          <i className="fa fa-clipboard"></i>
          {!props.showOnlyIcon && <span className="ml-1">Copy to clipboard</span>}
        </React.Fragment>
      }
    />
  );
};

CopyToClipboard.defaultProps = {
  value: "",
  clsName: "",
  showOnlyIcon: false,
};

CopyToClipboard.propTypes = {
  value: PropTypes.string,
  clsName: PropTypes.string,
  showOnlyIcon: PropTypes.bool,
};

export default CopyToClipboard;
