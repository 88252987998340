import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle, memo } from "react";
import {
  Badge,
  Col,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import Button from "./Button";
import Input from "./Input";
import Select from "./Select";
import TextArea from "./TextArea";
import { handlePopup } from "../../actions";
import {
  anchorUrl,
  extractAttributes,
  getAllQueryParams,
  handleRequestAPI,
  isArray,
  isJsonString,
  isObjEmpty,
  isURL,
} from "../../utils/GeneralUtils";
import Attributes from "./Attributes";
import PasswordInput from "./PasswordInput";
import { convertArrayToObject } from "../../pages/ModelStudio/helper";
import TooltipLink from "./TooltipLink";

const authTypeOpt = [
  { label: "No Auth", value: "no_auth" },
  { label: "Bearer Token", value: "BEARER_TOKEN" },
  { label: "Basic Auth", value: "BASIC_AUTH" },
];
export const contentTypeOpt = [
  { label: "JSON", value: "application/json" },
  { label: "form-data", value: "application/form-data" },
  { label: "x-www-form-urlencoded", value: "application/x-www-form-urlencoded" },
];

const HttpRequest = forwardRef(({ onHandleSubmit, data, isPopup = false, isContentType = false, intent }, ref) => {
  const initialForm = {
    type: "GET",
    headersList: [{ key: "", value: "" }],
    environmentList: [{ key: "", value: "" }],
    knowledgeMapping: !!intent ? [{ mandatory: false, variable: "", ontologyId: "", variableDescription: "" }] : [],
    paramsList: [{ key: "", value: "" }],
    bodyList: [{ key: "", value: "" }],
    url: "",
    rawData: "",
    response: null,
    authType: "no_auth",
    bearerToken: "",
    username: "",
    password: "",
    contentType: "application/json",
    authorization: {},
  };

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("params");
  const [httpRequestForm, setHttpRequestForm] = useState(initialForm);
  const [apiProcess, setApiProcess] = useState(false);
  const [result, setResult] = useState(null);
  const [status, setStatus] = useState();
  const [success, setSuccess] = useState();
  const [responsedisplay, setResponsedisplay] = useState("pretty");
  const customConfigData = useSelector(state => state.common.merchantAttr.data.customConfig);
  const externalEnvironments = data.externalEnvironments ? data.externalEnvironments : [];

  const [variableList, setVariableList] = useState([]);

  useEffect(() => {
    if (!intent) return;

    const attributes = extractAttributes({
      params: convertArrayToObject(
        getParamsList(httpRequestForm.url).map(x => {
          if (x.key && !x.value.includes("$")) {
            x.value = `$${x.value}`;
          }
          return x;
        })
      ),
      rawData: isJsonString(httpRequestForm.rawData) ? JSON.parse(httpRequestForm.rawData) : "",
      headersList: httpRequestForm.headersList,
      bodyList: httpRequestForm.bodyList,
    }).map(x => ({ label: x, value: x }));
    setVariableList(attributes);
  }, [
    !!intent
      ? JSON.stringify({
          url: httpRequestForm.url,
          rawData: httpRequestForm.rawData,
          headersList: httpRequestForm.headersList,
          bodyList: httpRequestForm.bodyList,
        })
      : false,
  ]);

  const getChildState = () => {
    if (httpRequestForm.authType === "no_auth") {
      httpRequestForm["authorization"] = {};
    } else if (httpRequestForm.authType === "BEARER_TOKEN") {
      httpRequestForm["authorization"] = {
        type: "BEARER_TOKEN",
        token: httpRequestForm.bearerToken,
      };
    } else if (httpRequestForm.authType === "BASIC_AUTH") {
      httpRequestForm["authorization"] = {
        type: "BASIC_AUTH",
        username: httpRequestForm.username,
        password: httpRequestForm.password,
      };
    }
    return httpRequestForm;
  };

  useImperativeHandle(ref, () => ({
    getChildState,
  }));

  useEffect(() => {
    const {
      requestType,
      requestHeaders,
      docLocation,
      requestBody,
      requestAuth,
      environments,
      contentType = initialForm.contentType,
      knowledgeMapping,
    } = data;
    setHttpRequestForm({
      ...initialForm,
      type: requestType,
      headersList: isArray(requestHeaders) ? requestHeaders : [{ key: "", value: "" }],
      environmentList: isArray(environments) ? environments : initialForm.environmentList,
      knowledgeMapping: isArray(knowledgeMapping) ? knowledgeMapping : initialForm.knowledgeMapping,
      paramsList: getParamsList(docLocation),
      url: docLocation,
      rawData: contentType === "application/json" ? requestBody : "",
      bodyList: contentType !== "application/json" ? requestBody : initialForm.bodyList,
      bearerToken: requestAuth && requestAuth.token ? requestAuth.token : "",
      username: requestAuth && requestAuth.username ? requestAuth.username : "",
      password: requestAuth && requestAuth.password ? requestAuth.password : "",
      authType: (requestAuth && requestAuth.type) || "no_auth",
      contentType,
      response: "",
    });
    setResult("");
    setStatus("");
  }, [JSON.stringify(data)]);

  const getParamsList = url => {
    let query = anchorUrl(url).search;
    let list = [];
    let params;
    //if (isURL(url)) {
    if (query.length > 0) {
      params = getAllQueryParams(url);
      if (!isObjEmpty(params)) {
        for (const [key, value] of Object.entries(params)) {
          let paramEntry = {
            key: key,
            value: value != "undefined" ? value : "",
          };
          list.push(paramEntry);
        }
      }
    } else {
      list = [{ key: "", value: "" }];
    }
    //}
    return isArray(list) ? list : [{ key: "", value: "" }];
  };

  const setRespToDisplay = value => {
    let data = result;
    setResponsedisplay(value);
    try {
      switch (value) {
        case "pretty":
          data = JSON.parse(data);
          data = JSON.stringify(data, null, 2);
          break;
        case "raw":
          data = JSON.parse(data);
          data = JSON.stringify(data);
          break;
        default:
          data = JSON.parse(data);
          data = data;
          break;
      }
    } catch (e) {}
    setResult(data);
  };

  const setList = (list, type) => {
    setHttpRequestForm(prevState => ({
      ...prevState,
      [type]: list,
    }));
    if (type === "paramsList") {
      updateUrl(list);
    }
  };

  const setParams = url => {
    let query = anchorUrl(url).search;
    const list = [];
    let params;

    if (isURL(url) && query.length > 0) {
      params = getAllQueryParams(url);
      if (!isObjEmpty(params)) {
        for (const [key, value] of Object.entries(params)) {
          const paramEntry = {
            key: key,
            value: value != "undefined" ? value : "",
          };
          list.push(paramEntry);
        }
      }
    }

    if (list.length > 0) {
      setHttpRequestForm(prev => {
        return {
          ...prev,
          paramsList: list,
        };
      });
    }
  };

  const updateUrl = (paramsList, listName) => {
    let url = httpRequestForm.url.split("?")[0];
    if (paramsList.length > 0 && paramsList[0].key) {
      paramsList.map((p, i) => {
        url += `${i === 0 ? "?" : "&"}${p.key}=${p.value}`;
      });
    }
    setHttpRequestForm(prev => {
      return {
        ...prev,
        url,
      };
    });
  };

  const onSubmit = () => {
    const reqestObj = {
      docLocation: httpRequestForm.url,
      requestBody: httpRequestForm.rawData,
      requestHeaders: httpRequestForm.headersList,
      requestType: httpRequestForm.type.toUpperCase(),
      requestParams: httpRequestForm.paramsList,
      environments: httpRequestForm.environmentList,
    };
    if (isPopup) {
      onHandleSubmit(reqestObj);
      dispatch(handlePopup());
    }
  };

  const handleTestRequest = async () => {
    setApiProcess(true);
    const response = await handleRequestAPI(httpRequestForm, externalEnvironments, customConfigData);
    setApiProcess(false);
    setStatus(`${response.status} ${response.statusText}`);
    setSuccess(response.success);
    setResult(JSON.stringify(response.data || response.error, null, 2));
    setHttpRequestForm(prev => {
      return {
        ...prev,
        response: JSON.stringify(response.data || response.error),
      };
    });
  };

  const renderTab = () => {
    const addOnClass = !!intent ? "px-2 fs13 " : "";
    return (
      <div className="media-fileupload http-request-head v1-design bg-transparent">
        <Nav className="nav-tab">
          <NavItem>
            <NavLink
              className={`${addOnClass}${classnames({ active: activeTab === "params" })}`}
              onClick={() => {
                setActiveTab("params");
              }}
            >
              {" "}
              Params
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${addOnClass}${classnames({ active: activeTab === "authorization" })}`}
              onClick={() => {
                setActiveTab("authorization");
              }}
            >
              Authorization
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${addOnClass}${classnames({ active: activeTab === "headers" })}`}
              onClick={() => {
                setActiveTab("headers");
              }}
            >
              {" "}
              Headers
            </NavLink>
          </NavItem>
          {["POST", "PUT", "PATCH"].includes(httpRequestForm.type) && (
            <NavItem>
              <NavLink
                className={`${addOnClass}${classnames({ active: activeTab === "body" })}`}
                onClick={() => {
                  setActiveTab("body");
                }}
              >
                {" "}
                Body
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={`${addOnClass}${classnames({ active: activeTab === "environment" })}`}
              onClick={() => {
                setActiveTab("environment");
              }}
            >
              <span>Environment</span>
              {httpRequestForm.environmentList.filter(x => x.key || x.value).length > 0 && (
                <Badge className="ml-2" pill color="orange">
                  {httpRequestForm.environmentList.filter(x => x.key || x.value).length}
                </Badge>
              )}
            </NavLink>
          </NavItem>
          {!!intent && (
            <NavItem>
              <NavLink
                className={`${addOnClass}${classnames({ active: activeTab === "knowledge" })}`}
                onClick={() => {
                  setActiveTab("knowledge");
                }}
              >
                <span>Knowledge</span>
                {httpRequestForm.knowledgeMapping.filter(x => x.variable).length > 0 && (
                  <Badge className="ml-2" pill color="orange">
                    {httpRequestForm.knowledgeMapping.filter(x => x.variable).length}
                  </Badge>
                )}
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="params">
            <Attributes list={httpRequestForm.paramsList} type="paramsList" setList={setList} />
          </TabPane>
          <TabPane tabId="authorization">
            {activeTab === "authorization" && (
              <div className="d-flex">
                <div className="pr-4 w-100" style={{ minWidth: 180, maxWidth: 250 }}>
                  <Label>Auth Type</Label>
                  <Select
                    name="authType"
                    value={httpRequestForm.authType}
                    options={authTypeOpt}
                    isClearable={false}
                    onChangeFunc={(name, value, err, obj, e) => {
                      setHttpRequestForm(prev => {
                        return {
                          ...prev,
                          [name]: value,
                        };
                      });
                    }}
                  />
                </div>
                <div className="border-left border-gray-200 pl-4 w-100">
                  {httpRequestForm.authType === "no_auth" && (
                    <label className="mt5">This request does not use any authorization.</label>
                  )}
                  {httpRequestForm.authType === "BEARER_TOKEN" && (
                    <React.Fragment>
                      <Label>Token</Label>
                      <Input
                        placeholder="Token"
                        name="bearerToken"
                        value={httpRequestForm.bearerToken}
                        onChangeFunc={(name, value, err, obj, e) => {
                          setHttpRequestForm(prev => {
                            return {
                              ...prev,
                              [name]: value,
                            };
                          });
                        }}
                      />
                    </React.Fragment>
                  )}
                  {httpRequestForm.authType === "BASIC_AUTH" && (
                    <div style={{ maxWidth: 300 }}>
                      <Label>Username</Label>
                      <Input
                        placeholder="Username"
                        name="username"
                        value={httpRequestForm.username}
                        onChangeFunc={(name, value, err, obj, e) => {
                          setHttpRequestForm(prev => {
                            return {
                              ...prev,
                              [name]: value,
                            };
                          });
                        }}
                      />
                      <Label>Password</Label>
                      <PasswordInput
                        placeholder="Password"
                        name="password"
                        value={httpRequestForm.password}
                        onChangeFunc={(name, value, err, obj, e) => {
                          setHttpRequestForm(prev => {
                            return {
                              ...prev,
                              [name]: value,
                            };
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
                {/* <Row>
                    <Col md="3" className="pr-4"></Col>
                    <Col md="9" className="border-left border-gray-200 pl-3"></Col>
                  </Row> */}
              </div>
            )}
          </TabPane>
          <TabPane tabId="headers">
            <Attributes list={httpRequestForm.headersList} type="headersList" setList={setList} />
          </TabPane>
          <TabPane tabId="body">
            {activeTab === "body" && ["POST", "PUT", "PATCH"].includes(httpRequestForm.type) && (
              <div>
                {isContentType && (
                  <Row className="mr-2">
                    <Col>
                      <Label>Content Type</Label>
                      <Select
                        name="contentType"
                        value={httpRequestForm.contentType}
                        options={contentTypeOpt}
                        isClearable={false}
                        outerWidth={250}
                        onChangeFunc={(name, value, err, obj, e) => {
                          setHttpRequestForm(prev => {
                            return {
                              ...prev,
                              [name]: value,
                            };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  {httpRequestForm.contentType === "application/json" ? (
                    <Col>
                      {isContentType && <Label>Body</Label>}
                      <div className="position-relative">
                        <TextArea
                          name="rawData"
                          value={httpRequestForm.rawData}
                          rows={5}
                          onChangeFunc={(name, value, err, obj, e) => {
                            setHttpRequestForm(prev => {
                              return {
                                ...prev,
                                [name]: value,
                              };
                            });
                          }}
                        />
                        {/* <CopyToClipboard clsName="float-right pr-0" value={httpRequestForm.rawData} /> */}
                      </div>
                    </Col>
                  ) : (
                    <Col>
                      <Label>Body</Label>
                      <Attributes list={httpRequestForm.bodyList} type="bodyList" setList={setList} />
                    </Col>
                  )}
                </Row>
              </div>
            )}
          </TabPane>
          <TabPane tabId="environment">
            <Attributes
              list={httpRequestForm.environmentList}
              type="environmentList"
              keyTitle="Key"
              valueTitle="Value"
              setList={setList}
            />
          </TabPane>
          {!!intent && (
            <TabPane tabId="knowledge">
              <div className="d-flex" style={{ gap: 10, width: "calc(100% - 65px)" }}>
                <div style={{ width: "30%", marginLeft: 30 }}>
                  <label className="fw-semibold">Variable</label>
                </div>
                <div style={{ width: "30%" }}>
                  <label className="fw-semibold">Ontology</label>
                </div>
                <div style={{ width: "40%" }}>
                  <label className="fw-semibold">
                    <span>Validation</span>
                    <TooltipLink
                      link={<i className="fs13 icon-question icons ml-1"></i>}
                      overlay={
                        <span>
                          Please provide a detailed description of the attribute <br /> to facilitate a more accurate
                          resolution by GenAI.
                        </span>
                      }
                    />
                  </label>
                </div>
              </div>
              <Attributes
                key={JSON.stringify(intent.ontologies) + JSON.stringify(variableList)}
                list={httpRequestForm.knowledgeMapping}
                keyName="variable"
                keyTitle="Variable"
                valueName="ontologyId"
                valueTitle="Ontology"
                keyOptions={variableList}
                valueOptions={intent.ontologies}
                type="knowledgeMapping"
                isCreatableKey={true}
                showDescription={true}
                showSelection={true}
                selectionName="mandatory"
                keyWidth={30}
                valueWidth={30}
                gap={10}
                descriptionTitle="Validation"
                descriptionName="variableDescription"
                setList={setList}
              />
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  };

  return (
    <Fragment>
      {isPopup && <ModalHeader>Http Request</ModalHeader>}
      <ModalBody className={!isPopup ? "p-0" : ""}>
        <div>
          <div>
            <Row>
              <Col md={12}>
                <input
                  type="radio"
                  name="type"
                  checked={httpRequestForm.type === "GET"}
                  value="GET"
                  className="mr5 cursor-pointer"
                  id="GET"
                  onChange={() =>
                    setHttpRequestForm(prev => {
                      return {
                        ...prev,
                        type: "GET",
                      };
                    })
                  }
                />
                <label className="cursor-pointer mr20" htmlFor="GET">
                  GET
                </label>

                <input
                  type="radio"
                  name="type"
                  checked={httpRequestForm.type === "POST"}
                  value="POST"
                  className="mr5 cursor-pointer"
                  id="POST"
                  onChange={() =>
                    setHttpRequestForm(prev => {
                      return {
                        ...prev,
                        type: "POST",
                      };
                    })
                  }
                />
                <label className="cursor-pointer mr20" htmlFor="POST">
                  POST
                </label>

                <input
                  type="radio"
                  name="type"
                  checked={httpRequestForm.type === "PUT"}
                  value="PUT"
                  className="mr5 cursor-pointer"
                  id="PUT"
                  onChange={() =>
                    setHttpRequestForm(prev => {
                      return {
                        ...prev,
                        type: "PUT",
                      };
                    })
                  }
                />
                <label className="cursor-pointer mr20" htmlFor="PUT">
                  PUT
                </label>

                <input
                  type="radio"
                  name="type"
                  checked={httpRequestForm.type === "PATCH"}
                  value="PATCH"
                  className="mr5 cursor-pointer"
                  id="PATCH"
                  onChange={() =>
                    setHttpRequestForm(prev => {
                      return {
                        ...prev,
                        type: "PATCH",
                      };
                    })
                  }
                />
                <label className="cursor-pointer mr20" htmlFor="PATCH">
                  PATCH
                </label>

                <input
                  type="radio"
                  name="type"
                  checked={httpRequestForm.type === "DELETE"}
                  value="DELETE"
                  className="mr5 cursor-pointer"
                  id="DELETE"
                  onChange={() =>
                    setHttpRequestForm(prev => {
                      return {
                        ...prev,
                        type: "DELETE",
                      };
                    })
                  }
                />
                <label className="cursor-pointer mr20" htmlFor="DELETE">
                  DELETE
                </label>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="d-flex justify-content-between">
                <Input
                  name="url"
                  title="URL"
                  placeholder="Enter Request URL"
                  value={httpRequestForm.url}
                  className="form-control pr35"
                  outerClassName="mr10 w-100"
                  isReq={true}
                  onChangeFunc={(name, value, err, obj, e) => {
                    setHttpRequestForm(prev => {
                      return {
                        ...prev,
                        [name]: value,
                      };
                    });
                    setParams(value);
                  }}
                />
                <Button
                  submitBtn
                  displayIcon={false}
                  style={{ height: "33px", minWidth: 100 }}
                  text="Test"
                  loading={apiProcess}
                  onClickFunc={handleTestRequest}
                  className="flex-shrink-0"
                />
              </Col>
            </Row>

            <Row>
              <Col>{renderTab()}</Col>
            </Row>

            <Row>
              <Col md="12">
                <label className="mt20 float-left font-weight-bold">Response</label>
              </Col>
            </Row>
            {httpRequestForm.response !== null && (
              <Row>
                <Col md="12">
                  <input
                    type="radio"
                    name="responsedisplay"
                    checked={responsedisplay === "pretty"}
                    value="pretty"
                    className="mr5 cursor-pointer"
                    id="pretty"
                    onChange={() => setRespToDisplay("pretty")}
                  />
                  <label className="cursor-pointer mr20" htmlFor="pretty">
                    Pretty
                  </label>

                  <input
                    type="radio"
                    name="responsedisplay"
                    checked={responsedisplay === "raw"}
                    value="raw"
                    className="mr5 cursor-pointer"
                    id="raw"
                    onChange={() => setRespToDisplay("raw")}
                  />
                  <label className="cursor-pointer mr20" htmlFor="raw">
                    Raw
                  </label>

                  {status && (
                    <div className="float-right">
                      Status:
                      <label className={`ml5 ${success ? "text-success" : "text-danger"}`}>{status}</label>
                    </div>
                  )}
                </Col>
              </Row>
            )}

            <TextArea value={result} rows={10} name="response" onChangeFunc={() => false} />
            {/* <CopyToClipboard clsName="float-right pr-0" value={resp} /> */}
          </div>
        </div>
      </ModalBody>
      {isPopup && (
        <ModalFooter>
          <Button className="btn-v1" submitBtn text="Submit" onClickFunc={onSubmit} />
          <Button className="btn-v1" cancelBtn text="Cancel" onClickFunc={() => dispatch(handlePopup())} />
        </ModalFooter>
      )}
    </Fragment>
  );
});

export default memo(HttpRequest);
