import {
  SET_TAB_INDEX,
  SET_MESSAGE_LIST,
  MODAL_POPUP,
  SET_RESPONSE_REVIEW_LIST,
  SET_ACTIVE_VISITOR_LIST,
  SET_ACTIVE_LIVE_VISITOR_LIST,
  SET_RAW_VISITOR_LIST,
  SET_RAW_VISITOR_REFERENCE_LIST,
  SET_MERCHANT_ATTR,
  SET_MERCHANT_INTEGRATION_LIST,
  SET_CURRENT_INTEGRATION_DETAIL,
  SET_INTEGRATION_API_DETAIL,
  SET_TASK_TYPE,
  SET_ROLE_LIST,
  SET_AGENT_METRICS,
  SET_MERCHANT_PROFILE,
  SET_LIVE_AGENTS,
  SET_RAW_VISITOR_PAGINATION_LIST,
  SET_RAW_VISITOR_EXPORT_CSV,
  SET_AGENT_TEAM_LIST,
  SET_ROLE_PERMISSION_LIST,
  SET_CHAT_LIST,
  REMOVE_CHAT_LIST,
  SET_CHAT_MESSAGE_LIST,
  SET_BOT_ELEMENTS,
  SET_ACTIVE_AGENT_LIST,
  SET_FETCHING_MERCHANT_INTEGRATION_LIST,
} from "../actions/actionTypes";
import { ELEMENT_LIST } from "../utils/BotUtils";
import { roleStaticList } from "../utils/GeneralUtils";

const initialState = {
  tabIndex: null,
  responseMessages: {
    list: [],
    fetching: false,
  },
  messages: {
    fetching: false,
    list: [],
    pendingList: [],
    pendingStatusList: [],
    repliedList: [],
    deletedList: [],
    junkList: [],
    draftList: [],
    needAgentList: [],
  },
  popup: {},
  activeVisitors: {
    list: [],
    fetching: false,
  },
  activeLiveVisitors: {
    list: [],
    fetching: false,
  },
  rawVisitors: {
    list: [],
    fetching: false,
  },
  rawVisitorsReference: {
    list: [],
    fetching: false,
  },
  rawVisitorsPagination: {
    list: [],
    fetching: false,
    totalVisitorPages: 0,
    totalRecords: 0,
  },
  rawVisitorsExportCSV: {
    list: [],
    fetching: false,
  },
  merchantAttr: {
    data: null,
    fetching: false,
  },
  taskTypeList: {
    list: [],
    fetching: false,
  },
  agentMetrics: {
    totalList: [],
    list: [],
    fetching: false,
  },
  liveAgents: {
    list: [],
    fetching: false,
  },
  agentTeam: {
    data: null,
    fetching: false,
  },
  activeAgent: {
    data: null,
    fetching: false,
  },
  merchantProfile: {
    data: null,
    fetching: false,
  },
  rolePermission: {
    menu: [],
    routes: [],
    fetching: true,
  },
  roleList: [...roleStaticList],
  botElements: [...ELEMENT_LIST],
  settings: {
    enableNotification: true,
  },
  chatList: [],
  chatMessageList: [],
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case SET_TAB_INDEX:
      const { tabIndex, tabData } = action.payload;
      return {
        ...state,
        tabIndex,
        tabData,
      };
    case SET_RESPONSE_REVIEW_LIST:
      const { responseMessages } = action.payload;
      return {
        ...state,
        responseMessages: { ...state.responseMessages, ...responseMessages },
      };
    case SET_MESSAGE_LIST:
      const { messages } = action.payload;
      return {
        ...state,
        messages: { ...state.messages, ...messages },
      };
    case MODAL_POPUP:
      let { popup } = action.payload;
      return {
        ...state,
        popup: { ...state.popup, ...popup },
      };
    case SET_ACTIVE_VISITOR_LIST:
      const { activeVisitors } = action.payload;
      return {
        ...state,
        activeVisitors,
      };
    case SET_ACTIVE_LIVE_VISITOR_LIST:
      const { activeLiveVisitors } = action.payload;
      return {
        ...state,
        activeLiveVisitors,
      };
    case SET_AGENT_TEAM_LIST:
      const { agentTeam } = action.payload;
      return {
        ...state,
        agentTeam,
      };
    case SET_ACTIVE_AGENT_LIST:
      const { activeAgent } = action.payload;
      return {
        ...state,
        activeAgent,
      };
    case SET_ROLE_PERMISSION_LIST:
      const { rolePermission } = action.payload;
      return {
        ...state,
        rolePermission,
      };
    case SET_RAW_VISITOR_LIST:
      const { rawVisitors } = action.payload;
      return {
        ...state,
        rawVisitors: { ...state.rawVisitors, ...rawVisitors },
      };
    case SET_RAW_VISITOR_REFERENCE_LIST:
      const { rawVisitorsReference } = action.payload;
      return {
        ...state,
        rawVisitorsReference: { ...state.rawVisitorsReference, ...rawVisitorsReference },
      };
    case SET_RAW_VISITOR_PAGINATION_LIST:
      const { rawVisitorsPagination } = action.payload;
      return {
        ...state,
        rawVisitorsPagination: { ...state.rawVisitorsPagination, ...rawVisitorsPagination },
      };
    case SET_RAW_VISITOR_EXPORT_CSV:
      const { rawVisitorsExportCSV } = action.payload;
      return {
        ...state,
        rawVisitorsExportCSV: { ...state.rawVisitorsExportCSV, ...rawVisitorsExportCSV },
      };
    case SET_MERCHANT_ATTR:
      const { merchantAttr } = action.payload;
      return {
        ...state,
        merchantAttr: { ...state.merchantAttr, ...merchantAttr },
      };
    case SET_MERCHANT_INTEGRATION_LIST: {
      const { merchantIntegrationList } = action.payload;
      return {
        ...state,
        merchantIntegrationList,
      };
    }
    case SET_FETCHING_MERCHANT_INTEGRATION_LIST: {
      const { isFetchingMerchantIntegrationList } = action.payload;
      return {
        ...state,
        isFetchingMerchantIntegrationList,
      };
    }
    case SET_CURRENT_INTEGRATION_DETAIL: {
      const { appStoreIntegrationDetail } = action.payload;
      return {
        ...state,
        appStoreIntegrationDetail,
      };
    }
    case SET_INTEGRATION_API_DETAIL: {
      const { appStoreIntegrationApiDetail } = action.payload;
      return {
        ...state,
        appStoreIntegrationApiDetail,
      };
    }
    case SET_TASK_TYPE:
      const { taskTypeList } = action.payload;
      return {
        ...state,
        taskTypeList: { ...state.taskTypeList, ...taskTypeList },
      };
    case SET_ROLE_LIST:
      const { roleList } = action.payload;
      return {
        ...state,
        roleList: [...roleList],
      };
    case SET_BOT_ELEMENTS:
      const { botElements } = action.payload;
      return {
        ...state,
        botElements: [...ELEMENT_LIST, ...botElements],
      };
    case SET_MERCHANT_PROFILE:
      const { merchantProfile } = action.payload;
      return {
        ...state,
        merchantProfile: { ...state.merchantProfile, ...merchantProfile },
      };
    case SET_AGENT_METRICS:
      const { agentMetrics } = action.payload;
      return {
        ...state,
        agentMetrics: { ...state.agentMetrics, ...agentMetrics },
      };
    case SET_LIVE_AGENTS:
      const { liveAgents } = action.payload;
      return {
        ...state,
        liveAgents: { ...state.liveAgents, ...liveAgents },
      };
    case SET_CHAT_LIST:
      const { chat } = action.payload;
      const chatList = [...state.chatList];
      const chatIndex = state.chatList.findIndex(x => x.sessionId === chat.sessionId);
      if (chatIndex === -1) chatList.push(chat);
      else chatList[chatIndex] = chat;
      return {
        ...state,
        chatList,
      };
    case REMOVE_CHAT_LIST:
      const { sessionList } = action.payload;

      const newChatList = [];
      [...state.chatList].map(x => {
        if (sessionList.includes(x.sessionId)) {
          x.subscription.unsubscribe();
          // x.socketClient.disconnect();
        } else {
          newChatList.push(x);
        }
      });

      return {
        ...state,
        chatList: newChatList,
      };
    case SET_CHAT_MESSAGE_LIST:
      const { messageList } = action.payload;
      return {
        ...state,
        chatMessageList: messageList,
      };
    default:
      return state;
  }
}
