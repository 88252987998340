import React from "react";
import { connect } from "react-redux";
import { Select, Input, ScrollBox } from "../../../common/components";
import { Label, Input as InputStrap } from "reactstrap";
import CommonUtils from "../../../utils/CommonUtils";
// import { getStatus } from '../../../utils/GeneralUtils';
import { SelectElement, ElementForm, SubmitElement } from "./components";
import { getRandomNumber } from "../../../utils/GeneralUtils";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";
import { getBotIntent } from "../../../utils/BotUtils";

class LiveAgent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // agentMsg: '',
      // departmentOpt: [],
      // deptId: null,
      // deptName: '',
      onOfflineIntent: null,
      userAgent: "ANY||",
      alertMessage: true,
      waitForAgent: true,
      alertMessageText: "",
      userAgentList: [],
      onOfflineIntentId: getRandomNumber(6),
      nextIntent: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // this.getAllDepartment();
    this.getAllUsers();
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        userAgent,
        nextIntent,
        alertMessage,
        waitForAgent,
        onOfflineIntent,
        alertMessageText = "",
        // agentMsg,  deptId, deptName,
      } = box.payload;
      this.setState({
        userAgent,
        nextIntent,
        alertMessage,
        onOfflineIntent,
        alertMessageText,
        waitForAgent: waitForAgent ? waitForAgent : false,
        // agentMsg, userAgent, deptId, deptName
      });
    }
  }

  getAllUsers() {
    CommonUtils.getAllUsers().then(response => {
      if (response.error) {
        this.setState({ userAgentList: [] });
        return false;
      }
      const list = response.map(l => ({
        label: `${l.firstName} ${l.lastName} (${(this.props.roleList.find(x => x.value === l.role) || { label: l.role }).label})`,
        value: `${l.id}|${l.firstName || ""}|${l.lastName || ""}`,
      }));
      this.setState({ userAgentList: [{ label: "Any", value: "ANY||" }, ...list] });
    });
  }

  // getAllDepartment() {
  // 	CommonUtils.getMerchantDepartment().then((response) => {
  // 		if (response.error || (response.code && response.code !== "000")) {
  // 			this.setState({ departmentOpt: [] });
  // 			return false;
  // 		}
  // 		if (response.departments && response.departments.length > 0) {
  // 			let arr = (response.departments || []).filter(x => x.status === getStatus());
  // 			let departmentOpt = [];
  // 			arr.map((x, i) => {
  // 				departmentOpt.push({ label: x.deptName, value: x.deptId });
  // 			})
  // 			this.setState({ departmentOpt });
  // 		}
  // 	});
  // }

  handleSubmit() {
    const {
      userAgent,
      prevElement,
      nextIntent,
      alertMessage,
      waitForAgent,
      onOfflineIntent,
      onOfflineIntentId,
      alertMessageText,
      // agentMsg, deptId, deptName
    } = this.state;
    const { handleSubmitElement, botVersion, boxes } = this.props;
    const userAgentData = userAgent ? userAgent.split("|") : ["", "", ""];
    const elOfflineIntent = boxes.find(x => x.id === onOfflineIntent);
    const data = {
      input_type: "liveAgent",
      next_intent: nextIntent,
      request_params: [
        {
          onOfflineIntent: getBotIntent(elOfflineIntent, botVersion),
          agentId: userAgentData[0],
          firstName: userAgentData[1],
          lastName: userAgentData[2],
          alertMessage,
          waitForAgent,
          alertMessageText: userAgent === "ANY||" ? alertMessageText : "",
          sessionId: BOT_ATTRIBUTES.SESSION_ID,
          merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
        },
      ],
      // agentMessage: agentMsg,
      // deptName
    };
    const payload = {
      userAgent,
      nextIntent,
      agentId: userAgentData[0],
      firstName: userAgentData[1],
      lastName: userAgentData[2],
      alertMessage,
      waitForAgent,
      alertMessageText: userAgent === "ANY||" ? alertMessageText : "",
      onOfflineIntentId,
      onOfflineIntent,
      qr: [],
      // agentMsg, deptId, deptName
    };
    if (data.request_params[0].onOfflineIntent) {
      payload.qr = [
        {
          id: onOfflineIntentId,
          text: "On Offline",
          connection: onOfflineIntent,
        },
      ];
    }
    handleSubmitElement(data, prevElement, payload);
  }

  render() {
    const { resetElement, boxes, botElementId, connectors } = this.props;
    const {
      userAgent,
      userAgentList,
      prevElement,
      alertMessage,
      waitForAgent,
      onOfflineIntent,
      alertMessageText,
      // agentMsg, deptId, departmentOpt
    } = this.state;
    return (
      <React.Fragment>
        <ScrollBox scrollClass="bt-liveagent" boxHeight={400}>
          <div className="ele-content">
            <div className="b-form">
              {/* <ElementForm title="Agent Message">
							<Input
								name="agentMsg"
								placeholder="Enter Agent Message"
								outerClassName="mb0"
								value={agentMsg}
								onChangeFunc={(name, value) => this.setState({ [name]: value })}
							/>
						</ElementForm>
						<ElementForm title="Department">
							<Select
								name="deptId"
								outerClassName="mb0"
								value={deptId}
								options={departmentOpt}
								onChangeFunc={(name, value, error, obj) => this.setState({ [name]: value, deptName: obj.label })}
							/>
						</ElementForm> */}
              <ElementForm title="Agent">
                <Select
                  name="userAgent"
                  outerClassName="mb0"
                  value={userAgent}
                  options={userAgentList}
                  isClearable={false}
                  onChangeFunc={(name, value) =>
                    this.setState({ [name]: value, alertMessage: value === "ANY||" ? true : alertMessage })
                  }
                />
              </ElementForm>
              <ElementForm title="Wait for Agent" rowClassName="mb20" flClassName="mt0">
                <Label className="checkbox-style">
                  <InputStrap
                    type="checkbox"
                    name="waitForAgent"
                    checked={waitForAgent}
                    onChange={e => this.setState({ waitForAgent: e.target.checked })}
                  />
                  <span className="checkmark"></span>
                </Label>
              </ElementForm>
              <ElementForm title="Alert Message" rowClassName="mb20" flClassName="mt0">
                <Label className="checkbox-style" style={{ opacity: userAgent === "ANY||" ? 0.7 : 1 }}>
                  <InputStrap
                    type="checkbox"
                    id="audiochat"
                    name="alertMessage"
                    checked={alertMessage}
                    onChange={e => this.setState({ alertMessage: e.target.checked })}
                    disabled={userAgent === "ANY||"}
                  />
                  <span className="checkmark"></span>
                </Label>
              </ElementForm>
              {userAgent === "ANY||" && (
                <ElementForm title="Alert Agent Message">
                  <Input
                    name="alertMessageText"
                    placeholder="Enter Alert Agent Message"
                    outerClassName="mb0"
                    value={alertMessageText}
                    maxLength={20}
                    onChangeFunc={(name, value) => this.setState({ [name]: value })}
                    className="form-control pr35"
                    appendIcon={
                      <span className="fs13 position-absolute text-gray-500" style={{ top: 7, right: 8 }}>
                        {alertMessageText.length}/20
                      </span>
                    }
                  />
                </ElementForm>
              )}
              <ElementForm prevElement>
                <SelectElement
                  value={prevElement}
                  boxes={boxes}
                  botElementId={botElementId}
                  connectors={connectors}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              </ElementForm>
              <ElementForm title="On Agent Offline">
                <SelectElement
                  outerClassName="d-inline-block mb0"
                  name="onOfflineIntent"
                  outerWidth={300}
                  value={onOfflineIntent}
                  boxes={boxes}
                  botElementId={botElementId}
                  connectors={connectors}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              </ElementForm>
            </div>
          </div>
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          totalSteps={1}
          botElementId={botElementId}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    roleList: state.common.roleList,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(LiveAgent);
