import { menuType } from "./index";

export const menuAccess = {
  [menuType.dashboard]: true,
  [menuType.visitor]: true,
  [`${menuType.visitor}-tabs`]: {
    [menuType.visitorTabInfo]: true,
    [menuType.visitorTabOnlineVisitor]: true,
    [menuType.visitorTabAiAgent]: true,
    [menuType.visitorTabTracking]: true,
    [menuType.visitorTabUserAgent]: true,
    [menuType.visitorTabMessageCenter]: true,
    [menuType.visitorTabVisitHistory]: true,
  },
  [menuType.engagement]: true,
  [`${menuType.engagement}-tabs`]: {
    [menuType.engagementTabInfo]: true,
    [menuType.engagementTabMyEngagement]: true,
    [menuType.engagementTabRouting]: true,
    [menuType.engagementTabSegmentation]: true,
    [menuType.engagementTabDataExtraction]: true,
    [menuType.engagementTabScoring]: true,
  },
  [menuType.automation]: true,
  [`${menuType.automation}-tabs`]: {
    [menuType.automationTabRules]: true,
    [menuType.automationTabFlowbots]: true,
  },
  [menuType.livechat]: true,
  [menuType.studio]: true,
  [`${menuType.studio}-tabs`]: {
    [menuType.studioTabStudio]: true,
    [menuType.studioTabVirtualAgent]: true,
    [menuType.studioTabChatBotTemplate]: true,
    [menuType.studioTabEmails]: true,
    [menuType.studioTabLandingPage]: true,
    [menuType.studioTabForm]: true,
    [menuType.studioTabPromotion]: true,
    [menuType.studioTabBotBuilder]: true,
  },
  [menuType.knowledgeBase]: true,
  [`${menuType.knowledgeBase}-tabs`]: {
    [menuType.knowledgeBaseTabTraning]: true,
    [menuType.knowledgeBaseTabKnwBase]: true,
    [menuType.knowledgeBaseTabDocument]: true,
    [menuType.knowledgeBaseTabIntent]: true,
    [menuType.knowledgeBaseSkillMgmt]: true,
    [menuType.knowledgeBaseTabEntity]: true,
    [menuType.knowledgeBaseTabFAQ]: true,
  },
  [menuType.contact]: true,
  [`${menuType.contact}-tabs`]: {
    [menuType.contactTabAllContacts]: true,
    [menuType.contactTabAllList]: true,
    [menuType.contactTabImportContacts]: true,
  },
  [menuType.lead]: true,
  [`${menuType.lead}-tabs`]: {
    [menuType.leadTabLead]: true,
    [menuType.leadTabProspect]: true,
    [menuType.leadTabMarket]: true,
    [menuType.leadTabSale]: true,
    [menuType.leadTabLostOpportunite]: true,
    [menuType.leadTabCustomer]: true,
    [menuType.leadTabNotQualified]: true,
  },
  [menuType.analytics]: true,
  [`${menuType.analytics}-tabs`]: {
    [menuType.analyticsTabChannel]: true,
    [menuType.analyticsTabEngagement]: true,
  },
  [menuType.product]: true,
  [`${menuType.product}-tabs`]: {
    [menuType.productTabCatalog]: true,
    [menuType.productTabPromotions]: true,
    [menuType.productTabDetails]: true,
    [menuType.productTabBulkupload]: true,
  },
  [menuType.fulfilment]: true,
  [`${menuType.fulfilment}-tabs`]: {
    [menuType.fulfilmentTabOrder]: true,
    [menuType.fulfilmentTabOrderDetails]: true,
    [menuType.fulfilmentTabPayment]: true,
    [menuType.fulfilmentTabCalendar]: true,
    [menuType.fulfilmentTabCustomer]: true,
    [menuType.fulfilmentTabAppointment]: true,
  },
  [menuType.report]: true,
  [`${menuType.report}-tabs`]: {
    [menuType.reportTabLeadReport]: true,
    [menuType.reportTabActivityReport]: true,
    [menuType.reportTabChannelReport]: true,
    [menuType.reportTabProductReport]: true,
    [menuType.reportTabScheduleReport]: true,
  },
  [menuType.setting]: true,
  [`${menuType.setting}-tabs`]: {
    [menuType.settingTabInfo]: true,
    [menuType.settingTabAccountInfo]: true,
    [menuType.settingTabBranding]: true,
    [menuType.settingTabCalendar]: true,
    [menuType.settingTabDepartment]: true,
    [menuType.settingTabRoleTeamMember]: true,
    [menuType.settingTabAccessManagement]: true,
    [menuType.settingTabPaymentTax]: true,
    [menuType.settingTabBotSetting]: true,
    [menuType.settingTabAiConfig]: true,
    [menuType.settingTabCustomConfig]: true,
    [menuType.settingTabChannel]: true,
    [menuType.settingTabIntegration]: true,
    [menuType.settingTabUnsubscribe]: true,
    [menuType.settingTabSecurity]: true,
    [menuType.settingTabTags]: true,
    [menuType.settingTabBilling]: true,
  },
  [menuType.messageCenter]: true,
  // [menuType.messageCenterUnansweredMessages]: true,
  // [menuType.messageCenterReviewResponse]: true,
  // [menuType.messageCenterBroadcastMessage]: true,
};
