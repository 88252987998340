import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Button, Input } from "../../../../common/components";
import SelectAttribute from "./SelectAttribute";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../../../../common/components/helper";
import { addErrorObj, isArray } from "../../../../utils/GeneralUtils";

const ActionSetUserData = forwardRef((props, ref) => {
  const { attributeList, prevElementStructure, lang } = props;
  const [attributes, setAttributes] = useState([{ key: "", value: "", errors: { key: null, value: null } }]);

  useEffect(() => {
    if (isArray(props.value)) {
      setAttributes(props.value.length ? addErrorObj(props.value, { key: null, value: null }) : props.value);
    } else {
      setAttributes([{ key: "", value: "", errors: { key: null, value: null } }]);
    }
  }, [props.value]);

  const addAttr = () => {
    setAttributes(prevAttr => {
      const newAttr = cloneDeep(prevAttr);
      newAttr.push({ key: "", value: "", errors: { key: null, value: null } });
      return newAttr;
    });
  };

  const removeAttr = index => {
    setAttributes(prevAttr => {
      const newAttr = cloneDeep(prevAttr);
      newAttr.splice(index, 1);
      return newAttr;
    });
  };

  const onChangeAttr = (name, value, index) => {
    setAttributes(prevAttr => {
      const newAttr = cloneDeep(prevAttr);
      newAttr[index][name] = value;
      return newAttr;
    });
  };

  const onListValidate = (name, error, index) => {
    setAttributes(prevAttr => {
      const newAttr = cloneDeep(prevAttr);
      newAttr[index]["errors"][name] = error;
      return newAttr;
    });
  };

  const getData = () => {
    let isError = false;
    attributes
      .filter(a => a.key || a.value)
      .map((a, i) => {
        const list = getFormDetails(a, (n, e) => onListValidate(n, e, i));
        if (!list) {
          isError = true;
          return false;
        }
      });
    if (isError) {
      return false;
    }
    if (!attributes.filter(a => a.key || a.value).length) {
      return { skip: true };
    }

    const data = attributes.filter(a => a.key || a.value);
    return {
      data,
      attrStructure: data.map(x => ({ label: `{{ATTR.${x.key}}}`, value: `{{ATTR.${x.key}}}` })),
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  return (
    <div className={`ele-content ${lang.className}`}>
      <label className="name">Add Attributes</label>
      {attributes.map((obj, index) => {
        return (
          <div key={index} style={{ marginTop: index == 0 ? 5 : 10 }}>
            <div className="input-box">
              <Row>
                <Col md={5}>
                  <Input
                    type="text"
                    placeholder="Enter Key"
                    title="Key"
                    name="key"
                    isReq={true}
                    error={obj.errors.key}
                    value={obj.key}
                    onChangeFunc={(name, value) => onChangeAttr(name, value, index)}
                    validationFunc={(n, e) => onListValidate(n, e, index)}
                  />
                </Col>
                <Col md={5}>
                  <Input
                    type="text"
                    placeholder="Enter Value"
                    title="Value"
                    name="value"
                    isReq={true}
                    error={obj.errors.value}
                    value={obj.value}
                    appendIcon={
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={prevElementStructure}
                        onChangeFunc={(value, inputName) => {
                          onChangeAttr(inputName, value, index);
                          onListValidate(inputName, null, index);
                        }}
                        setAttribute={[null, obj.value]}
                      />
                    }
                    onChangeFunc={(name, value) => onChangeAttr(name, value, index)}
                    validationFunc={(n, e) => onListValidate(n, e, index)}
                  />
                </Col>
                <Col md={2}>
                  <div className="btn-box">
                    {index === attributes.length - 1 ? (
                      <Button plusBtn className="d-inline mr10" onClickFunc={addAttr} />
                    ) : null}
                    {attributes.filter(x => !x.isDefault).length > 1 ? (
                      <Button minusBtn className="d-inline" onClickFunc={() => removeAttr(index)} />
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      })}
    </div>
  );
});

ActionSetUserData.defaultProps = {
  lang: {
    className: "",
  },
};

ActionSetUserData.propTypes = {
  lang: PropTypes.object,
};

export default ActionSetUserData;
