/* production api */
export const API_PRODUCTION = process.env.API_ENDPOINT;

/* production jello api */
export const JELLO_API_PRODUCTION = process.env.JELLO_API_PRODUCTION || process.env.API_ENDPOINT;

/* orchestrator api */
export const API_ORCHESTRATOR = `${process.env.API_ENDPOINT}orchestrator`;

/* ecloud-bl api */
export const API_ECLOUDBL = process.env.API_ECLOUDBL || `${process.env.API_ENDPOINT}ecloudbl`;

/* messenger url */
export const MESSENGER_URL = process.env.MESSENGER_URL;

/* navigator url */
export const NAVIGATOR_URL = process.env.NAVIGATOR_URL;

/* enable socket logs */
export const ENABLE_SOCKET_LOG = process.env.ENABLE_SOCKET_LOG || "0";

/* Authorization for pg0auth */
export const PG_AUTH = process.env.PG_AUTH || "Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0";

/* Authorization for open ai */
export const OPEN_AI_AUTH = process.env.OPEN_AI_AUTH || "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";

/* Ag Grid License Key */
export const AG_GRID_LICENSE_KEY =
  process.env.AG_GRID_LICENSE_KEY ||
  "CompanyName=Purple Grids Inc,LicensedApplication=Engagement Cloud,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-019477,ExpiryDate=27_September_2022_[v2]_MTY2NDIzMzIwMDAwMA==8ffecfa34cd97833a8881d7180180de2";

/* all origin url for screen share */
export const ALL_ORIGIN_API = url => `https://api.allorigins.win/get?url=${url}&callback=?`;

/* Analytics links */
export const ANALYTICS_LINKS = {
  VISITOR:
    process.env.ANALYTICS_VISITOR ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/ed55ba3f-94ea-47b9-ae65-6f81d7aabfa0/Visit%20History/Visitor%20Analytics??showmydashboards=0&merchantId=${merchantId}",
  CONVERSATION:
    process.env.ANALYTICS_CONVERSATION ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/faaf9d54-8040-4d08-9986-45612a2544c1/Visit%20History/Conversational%20Analytics??showmydashboards=0&merchantId=${merchantId}",
  CAMPAIGNS:
    process.env.ANALYTICS_CAMPAIGNS ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/f306401b-e1cc-4eb2-bcb9-2f03f8832eca/visit%20history/campaign%20analytics?showmydashboards=0&merchantId=${merchantId}",
  USER_ACTIVITY:
    "https://dev1purplegrids.boldbi.com/bi/dashboards/738f8017-d742-46cc-a40e-c8b2b797e5fa/visit%20history/user%20activity%20analytics?showmydashboards=1",
  ENGAGEMENT:
    process.env.ANALYTICS_ENGAGEMENT ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/a3648446-bb8f-4296-8316-4a286ebec31f/Visit%20History/Channel%20Analytics?showmydashboards=0&merchantId=${merchantId}",
  PRODUCTS:
    process.env.ANALYTICS_PRODUCTS ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/95092e4d-a65c-4579-ab53-57f3de4f5c55/Visit%20History/Product%20Analytics%20-%20Prod?showmydashboards=0&merchantId=${merchantId}",
  ORDERS:
    process.env.ANALYTICS_ORDERS ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/10108d1d-92ce-4532-9b34-0885dc7e3b77/visit%20history/order%20analytics?showmydashboards=0&merchantid=${merchantId}",
  CUSTOMER_PERFORMANCE:
    process.env.ANALYTICS_CUSTOMER__SERVICE_PERFORMANCE ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/049c74b1-fcbb-4bdf-bb32-d7a4b1308368/visit%20history/customer%20service%20performance?showmydashboards=0&merchantid=${merchantId}",
  CUSTOMER_PERFORMANCE_CC:
    process.env.ANALYTICS_CUSTOMER__SERVICE_PERFORMANCE_CC ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/049c74b1-fcbb-4bdf-bb32-d7a4b1308368/visit%20history/customer%20service%20performance?showmydashboards=0&merchantid=${merchantId}&agentName=${agentName}",
  CUSTOMER_ACTIVITY:
    process.env.ANALYTICS_CUSTOMER__SERVICE_ACTIVITY ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/e82871ff-0523-4490-b14f-0f4d660b40ce/Visit%20History/Team%20Activity?showmydashboards=0&merchantid=${merchantId}",
  CUSTOMER_ACTIVITY_CC:
    process.env.ANALYTICS_CUSTOMER__SERVICE_ACTIVITY_CC ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/f1fa4c5f-e005-4ec2-93c4-8931dba63af1/Visit%20History/Agent%20Activity?showmydashboards=0&merchantid=${merchantId}&agentName=${agentName}",
  CUSTOMER_TEAM_ACTIVITY:
    process.env.ANALYTICS_CUSTOMER_TEAM_ACTIVITY ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/f2fe29ad-aec0-44dc-924d-46e30abe582f/visit%20history/team%20analytics?showmydashboards=0&merchantid=${merchantId}",
  EXECUTIVE_SUMMARY:
    process.env.ANALYTICS_EXECUTIVE_SUMMARY ||
    "https://dev1purplegrids.boldbi.com/bi/dashboards/7f4221c5-23e0-41e4-ae24-486d5486fb9d/Activity%20Analytics/Executive%20Dashboard?&showmydashboards=0&merchantid=${merchantId}",
};

/* Cloudinary details for image upload */
export const CLOUDINARY_DATA = {
  CLOUD_NAME: process.env.CLOUDINARY_DATA_CLOUD_NAME || "dddd9bezd",
  UPLOAD_PRODUCT_PRESET: process.env.CLOUDINARY_DATA_UPLOAD_PRODUCT_PRESET || "ndignso9",
  UPLOAD_AIAGENTS_PRESET: process.env.CLOUDINARY_DATA_UPLOAD_AIAGENTS_PRESET || "vfno2esf",
  UPLOAD_BANNER_PRESET: process.env.CLOUDINARY_DATA_UPLOAD_BANNER_PRESET || "pfkg0wf8",
};

export const BEE_DATA = {
  EMAIL_CLIENT_ID: process.env.BEE_EMAIL_CLIENT_ID || "34a89728-3055-4fe1-b10b-b51fc193f51a",
  EMAIL_CLIENT_SECRET: process.env.BEE_EMAIL_CLIENT_SECRET || "JzGnu40jGLr4bwgFixQJJIG6fOx936e8al84OfWettP5uODTApxm",
  PAGE_CLIENT_ID: process.env.BEE_PAGE_CLIENT_ID || "2ce26a67-85de-4e78-8f2f-6a2d85f2b8c5",
  PAGE_CLIENT_SECRET: process.env.BEE_PAGE_CLIENT_SECRET || "nSpV5cpE0atyVwWCcYjLKXQjTGHcWTwNthAkSNU2RlUid63q7yqk",
};

import { menuType } from "../menu-config/index";
import { menuAccess as devMenuAccess } from "../menu-config/dev";
import { menuAccess as prodMenuAccess } from "../menu-config/prod";
import { menuAccess as stagingMenuAccess } from "../menu-config/staging";

let menuAccess = devMenuAccess;
if (process.env.MENU_ACCESS_TYPE === "prod") {
  menuAccess = prodMenuAccess;
} else if (process.env.MENU_ACCESS_TYPE === "staging") {
  menuAccess = stagingMenuAccess;
}

export const MENU_ACCESS = menuAccess;
export const MENU_TYPE = menuType;

export const CENTRAL_CHIMES_WEB_API =
  process.env.CENTRAL_CHIMES_WEB_API || "https://central-api.purplecloud.ai/chimeswebapi";

export const CLOUDINARY_ENDPOINT = "https://res.cloudinary.com/purplegrids";

export const BRAND_NAME = process.env.BRAND_NAME || "PurpleCloud";
export const BRAND_DOMAIN = process.env.BRAND_DOMAIN || "PurpleCloud.ai";
export const BRAND_DOMAIN_LINK = `https://${BRAND_DOMAIN.toLowerCase()}`;
export const BRAND_COMPANY_NAME = process.env.BRAND_COMPANY_NAME || "Purplegrids Inc.";
export const BRAND_LOGO_URL = process.env.BRAND_LOGO_URL || "/img/PurpleCloudLogo.png";

export const GCS_BUCKET = {
  MERCHANT: process.env.GCS_BUCKET_MERCHANT || "pgmerchants",
  AUDIO: process.env.GCS_BUCKET_AUDIO || "audiocall",
};

export const CLOGIN_DOMAIN = process.env.CLOGIN_DOMAIN;
export const CLOGIN_MERCHANT = process.env.CLOGIN_MERCHANT;

export const AAD_CLIENT_ID = process.env.AAD_CLIENT_ID || "73fdbe9a-370c-490d-af4c-89955617b862"; // '024fbabc-8aff-404d-a720-1f8e4a09b5f3';
