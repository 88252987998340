import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import CommonUtils from "../../utils/CommonUtils.js";
import { Button } from "../../common/components";
import { getAllQueryParams, getCLoginMerchant, isValidDigit, logoutApp, maskEmail } from "../../utils/GeneralUtils";
import FooterContent from "../../components/FooterContent";
import OtpInput from "react-otp-input";
import { sessionCLogin, sessionEmail, sessionUserTemp } from "../../utils/SessionUtils";
import { createUserAgents, getMerchantDetails, redirectToAccount } from "../../utils/LoginUtil";
import { withRouter } from "../../withRouter.js";

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      isError: false,
      otpError: "",
      seconds: 0,
      timer: 60,
      apiVerify: false,
      resendVerify: false,
      mId: null,
    };
    this.unsubscribeUser = false;
    this.handleChange = this.handleChange.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.resendOtpRequest = this.resendOtpRequest.bind(this);
  }

  componentDidMount() {
    this.setState({ mId: sessionCLogin() });
    this.startTimer();
  }

  UNSAFE_componentWillMount() {
    let params = getAllQueryParams(this.props.location.search);
    if (params.redirect === "unsubscribe") {
      this.unsubscribeUser = true;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startTimer() {
    this.setState({
      seconds: this.state.timer,
    });
    this.interval = setInterval(this.updateTimer, 1000);
  }

  updateTimer() {
    if (this.state.seconds > 0) {
      this.setState(prevState => ({
        seconds: prevState.seconds - 1,
      }));
    } else {
      clearInterval(this.interval);
    }
  }

  handleChange(otp) {
    this.setState({ otp });
    const isOTP = isValidDigit(6, 6, otp);
    if (isOTP) {
      this.setState({ otpError: "", isError: false });
    }
  }

  verifyLogin(e) {
    e.preventDefault();
    const { otp } = this.state;
    const isOTP = isValidDigit(6, 6, otp);
    if (isOTP) {
      this.verifyOTP(otp);
    } else {
      this.setState({ otpError: "Please enter OTP", isError: true });
    }
  }

  async resendOtpRequest() {
    this.setState({ resendVerify: true });
    const response = await CommonUtils.sendOtpRequest();
    if (response.error) {
      this.setState({ apiVerify: false });
      return false;
    }
    this.startTimer();
    this.setState({ resendVerify: false });
    return response;
  }

  async verifyOTP(otp) {
    const merchantState = this.props.location.state;
    if (!merchantState || !merchantState.user) {
      return false;
    }
    this.setState({ apiVerify: true });
    let response = await CommonUtils.verifyOTP(otp);

    if (response.status === "success") {
      const merchantDetailResponse = await getMerchantDetails(merchantState.user, sessionUserTemp());
      if (!merchantDetailResponse) return false;
      await createUserAgents(merchantDetailResponse.merchantRes, merchantDetailResponse.merchantUsername);
      redirectToAccount(this.props);
    } else {
      if (response.error.toLowerCase() === "session expired") {
        this.props.navigate("/support", { state: { supportMsg: "Session Expired" } });
      } else if (response.error.toLowerCase() === "invalid token") {
        this.setState({ otpError: "The OTP you're entered is incorrect. Please try again", isError: false });
      } else {
        this.setState({ otpError: response.error, isError: false });
      }
      this.setState({ apiVerify: false });
    }
    return response;
  }

  render() {
    const { otp, otpError, isError, seconds, apiVerify, resendVerify, mId } = this.state;
    return (
      <div className="login-v1">
        <div className="content m-0">
          <div className="box c-box">
            <Card className="m-box border-0 m-0">
              <div className="mlogo">
                <img src={getCLoginMerchant(mId)} height="100%" />
              </div>
              <CardBody className={`text-gray-700 ${!mId ? "pb-0" : ""}`}>
                <form onSubmit={this.verifyLogin.bind(this)}>
                  <div className="vc-fw-600 fs21 text-center text-muted">We emailed you a code</div>
                  <p className="mb-4 text-center text-muted">Enter the verification code sent to:</p>
                  <p
                    className="mb-4 text-center text-muted"
                    style={{
                      width: "max-content",
                      margin: "0 auto",
                      background: "#f3f3f3",
                      padding: "6px 20px",
                      borderRadius: 6,
                    }}
                  >
                    {maskEmail(sessionEmail())}
                    <i
                      className="fa fa-pencil edit-user-icon d-none"
                      onClick={() => {
                        logoutApp(this.props.navigate);
                      }}
                    />
                  </p>
                  <div className={`otp-container ${isError ? "error" : ""}`}>
                    <OtpInput
                      value={otp}
                      onChange={this.handleChange}
                      numInputs={6}
                      inputType="tel"
                      inputStyle={{ width: "3rem", height: "3.5rem" }}
                      renderInput={props => <input {...props} className="otp-input" />}
                    />
                    {otpError && <div className="otp-error-message req-msg mt5">{otpError}</div>}
                  </div>
                  <Button
                    text="Submit"
                    submitBtn
                    type="submit"
                    className="px-4 w-100 mt-3"
                    style={{ height: 40 }}
                    size="normal"
                    displayIcon={false}
                    loading={apiVerify}
                  />
                  <div className="link-box mt-3 text-center vc-fw-600 fs13 text-muted">
                    Didn't received the code?
                    {seconds > 0 ? (
                      <span className="vc-fw-600 resend-text ml5">
                        Resend in {seconds < 10 ? `0${seconds}` : seconds}s
                      </span>
                    ) : (
                      <React.Fragment>
                        {resendVerify ? (
                          <span className="vc-fw-600 text-muted ml5">Resending OTP ...</span>
                        ) : (
                          <span className="btn-link vc-fw-600 ml5" onClick={() => this.resendOtpRequest()}>
                            Resend OTP
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  <div className="link-box mt-3 text-center fs13">
                    <Button
                      text="Can't access your account?"
                      linkBtn
                      className="px-0 vc-fw-600 fs13"
                      onClickFunc={() => this.props.navigate("/support")}
                    />
                  </div>
                  <div className="mt-1 mb-1 text-center">
                    <Button
                      text="Back to home"
                      linkBtn
                      className="px-1 vc-fw-600 fs13"
                      customIcon="fa-thin fa-arrow-left"
                      onClickFunc={() => {
                        logoutApp(this.props.navigate);
                      }}
                    />
                  </div>
                  {!mId && (
                    <div className="footer text-muted mt-5">
                      <FooterContent />
                    </div>
                  )}
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default withRouter(Verify);
