import React from "react";
import { BRAND_COMPANY_NAME, BRAND_DOMAIN, BRAND_DOMAIN_LINK } from "../utils/Config";

const FooterContent = () => {
  return (
    <React.Fragment>
      © 2017-{new Date().getFullYear()} All Rights Reserved.
      <br />
      <a href={BRAND_DOMAIN_LINK} target="_blank">
        {BRAND_DOMAIN}
      </a>
      ® is a registered trademark of {BRAND_COMPANY_NAME}
      <br />
      <span>Cookie Preferences, Privacy and Terms.</span>
    </React.Fragment>
  );
};

export default FooterContent;
