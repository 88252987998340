import React from "react";

const SkeletonLoginCard = () => {
  return (
    // class is "app-store" since skeleton falls under "app-store" in v1-design.scss
    <div className="app-store">
      <div className="skeleton-box d-flex justify-content-between flex-column app-bot m-0 shadow-none">
        <div className="d-flex flex-col">
          <div className="skeleton login-brand-img"></div>
          <div>
            <div className="d-flex flex-col align-items-center my-4">
              <span className="skeleton text mb-2"></span>
              <span className="skeleton sub-text"></span>
            </div>
            <div className="d-flex flex-col">
              <div className="d-flex flex-col mb-4">
                <span className="skeleton model-tag mb-2"></span>
                <span className="skeleton skeleton-input"></span>
              </div>
              <div className="d-flex flex-col">
                <span className="skeleton model-tag mb-2"></span>
                <span className="skeleton skeleton-input mb-2"></span>
              </div>
              <span className="skeleton model-tag align-self-end"></span>
            </div>
          </div>
          <div className="skeleton skeleton-button mt-4"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoginCard;
