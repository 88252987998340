import React, { Fragment, useState } from "react";
import { Button, Select } from "./../../common/components";
import classNames from "classnames";

const MultiTag = ({
  allOptions,
  selectedTags,
  getUpdatedTags,
  labelKey = "label",
  valueKey = "value",
  title,
  isDisabled,
}) => {
  const [tagToAdd, setTagToAdd] = useState("");

  const selectedTagsList = selectedTags ? allOptions.filter(t => selectedTags.split(",").includes(t[valueKey])) : [];

  const onAddTags = () => {
    if (!tagToAdd) {
      setTagToAdd("");
      return;
    }
    const updatedTags = selectedTags ? selectedTags.concat(",", tagToAdd) : tagToAdd;
    getUpdatedTags(updatedTags);
    setTagToAdd("");
  };
  const onDeleteTags = delTag => {
    const updatedTags = selectedTags
      ? selectedTags
          .split(",")
          .filter(t => t !== delTag)
          .join(",")
      : "";
    getUpdatedTags(updatedTags);
  };
  return (
    <Fragment>
      <div className="d-flex">
        <Select
          value={tagToAdd}
          isDisabled={isDisabled}
          title={title}
          name="tagToAdd"
          isClearable
          outerClassName="mb-1 flex-grow-1"
          isMulti={false}
          labelKey={labelKey}
          valueKey={valueKey}
          options={selectedTags ? allOptions.filter(t => !selectedTags.split(",").includes(t[valueKey])) : allOptions}
          onChangeFunc={(n, v) => setTagToAdd(v)}
        />
        <Button
          text="Add"
          isDisabled={isDisabled}
          className="cb-submit br4 btn-v1 ml10"
          style={{ height: "35px", width: "45px" }}
          onClickFunc={onAddTags}
        />
      </div>
      <div className="d-flex flex-wrap">
        {selectedTagsList.map(t => (
          <div
            key={t[labelKey]}
            className={classNames("multitag-tag align-items-center", { "cursor-not-allowed": isDisabled })}
          >
            <span className="text p-1">{t[labelKey]}</span>
            {!isDisabled && (
              <span className="close" onClick={() => onDeleteTags(t[valueKey])}>
                &times;
              </span>
            )}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default MultiTag;
