import { cloneDeep, keys, omit, pick, get, flatMap } from "lodash";
import {
  getCreatedBy,
  getRandomNumber,
  getStatus,
  getStructure,
  handleRequestAPI,
  isArray,
  mapEnvironmentV2,
  stripHTML,
} from "./GeneralUtils";
import CommonUtils from "./CommonUtils";
import copy from "copy-to-clipboard";
import { uniqBy } from "lodash";
import { sessionBusinessType, sessionMerchantId } from "./SessionUtils";

export const ELEMENT_TYPE = {
  START: "start",
  SEND_MESSAGE: "send-message",
  SEND_MESSAGE_WITH_OPT: "send-message-with-opt",
  MULTI_SELECT: "multi-select",
  SEND_IMAGE: "send-image",
  SEND_VIDEO: "send-video",
  SEND_CAROUSEL: "send-carousel",
  REQ_USER_DATA: "request-user-data",
  SEND_AUDIO: "send-audio",
  SET_USER_DATA: "set-user-data",
  SET_PAGE_DATA: "set-page-data",
  JSON_API: "json-api",
  SOAP_API: "soap-api",
  DECISION: "decision",
  IDENTITY: "identity",
  JAVA_SCRIPT: "java-Script",
  JSON_TRANSFORMATION: "json-transformation",
  WEBVIEW: "webview",
  UPDATE_VISITOR: "update-visitor",
  CUSTOMER_RATING: "customer-rating",
  TRIGGER_PATH: "trigger-path",
  FILE_UPLOAD: "file-upload",
  SEND_EMAIL: "send-email",
  SEND_SMS: "send-sms",
  TRIGGER_PATH: "trigger-path",
  PAYMENT: "payment",
  FORM: "form",
  LIVE_AGENT: "live-agent",
  CAMPAIGN: "campaign",
  PRODUCT: "product",
  TASK: "task",
  MEETING: "meeting",
  RPA: "rpa",
  ML_MODELS: "ml-models",
  DATA_SOURCE: "data-source",
  CUSTOM_COMPONENT: "custom-component",
  FORM_BUILDER: "form-builder",
  PROMPT: "prompt",
};

export const ELEMENT_LIST = [
  {
    id: ELEMENT_TYPE.START,
    name: "START",
    img: "",
    intent: "pg_welcome",
    templateName: "pg_welcome",
    type: "",
    isHide: true,
  },
  {
    id: ELEMENT_TYPE.SEND_MESSAGE,
    name: "Message",
    label: "Message",
    intent: "message",
    templateName: "message",
    type: "text",
    image: "/img/bot-elements/message.svg",
    category: 1,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.SEND_MESSAGE_WITH_OPT,
    name: "Message with Options",
    label: "Message with Options",
    intent: "quickReplyv2",
    templateName: "quickReplyv2",
    type: "qreplyv2",
    image: "/img/bot-elements/message-opt.svg",
    category: 1,
  },
  {
    id: ELEMENT_TYPE.MULTI_SELECT,
    name: "Multi Select Message",
    label: "Multi Select Message",
    intent: "multiSelect",
    templateName: "multiSelect",
    type: "multiSelect",
    image: "/img/bot-elements/multi-select.svg",
    category: 1,
  },
  {
    id: ELEMENT_TYPE.SEND_IMAGE,
    name: "Show Image",
    label: "Show Image",
    intent: "image",
    templateName: "image",
    type: "image",
    image: "/img/bot-elements/image.svg",
    category: 1,
  },
  {
    id: ELEMENT_TYPE.SEND_VIDEO,
    name: "Show Video",
    label: "Show Video",
    intent: "video",
    templateName: "video",
    type: "video",
    image: "/img/bot-elements/video.svg",
    category: 1,
  },
  {
    id: ELEMENT_TYPE.SEND_CAROUSEL,
    name: "Show Carousel",
    label: "Show Carousel",
    intent: "botMenu",
    templateName: "botMenu",
    type: "botMenu",
    image: "/img/bot-elements/carousel.svg",
    category: 1,
  },
  {
    id: ELEMENT_TYPE.REQ_USER_DATA,
    name: "Question",
    label: "Question",
    intent: "requestUserData",
    templateName: "requestUserData",
    type: "userInput",
    image: "/img/bot-elements/req-user-data.svg",
    category: 1,
  },
  {
    id: ELEMENT_TYPE.SEND_AUDIO,
    name: "Show Audio",
    label: "Show Audio",
    intent: "sendAudio",
    templateName: "sendAudio",
    type: "audio",
    image: "/img/bot-elements/audio.svg",
    category: 1,
  },
  {
    id: ELEMENT_TYPE.SET_USER_DATA,
    name: "Set User Data",
    label: "Set User Data",
    intent: "setUserData",
    templateName: "setUserData",
    type: "setUserData",
    image: "/img/bot-elements/user-data.svg",
    category: 1,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.SET_PAGE_DATA,
    name: "Set Page Data",
    label: "Set Page Data",
    intent: "setPageData",
    templateName: "setPageData",
    type: "setPageData",
    image: "/img/bot-elements/page-data.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.JSON_API,
    name: "REST API",
    label: "REST API",
    intent: "json",
    templateName: "json",
    type: "json",
    image: "/img/bot-elements/json-api.svg",
    category: 3,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.SOAP_API,
    name: "SOAP API",
    label: "SOAP API",
    intent: "soap",
    templateName: "soap",
    type: "soap",
    image: "/img/bot-elements/soap-api.svg",
    category: 3,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.DECISION,
    name: "Decision",
    label: "Decision",
    intent: "decision",
    templateName: "decision",
    type: "decision",
    image: "/img/bot-elements/decision.svg",
    category: 1,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.IDENTITY,
    name: "Identity",
    label: "Identity",
    intent: "auth",
    templateName: "auth",
    type: "auth",
    image: "/img/bot-elements/identity.svg",
    category: 2,
    height: 115,
  },
  {
    id: ELEMENT_TYPE.JAVA_SCRIPT,
    name: "JavaScript",
    label: "JavaScript",
    intent: "javaScript",
    templateName: "javaScript",
    type: "javaScript",
    image: "/img/bot-elements/javascript.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.JSON_TRANSFORMATION,
    name: "Transformation",
    label: "Transformation",
    intent: "jsonTransformation",
    templateName: "jsonTransformation",
    type: "jsonTransformation",
    image: "/img/bot-elements/json-transformation.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.WEBVIEW,
    name: "Webview",
    label: "Webview",
    intent: "webview",
    templateName: "webview",
    type: "webview",
    image: "/img/bot-elements/webview.svg",
    // isDisabled: true,
    category: 2,
  },
  {
    id: ELEMENT_TYPE.UPDATE_VISITOR,
    name: "Update Visitor",
    label: "Update Visitor",
    intent: "updateVisitor",
    templateName: "updateVisitor",
    type: "updateVisitor",
    image: "/img/bot-elements/update-visitor.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.CUSTOMER_RATING,
    name: "Customer Rating",
    label: "Customer Rating",
    intent: "customerRating",
    templateName: "customerRating",
    type: "customerRating",
    image: "/img/bot-elements/customer-rating.svg",
    category: 2,
    height: 115,
  },
  {
    id: ELEMENT_TYPE.FILE_UPLOAD,
    name: "File Upload",
    label: "File Upload",
    intent: "fileUpload",
    templateName: "fileUpload",
    type: "fileUpload",
    image: "/img/bot-elements/file-upload.svg",
    category: 2,
    height: 115,
  },
  {
    id: ELEMENT_TYPE.SEND_EMAIL,
    name: "Email",
    label: "Email",
    intent: "Email",
    templateName: "sendEmail",
    type: "email",
    image: "/img/bot-elements/send-email.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.SEND_SMS,
    name: "Send SMS",
    label: "Send SMS",
    intent: "sendSMS",
    templateName: "sendSMS",
    type: "sms",
    image: "/img/bot-elements/send-sms.svg",
    isDisabled: true,
    category: 2,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.TRIGGER_PATH,
    name: "Trigger Path",
    label: "Trigger Path",
    intent: "triggerPath",
    templateName: "triggerPath",
    type: "triggerPath",
    image: "/img/bot-elements/path.svg",
    // isDisabled: true,
    category: 1,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.PAYMENT,
    name: "Payment",
    label: "Payment",
    intent: "payment",
    templateName: "payment",
    type: "payment",
    // isDisabled: true,
    image: "/img/bot-elements/payment.svg",
    category: 3,
    height: 115,
  },
  {
    id: ELEMENT_TYPE.FORM,
    name: "Form",
    label: "Form",
    intent: "forms",
    templateName: "forms",
    type: "forms",
    // isDisabled: true,
    image: "/img/bot-elements/form.svg",
    category: 2,
  },
  {
    id: ELEMENT_TYPE.LIVE_AGENT,
    name: "Agent",
    label: "Agent",
    intent: "liveAgent",
    templateName: "liveAgent",
    type: "liveAgent",
    // isDisabled: true,
    image: "/img/bot-elements/live-agent.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.CAMPAIGN,
    name: "Campaign",
    label: "Campaign",
    intent: "campaign",
    templateName: "campaign",
    type: "campaign",
    isDisabled: true,
    image: "/img/bot-elements/campaign.svg",
    category: 2,
  },
  {
    id: ELEMENT_TYPE.PRODUCT,
    name: "Product",
    label: "Product",
    intent: "product",
    templateName: "product",
    type: "product",
    image: "/img/bot-elements/product.svg",
    category: 2,
  },
  {
    id: ELEMENT_TYPE.TASK,
    name: "Create Task",
    label: "Create Task",
    intent: "task",
    templateName: "task",
    type: "task",
    image: "/img/bot-elements/task.svg",
    // isDisabled: true,
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.MEETING,
    name: "Meeting",
    label: "Meeting",
    intent: "meeting",
    templateName: "meeting",
    type: "meeting",
    image: "/img/bot-elements/meeting.svg",
    category: 2,
  },
  {
    id: ELEMENT_TYPE.RPA,
    name: "RPA",
    label: "RPA",
    intent: "rpa",
    templateName: "rpa",
    type: "rpa",
    image: "/img/bot-elements/rpa.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.ML_MODELS,
    name: "ML Models",
    label: "ML Models",
    intent: "mlModels",
    templateName: "mlModels",
    type: "mlModels",
    image: "/img/bot-elements/ml-models.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.PROMPT,
    name: "Prompt",
    label: "Prompt",
    intent: "prompt",
    templateName: "prompt",
    type: "prompt",
    image: "/img/bot-elements/prompt.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.DATA_SOURCE,
    name: "Data Source",
    label: "Data Source",
    intent: "dataSource",
    templateName: "dataSource",
    type: "dataSource",
    image: "/img/bot-elements/data-source.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.CUSTOM_COMPONENT,
    name: "Custom Component",
    label: "Custom Component",
    intent: "customComponent",
    templateName: "customComponent",
    type: "customComponent",
    image: "/img/bot-elements/custom-component.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
  {
    id: ELEMENT_TYPE.FORM_BUILDER,
    name: "Form Builder",
    label: "Form Builder",
    intent: "formBuilder",
    templateName: "formBuilder",
    type: "formBuilder",
    image: "/img/bot-elements/form-builder.svg",
    category: 2,
    height: 115,
    isFlow: true,
  },
];

export const BUSINESS_ELEMENT_LIST = [
  ELEMENT_TYPE.START,
  ELEMENT_TYPE.SEND_MESSAGE,
  ELEMENT_TYPE.SEND_MESSAGE_WITH_OPT,
  ELEMENT_TYPE.MULTI_SELECT,
  ELEMENT_TYPE.SEND_IMAGE,
  ELEMENT_TYPE.SEND_VIDEO,
  ELEMENT_TYPE.SEND_CAROUSEL,
  ELEMENT_TYPE.REQ_USER_DATA,
  ELEMENT_TYPE.SEND_AUDIO,
  ELEMENT_TYPE.PRODUCT,
  ELEMENT_TYPE.MEETING,
  ELEMENT_TYPE.FORM,
  ELEMENT_TYPE.FILE_UPLOAD,
  ELEMENT_TYPE.WEBVIEW,
  ELEMENT_TYPE.CUSTOM_COMPONENT,
  ELEMENT_TYPE.FORM_BUILDER,
];

const getIntersection = (dx, dy, cx, cy, w, h) => {
  if (Math.abs(dy / dx) < h / w) {
    // Hit vertical edge of box1
    return [cx + (dx > 0 ? w : -w), cy + (dy * w) / Math.abs(dx)];
  } else {
    // Hit horizontal edge of box1
    return [cx + (dx * h) / Math.abs(dy), cy + (dy > 0 ? h : -h)];
  }
};

const getCenterPoint = (point1, point2) => {
  var dx1 = point2.x - point1.x;
  var dy1 = point2.y - point1.y;
  var midX = point1.x + dx1 * 0.5;
  var midY = point1.y + dy1 * 0.5;
  return { x: midX, y: midY };
};

const drawArrow = (ctx, p1, p2, isSelected) => {
  const size = 5;
  const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
  const hyp = Math.sqrt((p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y));

  // ctx.globalCompositeOperation = 'destination-over';

  ctx.save();
  ctx.globalAlpha = 1;
  if (!isSelected) {
    ctx.setLineDash([1, 1]);
    ctx.globalAlpha = 0.3;
  }
  ctx.translate(p1.x, p1.y);
  ctx.rotate(angle);

  //point
  ctx.beginPath();
  ctx.fillStyle = isSelected ? "#20a8d8" : "#aaa";
  ctx.arc(0, 0, 4, 0, 2 * Math.PI);
  ctx.fill();

  // line
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.strokeStyle = isSelected ? "#20a8d8" : "#999";
  ctx.lineTo(hyp - size, 0);
  ctx.stroke();

  // triangle
  ctx.beginPath();
  ctx.fillStyle = isSelected ? "#20a8d8" : "#aaa";
  ctx.lineTo(hyp - size, size);
  ctx.lineTo(hyp, 0);
  ctx.lineTo(hyp - size, -size);
  ctx.fill();
  ctx.restore();
};

const getCoordinations = (connector, boxes) => {
  let coordinates = {},
    box1 = {},
    box2 = {};
  boxes.map(e => {
    if (e.id === connector.sp) {
      box1 = e.info;
    }
    if (e.id === connector.ep) {
      box2 = e.info;
    }
    if (e.payload && e.payload.carouselBox) {
      let box1Obj = null,
        box2Obj = null;

      if (
        ["carousel", "appointmentCarousel"].includes(e.payload.style) ||
        (e.typeData.id === ELEMENT_TYPE.PRODUCT && e.payload.source && e.payload.source !== "catalog")
      ) {
        box1Obj = e.payload.carousel[e.payload.slideIndex].buttons.find(b => b.id === connector.sp);
        box2Obj = e.payload.carousel[e.payload.slideIndex].buttons.find(b => b.id === connector.ep);
      } else {
        box1Obj = flatMap(e.payload.carousel, e => e.buttons || []).find(b => b.id === connector.sp);
        box2Obj = flatMap(e.payload.carousel, e => e.buttons || []).find(b => b.id === connector.ep);
      }

      if (e.payload.carouselBox && box1Obj) {
        box1 = box1Obj.position;
      }
      if (e.payload.carouselBox && box2Obj) {
        box2 = box2Obj.position;
      }
    }
    if (e.payload && e.payload.qrBox) {
      const box1Obj = e.payload.qr.find(b => b.id === connector.sp);
      const box2Obj = e.payload.qr.find(b => b.id === connector.ep);
      if (e.payload.qrBox && box1Obj) {
        box1 = box1Obj.position;
      }
      if (e.payload.qrBox && box2Obj) {
        box2 = box2Obj.position;
      }
    }
  });

  if (!box1 || !box2 || Object.keys(box1).length === 0 || Object.keys(box2).length === 0) {
    return { ignoreConector: true };
  }
  //Top left coordinates
  coordinates.x1 = parseFloat(box1.x);
  coordinates.y1 = parseFloat(box1.y);
  coordinates.x2 = parseFloat(box2.x);
  coordinates.y2 = parseFloat(box2.y);

  // Half widths and half drawBheights
  coordinates.w1 = parseFloat(box1.w) / 2;
  coordinates.h1 = parseFloat(box1.h) / 2;
  coordinates.w2 = parseFloat(box2.w) / 2;
  coordinates.h2 = parseFloat(box2.h) / 2;

  // Center coordinates
  coordinates.cx1 = coordinates.x1 + coordinates.w1;
  coordinates.cy1 = coordinates.y1 + coordinates.h1;
  coordinates.cx2 = coordinates.x2 + coordinates.w2;
  coordinates.cy2 = coordinates.y2 + coordinates.h2;

  const { cx1, cy1, w1, h1, h2, w2, cx2, cy2 } = coordinates;
  // Distance between centers
  const dx = cx2 - cx1;
  const dy = cy2 - cy1;

  const p1 = getIntersection(dx, dy, cx1, cy1, w1, h1);
  const p2 = getIntersection(-dx, -dy, cx2, cy2, w2, h2);

  const point1 = { x: p1[0], y: p1[1] };
  const point2 = { x: p2[0], y: p2[1] };

  const midPoint = getCenterPoint(point1, point2);

  return { point1, point2, midPoint };
};

export const drawConnector = (ctx, connector, boxes, isSelected) => {
  const { point1, point2, ignoreConector } = getCoordinations(connector, boxes);
  if (!ignoreConector) drawArrow(ctx, point1, point2, isSelected);
};

export const drawSpeech = (ctx, box, borderColor = "#a4b7c1", borderWidth = 1) => {
  ctx.save();
  ctx.beginPath();
  ctx.fillStyle = "#F1F9FC";
  ctx.lineWidth = borderWidth;
  ctx.strokeStyle = borderColor;
  ctx.moveTo(box.x, box.y);
  ctx.lineTo(box.x - 10, box.y);
  ctx.lineTo(box.x, box.y + 10);
  ctx.stroke();
  ctx.fill();
  ctx.restore();
};

export const drawRoundRect = (
  ctx,
  box,
  radius = { tr: 4, br: 4, bl: 4, tl: 4 },
  bgColor = "#f9f9f9",
  borderColor = "#a4b7c1",
  lineWidth = 1
) => {
  const { x, y, w, h } = box;
  const r = x + w;
  const b = y + h;

  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.fillStyle = bgColor;
  ctx.strokeStyle = borderColor;
  if (ctx.isBusinessView) {
    ctx.globalAlpha = ctx.isBusinessElement ? 1 : 0.3;
    // ctx.lineWidth = ctx.isBusinessElement ? 2 : lineWidth;
    // ctx.strokeStyle = ctx.isBusinessElement && borderColor === '#333333' ? '#999999' : borderColor;
  } else {
    ctx.globalAlpha = 1;
  }
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(r - radius.tr, y);
  ctx.quadraticCurveTo(r, y, r, y + radius.tr);
  ctx.lineTo(r, y + h - radius.br);
  ctx.quadraticCurveTo(r, b, r - radius.br, b);
  ctx.lineTo(x + radius.bl, b);
  ctx.quadraticCurveTo(x, b, x, b - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
};

export const drawText = (ctx, text, info, tObj, isStatic = true, maxLine = 0, isDraw = true) => {
  ctx.font = `${tObj.bold ? "bold " : ""}${tObj.fontsize}px ${tObj.fontface}`;
  ctx.fillStyle = tObj.color;
  ctx.textAlign = tObj.textAlign;
  ctx.textBaseline = "top";

  let x = info.x;
  let y = info.y;
  if (tObj.textAlign == "center") {
    ctx.textBaseline = "middle";
    x = info.x + info.w / 2;
    y = info.y + info.h / 2 + 1;
  }
  if (tObj.padding) {
    x += tObj.padding;
    y += tObj.padding;
  } else {
    x += tObj.pLeft;
    y += tObj.pTop;
  }

  if (!info.w || !info.h) {
    ctx.fillText(text, x, y);
    return;
  }

  const words = (text || "").split(" ");
  let line = "";
  let lineNo = 1;
  let lineText;
  let metrics;
  for (let n = 0; n < words.length; n++) {
    lineText = words[n];
    metrics = ctx.measureText(lineText);
    while (metrics.width > info.w - tObj.padding * 2) {
      lineText = lineText.substring(0, lineText.length - 1);
      metrics = ctx.measureText(lineText);
    }
    if (words[n] != lineText) {
      words.splice(n + 1, 0, words[n].substr(lineText.length));
      words[n] = lineText;
    }
    lineText = line + words[n] + " ";
    metrics = ctx.measureText(lineText);
    if (metrics.width > info.w - tObj.padding * 2 && n > 0) {
      const nextLineStartPoint = y + tObj.fontsize * 1.286 + 4;
      if (nextLineStartPoint > info.y + (info.h - tObj.padding * 2) && (isStatic ? true : lineNo >= maxLine)) {
        line = line.substr(0, line.length - 3) + "...";
        break;
      }
      if (isDraw) {
        ctx.fillText(line, x, y);
      }
      line = words[n] + " ";
      y = nextLineStartPoint;
      lineNo++;
    } else {
      line = lineText;
    }
  }
  if (isDraw) {
    ctx.fillText(line, x, y);
  }
  return { x, y, height: y - info.y };
};

export const drawTextWithBox = (ctx, text, info, bObj, tObj, isStatic = false, maxLine = 8) => {
  const boxData = cloneDeep(info);
  const { height } = drawText(ctx, stripHTML(text), boxData, tObj, isStatic, maxLine, false);
  boxData.h = height + 20;
  drawRoundRect(ctx, boxData, ...Object.values(bObj));
  drawText(ctx, stripHTML(text), boxData, tObj, isStatic, maxLine);
  return boxData;
};

export const drawLine = (ctx, obj, color = "#777777") => {
  const { x, y, w, h } = obj;
  ctx.beginPath();
  ctx.moveTo(x, y + h);
  ctx.lineTo(x + w, y + h);
  ctx.strokeStyle = color;
  ctx.stroke();
};

export const isPointInside = (x, y, info) => {
  if (info) {
    return x >= info.x && x <= info.x + info.w && y >= info.y && y <= info.y + info.h;
  }
};

const TEXT_STYLE = {
  fontface: "Arial",
  fontsize: 13,
  textAlign: "left",
  padding: 0,
  pTop: 0,
  pLeft: 0,
  color: "black",
  bold: false,
};

const BOT_COLORS = {
  BORDER: "#a4b7c1",
  BG: "#ffffff",
};

export const ELEMENET_POSITION = {
  STRUCTURE: {
    radius: { tl: 10, tr: 10, bl: 10, br: 10 },
    nuiRadius: { tl: 15, tr: 15, bl: 15, br: 15 },

    iconCloseInfo: info => ({ x: info.x + info.w - 20, y: info.y + 5, w: 15, h: 15 }),
    iconCloseRadius: { tl: 8, tr: 8, bl: 8, br: 8 },
    iconCloseImgInfo: info => ({ x: info.x + 1, y: info.y + 1, w: info.w - 2, h: info.h - 2 }),

    iconV2ImgInfo: info => ({ x: info.x + 10, y: info.y + 10, w: 25, h: 25 }),

    messageBoxInfo: (info, isFirst = true) => ({
      x: info.x + (isFirst ? 10 : 0),
      y: info.y + (isFirst ? 0 : info.h) + 55,
      w: info.w - (isFirst ? 20 : 0),
      h: 70,
    }),
    elTextStyle: { ...TEXT_STYLE, padding: 10 },
    elBoxStyle: { radius: undefined, bgColor: BOT_COLORS.BG, borderColor: BOT_COLORS.BORDER },
    elNuiBoxStyle: { radius: undefined, bgColor: BOT_COLORS.BG, borderColor: BOT_COLORS.BORDER },

    qrInfo: (info, i, isFull, width = 230) => ({
      x: info.x + (i % 2) * ((width - 10) / 2) + (i % 2 !== 0 ? 10 : 0),
      y: info.y + info.h + 10 + parseInt(i / 2) * 40,
      w: isFull ? width : (width - 10) / 2,
      h: 30,
    }),
    qrStyle: isSelected => ({
      radius: { tl: 15, tr: 15, bl: 15, br: 15 },
      bgColor: BOT_COLORS.BG,
      borderColor: isSelected ? "#20a8d8" : "#1b8eb7",
      lineWidth: isSelected ? 2 : undefined,
    }),
    qrTextStyle: { ...TEXT_STYLE, textAlign: "center", color: "#1b8eb7" },

    imgBoxInfo: (info, isFirst, width, height = 75, mt = 0) => {
      let w = info.w;
      let ml = 0;
      if (width) {
        w = width;
        ml = (info.w - width) / 2;
      }
      return {
        data: { x: info.x + ml, y: info.y + mt + (isFirst ? 0 : info.h + 10), w, h: height },
        box: { x: info.x, y: info.y + (isFirst ? 0 : info.h + 10), w: info.w, h: height },
      };
    },
    imgTextStyle: { ...TEXT_STYLE, textAlign: "center" },

    actionTextStyle: { ...TEXT_STYLE, color: "#777777", fontsize: 11 },
    actionTitleStyle: { ...TEXT_STYLE, color: "#666666", fontsize: 13, bold: true },

    qrInfo1: info => ({ x: info.x + 10, y: info.y + info.h + 10, w: 230, h: 30 }),
    centerTextStyle: { ...TEXT_STYLE, textAlign: "center" },
  },
  START: {
    info: { x: 60, y: 25, w: 250, h: 38 },
    textStyle: { ...TEXT_STYLE, fontsize: 13, textAlign: "center" },
  },
  BOT_MENU_CAROUSEL: {
    imgInfo: (info, isFirst) => ({
      x: info.x + (isFirst ? 10 : 0),
      y: info.y + (isFirst ? 10 : info.h + 30),
      w: 40,
      h: 40,
    }),
    imgBoxStyle: { radius: undefined, bgColor: "transparent", borderColor: BOT_COLORS.BORDER },

    pipeBoxInfo: info => ({ x: info.x + 10, y: info.y + 40, w: 25, h: 25 }),
    pipeTextStyle: { ...TEXT_STYLE, color: "#999999", padding: 10 },

    titleBoxInfo: info => ({ x: info.x + 40, y: info.y - 5, w: 210, h: 30 }),
    titleTextStyle: { ...TEXT_STYLE, padding: 10, bold: true },
    descBoxInfo: info => ({ x: info.x + 40, y: info.y + 15, w: 210, h: 30 }),
    descTextStyle: { ...TEXT_STYLE, padding: 10 },
  },
  LIST_CAROUSEL: {
    itemBoxInfo: (info, isFirst, hasText, btnCount = 0) => ({
      x: info.x + (isFirst ? (hasText ? 0 : 10) : 0),
      y: info.y + (isFirst ? (hasText ? info.h : 0) + 10 : info.h),
      w: info.w - (isFirst ? (hasText ? 0 : 20) : 0),
      h: 40 + 20 + 40 * Math.round(btnCount / 2),
    }),

    imgInfo: info => ({ x: info.x + info.w - 50, y: info.y + 10, w: 40, h: 40 }),
    imgBoxStyle: { radius: undefined, bgColor: "transparent", borderColor: BOT_COLORS.BORDER },

    titleBoxInfo: info => ({ x: info.x, y: info.y + 5, w: 190, h: 30 }),
    titleTextStyle: { ...TEXT_STYLE, padding: 10, bold: true },
    descBoxInfo: info => ({ x: info.x, y: info.y + 25, w: 190, h: 30 }),
    descTextStyle: { ...TEXT_STYLE, padding: 10 },
  },
  CARD_CAROUSEL: {
    boxInfo: (info, isFirst, btnCount = 1) => ({
      x: info.x + (isFirst ? 10 : 0),
      y: info.y + (isFirst ? 0 : info.h) + 10,
      w: info.w - (isFirst ? 20 : 0),
      h: 100 + 30 + 25 + 30 * btnCount,
    }),
    boxStyle: { radius: { tl: 0, tr: 0, bl: 4, br: 4 }, bgColor: BOT_COLORS.BG, borderColor: BOT_COLORS.BORDER },
    imgArrowInfo: (info, isLeft = true) => {
      const size = 20;
      return { x: isLeft ? info.x - 7 : info.x + info.w - size + 7, y: info.y + (info.h - size) / 2, w: size, h: size };
    },
    imgInfo: info => ({ x: info.x, y: info.y, w: info.w, h: 100 }),
    titleBoxInfo: info => ({ x: info.x, y: info.y + 100, w: info.w, h: 20 }),
    descBoxInfo: info => ({ x: info.x, y: info.y + 120, w: info.w, h: 20 }),
    titleTextStyle: { ...TEXT_STYLE, padding: 10, bold: true },
    descTextStyle: { ...TEXT_STYLE, padding: 10 },
  },
  APPT_CAROUSEL: {
    boxInfo: (info, isFirst, btnCount) => ({
      x: info.x + (isFirst ? 10 : 0),
      y: info.y + (isFirst ? 0 : info.h) + 10,
      w: info.w - (isFirst ? 20 : 0),
      h: 50 + 20 + 40 * Math.round(btnCount / 2),
    }),
    boxStyle: { radius: { tl: 0, tr: 0, bl: 4, br: 4 }, bgColor: BOT_COLORS.BG, borderColor: BOT_COLORS.BORDER },
    imgArrowInfo: (info, isLeft = true) => {
      const size = 20;
      return { x: isLeft ? info.x - 7 : info.x + info.w - size + 7, y: info.y + (info.h - size) / 2, w: size, h: size };
    },
    imgInfo: info => ({ x: info.x + 10, y: info.y + 10, w: 50, h: 50 }),
    titleBoxInfo: info => ({ x: info.x + 60, y: info.y + 10, w: info.w - 70, h: 20 }),
    descBoxInfo: info => ({ x: info.x + 60, y: info.y + 30, w: info.w - 70, h: 20 }),
    titleTextStyle: { ...TEXT_STYLE, padding: 10, bold: true },
    descTextStyle: { ...TEXT_STYLE, padding: 10 },
  },
  PRODUCT: {
    boxInfo: (info, isFirst, btnCount = 1) => ({
      x: info.x + (isFirst ? 10 : 0),
      y: info.y + (isFirst ? 0 : info.h) + 10,
      w: info.w - (isFirst ? 20 : 0),
      h: 100 + 30 + 25 + 30 * btnCount,
    }),
    boxStyle: { radius: { tl: 0, tr: 0, bl: 4, br: 4 }, bgColor: BOT_COLORS.BG, borderColor: BOT_COLORS.BORDER },
    imgArrowInfo: (info, isLeft = true) => {
      const size = 20;
      return { x: isLeft ? info.x - 7 : info.x + info.w - size + 7, y: info.y + (info.h - size) / 2, w: size, h: size };
    },
    imgInfo: info => ({ x: info.x, y: info.y, w: info.w, h: 100 }),
    titleBoxInfo: info => ({ x: info.x, y: info.y + 100, w: info.w, h: 20 }),
    descBoxInfo: info => ({ x: info.x, y: info.y + 120, w: info.w, h: 20 }),
    titleTextStyle: { ...TEXT_STYLE, padding: 10, bold: true },
    descTextStyle: { ...TEXT_STYLE, padding: 10 },
  },
  CUSTOM_COMPONENT: {
    titleBoxInfo: info => ({ x: info.x - 10, y: info.y + info.h + 10, w: info.w + 20, h: 20 }),
    qrBoxInfo: info => ({ x: info.x + 10, y: info.y, w: info.w - 20, h: info.h }),
    titleTextStyle: { ...TEXT_STYLE, padding: 10, bold: true },
    descBoxInfo: info => ({ x: info.x, y: info.y + info.h, w: info.w, h: 25 }),
    descTextStyle: { ...TEXT_STYLE, padding: 10 },
  },
  FORM_BUIDER: {
    titleBoxInfo: info => ({ x: info.x - 10, y: info.y + info.h + 10, w: info.w + 20, h: 20 }),
    qrBoxInfo: info => ({ x: info.x + 10, y: info.y, w: info.w - 20, h: info.h }),
    titleTextStyle: { ...TEXT_STYLE, padding: 10, bold: true },
    descBoxInfo: info => ({ x: info.x, y: info.y + info.h, w: info.w, h: 25 }),
    descTextStyle: { ...TEXT_STYLE, padding: 10 },
  },
};

export const getBotIntent = (element, botVersion) => {
  if (!element) return "";
  const botName =
    botVersion != 2 && element.storyNode && element.storyNode.name ? `${element.storyNode.name.split(":")[0]}__` : "";
  return `${botName}${element.typeData.intent}_${element.id}`;
  // return `${element.typeData.intent}${element.typeData.id === elementType.START ? '' : `_${element.id}`}`;
};

export const getBotTemplate = (storyTemplate, btName, intentName, id, botVersion) => {
  const botName = botVersion != 2 ? `${btName}__` : "";
  return `${storyTemplate}${botName}${intentName}_${id}`;
};

export const getAttrByPrevElement = (value, boxes, elementType) => {
  let output = [];
  const element =
    boxes.find(
      x =>
        x.id === value &&
        x.typeData &&
        [
          elementType.JSON_API,
          elementType.SOAP_API,
          elementType.JAVA_SCRIPT,
          elementType.JSON_TRANSFORMATION,
          elementType.SET_USER_DATA,
        ].includes(x.typeData.id)
    ) || boxes.find(x => x.id === value && x.typeData && x.typeData.id.startsWith("INT_API_"));
  if (!element) {
    return output;
  }
  switch (element.typeData.id) {
    case elementType.SET_USER_DATA: {
      const { attributes } = element.payload;
      if (isArray(attributes)) {
        const list = [];
        attributes.map(x => {
          list.push({ label: `{{ATTR.${x.key}}}`, value: `{{ATTR.${x.key}}}` });
        });
        output = list;
      }
      break;
    }
    default: {
      const { jsonStructure } = element.payload;
      output = isArray(jsonStructure) ? jsonStructure : [];
      break;
    }
  }
  return output;
};

export const convertPayloadToData = (optionList, props) => {
  const { boxes, botVersion, isInteractive } = props;

  const dataQR = [];
  const payloadQR = [];
  optionList.map(m => {
    if (!m.connection || !m.text) return;

    const element = boxes.find(x => x.id == m.connection);
    const payloadObj = {
      intent: getBotIntent(element, botVersion),
      displayString: m.text,
    };
    const qrObj = {
      title: m.text,
      type: m.type,
    };
    if (isInteractive) {
      qrObj.interactive = m.isInteractive;
    }
    if (m.type.includes("web_url") || m.type === "call") {
      qrObj.url = m.connection;
    } else if (m.type !== "close") {
      m.attributes.map(a => {
        if (a.attributeKey && a.attributeValue) {
          payloadObj[a.attributeKey] = a.attributeValue;
        }
      });
      qrObj.payload = JSON.stringify(payloadObj);
    }
    dataQR.push(qrObj);
    payloadQR.push(m);
  });

  return {
    dataQR: cloneDeep(dataQR),
    payloadQR: cloneDeep(payloadQR),
  };
};

export const loadDefaultBotPayload = (structure, lang) => {
  const obj = {};
  lang.list.map(x => {
    obj[x] = { ...cloneDeep(structure) };
  });
  return obj;
};

export const loadBotPayload = (structure, boxPayload, stateData, lang) => {
  const stateObj = cloneDeep(stateData);
  if (stateObj.isDynamic === undefined && stateObj.source) {
    stateObj.isDynamic = stateObj.source === "dynamic-entity";
  }
  const isDataQR = keys(structure).includes("dataQR");
  const isDataDynamicQR = keys(structure).includes("dataDynamicQR");
  const isCarouselList = keys(structure).includes("carouselList");
  const isDynamicCarousel = keys(structure).includes("dynamicCarousel");

  const isOptionList = keys(structure).includes("optionList");
  const isDynamicOptionList = keys(structure).includes("dynamicOptionList");

  const obj = {
    langPayload: cloneDeep(stateObj.langPayload),
  };

  if (isDataQR) {
    obj.payloadQR = cloneDeep(isDataDynamicQR ? boxPayload.sqr : boxPayload.oqr || boxPayload.qr);
    if (isDataDynamicQR) {
      obj.payloadDynamicQR = cloneDeep(boxPayload.dqr);
    }
  }

  keys(boxPayload.langPayload)
    .filter(x => lang.list.includes(x))
    .map(l => {
      obj.langPayload[l] = {
        ...obj.langPayload[l],
        ...boxPayload.langPayload[l],
      };

      if (isDataQR) {
        cloneDeep(obj.payloadQR).map((s, i) => {
          let item = boxPayload.langPayload[l].payloadQR[i];
          if (!item) {
            item = boxPayload.langPayload[lang.defaultLang].payloadQR[i] || { text: "" };
          }

          obj.langPayload[l].payloadQR[i] = {
            ...s,
            text: item.text,
          };

          if (isArray(s.attributes)) {
            s.attributes.map((attr, ai) => {
              obj.langPayload[l].payloadQR[i].attributes[ai].attributeValue =
                get(item, `attributes[${ai}].attributeValue`) || attr.attributeValue;
            });
          }
        });

        if (isDataDynamicQR) {
          cloneDeep(obj.payloadDynamicQR).map((d, i) => {
            let item = boxPayload.langPayload[l].payloadDynamicQR[i];
            if (!item) {
              item = boxPayload.langPayload[lang.defaultLang].payloadDynamicQR[i] || { text: "" };
            }

            obj.langPayload[l].payloadDynamicQR[i] = {
              ...d,
              text: item.text,
            };

            if (isArray(d.attributes)) {
              d.attributes.map((attr, ai) => {
                obj.langPayload[l].payloadDynamicQR[i].attributes[ai].attributeValue =
                  get(item, `attributes[${ai}].attributeValue`) || attr.attributeValue;
              });
            }
          });
        }
      }

      if (isCarouselList && !stateObj.isDynamic) {
        cloneDeep(boxPayload.carousel).map((s, i) => {
          const boxPayloadItem = boxPayload.langPayload[l].carouselList[i];
          if (!boxPayloadItem) {
            return;
          }
          const apptObj = {};
          if (stateObj.style === "appointmentCarousel") {
            apptObj.location = boxPayloadItem.location;
            apptObj.availability = boxPayloadItem.availability;
            apptObj.department = boxPayloadItem.department;
            apptObj.languages = boxPayloadItem.languages;
            apptObj.timing = boxPayloadItem.timing;
          }
          obj.langPayload[l].carouselList[i] = {
            ...s,
            ...apptObj,
            title: boxPayloadItem.title,
            description: boxPayloadItem.description,
            buttons: (s.buttons || []).map((btn, bi) => {
              const btnObj = {
                ...btn,
                title: boxPayloadItem.buttons[bi].title,
              };

              if (isArray(btn.attributes)) {
                btnObj.attributes = btn.attributes.map((attr, ai) => ({
                  ...attr,
                  attributeValue:
                    get(boxPayloadItem, `buttons[${bi}].attributes[${ai}].attributeValue`) || attr.attributeValue,
                }));
              }

              return btnObj;
            }),
          };
        });
      }

      if (isDynamicCarousel && stateObj.isDynamic) {
        const currentItem = cloneDeep(boxPayload.carousel[0]);
        const boxPayloadItem = boxPayload.langPayload[l].dynamicCarousel || currentItem; // { buttons: [] };

        const apptObj = {};
        if (stateObj.style === "appointmentCarousel") {
          apptObj.location = boxPayloadItem.location;
          apptObj.availability = boxPayloadItem.availability;
          apptObj.department = boxPayloadItem.department;
          apptObj.languages = boxPayloadItem.languages;
          apptObj.timing = boxPayloadItem.timing;
        }
        obj.langPayload[l].dynamicCarousel = {
          ...currentItem,
          ...apptObj,
          title: boxPayloadItem.title,
          description: boxPayloadItem.description,
          buttons: (currentItem.buttons || []).map((btn, bi) => {
            const btnObj = {
              ...btn,
              title: boxPayloadItem.buttons[bi].title,
            };

            if (isArray(btn.attributes)) {
              btnObj.attributes = btn.attributes.map((attr, ai) => ({
                ...attr,
                attributeValue:
                  get(boxPayloadItem, `buttons[${bi}].attributes[${ai}].attributeValue`) || attr.attributeValue,
              }));
            }

            return btnObj;
          }),
        };
      }

      if (isOptionList && !stateObj.isDynamic) {
        cloneDeep(boxPayload.options).map((s, i) => {
          const boxPayloadItem = boxPayload.langPayload[l].options[i];
          if (!boxPayloadItem) {
            return;
          }

          obj.langPayload[l].optionList[i] = {
            ...s,
            text: boxPayloadItem.text,
            value: boxPayloadItem.value,
          };
        });
      }

      if (isDynamicOptionList && stateObj.isDynamic) {
        cloneDeep(boxPayload.options).map((s, i) => {
          const boxPayloadItem = boxPayload.langPayload[l].options[i];
          if (!boxPayloadItem) {
            return;
          }

          obj.langPayload[l].dynamicOptionList[i] = {
            ...s,
            text: boxPayloadItem.text,
            value: boxPayloadItem.value,
          };
        });
      }
    });

  return obj;
};

export const updateBotPayload = (structure, stateData, lang, props) => {
  const LANG = lang.selectedLang;
  const stateObj = cloneDeep(stateData);
  if (stateObj.isDynamic === undefined && stateObj.source) {
    stateObj.isDynamic = stateObj.source === "dynamic-entity";
  }
  const isDataQR = keys(structure).includes("dataQR");
  const isDataDynamicQR = keys(structure).includes("dataDynamicQR");
  const isCarouselList = keys(structure).includes("carouselList");
  const isDynamicCarousel = keys(structure).includes("dynamicCarousel");
  const isOptionList = keys(structure).includes("optionList");
  const isDynamicOptionList = keys(structure).includes("dynamicOptionList");

  const newLangPayload = cloneDeep(stateObj.langPayload);
  const KEYS = keys(
    omit(structure, ["dataQR", "payloadQR", "dataDynamicQR", "payloadDynamicQR", "carouselList", "dynamicCarousel"])
  );

  newLangPayload[LANG] = {
    ...newLangPayload[LANG],
    ...cloneDeep(pick(stateObj, KEYS)),
  };

  if (isDataQR) {
    newLangPayload[LANG].payloadQR = cloneDeep(stateObj.payloadQR);
    newLangPayload[LANG].dataQR = cloneDeep(stateObj.dataQR);
    if (newLangPayload[LANG].payloadQR.length && !newLangPayload[LANG].dataQR.length) {
      newLangPayload[LANG].dataQR = convertPayloadToData(newLangPayload[LANG].payloadQR, props).dataQR;
    }

    if (isDataDynamicQR) {
      newLangPayload[LANG].payloadDynamicQR = cloneDeep(stateObj.payloadDynamicQR);
      newLangPayload[LANG].dataDynamicQR = cloneDeep(stateObj.dataDynamicQR);
      if (newLangPayload[LANG].payloadDynamicQR.length && !newLangPayload[LANG].dataDynamicQR.length) {
        newLangPayload[LANG].dataDynamicQR = convertPayloadToData(newLangPayload[LANG].payloadDynamicQR, props).dataQR;
      }
    }
  }
  if (isCarouselList && !stateObj.isDynamic) {
    newLangPayload[LANG].carouselList = cloneDeep(stateObj.carouselList);
  }
  if (isDynamicCarousel && stateObj.isDynamic) {
    newLangPayload[LANG].dynamicCarousel = cloneDeep(stateObj.dynamicCarousel);
  }
  if (isOptionList && !stateObj.isDynamic) {
    newLangPayload[LANG].optionList = cloneDeep(stateObj.optionList);
  }
  if (isDynamicOptionList && stateObj.isDynamic) {
    newLangPayload[LANG].dynamicOptionList = cloneDeep(stateObj.dynamicOptionList);
  }

  keys(newLangPayload).map(x => {
    if (x !== LANG) {
      KEYS.map(k => {
        newLangPayload[x][k] = newLangPayload[x][k] || newLangPayload[LANG][k];
      });

      if (isDataQR) {
        const payloadList = [];
        cloneDeep(stateObj.payloadQR).map((sp, i) => {
          const item = newLangPayload[x].payloadQR[i] || {};
          payloadList[i] = {
            ...sp,
            text: item.text || sp.text,
          };

          if (isArray(sp.attributes)) {
            sp.attributes.map((attr, ai) => {
              payloadList[i].attributes[ai].attributeValue =
                get(item, `attributes[${ai}].attributeValue`) || attr.attributeValue;
            });
          }
        });
        newLangPayload[x].payloadQR = payloadList;

        const dataList = [];
        cloneDeep(stateObj.dataQR).map((s, i) => {
          const item = newLangPayload[x].payloadQR[i] || {};
          const itemData = newLangPayload[x].dataQR[i] || {};
          dataList[i] = {
            ...s,
            title: item.text || s.title,
          };
          if (s.type === "postback") {
            const payloadObj = {
              ...JSON.parse(itemData.payload || s.payload),
              displayString: dataList[i].title,
            };
            if (isArray(item.attributes) && item.attributes.filter(a => a.attributeKey && a.attributeValue).length) {
              item.attributes
                .filter(a => a.attributeKey && a.attributeValue)
                .map(a => {
                  payloadObj[a.attributeKey] = a.attributeValue;
                });
            }
            dataList[i] = {
              ...dataList[i],
              payload: JSON.stringify(payloadObj),
            };
          }
        });
        newLangPayload[x].dataQR = dataList;

        if (isDataDynamicQR) {
          const dynamicPayloadList = [];
          cloneDeep(stateObj.payloadDynamicQR).map((dp, i) => {
            const item = newLangPayload[x].payloadDynamicQR[i] || {};
            dynamicPayloadList[i] = {
              ...dp,
              text: item.text || dp.text,
            };

            if (isArray(dp.attributes)) {
              dp.attributes.map((attr, ai) => {
                dynamicPayloadList[i].attributes[ai].attributeValue =
                  get(item, `attributes[${ai}].attributeValue`) || attr.attributeValue;
              });
            }
          });
          newLangPayload[x].payloadDynamicQR = dynamicPayloadList;

          const dynamicDataList = [];
          cloneDeep(stateObj.dataDynamicQR).map((d, i) => {
            const item = newLangPayload[x].payloadDynamicQR[i] || {};
            const itemData = newLangPayload[x].dataDynamicQR[i] || {};
            dynamicDataList[i] = {
              ...d,
              title: item.text || d.title,
              // payload: JSON.stringify({
              // 	...JSON.parse(itemData.payload || d.payload),
              // 	displayString: item.text || d.title
              // })
            };
            if (d.type === "postback") {
              const payloadObj = {
                ...JSON.parse(itemData.payload || d.payload),
                displayString: dynamicDataList[i].title,
              };

              if (isArray(item.attributes) && item.attributes.filter(a => a.attributeKey && a.attributeValue).length) {
                item.attributes
                  .filter(a => a.attributeKey && a.attributeValue)
                  .map(a => {
                    payloadObj[a.attributeKey] = a.attributeValue;
                  });
              }

              dynamicDataList[i] = {
                ...dynamicDataList[i],
                payload: JSON.stringify(payloadObj),
              };
            }
          });
          newLangPayload[x].dataDynamicQR = dynamicDataList;
        }
      }

      if (isCarouselList && !stateObj.isDynamic) {
        const cList = [];
        cloneDeep(stateObj.carouselList).map((sp, i) => {
          const item = newLangPayload[x].carouselList[i] || {};
          const apptObj = {};
          if (stateObj.style === "appointmentCarousel") {
            apptObj.location = item.location || sp.location;
            apptObj.availability = item.availability || sp.availability;
            apptObj.department = item.department || sp.department;
            apptObj.languages = item.languages || sp.languages;
            apptObj.timing = item.timing || sp.timing;
          }
          cList[i] = {
            ...sp,
            ...apptObj,
            title: item.title || sp.title,
            description: item.description || sp.description,
            buttons: (sp.buttons || []).map((btn, bi) => {
              const btnObj = {
                ...btn,
                title: get(item, `buttons[${bi}].title`, btn.title),
              };

              if (isArray(btn.attributes)) {
                btnObj.attributes = btn.attributes.map((attr, ai) => ({
                  ...attr,
                  attributeValue: get(item, `buttons[${bi}].attributes[${ai}].attributeValue`) || attr.attributeValue,
                }));
              }

              return btnObj;
            }),
          };
        });
        newLangPayload[x].carouselList = cList;
      }

      if (isDynamicCarousel && (stateObj.isDynamic || stateObj.source === "dynamic-entity")) {
        const currentItem = cloneDeep(stateObj.dynamicCarousel);
        const item = newLangPayload[x].dynamicCarousel || currentItem; // { buttons: []};

        const apptObj = {};
        if (stateObj.style === "appointmentCarousel") {
          apptObj.location = item.location || currentItem.location;
          apptObj.availability = item.availability || currentItem.availability;
          apptObj.department = item.department || currentItem.department;
          apptObj.languages = item.languages || currentItem.languages;
          apptObj.timing = item.timing || currentItem.timing;
        }

        newLangPayload[x].dynamicCarousel = {
          ...currentItem,
          ...apptObj,
          title: item.title || currentItem.title,
          description: item.description || currentItem.description,
          buttons: (currentItem.buttons || []).map((btn, bi) => {
            const btnObj = {
              ...btn,
              title: get(item, `buttons[${bi}].title`, btn.title),
            };

            if (isArray(btn.attributes)) {
              btnObj.attributes = btn.attributes.map((attr, ai) => ({
                ...attr,
                attributeValue: get(item, `buttons[${bi}].attributes[${ai}].attributeValue`) || attr.attributeValue,
              }));
            }

            return btnObj;
          }),
        };
      }

      if (isOptionList && !stateObj.isDynamic) {
        const oList = [];
        cloneDeep(stateObj.optionList).map((sp, i) => {
          const item = newLangPayload[x].optionList[i] || {};
          oList[i] = {
            ...sp,
            text: item.text || sp.text,
            value: item.value || sp.value,
          };
        });
        newLangPayload[x].optionList = oList;
      }

      if (isDynamicOptionList && (stateObj.isDynamic || stateObj.source === "dynamic-entity")) {
        const oList = [];
        cloneDeep(stateObj.dynamicOptionList).map((sp, i) => {
          const item = newLangPayload[x].dynamicOptionList[i] || {};
          oList[i] = {
            ...sp,
            text: item.text || sp.text,
            value: item.value || sp.value,
          };
        });
        newLangPayload[x].dynamicOptionList = oList;
      }
    }
  });

  return newLangPayload;
};

export const getButtonPayload = p => {
  const obj = { text: p.text };

  if (isArray(p.attributes) && p.attributes.filter(a => a.attributeKey && a.attributeValue).length) {
    obj.attributes = [];
    p.attributes
      .filter(a => a.attributeKey && a.attributeValue)
      .map(a => {
        obj.attributes.push({ attributeValue: a.attributeValue });
      });
  }

  return obj;
};

export const getPrevElement = (
  botElementId,
  boxes,
  connectors,
  elementList = [ELEMENT_TYPE.JAVA_SCRIPT, ELEMENT_TYPE.JSON_TRANSFORMATION]
) => {
  let prevElementId = null;
  boxes.map((el, i) => {
    if (elementList.includes(el.typeData.id) && el.id === botElementId) {
      const boxConnector = connectors.filter(b => b.ep == botElementId).find(c => boxes.find(b => b.id === c.sp));
      if (boxConnector) {
        prevElementId = boxConnector.sp;
      }
    }
  });
  return prevElementId;
};

export const getBotElementData = async (value, boxes, connectors, entityList, customConfigData) => {
  const jsonElement =
    boxes.find(
      x =>
        x.id === value &&
        x.typeData &&
        [
          ELEMENT_TYPE.JAVA_SCRIPT,
          ELEMENT_TYPE.JSON_API,
          ELEMENT_TYPE.SOAP_API,
          ELEMENT_TYPE.JSON_TRANSFORMATION,
        ].includes(x.typeData.id)
    ) || boxes.find(x => x.id === value && x.typeData && x.typeData.id.startsWith("INT_API_"));
  if (!jsonElement) {
    return { jsonStructure: [], jsonResult: "", isValid: true };
  }

  let data = await getAPIData(jsonElement, customConfigData);
  if ([ELEMENT_TYPE.JAVA_SCRIPT, ELEMENT_TYPE.JSON_TRANSFORMATION].includes(jsonElement.typeData.id)) {
    const prevEleId = getPrevElement(value, boxes, connectors);
    if (prevEleId) {
      data = await getBotElementData(prevEleId, boxes, connectors, entityList, customConfigData);
    }
    if (jsonElement.typeData.id === ELEMENT_TYPE.JAVA_SCRIPT) {
      const { scriptText, entity, environmentList } = jsonElement.payload;
      data = await getValidateJs(scriptText, data.jsonResult, entity, environmentList, customConfigData);
    } else if (jsonElement.typeData.id === ELEMENT_TYPE.JSON_TRANSFORMATION) {
      const { jsonSchema, transformationType } = jsonElement.payload;
      data = await getTransformData(jsonSchema, transformationType, data.jsonResult);
    }
  }

  if (jsonElement.payload.actionJS) {
    const { scriptText, entity } = jsonElement.payload.actionJS;
    data = await getValidateJs(scriptText, data.jsonResult, entity, [], customConfigData);
  }
  return data;
};

export const getValidateJs = async (scriptText, data, entity, environmentList = [], customConfigData) => {
  const mappedScriptText = mapEnvironmentV2(
    environmentList.filter(x => x.key && x.value),
    scriptText,
    customConfigData
  );
  const body = {
    entity,
    scriptText: mappedScriptText,
    payload: data,
  };
  const res = await CommonUtils.validateJs(body);
  if (res.error) {
    return { jsonStructure: [], jsonResult: "", isValid: false };
  }
  const jsonStructure = prepareStructure(res);
  return { jsonStructure, jsonResult: res, isValid: true };
};

const getTransformData = async (jsonSchema, transformationType, data) => {
  const body = {
    transformationType,
    jsonSchemaStr: jsonSchema,
    payload: data,
  };
  const res = await CommonUtils.transformJSON(body);
  if (res.error) {
    return { jsonStructure: [], jsonResult: "", isValid: false };
  }
  const jsonStructure = prepareStructure(res);
  return { jsonStructure, jsonResult: res, isValid: true };
};

const getAPIData = async (jsonElement, customConfigData) => {
  const { url, type, headersList, template, templatePayload, requestHeaders, contentType, requestAuth } =
    jsonElement.data.request_params[0];
  const { environmentList = [] } = jsonElement.payload;
  if (!url) {
    return { jsonStructure: [], jsonResult: "", isValid: true };
  }
  const isSoapData = jsonElement.typeData.id === ELEMENT_TYPE.SOAP_API;
  let body;
  if (isSoapData) {
    body = {
      template: template ? template : "",
      type: type,
      headersList: headersList,
      url: url,
    };
  } else {
    body = {
      contentType,
      bodyList: templatePayload ? templatePayload : "",
      type,
      headersList: requestHeaders,
      url,
      authType: (requestAuth && requestAuth.type) || "no_auth",
      bearerToken: requestAuth && requestAuth.token ? requestAuth.token : "",
      username: requestAuth && requestAuth.username ? requestAuth.username : "",
      password: requestAuth && requestAuth.password ? requestAuth.password : "",
      rawData: templatePayload,
      environmentList: jsonElement.payload.environmentList,
    };
  }
  const res = isSoapData
    ? await CommonUtils.getOrchestratorApi(body, isSoapData)
    : await handleRequestAPI(body, [], customConfigData);

  if (!res.success) {
    return { jsonStructure: [], jsonResult: "", isValid: false };
  }

  const jsonStructure = prepareStructure(res.data);
  return { jsonStructure, jsonResult: res.data, isValid: true };
};

export const prepareStructure = jsonResult => {
  const jsonStructure = [];
  getStructure(jsonResult, "JSON", (str, x) => {
    const structure = x ? `${str}.${x}` : `${str}${str === "JSON" ? "." : ""}[i]`;
    jsonStructure.push({ label: `{{${structure}}}`, value: `{{${structure}}}` });
  });
  return jsonStructure;
};

export const cloneBotDetails = botDetails => {
  let clonedData = botDetails.clonedData;
  clonedData.nextIntent = "";
  clonedData.intent = "";
  clonedData.prevElement = "";
  ["carouselList", "buttonList", "payloadQR", "payloadDynamicQR", "optionList", "dynamicOptionList"].map(element => {
    isArray(clonedData[element]) &&
      clonedData[element].map(data => {
        data.id = getRandomNumber(6);
        if (["carouselList"].includes(element)) {
          data.buttons &&
            data.buttons.map(button => {
              button.id = getRandomNumber(6);
            });
        }
      });
  });

  get(clonedData, "groups", [])
    .filter(x => x.groupType === "BUTTON")
    .map(g =>
      get(g, "elementList", []).map(data => {
        data.id = getRandomNumber(6);
        get(data, "payloadQR", []).map(p => {
          p.id = getRandomNumber(6);
        });
      })
    );

  get(clonedData, "groups", [])
    .filter(x => x.source === "dynamic-entity")
    .map(g =>
      get(g, "dynamicComponent.payloadQR", []).map(data => {
        data.id = getRandomNumber(6);
      })
    );

  get(clonedData, "groups", [])
    .filter(x => x.source === "static-card")
    .map(g =>
      get(g, "customComponentList", []).map(data => {
        get(data, "payloadQR", []).map(pQR => {
          pQR.id = getRandomNumber(6);
        });
      })
    );

  const adData = get(clonedData, "actionDecision", "");

  adData &&
    adData.qr.map(q => {
      const randomId = getRandomNumber(6);
      q.id = randomId;
      q.text && q.text.toLowerCase() === "true" ? (adData.intentTrueId = randomId) : "";
      q.text && q.text.toLowerCase() === "false" ? (adData.intentFalseId = randomId) : "";
    });

  const dataToCopy = JSON.stringify(botDetails);
  copy(dataToCopy);
};

export const getClipboardData = async () => {
  if (typeof navigator.clipboard.readText === "function") {
    try {
      const clipboardText = await navigator.clipboard.readText();
      return clipboardText;
    } catch (error) {
      throw error;
    }
  }
  return false;
};

export const getEntityIds = (data, botName) => {
  let entityBotsListStr = "";
  if (botName) {
    entityBotsListStr = data
      .filter(x => x.name.startsWith(`${botName}:`))
      .filter(x => x.value && x.value.payload && x.value.payload.includes("entity"))
      .map(x => x.value.payload)
      .join("");
  } else {
    entityBotsListStr = data
      .filter(x => JSON.stringify(x.data).includes("entity"))
      .map(x => JSON.stringify(x.data))
      .join("");
  }

  const regex = /"entity":\s*"(\d+)"/g;
  const entityIds = [];
  let match;
  while ((match = regex.exec(entityBotsListStr)) !== null) {
    entityIds.push(match[1]);
  }
  return [...new Set(entityIds)];
};

export const getTriggerPathList = data => {
  const triggerPathList = data.filter(
    x => x.typeData.id === ELEMENT_TYPE.TRIGGER_PATH && x.payload.type !== "extension" && !x.payload.invokeCbIntent
  );
  return uniqBy(triggerPathList, "payload.selectedBotName");
};

export const cloneBotV3 = async ({ oldBot, newBot, cloneItems, storyNodeData = "" }, handlePopup) => {
  handlePopup({
    showModal: true,
    size: "md",
    title: `Cloning Bot... (${oldBot.botName})`,
    message: "Please wait while we are processing your request.",
    component: null,
    loading: true,
    cancelBtnText: "Cancel",
  });
  const botData = {
    aiSource: "Watson",
    botAccess: newBot.botAccess,
    botImage1: newBot.botImage1,
    botImage2: newBot.botImage2,
    botName: newBot.botId,
    channelId: newBot.channelId,
    description: newBot.description,
    title: newBot.botName,
    type: newBot.type,
    features: newBot.features,
    merchantRef: newBot.merchantId ? newBot.merchantId : sessionMerchantId(),
    groupName: newBot.businessType ? newBot.businessType : sessionBusinessType(),
    status: getStatus(),
    createdBy: getCreatedBy(),
    businessVerticalId: 10,
    rating: "5",
    isVirtual: "",
    reference: "",
  };

  let cbRes = await (newBot.endpoint
    ? CommonUtils.createBotEnv(botData, newBot.endpoint, newBot.accessToken)
    : CommonUtils.createBot(botData));

  if (cbRes.error) {
    throw new Error("createBot failed");
  }

  let storyNode, boxes;

  if (storyNodeData) {
    storyNode = storyNodeData.storyNode;
    boxes = storyNodeData.list.uiPosition.boxes;
  } else {
    const parentBotStory = await CommonUtils.getStoryDialog(oldBot.botName);
    storyNode = parentBotStory[0];
    boxes = storyNode.uiPosition.boxes;
  }

  const apiCalls = boxes.map(x => {
    let objValue = JSON.parse(
      JSON.stringify(x.storyNode.value)
        .replace(new RegExp(`${oldBot.botName}:`, "g"), `${newBot.botId}:`)
        .replace(new RegExp(`${oldBot.botName}__`, "g"), `${newBot.botId}__`)
    );
    let objName = x.storyNode.name
      .replace(new RegExp(`${oldBot.botName}:`, "g"), `${newBot.botId}:`)
      .replace(new RegExp(`${oldBot.botName}__`, "g"), `${newBot.botId}__`);

    if (cloneItems && cloneItems.botList && JSON.parse(x.storyNode.value.payload).type === "triggerPath") {
      cloneItems.botList.map(cb => {
        if (newBot.botId !== cb.new) {
          objValue = JSON.parse(
            JSON.stringify(objValue)
              .replace(new RegExp(`\\\\"${cb.old}\\\\"`, "g"), `\\"${cb.new}\\"`)
              .replace(new RegExp(`${cb.old}__`, "g"), `${cb.new}__`)
          );
          objName = objName
            .replace(new RegExp(`\\\\"${cb.old}\\\\"`, "g"), `\\"${cb.new}\\"`)
            .replace(new RegExp(`${cb.old}__`, "g"), `${cb.new}__`);
        }
      });
    }

    if (cloneItems && cloneItems.entityList) {
      cloneItems.entityList.map(ce => {
        objValue = JSON.parse(
          JSON.stringify(objValue)
            .replace(new RegExp(`\\\\"${ce.old}\\\\"`, "g"), `\\"${ce.new}\\"`)
            .replace(new RegExp(`\\\\"{{entity_${ce.old}.`, "g"), `\\"{{entity_${ce.new}.`)
        );
        objName = objName
          .replace(new RegExp(`\\\\"${ce.old}\\\\"`, "g"), `\\"${ce.new}\\"`)
          .replace(new RegExp(`\\\\{{entity_"${ce.old}.`, "g"), `\\"{{entity_${ce.new}.`);
      });
    }

    const apiObj = {
      merchantId: newBot.merchantId ? newBot.merchantId : sessionMerchantId(),
      version: storyNodeData ? storyNode.list.version : storyNode.version,
      createdBy: getCreatedBy(),
      updatedBy: getCreatedBy(),
      status: getStatus(),
      name: objName,
      value: objValue,
    };
    return newBot.endpoint
      ? CommonUtils.createStoryNodeEnv(apiObj, newBot.endpoint, newBot.accessToken)
      : CommonUtils.createStoryNode(apiObj);
  });

  const csnRes = await Promise.all(apiCalls);
  if (csnRes.filter(x => x.error).length) {
    throw new Error("createStoryNode failed");
  }
  const bot = storyNodeData ? storyNode.list : storyNode;
  csnRes.map((x, i) => {
    bot.uiPosition.boxes[i].storyNodeId = x.id;
  });

  let objStory = JSON.parse(
    JSON.stringify(bot.story)
      .replace(new RegExp(`${oldBot.botName}:`, "g"), `${newBot.botId}:`)
      .replace(new RegExp(`${oldBot.botName}__`, "g"), `${newBot.botId}__`)
  );
  let objUiPosition = JSON.parse(
    JSON.stringify(bot.uiPosition)
      .replace(new RegExp(`${oldBot.botName}:`, "g"), `${newBot.botId}:`)
      .replace(new RegExp(`${oldBot.botName}__`, "g"), `${newBot.botId}__`)
  );
  let objTriggerBots = bot.triggerBots;

  if (cloneItems && cloneItems.botList) {
    cloneItems.botList.map(cb => {
      if (newBot.botId !== cb.new) {
        objStory = JSON.parse(JSON.stringify(objStory).replace(new RegExp(`${cb.old}__`, "g"), `${cb.new}__`));
        objUiPosition = JSON.parse(
          JSON.stringify(objUiPosition)
            .replace(new RegExp(`\\"${cb.old}\\"`, "g"), `\"${cb.new}\"`)
            .replace(new RegExp(`${cb.old}__`, "g"), `${cb.new}__`)
        );
        objTriggerBots = JSON.parse(
          JSON.stringify(objTriggerBots).replace(new RegExp(`\\"${cb.old}\\"`, "g"), `\"${cb.new}\"`)
        );
      }
    });
  }

  if (cloneItems && cloneItems.entityList) {
    cloneItems.entityList.map(ce => {
      objStory = JSON.parse(
        JSON.stringify(objStory)
          .replace(new RegExp(`\\"${ce.old}\\"`, "g"), `\"${ce.new}\"`)
          .replace(new RegExp(`\\"{{entity_${ce.old}.`, "g"), `\"{{entity_${ce.new}.`)
      );
      objUiPosition = JSON.parse(
        JSON.stringify(objUiPosition)
          .replace(new RegExp(`\\"${ce.old}\\"`, "g"), `\"${ce.new}\"`)
          .replace(new RegExp(`\\"{{entity_${ce.old}.`, "g"), `\"{{entity_${ce.new}.`)
      );
    });
  }

  const storyBoardList = {
    merchantId: newBot.merchantId ? newBot.merchantId : sessionMerchantId(),
    channel: ["Web"],
    languages: bot.languages,
    defaultLanguage: bot.defaultLanguage,
    storyboardId: newBot.botId,
    botId: newBot.botId,
    botName: newBot.botId,
    botType: newBot.type,
    domain: newBot.businessType ? newBot.businessType : sessionBusinessType(),
    createdBy: getCreatedBy(),
    modifiedBy: getCreatedBy(),
    status: getStatus(),
    version: bot.version,
    description: newBot.description,
    story: objStory,
    uiPosition: objUiPosition,
    triggerBots: objTriggerBots,
  };
  const csdRes = await (newBot.endpoint
    ? CommonUtils.createStoryDialogEnv(storyBoardList, newBot.endpoint, newBot.accessToken)
    : CommonUtils.createStoryDialog(storyBoardList));
  if (csdRes.error) {
    throw new Error("createStoryDialog failed");
  }
  return { cbRes: cbRes };
};
