import React, { useState, useEffect } from "react";
import Input from "./Input";
import Button from "./Button";
import CommonUtils from "../../utils/CommonUtils";
import { SelectAttribute } from "../../pages/Studio/BotBuilder/components";
import Select from "./Select";
import CreatableSelect from "react-select/creatable";

function Attributes(props) {
  const {
    list,
    type,
    setList,
    keyWidth = 50,
    valueWidth = props.keyWidth ? 100 - props.keyWidth : 50,
    size,
    fileUploadDir,
    attributeButton,
    keyOptions,
    valueOptions,
    isCreatableKey,
    isCreatableValue,
    keyName = "key",
    keyTitle = "key",
    valueName = "value",
    valueTitle = "value",
    showDescription = false,
    descriptionName = "description",
    descriptionTitle = "description",
    showSelection = false,
    selectionName = "isSelected",
    gap = size === "sm" ? 7 : 20,
  } = props;

  const [loading, setLoading] = useState(Array((list || []).length).fill(false));
  const [errorLoading, setErrorLoading] = useState(Array((list || []).length).fill(""));

  const [keyOptionList, setKeyOptionList] = useState(keyOptions);
  const [valueOptionList, setValueOptionList] = useState(valueOptions);

  useEffect(() => {
    if (!keyOptions || !isCreatableKey) return;

    const keyList = keyOptions;
    list.forEach(item => {
      if (item[keyName] && !keyList.some(a => a.value === item[keyName])) {
        keyList.push({ label: item[keyName], value: item[keyName] });
      }
    });
    setKeyOptionList(keyList);
  }, []);

  useEffect(() => {
    if (!valueOptions || !isCreatableValue) return;

    const valueList = valueOptions;
    list.forEach(item => {
      if (item[valueName] && !valueList.some(a => a.value === item[valueName])) {
        valueList.push({ label: item[valueName], value: item[valueName] });
      }
    });
    setValueOptionList(valueList);
  }, []);

  const handleIconClick = index => {
    const fileInput = document.getElementById(`file-input-${index}`);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = async (event, index) => {
    setErrorLoading(prev => prev.map((_, i) => (i === index ? "" : _)));
    const file = event.target.files[0];
    const fileData = new FormData();
    fileData.append("file", file);
    setLoading(prev => prev.map((_, i) => (i === index ? true : _)));
    const fileRes = await CommonUtils.uploadGCS(fileData, fileUploadDir);
    setLoading(prev => prev.map((_, i) => (i === index ? false : _)));
    if (fileRes && fileRes.fileUrl) {
      const fileUrl = fileRes.fileUrl || "";
      onChangeFeature(valueName, fileUrl, index);
    } else if (fileRes && fileRes.error && fileRes.data && fileRes.data.error) {
      setErrorLoading(prev => prev.map((_, i) => (i === index ? fileRes.data.error : _)));
      onChangeFeature(valueName, "", index);
    } else {
      onChangeFeature(valueName, "", index);
      setErrorLoading(prev => prev.map((_, i) => (i === index ? "File not loaded." : _)));
    }
  };

  const addFeature = () => {
    const newFeature = {
      [keyName]: "",
      [valueName]: "",
      ...(showDescription && { [descriptionName]: "" }),
      ...(showSelection && { [selectionName]: false }),
    };

    const newList = [...list, newFeature];
    setList(newList, type);
    setLoading(prev => [...prev, false]);
    setErrorLoading(prev => [...prev, ""]);
  };

  const removeFeature = index => {
    const filteredList = list.filter((_, i) => i !== index);
    setList(filteredList, type);
    setLoading(prev => prev.filter((_, i) => i !== index));
    setErrorLoading(prev => prev.filter((_, i) => i !== index));
  };

  const onChangeFeature = (name, value, index) => {
    const newList = [...list];
    newList[index] = { ...newList[index], [name]: value };
    setList(newList, type);
  };

  return (
    list &&
    list.map((obj, index) => {
      return (
        <div key={index} className="attribute-box">
          <div className="d-flex">
            <div className="d-flex w-100" style={{ gap }}>
              {showSelection && (
                <Input
                  type="checkbox"
                  outerStyle={{ width: "20px" }}
                  name={selectionName}
                  checked={obj[selectionName]}
                  onChangeFunc={(name, value) => {
                    onChangeFeature(name, !obj[selectionName], index);
                  }}
                />
              )}

              {keyOptionList ? (
                isCreatableKey ? (
                  <div style={{ width: `${keyWidth}%` }}>
                    <CreatableSelect
                      name={keyName}
                      placeholder={`Select ${keyTitle}`}
                      value={keyOptionList.find(x => x.value == obj[keyName])}
                      clearable={false}
                      noResultsText={`Type custom ${keyTitle}}`}
                      onChange={e => {
                        onChangeFeature(keyName, e.value, index);
                      }}
                      onCreateOption={value => {
                        onChangeFeature(keyName, value, index);
                        setKeyOptionList(prevState =>
                          value ? [{ label: value, value: value }, ...prevState] : prevState
                        );
                      }}
                      options={keyOptionList}
                    />
                  </div>
                ) : (
                  <Select
                    title={keyTitle}
                    name={keyName}
                    placeholder={`Select ${keyTitle}`}
                    value={obj[keyName]}
                    options={keyOptionList}
                    onChangeFunc={(name, value) => {
                      onChangeFeature(name, value, index);
                    }}
                    outerWidth={`${keyWidth}%`}
                    isClearable={false}
                  />
                )
              ) : (
                <Input
                  smallSize={size === "sm"}
                  type="text"
                  placeholder={`Enter ${keyTitle}`}
                  name={keyName}
                  value={obj[keyName]}
                  outerStyle={{ width: `${keyWidth}%` }}
                  onChangeFunc={(name, value) => onChangeFeature(name, value, index)}
                  disabled={obj.isDisabledKey}
                  mouseHoverTitle={obj.isDisabledKey ? obj.key : null}
                />
              )}

              {valueOptionList ? (
                isCreatableValue ? (
                  <div style={{ width: `${valueWidth}%` }}>
                    <CreatableSelect
                      name={valueName}
                      placeholder={`Select ${valueTitle}`}
                      value={valueOptionList.find(x => x.value == obj[valueName])}
                      clearable={false}
                      noResultsText={`Type custom ${valueTitle}}`}
                      onChange={e => {
                        onChangeFeature(valueName, e.value, index);
                      }}
                      onCreateOption={value => {
                        onChangeFeature(valueName, value, index);
                        setValueOptionList(prevState =>
                          value ? [{ label: value, value: value }, ...prevState] : prevState
                        );
                      }}
                      options={valueOptionList}
                    />
                  </div>
                ) : (
                  <Select
                    title={valueTitle}
                    name={valueName}
                    placeholder={`Select ${valueTitle}`}
                    value={obj[valueName]}
                    options={valueOptionList}
                    onChangeFunc={(name, value) => {
                      onChangeFeature(name, value, index);
                    }}
                    outerWidth={`${valueWidth}%`}
                    isClearable={false}
                  />
                )
              ) : (
                <Input
                  smallSize={size === "sm"}
                  type="text"
                  placeholder={`Enter ${valueTitle}`}
                  name={valueName}
                  disabled={loading[index]}
                  value={obj[valueName]}
                  error={errorLoading[index] ? errorLoading[index] : ""}
                  outerStyle={{ width: `${valueWidth}%` }}
                  onChangeFunc={(name, value) => onChangeFeature(name, value, index)}
                  style={{
                    paddingRight:
                      fileUploadDir || attributeButton
                        ? (fileUploadDir ? (size === "sm" ? 17 : 25) : 0) +
                          (attributeButton ? (size === "sm" ? 17 : 25) : 0)
                        : undefined,
                  }}
                  appendIcon={
                    <div className="attr-btn-action">
                      {fileUploadDir && (
                        <div className={`action-icon${size === "sm" ? " icon-sm" : ""}`}>
                          <input
                            type="file"
                            id={`file-input-${index}`}
                            onChange={e => handleFileChange(e, index)}
                            style={{ display: "none" }}
                          />
                          {loading[index] ? (
                            <i className="fa fa-spinner fa-pulse fa-fw font-16 text-gray-600"></i>
                          ) : (
                            <i className="fa fa-upload text-gray-600" onClick={() => handleIconClick(index)}></i>
                          )}
                        </div>
                      )}
                      {attributeButton && (
                        <SelectAttribute
                          outerAttriClass="h-100"
                          className={`action-icon${size === "sm" ? " icon-sm" : ""}`}
                          attributeList={attributeButton.attributeList}
                          externalAttributes={[
                            ...(attributeButton.externalAttributes || []),
                            ...(attributeButton.prevElementStructure || []),
                          ]}
                          onChangeFunc={value => onChangeFeature(valueName, value, index)}
                          setAttribute={[null, obj[valueName]]}
                        />
                      )}
                    </div>
                  }
                />
              )}

              {showDescription && (
                <Input
                  smallSize={size === "sm"}
                  type="text"
                  placeholder={`Enter ${descriptionTitle}`}
                  name={descriptionName}
                  value={obj[descriptionName]}
                  outerStyle={{ width: `${100 - keyWidth - valueWidth}%` }}
                  onChangeFunc={(name, value) => onChangeFeature(name, value, index)}
                />
              )}
            </div>
            {!obj.isDisabledKey && (
              <div className="d-flex align-items-center attributes-actions">
                {index === list.length - 1 ? <Button plusBtn className="ml10" onClickFunc={addFeature} /> : null}
                {list.filter(x => !x.isDisabledKey).length > 1 ? (
                  <Button minusBtn className="ml10" onClickFunc={() => removeFeature(index)} />
                ) : null}
              </div>
            )}
          </div>
        </div>
      );
    })
  );
}

export default Attributes;
