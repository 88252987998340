import React, { Component } from "react";
import $ from "jquery";
// import { withRouter } from "../withRouter";
import { connect } from "react-redux";
import { getMerchantAttr } from "../../../actions";
import { loadScript } from "../../../utils/ScriptUtils";
import { sessionRole } from "../../../utils/SessionUtils";
import { BRAND_COMPANY_NAME, BRAND_DOMAIN_LINK, BRAND_NAME, MESSENGER_URL } from "../../../utils/Config";
import { supportBotAllowedRoles } from "../../../utils/ConstUtils";
import { get } from "lodash";
import { withRouter } from "../../../withRouter";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internalChatId: null,
      merchantId: null,
    };
    this.isAllowedRoles = supportBotAllowedRoles.includes(sessionRole());
    this.loadInternalChat = this.loadInternalChat.bind(this);
    this.removeInternalChat = this.removeInternalChat.bind(this);
  }

  componentDidMount() {
    if (this.isAllowedRoles && window.innerWidth > 768) this.loadInternalChat();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const supportBotAccess = get(this.props, "merchantAttr.customConfig.supportBotAccess", "");
    const nextSupportBotAccess = get(nextProps, "merchantAttr.customConfig.supportBotAccess", "");
    const internalChatId = get(this.props, "merchantAttr.settings.internalChatId", null);
    const nextInternalChatId = get(nextProps, "merchantAttr.settings.internalChatId", null);

    if (window.innerWidth > 768 && (internalChatId != nextInternalChatId || supportBotAccess != nextSupportBotAccess)) {
      this.isAllowedRoles = [...supportBotAllowedRoles, ...nextSupportBotAccess.split(",")].includes(sessionRole());
      this.setState({ internalChatId: nextInternalChatId, merchantId: nextProps.merchantAttr.id }, () => {
        if (this.isAllowedRoles) {
          this.loadInternalChat();
        } else {
          this.removeInternalChat();
        }
      });
    }
  }

  loadInternalChat() {
    this.removeInternalChat();
    const { internalChatId, merchantId } = this.state;
    if (!internalChatId || !merchantId) return;

    const internalBotScript = document.createElement("script");
    internalBotScript.id = "internalchat";
    internalBotScript.type = "text/javascript";
    internalBotScript.src = `${MESSENGER_URL}libs/engagement-1.2.0.min.js?v=${Math.random()}`;
    internalBotScript.setAttribute("data-pg-chat", internalChatId);
    internalBotScript.setAttribute("data-pg-client", merchantId);
    internalBotScript.setAttribute("data-pg-db", "1");
    internalBotScript.setAttribute("data-pg-dsc", "1");
    internalBotScript.setAttribute("data-pg-spa", "1");
    internalBotScript.setAttribute("data-pg-closeBtn", "1");
    internalBotScript.setAttribute("data-pg-id", "purplegrid-internal-chat-frame");
    internalBotScript.setAttribute("data-pg-attr", "&size=medium");
    document.body.appendChild(internalBotScript);
  }

  removeInternalChat() {
    const chatElement = $("iframe[id='purplegrid-internal-chat-frame']");
    if (chatElement && chatElement.remove) {
      chatElement.remove();
    }
    const scriptElement = $("script#internalchat");
    if (scriptElement && scriptElement.remove) {
      scriptElement.remove();
    }
  }

  componentWillUnmount() {
    this.removeInternalChat();
  }

  render() {
    return (
      <footer className="app-footer">
        <span>
          Copyright &copy; {new Date().getFullYear()}{" "}
          <a href={BRAND_DOMAIN_LINK} target="_blank">
            {BRAND_COMPANY_NAME}
          </a>
          , All rights reserved.
        </span>
      </footer>
    );
  }
}

const mapStateToProps = state => {
  return {
    merchantAttr: state.common.merchantAttr.data,
  };
};

const mapDispatchToProps = {
  getMerchantAttr,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
