import React from "react";
import { TextArea, TextEditor, Input, ScrollBox, Select } from "../../../common/components";
import { Row, Col } from "reactstrap";
import { isArray } from "../../../utils/GeneralUtils";
import {
  getAttrByPrevElement,
  getButtonPayload,
  loadBotPayload,
  loadDefaultBotPayload,
  updateBotPayload,
} from "../../../utils/BotUtils";
import {
  SelectElement,
  SelectIntent,
  SelectAttribute,
  ElementForm,
  SubmitElement,
  OptionsElement,
  AdvanceOptionsExpand,
} from "./components";
import { cloneDeep, keys } from "lodash";
import CommonUtils from "../../../utils/CommonUtils";
import { getFormDetails } from "../../../common/components/helper.js";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";

const LANG_STRUCTURE = {
  text: "",
  help_text: "",
  header: "",
  footer: "",
  nextBtn: "",
  prevBtn: "",
  dataQR: [],
  payloadQR: [],
  dataDynamicQR: [],
  payloadDynamicQR: [],
};

class QReplyV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPos: 0,
      text: "",
      waTemplate: false,
      legacy_message: false,
      activity: false,
      help_text: "",
      header: "",
      footer: "",
      isRich: false,
      intent: null,
      nextIntent: "",
      currentIntent: "",
      step: 1,
      source: "static",
      isDynamic: false,
      prevElementStructure: [],
      externalAttributes: [],
      entity: null,
      entityList: [],
      enableStaticOptions: false,
      enablePagination: false,
      perPageCount: 10,
      prevBtn: "Previous",
      nextBtn: "Next",

      dataQR: [],
      payloadQR: [],
      dataDynamicQR: [],
      payloadDynamicQR: [],
      errors: {
        text: null,
      },
      langPayload: loadDefaultBotPayload(LANG_STRUCTURE, props.lang),
    };

    this.sourceList = [
      { label: "Static List", value: "static" },
      { label: "Dynamic List", value: "dynamic" },
    ];

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onChangeEntity = this.onChangeEntity.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onAdvanceOptionChange = this.onAdvanceOptionChange.bind(this);
  }

  componentDidMount() {
    this.getEntityList();
    const { boxes, botElementId, lang } = this.props;
    const box = boxes.find(b => b.id === botElementId);

    if (box) {
      const {
        text,
        waTemplate = false,
        legacy_message = false,
        activity = false,
        header = "",
        footer = "",
        help_text = "",
        intent = null,
        nextIntent = "",
        source = this.state.source,
        isDynamic = this.state.isDynamic,
        isRich,
        entity,
        currentIntent = "",
        enableStaticOptions,
        qr,
        enablePagination = false,
        perPageCount,
        prevBtn,
        nextBtn,
      } = box.payload;
      let obj = {
        text,
        waTemplate,
        legacy_message,
        activity,
        intent,
        nextIntent,
        source,
        isDynamic,
        header,
        footer,
        help_text,
        isRich,
        entity,
        enablePagination,
        currentIntent,
      };

      if (enablePagination) {
        obj.perPageCount = perPageCount;
        obj.prevBtn = prevBtn;
        obj.nextBtn = nextBtn;
      }

      if (box.typeData.type === "qreply") {
        obj[obj.isDynamic ? "payloadDynamicQR" : "payloadQR"] = cloneDeep(qr);
      } else {
        obj.enableStaticOptions = enableStaticOptions;
        obj = {
          ...obj,
          ...loadBotPayload(LANG_STRUCTURE, box.payload, this.state, lang),
        };
      }

      this.setState(obj, () => this.updatePayload());
    }
  }

  updatePayload(prevLang = this.props.lang, callback = () => {}) {
    const props = {
      boxes: this.props.boxes,
      botVersion: this.props.botVersion,
      isInteractive: this.state.waTemplate,
    };
    const langPayload = updateBotPayload(LANG_STRUCTURE, this.state, prevLang, props);
    this.setState(
      {
        langPayload,
        ...langPayload[this.props.lang.selectedLang],
      },
      callback
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang.selectedLang !== prevProps.lang.selectedLang) {
      if (this.state.step === 2) {
        this.handleStep(3, false, prevProps.lang);
      } else {
        this.updatePayload(prevProps.lang);
      }
    }
  }

  onAdvanceOptionChange(name, value, err, obj, e) {
    if (["waTemplate", "legacy_message", "activity"].includes(name)) {
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: value });
    }
  }

  getEntityList() {
    CommonUtils.getEntityByMerchantId().then(response => {
      if (response.error) {
        this.setState({ entityList: [] });
        return false;
      }
      if (response && response.entities) {
        const list = response.entities.map(e => {
          return {
            entityId: e.entityId,
            entries: e.entries,
            name: e.name,
          };
        });
        let { entity } = this.state;
        if (entity) {
          this.onChangeEntity(
            entity,
            list.find(x => x.entityId === entity)
          );
        }
        this.setState({ entityList: list });
      }
    });
  }

  onChangeEntity(value, obj) {
    const stateObj = {
      entity: value,
      externalAttributes: [],
      entityEntries: [],
    };
    if (obj && isArray(obj.entries)) {
      stateObj.externalAttributes = keys(obj.entries[0]).map(x => ({
        label: `entity.${x}`,
        value: `{{entity_${value}.${x}}}`,
      }));
      stateObj.entityEntries = obj.entries;
    }
    this.setState(stateObj);
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleInputChange(name, value) {
    this.setState({ [name]: value });
  }

  handleStep(step, isSubmit = false, prevLang) {
    const stateObj = prevLang ? {} : { step };
    const { isDynamic, enableStaticOptions } = this.state;
    const obj = getFormDetails(this.state, this.onInputValidate);
    if (!obj) return false;
    if (step === 3) {
      const obj = (isDynamic ? this.dynamicOptionRef : this.staticOptionRef).getOptions();
      if (!obj) return false;
      else {
        stateObj[isDynamic ? "dataDynamicQR" : "dataQR"] = obj.dataQR;
        stateObj[isDynamic ? "payloadDynamicQR" : "payloadQR"] = obj.payloadQR;
      }

      if (isDynamic && enableStaticOptions) {
        const sObj = this.staticOptionRef.getOptions();
        if (!sObj) return false;
        else {
          stateObj.dataQR = sObj.dataQR;
          stateObj.payloadQR = sObj.payloadQR;
        }
      }
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => this.updatePayload(prevLang, isSubmit ? this.handleSubmit : null));
  }

  handleSubmit() {
    const {
      waTemplate,
      legacy_message,
      activity,
      prevElement,
      intent,
      source,
      isDynamic,
      isRich,
      entity,
      nextIntent,
      enableStaticOptions,
      enablePagination,
      perPageCount,
      currentIntent,
      langPayload,
    } = this.state;
    const { handleSubmitElement, lang } = this.props;
    const submitCb = () => {
      const langDataObj = {};
      const langPayloadObj = {};
      let defaultPayloadQR = [],
        defaultPayloadDynamicQR = [];

      keys(langPayload).map(x => {
        const {
          dataDynamicQR,
          payloadDynamicQR,
          dataQR,
          payloadQR,
          text,
          header,
          footer,
          help_text,
          prevBtn,
          nextBtn,
        } = langPayload[x];
        let dQR = [],
          sQR = [],
          dPQR = [],
          sPQR = [];

        if (isDynamic) {
          dQR = cloneDeep(dataDynamicQR);
          dPQR = cloneDeep(payloadDynamicQR);
          if (enableStaticOptions) {
            sQR = cloneDeep(dataQR);
            sPQR = cloneDeep(payloadQR);
          }
        } else {
          sQR = cloneDeep(dataQR);
          sPQR = cloneDeep(payloadQR);
        }

        if (x === lang.defaultLang) {
          defaultPayloadDynamicQR = cloneDeep(dPQR);
          defaultPayloadQR = cloneDeep(sPQR);
        }

        langDataObj[x] = {
          input_type: "qreplyv2",
          next_intent: nextIntent,
          request_params: [
            {
              [`page_${currentIntent}`]: `\${(payLoad.page_${currentIntent})!}`,
              text: text,
              waTemplate,
              legacy_message,
              header: header,
              footer: footer,
              help_text: help_text,
              current_intent: currentIntent,
              dQR: dQR,
              sQR: sQR,
              isDynamic,
              enablePagination,
              perPageCount: enablePagination ? perPageCount : null,
              prevBtn: enablePagination ? prevBtn : null,
              nextBtn: enablePagination ? nextBtn : null,
              entityId: isDynamic ? entity : null,
              pgLanguage: BOT_ATTRIBUTES.LANGUAGE,
            },
          ],
        };

        langPayloadObj[x] = {
          text: text,
          header: header,
          footer: footer,
          help_text: help_text,
          prevBtn: enablePagination ? prevBtn : "",
          nextBtn: enablePagination ? nextBtn : "",
          payloadQR: sPQR.map(p => getButtonPayload(p)),
          payloadDynamicQR: dPQR.map(p => getButtonPayload(p)),
        };
      });

      return { langDataObj, langPayloadObj, defaultPayloadQR, defaultPayloadDynamicQR };
    };

    const { langDataObj, langPayloadObj, defaultPayloadQR, defaultPayloadDynamicQR } = submitCb();
    const { text, header, footer, help_text, nextBtn, prevBtn } = langPayloadObj[lang.defaultLang];

    const payload = {
      dqr: defaultPayloadDynamicQR,
      sqr: defaultPayloadQR,
      qr: [...defaultPayloadDynamicQR, ...defaultPayloadQR],
      text: text,
      waTemplate,
      legacy_message,
      activity,
      header,
      footer,
      help_text,
      isRich,
      intent,
      nextIntent,
      source,
      isDynamic,
      enableStaticOptions: isDynamic ? enableStaticOptions : false,
      entity: isDynamic ? entity : null,
      enablePagination,
      perPageCount: enablePagination ? perPageCount : null,
      prevBtn: enablePagination ? prevBtn : null,
      nextBtn: enablePagination ? nextBtn : null,
      currentIntent,
      langPayload: langPayloadObj,
    };

    handleSubmitElement(langDataObj[lang.defaultLang], prevElement, payload, langDataObj);
  }

  //#region render steps

  renderStep3() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId, lang } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={this.handleInputChange}
          lang={lang}
        />
      </div>
    );
  }

  renderStep2() {
    const { isDynamic } = this.state;
    return (
      <div className="ele-content">
        {isDynamic && this.renderDynamicOptions()}
        {this.renderStaticOptions()}
      </div>
    );
  }

  renderDynamicOptions() {
    const {
      externalAttributes,
      prevElementStructure,
      payloadDynamicQR,
      isDynamic,
      enablePagination,
      perPageCount,
      prevBtn,
      nextBtn,
      waTemplate,
    } = this.state;
    const { lang } = this.props;
    const isInteractive = waTemplate;
    return (
      <React.Fragment>
        <OptionsElement
          {...this.props}
          isDynamic
          isInteractive={isInteractive}
          waTemplate
          pageTitle="Add Dynamic Options"
          payloadQR={isArray(payloadDynamicQR) ? payloadDynamicQR : undefined}
          ref={e => (this.dynamicOptionRef = e)}
          prevElementStructure={[...externalAttributes, ...prevElementStructure]}
          lang={lang}
        />
        {isDynamic && (
          <div className={lang.className}>
            <label className="name mt10" style={{ lineHeight: "33px" }}>
              Enable Pagination
              <Input
                type="checkbox"
                name="enablePagination"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={enablePagination}
                onChangeFunc={() => this.setState(prevState => ({ enablePagination: !prevState.enablePagination }))}
              />
            </label>
          </div>
        )}
        {enablePagination && (
          <Row className="mb-4">
            <Col className={lang.className} md={3}>
              <label>Per Page Count</label>
              <Input
                type="number"
                name="perPageCount"
                min="1"
                placeholder="Enter per page count"
                outerClassName="mb0"
                value={perPageCount}
                onBlurFunc={(name, value) => this.setState({ [name]: value > 0 ? value : 1 })}
                onChangeFunc={this.handleInputChange}
              />
            </Col>
            <Col md={3}>
              <label>Previous Button Name</label>
              <Input
                name="prevBtn"
                placeholder="Enter button name"
                outerClassName="mb0"
                value={prevBtn}
                onChangeFunc={this.handleInputChange}
              />
            </Col>
            <Col md={3}>
              <label>Next Button Name</label>
              <Input
                name="nextBtn"
                placeholder="Enter button name"
                outerClassName="mb0"
                value={nextBtn}
                onChangeFunc={this.handleInputChange}
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }

  renderStaticOptions() {
    const { prevElementStructure, isDynamic, enableStaticOptions, payloadQR, waTemplate, legacy_message } = this.state;
    const { lang } = this.props;
    const isInteractive = waTemplate || legacy_message;
    return (
      <React.Fragment>
        {isDynamic && (
          <div className={lang.className}>
            <label className="name mt10" style={{ lineHeight: "33px" }}>
              Add Static Options
              <Input
                type="checkbox"
                name="enableStaticOptions"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={enableStaticOptions}
                onChangeFunc={() =>
                  this.setState(prevState => ({ enableStaticOptions: !prevState.enableStaticOptions }))
                }
              />
            </label>
          </div>
        )}
        {!isDynamic || (isDynamic && enableStaticOptions) ? (
          <OptionsElement
            {...this.props}
            hideTitle
            waTemplate
            isInteractive={isInteractive}
            payloadQR={isArray(payloadQR) ? payloadQR : undefined}
            ref={e => (this.staticOptionRef = e)}
            prevElementStructure={prevElementStructure}
            lang={lang}
          />
        ) : null}
      </React.Fragment>
    );
  }

  renderStep1() {
    const { boxes, botElementId, connectors, attributeList, lang } = this.props;
    const {
      text,
      waTemplate,
      legacy_message,
      help_text,
      header,
      footer,
      activity,
      prevElement,
      selectPos,
      source,
      prevElementStructure,
      isRich,
      isDynamic,
      entityList,
      entity,
      errors,
    } = this.state;
    return (
      <div className="ele-content">
        <label>
          Have your users to select an option. Use the selected option to trigger an appropriate action or set an
          attribute value.
        </label>
        <div className="b-form mt10">
          <ElementForm rowClassName={lang.className} prevElement>
            <SelectElement
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm rowClassName={lang.className} title="Source">
            <Select
              name="source"
              outerClassName="mb0"
              outerWidth={300}
              value={source}
              isClearable={false}
              options={this.sourceList}
              onChangeFunc={(name, value) => this.setState({ [name]: value, isDynamic: value === "dynamic" })}
            />
          </ElementForm>
          {isDynamic && (
            <ElementForm rowClassName={lang.className} title="Entity">
              <Select
                options={entityList}
                name="entity"
                value={entity}
                labelKey="name"
                valueKey="entityId"
                outerWidth={300}
                outerClassName="mb0"
                onChangeFunc={(name, value, error, obj) => this.onChangeEntity(value, obj)}
              />
            </ElementForm>
          )}
          <ElementForm title="Message">
            <div className="position-relative">
              {isRich ? (
                <TextEditor
                  title="Message"
                  name="text"
                  value={text}
                  isReq={true}
                  error={errors.text}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={value => this.setState({ text: value })}
                      setAttribute={[selectPos, text]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onChangeFunc={this.handleInputChange}
                />
              ) : (
                <TextArea
                  title="Message"
                  value={text}
                  name="text"
                  placeholder="Welcome to Purple grids !!"
                  isReq={true}
                  error={errors.text}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={(value, inputName) =>
                        this.setState({ text: value }, () => this.onInputValidate(inputName, null))
                      }
                      setAttribute={[selectPos, text]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value, e) => this.setState({ [name]: value, selectPos: e.target })}
                />
              )}
            </div>
            <label className={`mb0 ${lang.className}`} style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>
          <AdvanceOptionsExpand
            help_text={help_text}
            header={header}
            footer={footer}
            waTemplate={waTemplate}
            legacy_message={legacy_message}
            activity={activity}
            lang={lang}
            displayList={["waTemplate", "legacy_message", "activity", "help_text", "header", "footer"]}
            onChangeFunc={this.onAdvanceOptionChange}
          />
        </div>
      </div>
    );
  }

  //#endregion

  render() {
    const { step } = this.state;
    const { resetElement, botElementId, lang } = this.props;

    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-send-msg-group" className="adv-settings" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
          {step === 3 && this.renderStep3()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={() => this.updatePayload(lang, this.handleSubmit)}
          step={step}
          totalSteps={3}
          botElementId={botElementId}
          handleStep={this.handleStep}
          lang={lang}
        />
      </React.Fragment>
    );
  }
}

export default QReplyV2;
