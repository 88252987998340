import React, { Component } from "react";
import PropTypes from "prop-types";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Progress, Input } from "reactstrap";
import { isEqual, take } from "lodash";
import {
  getVisitorId,
  getCreatedBy,
  logoutApp,
  convertUTCtoLocal,
  isArray,
  optimizeCloudinaryImage,
} from "../../../utils/GeneralUtils";
import { handlePopup } from "../../../actions";
import { MENU_TYPE, MENU_ACCESS } from "../../../utils/Config";
import CommonUtils from "../../../utils/CommonUtils";
import { sessionRole, sessionImageUrl, sessionClear, sessionStayAlive } from "../../../utils/SessionUtils";
import { connect } from "react-redux";
// import { withRouter } from "../withRouter";
import { supportBotAllowedRoles } from "../../../utils/ConstUtils";
import { withRouter } from "../../../withRouter";

const propTypes = {
  notif: PropTypes.bool,
  accnt: PropTypes.bool,
  tasks: PropTypes.bool,
  mssgs: PropTypes.bool,
  assignedList: PropTypes.any,
};
const defaultProps = {
  notif: false,
  accnt: false,
  tasks: false,
  mssgs: false,
  assignedList: [],
};

class HeaderDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      internalChatId: null,
      assignedList: props.assignedList,
    };

    this.tabObj = MENU_ACCESS[`${MENU_TYPE.visitor}-tabs`];
    this.isAllowedRoles = supportBotAllowedRoles.includes(sessionRole());
    this.toggle = this.toggle.bind(this);
    this.handleViewAllMessages = this.handleViewAllMessages.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.assignedList, this.props.assignedList)) {
      this.setState({ assignedList: nextProps.assignedList });
    }
    if (
      this.isAllowedRoles &&
      window.innerWidth > 768 &&
      nextProps.merchantAttr &&
      nextProps.merchantAttr.settings &&
      this.state.internalChatId != nextProps.merchantAttr.settings.internalChatId
    ) {
      this.setState({ internalChatId: nextProps.merchantAttr.settings.internalChatId });
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  toggleDisplayChat() {
    if (document.querySelector("#purplegrid-internal-chat-frame")) {
      const display = document.querySelector("#purplegrid-internal-chat-frame").style.display;
      document.querySelector("#purplegrid-internal-chat-frame").style.display = display === "none" ? "block" : "none";
    }
  }

  handleViewAllMessages() {
    //NOTE: check this part on removal of message center
    this.props.setTabIndex("message-center");
    // this.props.navigate('/visitors');
    this.props.navigate("/visitors/my-messages");
  }

  dropNotif() {
    const itemsCount = 5;
    return (
      <Dropdown nav className="d-md-down-none" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <i className="icon-bell"></i>
          <Badge pill color="danger">
            {itemsCount}
          </Badge>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>You have {itemsCount} notifications</strong>
          </DropdownItem>
          {/* <DropdownItem><i className="icon-user-follow text-success"></i> New user registered</DropdownItem>
          <DropdownItem><i className="icon-user-unfollow text-danger"></i> User deleted</DropdownItem>
          <DropdownItem><i className="icon-chart text-info"></i> Sales report is ready</DropdownItem>
          <DropdownItem><i className="icon-basket-loaded text-primary"></i> New client</DropdownItem>
          <DropdownItem><i className="icon-speedometer text-warning"></i> Server overloaded</DropdownItem>
          <DropdownItem header tag="div" className="text-center"><strong>Server</strong></DropdownItem>
          <DropdownItem>
            <div className="text-uppercase mb-1">
              <small><b>CPU Usage</b></small>
            </div>
            <Progress className="progress-xs" color="info" value="25"/>
            <small className="text-muted">348 Processes. 1/4 Cores.</small>
          </DropdownItem>
          <DropdownItem>
            <div className="text-uppercase mb-1">
              <small><b>Memory Usage</b></small>
            </div>
            <Progress className="progress-xs" color="warning" value={70}/>
            <small className="text-muted">11444GB/16384MB</small>
          </DropdownItem>
          <DropdownItem>
            <div className="text-uppercase mb-1">
              <small><b>SSD 1 Usage</b></small>
            </div>
            <Progress className="progress-xs" color="danger" value={90}/>
            <small className="text-muted">243GB/256GB</small>
          </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    );
  }

  stayAlive() {
    this.props.handlePopup({
      showModal: true,
      title: "Stay Alive",
      message: sessionStayAlive()
        ? `Session will automatically log out after sometime.`
        : `Session will always stay alive until you manually do logout.`,
      okBtnText: "Yes",
      cancelBtnText: "No",
      onOkClick: () => {
        sessionStorage.setItem("stayAlive", sessionStayAlive() ? "0" : "1");
        this.props.handlePopup();
        if (this.props.handleStayAlive) {
          this.props.handleStayAlive();
        }
      },
      onCancelClick: () => this.props.handlePopup(),
    });
  }

  logout() {
    sessionClear();
    this.props.logoutUser();
    this.props.handlePopup();
    logoutApp(this.props.navigate);
  }
  dropAccnt() {
    const userRole = sessionRole();
    const { merchantProfile, rolePermission } = this.props;
    const imgUrl =
      (merchantProfile && merchantProfile.data && optimizeCloudinaryImage(merchantProfile.data.imageUrl, 35)) ||
      sessionImageUrl() ||
      "img/UserProfile.svg";
    let name = getCreatedBy();
    if (merchantProfile && merchantProfile.data && (merchantProfile.data.firstName || merchantProfile.data.lastName)) {
      name = `${merchantProfile.data.firstName || ""} ${merchantProfile.data.lastName || ""}`.trim();
    }
    const stayAlive = sessionStayAlive();
    return (
      <Dropdown className="head-msg-icon" nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          {stayAlive && <span className="stay-alive-icon"></span>}
          <img
            src={imgUrl}
            width={35}
            height={35}
            style={{ background: "#f2f5f9" }}
            className="img-avatar b-a-1"
            alt={name}
          />
          <span className="mr30">{name}</span>
        </DropdownToggle>

        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>Account</strong>
          </DropdownItem>
          {/* <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem> */}
          {sessionRole() !== "PHYSICIAN" &&
            sessionRole() !== "CALLCENTER_USER2" &&
            sessionRole() !== "M_USER" &&
            this.tabObj[MENU_TYPE.visitorTabMessageCenter] && (
              <DropdownItem onClick={() => this.handleViewAllMessages()}>
                <i className="fa fa-envelope-o"></i> Messages
                <Badge color="success">{this.state.assignedList.length}</Badge>
              </DropdownItem>
            )}
          {/* <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem> */}
          {/* <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem> */}
          {/* <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem> */}
          <DropdownItem onClick={() => this.props.navigate("/profile")}>
            <i className="fa fa-user"></i> Profile
          </DropdownItem>
          {!rolePermission.fetching &&
            isArray(rolePermission.flatMenu) &&
            rolePermission.flatMenu.some(m => m.pageId === "settings") && (
              <DropdownItem onClick={() => this.props.navigate("/settings")}>
                <i className="fa fa-wrench"></i> Settings
              </DropdownItem>
            )}
          {/* <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem> */}
          {/* <DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>
          <DropdownItem divider />
          <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem> */}
          <DropdownItem onClick={this.stayAlive.bind(this)}>
            <i className={`fa fa-anchor${stayAlive ? " text-success" : ""}`}></i>Stay Alive
          </DropdownItem>
          <DropdownItem onClick={this.logout.bind(this)}>
            <i className="fa fa-lock"></i> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  dropTasks() {
    const itemsCount = 15;
    return (
      <Dropdown nav className="d-md-down-none" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <i className="icon-settings"></i>
          <Badge pill color="warning">
            {itemsCount}
          </Badge>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem header tag="div" className="text-center">
            <strong>You have {itemsCount} pending tasks</strong>
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              Hello world! He is amet{" "}
              <span className="float-right">
                <strong>0%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="info" value={0} />
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              just until the foot{" "}
              <span className="float-right">
                <strong>25%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="danger" value={25} />
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              chat ullamcorper ultricies{" "}
              <span className="float-right">
                <strong>50%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="warning" value={50} />
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              Till members with arrows{" "}
              <span className="float-right">
                <strong>75%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="info" value={75} />
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              Nunc deductible region
              <span className="float-right">
                <strong>100%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="success" value={100} />
          </DropdownItem>
          <DropdownItem className="text-center">
            <strong>View all tasks</strong>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  dropMssgs() {
    const { assignedList } = this.state;
    return (
      this.tabObj[MENU_TYPE.visitorTabMessageCenter] && (
        <Dropdown nav className="d-md-down-none" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle title="Messages" nav>
            {/* <i className="icon-envelope-letter" ></i> */}
            <img height="38" src="/img/Message.svg" />
            {assignedList && assignedList.length > 0 && (
              <Badge pill color="purple">
                {assignedList.length}
              </Badge>
            )}
          </DropdownToggle>
          {assignedList && assignedList.length > 0 && (
            <DropdownMenu right className="dropdown-menu-lg">
              <DropdownItem header tag="div">
                <strong>You have {assignedList.length} messages</strong>
              </DropdownItem>
              {take(assignedList, 5).map((m, i) => (
                <DropdownItem key={i} onClick={() => this.handleViewAllMessages()}>
                  <div className="message ml5">
                    {m.isOnline && (
                      <div className="float-left">
                        <div className="avatar" style={{ width: "auto" }}>
                          <span className="avatar-status badge badge-success" style={{ left: -18 }}></span>
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="text-truncate font-weight-bold d-inline">
                        {getVisitorId({
                          contacts: { firstName: m.firstName || "", lastName: m.lastName || "" },
                          referenceID: m.visitorId,
                        })}
                      </div>
                      <small className="text-muted float-right font-italic">
                        {convertUTCtoLocal(m.lastModifiedTime)}
                      </small>
                    </div>
                    <div className="small text-muted text-truncate">
                      {m.pendingMessages ? m.pendingMessages.join(", ") : ""}
                    </div>
                  </div>
                </DropdownItem>
              ))}
              <DropdownItem onClick={() => this.handleViewAllMessages()} className="text-center">
                <strong>View all messages</strong>
              </DropdownItem>
            </DropdownMenu>
          )}
        </Dropdown>
      )
    );
  }

  dropMssgsBotToggle() {
    return (
      <div nav className="px-3 nav-item">
        <i className="fa fa-commenting-o fs21" onClick={this.toggleDisplayChat}></i>
      </div>
    );
  }

  render() {
    const { notif, accnt, tasks, mssgs } = this.props;
    const { internalChatId } = this.state;
    return notif
      ? this.dropNotif()
      : accnt
        ? this.dropAccnt()
        : tasks
          ? this.dropTasks()
          : mssgs
            ? internalChatId
              ? this.dropMssgsBotToggle()
              : this.dropMssgs()
            : null;
  }
}

HeaderDropdown.propTypes = propTypes;
HeaderDropdown.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    merchantAttr: state.common.merchantAttr.data,
    rolePermission: state.common.rolePermission,
  };
};

const mapDispatchToProps = {
  handlePopup,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderDropdown));
