import { isArray } from "./GeneralUtils";

export const isScriptUrlLoaded = url => {
  var scripts = document.getElementsByTagName("script");
  for (var i = scripts.length; i--; ) {
    if (scripts[i].src == url) return true;
  }
  return false;
};

export const isScriptLoaded = srcText => {
  var scripts = document.getElementsByTagName("script");
  for (var i = scripts.length; i--; ) {
    if (scripts[i].innerText.includes(srcText)) return true;
  }
  return false;
};

export const loadScriptByUrl = (url, params, cb) => {
  if (isScriptUrlLoaded(url)) {
    if (cb) {
      cb();
    }
    return false;
  }
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;
  if (params && isArray(params)) {
    params.map(p => {
      script.setAttribute(p.name, p.value);
    });
  }
  if (cb) {
    script.onload = function () {
      cb();
    };
  }
  document.body.appendChild(script);
};

export const loadScript = (scriptText, id, timeout = 0) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  if (id) script.id = id;
  script.innerHTML = scriptText;
  setTimeout(() => document.body.appendChild(script), timeout);
};
