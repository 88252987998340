import React, { useState, useEffect, useRef } from "react";
import { cloneDeep, get } from "lodash";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";

import { Button, CopyToClipboard, Head } from "./../common/components";
import CommonUtils from "./../utils/CommonUtils";

const KnowledgeHub = props => {
  const editorRef = useRef(null);
  const controller = useRef(new AbortController());
  const [playgroundTextResults, setPlaygroundTextResults] = useState("");
  const [playgroundText, setPlaygroundText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      controller.current.abort();
    };
  }, []);

  const handleSubmit = async () => {
    if (!playgroundText) return;
    setLoading(true);
    setPlaygroundTextResults("\n");
    setPlaygroundText(prev => `${prev}\n\n`);
    const response = await CommonUtils.vertexPredict(
      playgroundText.toString(),
      controller.current.signal,
      "NEWLINE_CONTENT"
    );
    setLoading(false);
    if (response.error) {
      return false;
    }
    setPlaygroundTextResults(prev => `${prev}${response.data}`);
    setPlaygroundText(prev => `${prev}${response.data}`);
  };

  const handleCancel = () => {
    controller.current.abort();
    controller.current = new AbortController();
    setLoading(false);
  };

  const handleClear = () => {
    setPlaygroundTextResults("");
    setPlaygroundText("");
  };

  return (
    <div className="knowledge-hub-box">
      <div className="inner-box">
        <div className="align-items-start d-flex">
          <img src="/img/ai.gif" width="36" className="title-icon"></img>
          <Head
            title="Knowledge Hub"
            desc="Use Knoweldge Hub to retrieve information in natural language for decision-making or information research."
          />
          <img src="/img/close.svg" className="close-icon" onClick={() => props.handleClose()} />
        </div>
        <div className="position-absolute" style={{ right: 20, top: 45 }}>
          <CopyToClipboard clsName="float-right pr-0" showOnlyIcon value={playgroundTextResults} />
        </div>
        <div className="form-control play-textarea br4" onClick={() => editorRef.current.focus()}>
          <HighlightWithinTextarea
            placeholder="Please type your query..."
            value={playgroundText}
            highlight={playgroundTextResults}
            onChange={val => setPlaygroundText(val)}
            ref={editorRef}
          />
        </div>
        <div className="mt-3 text-right">
          {loading && (
            <span className="fs15 float-left">
              <img src="/img/loader.gif" className="mr-1" style={{ width: 22 }} />
              Processing...
            </span>
          )}
          {loading ? (
            <Button cancelBtn size="sm" displayIcon={false} text="Cancel" onClickFunc={handleCancel} />
          ) : (
            <Button
              submitBtn
              size="sm"
              displayIcon={false}
              text="Submit"
              loading={loading}
              onClickFunc={handleSubmit}
            />
          )}
          <Button
            backBtn
            size="sm"
            displayIcon={false}
            text="Clear"
            className="ml-2"
            isDisabled={loading}
            onClickFunc={handleClear}
          />
        </div>
      </div>
    </div>
  );
};

export default KnowledgeHub;
