import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ElementForm from "./ElementForm";
import { Input } from "../../../../common/components";
import { Collapse } from "reactstrap";

const AdvanceOptionsExpand = props => {
  const {
    help_text,
    header,
    footer,
    waTemplate,
    interactive,
    legacy_message,
    skipIfAvailable,
    activity,
    asyncExecution,
    onChangeFunc,
    lang,
    displayList,
  } = props;
  const [isAdvSettings, setIsAdvSettings] = useState(false);

  useEffect(() => {
    if (isAdvSettings) return;

    const isOpen =
      header ||
      footer ||
      help_text ||
      waTemplate ||
      interactive ||
      legacy_message ||
      skipIfAvailable ||
      activity ||
      asyncExecution
        ? true
        : false;
    setIsAdvSettings(isOpen);
  }, [header, footer, help_text, waTemplate, interactive, legacy_message, skipIfAvailable, activity, asyncExecution]);

  useEffect(() => {
    handleScroll();
  }, [isAdvSettings]);

  const handleScroll = () => {
    setTimeout(() => {
      const element = document.querySelector(".adv-settings");
      if (element) {
        element.scrollTop = isAdvSettings ? element.scrollHeight : 0;
      }
    }, 500);
  };

  const displayField = field => {
    return displayList.includes(field);
  };

  return (
    <Fragment>
      <div className="adv-title" onClick={() => setIsAdvSettings(!isAdvSettings)}>
        <label className="name mt10 cursor-pointer" style={{ lineHeight: "33px" }}>
          Advance Options
        </label>
        <i className={`fa cursor-pointer ${isAdvSettings ? "fa-minus" : "fa-plus"}`} />
      </div>
      <div id="collapse">
        <Collapse isOpen={isAdvSettings}>
          {displayField("help_text") && (
            <ElementForm title="Help Text" rowClassName="mb10">
              <Input
                name="help_text"
                placeholder="Enter help text"
                outerClassName="mr10 mb0"
                value={help_text}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
          {displayField("header") && (
            <ElementForm title="Header" rowClassName="mb10">
              <Input
                name="header"
                placeholder="Enter header"
                outerClassName="mr10 mb0"
                value={header}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
          {displayField("footer") && (
            <ElementForm title="Footer" rowClassName="mb10">
              <Input
                name="footer"
                placeholder="Enter footer"
                outerClassName="mr10 mb0"
                value={footer}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
          {displayField("waTemplate") && (
            <ElementForm title="WhatsApp Template" rowClassName={`mb10 ${lang.className}`}>
              <Input
                type="checkbox"
                name="waTemplate"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={waTemplate}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
          {displayField("interactive") && (
            <ElementForm title="Interactive Message" rowClassName={`mb10 ${lang.className}`}>
              <Input
                type="checkbox"
                name="interactive"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={interactive}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
          {displayField("legacy_message") && (
            <ElementForm title="Legacy Text Mode" rowClassName={`mb10 ${lang.className}`}>
              <Input
                type="checkbox"
                name="legacy_message"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={legacy_message}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
          {displayField("activity") && (
            <ElementForm title="Capture Activity" rowClassName={`mb10 ${lang.className}`}>
              <Input
                type="checkbox"
                name="activity"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={activity}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
          {displayField("skipIfAvailable") && (
            <ElementForm title="Skip if Available" rowClassName={`mb10 ${lang.className}`}>
              <Input
                type="checkbox"
                name="skipIfAvailable"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={skipIfAvailable}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
          {displayField("asyncExecution") && (
            <ElementForm title="Async Execution" rowClassName={`mb10 ${lang.className}`}>
              <Input
                type="checkbox"
                name="asyncExecution"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={asyncExecution}
                onChangeFunc={onChangeFunc}
              />
            </ElementForm>
          )}
        </Collapse>
      </div>
    </Fragment>
  );
};

AdvanceOptionsExpand.defaultProps = {
  help_text: "",
  header: "",
  footer: "",
  waTemplate: false,
  interactive: false,
  legacy_message: false,
  activity: false,
  skipIfAvailable: false,
  asyncExecution: false,
  isDisable: false,
  displayList: [],
  onlyCapture: false,
  lang: {
    className: "",
  },
};

AdvanceOptionsExpand.propTypes = {
  help_text: PropTypes.string,
  header: PropTypes.string,
  footer: PropTypes.string,
  waTemplate: PropTypes.bool,
  interactive: PropTypes.bool,
  legacy_message: PropTypes.bool,
  skipIfAvailable: PropTypes.bool,
  activity: PropTypes.bool,
  asyncExecution: PropTypes.bool,
  onChangeFunc: PropTypes.func,
  isDisable: PropTypes.bool,
  displayList: PropTypes.array,
  lang: PropTypes.object,
};

export default AdvanceOptionsExpand;
