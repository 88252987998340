import React from "react";
import PropTypes from "prop-types";

const Head = props => {
  const {
    title,
    desc,
    details,
    detailsClassName,
    rightSec,
    descIcon,
    titleComp,
    subDesc,
    leftSec,
    linkDesc,
    extraWidth,
    displayPageBorder,
    className,
  } = props;
  let boxProps = {};
  if (extraWidth) boxProps.style = { maxWidth: `calc(100% - ${extraWidth}px)` };
  return (
    <React.Fragment>
      {!leftSec && (
        <div className={`page-head${rightSec ? " float-left" : ""} ${className ? className : ""}`} {...boxProps}>
          <div className="title">
            {title}
            {details && <span className={detailsClassName}>{details}</span>}
          </div>
          {titleComp}
          {desc && (
            <div className="desc">
              <i className={`fa ${descIcon || "fa-hand-o-right"}`}></i>
              <span>{desc}</span>
              {linkDesc && (
                <a href="javascript:;" className="ml-1">
                  {linkDesc}
                </a>
              )}
            </div>
          )}
          {subDesc && (
            <div className="desc" style={{ color: "#dc3545" }}>
              <i className="fa fa-hand-o-right"></i>
              <span>{subDesc}</span>
            </div>
          )}
        </div>
      )}
      {rightSec && (
        <React.Fragment>
          <div className="page-head-right">{rightSec}</div>
          <div className="clearfix"></div>
        </React.Fragment>
      )}
      {displayPageBorder && <div className="page-border-head"></div>}
    </React.Fragment>
  );
};

Head.defaultProps = {
  title: null,
  desc: null,
  details: null,
  rightSec: null,
  titleComp: null,
  linkDesc: null,
  extraWidth: null,
  displayPageBorder: false,
  detailsClassName: "",
};

Head.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  details: PropTypes.any,
  rightSec: PropTypes.element,
  titleComp: PropTypes.element,
  linkDesc: PropTypes.string,
  extraWidth: PropTypes.number,
  displayPageBorder: PropTypes.bool,
  detailsClassName: PropTypes.string,
};
export default Head;
