import moment from "moment";

const defaultFilterOptions = {
  buttons: ["apply", "reset"],
  closeOnApply: true,
  suppressAndOrCondition: true,
  trimInput: true,
};

export const textFilterOptions = (option = "contains") => ({
  ...defaultFilterOptions,
  filterOptions: [option],
  defaultOption: option,
});

export const dateFilterOptions = {
  ...defaultFilterOptions,
  filterOptions: ["inRange"],
  inRangeInclusive: true,
  defaultOption: "inRange",
};

export const numberFilterOptions = {
  ...defaultFilterOptions,
  filterOptions: ["equals", "notEqual", "lessThan", "greaterThan"],
  defaultOption: "equals",
};

export const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  if (!cellValue) return 0;

  const dt = moment(cellValue, "lll");
  const cellDate = new Date(dt.get("year"), dt.get("month"), dt.get("date"));

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};
