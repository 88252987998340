import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../common/components";
import { LANGUAGE_LIST } from "../../../../utils/ConstUtils";
import { BotPopupContext } from "./BotPopupContext";

const SubmitElement = props => {
  const { isSubmitLoader } = useContext(BotPopupContext);
  const { totalSteps, botElementId, step, resetElement, handleSubmit, handleStep, hideButtons, lang } = props;
  return (
    <div>
      <div className="ele-submit">
        {lang && (
          <div className={`float-left lang-list${hideButtons ? " no-click-v1" : ""}`}>
            {lang.list
              .map(l => LANGUAGE_LIST.find(x => x.value === l))
              .map(l => (
                <span
                  className={l.value === lang.selectedLang ? "text-primary " : ""}
                  onClick={() => lang.handleLang(l.value)}
                  key={l.value}
                >
                  {l.label}
                </span>
              ))}
          </div>
        )}
        <Button cancelBtn text="Cancel" className={hideButtons ? "" : "mr10"} onClickFunc={resetElement} />
        {!hideButtons && step !== 1 && (
          <Button prevBtn text="Previous" className="mr10" onClickFunc={() => handleStep(step - 1)} />
        )}
        {!hideButtons && step !== totalSteps && (
          <Button
            nextBtn
            text="Next"
            className={botElementId !== 0 ? "mr10" : ""}
            onClickFunc={() => handleStep(step + 1)}
          />
        )}
        {!hideButtons && botElementId === 0 && step === totalSteps && (
          <Button submitBtn text="Submit" loading={isSubmitLoader} onClickFunc={handleSubmit} />
        )}
        {!hideButtons && botElementId !== 0 && (
          <Button
            submitBtn
            text="Save & Close"
            loading={isSubmitLoader}
            onClickFunc={() => (step === totalSteps ? handleSubmit() : handleStep(step + 1, true))}
          />
        )}
      </div>
    </div>
  );
};

SubmitElement.defaultProps = {
  step: 1,
  totalSteps: 2,
  hideButtons: false,
};

SubmitElement.propTypes = {
  step: PropTypes.number,
  totalSteps: PropTypes.number,
  resetElement: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleStep: PropTypes.func,
  botElementId: PropTypes.any,
  hideButtons: PropTypes.bool,
  lang: PropTypes.object,
};

export default SubmitElement;
