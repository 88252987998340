import React, { Component, Fragment } from "react";

import { Button } from "../../common/components";

import { CLOUDINARY_DATA } from "../../utils/Config";
import { createCloudinaryWidget, optimizeCloudinaryImage } from "../../utils/GeneralUtils";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: this.props.imagePath,
    };

    this.widget = null;
    this.openCloudinaryWidget = this.openCloudinaryWidget.bind(this);
    this.clearAiAgentImage = this.clearAiAgentImage.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.imagePath !== this.props.imagePath) {
      this.setState({ imagePath: nextProps.imagePath });
    }
  }

  // upload ai aget image
  openCloudinaryWidget() {
    if (this.widget) {
      this.widget.open();
      return;
    }
    this.widget = createCloudinaryWidget(CLOUDINARY_DATA.UPLOAD_AIAGENTS_PRESET, result => {
      if (result.event == "success") {
        this.setState({ imagePath: result.info.secure_url }, () => {
          setTimeout(() => {
            this.widget.close({ quiet: true });
          }, 300);
        });
        if (this.props.getImagePath) this.props.getImagePath(result.info.secure_url);
      }
    });
    this.widget.open();
  }

  // remove image of ai agent
  clearAiAgentImage() {
    this.setState({ imagePath: null });
    this.props.getImagePath(null);
  }

  render() {
    const { imagePath } = this.state;
    const { defaultImg, imgWidth, imgHeight, outerClassName, imageClassName, isSmall } = this.props;

    let imgBoxStyle = {};
    if (imgWidth) {
      imgBoxStyle["width"] = imgWidth;
    }
    if (imgHeight) {
      imgBoxStyle["height"] = imgHeight;
    }

    let btnStyle = {};

    return (
      <Fragment>
        <div className={`c-img-box ${outerClassName || ""}`} style={imgBoxStyle}>
          {imagePath ? (
            <div className="c-action-btn-box">
              <Button
                text=""
                customIcon="fa-cloud-upload"
                outline
                color="secondary"
                title="Upload"
                style={btnStyle}
                className={`upload m0 ${isSmall ? "small" : ""}`}
                onClickFunc={this.openCloudinaryWidget}
              />
              <Button
                text=""
                customIcon="fa-trash-o"
                outline
                color="secondary"
                title="Clear"
                style={btnStyle}
                className={`delete ${isSmall ? "small" : ""}`}
                onClickFunc={this.clearAiAgentImage}
              />
            </div>
          ) : (
            <Button
              outline
              title="Upload"
              color="secondary"
              className="c-upload-alone"
              customIcon="fa-cloud-upload"
              onClickFunc={this.openCloudinaryWidget}
            />
          )}
          {imagePath || defaultImg ? (
            <img
              className={imageClassName}
              src={imagePath ? optimizeCloudinaryImage(imagePath, imgWidth) : defaultImg}
            />
          ) : null}
        </div>
        <div className="clearfix"></div>
      </Fragment>
    );
  }
}

ImageUpload.defaultProps = {
  imagePath: null,
  imgWidth: null,
  imgHeight: null,
  defaultImg: "./img/ai-agent-icon.svg",
  btnWidth: null,
  outerClassName: "",
  isSmall: false,
  imageClassName: undefined,
};

export default ImageUpload;
