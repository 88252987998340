import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import Button from "./Button";
import { handlePopup } from "./../../actions";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

class ModalPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.modal,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.modal, this.props.modal)) {
      this.setState({ modal: nextProps.modal });
    }
  }

  handleCancel() {
    const { modal } = this.state;
    const { handlePopup } = this.props;
    handlePopup();
    if (modal.onCancelClick) {
      modal.onCancelClick();
    }
  }

  handleOk() {
    const { modal } = this.state;
    const { handlePopup } = this.props;
    if (modal.onOkClick) {
      modal.onOkClick();
    } else {
      handlePopup();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal isOpen={modal.showModal} size={modal.size} centered={modal.centered} className={modal.modalClass || ""}>
        {modal.component ? (
          <React.Fragment>{modal.component}</React.Fragment>
        ) : (
          <React.Fragment>
            <ModalHeader>{modal.title}</ModalHeader>
            <ModalBody>
              <p dangerouslySetInnerHTML={{ __html: modal.message }}></p>
            </ModalBody>
            <ModalFooter>
              <Button
                text={modal.okBtnText || "OK"}
                onClickFunc={this.handleOk}
                loading={modal.loading}
                color="primary"
              />
              {modal.cancelBtnText && (
                <Button text={modal.cancelBtnText} onClickFunc={this.handleCancel} color="danger" />
              )}
            </ModalFooter>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

ModalPopup.defaultProps = {
  centered: false,
};

const state = store => ({
  modal: store.common.popup,
});

export default connect(state, { handlePopup })(ModalPopup);
