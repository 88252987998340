import { engagementType, ignoreChannelForMerchant } from "./GeneralUtils";

//module.exports = {
// flag-icon-css
export function getCountryFlag(countryCode, className = "mr5") {
  let code = countryCode && countryCode.toLowerCase() != "" ? countryCode.toLowerCase() : "us";
  if (code == "usa") code = "us";
  return `fi fi-${code} ${className}`;
}
// font-awesome
export function getDevice(deviceName, className = "") {
  let device = deviceName && deviceName.toLowerCase() != "" ? deviceName.toLowerCase() : "desktop";
  if (device == "laptop") return `fa fa-laptop ${className}`;
  else if (device == "mobile" || device == "iphone") return `fa fa-mobile fs21 ml3 ${className}`;
  else return `fa fa-desktop fs15 ${className}`;
}
// font-os.css
export function getOS(osName, className = "mr5") {
  let os = osName && osName.toLowerCase() != "" ? osName.toLowerCase() : "apple";
  if (os.includes("linux")) return `fo-ubuntu fs17 ${className}`;
  else if (os.includes("win")) return `fo-win10 fs15 ${className}`;
  else if (os.includes("android")) return `fa fa-android  ${className}`;
  else return `fo-apple fs17 ${className}`;
}
// font-awesome
export function getChannel(channelName, className = "") {
  let channel = channelName && channelName.toLowerCase() != "" ? channelName.toLowerCase() : "Web";
  if (channel == "web") return `fa fa-globe ${className}`;
  else if (channel == "facebook" || channel == "fb") return `fa fa-facebook ${className}`;
  else if (channel == "twitter") return `fa fa-twitter ${className}`;
  else if (channel == "slack") return `fa fa-slack ${className}`;
  else if (channel == "whats app" || channel == "whatsapp") return `fa fa-whatsapp ${className}`;
  else if (channel == "link") return `fa fa-link ${className}`;
  else if (channel == "apple chat") return `fa fa-wechat ${className}`;
  else return `fa fa-globe ${className}`;
}
export function getChannelImg(channelName, isAds = false) {
  let iconPath = "web.svg";
  let channel = channelName && channelName.toLowerCase() != "" ? channelName.toLowerCase().trim() : "web";
  const iconList = [
    { name: "twitter", path: "twitter.svg", adPath: "twitter.jpg" },
    { name: "imessage", path: "imessage.svg" },
    { name: "line", path: "line.svg" },
    { name: "web", path: "web.svg" },
    { name: "jello", path: "jello-business-app.png" },
    { name: "wechat", path: "wechat.svg" },
    { name: "skype", path: "skype.svg" },
    { name: "sms", path: "sms.png" },
    { name: "voice", path: "phone.svg" },
    { name: "twilio", path: "twilio.png" },
    { name: "google business", path: "google-business.png" },
    { name: "alexa", path: "alexa.png" },
    { name: "cortana", path: "cortana.png" },
    { name: "android", path: "android.svg" },
    { name: "viber", path: "viber.svg" },
    { name: "whatsapp", path: "whatsapp.svg" },
    { name: "chimes kiosk", path: "chimes-kiosk.png", adPath: "chimesweb.png" },
    { name: "ios", path: "ios.svg" },
    { name: "ebay", path: "ebay.svg" },
    { name: "google ads", path: "googleads.svg", adPath: "googleads.jpg" },
    { name: "googleads", path: "googleads.svg", adPath: "googleads.jpg" },
    { name: "facebook marketplace", path: "facebook-marketplace.svg" },
    { name: "facebook messenger", path: ignoreChannelForMerchant() },
    { name: "facebook ads", path: "facebookads.png" },
    { name: "fbads", path: "facebookads.png" },
    { name: "facebook", path: "facebook.svg" },
    { name: "fb", path: "facebook.svg" },
    { name: "olx", path: "olx.svg" },
    { name: "email", path: "email.png" },
    { name: "email message", path: "email.png" },
    { name: "pinterest", path: "pinterest.png", adPath: "pintrust.jpg" },
    { name: "youtube", path: "youtube.png" },
    { name: "instagram", path: "instagram.png", adPath: "instagram.jpg" },
    { name: "telegram", path: "telegram.png" },
    { name: "yelp", path: "yelp.png", adPath: "yelp.png" },
    { name: "vine", path: "vine.png" },
    { name: "amazon", path: "amazon.png" },
    { name: "linkedin ads", path: "linkedin-ads.png" },
    { name: "linkedin", path: "linkedin.png" },
    { name: "quikr", path: "quikr.png" },
    { name: "reddit", path: "reddit.png" },
    { name: "snapchat ads", path: "snapchat-ads.png" },
    { name: "snapchat", path: "snapchat.png" },
    { name: "tumblr", path: "tumblr.png" },
    { name: "yahoo ads", path: "yahoo-ads.png" },
    { name: "bing ads", path: "bing-ads.jpg" },
    { name: "baidu", path: "baidu.png" },
    { name: "blogger", path: "blogger.png" },
    { name: "criagslist", path: "craigslist.png" },
    { name: "google+", path: "google-plus.png" },
    { name: "nextdoor", path: "nextdoor.png" },
    { name: "foursquare", path: "foursquare.png" },
    { name: "yellowpages", path: "yellowpages.png" },
    { name: "temi robot", path: "temi-robot.svg" },
    { name: "pepper robot", path: "pepper-robot.svg" },
    { name: "cruzr robot", path: "cruzr-robot.svg" },
    { name: "bella robot", path: "bella-bot.svg" },
    { name: "ketty robot", path: "ketty-bot.svg" },
    { name: "pudu robot", path: "pudu-bot.svg" },
    { name: "keenon robot", path: "pudu-bot.svg" },
    { name: "google business review", path: "google-business-review.svg" },
    { name: "google play store review", path: "google-play-store-review.svg" },
    { name: "facebook comments", path: "facebook-comments.svg" },
    { name: "instagram comments", path: "instagram-comments.svg" },
    { name: "teams", path: "teams.svg" },
    // { name: 'instagram ads', path: 'instagram.png' },
  ];

  const obj = iconList.find(i => channel.includes(i.name));
  if (isAds) iconPath = obj && obj.adPath ? obj.adPath : null;
  else iconPath = obj ? obj.path : "web.svg";
  if (!iconPath) return null;
  return `/img/${isAds ? "ads" : "channel"}/${iconPath}`;
}
export function getChannelImgById(id, isAds = false) {
  let iconPath = "web.svg";
  const iconList = [
    { channelId: 1, name: "facebook messenger", path: ignoreChannelForMerchant() },
    { channelId: 2, name: "skype", path: "skype.svg" },
    { channelId: 3, name: "web", path: "web.svg", adPath: "web.png" },
    { channelId: 4, name: "email", path: "email.png" },
    { channelId: 5, name: "sms", path: "sms.png" },
    { channelId: 6, name: "twitter", path: "twitter.svg", adPath: "twitter.jpg" },
    // { channelId: 6, name: 'pinterest', path: 'pinterest.png', adPath: 'pinterest.jpg' },
    { channelId: 7, name: "youtube", path: "youtube.svg" },
    { channelId: 8, name: "instagram messenger", path: "insta-messenger.svg", adPath: "insta-messenger.svg" },
    { channelId: 9, name: "whatsapp", path: "whatsapp.svg" },
    { channelId: 10, name: "imessage", path: "imessage.svg" },
    { channelId: 11, name: "viber", path: "viber.svg" },
    { channelId: 12, name: "telegram", path: "telegram.png" },
    { channelId: 13, name: "yelp", path: "yelp.png", adPath: "yelp.png" },
    { channelId: 14, name: "vine", path: "vine.png" },
    { channelId: 15, name: "wechat", path: "wechat.svg" },
    { channelId: 16, name: "twilio", path: "twilio.png" },
    { channelId: 17, name: "google business", path: "google-business.png" },
    { channelId: 18, name: "ebay", path: "ebay.svg" },
    { channelId: 19, name: "amazon", path: "amazon.png" },
    { channelId: 20, name: "facebook marketplace", path: "facebook-marketplace.svg" },
    { channelId: 21, name: "facebook ads", path: "facebookads.png" },
    { channelId: 22, name: "google ads", path: "googleads.svg", adPath: "googleads.jpg" },
    { channelId: 23, name: "line", path: "line.svg" },
    { channelId: 24, name: "linkedin", path: "linkedin.png" },
    { channelId: 25, name: "linkedin ads", path: "linkedin-ads.png", adPath: "linkedin.jpg" },
    { channelId: 26, name: "olx", path: "olx.svg" },
    { channelId: 27, name: "quikr", path: "quikr.png" },
    { channelId: 28, name: "reddit", path: "reddit.png" },
    { channelId: 29, name: "snapchat", path: "snapchat.png" },
    { channelId: 30, name: "snapchat ads", path: "snapchat-ads.png" },
    { channelId: 31, name: "tumblr", path: "tumblr.png" },
    { channelId: 32, name: "twitter ads", path: "twitter.svg", adPath: "twitter.jpg" },
    { channelId: 33, name: "yahoo ads", path: "yahoo-ads.png" },
    { channelId: 34, name: "bing ads", path: "bing-ads.jpg" },
    { channelId: 35, name: "criagslist", path: "craigslist.png" },
    { channelId: 36, name: "baidu", path: "baidu.png" },
    { channelId: 37, name: "blogger", path: "blogger.png" },
    { channelId: 38, name: "tiktok", path: "tiktok.svg" },
    { channelId: 39, name: "nextdoor", path: "nextdoor.png" },
    { channelId: 40, name: "yellowpages", path: "yellowpages.png" },
    { channelId: 41, name: "jello", path: "jello-business-app.png" },
    { channelId: 42, name: "foursquare", path: "foursquare.png" },
    { channelId: 43, name: "chimes web", path: "chimes-web.png", adPath: "chimesweb.png" },
    { channelId: 44, name: "pinterest", path: "pinterest.png", adPath: "pinterest.jpg" },
    { channelId: 45, name: "trip advisor", path: "tripadvisor.png", adPath: "tripadvisor.jpg" },
    { channelId: 46, name: "landing page", path: "landing-page.svg" },
    { channelId: 47, name: "botim", path: "botim.png" },
    { channelId: 48, name: "alexa", path: "alexa.png" },
    { channelId: 49, name: "cortana", path: "cortana.png" },
    { channelId: 50, name: "google assistant", path: "google-assistant.png" },
    { channelId: 51, name: "voice", path: "phone.svg" },
    { channelId: 52, name: "live chat", path: "livechat.png" },
    { channelId: 53, name: "email message", path: "email.png" },
    { channelId: 54, name: "temi robot", path: "temi-robot.svg" },
    { channelId: 55, name: "pepper robot", path: "pepper-robot.svg" },
    { channelId: 56, name: "cruzr robot", path: "cruzr-robot.svg" },
    { channelId: 57, name: "bella robot", path: "bella-bot.svg" },
    { channelId: 58, name: "ketty robot", path: "ketty-bot.svg" },
    { channelId: 59, name: "pudu robot", path: "pudu-bot.svg" },
    { channelId: 60, name: "facebook page ", path: "facebook.svg" },
    { channelId: 61, name: "instagram", path: "instagram.png", adPath: "instagram.jpg" },
    { channelId: 62, name: "google business review", path: "google-business-review.svg" },
    { channelId: 63, name: "google play store review", path: "google-play-store-review.svg" },
    { channelId: 64, name: "facebook comments", path: "facebook-comments.svg" },
    { channelId: 65, name: "instagram comments", path: "instagram-comments.svg" },
    { channelId: 67, name: "keenon robot", path: "keenon-robotics.svg" },
    { channelId: 68, name: "instagram ads", path: "instagram.png" },
    { channelId: 69, name: "teams", path: "teams.svg" },
    { channelId: 70, name: "mobileapp", path: "mobileapp.svg" },
    // { name: 'android', path: 'android.svg' },
    // { name: 'ios', path: 'ios.svg' },
  ];

  const obj = iconList.find(i => i.channelId == id);
  if (isAds) iconPath = obj && obj.adPath ? obj.adPath : null;
  else iconPath = obj ? obj.path : "web.svg";

  if (!iconPath) return null;
  return `/img/${isAds ? "ads" : "channel"}/${iconPath}`;
}
export function getAiAgentImg(name) {
  let iconPath = "";
  let agent = name && name.toLowerCase() != "" ? name.toLowerCase().trim() : "";

  const iconList = [
    { name: "watsonai", path: "ibmwatson.png" },
    { name: "openai", path: "openai.svg" },
    { name: "googleai", path: "google-ai.png" },
    { name: "llama3", path: "llama3.svg" },
    { name: "azureai", path: "azure-ai.svg" },
    { name: "bedrock", path: "bedrock.svg" },
    { name: "claudeai", path: "cloude-ai.svg" },
    { name: "mondeeai", path: "mondee-ai.svg" },
    { name: "solai", path: "solai.png" },
    { name: "alexa", path: "aws.png" },
    { name: "googlebardalpha", path: "google-bard-alpha.png" },
    { name: "vertexai", path: "google-vertex-ai.png" },
    { name: "visionai", path: "ComputerVision.svg" },
  ];

  const obj = iconList.find(i => agent === i.name);
  iconPath = obj ? obj.path : "";
  return iconPath ? `/img/aiagents/${iconPath}` : "";
}

// new util for getting icon with fallback svg
export function getIntegrationImage(item) {
  return item.icon ? item.icon.url : `/img/integrations/no-img.svg`;
}

export function getIntegrationImg(name) {
  let iconPath = "no-img.svg";
  let integration = name && name.toLowerCase() != "" ? name.toLowerCase().trim() : "google calendar";

  const iconList = [
    { name: "google calendar", path: "Google_Calendar.svg" },
    { name: "chimes", path: "chimes.svg" },
    { name: "zappier", path: "Zapier.svg" },
    { name: "hubspot", path: "hubspot.svg" },
    { name: "epic", path: "EPIC.svg" },
    { name: "marketo", path: "Marketo.svg" },
    { name: "salesforce", path: "Salesforce.svg" },
    { name: "slack", path: "Slack.svg" },
    { name: "sendgrid", path: "Sendgrid.svg" },
    { name: "leadsquared", path: "Leadsquare.svg" },
    { name: "zoom", path: "Zoom.svg" },
    { name: "teams", path: "Teams.svg" },
    { name: "twilio", path: "Twilio.svg" },
    { name: "cloudinary", path: "cloudinary.svg" }, //change later
    { name: "gtm", path: "goto-meeting.svg" },
    { name: "onedrive", path: "Onedrive.svg" },
    { name: "jotform", path: "Jotform.svg" },
    { name: "secure api", path: "secure-api.svg" },
    { name: "api", path: "api.svg" },
    { name: "video conferencing", path: "VC.svg" },
    { name: "avaya", path: "Avaya.svg" },
    { name: "box", path: "Box.svg" },
    { name: "calendly", path: "calendly.svg" },
    { name: "google sheets", path: "Google_Sheets.svg" },
    { name: "jira", path: "Jira.svg" },
    { name: "sap", path: "sap.svg" },
    { name: "shopify", path: "Shopify.svg" },
    { name: "service now", path: "service-now.svg" },
    { name: "stripe", path: "Stripe.svg" },
    { name: "microsoft dynamics", path: "ms-dynamics.svg" },
    { name: "lyft", path: "lyft.svg" },
    { name: "uber", path: "uber-icon.svg" },
    { name: "rapid api", path: "rapid-api.svg" },
    { name: "paypal", path: "Paypal.svg" },
    { name: "paytm", path: "Paytm.svg" },
    { name: "braintree", path: "braintree.svg" },
    { name: "razorpay", path: "Razerpay.svg" },
    { name: "noqoodypay", path: "Noqoody.svg" },
    { name: "workday", path: "Workday.svg" },
    { name: "zendesk", path: "Zendesk.svg" },
    { name: "zoho", path: "Zoho.svg" },
    { name: "freshdesk", path: "FreshDesk.svg" },
    { name: "nest thermostat", path: "Nest.svg" },
    { name: "arlo camera", path: "Arlo.svg" },
    { name: "pressure thermometer", path: "Pressure Thermo meter.svg" },
    { name: "connected inhalers", path: "Connected Inhalers.svg" },
    { name: "glucose monitoring", path: "glucosemeter.svg" },
    { name: "heart rate monitoring", path: "heart rate monitoring.svg" },
    { name: "patient monitoring", path: "patient-monitoring.svg" },
    { name: "temicloud", path: "temi-robot.svg" },
    { name: "puducloud", path: "pudu-robotics.svg" },
    { name: "ubtech robotics", path: "cruzr-robot.svg" },
    { name: "softbank robotics", path: "softbank.svg" },
    { name: "mqtt", path: "mqtt.svg" },
    { name: "jello ticketing app", path: "jello-ticketing.svg" },
    { name: "adp (workforce now)", path: "adp.svg" },
    { name: "oracle symphony", path: "oracle-symphony.svg" },
    { name: "peoplesoft", path: "peoplesoft.svg" },
    { name: "bamboohr", path: "bamboohr.svg" },
    { name: "kone elevators", path: "kone-elevators.svg" },
    { name: "mitsubishi elevators", path: "mitsubishi-elevators.svg" },
    { name: "oracle cerner", path: "oracle-cerner.svg" },
    { name: "ui path", path: "ui-path.svg" },
    { name: "automation anywhere", path: "automation-anywhere.svg" },
    { name: "schindler", path: "schindler.svg" },
    { name: "otis elevator", path: "otis.svg" },
    { name: "hid signo reader", path: "hid-signo.svg" },
    { name: "hid omnikey reader", path: "hid-omnikey.svg" },
    { name: "data source", path: "data-source.png" },
    { name: "oracle e-business suite", path: "oracle-e-suite.svg" },
    { name: "upkeep", path: "upkeep.svg" },
    { name: "maximo by ibm", path: "maximo.svg" },
    { name: "cisco", path: "cisco.svg" },
    { name: "genesys", path: "genesys.svg" },
    { name: "voxtron", path: "voxtron.svg" },
    { name: "dialpad", path: "dialpad.svg" },
    { name: "google maps", path: "google-maps.svg" },
    // { name: 'google marketplace', path: 'google-marketplace.svg' },
    { name: "google places api", path: "google-places-api.svg" },
    { name: "klook", path: "klook.svg" },
    { name: "opentable", path: "opentable.svg" },
    { name: "skyscanner", path: "skyscanner.svg" },
    { name: "yelp", path: "yelp.svg" },
    { name: "google my business", path: "google-my-business.svg" },
    { name: "ticketmaster", path: "ticketmaster.svg" },
    { name: "tripadvisor", path: "tripadvisor.svg" },
    { name: "viator", path: "viator.svg" },
    { name: "getyourguide", path: "getyourguide.svg" },
    { name: "mondee marketplace", path: "mondee-marketplace.svg" },
    { name: "walmart", path: "Walmart.svg" },
    { name: "tickpick", path: "tickpick.png" },
    { name: "target", path: "target.png" },
    { name: "magento", path: "magento.svg" },
    { name: "squarespace", path: "squarespace.svg" },
    { name: "bigcommerce", path: "bigcommerce.svg" },
    { name: "aerolopa", path: "aerolopa.png" },
    { name: "airlegit", path: "airlegit.svg" },
    { name: "amadeus discover", path: "amadeus.svg" },
    { name: "cirium", path: "cirrium.svg" },
    { name: "dreamfolks", path: "dream_folks.svg" },
    { name: "google news, reuters", path: "google_news.svg" },
    { name: "googlemap", path: "googlemap.svg" },
    { name: "shipgo", path: "shipgo.svg" },
    { name: "splitwise", path: "splitwise.svg" },
    { name: "tail", path: "tail.svg" },
    { name: "tevo", path: "tevo.svg" },
    { name: "travel boutique", path: "travel_boutique.svg" },
    { name: "wagtail", path: "wagtail.svg" },
    { name: "weatherstack", path: "weatherstack.svg" },
    { name: "entertainment benefits group", path: "ebg.svg" },
    { name: "tbc hotels", path: "tbc_hotels.svg" },
    { name: "hugging face", path: "hugging-face.svg" },
    { name: "kaggle", path: "kaggle.svg" },

    { name: "ola", path: "ola.svg" },
    { name: "redbus", path: "redbus.svg" },
    { name: "abhibus", path: "abhibus.svg" },
    { name: "variflights", path: "variflights.svg" },
    { name: "searce", path: "searce.svg" },
    { name: "tomtom", path: "tomtom.svg" },
    { name: "book my show", path: "book-my-show.svg" },
    { name: "eatapp", path: "eatapp.svg" },
    { name: "g adventures", path: "gadventures.svg" },
    { name: "omega", path: "omega.svg" },
    { name: "open menu", path: "openmenu.svg" },
    { name: "restaurant guru", path: "restaurantguru.svg" },
    { name: "resy", path: "resy.svg" },
    { name: "seven rooms", path: "sevenrooms.svg" },
    { name: "smartvel", path: "smartvel.svg" },
    { name: "stubhub", path: "stubhub.svg" },

    { name: "hotstar", path: "hotstar.svg" },
    { name: "stictravel group", path: "stictravel.svg" },
    { name: "gupshup", path: "gupshup.svg" },
    { name: "netcore", path: "netcore.svg" },
    { name: "infobip", path: "infobip.svg" },
    { name: "abode worldwide", path: "abode-worldwide.svg" },

    { name: "holiday", path: "holiday.svg" },
    { name: "carbon emission", path: "carbon-emission.svg" },
    { name: "award wallet", path: "award-wallet.svg" },
    { name: "fluxir", path: "fluxir.svg" },

    { name: "way", path: "way_com.svg" },
    { name: "airportzo", path: "airportzo.svg" },
    { name: "asego", path: "asego.svg" },
    { name: "iway", path: "iway.svg" },
    { name: "tui", path: "TUI.svg" },
    { name: "finityfun", path: "finityfun.svg" },

    { name: "trip planning", path: "trip-planning.svg" },
    { name: "xhub services", path: "xhub.svg" },
    { name: "rewards loyalty points", path: "rewards-loyalty-points.svg" },
    { name: "booking", path: "booking.svg" },
    { name: "cruise", path: "cruise.svg" },
    { name: "appointment", path: "appointment.svg" },
    { name: "passport", path: "passport.svg" },
    { name: "currency exchange", path: "currency-exchange.svg" },
    { name: "travel documents service", path: "travel-documents.svg" },
    { name: "bopis", path: "bopis.svg" },
    { name: "delivery service", path: "delivery.svg" },
    { name: "price match", path: "price-match.svg" },
    { name: "verify id services", path: "verify-id.svg" },
    { name: "id scanning services", path: "id-scanning.svg" },
    { name: "mondee ai services", path: "mondee-ai-services.png" },
    { name: "trip management services", path: "trip-management-services.png" },
    { name: "travel shopping services", path: "travel-shopping-services.png" },
    { name: "engagement services", path: "engagement-services.png" },
    { name: "fintech services", path: "fintech-services.png" },
    { name: "travel booking services", path: "travel-booking-services.png" },

    { name: "microsoft fabric", path: "microsoft-fabric.svg" },
    { name: "amadeus cars and transfers", path: "amadeus-cars.svg" },
    { name: "mozio", path: "mozio.svg" },
  ];

  const obj = iconList.find(i => integration === i.name);
  iconPath = obj ? obj.path : iconPath;
  return `/img/integrations/${iconPath}`;
}

export function getEngagementImgByType(type) {
  let iconPath = "web-eng.svg";
  const engagementTypeList = [
    { name: engagementType.web, path: "web-eng.svg" },
    { name: engagementType.campaigns, path: "smart-campaigns.svg" },
    { name: engagementType.ads, path: "active-ads.svg" },
    { name: engagementType.social, path: "social-media.svg" },
    { name: engagementType.messaging, path: "messaging-platforms.svg" },
    { name: engagementType.landingPage, path: "landing_page-01.svg" },
    { name: engagementType.promotions, path: "promotions.png" },
    { name: engagementType.voiceBasedAssistant, path: "voice-based-assistant.svg" },
  ];
  const obj = engagementTypeList.find(e => e.name === type);
  iconPath = obj ? obj.path : "web-eng.svg";
  return `img/engagements/${iconPath}`;
}

export function getSetupEngagementImg(type) {
  let imgPath = "setup-WebChatbot.png";
  const engagementTypeList = [
    { name: engagementType.web, path: "setup-WebChatbot.png" },
    { name: engagementType.campaigns, path: "setup-SmartCampaigns.png" },
    { name: engagementType.ads, path: "active-ads.svg" },
    { name: engagementType.social, path: "setup-SocialMedia.png" },
    { name: engagementType.messaging, path: "setup-Messaging.png" },
    { name: engagementType.landingPage, path: "setup-LandingPage.png" },
    { name: engagementType.promotions, path: "promotions.png" },
    { name: engagementType.voiceBasedAssistant, path: "voice-based-assistant.svg" },
  ];
  const obj = engagementTypeList.find(e => e.name === type);
  imgPath = obj ? obj.path : "setup-WebChatbot.png";
  return `img/engagements/${imgPath}`;
}

export function getStudioImgByType(type) {
  let iconPath = "agent-designer.svg";
  const engagementTypeList = [
    { name: "Virtual Agents", path: "agent-designer.svg" },
    { name: "ChatBot Templates", path: "dialog-designer.svg" },
    { name: "Emails", path: "campaign-designer.svg" },
    { name: "Smart Pages", path: "pages-designer.svg" },
    { name: "Forms", path: "forms.png" },
    { name: "Promotions", path: "promotions.png" },
  ];
  const obj = engagementTypeList.find(e => e.name === type);
  iconPath = obj ? obj.path : "web-eng.png";
  return `img/studio/${iconPath}`;
}
export function getEmojiFace(csrating) {
  if (!csrating || csrating == null) {
    csrating = { score: 0, type: "neutral" };
  }
  let emojiPath = "";
  const { score, type } = csrating;
  if (type === "positive") {
    if (score > 2) emojiPath = "excellent.png";
    //Excellent
    else emojiPath = "satisfied.png"; //Satisfied
  } else if (type === "negative") {
    if (score < -2) emojiPath = "very-unsatisfied.png";
    //Very UnSatisfied
    else emojiPath = "unsatisfied.png"; //UnSatisfied
  } else {
    emojiPath = "neutral.png"; //Neutral}
  }
  return `img/visitors/${emojiPath}`;
}
//};
