import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Button, Input, Select } from "../../../../common/components";
import SelectAttribute from "./SelectAttribute";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../../../../common/components/helper";
import { addErrorObj, conditionList, getRandomNumber } from "../../../../utils/GeneralUtils";
import SelectElement from "./SelectElement";
import { getBotIntent } from "../../../../utils/BotUtils";

const ActionDecision = forwardRef((props, ref) => {
  const { attributeList, prevElementStructure, boxes, botElementId, connectors, botVersion, lang } = props;
  const [attributes, setAttributes] = useState([
    {
      attribute1: null,
      condition: null,
      attribute2: null,
      criteria: null,
      errors: { attribute1: null, condition: null, attribute2: null },
    },
  ]);
  const [attributesKey, setAttributesKey] = useState(Math.random());

  const [intentTrueId, setIntentTrueId] = useState(getRandomNumber(6));
  const [intentFalseId, setIntentFalseId] = useState(getRandomNumber(6));
  const [intentTrue, setIntentTrue] = useState(null);
  const [intentFalse, setIntentFalse] = useState(null);

  useEffect(() => {
    if (props.value) {
      setAttributes(
        props.value.attributes.length
          ? addErrorObj(props.value.attributes, { attribute1: null, condition: null, attribute2: null })
          : props.value.attributes
      );
      setAttributesKey(Math.random());
      setIntentTrue(props.value.intentTrue);
      setIntentFalse(props.value.intentFalse);
      setIntentTrueId(props.value.intentTrueId);
      setIntentFalseId(props.value.intentFalseId);
    } else {
      setAttributes([
        {
          attribute1: null,
          condition: null,
          attribute2: null,
          criteria: null,
          errors: { attribute1: null, condition: null, attribute2: null },
        },
      ]);
      setAttributesKey(Math.random());
      setIntentTrue(null);
      setIntentFalse(null);
    }
  }, [props.value]);

  const addAttr = () => {
    setAttributes(prevAttr => {
      const newAttr = cloneDeep(prevAttr);
      newAttr.unshift({
        attribute1: null,
        condition: null,
        attribute2: null,
        criteria: "AND",
        errors: { attribute1: null, condition: null, attribute2: null },
      });
      return newAttr;
    });
    setAttributesKey(Math.random());
  };

  const removeAttr = index => {
    setAttributes(prevAttr => {
      const newAttr = cloneDeep(prevAttr);
      newAttr.splice(index, 1);
      return newAttr;
    });
    setAttributesKey(Math.random());
  };

  const onChangeAttr = (name, value, index) => {
    setAttributes(prevAttr => {
      const newAttr = cloneDeep(prevAttr);
      newAttr[index][name] = value;
      return newAttr;
    });
  };

  const onListValidate = (name, error, index) => {
    setAttributes(prevAttr => {
      const newAttr = cloneDeep(prevAttr);
      newAttr[index]["errors"][name] = error;
      return newAttr;
    });
  };

  const getData = () => {
    let isError = false;
    attributes
      .filter(a => a.attribute1 || a.attribute2 || a.condition)
      .map((a, i) => {
        const list = getFormDetails(
          a,
          (n, e) => onListValidate(n, e, i),
          a.condition === "hasValue" ? "attribute1" : undefined
        );
        if (!list) {
          isError = true;
          return false;
        }
      });
    if (isError) {
      return false;
    }

    const elementTrue = boxes.find(x => x.id === intentTrue);
    const elementFalse = boxes.find(x => x.id === intentFalse);

    attributes.map(x => {
      if (x.condition === "hasValue") x.attribute2 = null;
    });

    const data = {
      intent_true: getBotIntent(elementTrue, botVersion),
      intent_false: getBotIntent(elementFalse, botVersion),
      attributes,
      intentTrueId,
      intentTrue,
      intentFalseId,
      intentFalse,
      qr: [
        {
          id: intentTrueId,
          text: "True",
          connection: intentTrue,
        },
        {
          id: intentFalseId,
          text: "False",
          connection: intentFalse,
        },
      ],
    };
    delete data.attributes[data.attributes.length - 1].criteria;
    if (!attributes.filter(a => a.attribute1 || a.attribute2 || a.condition).length) {
      return { skip: true };
    }
    return data;
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  return (
    <div className={`ele-content bt-decision ${lang.className}`}>
      <label className="name">Conditions</label>
      {attributes.map((obj, index) => {
        return (
          <div key={index + attributesKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
            <div className="input-box">
              <Row>
                <Col md={3}>
                  <Input
                    type="text"
                    placeholder="Value"
                    name="attribute1"
                    title="Value"
                    value={obj.attribute1}
                    isReq={true}
                    error={obj.errors.attribute1}
                    appendIcon={
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={prevElementStructure}
                        onChangeFunc={(value, inputName) => {
                          onChangeAttr(inputName, value, index);
                          onListValidate(inputName, null, index);
                        }}
                        setAttribute={[null, obj.attribute1]}
                      />
                    }
                    onChangeFunc={(name, value) => onChangeAttr(name, value, index)}
                    validationFunc={(n, e) => onListValidate(n, e, index)}
                  />
                </Col>
                <Col md={3}>
                  <Select
                    outerWidth="100%"
                    placeholder="Condition"
                    name="condition"
                    title="Condition"
                    value={obj.condition}
                    isReq={true}
                    error={obj.errors.condition}
                    options={conditionList}
                    labelKey="name"
                    onChangeFunc={(name, value) => onChangeAttr(name, value, index)}
                    validationFunc={(n, e) => onListValidate(n, e, index)}
                  />
                </Col>
                {obj.condition !== "hasValue" && (
                  <Col md={3}>
                    <Input
                      type="text"
                      placeholder="Value"
                      name="attribute2"
                      title="Value"
                      value={obj.attribute2}
                      isReq={obj.condition !== "hasValue"}
                      error={obj.condition !== "hasValue" && obj.errors.attribute2}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={prevElementStructure}
                          onChangeFunc={(value, inputName) => {
                            onChangeAttr(inputName, value, index);
                            onListValidate(inputName, null, index);
                          }}
                          setAttribute={[null, obj.attribute2]}
                        />
                      }
                      onChangeFunc={(name, value) => onChangeAttr(name, value, index)}
                      validationFunc={obj.condition !== "hasValue" ? (n, e) => onListValidate(n, e, index) : undefined}
                    />
                  </Col>
                )}
                <Col md={3}>
                  {index !== attributes.length - 1 && (
                    <Select
                      isClearable={false}
                      placeholder="Criteria"
                      outerClassName="ddl-criteria"
                      name="criteria"
                      value={obj.criteria}
                      options={[
                        { label: "AND", value: "AND" },
                        { label: "OR", value: "OR" },
                      ]}
                      onChangeFunc={(name, value) => onChangeAttr(name, value, index)}
                    />
                  )}
                  <div className="btn-box d-inline-block mt0">
                    {index === 0 ? <Button plusBtn className="d-inline mr10" onClickFunc={() => addAttr()} /> : null}
                    {attributes.filter(x => !x.isDefault).length > 1 ? (
                      <Button minusBtn className="d-inline" onClickFunc={() => removeAttr(index)} />
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      })}
      <div className="clearfix"></div>

      <label className="name mt10 mb10">Node to trigger on</label>
      <Row>
        <Col>
          <label className="mr10">True</label>
          <SelectElement
            outerClassName="d-inline-block mb0"
            name="intentTrue"
            outerWidth={250}
            value={intentTrue}
            boxes={boxes}
            botElementId={botElementId}
            isClearable={true}
            connectors={connectors}
            onChangeFunc={(name, value) => setIntentTrue(value)}
          />
        </Col>
        <Col>
          <label className="mr10">False</label>
          <SelectElement
            outerClassName="d-inline-block mb0"
            name="intentFalse"
            outerWidth={250}
            value={intentFalse}
            boxes={boxes}
            botElementId={botElementId}
            isClearable={true}
            connectors={connectors}
            onChangeFunc={(name, value) => setIntentFalse(value)}
          />
        </Col>
      </Row>
    </div>
  );
});

ActionDecision.defaultProps = {
  lang: {
    className: "",
  },
};

ActionDecision.propTypes = {
  lang: PropTypes.object,
};

export default ActionDecision;
