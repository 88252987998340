import { userLogout } from "./GeneralUtils";

export const sessionMerchantId = () => {
  return sessionStorage.getItem("MerchantId");
};

export const sessionAccessToken = () => {
  return sessionStorage.getItem("access_token");
};

export const sessionUser = () => {
  return sessionStorage.getItem("saveUser");
};

export const sessionEmail = () => {
  return sessionStorage.getItem("email");
};

export const sessionUserTemp = () => {
  return sessionStorage.getItem("saveUserTemp");
};

export const sessionUserId = () => {
  return sessionStorage.getItem("id");
};

export const sessionFirstName = () => {
  return sessionStorage.getItem("firstName");
};

export const sessionLastName = () => {
  return sessionStorage.getItem("lastName");
};

export const sessionFullName = () => {
  const fName = sessionStorage.getItem("firstName");
  const lName = sessionStorage.getItem("lastName");
  return [fName, lName].filter(Boolean).join(" ");
};

export const sessionAuthType = () => {
  return sessionStorage.getItem("authType");
};

export const sessionExpiresAt = () => {
  return sessionStorage.getItem("expires_at");
};

export const sessionRefreshToken = () => {
  return sessionStorage.getItem("refresh_token");
};

export const sessionBusinessType = () => {
  return sessionStorage.getItem("businessType");
};

export const sessionRole = () => {
  return sessionStorage.getItem("Role");
};

export const sessionImageUrl = () => {
  return sessionStorage.getItem("imageUrl");
};

export const sessionTimeZone = () => {
  return sessionStorage.getItem("timeZone");
};

export const sessionCLogin = () => {
  return sessionStorage.getItem("cLogin");
};

export const sessionStayAlive = () => {
  return (sessionStorage.getItem("stayAlive") || "0") === "1";
};

export const sessionIsBusinessView = type => {
  return !sessionStorage.getItem("isBusinessView") && type !== "flowbot"
    ? true
    : sessionStorage.getItem("isBusinessView") === "true";
};

export const sessionClear = () => {
  userLogout();
  const cLogin = sessionCLogin();
  if (sessionStorage) sessionStorage.clear();
  if (localStorage) localStorage.clear();
  if (cLogin && sessionStorage) {
    sessionStorage.setItem("cLogin", cLogin);
  }
};
