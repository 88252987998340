import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Label, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Button, Input, TextArea, Select, CKEditor } from "../../../../common/components";
import ImageUpload from "../../../../common/components/ImageUpload.js";
import CommonUtils from "../../../../utils/CommonUtils";
import { getAllQueryParams, getStatus, statusList, isArray } from "../../../../utils/GeneralUtils";
import LanguageSelection from "./LanguageSelection";
import { botTypeList, botAccessList } from "../../../../utils/ConstUtils";
import { cloneDeep } from "lodash";
import { sessionMerchantId } from "../../../../utils/SessionUtils";

class BotDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiLoading: false,
      channelList: [],
      botName: "",
      description: "",
      features: "",
      botImage1: "",
      botImage2: "",
      channelId: 11,
      endpoint: "",
      merchantId: "",
      botType: props.botType || "",
      triggerBotList: props.triggerBots.map(t => ({
        oldBotName: t,
        oldBotDisplayName: t.split("_").join(" "),
        newBotName: "",
      })),
      entityList: [],
      merchantEntityLoading: false,
      merchantEntityList: [],
      botAccess: "Private",
      status: getStatus(),
      businessType: "",
      accessToken: "",
      selectedLang: props.selectedLanguages,
      defaultLang: props.defaultLanguage,
    };
    this.isInternal = getAllQueryParams(window.location.search).internal === "true";
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchEntities = this.fetchEntities.bind(this);
    this.handleTriggerBotChange = this.handleTriggerBotChange.bind(this);
    this.handleEntityChange = this.handleEntityChange.bind(this);
  }

  componentDidMount() {
    this.getChannelList();
    if (this.props.botData) {
      const { title, description, features, botImage1, botImage2, type, botAccess, status } = this.props.botData;
      this.setState({
        botName: title,
        description,
        botImage1,
        botImage2,
        botType: type,
        botAccess,
        status,
        features,
      });
    }

    this.loadEntities();
  }

  loadEntities() {
    if (!isArray(this.props.entityIds) || !this.isInternal) {
      return;
    }

    CommonUtils.getEntityByMerchantId(sessionMerchantId()).then(res => {
      if (res.error || !isArray(res.entities)) {
        return;
      }
      const list = res.entities
        .filter(x => this.props.entityIds.includes(x.entityId))
        .map(x => ({ oldEntityName: x.name, oldEntityId: x.entityId, newEntityId: null }));
      this.props.entityIds.map(e => {
        if (!list.map(x => x.oldEntityId).includes(e)) {
          list.push({ oldEntityName: e, oldEntityId: e, newEntityId: null });
        }
      });
      this.setState({ entityList: list });
    });
  }

  fetchEntities() {
    const { merchantId, endpoint, accessToken } = this.state;
    this.setState({ merchantEntityLoading: true });
    CommonUtils.getEntityByMerchantIdEnv(merchantId, endpoint, accessToken).then(res => {
      if (res.error || !isArray(res.entities)) {
        this.setState({ merchantEntityLoading: false });
        return;
      }
      this.setState({
        merchantEntityLoading: false,
        merchantEntityList: res.entities.map(x => ({ label: x.name, value: x.entityId })),
      });
    });
  }

  handleTriggerBotChange(name, value) {
    this.setState(prevState => ({
      triggerBotList: prevState.triggerBotList.map(item => {
        if (item.oldBotName === name) {
          item.newBotName = value;
        }
        return item;
      }),
    }));
  }

  handleEntityChange(name, value) {
    this.setState(prevState => ({
      entityList: prevState.entityList.map(item => {
        if (item.oldEntityId === name) {
          item.newEntityId = value;
        }
        return item;
      }),
    }));
  }

  getChannelList() {
    CommonUtils.getAllChannels().then(response => {
      if (response.error) {
        this.setState({ channelList: [] });
        return false;
      }
      this.setState({ channelList: response });
    });
  }

  handleSubmit() {
    const {
      botName,
      description,
      features,
      botImage1,
      botImage2,
      channelId,
      endpoint,
      merchantId,
      businessType,
      accessToken,
      selectedLang,
      defaultLang,
      botType,
      botAccess,
      status,
      triggerBotList,
      entityList,
    } = this.state;
    const { cloneBotName, botData } = this.props;
    let obj = {
      botName,
      type: botType,
      botAccess,
      botId: botName.split(" ").join("_"),
      description,
      botImage1,
      botImage2,
      channelId,
      status,
      features,
      endpoint,
      merchantId,
      businessType,
      accessToken,
      selectedLang,
      defaultLang,
    };
    if (botData) {
      obj = {
        ...botData,
        description,
        botImage1,
        botImage2,
        type: botType,
        botAccess,
        channelId,
        status,
        features,
      };
    }
    this.setState({ apiLoading: true });
    const botList = [obj];
    const cloneItems = {
      botList: [
        {
          old: cloneBotName,
          new: botName.split(" ").join("_"),
        },
      ],
      entityList: [],
    };

    triggerBotList
      .filter(item => item.newBotName.trim())
      .map(item => {
        const newBotName = item.newBotName.trim();
        const cloneObj = cloneDeep(obj);
        cloneObj.botName = newBotName;
        cloneObj.botId = newBotName.split(" ").join("_");
        cloneObj.description = `${obj.description} - ${newBotName}`;
        cloneObj.cloneBotName = item.oldBotName;
        botList.push(cloneObj);
        cloneItems.botList.push({
          old: item.oldBotName,
          new: newBotName.split(" ").join("_"),
        });
      });

    entityList
      .filter(item => item.newEntityId)
      .map(item => {
        cloneItems.entityList.push({
          old: item.oldEntityId,
          new: item.newEntityId,
        });
      });

    this.props.onSubmit(botList, cloneItems);
  }

  handleLangChange(name, value) {
    name === "selectedLanguages"
      ? this.setState({ selectedLang: value || "" })
      : this.setState({ defaultLang: value || "" });
  }

  renderEntites() {
    const { entityList, merchantEntityLoading, merchantEntityList } = this.state;
    if (!isArray(entityList)) return null;

    return (
      <React.Fragment>
        <h5>
          Entities{" "}
          <i
            className={`cursor-pointer fa fa-refresh ml-1${merchantEntityLoading ? " fa-spin" : ""}`}
            onClick={this.fetchEntities}
          />
        </h5>
        {entityList.map((entity, i) => {
          return (
            <div key={i} className="mt-3">
              <Label className="text-right">{entity.oldEntityName}</Label>
              <Select
                title="Entity"
                name={entity.oldEntityId}
                placeholder="Select Entity"
                value={entity.newEntityId}
                isLoading={merchantEntityLoading}
                options={merchantEntityList}
                onChangeFunc={this.handleEntityChange}
              />
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  renderTriggerBots() {
    const { triggerBotList } = this.state;
    if (!isArray(triggerBotList)) return null;

    return (
      <React.Fragment>
        <h5>Trigger Path Bots ({triggerBotList.length})</h5>
        {triggerBotList.map((triggerBot, i) => {
          return (
            <div key={i} className="mt-3">
              <Label className="text-right">{triggerBot.oldBotDisplayName}</Label>
              <Input
                name={triggerBot.oldBotName}
                title="Bot Name"
                placeholder="Enter Bot Name"
                value={triggerBot.newBotName}
                onChangeFunc={this.handleTriggerBotChange}
              />
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  renderMerchantTransfer() {
    const { endpoint, merchantId, businessType, accessToken } = this.state;
    if (!this.isInternal) return null;

    return (
      <React.Fragment>
        <Row className="mt-3">
          <Col md="3 text-right">
            <Label className="text-right">End Point</Label>
          </Col>
          <Col>
            <Input
              name="endpoint"
              title="End Point"
              placeholder="Enter End Point"
              value={endpoint}
              onChangeFunc={(name, value) => {
                this.setState({ [name]: value });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3 text-right">
            <Label className="text-right">Merchant Id</Label>
          </Col>
          <Col>
            <Input
              name="merchantId"
              title="Merchant Id"
              placeholder="Enter Merchant Id"
              value={merchantId}
              onChangeFunc={(name, value) => {
                this.setState({ [name]: value });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3 text-right">
            <Label className="text-right">Business Type</Label>
          </Col>
          <Col>
            <Input
              name="businessType"
              title="Business Type"
              placeholder="Enter Business Type"
              value={businessType}
              onChangeFunc={(name, value) => {
                this.setState({ [name]: value });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3 text-right">
            <Label className="text-right">Access Token</Label>
          </Col>
          <Col>
            <Input
              name="accessToken"
              title="Access Token"
              placeholder="Enter Access Token"
              value={accessToken}
              onChangeFunc={(name, value) => {
                this.setState({ [name]: value });
              }}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const {
      apiLoading,
      channelList,
      channelId,
      description,
      features,
      botName,
      botType,
      botAccess,
      botImage1,
      botImage2,
      selectedLang,
      defaultLang,
      status,
      triggerBotList,
      entityList,
    } = this.state;
    const { title, showLang, botData } = this.props;
    const loadMoreData = isArray(triggerBotList) || isArray(entityList);
    return (
      <div className="bot-details">
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={loadMoreData ? "8" : "12"} className={loadMoreData ? "bd-left-container" : ""}>
              <Row>
                <Col md="3 text-right">
                  <Label className="text-right" htmlFor="Bot name">
                    Bot Name
                  </Label>
                </Col>
                <Col>
                  <Input
                    name="botName"
                    title="Bot name"
                    placeholder="Enter bot name"
                    value={botName}
                    disabled={botData ? true : false}
                    onChangeFunc={(name, value) => {
                      this.setState({ [name]: value });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3 text-right">
                  <Label className="text-right" htmlFor="Description">
                    Description
                  </Label>
                </Col>
                <Col>
                  <TextArea
                    name="description"
                    title="Description"
                    placeholder="Enter description"
                    value={description}
                    rows={2}
                    onChangeFunc={(name, value) => this.setState({ [name]: value })}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="3 text-right">
                  <Label className="text-right" htmlFor="channel">
                    Bot Type
                  </Label>
                </Col>
                <Col>
                  <Select
                    title="botType"
                    name="botType"
                    value={botType}
                    options={botTypeList}
                    onChangeFunc={(name, value) => this.setState({ [name]: value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3 text-right">
                  <Label className="text-right" htmlFor="channel">
                    Bot Access
                  </Label>
                </Col>
                <Col>
                  <Select
                    title="botAccess"
                    name="botAccess"
                    value={botAccess}
                    options={botAccessList}
                    onChangeFunc={(name, value) => this.setState({ [name]: value })}
                  />
                </Col>
              </Row>
              {/* <Row>
              <Col md="3 text-right">
                <Label className='text-right' htmlFor="channel">Channel</Label>
              </Col>
              <Col>
                <Select
                  title="Channel"
                  valueKey="id"
                  labelKey="name"
                  name="channelId"
                  value={channelId}
                  options={channelList}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              </Col>
            </Row> */}
              {showLang && !botData && (
                <LanguageSelection
                  selectedLanguages={selectedLang}
                  defaultLanguage={defaultLang}
                  onChange={(name, value) => this.handleLangChange(name, value)}
                />
              )}
              <Row>
                <Col md="3 text-right">
                  <Label className="text-right" htmlFor="botImage">
                    Bot image
                  </Label>
                </Col>
                <Col md="9">
                  <div className="image mr10">
                    <ImageUpload
                      isSmall={true}
                      imagePath={botImage1}
                      imgWidth="100px"
                      imgHeight="100px"
                      defaultImg=""
                      getImagePath={path => this.setState({ botImage1: path })}
                    />
                  </div>
                  <div className="image">
                    <ImageUpload
                      isSmall={true}
                      imagePath={botImage2}
                      imgWidth="100px"
                      imgHeight="100px"
                      defaultImg=""
                      getImagePath={path => this.setState({ botImage2: path })}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="3 text-right">
                  <Label className="text-right" htmlFor="status">
                    Status
                  </Label>
                </Col>
                <Col>
                  <Select
                    title="status"
                    name="status"
                    value={status}
                    options={statusList}
                    onChangeFunc={(name, value) => this.setState({ [name]: value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3 text-right">
                  <Label className="text-right" htmlFor="features">
                    Features
                  </Label>
                </Col>
                <Col>
                  {/* <CKEditor
                  content={features}
                  onChangeFunc={val => this.setState({ 'features': val })}
                /> */}
                  <TextArea
                    name="features"
                    title="Features"
                    placeholder="Enter features"
                    value={features}
                    rows={2}
                    onChangeFunc={(name, value) => this.setState({ [name]: value })}
                  />
                </Col>
              </Row>
              {this.renderMerchantTransfer()}
            </Col>
            {loadMoreData ? (
              <React.Fragment>
                <Col md="4">
                  {this.renderTriggerBots()}
                  {this.renderEntites()}
                </Col>
              </React.Fragment>
            ) : null}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button submitBtn text="Submit" loading={apiLoading} onClickFunc={this.handleSubmit} />
          <Button cancelBtn text="Cancel" onClickFunc={this.props.onCancel} />
        </ModalFooter>
      </div>
    );
  }
}

BotDetails.defaultProps = {
  title: "Bot Details",
  showLang: true,
  triggerBots: [],
};

BotDetails.propTypes = {
  title: PropTypes.string,
  showLang: PropTypes.bool,
  triggerBots: PropTypes.array,
};

export default BotDetails;
