export const menuType = {
  dashboard: "dashboard",
  visitor: "visitors",
  visitorTabInfo: "visitors-info",
  visitorTabOnlineVisitor: "visitors-online-visitors",
  visitorTabAiAgent: "visitors-ai-agents",
  visitorTabTracking: "visitors-tracking",
  visitorTabUserAgent: "visitors-user-agents",
  visitorTabMessageCenter: "visitors-message-center",
  visitorTabVisitHistory: "visitors-visit-history",
  engagement: "engagements",
  engagementTabInfo: "engagements-info",
  engagementTabMyEngagement: "engagements-my-engagement",
  engagementTabRouting: "engagements-routing",
  engagementTabSegmentation: "engagements-segmentation",
  engagementTabDataExtraction: "engagements-data-extraction",
  engagementTabScoring: "engagements-scoring",
  automation: "automation",
  automationTabRules: "automation-rules",
  automationTabFlowbots: "automation-flowbots",
  livechat: "livechat",
  studio: "designer",
  studioTabStudio: "designer-studio",
  studioTabVirtualAgent: "designer-virtual-agents",
  studioTabChatBotTemplate: "designer-chatbot-templates",
  studioTabEmails: "designer-emails",
  studioTabLandingPage: "designer-landing-page",
  studioTabForm: "designer-forms",
  studioTabPromotion: "designer-promotions",
  studioTabBotBuilder: "designer-bot-builder",
  knowledgeBase: "knowledge",
  knowledgeBaseTabTraning: "knowledge-training",
  knowledgeBaseTabKnwBase: "knowledge-knowledge-base",
  knowledgeBaseTabDocument: "knowledge-document",
  knowledgeBaseTabIntent: "knowledge-intent",
  knowledgeBaseSkillMgmt: "knowledge-skill-management",
  knowledgeBaseTabEntity: "knowledge-Entity",
  knowledgeBaseTabFAQ: "knowledge-FAQ",
  contact: "contacts",
  contactTabAllContacts: "contacts-allContacts",
  contactTabAllList: "contacts-allList",
  contactTabImportContacts: "contacts-import-contacts",
  lead: "leads",
  leadTabLead: "leads-leads",
  leadTabProspect: "leads-prospects",
  leadTabMarket: "leads-market",
  leadTabSale: "leads-sales",
  leadTabLostOpportunite: "leads-opportunities",
  leadTabCustomer: "leads-customers",
  leadTabNotQualified: "leads-dnq",
  analytics: "analytics",
  analyticsTabChannel: "analytics-channel",
  analyticsTabEngagement: "analytics-engagement",
  product: "business",
  productTabCatalog: "business-product-catalog",
  productTabPromotions: "business-product-promotions",
  productTabDetails: "business-product-detail",
  productTabBulkupload: "business-bulk-upload",
  fulfilment: "fulfilment",
  fulfilmentTabOrder: "fulfilment-order",
  fulfilmentTabOrderDetails: "fulfilment-order-details",
  fulfilmentTabPayment: "fulfilment-payment",
  fulfilmentTabCalendar: "fulfilment-calender",
  fulfilmentTabCustomer: "fulfilment-customer",
  fulfilmentTabAppointment: "fulfilment-appointment",
  report: "reports",
  requestReport: "request-report",
  reportTabLeadReport: "reports-lead-reports",
  reportTabActivityReport: "reports-activity-reports",
  reportTabChannelReport: "reports-channel-reports",
  reportTabProductReport: "reports-product-reports",
  reportTabScheduleReport: "reports-schedule-reports",
  setting: "settings",
  settingTabInfo: "settings-account-info-tab",
  settingTabAccountInfo: "settings-account-information",
  settingTabBranding: "settings-branding",
  settingTabCalendar: "settings-calendar",
  settingTabDepartment: "settings-departments",
  settingTabRoleTeamMember: "settings-roles-team-members",
  settingTabAccessManagement: "settings-access-management",
  settingTabPaymentTax: "settings-payment-tax",
  settingTabBilling: "settings-billing",
  settingTabBotSetting: "settings-bot-settings",
  settingTabAiConfig: "settings-ai-configuration",
  settingTabCustomConfig: "settings-custom-configuration",
  settingTabChannel: "settings-channels",
  settingTabIntegration: "settings-integrations",
  settingTabUnsubscribe: "settings-unsubscribe",
  settingTabSecurity: "settings-security",
  settingTabTags: "settings-tags",
  messageCenter: "message-center",
  // messageCenterUnansweredMessages:'message-center-unanswered-mssages',
  // messageCenterReviewResponse:'message-center-review-response',
  // messageCenterBroadcastMessage:'message-center-broadcast-message'
};
