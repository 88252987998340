import React from "react";
import { Select } from "../../../../common/components";

class SelectElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevElementOpt: [],
    };
  }

  componentDidMount() {
    const { boxes, name, onChangeFunc, value, botElementId, connectors, defaultSelected } = this.props;
    const prevElementOpt = [];
    let prevElement = value;
    boxes
      .filter(b => b.typeData && b.id !== botElementId)
      .map((b, i) => {
        if (b.typeData) {
          const ddLabel = (
            <span>
              {b.typeData.image ? (
                <img height={20} className="mr-2" src={b.typeData.image.src} />
              ) : (
                <i
                  className="fa fa-circle-o mr-2 ml-1"
                  style={{ color: "purple", height: 25, width: 14, paddingTop: 6, fontWeight: "bold" }}
                ></i>
              )}
              {b.typeData.label || b.typeData.name}
            </span>
          );
          prevElementOpt.push({ label: ddLabel, value: b.id, labelKey: b.typeData.label || b.typeData.name });
        }
      });
    if (botElementId !== 0) {
      boxes.map((el, i) => {
        if (el.id === botElementId) {
          const boxConnector = connectors.filter(b => b.ep == botElementId).find(c => boxes.find(b => b.id === c.sp));
          if (boxConnector) {
            prevElement = boxConnector.sp;
          }
          const elementIndex = prevElementOpt.findIndex(x => x.vlaue === botElementId);
          if (elementIndex !== -1) {
            prevElementOpt.splice(elementIndex, 1);
          }
        }
      });
    }
    if (defaultSelected && prevElementOpt.length > 0 && value === undefined && botElementId === 0) {
      prevElement = prevElementOpt[prevElementOpt.length - 1].value;
    }
    this.setState({ prevElementOpt });
    onChangeFunc(name, prevElement);
  }

  format(text) {
    return text.length >= 20 ? text.substr(0, 20).concat("...") : text;
  }

  render() {
    const { prevElementOpt } = this.state;
    const {
      name,
      placeholder,
      value,
      outerClassName,
      outerWidth,
      onChangeFunc,
      smallSize,
      height,
      menuPlacement,
      isDisabled,
      isClearable,
      validationFunc,
      isReq,
      title,
      error,
    } = this.props;

    return (
      <Select
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        outerClassName={outerClassName}
        outerWidth={outerWidth}
        options={prevElementOpt}
        value={value}
        name={name}
        isDisabled={isDisabled}
        isClearable={isClearable}
        smallSize={smallSize}
        height={height}
        onChangeFunc={onChangeFunc}
        title={title}
        error={error}
        isReq={isReq}
        validationFunc={validationFunc}
        filterOptionFunc={(option, inputValue) => {
          return (option.data.labelKey || "").toLowerCase().includes((inputValue || "").toLowerCase());
        }}
      />
    );
  }
}

SelectElement.defaultProps = {
  name: "prevElement",
  placeholder: "Select Previous Element",
  outerWidth: 300,
  outerClassName: "mb0",
  defaultSelected: true,
  menuPlacement: "auto",
};

export default SelectElement;
