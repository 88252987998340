import React from "react";
import { TextArea, ScrollBox, TextEditor, Input } from "../../../common/components";
import { getAttrByPrevElement, loadBotPayload, loadDefaultBotPayload, updateBotPayload } from "../../../utils/BotUtils";
import {
  SelectElement,
  SelectIntent,
  SelectAttribute,
  ElementForm,
  SubmitElement,
  AdvanceOptionsExpand,
} from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { keys } from "lodash";

const LANG_STRUCTURE = {
  text: "",
};

class SendMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageTarget: 0,
      value: "",
      text: "",
      activity: false,
      isRich: false,
      richPosition: null,
      intent: null,
      step: 1,
      nextIntent: "",
      prevElementStructure: [],
      errors: {
        text: null,
      },
      langPayload: loadDefaultBotPayload(LANG_STRUCTURE, props.lang),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onAdvanceOptionChange = this.onAdvanceOptionChange.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId, lang } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { text, isRich, intent, nextIntent, activity = false } = box.payload;
      let obj = {
        text,
        isRich: isRich,
        intent: intent || null,
        nextIntent,
        activity,
        ...loadBotPayload(LANG_STRUCTURE, box.payload, this.state, lang),
      };
      this.setState(obj, () => this.updatePayload());
    }
  }

  updatePayload(prevLang = this.props.lang, callback = () => {}) {
    const props = {
      boxes: this.props.boxes,
      botVersion: this.props.botVersion,
    };
    const langPayload = updateBotPayload(LANG_STRUCTURE, this.state, prevLang, props);
    this.setState(
      {
        langPayload,
        ...langPayload[this.props.lang.selectedLang],
      },
      callback
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang.selectedLang !== prevProps.lang.selectedLang) {
      this.updatePayload(prevProps.lang);
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  onAdvanceOptionChange(name, value, err, obj, e) {
    if (["activity"].includes(name)) {
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleStep(step, isSubmit = false, prevLang) {
    const obj = getFormDetails(this.state, this.onInputValidate);
    if (!obj) return false;

    if (isSubmit) step--;

    this.setState({ step }, () => this.updatePayload(prevLang, isSubmit ? this.handleSubmit : null));
  }

  handleSubmit() {
    const { prevElement, intent, isRich, nextIntent, langPayload, activity } = this.state;
    const { handleSubmitElement, lang } = this.props;

    const submitCb = () => {
      const langDataObj = {};
      const langPayloadObj = {};

      keys(langPayload).map(x => {
        const { text } = langPayload[x];

        langDataObj[x] = {
          text,
          next_intent: nextIntent,
        };

        langPayloadObj[x] = {
          text: text,
        };
      });

      return { langDataObj, langPayloadObj };
    };

    const { langDataObj, langPayloadObj } = submitCb();
    const { text } = langPayloadObj[lang.defaultLang];

    const payload = {
      intent,
      isRich,
      text,
      activity,
      nextIntent,
      langPayload: langPayloadObj,
    };

    handleSubmitElement(langDataObj[lang.defaultLang], prevElement, payload, langDataObj);
  }

  renderStep1() {
    const { text, prevElement, messageTarget, prevElementStructure, isRich, richPosition, errors, activity } =
      this.state;
    const { boxes, botElementId, connectors, attributeList, lang } = this.props;
    return (
      <div className="ele-content">
        <label>
          Enter your messsage to be send here, Use curly barces to refer to user inputs or parameters. For example -{" "}
          {"{{ First Name }}"}
        </label>
        <div className="b-form mt10">
          <ElementForm title="Message">
            <div className="position-relative">
              {isRich ? (
                <TextEditor
                  title="Message"
                  name="text"
                  value={text}
                  isReq={true}
                  error={errors.text}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={(value, inputName) =>
                        this.setState({ text: value }, () => {
                          this.onInputValidate(inputName, null);
                        })
                      }
                      setAttribute={[messageTarget, text, isRich, richPosition]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value, position) => this.setState({ [name]: value, richPosition: position })}
                />
              ) : (
                <TextArea
                  title="Message"
                  value={text}
                  placeholder="Enter text message"
                  name="text"
                  isReq={true}
                  error={errors.text}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={(value, inputName) =>
                        this.setState({ text: value }, () => {
                          this.onInputValidate(inputName, null);
                        })
                      }
                      setAttribute={[messageTarget, text, isRich, richPosition]}
                    />
                  }
                  validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value, e) => this.setState({ [name]: value, messageTarget: e.target })}
                />
              )}
            </div>
            <label className={`mb0 ${lang.className}`} style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>
          <ElementForm rowClassName={lang.className} prevElement>
            <SelectElement
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
        </div>
        <div className="b-form">
          <AdvanceOptionsExpand
            activity={activity}
            onChangeFunc={this.onAdvanceOptionChange}
            displayList={["activity"]}
          />
        </div>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId, lang } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
          lang={lang}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId, lang, isSubmitLoader } = this.props;

    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-send-msg" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={() => this.updatePayload(lang, this.handleSubmit)}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
          lang={lang}
          isSubmitLoader={isSubmitLoader}
        />
      </React.Fragment>
    );
  }
}

export default SendMessage;
