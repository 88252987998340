import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { Button } from "../../common/components";
// import { withRouter } from "../../withRouter";
import FooterContent from "../../components/FooterContent";
import { getCLoginMerchant, logoutApp } from "../../utils/GeneralUtils";
import { sessionCLogin } from "../../utils/SessionUtils";
import { withRouter } from "../../withRouter";

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mId: null,
    };
  }

  componentDidMount() {
    this.setState({ mId: sessionCLogin() });
  }

  render() {
    const supportMessage = this.props.location.state;
    const { mId } = this.state;
    return (
      <div className="login-v1">
        <div className="content m-0">
          <div className="box c-box">
            <Card className="m-box border-0 m-0">
              <div className="mlogo">
                <img src={getCLoginMerchant(mId)} height="100%" />
              </div>
              <CardBody className={`text-gray-700 ${!mId ? "pb-0" : ""}`}>
                <div className="mb-4 vc-fw-600 fs21 text-center">
                  {supportMessage && supportMessage.supportMsg
                    ? supportMessage.supportMsg
                    : "Can't access your account?"}
                </div>
                <h5 className="text-center m-box-desc vc-fw-600 fs17">Need Support?</h5>
                <p className="mt-2 text-center text-muted">
                  Please send us an email at{" "}
                  <a className="email-link" href="mailto:support@purplegrids.com">
                    support@purplegrids.com
                  </a>{" "}
                  <br /> describing your facing issues so we can assist you.
                </p>
                <div className="mt-4 text-center">
                  <Button
                    text="Back to home"
                    linkBtn
                    className="px-1 vc-fw-600 fs13"
                    customIcon="fa-thin fa-arrow-left"
                    onClickFunc={() => {
                      logoutApp(this.props.navigate);
                    }}
                  />
                </div>
                {!mId && (
                  <div className="footer text-muted mt-5">
                    <FooterContent />
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default withRouter(Support);
