import React, { useState, useEffect, useCallback } from "react";
// import { withRouter }  from "react-router-dom";
import { useDispatch } from "react-redux";
import { Card, CardBody, Label } from "reactstrap";
import { generateCodeChallenge, getAllQueryParams, getRandomNumber, logoutApp } from "../../utils/GeneralUtils";
import { Button, Input } from "../../common/components";
import { AAD_CLIENT_ID, BRAND_LOGO_URL } from "../../utils/Config";
import CommonUtils from "../../utils/CommonUtils";
import FooterContent from "../../components/FooterContent";
import { handlePopup } from "../../actions";
import { redirectToAccount } from "../../utils/LoginUtil";
import { withRouter } from "../../withRouter";
import { useLocation, useNavigate } from "react-router";

const AADLogin = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlePopupDispatch = useCallback(data => dispatch(handlePopup(data)), [dispatch]);

  const redirectUri = `${window.location.origin}/aadlogin`;
  const [email, setEmail] = useState(sessionStorage.getItem("adEmail") || "");
  const [errorMsg, setErrorMsg] = useState("");
  const [apiLogin, setApiLogin] = useState(false);
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    const searchObj = new URLSearchParams(window.location.search);

    const signupEmail = searchObj.get("user");
    if (props.isSignup && signupEmail && !email) {
      setEmail(signupEmail);
    }

    const isError = searchObj.get("error");
    if (isError) {
      setErrorMsg("Sorry, something went wrong: Please try again later.");
    }
  }, []);

  useEffect(() => {
    const getAuthInfo = async () => {
      setApiLogin(true);
      const csrfToken = sessionStorage.getItem("csrfToken");
      const verifier = sessionStorage.getItem("verifier");

      const data = {
        authType: "AZURE_AD",
        verifier,
        csrfToken,
      };

      const response = await CommonUtils.authToken(data, window.location.search);
      if (response.error) {
        setApiLogin(false);
        setErrorMsg(response.data.error);
        clearData();
        return false;
      }

      if (response.twoFA.isTwoFactorEnabled && response.twoFA.isInvalid) {
        navigate("/support");
        return;
      }

      sessionStorage.setItem("saveUserTemp", response.user.username);
      sessionStorage.setItem("access_token", response.token.access_token);
      sessionStorage.setItem("expires_at", Math.floor(Date.now() / 1000) + response.token.expires_in);
      sessionStorage.setItem("refresh_token", response.token.refresh_token);
      sessionStorage.setItem("stayAlive", "1");

      sessionStorage.setItem("id", response.user.id);
      sessionStorage.setItem("Role", response.user.role);
      sessionStorage.setItem("MerchantId", response.user.merchant.merchantId);
      sessionStorage.setItem("firstName", response.user.firstName || "");
      sessionStorage.setItem("lastName", response.user.lastName || "");
      sessionStorage.setItem("imageUrl", response.user.imageUrl || "");
      sessionStorage.setItem("email", response.user.email || "");
      sessionStorage.setItem("authType", response.user.authType || "AZURE_AD");

      if (response.twoFA.isTwoFactorEnabled) {
        navigate("/verify", { state: { user: response.user } });
        return;
      }

      if (response.merchant && response.merchant.type)
        sessionStorage.setItem("businessType", response.merchant.type || "");
      if (response.merchant && response.merchant.timeZone)
        sessionStorage.setItem("timeZone", response.merchant.timeZone || "");
      if (response.merchant && response.merchant.settings)
        localStorage.setItem("wdb", response.merchant.settings.widgetDisableBranding ? "1" : "0");
      clearData();
      redirectToAccount(props);
    };

    const searchCode = new URLSearchParams(window.location.search).get("code");
    const searchError = new URLSearchParams(window.location.search).get("error");
    const storageUrl = sessionStorage.getItem("redirectURL");
    const storageAdEmail = sessionStorage.getItem("adEmail");

    if (storageAdEmail && searchCode) {
      getAuthInfo();
    } else if (storageAdEmail && storageUrl && searchError) {
      const url = `${storageUrl}${storageUrl.includes("?") ? "&" : "?"}error=${searchError}`;
      navigate(url);
      clearData();
    } else {
      clearData();
    }
  }, []);

  const clearData = () => {
    sessionStorage.removeItem("verifier");
    sessionStorage.removeItem("csrfToken");
    sessionStorage.removeItem("adEmail");
    sessionStorage.removeItem("redirectURL");
    if (new URLSearchParams(window.location.search).get("code")) {
      navigate("/aadlogin");
    }
  };

  const handleLogin = async e => {
    e.preventDefault();

    if (!email || emailError) {
      if (!emailError) setEmailError("Please enter Email Address.");
      handlePopupDispatch({ isError: true, message: "Please enter required information." });
      return false;
    }

    setApiLogin(true);
    const tenantId = email.split("@")[1] || "common";
    const csrfToken = getRandomNumber();

    let stateStr = `e=${email}&&ct=${csrfToken}`;
    let obj = getAllQueryParams(window.location.search);
    if (obj.user && obj.secureToken) {
      stateStr += `&&st=${obj.secureToken}`;
    }
    const state = btoa(stateStr);
    const verifier = getRandomNumber();
    const challenge = await generateCodeChallenge(verifier);

    const endpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${AAD_CLIENT_ID}&
          response_type=code&redirect_uri=${redirectUri}&scope=User.Read&state=${state}&code_challenge=${challenge}&code_challenge_method=S256&prompt=select_account`;
    sessionStorage.setItem("verifier", verifier);
    sessionStorage.setItem("csrfToken", csrfToken);
    sessionStorage.setItem("adEmail", email);
    sessionStorage.setItem("redirectURL", `${location.pathname}${location.search.split("&error")[0]}`);
    window.location = endpoint;
  };

  return (
    <div className="login-v1">
      <div className="content m-0">
        <div className="box c-box">
          <Card className="m-box border-0 m-0">
            <div className="mlogo">
              <img src={BRAND_LOGO_URL} height="100%" />
            </div>
            <CardBody className="text-gray-700 pb-0">
              <form onSubmit={handleLogin}>
                <div className="vc-fw-600 fs21 text-center">
                  {props.isSignup ? "Sign up" : "Sign in"} with Microsoft
                </div>
                <p className="mb-4 text-center vc-secondary fs13">Please enter your details</p>
                <label className="vc-fw-600 d-flex align-items-center">
                  <img src="./img/microsoft.png" width={20} className="mr-2" />
                  Email
                </label>
                <Input
                  outerClassName="mb0"
                  title="Email"
                  name="email"
                  value={email}
                  placeholder="Enter your email"
                  onChangeFunc={(_, value) => setEmail(value)}
                  autoComplete="email"
                  isReq={true}
                  reqType="email"
                  error={emailError}
                  validationFunc={(_, err) => setEmailError(err)}
                  disabled={sessionStorage.getItem("adEmail") || props.isSignup ? true : false}
                />

                <Button
                  text="Continue"
                  submitBtn
                  type="submit"
                  className="px-4 w-100 mt-3"
                  style={{ height: 40 }}
                  size="normal"
                  displayIcon={false}
                  loading={apiLogin}
                  loadingText="Logging in..."
                />
                {errorMsg ? (
                  <div className="text-center">
                    <Label className="error mb0">{errorMsg}</Label>
                  </div>
                ) : null}
                <div className="link-box mt-3 text-center vc-fw-600 fs13">
                  Already have an account?{" "}
                  <a
                    href="javascript:;"
                    className="link"
                    onClick={() => {
                      logoutApp(props.navigate);
                    }}
                  >
                    Sign in
                  </a>
                </div>

                <div className="footer text-muted mt-5">
                  <FooterContent />
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default withRouter(AADLogin);
