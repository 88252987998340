import React from "react";
import { Button, ScrollBox, Select, Input } from "../../../common/components";
import CommonUtils from "../../../utils/CommonUtils";
import { Row, Col } from "reactstrap";
import { conditionList, getRandomNumber, addErrorObj } from "../../../utils/GeneralUtils";
import { getAttrByPrevElement, getBotIntent } from "../../../utils/BotUtils";
import { SelectElement, ElementForm, SubmitElement, SelectIntent, SelectAttribute } from "./components";
import { getFormDetails } from "../../../common/components/helper.js";

class Decision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intentTrueId: getRandomNumber(6),
      intentTrue: null,
      intentFalseId: getRandomNumber(6),
      intentFalse: null,
      intent: null,
      step: 1,
      attrKey: Date.now(),
      attributes: [
        {
          attribute1: null,
          condition: null,
          attribute2: null,
          criteria: null,
          errors: { attribute1: null, condition: null, attribute2: null },
        },
      ],
      prevElementStructure: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onListValidate = this.onListValidate.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    const { errors } = this.state.attributes[0];
    if (box) {
      const { attributes, intentTrue, intentFalse, intentTrueId, intentFalseId, intent } = box.payload;
      this.setState({
        attributes: attributes.length > 0 ? addErrorObj(attributes, errors) : this.state.attributes,
        intentTrueId,
        intentTrue,
        intentFalseId,
        intentFalse,
        intent: intent || null,
      });
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  handleChange(name, value) {
    this.setState({ [name]: value });
  }

  addAttr() {
    const { attributes } = this.state;
    attributes.unshift({
      attribute1: null,
      condition: null,
      attribute2: null,
      criteria: "AND",
      errors: { attribute1: null, condition: null, attribute2: null },
    });
    this.setState({ attributes, attrKey: Date.now() });
  }

  removeAttr(index) {
    const { attributes } = this.state;
    attributes.splice(index, 1);
    this.setState({ attributes, attrKey: Date.now() });
  }

  onChangeAttr(name, value, index) {
    const { attributes } = this.state;
    attributes[index][name] = value;
    this.setState({ attributes });
  }

  onListValidate(name, error, index) {
    let attributes = this.state.attributes;
    attributes[index]["errors"][name] = error;
    this.setState({ attributes });
  }

  handleStep(step, isSubmit = false) {
    const { attributes } = this.state;
    let isError = false;
    attributes.map((a, i) => {
      const list = getFormDetails(
        a,
        (n, e) => this.onListValidate(n, e, i),
        a.condition === "hasValue" ? "attribute1" : undefined
      );
      if (!list) {
        isError = true;
        return false;
      }
    });
    if (isError) {
      return false;
    }

    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { prevElement, attributes, intentTrue, intentFalse, intentTrueId, intentFalseId, intent } = this.state;
    const { handleSubmitElement, boxes, botVersion } = this.props;
    const elementTrue = boxes.find(x => x.id === intentTrue);
    const elementFalse = boxes.find(x => x.id === intentFalse);

    attributes.map(x => {
      if (x.condition === "hasValue") x.attribute2 = null;
    });

    const data = {
      intent_true: getBotIntent(elementTrue, botVersion),
      intent_false: getBotIntent(elementFalse, botVersion),
      attributes: attributes,
    };
    delete data.attributes[data.attributes.length - 1].criteria;
    const payload = {
      attributes,
      intentTrueId,
      intentTrue,
      intentFalseId,
      intentFalse,
      intent,
      qr: [
        {
          id: intentTrueId,
          text: "True",
          connection: intentTrue,
        },
        {
          id: intentFalseId,
          text: "False",
          connection: intentFalse,
        },
      ],
    };
    handleSubmitElement(data, prevElement, payload);
  }

  renderStep1() {
    const { attrKey, attributes, prevElement, intentTrue, intentFalse, prevElementStructure } = this.state;
    const { boxes, attributeList, botElementId, connectors } = this.props;
    return (
      <div className="ele-content">
        <label className="mb20">
          Create an evaluation expression using AND/OR.
          <br />
          Choose node to trigger based on expression result value.
        </label>

        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
        </div>

        <label className="name mt10">Conditions</label>
        {attributes.map((obj, index) => {
          return (
            <div key={index + attrKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
              <div className="input-box">
                <Row>
                  <Col md={3}>
                    <Input
                      type="text"
                      placeholder="Value"
                      name="attribute1"
                      title="Value"
                      value={obj.attribute1}
                      isReq={true}
                      error={obj.errors.attribute1}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={prevElementStructure}
                          onChangeFunc={value => this.onChangeAttr("attribute1", value, index)}
                          setAttribute={[null, obj.attribute1]}
                        />
                      }
                      onChangeFunc={(name, value) => this.onChangeAttr(name, value, index)}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    />
                  </Col>
                  <Col md={3}>
                    <Select
                      outerWidth="100%"
                      placeholder="Condition"
                      name="condition"
                      title="Condition"
                      value={obj.condition}
                      isReq={true}
                      error={obj.errors.condition}
                      options={conditionList}
                      labelKey="name"
                      onChangeFunc={(name, value) => this.onChangeAttr(name, value, index)}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    />
                  </Col>
                  {obj.condition !== "hasValue" && (
                    <Col md={3}>
                      <Input
                        type="text"
                        placeholder="Value"
                        name="attribute2"
                        title="Value"
                        value={obj.attribute2}
                        isReq={obj.condition !== "hasValue"}
                        error={obj.condition !== "hasValue" && obj.errors.attribute2}
                        appendIcon={
                          <SelectAttribute
                            attributeList={attributeList}
                            externalAttributes={prevElementStructure}
                            onChangeFunc={value => this.onChangeAttr("attribute2", value, index)}
                            setAttribute={[null, obj.attribute2]}
                          />
                        }
                        onChangeFunc={(name, value) => this.onChangeAttr(name, value, index)}
                        validationFunc={
                          obj.condition !== "hasValue" ? (n, e) => this.onListValidate(n, e, index) : undefined
                        }
                      />
                    </Col>
                  )}
                  <Col md={3}>
                    {index !== attributes.length - 1 && (
                      <Select
                        isClearable={false}
                        placeholder="Criteria"
                        outerClassName="ddl-criteria"
                        name="criteria"
                        value={obj.criteria}
                        options={[
                          { label: "AND", value: "AND" },
                          { label: "OR", value: "OR" },
                        ]}
                        onChangeFunc={(name, value) => this.onChangeAttr(name, value, index)}
                      />
                    )}
                    <div className="btn-box d-inline-block mt0">
                      {index === 0 ? (
                        <Button plusBtn className="d-inline mr10" onClickFunc={() => this.addAttr()} />
                      ) : null}
                      {attributes.filter(x => !x.isDefault).length > 1 ? (
                        <Button minusBtn className="d-inline" onClickFunc={() => this.removeAttr(index)} />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="clearfix"></div>
            </div>
          );
        })}
        <div className="clearfix"></div>

        <label className="name mt10 mb10">Node to trigger on</label>
        <Row>
          <Col>
            <label className="mr10">True</label>
            <SelectElement
              outerClassName="d-inline-block mb0"
              name="intentTrue"
              outerWidth={250}
              value={intentTrue}
              boxes={boxes}
              botElementId={botElementId}
              isClearable={false}
              connectors={connectors}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </Col>
          <Col>
            <label className="mr10">False</label>
            <SelectElement
              outerClassName="d-inline-block mb0"
              name="intentFalse"
              outerWidth={250}
              value={intentFalse}
              boxes={boxes}
              botElementId={botElementId}
              isClearable={false}
              connectors={connectors}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          boxes={boxes}
          botElementId={botElementId}
          intentList={intentList}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-decision" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default Decision;
