import React from "react";
import { useState } from "react";

function ReadMore(props) {
  const { children, characters } = props;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <span className={props.className}>
      {isReadMore ? `${children.slice(0, characters)}` : children}
      {children.length > characters && (
        <React.Fragment>
          {isReadMore && <span>...</span>}
          <span onClick={toggleReadMore} className="show-more">
            {isReadMore ? "read more" : "show less"}
          </span>
        </React.Fragment>
      )}
    </span>
  );
}

export default ReadMore;
