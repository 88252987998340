import React from "react";
import { Row, Col } from "reactstrap";
import { Select, ScrollBox, Input } from "../../../common/components";
import { SelectElement, ElementForm, SubmitElement, SelectIntent, SelectAttribute } from "./components";
import { getAttrByPrevElement, getBotIntent } from "../../../utils/BotUtils";
import { BOT_ATTRIBUTES, authTypeList } from "../../../utils/ConstUtils";
import { getRandomNumber, isArray, PageType } from "../../../utils/GeneralUtils";
import { getFormDetails } from "../../../common/components/helper";
import CommonUtils from "../../../utils/CommonUtils";

class Identity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intentTrueId: getRandomNumber(6),
      intentTrue: null,
      intentFalseId: getRandomNumber(6),
      intentFalse: null,
      authType: authTypeList[0].value,
      templateId: null,
      toEmail: "",
      userPrompt: "",
      emailSubject: "",
      fromEmail: "",
      intent: null,
      fetchEmailTemplate: false,
      emailTemplateList: [],
      step: 1,
      prevElementStructure: [],
      nextIntent: "",
      currentIntent: "",
      errors: {
        toEmail: null,
        userPrompt: null,
        emailSubject: null,
        fromEmail: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        nextIntent,
        currentIntent = "",
        intent,
        authType,
        templateId,
        toEmail,
        fromEmail,
        userPrompt,
        emailSubject,
        intentTrue,
        intentFalse,
        intentTrueId,
        intentFalseId,
      } = box.payload;
      this.setState({
        nextIntent,
        currentIntent,
        intent,
        authType,
        templateId,
        toEmail,
        fromEmail,
        userPrompt,
        emailSubject,
        intentTrue,
        intentFalse,
        intentTrueId,
        intentFalseId,
      });
    }
    this.getEmailTemplateList();
  }

  getEmailTemplateList() {
    this.setState({ fetchEmailTemplate: true });
    CommonUtils.getMerchantPage().then(response => {
      if (response.error || !response.mrchntPages) {
        this.setState({ emailTemplateList: [], fetchEmailTemplate: false });
        return false;
      }
      let list = [];
      if (isArray(response.mrchntPages) && isArray(response.mrchntPages[0].pages)) {
        list = response.mrchntPages[0].pages.filter(item =>
          [PageType.email, PageType.customEmail, PageType.predefinedEmail].includes(item.pageType)
        );
      }
      this.setState({ emailTemplateList: list, fetchEmailTemplate: false });
    });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      prevElement,
      nextIntent,
      currentIntent,
      intent,
      userPrompt,
      emailSubject,
      authType,
      templateId,
      toEmail,
      fromEmail,
      intentTrue,
      intentFalse,
      intentTrueId,
      intentFalseId,
    } = this.state;
    const { handleSubmitElement, boxes, botVersion } = this.props;

    const elementTrue = boxes.find(x => x.id === intentTrue);
    const elementFalse = boxes.find(x => x.id === intentFalse);
    const data = {
      input_type: "auth",
      next_intent: nextIntent,
      request_params: [
        {
          merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
          current_intent: currentIntent,
          attribute_name: `identity_${currentIntent}`,
          [`identity_${currentIntent}`]: `\${(payLoad.identity_${currentIntent})!}`,
          authType,
          templateId,
          toEmail,
          fromEmail,
          prompt: userPrompt,
          emailSubject,
          intent_true: getBotIntent(elementTrue, botVersion),
          intent_false: getBotIntent(elementFalse, botVersion),
        },
      ],
    };
    const payload = {
      nextIntent,
      currentIntent,
      intent,
      intentTrueId,
      intentTrue,
      intentFalseId,
      intentFalse,
      authType,
      templateId,
      toEmail,
      fromEmail,
      userPrompt,
      emailSubject,
      qr: [
        {
          id: intentTrueId,
          text: "True",
          connection: intentTrue,
        },
        {
          id: intentFalseId,
          text: "False",
          connection: intentFalse,
        },
      ],
    };

    handleSubmitElement(data, prevElement, payload);
  }

  handleChange(name, value) {
    this.setState({ [name]: value });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  renderStep1() {
    const { boxes, botElementId, connectors, attributeList } = this.props;
    const {
      authType,
      templateId,
      prevElement,
      toEmail,
      fromEmail,
      userPrompt,
      emailSubject,
      fetchEmailTemplate,
      emailTemplateList,
      prevElementStructure,
      intentTrue,
      intentFalse,
      errors,
    } = this.state;

    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Type">
            <Select
              name="authType"
              outerClassName="mb0"
              value={authType}
              options={authTypeList}
              isClearable={false}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="User Prompt">
            <Input
              name="userPrompt"
              title="User Prompt"
              placeholder="Request user input with message"
              outerClassName="mb0"
              className="form-control pr35"
              value={userPrompt}
              onChangeFunc={this.handleChange}
              isReq={true}
              error={errors.userPrompt}
              validationFunc={this.onInputValidate}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={[...prevElementStructure]}
                  onChangeFunc={value => this.handleChange("userPrompt", value)}
                  setAttribute={[null, userPrompt]}
                />
              }
            />
          </ElementForm>
          <ElementForm title="To Email">
            <Input
              name="toEmail"
              title="To Email"
              placeholder="Enter To Email"
              outerClassName="mb0"
              className="form-control pr35"
              value={toEmail}
              onChangeFunc={this.handleChange}
              isReq={true}
              error={errors.toEmail}
              validationFunc={this.onInputValidate}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={[...prevElementStructure]}
                  onChangeFunc={value => this.handleChange("toEmail", value)}
                  setAttribute={[null, toEmail]}
                />
              }
            />
          </ElementForm>
          <ElementForm title="From Email">
            <Input
              name="fromEmail"
              title="From Email"
              placeholder="Enter From Email"
              outerClassName="mb0"
              className="form-control pr35"
              value={fromEmail}
              onChangeFunc={this.handleChange}
              isReq={true}
              error={errors.fromEmail}
              validationFunc={this.onInputValidate}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={[...prevElementStructure]}
                  onChangeFunc={value => this.handleChange("fromEmail", value)}
                  setAttribute={[null, fromEmail]}
                />
              }
            />
          </ElementForm>
          <ElementForm title="Email Subject">
            <Input
              name="emailSubject"
              title="Email Subject"
              placeholder="Email Subject"
              outerClassName="mb0"
              className="form-control pr35"
              value={emailSubject}
              onChangeFunc={this.handleChange}
              isReq={true}
              error={errors.emailSubject}
              validationFunc={this.onInputValidate}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={[...prevElementStructure]}
                  onChangeFunc={value => this.handleChange("emailSubject", value)}
                  setAttribute={[null, emailSubject]}
                />
              }
            />
          </ElementForm>
          <ElementForm title="Email Template">
            <Select
              name="templateId"
              outerClassName="mb0"
              value={templateId}
              options={emailTemplateList}
              labelKey="pageName"
              valueKey="pageId"
              isClearable={false}
              isLoading={fetchEmailTemplate}
              onChangeFunc={this.handleChange}
            />
            <i className="d-block fs13 mt-1 text-gray-500">
              Don't forget to add [OTP-EMAIL] tag in the email template.
            </i>
          </ElementForm>

          <label className="name mt10 mb10">Node to trigger on</label>
          <Row>
            <Col>
              <label className="mr10">True</label>
              <SelectElement
                outerClassName="d-inline-block mb0"
                name="intentTrue"
                outerWidth={200}
                value={intentTrue}
                boxes={boxes}
                botElementId={botElementId}
                isClearable={false}
                connectors={connectors}
                onChangeFunc={(name, value) => this.setState({ [name]: value })}
              />
            </Col>
            <Col>
              <label className="mr10">False</label>
              <SelectElement
                outerClassName="d-inline-block mb0"
                name="intentFalse"
                outerWidth={200}
                value={intentFalse}
                boxes={boxes}
                botElementId={botElementId}
                isClearable={false}
                connectors={connectors}
                onChangeFunc={(name, value) => this.setState({ [name]: value })}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-identity" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default Identity;
