import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DP from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = props => {
  const dpProps = {
    portalId: "root-portal",
    showIcon: true,
    showTimeSelect: props.showTimeSelect,
    icon: "fa fa-calendar mt-1",
    className: props.className,
    placeholderText: props.placeholder,
    showTimeInput: props.showTimeInput,
    disabled: props.disabled,
    timeIntervals: props.timeIntervals,
  };

  if (props.displayFormat) {
    dpProps.dateFormat = props.displayFormat;
  }
  if (props.minDate) {
    dpProps.minDate = props.minDate;
    if (props.showTimeSelect) {
      const val = props.outputFormat ? moment(props.value, props.outputFormat).toDate() : props.value;
      const isSameDay = moment(val).format("YYYY-MM-DD") === moment(props.minDate).format("YYYY-MM-DD");
      dpProps.minTime = isSameDay ? props.minDate : moment(props.minDate).startOf("day").toDate();
      dpProps.maxTime = moment(props.minDate).endOf("day").toDate();
    }
  }

  return (
    <DP
      {...dpProps}
      selected={props.outputFormat ? moment(props.value, props.outputFormat).toDate() : props.value}
      onChange={dt => props.onChange(props.name, props.outputFormat ? moment(dt).format(props.outputFormat) : dt)}
      onKeyDown={e => e.preventDefault()}
    />
  );
};

DatePicker.defaultProps = {
  placeholder: "Select Date",
  className: "form-control",
  showTimeInput: false,
  showTimeSelect: false,
  disabled: false,
  timeIntervals: 5,
};

DatePicker.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showTimeInput: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  outputFormat: PropTypes.string,
  displayFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
};

export default DatePicker;
