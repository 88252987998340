import React, { memo } from "react";
import { Button, ScrollBox, Select, TextArea } from "../../../common/components/index.js";
import {SelectAttribute } from './components';
import { ElementForm, SelectElement, SubmitElement, SelectIntent } from "./components/index.js";
import { getFormDetails } from "../../../common/components/helper.js";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils.js";
import CommonUtils from "../../../utils/CommonUtils.js";
import { sessionMerchantId } from "../../../utils/SessionUtils.js";
import { formatLocalDate, getAiList, getRandomNumber, isJsonString } from "../../../utils/GeneralUtils.js";
import { getBotIntent, getAttrByPrevElement } from "../../../utils/BotUtils.js";
import { get } from "lodash";

class Prompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intent: null,
      step: 1,
      nextIntent: "",
      modelId: null,
      modelList: [],
      prompt: "",
      errorIntent: null,
      // modelData: null,

      errorElementId: getRandomNumber(6),
      errorElement: null,
      isTestloading: false,
      promptResponse: "",
      messageTarget: 0,
      prevElementStructure: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeElement = this.onChangeElement.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.handleRunPrompt = this.handleRunPrompt.bind(this);
    this.onChangePrompt = this.onChangePrompt.bind(this);
  }

  getModelList() {
    const filterItems = getAiList()
      .filter(item => !["WATSONAI", "MONDEEAI"].includes(item.value))
      .map(item => item.value)
      .join(",");

    let modelParams = {
      merchantId: sessionMerchantId(),
      ai: filterItems,
    };

    CommonUtils.getModelList(modelParams).then(res => {
      const list = res.models.map(model => {
        return {
          label: model.modelName,
          value: model.modelId,
          // data: {
          //   foundationModel: model.modelParams ? model.modelParams.foundationModel : "",
          //   modelType: model.modelType,
          //   modelVersion: model.modelVersion,
          //   createdBy: model.createdBy,
          //   createdDate: model.createdDate,
          // },
        };
      });
      this.setState({
        modelList: list,
      });
    });
  }

  componentDidMount() {
    this.getModelList();
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { intent, nextIntent, modelId, prompt, errorElementId, errorElement } = box.payload;

      const stateObj = {
        nextIntent,
        intent: intent || null,
        modelId,
        prompt,
        errorElementId,
        errorElement,
      };
      this.setState(stateObj);
    }
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { intent, nextIntent, prevElement, modelId, prompt, errorElementId, errorElement } = this.state;
    const { handleSubmitElement, boxes, botVersion } = this.props;

    const elError = boxes.find(x => x.id === errorElement);
    let data = {
      input_type: "prompt",
      next_intent: nextIntent,
      request_params: [
        {
          modelId,
          prompt,
          merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
          errorIntent: getBotIntent(elError, botVersion),
        },
      ],
    };
    const payload = {
      intent,
      nextIntent,
      modelId,
      prompt,
      errorElementId,
      errorElement,
      qr: [],
    };
    if (data.request_params[0].errorIntent) {
      payload.qr = [
        {
          id: errorElementId,
          text: "Error",
          connection: errorElement,
        },
      ];
    }
    handleSubmitElement(data, prevElement, payload);
  }

  onChangeElement(name, value) {
    const stateObj = { [name]: value };
    // if (name === "modelId") {
    //   stateObj.modelData = (this.state.modelList.find(x => x.value === value) || { data: null }).data;
    // }
    this.setState(stateObj);
  }

  onChangePrompt(name, value, e){
    const { boxes, elementType } = this.props;
    this.setState({[name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType), messageTarget: e.target })
  }

  async handleRunPrompt() {
    if (!this.state.prompt) return;

    const { prompt, modelId } = this.state;
    this.setState({ isTestloading: true });
    const result = await CommonUtils.generateAI({
      message: prompt,
      merchantId: sessionMerchantId(),
      modelId: modelId,
    });
    if (result.error || result.responseCode === 500) {
      this.setState({ isTestloading: false, promptResponse: "" });
      return;
    }

    const response = get(result, "intents[0].response", "");
    const decodedJsonString = response.replace(/\\n/g, "\n").replace(/\\"/g, '"');
    if (prompt.toLowerCase().includes("json") && isJsonString(decodedJsonString)) {
      const jsonObject = JSON.parse(decodedJsonString);
      const formattedJsonString = JSON.stringify(jsonObject, null, 2);
      this.setState({ isTestloading: false, promptResponse: formattedJsonString });
    } else {
      this.setState({ isTestloading: false, promptResponse: response });
    }
  }

  renderStep1() {
    const { prevElement, modelId, modelList, prompt, errorElement, isTestloading, promptResponse,
    messageTarget, prevElementStructure,
    } = this.state;
    const { botElementId, boxes, connectors, attributeList } = this.props;

    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangeElement}
            />
          </ElementForm>
          <ElementForm title="Model">
            <Select
              name="modelId"
              outerClassName="mb0"
              title="Model"
              outerWidth={300}
              value={modelId}
              options={modelList}
              onChangeFunc={this.onChangeElement}
            />
          </ElementForm>

          <ElementForm title="On Error">
            <SelectElement
              value={errorElement}
              boxes={boxes}
              name="errorElement"
              placeholder="Select Error Element"
              connectors={connectors}
              defaultSelected={false}
              onChangeFunc={this.onChangeElement}
            />
          </ElementForm>

          {/* {modelData && (
            <ElementForm title="Model Details">
              <div className="ml-box px-4">
                {modelData.foundationModel && (
                  <div>
                    <span className="fw-semibold mr-1">Foundation Model:</span>
                    <span className="info">{modelData.foundationModel}</span>
                  </div>
                )}
                {modelData.modelType && (
                  <div>
                    <span className="fw-semibold mr-1">Model Type:</span>
                    <span className="info">{modelData.modelType}</span>
                  </div>
                )}
                {modelData.modelVersion && (
                  <div>
                    <span className="fw-semibold mr-1">Model Version:</span>
                    <span className="info">{modelData.modelVersion}</span>
                  </div>
                )}
                {modelData.createdBy && (
                  <div>
                    <span className="fw-semibold mr-1">Created By:</span>
                    <span className="info">{modelData.createdBy}</span>
                  </div>
                )}
                {modelData.createdDate && (
                  <div>
                    <span className="fw-semibold mr-1">Created Date:</span>
                    <span className="info">{formatLocalDate(modelData.createdDate)}</span>
                  </div>
                )}
              </div>
            </ElementForm>
          )} */}

          <ElementForm title="Prompt">
            <TextArea
              autoResize
              placeholder="Enter Prompt"
              name="prompt"
              value={prompt}
              onChangeFunc={this.onChangePrompt}
              rows={5}
              appendIcon={
                <SelectAttribute
                  outerAttriClass="text-area-icon right-0"
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={(value) =>{
                    this.setState({ prompt: value })
                  }}
                  setAttribute={[messageTarget, prompt, false, null]}
                />
              }
              
            />
            <Button
              submitBtn
              className="mt-2"
              customIcon="fa-play"
              loading={isTestloading}
              text="Run"
              onClickFunc={this.handleRunPrompt}
            />
          </ElementForm>

          <ElementForm title="Response">
            <TextArea autoResize forceAutoResize={promptResponse} rows={5} value={promptResponse} disabled></TextArea>
          </ElementForm>
        </div>
      </div>
    );
  }
  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-json-api" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          step={step}
          resetElement={resetElement}
          botElementId={botElementId}
          handleSubmit={this.handleSubmit}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default memo(Prompt);
