import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDataGrid from "react-data-grid";
import Pagination from "react-js-pagination";
import { listNoRecord } from "../../utils/GeneralUtils";
import { isEqual } from "lodash";

class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridWidth: null,
      columnList: [],
    };
    this.calculateColumns = this.calculateColumns.bind(this);
    this.setScrollPosition = this.setScrollPosition.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.calculateColumns();
  }

  componentDidMount() {
    this.setScrollPosition(this.props.scrollToSet);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.columns, this.props.columns)) {
      this.calculateColumns(nextProps);
    }
    if (nextProps.scrollToSet !== null && !isEqual(nextProps.list, this.props.list) && nextProps.list.length > 0) {
      this.setScrollPosition(nextProps.scrollToSet);
    }
  }

  setScrollPosition(scroll) {
    if (this.gridRef) {
      var gridCanvas = this.gridRef.getDataGridDOMNode().querySelector(".react-grid-Canvas");
      if (gridCanvas) gridCanvas.scrollTop = scroll;
    }
  }

  calculateColumns(props = this.props) {
    const { widthInPct, columns, showCheckbox } = props;
    let columnList = [];
    let totalWidth = 0;
    let totalPercentColumn = 0;
    if (widthInPct) {
      totalWidth = this.state.gridWidth || window.innerWidth;
      if (showCheckbox) totalWidth -= 60;
      columns.map(x => {
        if (!x.width) totalPercentColumn++;
        else totalWidth -= x.width;
      });
    }
    columns.map(c => {
      let obj = { ...c };
      if (widthInPct) {
        if (!c.width) {
          obj.width = totalWidth / (totalPercentColumn || 1);
          if (obj.width < obj.minWidth) obj.width = obj.minWidth;
        }
      } else if (!c.width) obj.width = 120;
      if (c.resizable === undefined) obj.resizable = true;
      columnList.push(obj);
    });
    this.setState({ columnList });
  }

  emptyRowsView(list, loading) {
    return <center>{listNoRecord(loading, list)}</center>;
  }

  onRowsSelected(list, isSelected) {
    let { selectedRowList, rowKey } = this.props;
    if (isSelected) {
      selectedRowList.push(...list.map(o => o.row[rowKey]));
    } else {
      let rowIndexes = list.map(o => o.row[rowKey]);
      selectedRowList = selectedRowList.filter(x => rowIndexes.indexOf(x) === -1);
    }
    this.props.selectedRow(selectedRowList);
  }

  render() {
    const {
      rowKey,
      list,
      loading,
      minHeight,
      extraHeight,
      minWidth,
      showTotalRecords,
      enableRowSelect,
      enableShiftSelect,
      key,
      enableCellSelect,
      onGridRowsUpdated,
      index,
      showCheckbox,
      rowHeight,
      enableCellAutoFocus,
      className,
      onRowRenderer,
      headerRowHeight,
      onGridSort,
      pagination,
      paginationLoading,
      pageChange,
      widthInPct,
      scrollPosition,
      scrollToSet,
      selectedRowList,
    } = this.props;
    const { columnList, gridWidth } = this.state;

    let minGridHeight = extraHeight !== 0 ? window.innerHeight - extraHeight : minHeight;
    const dHeight = list.length * rowHeight + headerRowHeight + 7;
    minGridHeight = dHeight > minGridHeight ? minGridHeight : dHeight;
    const inputProps = {
      key,
      rowKey,
      columns: columnList,
      rowsCount: list.length,
      rowGetter: i => list[i],
      emptyRowsView: () => this.emptyRowsView(list, loading),
      minHeight: loading || list.length == 0 ? 0 : minGridHeight,
      minWidth,
      enableRowSelect,
      enableCellSelect,
      enableCellAutoFocus,
      enableShiftSelect,
      onGridRowsUpdated: obj => onGridRowsUpdated(obj),
      rowSelection: showCheckbox
        ? {
            showCheckbox: true,
            enableShiftSelect: true,
            onRowsSelected: e => this.onRowsSelected(e, true),
            onRowsDeselected: e => this.onRowsSelected(e, false),
            selectBy: {
              keys: {
                rowKey: rowKey,
                values: selectedRowList,
              },
            },
          }
        : null,
      rowHeight,
      headerRowHeight,
    };
    if (onRowRenderer) inputProps.rowRenderer = onRowRenderer;
    if (onGridSort) inputProps.onGridSort = onGridSort;
    if (scrollPosition) inputProps.onScroll = position => scrollPosition(position.scrollTop);

    inputProps.ref = e => {
      if (scrollPosition && scrollToSet !== null) {
        this.gridRef = e;
      }
      if (widthInPct) {
        if (e && e.gridWidth && gridWidth !== e.gridWidth()) {
          this.setState({ gridWidth: e.gridWidth() }, () => {
            this.calculateColumns();
          });
        }
      }
    };

    return (
      <div className={className}>
        <ReactDataGrid {...inputProps} />
        {pagination && pagination.totalRecords > 0 && (
          <div className="mt20 table-pagination">
            {pagination.perPageRecords < pagination.totalRecords && (
              <Pagination
                hideDisabled={false}
                activePage={pagination.activePage}
                itemsCountPerPage={pagination.perPageRecords}
                totalItemsCount={pagination.totalRecords}
                pageRangeDisplayed={5}
                onChange={page => pageChange(page)}
              />
            )}
            {showTotalRecords && (
              <span
                className="total-records"
                style={{ lineHeight: pagination.perPageRecords >= pagination.totalRecords ? "25px" : "35px" }}
              >
                Total Records: {pagination.totalRecords}
              </span>
            )}
            {listNoRecord(paginationLoading && list.length !== 0)}
            <div className="clearfix"></div>
          </div>
        )}
      </div>
    );
  }
}

Grid.defaultProps = {
  rowKey: "",
  list: [],
  columns: [],
  loading: false,
  minWidth: "100%",
  minHeight: 400,
  extraHeight: 0,
  key: "",
  showCheckbox: false,
  enableCellSelect: false,
  enableCellAutoFocus: false,
  className: "",
  rowHeight: 35,
  headerRowHeight: 35,
  widthInPct: false,
  scrollToSet: null,
  showTotalRecords: true,
  selectedRowList: [],
};

Grid.propTypes = {
  rowKey: PropTypes.string,
  key: PropTypes.string,
  list: PropTypes.array,
  columns: PropTypes.array,
  showCheckbox: PropTypes.bool,
  loading: PropTypes.bool,
  minHeight: PropTypes.number,
  extraHeight: PropTypes.number,
  minWidth: PropTypes.string,
  enableRowSelect: PropTypes.bool,
  enableCellSelect: PropTypes.bool,
  enableCellAutoFocus: PropTypes.bool,
  enableShiftSelect: PropTypes.bool,
  onGridRowsUpdated: PropTypes.func,
  rowHeight: PropTypes.number,
  headerRowHeight: PropTypes.number,
  className: PropTypes.string,
  onRowRenderer: PropTypes.func,
  onGridSort: PropTypes.func,
  paginationLoading: PropTypes.bool,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    perPageRecords: PropTypes.number,
    totalRecords: PropTypes.number,
  }),
  pageChange: PropTypes.func,
  widthInPct: PropTypes.bool,
  scrollPosition: PropTypes.func,
  showTotalRecords: PropTypes.bool,
  selectedRowList: PropTypes.array,
};

export default Grid;
