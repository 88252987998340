import React from "react";
import { Row, Col, Collapse, Card, CardBody, Label } from "reactstrap";
import { Button, Input, ScrollBox } from "../../../../common/components/index.js";
import { getRandomNumber, isArray } from "../../../../utils/GeneralUtils.js";
import { getAttrByPrevElement } from "../../../../utils/BotUtils.js";
import {
  SubmitElement,
  SelectElement,
  SelectIntent,
  ElementForm,
  OptionsElement,
  SelectAttribute,
} from "../components/index.js";
import { getFormDetails } from "../../../../common/components/helper.js";
import { cloneDeep } from "lodash";
import Sortable from "sortablejs";
import FormBuilderProperties from "./Properties/index.js";
import { get } from "lodash";

const initGroup = {
  isOpen: true,
  groupType: "ELEMENT",
  groupName: "",
  groupTitle: "",
  elementList: [],
  id: Date.now(),
  errors: {
    groupName: null,
  },
};

const formElements = [
  {
    label: "Paragraph",
    value: "paragraph",
    type: "ELEMENT",
    properties: { label: "" },
  },
  {
    label: "Text",
    value: "text",
    type: "ELEMENT",
    properties: { label: "", name: "", placeholder: "", value: "", width: "" },
  },
  {
    label: "Textarea",
    value: "textarea",
    type: "ELEMENT",
    properties: { label: "", name: "", placeholder: "", rows: "", value: "", width: "" },
  },
  {
    label: "Dropdown",
    value: "dropdown",
    type: "ELEMENT",
    properties: { label: "", name: "", placeholder: "", options: "", value: "", width: "" },
  },
  {
    label: "Radio",
    value: "radio",
    type: "ELEMENT",
    properties: { label: "", name: "", options: "", value: "", width: "" },
  },
  {
    label: "Checkbox",
    value: "checkbox",
    type: "ELEMENT",
    properties: { label: "", name: "", options: "", value: "", width: "" },
  },
  {
    label: "Date Picker",
    value: "datepicker",
    type: "ELEMENT",
    properties: { label: "", name: "", placeholder: "", value: "", width: "" },
  },
  {
    label: "Image",
    value: "image",
    type: "ELEMENT",
    properties: { src: "", alt: "" },
  },
  { label: "Submit", value: "submit", type: "BUTTON" },
  { label: "Reset", value: "reset", type: "BUTTON" },
  { label: "Button", value: "button", type: "BUTTON" },
];

class FormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subtitle: "",
      title: "",
      groups: [initGroup],
      groupKey: Date.now(),
      intent: null,
      nextIntent: "",
      step: 1,
      prevElementStructure: [],
      groupIndex: 0,
      listIndex: 0,
      elementIndex: 0,
      subScreen: false,
      section: "",
      renderData: "",
    };

    this.elementListRefs = [];
    this.elementSortable = [];

    this.groupRef = null;
    this.groupSortable = null;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleStep = this.handleStep.bind(this);

    this.setList = this.setList.bind(this);
    this.setActionButton = this.setActionButton.bind(this);

    this.setElementRef = this.setElementRef.bind(this);
    this.initElementSortable = this.initElementSortable.bind(this);
    this.handleElementDrop = this.handleElementDrop.bind(this);

    this.setGroupRef = this.setGroupRef.bind(this);
    this.initGroupSortable = this.initGroupSortable.bind(this);
    this.handleGroupDrop = this.handleGroupDrop.bind(this);

    this.distroySortable = this.distroySortable.bind(this);

    this.onInputChange = this.onInputChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.onGroupChangeValidate = this.onGroupChangeValidate.bind(this);

    this.onGroupUpdate = this.onGroupUpdate.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.deleteElement = this.deleteElement.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      let { subtitle, title, intent, nextIntent, groups } = box.payload;
      const updatedGroups = groups.map(group => {
        group.elementList.forEach(grp => {
          if (isArray(grp.attributes)) {
            const convertedObject = {};
            grp.attributes.forEach(item => {
              if (item.key) {
                convertedObject[item.key] = item.value;
              }
            });
            grp.properties = { ...grp.properties, ...convertedObject };
            delete grp.attributes;
          }
        });
        if (group.elementListKey) {
          const { elementListKey, ...otherGroups } = group;
          return {
            ...otherGroups,
            id: elementListKey,
          };
        }
        return group;
      });

      this.setState({
        subtitle,
        title,
        intent,
        groups: updatedGroups,
        nextIntent,
      });
    }
  }

  componentWillUnmount() {
    this.distroySortable();
  }

  onInputChange(name, value) {
    this.setState({ [name]: value });
  }

  setElementRef(ref, index) {
    if (!ref || index < this.elementListRefs.length) return;

    this.elementListRefs[index] = ref;
    this.initElementSortable(index);
  }

  initElementSortable(index) {
    if (this.elementListRefs[index]) {
      if (this.elementSortable[index]) {
        this.elementSortable[index].destroy();
      }
      this.elementSortable[index] = new Sortable(this.elementListRefs[index], {
        onEnd: e => this.handleElementDrop(e, index),
        animation: 200,
        filter: ".non-draggable-item",
      });
    }
  }

  setGroupRef(ref) {
    if (!ref) return;

    this.groupRef = ref;
    this.initGroupSortable();
  }

  initGroupSortable() {
    if (this.groupRef) {
      if (this.groupSortable) {
        this.groupSortable.destroy();
      }
      this.groupSortable = new Sortable(this.groupRef, {
        onEnd: this.handleGroupDrop,
        animation: 200,
        filter: ".non-draggable-item",
      });
    }
  }

  handleElementDrop(event, index) {
    const { oldIndex, newIndex } = event;

    this.setState(prevState => {
      const updatedGroups = cloneDeep(prevState.groups);
      const { elementList } = updatedGroups[index];

      const [removedItem] = elementList.splice(oldIndex, 1);
      elementList.splice(newIndex, 0, removedItem);

      updatedGroups[index] = {
        ...updatedGroups[index],
        elementList,
        id: Date.now(),
      };

      return { groups: updatedGroups };
    });
  }

  handleGroupDrop(event) {
    const { oldIndex, newIndex } = event;

    this.setState(prevState => {
      const updatedGroups = cloneDeep(prevState.groups);

      const [removedItem] = updatedGroups.splice(oldIndex, 1);
      updatedGroups.splice(newIndex, 0, removedItem);

      return { groups: updatedGroups, groupKey: Date.now() };
    });
  }

  distroySortable() {
    this.elementSortable.map(el => {
      el.destroy();
    });
    if (this.groupSortable) {
      this.groupSortable.destroy();
    }
  }

  handleStep(step, isSubmit = false) {
    let isError = false;
    this.state.groups.map((group, i) => {
      const validGroups = getFormDetails(group, (n, e) => this.onGroupChangeValidate(n, e, i));
      if (!validGroups) {
        if (!isError) {
          this.setState({ section: "GROUP", groupIndex: i, elementIndex: null });
        }
        isError = true;
        return false;
      }
    });

    if (isError) {
      return false;
    }

    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { handleSubmitElement } = this.props;
    const { prevElement, intent, nextIntent, subtitle, title, groups } = this.state;

    const data = {
      input_type: "formBuilder",
      request_params: [
        {
          subtitle,
          title,
          groups: [],
        },
      ],
      next_intent: nextIntent,
    };

    groups.map((group, i) => {
      const list = cloneDeep(group.elementList);
      data.request_params[0].groups.push({
        elements: list.map(c => {
          const elementObj = {
            //...combineObjectsFromArray(convertToKeyvalue(c.attributes)),
            ...c.properties,
            elementType: c.elementType,
          };
          if (group.groupType === "BUTTON") {
            elementObj.buttons = c.dataQR;
          }
          return elementObj;
        }),
        groupTitle: group.groupTitle,
        groupName: group.groupName,
        groupData: group.groupData,
      });
    });

    const qr = [];
    groups.map((g, gi) => {
      return g.elementList.map((c, ci) =>
        (c.payloadQR || []).map(b => {
          if (qr.findIndex(q => q.text === b.text && q.connection === b.connection && q.type === b.type) === -1) {
            qr.push({
              ...b,
              groupName: g.groupName,
              groupType: g.groupType,
            });
          }
        })
      );
    });

    const payload = {
      groups,
      qr,
      subtitle,
      title,
      intent,
      nextIntent,
    };
    handleSubmitElement(data, prevElement, payload);
  }

  //#region onChange element

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  //#endregion

  //#region attribute events

  removeGroupItem(listName, index, groupIndex) {
    const groups = cloneDeep(this.state.groups);
    groups[groupIndex][listName].splice(index, 1);
    this.setState({ groups, id: Date.now() });
  }

  setList(list, type, index, groupIndex) {
    let arr = cloneDeep(this.state.groups);
    arr[groupIndex].elementList[index][type] = list;
    this.setState({
      groups: arr,
    });
  }

  addButtonOrComponent(index, elementType) {
    const { groups } = this.state;
    const updatedGroups = cloneDeep(groups);

    updatedGroups[index].elementList = [
      ...updatedGroups[index].elementList,
      {
        id: getRandomNumber(6),
        properties: {},
        // attributes: [
        //   ...formElements
        //     .find(x => x.value === elementType)
        //     .attributes.map(x => {
        //       x.isDisabledKey = true;
        //       return x;
        //     }),
        //   { key: "", value: "" },
        // ],
        buttons: [],
        elementType,
      },
    ];
    updatedGroups[index].id = Date.now();
    this.setState({ groups: updatedGroups });
  }

  handleActionSave() {
    const { groups, listIndex, groupIndex } = this.state;
    const { elementList } = groups[groupIndex];

    const obj = this.optionRef.getOptions();
    if (!obj) return false;
    else {
      elementList[listIndex].dataQR = obj.dataQR;
      elementList[listIndex].payloadQR = obj.payloadQR;
    }

    groups[groupIndex] = {
      ...groups[groupIndex],
      elementList,
    };

    this.setState({
      groups,
      subScreen: false,
    });
  }
  handleActionCancel() {
    this.setState({
      subScreen: false,
    });
  }

  onGroupChange(name, value, index) {
    this.setState(prevState => {
      const updatedGroups = [...prevState.groups];
      updatedGroups[index] = { ...updatedGroups[index], [name]: value };
      return { groups: updatedGroups };
    });
  }
  onGroupChangeValidate(name, error, groupIndex) {
    this.setState(prevState => {
      const updatedGroups = cloneDeep(prevState.groups);
      updatedGroups[groupIndex].errors[name] = error;
      return { groups: updatedGroups };
    });
  }

  onGroupUpdate(updatedGroups) {
    this.setState({ groups: updatedGroups });
  }

  addGroup(groupType) {
    this.setState(prevState => {
      const init = cloneDeep(initGroup);
      init.groupType = groupType;
      return { groups: [...prevState.groups, init] };
    });
  }

  toggleGroup(index) {
    this.setState(prevState => {
      const updatedGroups = prevState.groups.map((group, i) => {
        if (i === index) {
          return { ...group, isOpen: !group.isOpen };
        }
        return group;
      });
      return { groups: updatedGroups };
    });
  }

  deleteGroup(index) {
    this.setState(prevState => {
      const updatedGroups = prevState.groups.filter((_, i) => i !== index);
      return { groups: updatedGroups, section: "", groupIndex: 0 };
    });
  }

  deleteElement(gIndex, eIndex) {
    this.setState(prevState => {
      const updatedGroups = prevState.groups.map((group, index) => {
        if (index === gIndex) {
          return {
            ...group,
            elementList: group.elementList.filter((_, i) => i !== eIndex),
          };
        }
        return group;
      });
      return { groups: updatedGroups, elementIndex: -1, section: "" };
    });
  }

  //#endregion

  //#region render elements

  renderActions() {
    const { prevElementStructure, groups, groupIndex, listIndex } = this.state;
    const { elementList } = groups[groupIndex];
    const { payloadQR } = elementList[listIndex];

    return (
      <div className="ele-content">
        <OptionsElement
          maxItems={1}
          pageTitle="Add Actions"
          {...this.props}
          payloadQR={isArray(payloadQR) ? payloadQR : undefined}
          ref={e => (this.optionRef = e)}
          prevElementStructure={prevElementStructure}
        />
        <div className="mt-2">
          <Button text="Save" submitBtn className="mr10" onClickFunc={() => this.handleActionSave(groupIndex)} />
          <Button text="Cancel" cancelBtn onClickFunc={() => this.handleActionCancel()} />
        </div>
      </div>
    );
  }

  renderElement(obj, index, groupIndex) {
    const { section, elementIndex } = this.state;
    const isSelected = section === "ELEMENT" && groupIndex === this.state.groupIndex && index === elementIndex;
    const containerClassName = `p-1${isSelected ? " isSelected" : ""}`;
    const containerWidth = obj.properties.width ? obj.properties.width : "100%";
    const validations = get(obj, "properties.validations", []);
    const isMandate = isArray(validations.filter(x => x.type === "required"));
    switch (obj.elementType) {
      case "paragraph":
        return (
          <div
            className={containerClassName}
            style={{ width: containerWidth }}
            onClick={e => this.handleContainerClick(e, "ELEMENT", groupIndex, index)}
          >
            <Label className="font-weight-normal">{obj.properties.label || "Paragraph"}</Label>
          </div>
        );
      case "text":
      case "datepicker":
        return (
          <div
            className={containerClassName}
            style={{ width: containerWidth }}
            onClick={e => this.handleContainerClick(e, "ELEMENT", groupIndex, index)}
          >
            {obj.properties.label && <Label className={isMandate ? "req-lbl" : ""}>{obj.properties.label}</Label>}
            <Input
              type={obj.elementType === "datepicker" ? "date" : obj.elementType}
              name={obj.properties.name}
              value={obj.properties.value}
              placeholder={obj.properties.placeholder}
              outerClassName="mb-2"
            />
          </div>
        );
      case "textarea":
        return (
          <div
            className={containerClassName}
            style={{ width: containerWidth }}
            onClick={e => this.handleContainerClick(e, "ELEMENT", groupIndex, index)}
          >
            {obj.properties.label && <Label className={isMandate ? "req-lbl" : ""}>{obj.properties.label}</Label>}
            <textarea
              className="form-control"
              name={obj.properties.name}
              value={obj.properties.value}
              placeholder={obj.properties.placeholder}
              style={{ fontSize: 13 }}
              rows={obj.properties.rows}
            />
          </div>
        );

      case "dropdown":
        const ddlDataSource = [];
        if (obj.properties.options) {
          obj.properties.options.split(",").map(x => {
            ddlDataSource.push({ label: x.trim(), value: x.trim() });
          });
        }
        return (
          <div
            className={containerClassName}
            style={{ width: containerWidth }}
            onClick={e => this.handleContainerClick(e, "ELEMENT", groupIndex, index)}
          >
            {obj.properties.label && <Label className={isMandate ? "req-lbl" : ""}>{obj.properties.label}</Label>}
            <select className="form-control" style={{ fontSize: 13 }}>
              <option value="" hidden>
                {obj.properties.placeholder}
              </option>
              {ddlDataSource.map((option, index) => (
                <option key={index} value={option.value} selected={obj.value == option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );
      case "radio":
        const radioDataSource = [];
        if (obj.properties.options) {
          if (typeof obj.properties.options === "string") {
            obj.properties.options.split(",").map(x => {
              radioDataSource.push({ label: x.trim(), value: x.trim() });
            });
          } else if (isArray(obj.properties.options)) {
            obj.properties.options.map(x => {
              radioDataSource.push({ label: x.label || "", value: x.value || "" });
            });
          }
        }
        if (!isArray(radioDataSource)) {
          radioDataSource.push({ label: "Radio", value: "" });
        }
        return (
          <div
            className={containerClassName}
            style={{ width: containerWidth }}
            onClick={e => this.handleContainerClick(e, "ELEMENT", groupIndex, index)}
          >
            {obj.properties.label && (
              <label className={isMandate ? "req-lbl" : ""} style={{ fontWeight: 600, fontSize: 13, marginBottom: 5 }}>
                {obj.properties.label}
              </label>
            )}
            <div style={{ display: "flex", flexWrap: "wrap", gap: "0 15px" }}>
              {radioDataSource.map((option, index) => (
                <label className="d-block mb-0 font-weight-normal" key={index}>
                  <input
                    type="radio"
                    value={option.value}
                    name={obj.properties.name}
                    defaultChecked={obj.properties.value == option.value}
                    style={{ marginRight: "5px" }}
                  />
                  <span for={obj.properties.name} style={{ fontSize: 13 }}>
                    {option.label}
                  </span>
                </label>
              ))}
            </div>
          </div>
        );

      case "checkbox":
        const cbDataSource = [];
        if (obj.properties.options) {
          if (typeof obj.properties.options === "string") {
            obj.properties.options.split(",").map(x => {
              cbDataSource.push({ label: x.trim(), value: x.trim() });
            });
          } else if (isArray(obj.properties.options)) {
            obj.properties.options.map(x => {
              cbDataSource.push({ label: x.label || "", value: x.value || "" });
            });
          }
        } else {
          cbDataSource.push({ label: obj.properties.label || "", value: obj.properties.name });
        }
        return (
          <div
            className={containerClassName}
            style={{ width: containerWidth }}
            onClick={e => this.handleContainerClick(e, "ELEMENT", groupIndex, index)}
          >
            {obj.properties.label && cbDataSource[0].label !== obj.properties.label && (
              <label className={isMandate ? "req-lbl" : ""} style={{ fontWeight: 600, fontSize: 13, marginBottom: 5 }}>
                {obj.properties.label}
              </label>
            )}
            {cbDataSource.map((option, index) => (
              <label className="d-block mb-0 font-weight-normal" key={index}>
                <input
                  type="checkbox"
                  value={option.value}
                  defaultChecked={obj.properties.value == option.value}
                  style={{ marginRight: "5px" }}
                />
                <span for={obj.properties.name} style={{ fontSize: 13 }}>
                  {option.label}
                </span>
              </label>
            ))}
          </div>
        );

      case "image":
        return (
          <div
            className={containerClassName}
            style={{ width: containerWidth }}
            onClick={e => this.handleContainerClick(e, "ELEMENT", groupIndex, index)}
          >
            <div className="form-group mb-2 text-center">
              {obj.properties.src ? (
                <img src={obj.properties.src} alt={obj.properties.alt} style={{ width: "100%" }} />
              ) : (
                <img src="/img/no-image.jpg" alt={obj.properties.alt} style={{ width: "100px" }} />
              )}
            </div>
          </div>
        );
      case "submit":
      case "reset":
      case "button":
        const submitBtn = isArray(obj.dataQR) ? obj.dataQR[0] : "";
        const buttonStyle = {
          color: "rgb(72, 163, 205)",
          borderColor: "rgb(72, 163, 205)",
        };
        const buttonClass = "btn btn-sm";
        const buttonContent = submitBtn ? submitBtn.title : `Add ${obj.elementType.toUpperCase()} Action`;

        return (
          <div className={containerClassName} onClick={e => this.handleContainerClick(e, "ELEMENT", groupIndex, index)}>
            {obj.elementType !== "button" ? (
              <button type={submitBtn ? "submit" : "button"} style={buttonStyle} className={buttonClass}>
                {buttonContent}
              </button>
            ) : (
              <a href="javascript:;">{buttonContent}</a>
            )}
          </div>
        );
    }
  }

  setActionButton(groupIndex, index) {
    this.setState({ groupIndex, listIndex: index, subScreen: true });
  }

  renderStaticCard(group, groupIndex) {
    return (
      <React.Fragment>
        <div>
          <div
            ref={e => this.setElementRef(e, groupIndex)}
            className="group-element draggable"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {group.elementList.map((obj, index) => {
              return this.renderElement(obj, index, groupIndex);
            })}
          </div>

          <Col className="mb10 p-1">
            <div className="c-box" style={{ minHeight: 100 }}>
              <SelectAttribute
                outerAttriClass="element-control"
                attributeList={formElements.filter(x => x.type === group.groupType)}
                onChangeFunc={value => this.addButtonOrComponent(groupIndex, value)}
                customIcon="fa fa-plus"
              />

              <div className="plus fs13 text-center text-gray-600 w-100">
                <img src="/img/bot-elements/form-builder.svg" style={{ width: 30 }} />
                <div className="mt-2 px-5">Choose an element from the list to design the form.</div>
              </div>
              {/* <Button className="plus" text="+" onClickFunc={() => this.addButtonOrComponent(groupIndex, type)} /> */}
            </div>
          </Col>
        </div>
      </React.Fragment>
    );
  }

  //#endregion

  handleContainerClick(event, section, groupIndex = 0, elementIndex = 0) {
    event.stopPropagation();
    this.setState({
      section,
      groupIndex,
      elementIndex,
    });
  }

  //#region render steps

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  renderStep1() {
    const { subtitle, title, prevElement, groups, subScreen, groupKey, section, groupIndex } = this.state;
    const { boxes, botElementId, connectors } = this.props;
    if (subScreen) {
      return this.renderActions();
    }

    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm title="Previous Element">
            <div className="position-relative">
              <SelectElement
                value={prevElement}
                botElementId={botElementId}
                boxes={boxes}
                connectors={connectors}
                onChangeFunc={this.onChangePrevElement}
              />
            </div>
          </ElementForm>
        </div>

        <div className="form-box">
          <div className="form-preview">
            <div className="form-preview-content">
              <div
                className={`border-bottom p-2 pb-1${section === "TITLE" ? " isSelected" : ""}`}
                onClick={e => this.handleContainerClick(e, "TITLE")}
              >
                <div className="editor font-weight-bold">{title || "FORM"}</div>
                <div className="editor">{subtitle}</div>
              </div>

              <div ref={e => this.setGroupRef(e)} className="draggable">
                {groups.map((group, index) => (
                  <div
                    key={index + groupKey}
                    className={`p-1${section === "GROUP" && index === groupIndex ? " isSelected" : ""}`}
                    onClick={e => this.handleContainerClick(e, "GROUP", index)}
                  >
                    {group.groupTitle && (
                      <Row className="mt10">
                        <Col xs="12" className="d-flex align-items-center">
                          <div className="editor d-block font-weight-bold ml-1">{group.groupTitle}</div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xs="12">{this.renderStaticCard(group, index)}</Col>
                    </Row>
                  </div>
                ))}
              </div>

              <Card className="mb0 mt20 v1-design">
                <CardBody>
                  <div className="d-flex flex-column justify-content-center align-items-center ">
                    <p style={{ color: "rgb(153, 153, 153)" }}>
                      If you like to add attribute list group, you may create Group attribute by clicking the below
                      button.
                    </p>
                    <div className="d-flex">
                      <Button
                        onClickFunc={() => this.addGroup("ELEMENT")}
                        text="Add Elements Group"
                        className="btn-v2"
                      />
                      <Button
                        onClickFunc={() => this.addGroup("BUTTON")}
                        text="Add Buttons Group"
                        className="btn-v2 ml-2"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="form-details-outer">
            <FormBuilderProperties
              {...this.state}
              formElements={formElements}
              setList={this.setList}
              setActionButton={this.setActionButton}
              onInputChange={this.onInputChange}
              onGroupChange={this.onGroupChange}
              onGroupChangeValidate={this.onGroupChangeValidate}
              onGroupUpdate={this.onGroupUpdate}
              deleteGroup={this.deleteGroup}
              deleteElement={this.deleteElement}
            />
          </div>
        </div>
      </div>
    );
  }

  _renderStep1() {
    const { subtitle, title, prevElement, groups, subScreen, groupKey } = this.state;
    const { boxes, botElementId, connectors } = this.props;

    if (subScreen) {
      return this.renderActions();
    }

    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm title="Title" flClassName="req-lbl">
            <div className="position-relative">
              <Input
                name="title"
                placeholder="Enter Title"
                outerClassName="mb0"
                value={title}
                onChangeFunc={(name, value) => this.setState({ [name]: value })}
              />
            </div>
          </ElementForm>
        </div>
        <div className="b-form">
          <ElementForm title="Sub Title">
            <div className="position-relative">
              <Input
                name="subtitle"
                placeholder="Enter Subtitle"
                outerClassName="mb0"
                value={subtitle}
                onChangeFunc={(name, value) => this.setState({ [name]: value })}
              />
            </div>
          </ElementForm>
        </div>
        <div className="b-form">
          <ElementForm title="Previous Element">
            <div className="position-relative">
              <SelectElement
                value={prevElement}
                botElementId={botElementId}
                boxes={boxes}
                connectors={connectors}
                onChangeFunc={this.onChangePrevElement}
              />
            </div>
          </ElementForm>
        </div>

        <div ref={e => this.setGroupRef(e)} className="draggable">
          {groups.map((group, index) => (
            <div key={index + groupKey}>
              <Row className="mt20">
                <Col xs="9" className="d-flex align-items-center">
                  <div className="b-form" style={{ marginLeft: -90, marginBottom: -25 }}>
                    <ElementForm title="Group Name" flClassName="req-lbl">
                      <div className="position-relative">
                        <Input
                          name="groupName"
                          title="Group Name"
                          placeholder="Enter Group Name"
                          outerClassName="mb2"
                          value={group.groupName}
                          isReq={true}
                          error={group.errors && group.errors.groupName ? group.errors.groupName : null}
                          onChangeFunc={(name, value) => this.onGroupChange(name, value, index)}
                          validationFunc={(n, e) => this.onGroupChangeValidate(n, e, index)}
                        />
                      </div>
                    </ElementForm>
                  </div>
                </Col>
                <Col xs="3" className="text-right mt-2">
                  {groups.length !== 1 && (
                    <Button
                      outline
                      title="Delete"
                      className="action-btn mr5"
                      customIcon="fa-trash-o font-sm"
                      onClickFunc={() => this.deleteGroup(index)}
                    />
                  )}

                  <i
                    className={`btn btn-outline-secondary btn-sm arrow font-weight-bold fa ${
                      group.isOpen ? "fa-angle-up" : "fa-angle-down"
                    }`}
                    onClick={() => this.toggleGroup(index)}
                  ></i>
                </Col>
              </Row>

              <Collapse isOpen={group.isOpen}>
                <Card className="light-box mb0">
                  <CardBody>
                    <Row>
                      <Col md={5}>
                        <div className="b-form" style={{ marginLeft: -113 }}>
                          <ElementForm title="Group Title">
                            <div className="position-relative">
                              <Input
                                name="groupTitle"
                                placeholder="Enter Group Title"
                                outerClassName="mb0"
                                value={group.groupTitle}
                                onChangeFunc={(name, value) => {
                                  this.onGroupChange(name, value, index);
                                }}
                              />
                            </div>
                          </ElementForm>
                        </div>
                      </Col>
                    </Row>

                    {this.renderStaticCard(group, index)}
                  </CardBody>
                </Card>
              </Collapse>
            </div>
          ))}
        </div>

        <Card className="mb0 mt20 v1-design">
          <CardBody>
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <p style={{ color: "rgb(153, 153, 153)" }}>
                If you like to add attribute list group, you may create Group attribute by clicking the below button.
              </p>
              <div className="d-flex">
                <Button onClickFunc={() => this.addGroup("ELEMENT")} text="Add Elements Group" className="btn-v2" />
                <Button onClickFunc={() => this.addGroup("BUTTON")} text="Add Buttons Group" className="btn-v2 ml-2" />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }

  render() {
    const { step, subScreen } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step + subScreen} scrollClass="bt-send-carousel" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
          hideButtons={subScreen}
        />
      </React.Fragment>
    );
  }
}

export default FormBuilder;
