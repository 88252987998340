import React, { Component } from "react";
// import { withRouter } from "../../withRouter";
import { connect } from "react-redux";
import { handlePopup } from "../../actions";
import { Card, CardBody } from "reactstrap";
import CommonUtils from "../../utils/CommonUtils.js";
import { Button, Input } from "../../common/components";
import { getAllQueryParams, logoutApp } from "../../utils/GeneralUtils";
import { passwordStrength } from "check-password-strength";
import { BRAND_LOGO_URL } from "../../utils/Config";
import FooterContent from "../../components/FooterContent";
import { withRouter } from "../../withRouter.js";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        password: "",
        confirm_password: "",
        errors: {
          password: null,
          confirm_password: null,
        },
      },
      showRePwd: false,
      showPwd: false,
      validationMessage: "",
      userEmail: "",
      secureToken: "",
      apiResetPassword: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.checkPassStrength = this.checkPassStrength.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);
  }

  UNSAFE_componentWillMount() {
    let params = getAllQueryParams(this.props.location.search);
    this.setState({ userEmail: params.user, secureToken: params.secureToken });
  }
  onChange(name, value) {
    this.setState({ user: { ...this.state.user, [name]: value } });
  }

  onInputValidate(name, error) {
    const { errors } = this.state.user;
    errors[name] = error;
    this.setState({ user: { ...this.state.user, errors } });
  }

  resetPassword(e) {
    e.preventDefault();
    const { password, confirm_password, errors } = this.state.user;
    const { validationMessage } = this.state;
    if (validationMessage) {
      return;
    }
    let apiCall = true;
    if (password !== "" && confirm_password === "") {
      errors["confirm_password"] = "Please enter Confirm password";
      apiCall = false;
    } else if ((password === "" && confirm_password === "") || password === "" || confirm_password === "") {
      errors["password"] = "Please enter password ";
      apiCall = false;
    } else if (password !== confirm_password) {
      errors["confirm_password"] = "Confirm password does not match with password";
      apiCall = false;
    }
    if (!apiCall) {
      this.setState({ user: { ...this.state.user, errors } });
      return false;
    }
    const { secureToken, user, userEmail } = this.state;
    let data = {
      userEmail,
      secureToken,
      password: user.password,
    };
    this.setState({ apiResetPassword: true });
    CommonUtils.resetPassword(data).then(response => {
      if (response.error) {
        this.setState({ apiResetPassword: false }, () => {
          this.props.handlePopup({ isError: true });
        });
        return false;
      }
      this.setState({ apiResetPassword: false }, () => {
        this.props.handlePopup({
          isSuccess: true,
          message: "Your password has been reset successfully.",
          onOkClick: () => {
            this.props.handlePopup();
            logoutApp(this.props.navigate);
          },
        });
      });
    });
  }

  getIcon(name, type) {
    if ((name == "password" || name == "confirm_password") && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="icon-lock"></i>
          </span>
        </div>
      );
    } else if (name == "confirm_password" && type == "append") {
      return (
        <div className="input-group-append" onClick={() => this.setState({ showRePwd: !this.state.showRePwd })}>
          <span className="input-group-text">
            <i className={`fa fa-eye${this.state.showRePwd ? "" : "-slash"}`}></i>
          </span>
        </div>
      );
    } else if (name == "password" && type == "append") {
      return (
        <div className="input-group-append" onClick={() => this.setState({ showPwd: !this.state.showPwd })}>
          <span className="input-group-text">
            <i className={`fa fa-eye${this.state.showPwd ? "" : "-slash"}`}></i>
          </span>
        </div>
      );
    }
  }

  checkPassStrength(pass) {
    const passDetails = passwordStrength(pass);
    const passValues = ["too-weak", "weak", "medium", "strong"];
    const stateObj = {
      passContains: passDetails.contains,
      passLength: passDetails.length,
      passwordStrength: passDetails.value,
      passClass: passValues[passDetails.id],
    };

    this.setState(stateObj, () => {
      this.getPassValidation(stateObj);
    });
  }

  getPassValidation(passDetails) {
    const { password } = this.state.user;
    const { passContains, passLength, passwordStrength } = passDetails;
    const validList = ["lowercase", "uppercase", "symbol", "number"];
    if (passLength < 6) {
      this.setState({ validationMessage: password ? "Password must contain minimum 6 characters" : "" });
    } else {
      const missing = validList.filter(x => !passContains.includes(x));
      this.setState({ validationMessage: missing.length > 0 ? `Password must contain ${missing.join(", ")}` : "" });
    }
  }
  handleNewPassword(name, value) {
    this.onChange(name, value);
    this.checkPassStrength(value);
  }

  render() {
    const { user, apiResetPassword, showRePwd, showPwd, validationMessage } = this.state;
    const { password, confirm_password, errors } = user;

    return (
      <div className="login-v1">
        <div className="content m-0">
          <div className="box c-box">
            <Card className="m-box border-0 m-0">
              <div className="mlogo">
                <img src={BRAND_LOGO_URL} height="100%" />
              </div>
              <CardBody className="text-gray-700 pb-0">
                <form onSubmit={this.resetPassword.bind(this)}>
                  <div className="vc-fw-600 fs21 text-center text-muted">Reset Password</div>
                  <p className="mb-4 text-center text-muted">Please reset a new password for your account</p>
                  <div className="input-password mb-3">
                    <label className="vc-fw-600">Password</label>
                    <Input
                      appendIcon={this.getIcon("password", "append")}
                      outerClassName="mb0"
                      type={showPwd ? "text" : "password"}
                      title="Password"
                      name="password"
                      placeholder="Enter Password"
                      value={password}
                      autoComplete="new_password"
                      onChangeFunc={this.handleNewPassword}
                      isReq={true}
                      error={errors.password ? errors.password : validationMessage}
                      validationFunc={this.onInputValidate}
                    />
                  </div>
                  <div className="input-password">
                    <label className="vc-fw-600">Confirm Password</label>
                    <Input
                      appendIcon={this.getIcon("confirm_password", "append")}
                      type={showRePwd ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      name="confirm_password"
                      value={confirm_password}
                      onChangeFunc={this.onChange}
                      title="Confirm Password"
                      isReq={true}
                      error={errors.confirm_password}
                      validationFunc={this.onInputValidate}
                    />
                  </div>
                  <Button
                    text="Save"
                    submitBtn
                    type="submit"
                    className="px-4 w-100 mt-3"
                    style={{ height: 40 }}
                    size="normal"
                    displayIcon={false}
                    loading={apiResetPassword}
                  />
                  <div className="link-box mt-3 text-center vc-fw-600 fs13">
                    Already have an account?{" "}
                    <a
                      href="javascript:;"
                      className="link"
                      onClick={() => {
                        logoutApp(this.props.navigate);
                      }}
                    >
                      Sign in
                    </a>
                  </div>
                  <div className="footer text-muted mt-5">
                    <FooterContent />
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  handlePopup,
};

export default withRouter(connect(null, mapDispatchToProps)(ResetPassword));
