import React from "react";
import { Button, Input, ScrollBox, Select, TextArea, TextEditor } from "../../../common/components";
import { Row, Col } from "reactstrap";
import { SelectElement, ElementForm, SubmitElement, SelectIntent, SelectAttribute } from "./components";
import { botFormType, addErrorObj } from "../../../utils/GeneralUtils";
import { getAttrByPrevElement } from "../../../utils/BotUtils";
import { getFormDetails } from "../../../common/components/helper.js";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attrKey: Date.now(),
      attributes: [{ key: "", value: "", errors: { key: null, value: null } }],
      parameters: [{ key: "", value: "" }],
      isRich: false,
      text: "",
      formType: null,
      intent: null,
      nextIntent: "",
      step: 1,
      content: "",
      prevElementStructure: [],
      errors: {
        formType: null,
        content: null,
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.onListValidate = this.onListValidate.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    this.insertDefaultAttr();

    //attri and params have same error obj
    const { errors } = this.state.attributes[0];
    if (box) {
      let { attributes = [], parameters = [], text, isRich, intent, formType, content, nextIntent } = box.payload;
      this.setState({
        attributes: attributes.length > 0 ? addErrorObj(attributes, errors) : this.state.attributes,
        parameters: parameters.length > 0 ? addErrorObj(parameters, errors) : this.state.parameters,
        text,
        isRich,
        formType,
        content,
        nextIntent,
        intent: intent || null,
      });
    }
  }

  insertDefaultAttr() {
    let { attributes } = this.state;
    attributes.unshift(
      { key: "formId", value: "", errors: { key: null, value: null } },
      { key: "base", value: "https://form.jotform.com/", errors: { key: null, value: null } }
    );
    this.setState({ attributes });
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  //#region form data

  addAttr(listName = "attributes") {
    const list = this.state[listName];
    list.push({ key: "", value: "", errors: { key: null, value: null } });
    this.setState({ [listName]: list, attrKey: Date.now() });
  }

  removeAttr(index, listName = "attributes") {
    const list = this.state[listName];
    list.splice(index, 1);
    this.setState({ [listName]: list, attrKey: Date.now() });
  }

  onChangeAttr(name, value, index, listName = "attributes") {
    const list = this.state[listName];
    list[index][name] = value;
    this.setState({ [listName]: list });
  }

  //#endregion

  onChangeInput(name, value) {
    this.setState({ [name]: value });
  }

  onListValidate(name, error, index, listName = "attributes") {
    const list = this.state[listName];
    list[index]["errors"][name] = error;
    this.setState({ [listName]: list });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const { attributes } = this.state;
    const obj = getFormDetails(this.state, this.onInputValidate);
    if (!obj) return false;
    let isError = false;
    attributes.map((a, i) => {
      const list = getFormDetails(a, (n, e) => this.onListValidate(n, e, i));
      if (!list) {
        isError = true;
        return false;
      }
    });
    if (isError) {
      return false;
    }

    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }
  handleSubmit() {
    const { prevElement, attributes, parameters, isRich, text, formType, content, intent, nextIntent } = this.state;
    const { handleSubmitElement } = this.props;
    const data = {
      form_data: {},
      form_type: formType,
      next_intent: nextIntent,
      text,
      script_url: "https://form.jotform.com/static/feedback2.js",
      content,
    };
    const payload = { attributes, parameters, text, isRich, formType, content, intent, nextIntent };

    attributes
      .filter(x => x.key && x.value)
      .map(x => {
        data.form_data[x.key] = x.value;
      });

    if (parameters.filter(x => x.key && x.value).length) {
      data.form_data.iframeParameters = {};
    }

    parameters
      .filter(x => x.key && x.value)
      .map(x => {
        data.form_data.iframeParameters[x.key] = x.value;
      });

    handleSubmitElement(data, prevElement, payload);
  }

  renderLightbox() {
    const { attrKey, attributes, parameters, content, prevElementStructure, errors } = this.state;
    const { attributeList } = this.props;
    return (
      <div className="b-form">
        <ElementForm title="Content">
          <TextArea
            name="content"
            title="Content"
            value={content}
            isReq={true}
            error={errors.content}
            placeholder="Enter content"
            validationFunc={this.onInputValidate}
            onChangeFunc={this.onChangeInput}
          />
        </ElementForm>

        <ElementForm title="Form Data">
          {attributes.map((obj, index) => {
            return (
              <div key={index + attrKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
                <Row>
                  <Col md={5}>
                    <Input
                      type="text"
                      placeholder="Enter Key"
                      name="key"
                      title="Key"
                      value={obj.key}
                      isReq={true}
                      disabled={obj.key === "formId" || obj.key === "base"}
                      error={obj.errors.key}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                      onChangeFunc={(name, value) => this.onChangeAttr(name, value, index)}
                    />
                  </Col>
                  <Col md={5}>
                    <Input
                      type="text"
                      placeholder="Enter Value"
                      className="form-control pr35"
                      title="Value"
                      name="value"
                      value={obj.value}
                      isReq={true}
                      error={obj.errors.value}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={prevElementStructure}
                          onChangeFunc={(value, inputName) => {
                            this.onChangeAttr("value", value, index), this.onListValidate(inputName, null, index);
                          }}
                          setAttribute={[null, obj.value]}
                        />
                      }
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                      onChangeFunc={(name, value) => this.onChangeAttr(name, value, index)}
                    />
                  </Col>
                  <Col md={2}>
                    <div className="btn-box">
                      {index === attributes.length - 1 ? (
                        <Button plusBtn className="d-inline mr10" onClickFunc={() => this.addAttr()} />
                      ) : null}
                      {attributes.filter(x => !x.isDefault).length > 1 ? (
                        <Button minusBtn className="d-inline" onClickFunc={() => this.removeAttr(index)} />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </ElementForm>

        <ElementForm title="Parameters">
          {parameters.map((obj, index) => {
            return (
              <div key={index + attrKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
                <Row>
                  <Col md={5}>
                    <Input
                      type="text"
                      placeholder="Enter Key"
                      name="key"
                      title="Key"
                      value={obj.key}
                      onChangeFunc={(name, value) => this.onChangeAttr(name, value, index, "parameters")}
                    />
                  </Col>
                  <Col md={5}>
                    <Input
                      type="text"
                      placeholder="Enter Value"
                      className="form-control pr35"
                      name="value"
                      title="Value"
                      value={obj.value}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={prevElementStructure}
                          onChangeFunc={value => this.onChangeAttr("value", value, index, "parameters")}
                          setAttribute={[null, obj.value]}
                        />
                      }
                      onChangeFunc={(name, value) => this.onChangeAttr(name, value, index, "parameters")}
                    />
                  </Col>
                  <Col md={2}>
                    <div className="btn-box">
                      {index === parameters.length - 1 ? (
                        <Button plusBtn className="d-inline mr10" onClickFunc={() => this.addAttr("parameters")} />
                      ) : null}
                      {parameters.filter(x => !x.isDefault).length > 1 ? (
                        <Button
                          minusBtn
                          className="d-inline"
                          onClickFunc={() => this.removeAttr(index, "parameters")}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </ElementForm>
      </div>
    );
  }

  renderStep1() {
    const { prevElement, isRich, text, formType, errors } = this.state;
    const { boxes, botElementId, connectors } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Message">
            {isRich ? (
              <TextEditor title="Message" name="text" value={text} onChangeFunc={this.onChangeInput} />
            ) : (
              <Input
                type="text"
                value={text}
                placeholder="Enter text message"
                outerClassName="mb0"
                name="text"
                onChangeFunc={this.onChangeInput}
              />
            )}
            <label className="mb0" style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>

          <ElementForm title="Type">
            <Select
              name="formType"
              title="Form Type"
              value={formType}
              isReq={true}
              error={errors.formType}
              outerClassName="mb0"
              options={botFormType}
              isClearable={false}
              validationFunc={this.onInputValidate}
              onChangeFunc={this.onChangeInput}
            />
          </ElementForm>
        </div>

        {formType === "LIGHTBOX" && this.renderLightbox()}
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-send-msg-group" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default Form;
