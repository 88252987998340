import React from "react";
import { Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { Input, Button, ScrollBox, Select, TextArea } from "../../../common/components";
import { ElementForm, SelectElement, SubmitElement, SelectIntent } from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { BOT_ATTRIBUTES, mlModelList, mlTypeList } from "../../../utils/ConstUtils";
import CommonUtils from "../../../utils/CommonUtils";
import { sessionMerchantId } from "../../../utils/SessionUtils.js";
import { formatLocalDate } from "../../../utils/GeneralUtils.js";

class MLModels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt: "",
      intent: null,
      step: 1,
      nextIntent: "",
      mlModel: null,
      mlType: "GENERATIVE",
      mlModelData: null,
      generativeml: [],
      generativeMlData: null,
      activeTab: "inputParams",
      inputParamsList: [{ key: "", value: "" }],
      outputParamsList: [{ key: "", value: "" }],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeElement = this.onChangeElement.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  getModelList() {
    CommonUtils.getModelList({ merchantId: sessionMerchantId() }).then(res => {
      this.setState({
        generativeml: res.models.map(model => {
          return {
            label: model.modelName,
            value: model.modelId,
            data: {
              foundationModel: model.modelParams ? model.modelParams.foundationModel : "",
              modelType: model.modelType,
              modelVersion: model.modelVersion,
              createdBy: model.createdBy,
              createdDate: model.createdDate,
            },
          };
        }),
      });
    });
  }

  componentDidMount() {
    this.getModelList();
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        intent,
        nextIntent,
        mlModel,
        mlType,
        prompt,
        inputParamsList,
        outputParamsList = [{ key: "", value: "" }],
      } = box.payload;

      const stateObj = {
        nextIntent,
        intent: intent || null,
        mlModel,
        outputParamsList,
        mlType,
        prompt: "",
        inputParamsList: [{ key: "", value: "" }],
      };

      if (mlType === "GENERATIVE") {
        stateObj.prompt = prompt;
      } else {
        stateObj.inputParamsList = inputParamsList;
      }

      this.setState(stateObj, () => {
        this.getModelData();
      });
    }
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  getModelData() {
    if (this.state.mlType === "TRADITIONAL") {
      this.setState({
        mlModelData: (mlModelList.find(x => x.value === this.state.mlModel) || { data: null }).data,
        generativeMlData: null,
      });
    }
    if (this.state.mlType === "GENERATIVE") {
      this.setState({
        generativeMlData: (this.state.generativeml.find(x => x.value === this.state.mlModel) || { data: null }).data,
        mlModelData: null,
      });
    }
  }

  handleSubmit() {
    const { intent, nextIntent, prevElement, mlModel, inputParamsList, outputParamsList, mlType, prompt } = this.state;
    const { handleSubmitElement } = this.props;

    let data = {
      input_type: "mlModels",
      next_intent: nextIntent,
      request_params: [
        {
          mlType,
          mlModel,
          inputParamsList: mlType === "GENERATIVE" ? prompt : inputParamsList,
          outputParamsList: mlType === "GENERATIVE" ? null : outputParamsList,
          merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
        },
      ],
    };
    const payload = {
      intent,
      nextIntent,
      mlModel,
      mlType,
      outputParamsList,
      inputParamsList: mlType === "GENERATIVE" ? null : inputParamsList,
      prompt: mlType === "GENERATIVE" ? prompt : "",
    };
    handleSubmitElement(data, prevElement, payload);
  }

  onChangeElement(name, value) {
    this.setState({ [name]: value });
  }

  addFeature(listName) {
    let list = this.state[listName];
    list.push({
      key: "",
      value: "",
    });
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  removeFeature(listName, index) {
    let list = this.state[listName];
    list.splice(index, 1);
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  onChangeFeature(name, value, index, listName) {
    let list = this.state[listName];
    list[index][name] = value;
    this.setState({ [listName]: list });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  renderTab() {
    const { activeTab, inputParamsList, outputParamsList, listKey, prompt } = this.state;

    return (
      <div className="postman-head">
        <Nav className="nav-tab">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "inputParams" })}
              onClick={() => {
                this.toggle("inputParams");
              }}
            >
              {" "}
              Input Parameters
            </NavLink>
          </NavItem>
          {this.state.mlType !== "GENERATIVE" && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "outputParams" })}
                onClick={() => {
                  this.toggle("outputParams");
                }}
              >
                {" "}
                Output Parameters
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="inputParams">
            {activeTab === "inputParams" && this.state.mlType === "GENERATIVE" ? (
              <Row>
                <Col md={1}>
                  <Label className="mt5" htmlFor="status">
                    Prompt
                  </Label>
                </Col>
                <Col md="10" xs="11">
                  <div className="mb-3">
                    <TextArea
                      autoResize
                      placeholder="Enter Prompt"
                      name="prompt"
                      value={prompt}
                      onChangeFunc={this.onChangeElement}
                      rows={2}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              inputParamsList.map((obj, index) => {
                return (
                  <div key={`i${index}${listKey}`} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "inputParamsList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "inputParamsList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === inputParamsList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("inputParamsList")} />
                            ) : null}
                            {index !== 0 || inputParamsList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("inputParamsList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })
            )}
          </TabPane>
          <TabPane tabId="outputParams">
            {activeTab === "outputParams" &&
              outputParamsList.map((obj, index) => {
                return (
                  <div key={`o${index}${listKey}`} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "outputParamsList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "outputParamsList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === outputParamsList.length - 1 ? (
                              <Button
                                plusBtn
                                className="mr10"
                                onClickFunc={() => this.addFeature("outputParamsList")}
                              />
                            ) : null}
                            {index !== 0 || outputParamsList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("outputParamsList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
        </TabContent>
      </div>
    );
  }

  renderStep1() {
    const { prevElement, mlModel, mlModelData, mlType, generativeml, generativeMlData } = this.state;
    const { botElementId, boxes, connectors } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangeElement}
            />
          </ElementForm>
          <ElementForm title="Type">
            <Select
              name="mlType"
              outerClassName="mb0"
              title="Type"
              outerWidth={300}
              value={mlType}
              options={mlTypeList}
              onChangeFunc={this.onChangeElement}
            />
          </ElementForm>
          <ElementForm title="ML Model">
            <Select
              name="mlModel"
              outerClassName="mb0"
              title="ML Model"
              outerWidth={300}
              value={mlModel}
              options={mlType === "TRADITIONAL" ? mlModelList : generativeml}
              onChangeFunc={(name, value) =>
                this.setState({ [name]: value }, () => {
                  this.getModelData();
                })
              }
            />
          </ElementForm>

          {mlModelData && mlType === "TRADITIONAL" && (
            <ElementForm title="Model Details">
              <div className="ml-box">
                <div>
                  <span className="title">Model Name:</span>
                  <span className="info">{mlModelData.name}</span>
                </div>
                <div>
                  <span className="title">Model Tag:</span>
                  <span className="info">{mlModelData.tag}</span>
                </div>
                <div>
                  <span className="title">Model Version:</span>
                  <span className="info">{mlModelData.version}</span>
                </div>
                <div>
                  <span className="title">Created By:</span>
                  <span className="info">{mlModelData.createdBy}</span>
                </div>
                <div>
                  <span className="title">Created Date:</span>
                  <span className="info">{formatLocalDate(mlModelData.createdDate)}</span>
                </div>
                <div>
                  <span className="title">Response Type:</span>
                  <span className="info">{mlModelData.responseType}</span>
                </div>
              </div>
            </ElementForm>
          )}
          {generativeMlData && mlType === "GENERATIVE" && (
            <ElementForm title="Model Details">
              <div className="ml-box">
                {generativeMlData.foundationModel && (
                  <div>
                    <span className="title">Foundation Model:</span>
                    <span className="info">{generativeMlData.foundationModel}</span>
                  </div>
                )}
                {generativeMlData.modelType && (
                  <div>
                    <span className="title">Model Type:</span>
                    <span className="info">{generativeMlData.modelType}</span>
                  </div>
                )}
                {generativeMlData.modelVersion && (
                  <div>
                    <span className="title">Model Version:</span>
                    <span className="info">{generativeMlData.modelVersion}</span>
                  </div>
                )}
                {generativeMlData.createdBy && (
                  <div>
                    <span className="title">Created By:</span>
                    <span className="info">{generativeMlData.createdBy}</span>
                  </div>
                )}
                {generativeMlData.createdDate && (
                  <div>
                    <span className="title">Created Date:</span>
                    <span className="info">{formatLocalDate(generativeMlData.createdDate)}</span>
                  </div>
                )}
              </div>
            </ElementForm>
          )}

          <ElementForm title="" frMaxWidth="unset">
            {this.renderTab()}
          </ElementForm>
        </div>
      </div>
    );
  }
  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-json-api" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          step={step}
          resetElement={resetElement}
          botElementId={botElementId}
          handleSubmit={this.handleSubmit}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default MLModels;
