import React, { Component } from "react";
import { connect } from "react-redux";
// import { withRouter } from "../withRouter";

import { Row, Col, Label, Card, CardBody } from "reactstrap";
import CommonUtils from "../../utils/CommonUtils";
import { Input, Button } from "../../common/components";
import cloneDeep from "lodash/cloneDeep";
import { getFormDetails } from "../../common/components/helper";

import { handlePopup } from "../../actions";
import { getAllQueryParams, logoutApp } from "../../utils/GeneralUtils";
import { passwordStrength } from "check-password-strength";
import { BRAND_LOGO_URL, BRAND_NAME } from "../../utils/Config";
import FooterContent from "../../components/FooterContent";
import { withRouter } from "../../withRouter";

class PGRegister extends Component {
  constructor(props) {
    super(props);
    this.initForm = {
      email: "",
      password: "",
      repassword: "",
      firstName: "",
      userName: "",
      lastName: "",
      errors: {
        email: null,
        password: null,
        repassword: null,
        firstName: null,
        userName: null,
        lastName: null,
      },
    };
    this.state = {
      customAlert: "",
      showPwd: false,
      showRePwd: false,
      loading: false,
      accept: false,
      passStrengthValue: "",
      passLength: "",
      passContains: [],
      passClass: "",
      validationMessage: "",
      validationReMessage: "",
      form: cloneDeep(this.initForm),
    };
    this.role = null;
    this.secureToken = null;

    this.createUser = this.createUser.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.checkPassStrength = this.checkPassStrength.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);
    this.handleRePassword = this.handleRePassword.bind(this);
  }

  UNSAFE_componentWillMount() {
    let params = getAllQueryParams(this.props.location.search);
    const email = params.user;
    this.secureToken = params.secureToken;
    if (this.secureToken) this.setState({ form: { ...this.state.form, email: email } });

    const { state } = this.props.location;
    if (!(state && state.allowAccess) && !(email && this.secureToken)) {
      logoutApp(this.props.navigate);
    }
    if (state && state.internal) {
      this.role = "ADMIN";
    }
  }

  onChangeInput(name, value) {
    const { form } = this.state;
    form[name] = value;
    if (form.userName) {
      form[name] = value.trim();
    }
    this.setState({ form });
  }

  onInputValidate(name, error) {
    const { errors } = this.state.form;
    errors[name] = error;
    this.setState({ form: { ...this.state.form, errors } });
  }

  createUser(e) {
    e.preventDefault();
    const { form, accept, validationMessage, validationReMessage } = this.state;
    let obj = getFormDetails(form, this.onInputValidate);
    const { email, password, repassword, firstName, lastName, userName } = form;
    if (validationMessage || validationReMessage) {
      return;
    }
    if (accept != true) {
      this.setState({ customAlert: "Please accept the Terms & Conditions!" });
    } else if (password != repassword) {
      this.setState({ customAlert: "The password and repeat password doesn't match!" });
    } else if (!obj) {
      this.setState({ customAlert: "Please fill out all the required feilds!" });
    } else {
      let variables = {
        email: email,
        userName: userName,
        password: repassword,
        firstName: firstName,
        lastName: lastName,
        authType: "PG",
      };
      if (this.role) {
        variables.role = this.role;
      }
      this.setState({ customAlert: "" }, () => {
        this.secureToken ? this.createUserAccount(variables) : this.register(variables);
      });
    }
  }

  createUserAccount(variables) {
    this.setState({ loading: true });
    CommonUtils.createUserAccount(this.secureToken, variables).then(response => {
      if (response.error) {
        const errorObj = { loading: false, customAlert: "Sorry, something went wrong: Please try again later." };
        if (response.status === 401) errorObj.customAlert = "The register link is invalid or has expired.";
        this.setState(errorObj);
        return false;
      }
      if (response && response.errorDescription) {
        this.setState({ customAlert: response.errorDescription, loading: false });
      } else {
        this.setState({ loading: false });
        // this.props.handlePopup({ isSuccess: true, message: 'User account created successfully.' });
        // this.props.navigate('/login');
        this.Login(response.userName, this.state.form.repassword);
      }
    });
  }

  register(variables) {
    this.setState({ loading: true });
    CommonUtils.register(variables).then(response => {
      if (response.error) {
        this.setState({ loading: false, customAlert: "Sorry, something went wrong: Please try again later." });
        return false;
      }
      if (response && response.errorDescription) {
        this.setState({ customAlert: response.errorDescription, loading: false });
      } else {
        this.setState({ loading: false });
        // this.props.handlePopup({ isSuccess: true, message: 'User registered successfully.' });
        this.Login(response.userName, this.state.form.repassword);
      }
    });
  }

  Login(userName, password) {
    CommonUtils.login(userName, password).then(response => {
      if (response.error) return false;
      sessionStorage.setItem("saveUser", userName);
      sessionStorage.setItem("access_token", response.access_token);
      sessionStorage.setItem("expires_at", Math.floor(Date.now() / 1000) + response.expires_in);
      sessionStorage.setItem("refresh_token", response.refresh_token);
      sessionStorage.setItem("stayAlive", "1");
      this.MerchanId(userName);
    });
  }

  MerchanId(userName) {
    CommonUtils.getMerchantId(userName).then(response => {
      if (response.error) return false;
      sessionStorage.setItem("id", response.id);
      sessionStorage.setItem("Role", response.role);
      sessionStorage.setItem("MerchantId", response.merchant.merchantId);
      sessionStorage.setItem("firstName", response.firstName || "");
      sessionStorage.setItem("lastName", response.lastName || "");
      sessionStorage.setItem("authType", response.authType || "PG");
      this.props.navigate("/");
    });
  }

  getIcon(name, type) {
    if (name == "first_name" && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="icon-user"></i>
          </span>
        </div>
      );
    } else if (name == "last_name" && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="icon-user"></i>
          </span>
        </div>
      );
    } else if (name == "user_name" && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="icon-user"></i>
          </span>
        </div>
      );
    } else if (name == "email" && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">@</span>
        </div>
      );
    } else if (name == "password" && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="icon-lock"></i>
          </span>
        </div>
      );
    } else if (name == "password" && type == "append") {
      return (
        <div className="input-group-append" onClick={() => this.setState({ showPwd: !this.state.showPwd })}>
          <span className="input-group-text">
            <i className={`fa fa-eye${this.state.showPwd ? "" : "-slash"}`}></i>
          </span>
        </div>
      );
    } else if (name == "repassword" && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="icon-lock"></i>
          </span>
        </div>
      );
    } else if (name == "repassword" && type == "append") {
      return (
        <div className="input-group-append" onClick={() => this.setState({ showRePwd: !this.state.showRePwd })}>
          <span className="input-group-text">
            <i className={`fa fa-eye${this.state.showRePwd ? "" : "-slash"}`}></i>
          </span>
        </div>
      );
    }
  }

  checkPassStrength(pass) {
    const passDetails = passwordStrength(pass);
    const passValues = ["too-weak", "weak", "medium", "strong"];
    const stateObj = {
      passContains: passDetails.contains,
      passLength: passDetails.length,
      passwordStrength: passDetails.value,
      passClass: passValues[passDetails.id],
    };

    this.setState(stateObj, () => {
      this.getPassValidation(stateObj);
    });
  }

  getPassValidation(passDetails) {
    const { password } = this.state.form;
    const { passContains, passLength, passwordStrength } = passDetails;
    const validList = ["lowercase", "uppercase", "symbol", "number"];
    if (passLength < 6) {
      this.setState({ validationMessage: password ? "Password must contain minimum 6 characters" : "" });
    } else {
      const missing = validList.filter(x => !passContains.includes(x));
      this.setState({ validationMessage: missing.length > 0 ? `Password must contain ${missing.join(", ")}` : "" });
    }
  }

  getNewPassValidation(passDetails) {
    const { password, repassword } = this.state.form;
    if (typeof password !== "undefined" && typeof repassword !== "undefined") {
      this.setState({
        validationReMessage: password != repassword ? "The password and repeat password doesn't match!" : "",
      });
    } else {
      this.setState({ validationReMessage: "" });
    }
  }

  handleRePassword(name, value) {
    this.onChangeInput(name, value);
    this.getNewPassValidation();
  }

  handleNewPassword(name, value) {
    this.onChangeInput(name, value);
    this.checkPassStrength(value);
  }

  render() {
    const { loading, customAlert, showPwd, showRePwd, validationMessage, validationReMessage, form } = this.state;
    const { email, password, repassword, firstName, lastName, userName, accept, errors } = form;

    return (
      <div className="login-v1">
        <div className="content register m-0">
          <div className="box c-box">
            <Card className="m-box border-0 m-0">
              <div className="mlogo">
                <img src={BRAND_LOGO_URL} height="100%" />
              </div>
              <CardBody className="text-gray-700 pb-0">
                <form onSubmit={this.createUser.bind(this)}>
                  <div className="vc-fw-600 fs21 text-center text-muted">Sign up</div>
                  <p className="mb-4 text-center text-muted">
                    Sign up to {BRAND_NAME} for free trial. No credit card is needed.
                  </p>
                  <div className="d-flex mb-3">
                    <div className="mr-3 w-100">
                      <label className="vc-fw-600">First Name</label>
                      <Input
                        outerClassName="mr10 mb0"
                        name="firstName"
                        placeholder="Enter First Name"
                        value={firstName}
                        autoComplete="first_name"
                        onChangeFunc={this.onChangeInput}
                        title="First Name"
                        isReq={true}
                        error={errors.firstName}
                        validationFunc={this.onInputValidate}
                      />
                    </div>
                    <div className="w-100">
                      <label className="vc-fw-600">Last Name</label>
                      <Input
                        outerClassName="mb0"
                        name="lastName"
                        placeholder="Enter Last Name"
                        value={lastName}
                        autoComplete="last_name"
                        onChangeFunc={this.onChangeInput}
                        title="Last Name"
                        isReq={true}
                        error={errors.lastName}
                        validationFunc={this.onInputValidate}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="mr-3 w-100">
                      <label className="vc-fw-600">Username</label>
                      <Input
                        title="Username"
                        outerClassName=" mb0"
                        name="userName"
                        placeholder="Enter Username"
                        value={userName}
                        minLength="6"
                        isReq={true}
                        error={errors.userName}
                        autoComplete="user_name"
                        onChangeFunc={this.onChangeInput}
                        validationFunc={this.onInputValidate}
                      />
                    </div>
                    <div className="w-100">
                      <label className="vc-fw-600">Email</label>
                      <Input
                        outerClassName="mb0"
                        name="email"
                        placeholder="Enter Email"
                        value={email || ""}
                        disabled={this.secureToken ? true : false}
                        onChangeFunc={this.onChangeInput}
                        autoComplete="email"
                        title="Email"
                        isReq={true}
                        reqType="email"
                        error={errors.email}
                        validationFunc={this.onInputValidate}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-3 input-password">
                    <div className="mr-3 w-100">
                      <label className="vc-fw-600">Password</label>
                      <Input
                        appendIcon={this.getIcon("password", "append")}
                        outerClassName="mb0"
                        type={showPwd ? "text" : "password"}
                        title="Password"
                        name="password"
                        placeholder="Enter Password"
                        value={password}
                        autoComplete="new_password"
                        onChangeFunc={this.handleNewPassword}
                        isReq={true}
                        error={errors.password ? errors.password : validationMessage}
                        validationFunc={this.onInputValidate}
                      />
                    </div>
                    <div className="w-100">
                      <label className="vc-fw-600">Repeat Password</label>
                      <Input
                        appendIcon={this.getIcon("repassword", "append")}
                        outerClassName=" mb0"
                        type={showRePwd ? "text" : "password"}
                        name="repassword"
                        placeholder="Enter Repeat password"
                        value={repassword}
                        onBlurFunc={this.handleRePassword}
                        onChangeFunc={this.onChangeInput}
                        title="Repeat Password"
                        isReq={true}
                        error={errors.repassword ? errors.repassword : validationReMessage}
                        validationFunc={this.onInputValidate}
                      />
                    </div>
                  </div>
                  <div className="d-flex fs13 vc-fw-600">
                    <input
                      id="tc"
                      type="checkbox"
                      className="cursor-pointer"
                      checked={accept}
                      value={accept}
                      name="tc"
                      onChange={e => this.setState({ accept: e.target.checked })}
                    />
                    <label htmlFor="tc" className="mb-0 ml5">
                      I agree to the{" "}
                      <a href="javascript:;" style={{ textDecoration: "underline" }}>
                        Terms of Services
                      </a>{" "}
                      and{" "}
                      <a href="javascript:;" style={{ textDecoration: "underline" }}>
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </div>
                  <Button
                    text="Get Started!"
                    submitBtn
                    type="submit"
                    className="px-4 w-100 mt-3"
                    style={{ height: 40 }}
                    size="normal"
                    displayIcon={false}
                    loading={loading}
                  />
                  {customAlert ? (
                    <Row>
                      <Col className="text-center">
                        <Label className="error mb0">{customAlert}</Label>
                      </Col>
                    </Row>
                  ) : null}
                  <div className="link-box mt-3 text-center vc-fw-600 fs13">
                    Already have an account?{" "}
                    <a
                      href="javascript:;"
                      className="link"
                      onClick={() => {
                        logoutApp(this.props.navigate);
                      }}
                    >
                      Sign in
                    </a>
                  </div>
                  <div className="footer text-muted mt-4">
                    <FooterContent />
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  handlePopup,
};

export default withRouter(connect(null, mapDispatchToProps)(PGRegister));
