import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Badge, Nav, NavItem, NavLink as RsNavLink } from "reactstrap";
import classNames from "classnames";
import SidebarFooter from "./../SidebarFooter";
import SidebarForm from "./../SidebarForm";
import SidebarHeader from "./../SidebarHeader";
import SubMenu from "./SubMenu";
import { loadSupportBot, removeSupportBot, visitorCheck } from "../../../utils/GeneralUtils";
import { menuType } from "./menuUtils";
import { BRAND_DOMAIN } from "../../../utils/Config";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportBotLoading: 0, // 0 - nothing, 1 - loading, 2 - open
      collapsed: this.getSelectedMenu(),
      open: document.body.classList.contains("sidebar-minimized"),
    };
    this.ps = null;
    this.handleClick = this.handleClick.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
    this.hideMobile = this.hideMobile.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);

    this.nav = [];
  }

  getSelectedMenu() {
    let collapsedArr = [];
    for (let i = 0; i < ((this.props.menu || []).length || 20); i++) collapsedArr.push(false);
    return collapsedArr;
  }

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle("open");
  }

  activeRoute(routeName, props) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    return props.location.pathname.indexOf(routeName) > -1 ? "nav-item nav-dropdown open" : "nav-item nav-dropdown";
  }

  hideMobile() {
    if (document.body.classList.contains("sidebar-mobile-show")) {
      document.body.classList.toggle("sidebar-mobile-show");
    }
  }

  // todo Sidebar nav secondLevel
  // secondLevelActive(routeName) {
  //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }

  componentDidMount() {
    this.ps = new PerfectScrollbar("#sidebarNav");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.sidebarOpen !== nextProps.sidebarOpen) {
      const el = document.getElementById("purplegrid-support-chat-frame");
      if (el) {
        el.style.left = nextProps.sidebarOpen ? "54px" : "204px";
      }
    }
  }

  componentWillUnmount() {
    if (this.ps && this.ps.destroy) {
      this.ps.destroy();
      this.ps = null;
    }
  }

  toggleMenu(item) {
    const prevState = this.state.collapsed;
    const state = prevState.map((x, index) => (item === index ? !x : false));
    this.setState({ collapsed: state });
  }

  componentWillUnmount() {
    removeSupportBot();
  }

  render() {
    const props = this.props;
    // badge addon to NavItem
    const badge = badge => {
      if (badge) {
        const classes = classNames(badge.class);
        return (
          badge.text && (
            <Badge className={classes} color={badge.variant}>
              {badge.text}
            </Badge>
          )
        );
      }
    };

    // simple wrapper for nav-title item
    const wrapper = item => {
      return item.wrapper && item.wrapper.element
        ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
        : item.name;
    };

    // nav list section title
    const title = (title, key) => {
      const classes = classNames("nav-title", title.class);
      return (
        <li key={key} className={classes}>
          {wrapper(title)}{" "}
        </li>
      );
    };

    // nav list divider
    const divider = (divider, key) => {
      const classes = classNames("divider", divider.class);
      return <li key={key} className={classes}></li>;
    };

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames("nav-link", item.variant ? `nav-link-${item.variant}` : ""),
      };

      if (item.icon) {
        classes.icon = classNames(item.icon);
      }

      return navLink(item, key, classes);
    };

    // nav link
    const navLink = (item, key, classes) => {
      const { collapsed } = this.state;
      const url = item.url ? item.url : "";
      const isMinimized = document.body.classList.contains("sidebar-minimized");
      return (
        <NavItem key={key} className={classes.item} title={item.name}>
          {isExternal(url) ? (
            <div className="dropdown">
              <RsNavLink href={url} target="_blank" className={classes.link}>
                {classes.icon && <i className={classes.icon}></i>}
                {item.iconImg && <img src={item.iconImg} />}
                {item.name}
                {badge(item.badge)}
              </RsNavLink>
            </div>
          ) : (
            <div className="dropdown">
              {item.subMenu ? (
                <SubMenu
                  sidebarOpen={this.props.sidebarOpen}
                  classes={classes}
                  index={key}
                  item={item}
                  toggleMenu={this.toggleMenu}
                  collapsed={collapsed[key]}
                />
              ) : (
                <NavLink to={url} className={classes.link} activeClassName="active" onClick={() => this.hideMobile()}>
                  {classes.icon && <i className={classes.icon}></i>}
                  {item.iconImg && (
                    <img
                      src={item.iconImg}
                      width={20}
                      style={{ margin: isMinimized ? "0 1.5rem 0 14px" : "0 0.5rem 0 0" }}
                    />
                  )}
                  {item.name}
                  {badge(item.badge)}
                </NavLink>
              )}
            </div>
          )}
        </NavItem>
      );
    };

    // nav dropdown
    const navDropdown = (item, key) => {
      return (
        <li key={key} className={this.activeRoute(item.url, props)}>
          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick}>
            <i className={item.icon}></i>
            {item.name}
          </a>
          <ul className="nav-dropdown-items">{navList(item.children)}</ul>
        </li>
      );
    };

    // nav type
    const navType = (item, idx) => {
      return item.title
        ? title(item, idx)
        : item.divider
          ? divider(item, idx)
          : item.children
            ? navDropdown(item, idx)
            : navItem(item, idx);
    };

    const addLiveChatBadge = item => {
      const reqCallBack = this.props.liveVisitorList.filter(v => {
        return visitorCheck(v).isReqAssist;
      });
      const count = reqCallBack.length;
      if (count > 0) {
        item.badge = {
          variant: "info",
          text: count,
        };
      } else {
        delete item.badge;
      }
      document.title = `${count > 0 ? `(${count}) ` : ""}${BRAND_DOMAIN}`;
      return item;
    };

    const addUnassignedMessageBadge = item => {
      const count =
        item.pageId === menuType.KB_UNASSIGNED_MESSAGES
          ? this.props.messages.pendingStatusList.length
          : this.props.messages.pendingList.length;
      if (count > 0) {
        item.badge = {
          variant: "info",
          text: count,
        };
      } else {
        delete item.badge;
      }
      return item;
    };

    const addOnlineVisitorBadge = item => {
      const count = this.props.visitorList.length;
      if (count > 0) {
        item.badge = {
          variant: "info",
          text: count,
        };
      } else {
        delete item.badge;
      }
      return item;
    };

    const checkBadge = (item, type, fn) => {
      if (item.pageId && item.pageId === type) {
        item = fn(item);
      } else if (item.subMenu) {
        item.subMenu.map(x => {
          if (x.pageId === type) x = fn(x);
          return x;
        });
      }
      return item;
    };

    const checkAnalyticsCustom = item => {
      if (
        this.props.merchantAttr.data &&
        item.subMenu &&
        item.subMenu.findIndex(x => x.pageId === menuType.ANALYTICS_CUSTOM)
      ) {
        const { customDashboard } = this.props.merchantAttr.data;
        item.subMenu = item.subMenu.filter(
          x =>
            x.pageId !== menuType.ANALYTICS_CUSTOM ||
            (x.pageId === menuType.ANALYTICS_CUSTOM &&
              (customDashboard || []).filter(x => x.status === "ACTIVE").length > 0)
        );
      }
      return item;
    };

    // nav list
    const navList = items => {
      return items.map((item, index) => {
        item = checkBadge(item, menuType.LIVECHAT, addLiveChatBadge);
        item = checkBadge(item, menuType.UNASSIGNED_MESSAGES, addUnassignedMessageBadge);
        item = checkBadge(item, menuType.KB_UNASSIGNED_MESSAGES, addUnassignedMessageBadge);
        item = checkBadge(item, menuType.ONLINE_VISITORS, addOnlineVisitorBadge);
        item = checkAnalyticsCustom(item);
        return navType(item, index);
      });
    };

    const isExternal = url => {
      const link = url ? url.substring(0, 4) : "";
      return link === "http";
    };

    // sidebar-nav root
    return (
      <div className="sidebar">
        <SidebarHeader />
        <SidebarForm />
        <nav id="sidebarNav" className="sidebar-nav">
          <Nav>
            {navList(this.props.menu)}
            <li className="ihelp nav-item static">
              <i
                className="icon fa fa-question"
                title="Help"
                onClick={() => window.open("https://www.purplegrids.com/docs", "_blank")}
              ></i>
              <i
                className={`icon fa ${this.state.supportBotLoading === 1 ? "fa-refresh fa-spin" : this.state.supportBotLoading === 2 ? "fa-times" : "fa-comments-o"}`}
                title="Chat"
                onClick={() => loadSupportBot(e => this.setState({ supportBotLoading: e }))}
              ></i>
            </li>
          </Nav>
        </nav>
        <SidebarFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    visitorList: state.common.activeVisitors.list,
    messages: state.common.messages,
    liveVisitorList: state.common.activeLiveVisitors.list,
    merchantAttr: state.common.merchantAttr,
    menu: state.common.rolePermission.menu,
  };
};

export default connect(mapStateToProps, null)(Sidebar);
