import React from "react";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { TextArea, ScrollBox, Button, Select, CopyToClipboard } from "../../../common/components";
import { isArray, isJsonString } from "../../../utils/GeneralUtils";
import CommonUtils from "../../../utils/CommonUtils";
import classnames from "classnames";
import {
  SelectElement,
  ElementForm,
  SubmitElement,
  SelectIntent,
  ActionSetUserData,
  ActionDecision,
} from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { cloneDeep, get } from "lodash";
import { getBotElementData, prepareStructure } from "../../../utils/BotUtils";
import { connect } from "react-redux";
import { transformationTypes } from "../../../utils/ConstUtils";
import Workbench from "./components/Workbench";

class JsonTransformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      jsonSchema: "",
      transformationType: "ai",
      activeTab: "input",
      step: 1,
      jsonResult: "",
      outputResult: "",
      jsonStructure: [],
      prevElementStructure: [],
      nextIntent: "",
      intent: null,
      fetchEntityList: true,
      entityList: [],
      errors: {
        jsonSchema: null,
      },
      actionAttr: null,
      actionDecision: null,
      loading: false,
      invalidSchema: false,
      showWorkBench: false,
    };

    this.verificationKey = null;
    this.schemaTypes = ["ai", "classic", "dsl"];
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.closeWorkBench = this.closeWorkBench.bind(this);
    this.updateSchemaJson = this.updateSchemaJson.bind(this);
  }

  componentDidMount() {
    this.getEntityList();
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { jsonSchema, nextIntent, intent, jsonStructure, transformationType, actionAttr, actionDecision } =
        box.payload;
      this.setState({
        jsonSchema,
        nextIntent,
        intent,
        jsonStructure,
        transformationType,
        actionAttr,
        actionDecision,
      });
    }
  }

  closeWorkBench() {
    this.setState({ showWorkBench: false });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  getEntityList() {
    this.setState({ fetchEntityList: true });
    CommonUtils.getEntityByMerchantId().then(response => {
      if (response.error) {
        this.setState({ entityList: [], fetchEntityList: false });
        return false;
      }
      if (response && response.entities) {
        const list = response.entities.map(e => {
          return {
            entityId: e.entityId,
            entries: e.entries,
            name: e.name,
          };
        });
        this.setState({ entityList: list, fetchEntityList: false }, () => this.getPrevElementData());
      }
    });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }
    if (step === 3 && this.actionSetUserDataRef) {
      const obj = this.actionSetUserDataRef.getData();
      if (!obj) return false;
      stateObj.actionAttr = obj.skip ? null : obj.data;
      if (!obj.skip && obj.attrStructure) {
        stateObj.prevElementStructure = [...this.state.prevElementStructure, ...obj.attrStructure];
      }
    }
    if (step === 4 && this.actionDecisionRef) {
      const obj = this.actionDecisionRef.getData();
      if (!obj) return false;
      stateObj.actionDecision = obj.skip ? null : obj;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      jsonSchema,
      prevElement,
      nextIntent,
      intent,
      jsonStructure,
      transformationType,
      actionAttr,
      actionDecision,
    } = this.state;
    const { handleSubmitElement } = this.props;

    let data = {
      input_type: "jsonTransformation",
      next_intent: nextIntent,
      request_params: [
        {
          jsonSchemaStr: jsonSchema,
          transformationType,
        },
      ],
    };

    if (isArray(actionAttr) || actionDecision) {
      data.customAction = {};

      if (isArray(actionAttr)) {
        data.customAction.attributes = actionAttr;
      }
      if (actionDecision) {
        data.customAction.decision = actionDecision;
      }
    }

    const aqr = get(data, `customAction.decision.qr`, []);

    const payload = {
      jsonSchema,
      nextIntent,
      intent,
      jsonStructure,
      transformationType,
      qr: [...aqr],
      actionAttr,
      actionDecision,
    };
    handleSubmitElement(data, prevElement, payload);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  async handleVerify() {
    const { jsonSchema, jsonResult, transformationType } = this.state;
    this.setState({ invalidSchema: false });
    if (transformationType === "classic" && !isJsonString(jsonSchema)) {
      this.setState({ invalidSchema: true });
      return false;
    }
    try {
      const data = {
        transformationType,
        jsonSchemaStr: jsonSchema,
        payload: jsonResult,
      };
      this.setState({ loading: true });
      const res = await CommonUtils.transformJSON(data);
      const jsonStructure = prepareStructure(res);
      this.toggle("output");
      this.setState({ jsonStructure, outputResult: res, loading: false });
    } catch (error) {
      this.setState({ invalidSchema: true });
    }
  }

  onChangePrevElement(name, value) {
    this.setState({ [name]: value }, () => {
      this.getPrevElementData();
    });
  }

  async getPrevElementData() {
    if (this.state.fetchEntityList) return;
    const customConfigData = this.props.merchantAttr.data.customConfig;
    const res = await getBotElementData(
      this.state.prevElement,
      this.props.boxes,
      this.props.connectors,
      this.state.entityList,
      customConfigData
    );
    const stateObj = { jsonResult: res.jsonResult };
    if (isArray(res.jsonStructure)) {
      stateObj.prevElementStructure = [...this.state.prevElementStructure, ...res.jsonStructure];
    }
    this.setState(stateObj, () => this.toggle("input"));
  }

  onChangeFeature(name, value, index, listName) {
    let list = this.state[listName];
    list[index][name] = value;
    this.setState({ [listName]: list });
  }

  addFeature(listName) {
    let list = this.state[listName];
    list.push({
      key: "",
      value: "",
    });
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  removeFeature(listName, index) {
    let list = this.state[listName];
    list.splice(index, 1);
    if (listName == "paramsList") {
      this.updateUrl(list);
    }
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  renderStep1() {
    const { transformationType, jsonSchema, prevElement, errors, loading, invalidSchema } = this.state;
    const { boxes, botElementId, connectors, isSmallMenu } = this.props;
    return (
      <div className="ele-content">
        <label>
          The component would provide capabilities to transform input JSON into a new format according to the schema
          provided.
        </label>
        <div className="b-form mt10">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              outerWidth={!isSmallMenu ? 0 : undefined}
              outerClassName={!isSmallMenu ? "" : undefined}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Transformation">
            <Select
              name="transformationType"
              value={transformationType}
              outerWidth={300}
              outerClassName="mb0"
              options={transformationTypes}
              onChangeFunc={(name, value) =>
                this.setState({ [name]: value }, () => {
                  if (!this.schemaTypes.includes(this.state.transformationType)) {
                    const errorObj = cloneDeep(this.state.errors);
                    delete errorObj.jsonSchema;
                    this.setState({ errors: errorObj });
                  }
                })
              }
            />
          </ElementForm>
          {this.schemaTypes.includes(transformationType) && (
            <ElementForm rowClassName="js-function" title="Transformation Schema">
              <div className="position-relative">
                <TextArea
                  value={jsonSchema}
                  rows={6}
                  title="Transformation Schema"
                  placeholder="Write your Transformation Schema..."
                  name="jsonSchema"
                  isReq={true}
                  error={errors.jsonSchema}
                  validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value, e) => this.setState({ [name]: value })}
                />
                {!errors.jsonSchema && invalidSchema && <span className="req-msg">Invalid schema format</span>}
                {transformationType === "ai" && (
                  <p className="fs13 mt-1 text-gray-600 mb-0">
                    <b>Tip:</b> When using Generative AI Transformation, please be more descriptive for each attribute
                    in the JSON.
                  </p>
                )}
                {["classic", "dsl"].includes(transformationType) && (
                  <p className="fs13 mt-1 text-gray-600 mb-0">
                    For additional details or guidance, please refer to the accompanying{" "}
                    <a href={`/transformation/${transformationType}.html`} target="_blank">
                      documentation
                    </a>
                    .
                  </p>
                )}
              </div>
            </ElementForm>
          )}
          <ElementForm title="">
            <Button
              submitBtn
              text="Test"
              customIcon="fa-play-circle"
              loading={loading}
              onClickFunc={this.handleVerify}
            />
            {this.schemaTypes.includes(transformationType) && (
              <Button
                submitBtn
                className="ml10"
                text="Workbench"
                displayIcon={false}
                onClickFunc={() => this.setState({ showWorkBench: true })}
              />
            )}
          </ElementForm>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  renderStepSetAttr() {
    return (
      <ActionSetUserData
        {...this.props}
        value={this.state.actionAttr}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionSetUserDataRef = e)}
      />
    );
  }

  renderStepDecision() {
    return (
      <ActionDecision
        {...this.props}
        value={this.state.actionDecision}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionDecisionRef = e)}
      />
    );
  }

  updateSchemaJson(schema) {
    this.setState(
      {
        jsonSchema: schema,
      },
      () => {
        this.closeWorkBench();
        this.handleVerify();
      }
    );
  }

  render() {
    const { jsonResult, outputResult, activeTab, step, showWorkBench, transformationType, jsonSchema } = this.state;
    const { resetElement, botElementId } = this.props;
    const workbenchProps = {
      closeWorkBench: this.closeWorkBench,
      updateSchemaJson: this.updateSchemaJson,
      type: "jsonTransformation",
      code: jsonResult,
      transformationType,
      schema: jsonSchema,
    };
    return (
      <React.Fragment>
        <ScrollBox scrollClass="bt-javascript" boxHeight={400}>
          {step === 1 && (
            <Col>
              <Row>
                <Col md="8">{this.renderStep1()}</Col>
                <Col md="4">
                  <div className="display-head">
                    <Nav className="nav-tab">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "input" })}
                          onClick={() => {
                            this.toggle("input");
                          }}
                        >
                          Input
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "output" })}
                          onClick={() => {
                            this.toggle("output");
                          }}
                        >
                          Output
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="input">
                        <TextArea
                          rows={14}
                          value={typeof jsonResult === "string" ? jsonResult : JSON.stringify(jsonResult, null, 2)}
                        />
                        <CopyToClipboard
                          clsName="float-right pr-0"
                          value={typeof jsonResult === "string" ? jsonResult : JSON.stringify(jsonResult, null, 2)}
                        />
                      </TabPane>
                      <TabPane tabId="output">
                        <TextArea
                          rows={14}
                          value={
                            typeof outputResult === "string" ? outputResult : JSON.stringify(outputResult, null, 2)
                          }
                        />
                        <CopyToClipboard
                          clsName="float-right pr-0"
                          value={
                            typeof outputResult === "string" ? outputResult : JSON.stringify(outputResult, null, 2)
                          }
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          {step === 2 && this.renderStepSetAttr()}
          {step === 3 && this.renderStepDecision()}
          {step === 4 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          step={step}
          totalSteps={4}
          resetElement={resetElement}
          botElementId={botElementId}
          handleSubmit={this.handleSubmit}
          handleStep={this.handleStep}
        />
        {showWorkBench && <Workbench {...workbenchProps} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    merchantAttr: state.common.merchantAttr,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(JsonTransformation);
