import {
  convertUTCtoLocal,
  convertlocaltoUTC,
  convertDate,
  getTotalMessages,
  getTotalConverseCt,
  isArray,
} from "./GeneralUtils.js";
import { sessionMerchantId } from "./SessionUtils.js";

export const getOrdersDF = obj => {
  obj.endDate = convertUTCtoLocal(obj.endDate);
  obj.startDate = convertUTCtoLocal(obj.startDate);
  if (obj.orders && obj.orders.length > 0) {
    obj.orders.map(e => {
      e.details.order_creation_date = convertDate(e.details.order_creation_date, undefined, "MM/DD/YYYY HH:mm:ss A");
    });
  }
  return obj;
};

export const getOrderByIdDF = obj => {
  if (obj.details) {
    obj.details.order_creation_date = convertDate(obj.details.order_creation_date, undefined, "MM/DD/YYYY HH:mm:ss A");
  }
  return obj;
};

export const getProductsListDF = obj => {
  //does not display
  if (obj.products && obj.products.length > 0) {
    obj.products.map(o => {
      o.activationDate = convertUTCtoLocal(o.activationDate);
    });
  }
  return obj;
};

export const getEngagementsDF = obj => {
  if (obj.engagements && obj.engagements.length > 0) {
    obj.engagements.map(e => {
      e.startDate = convertUTCtoLocal(e.startDate, "ll");
      e.endDate = convertUTCtoLocal(e.endDate, "ll");
      e.createdDate = convertUTCtoLocal(e.createdDate);
      e.lastModifiedDate = convertUTCtoLocal(e.lastModifiedDate);
    });
    // obj.engagements = obj.engagements.filter(x => x.status === 'Active');
  }
  return obj;
};

export const getUserAgentsDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate);
      e.lastModifiedDate = convertUTCtoLocal(e.lastModifiedDate);
    });
  }
  return obj;
};

export const getUserAgentByIdDF = obj => {
  if (obj && !obj.error) {
    obj.createdDate = convertUTCtoLocal(obj.createdDate);
    obj.lastModifiedDate = convertUTCtoLocal(obj.lastModifiedDate);
  }
  return obj;
};

export const getAIAgentsDF = obj => {
  //does not display
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.lastModifiedDate = convertUTCtoLocal(e.lastModifiedDate.seconds);
    });
  }
  return obj;
};

export const getKnowledgeBaseDF = obj => {
  if (obj.knowledgeBases && obj.knowledgeBases.length > 0) {
    obj.knowledgeBases.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate);
      e.modifiedDate = convertUTCtoLocal(e.modifiedDate);
      if (e.documents && e.documents.length > 0) {
        e.documents.map(d => {
          d.createdDate = convertUTCtoLocal(d.createdDate);
        });
      }
    });
  }
  return obj;
};

export const getMerchantBotDF = obj => {
  if (obj.myBots && obj.myBots.length > 0 && obj.myBots[0].bot && obj.myBots[0].bot.length > 0) {
    obj.myBots[0].bot.map(e => {
      e.purchaseDate = convertUTCtoLocal(e.purchaseDate);
    });
  }
  return obj;
};

export const getMerchantPageDF = obj => {
  if (
    obj.mrchntPages &&
    obj.mrchntPages.length > 0 &&
    obj.mrchntPages[0].pages &&
    obj.mrchntPages[0].pages.length > 0
  ) {
    obj.mrchntPages[0].pages.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate);
    });
  }
  return obj;
};

export const getContactByMerchantIdDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate, "lll", "MM-DD-YYYY HH:mm:ss");
      e.lastModifiedDate = convertUTCtoLocal(e.lastModifiedDate, "lll", "MM-DD-YYYY HH:mm:ss");
    });
  }
  return obj;
};

export const getContactListDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate);
      e.lastModifiedDate = convertUTCtoLocal(e.lastModifiedDate);
    });
  }
  return obj;
};

export const getKnwIntentDF = obj => {
  if (obj.knwDocIntents && obj.knwDocIntents.length > 0) {
    obj.knwDocIntents.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate);
    });
  }
  return obj;
};

export const getReportDF = obj => {
  if (obj.response && obj.response.length > 0 && Array.isArray(obj.response)) {
    obj.response.map(e => {
      e.accessDate = convertUTCtoLocal(e.accessDate);
      e.createdDate = convertUTCtoLocal(e.createdDate);
      e.firstVisit = convertUTCtoLocal(e.firstVisit);
      e.lastModifiedDate = convertUTCtoLocal(e.lastModifiedDate);
      e.recentVisit = convertUTCtoLocal(e.recentVisit);
    });
  }
  return obj;
};

export const getLoginSessionsDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate);
      e.lastLoginDt = convertUTCtoLocal(e.lastLoginDt);
    });
  }
  return obj;
};

export const getAllUsersDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.firstName = e.firstName || "";
      e.lastName = e.lastName || "";
      e.createTime = convertUTCtoLocal(e.createTime);
      e.modifiedTime = convertUTCtoLocal(e.modifiedTime);
    });
    return obj.filter(u => u.userName !== `jello${sessionMerchantId()}usr`);
  }
  return obj;
};

export const getConversationsDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.timestamp = convertUTCtoLocal(e.timestamp);
    });
  }
  return obj;
};

export const getVisitSummaryDF = obj => {
  obj.firstVisit = convertUTCtoLocal(obj.firstVisit);
  obj.recentVisit = convertUTCtoLocal(obj.recentVisit);
  return obj;
};

export const getDepartmentByMerchantIdDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.lastModifiedDate = convertUTCtoLocal(e.lastModifiedDate && e.lastModifiedDate.seconds);
      e.createdDate = convertUTCtoLocal(e.createdDate && e.createdDate.seconds);
    });
  }
  return obj;
};

export const getVisitorsDF = obj => {
  if (obj.visitors && obj.visitors.length > 0) {
    obj.visitors.map(e => {
      e.totalMessages = getTotalMessages(e);
      e.createdDate = convertUTCtoLocal(e.createdDate);
      e.lastAccessedDate = convertUTCtoLocal(e.lastAccessedDate, "lll", "MM-DD-YYYY HH:mm:ss");
      e.lastModifiedDate = convertUTCtoLocal(e.lastModifiedDate);
      // e.lastAccessedDate_dt = convertUTCtoLocal(e.lastAccessedDate_dt);
      e.lastAccessedDate_dt = convertDate(e.lastAccessedDate_dt);
      if (e.engagements && e.engagements.length > 0) {
        e.engagements.map(a => {
          a.accessDate = convertUTCtoLocal(a.accessDate);
          if (a.allConversations && a.allConversations.length > 0) {
            a.allConversations.map(x => {
              x.startTime = convertUTCtoLocal(x.startTime);
              x.senderConverseCt = getTotalConverseCt(x);
            });
          }
        });
      }
      if (e.tracking && e.tracking.length > 0) {
        e.tracking.map(t => {
          t.track_from = convertUTCtoLocal(t.track_from);
        });
      }
      if (Array.isArray(e.notes)) {
        e.notes.map(n => {
          n.createdDate = convertUTCtoLocal(n.createdDate, null, "MM-DD-YYYY HH:mm:ss");
        });
      }
    });
  }
  return obj;
};

export const createVisitorDF = obj => {
  if (obj) {
    obj.createdDate ? (obj.createdDate = convertlocaltoUTC(obj.createdDate, "MM-DD-YYYY HH:mm:ss")) : null;
    obj.lastAccessedDate
      ? (obj.lastAccessedDate = convertlocaltoUTC(obj.lastAccessedDate, "MM-DD-YYYY HH:mm:ss"))
      : null;
    obj.lastModifiedDate ? (obj.lastModifiedDate = convertlocaltoUTC(obj.lastModifiedDate)) : null;
    if (obj.engagements && obj.engagements.length > 0) {
      obj.engagements.map(a => {
        a.accessDate = convertlocaltoUTC(a.accessDate, "MM/DD/YYYY HH:mm:ss");
        if (a.allConversations && a.allConversations.length > 0) {
          a.allConversations.map(x => {
            x.startTime = convertlocaltoUTC(x.startTime, "MM/DD/YYYY HH:mm:ss");
          });
        }
      });
    }
    if (obj.tracking && obj.tracking.length > 0) {
      obj.tracking.map(t => {
        t.track_from = convertlocaltoUTC(t.track_from);
      });
    }
  }
  return obj;
};

export const getAgentMetricsDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(e => {
      e.accessDate = convertUTCtoLocal(e.accessDate);
    });
  }
  return obj;
};

export const getMerchantDetailsDF = obj => {
  if (obj.merchant && obj.merchant.tags) {
    obj.merchant.tags.map(c => (c.createdDate = convertUTCtoLocal(c.createdDate)));
  }
  return obj;
};

export const getActiveVisitorsDF = obj => {
  if (obj && obj.length > 0) {
    obj.map(o => {
      o.lang = o.lang && o.lang.startsWith("en-") ? "en" : o.lang || "en";
      o.totalMessages = getTotalMessages(o);
      o.createdDate = convertUTCtoLocal(o.createdDate);
      o.lastAccessedDate = convertUTCtoLocal(o.lastAccessedDate, null, "MM-DD-YYYY HH:mm:ss");
      if (o.engagements && o.engagements.length > 0) {
        o.engagements.map(e => {
          e.accessDate = convertUTCtoLocal(e.accessDate);
          if (e.allConversations && e.allConversations.length > 0) {
            e.allConversations.map(a => {
              a.startTime = convertUTCtoLocal(a.startTime);
              a.senderConverseCt = getTotalConverseCt(a);
            });
          }
        });
      }
      if (Array.isArray(o.notes)) {
        o.notes.map(n => {
          n.createdDate = convertUTCtoLocal(n.createdDate);
        });
      }
    });
  }
  return obj;
};

export const getRawVisitorsListDF = obj => {
  if (obj && obj.rawVisitors && obj.rawVisitors.length > 0) {
    obj.rawVisitors.map(o => {
      o.totalMessages = getTotalMessages(o);
      o.createdDate = convertUTCtoLocal(o.createdDate);
      o.lastAccessedDate = convertUTCtoLocal(o.lastAccessedDate);
      o.lastModifiedDate = convertUTCtoLocal(o.lastModifiedDate);
      o.lastAccessedDate_dt = convertDate(o.lastAccessedDate_dt);
      if (o.engagements && o.engagements.length > 0) {
        o.engagements.map(e => {
          e.accessDate = convertUTCtoLocal(e.accessDate);
          if (e.allConversations && e.allConversations.length > 0) {
            e.allConversations.map(a => {
              a.startTime = convertUTCtoLocal(a.startTime);
              a.senderConverseCt = getTotalConverseCt(a);
            });
          }
        });
      }
    });
  }
  return obj;
};

export const createRawVisitorDF = obj => {
  obj.createdDate = convertlocaltoUTC(obj.createdDate, "MM-DD-YYYY HH:mm:ss");
  obj.lastAccessedDate = convertlocaltoUTC(obj.lastAccessedDate, "MM-DD-YYYY HH:mm:ss");
  obj.lastModifiedDate = convertlocaltoUTC(obj.lastModifiedDate);
  if (obj.engagements && obj.engagements.length > 0) {
    obj.engagements.map(e => {
      e.accessDate = convertlocaltoUTC(e.accessDate, "MM/DD/YYYY HH:mm:ss");
      if (e.allConversations && e.allConversations.length > 0) {
        e.allConversations.map(a => {
          a.startTime = convertlocaltoUTC(a.startTime, "MM/DD/YYYY HH:mm:ss");
        });
      }
    });
  }
  return obj;
};

export const updateVisitorNotesDF = obj => {
  if (obj) {
    obj.createdDate = convertUTCtoLocal(obj.createdDate);
    obj.lastAccessedDate = convertUTCtoLocal(obj.lastAccessedDate);
    obj.lastModifiedDate = convertUTCtoLocal(obj.lastModifiedDate);
    obj.lastAccessedDate_dt = convertUTCtoLocal(obj.lastAccessedDate_dt);
    if (obj.engagements && obj.engagements.length > 0) {
      obj.engagements.map(a => {
        a.accessDate = convertUTCtoLocal(a.accessDate);
        if (a.allConversations && a.allConversations.length > 0) {
          a.allConversations.map(x => {
            x.startTime = convertUTCtoLocal(x.startTime);
          });
        }
      });
    }
    if (obj.tracking && obj.tracking.length > 0) {
      obj.tracking.map(t => {
        t.track_from = convertUTCtoLocal(t.track_from);
      });
    }
    if (Array.isArray(obj.notes)) {
      obj.notes.map(n => {
        n.createdDate = convertUTCtoLocal(n.createdDate);
      });
    }
  }
  return obj;
};

export const getTaskDF = obj => {
  if (isArray(obj)) {
    obj.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate, "lll", "YYYY-MM-DD HH:mm:ss");
      e.lastUpdateDate = convertUTCtoLocal(e.lastUpdateDate, "lll", "YYYY-MM-DD HH:mm:ss");
      e.taskClosedAt = convertUTCtoLocal(e.taskClosedAt, "lll", "YYYY-MM-DD HH:mm:ss");
    });
  } else if (isArray(obj.tasks)) {
    obj.tasks.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate, "lll", "YYYY-MM-DD HH:mm:ss");
      e.lastUpdateDate = convertUTCtoLocal(e.lastUpdateDate, "lll", "YYYY-MM-DD HH:mm:ss");
      e.taskClosedAt = convertUTCtoLocal(e.taskClosedAt, "lll", "YYYY-MM-DD HH:mm:ss");
    });
  }
  return obj;
};

export const getEscalationDF = obj => {
  if (isArray(obj)) {
    obj.map(e => {
      e.creationDate = convertUTCtoLocal(e.creationDate, "lll", "YYYY-MM-DD HH:mm:ss");
    });
  }
  return obj;
};

export const getEscalationLogDF = obj => {
  if (isArray(obj)) {
    obj.map(e => {
      e.creationDate = convertUTCtoLocal(e.creationDate, "lll", "YYYY-MM-DD HH:mm:ss");
    });
  }
  return obj;
};

export const getExecutionPlanDF = obj => {
  if (isArray(obj)) {
    obj.map(e => {
      e.executionDate = convertUTCtoLocal(e.executionDate, "lll", "YYYY-MM-DD HH:mm:ss");
    });
  }
  return obj;
};

export const getBroadcastDF = obj => {
  if (isArray(obj)) {
    obj.map(e => {
      e.createdDate = convertUTCtoLocal(e.createdDate, "MMM DD, YYYY, hh:mm:ss A");
    });
  }
  return obj;
};

export const formatCustomConfig = data => {
  if (
    data.merchant &&
    data.merchant.customConfig &&
    data.merchant.customConfig.AgentQR &&
    typeof data.merchant.customConfig.AgentQR === "object" &&
    !Array.isArray(data.merchant.customConfig.AgentQR)
  ) {
    const agentQR = data.merchant.customConfig.AgentQR;
    const keyValuePairs = Object.entries(agentQR);
    const keyValueList = [];
    for (const [key, value] of keyValuePairs) {
      keyValueList.push({ key, value });
    }
    data.merchant.customConfig.AgentQR = keyValueList;
  }
  return data;
};
