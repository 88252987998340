import React, { Component } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import { Row, Col, Collapse } from "reactstrap";
import { Button, Input, Select } from "../../../../common/components";
import SelectElement from "./SelectElement";
import SelectAttribute from "./SelectAttribute";
import { actionList } from "../../../../utils/ConstUtils";
import { addErrorObj, getRandomNumber } from "../../../../utils/GeneralUtils";
import { getFormDetails } from "../../../../common/components/helper";
import { convertPayloadToData } from "../../../../utils/BotUtils";

const initOptions = (id = getRandomNumber(6)) => ({
  id,
  text: "",
  isInteractive: false,
  connection: null,
  type: "",
  attributes: [{ attributeKey: "", attributeValue: "", errors: { attributeKey: null, attributeValue: null } }],
  errors: {
    text: null,
    connection: null,
    type: null,
  },
});

class OptionsElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionKey: Math.random(),
      optionList: [],
    };

    this.onListValidate = this.onListValidate.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.payloadQR) !== JSON.stringify(prevProps.payloadQR)) {
      this.updateData();
    }
  }

  updateData() {
    const { payloadQR, isInteractive } = this.props;
    if (payloadQR.length > 0) {
      let list = cloneDeep(payloadQR);
      list = addErrorObj(list, initOptions().errors);
      list.map(o => {
        if (!o.attributes) {
          o.attributes = [
            { attributeKey: "", attributeValue: "", errors: { attributeKey: null, attributeValue: null } },
          ];
        } else if (o.attributes) {
          o.attributes = addErrorObj(o.attributes, { attributeKey: null, attributeValue: null });
        }
        if ("connection" in o && !("type" in o)) {
          o.type = "postback";
        }
        if (!isInteractive) {
          o.isInteractive = false;
        }
      });
      this.setState({ optionList: list });
    } else this.setState({ optionList: [cloneDeep(initOptions())] });
  }

  //#region attribute events

  addAttribute(optionIndex) {
    const list = cloneDeep(this.state.optionList);
    list[optionIndex].attributes.push({
      attributeKey: "",
      attributeValue: "",
      errors: { attributeKey: null, attributeValue: null },
    });
    this.setState({ optionList: list });
  }

  removeAttribute(attrIndex, optionIndex) {
    const list = cloneDeep(this.state.optionList);
    list[optionIndex].attributes.splice(attrIndex, 1);
    this.setState({ optionList: list });
  }

  onChangeAttribute(name, value, attrIndex, optionIndex) {
    const list = cloneDeep(this.state.optionList);
    list[optionIndex].attributes[attrIndex][name] = value;
    this.setState({ optionList: list });
  }

  //#endregion

  //#region add options

  addOption() {
    const list = cloneDeep(this.state.optionList);
    list.push(cloneDeep(initOptions()));
    this.setState({ optionList: list, optionKey: Math.random() });
  }

  removeOption(index) {
    const list = cloneDeep(this.state.optionList);
    list.splice(index, 1);
    this.setState({ optionList: list, optionKey: Math.random() });
  }

  onChangeOption(name, value, index, isValid) {
    const list = cloneDeep(this.state.optionList);
    if (isValid) {
      list[index].errors[name] = null;
    }
    list[index][name] = value;
    if (name === "type") {
      list[index].attributes = initOptions().attributes;
      list[index].errors["connection"] = null;
      list[index].connection = value === "close" ? "close" : null;
    }
    this.setState({ optionList: list });
  }

  //#endregion

  onListValidate(name, error, index) {
    const { optionList } = this.state;
    optionList[index]["errors"][name] = error;
    this.setState({ optionList });
  }

  getOptions() {
    const { optionList } = this.state;
    const { strictValidation } = this.props;

    let list = cloneDeep(optionList);
    if (!strictValidation) {
      list = list.filter(x => x.text || x.type || x.connection);
    }

    let isError = false;
    list.map((a, i) => {
      const list = getFormDetails(a, (n, e) => this.onListValidate(n, e, i));
      if (!list) {
        isError = true;
        return false;
      }
    });
    if (isError) {
      return false;
    }

    return convertPayloadToData(optionList, this.props);
  }

  render() {
    const { optionKey, optionList } = this.state;
    const {
      pageTitle,
      boxes,
      botElementId,
      attributeList,
      prevElementStructure,
      actions,
      isDynamic,
      isInteractive,
      lang,
      maxItems,
    } = this.props;
    return (
      <React.Fragment>
        <label className="name">{pageTitle}</label>
        {optionList.map((obj, index) => {
          return (
            <div key={index + optionKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
              <div className="input-box">
                <Row>
                  <Col sm={3}>
                    <Input
                      type="text"
                      placeholder="Enter Title"
                      title="Title"
                      name="text"
                      isReq={true}
                      error={obj.errors.text}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={prevElementStructure}
                          onChangeFunc={value => {
                            this.onChangeOption("text", value, index, true);
                          }}
                          setAttribute={[null, obj.text]}
                        />
                      }
                      value={obj.text}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                      onChangeFunc={(name, value) => this.onChangeOption(name, value, index)}
                    />
                  </Col>
                  <Col className={lang.className} sm={3}>
                    <Select
                      outerWidth={0}
                      outerClassName=""
                      name="type"
                      title="Action"
                      placeholder="Select Action"
                      value={obj.type}
                      options={actions}
                      smallSize
                      height={35}
                      isReq={true}
                      error={obj.errors.type}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                      onChangeFunc={(name, value) => this.onChangeOption(name, value, index)}
                    />
                  </Col>

                  {obj.type && (obj.type.includes("web_url") || obj.type === "call") && (
                    <Col className={lang.className} sm={3}>
                      <Input
                        name="connection"
                        title={obj.type.includes("web_url") ? "Enter url" : "Phone Number"}
                        placeholder={obj.type.includes("web_url") ? "Enter Url" : "Enter Phone Number"}
                        style={{ minWidth: "80px" }}
                        className="form-control pr35"
                        isReq={true}
                        appendIcon={
                          <SelectAttribute
                            attributeList={attributeList}
                            externalAttributes={prevElementStructure}
                            onChangeFunc={value => this.onChangeOption("connection", value, index)}
                            setAttribute={[null, obj.connection]}
                          />
                        }
                        error={obj.errors.connection}
                        value={obj.connection || ""}
                        validationFunc={(n, e) => this.onListValidate(n, e, index)}
                        onChangeFunc={(name, value) => this.onChangeOption(name, value, index)}
                      />
                    </Col>
                  )}
                  {obj.type === "postback" && (
                    <Col className={lang.className} sm={3}>
                      <SelectElement
                        name="connection"
                        title="Connection"
                        placeholder="Select Connection"
                        value={obj.connection}
                        isReq={true}
                        error={obj.errors.connection}
                        botElementId={botElementId}
                        connectors={optionList}
                        boxes={boxes}
                        outerWidth={0}
                        outerClassName=""
                        defaultSelected={false}
                        onChangeFunc={(name, value) => this.onChangeOption(name, value, index)}
                        validationFunc={(n, e) => this.onListValidate(n, e, index)}
                      />
                    </Col>
                  )}
                  {isInteractive && (
                    <Col className={lang.className} md={2}>
                      <label style={{ lineHeight: "36px" }}>
                        Interactive
                        <Input
                          type="checkbox"
                          name="isInteractive"
                          outerClassName="float-left mr10 mb0"
                          style={{ width: 20 }}
                          checked={obj.isInteractive}
                          onChangeFunc={(name, value) => this.onChangeOption(name, !obj.isInteractive, index)}
                        />
                      </label>
                    </Col>
                  )}
                  <Col className={lang.className} md={1}>
                    {!isDynamic && (
                      <div className="btn-box">
                        {index === optionList.length - 1 && (!maxItems || maxItems > optionList.length) ? (
                          <Button plusBtn className="d-inline mr10" onClickFunc={() => this.addOption()} />
                        ) : null}
                        {optionList.filter(x => !x.isDefault).length > 1 ? (
                          <Button minusBtn className="d-inline" onClickFunc={() => this.removeOption(index)} />
                        ) : null}
                      </div>
                    )}
                  </Col>
                </Row>

                {obj.type === "postback" &&
                  obj.attributes.map((attr, attrIndex) => {
                    return (
                      <Row key={attrIndex}>
                        <Col sm={3} className="text-right">
                          <label className="name mt5">Set Attribute</label>
                        </Col>
                        <Col className={lang.className} sm={4}>
                          <Input
                            name="attributeKey"
                            title="Key"
                            placeholder="Enter Key"
                            isReq={true}
                            error={attr.errors.attributeKey}
                            value={attr.attributeKey}
                            onChangeFunc={(name, value) => this.onChangeAttribute(name, value, attrIndex, index)}
                            // validationFunc={(n, e) => this.onListValidate(n, e, index)}
                          />
                        </Col>
                        <Col sm={3}>
                          <Input
                            name="attributeValue"
                            title="value"
                            placeholder="Enter Value"
                            isReq={true}
                            error={attr.errors.attributeValue}
                            appendIcon={
                              <SelectAttribute
                                attributeList={attributeList}
                                externalAttributes={prevElementStructure}
                                onChangeFunc={value =>
                                  this.onChangeAttribute("attributeValue", value, attrIndex, index)
                                }
                                setAttribute={[null, attr.attributeValue]}
                              />
                            }
                            className="form-control pr35"
                            value={attr.attributeValue}
                            onChangeFunc={(name, value) => this.onChangeAttribute(name, value, attrIndex, index)}
                            // validationFunc={(n, e) => this.onListValidate(n, e, index)}
                          />
                        </Col>
                        <Col className={lang.className} sm={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {attrIndex === obj.attributes.length - 1 ? (
                              <Button plusBtnDark className=" mr10" onClickFunc={() => this.addAttribute(index)} />
                            ) : null}
                            {attrIndex !== 0 || obj.attributes.length > 1 ? (
                              <Button minusBtnDark onClickFunc={() => this.removeAttribute(attrIndex, index)} />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
              <div className="clearfix"></div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

OptionsElement.defaultProps = {
  pageTitle: "Add Options",
  hideTitle: false,
  payloadQR: [],
  actions: actionList,
  strictValidation: false,
  isDynamic: false,
  isInteractive: false,
  isDisable: false,
  lang: {
    className: "",
  },
};

OptionsElement.propTypes = {
  pageTitle: PropTypes.string,
  hideTitle: PropTypes.bool,
  boxes: PropTypes.any.isRequired,
  botElementId: PropTypes.any.isRequired,
  attributeList: PropTypes.any.isRequired,
  payloadQR: PropTypes.any.isRequired,
  actions: PropTypes.any,
  strictValidation: PropTypes.bool,
  isDynamic: PropTypes.bool,
  isInteractive: PropTypes.bool,
  isDisable: PropTypes.bool,
  lang: PropTypes.object,
  maxItems: PropTypes.number,
};

export default OptionsElement;
