import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Input, Select, ScrollBox } from "../../../common/components";
import { isArray, validationTypeList } from "../../../utils/GeneralUtils";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { getFormDetails } from "../../../common/components/helper.js";
import {
  SelectElement,
  SelectIntent,
  ElementForm,
  SubmitElement,
  OptionsElement,
  ActionSetUserData,
  ActionDecision,
  AdvanceOptionsExpand,
  SelectAttribute,
} from "./components";
import {
  getAttrByPrevElement,
  getButtonPayload,
  loadBotPayload,
  loadDefaultBotPayload,
  updateBotPayload,
} from "../../../utils/BotUtils";
import { cloneDeep, keys, get } from "lodash";

const LANG_STRUCTURE = {
  userPrompt: "",
  validationPrompt: "",
  dataQR: [],
  payloadQR: [],
  allowBtn: "",
  denyBtn: "",
  submitBtn: "",
  scanBtn: "",
};

class RequestUserData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waTemplate: false,
      legacy_message: false,
      skipIfAvailable: false,
      type: "standard",
      dateFormat: "MM-DD-YYYY",
      regEx: "",
      regExTest: "",
      isValidRegExTest: false,
      isValidRegEx: true,
      defaultDate: "none",
      dateType: "input",
      futureDate: true,
      pastDate: true,
      referenceDate: "",
      timeFormat: "HH:mm",
      defaultTime: "none",
      timeType: "input",
      futureTime: true,
      pastTime: true,
      userPrompt: "",
      rateFor: "5",
      validationType: validationTypeList[0].value,
      optional: "NO",
      attribute: "",
      validationPrompt: "",
      nextIntent: "",
      intent: null,
      prevElementStructure: [],
      externalAttributes: [],
      dataQR: [],
      payloadQR: [],
      step: 1,
      errors: {
        userPrompt: null,
        attribute: null,
        validationPrompt: null,
      },
      langPayload: loadDefaultBotPayload(LANG_STRUCTURE, props.lang),
      actionAttr: null,
      currentIntent: "",
      actionDecision: null,
      allowBtn: "Allow",
      denyBtn: "Deny",
      submitBtn: "Submit",
      scanBtn: "Scan",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onAdvanceOptionChange = this.onAdvanceOptionChange.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.checkLanguageForBtns = this.checkLanguageForBtns.bind(this);
    this.showLocationLanguageBtns = this.showLocationLanguageBtns.bind(this);
    this.showDateAndTimeLanguageBtn = this.showDateAndTimeLanguageBtn.bind(this);
    this.showBarCodeLanguageBtn = this.showBarcodeLanguageBtn.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId, lang } = this.props;
    const box = boxes.find(b => b.id === botElementId);

    if (box) {
      const {
        intent,
        userPrompt,
        validationType,
        optional = "NO",
        attribute,
        validationPrompt,
        nextIntent,
        waTemplate = false,
        legacy_message = false,
        skipIfAvailable = false,
        oqr,
        qr,
        ...rest
      } = box.payload;
      let stateObj = {
        userPrompt,
        validationType,
        optional,
        attribute,
        validationPrompt,
        nextIntent,
        intent,
        waTemplate,
        legacy_message,
        skipIfAvailable,
        ...rest,
      };
      if (oqr ? isArray(oqr) : isArray(qr)) {
        stateObj = {
          ...stateObj,
          ...loadBotPayload(LANG_STRUCTURE, box.payload, this.state, lang),
        };
      }
      this.setState(stateObj, () => this.updatePayload());
    }
  }

  updatePayload(prevLang = this.props.lang, callback = () => {}) {
    const props = {
      boxes: this.props.boxes,
      botVersion: this.props.botVersion,
      isInteractive: this.state.waTemplate,
    };
    const langPayload = updateBotPayload(LANG_STRUCTURE, this.state, prevLang, props);
    this.setState(
      {
        langPayload,
        ...langPayload[this.props.lang.selectedLang],
      },
      callback
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang.selectedLang !== prevProps.lang.selectedLang) {
      if (this.state.step === 2) {
        this.handleStep(3, false, prevProps.lang);
      } else {
        this.updatePayload(prevProps.lang);
      }
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({
      [name]: value,
      prevElementStructure: getAttrByPrevElement(value, boxes, elementType),
    });
  }

  onAdvanceOptionChange(name, value, err, obj, e) {
    if (["waTemplate", "legacy_message", "skipIfAvailable"].includes(name)) {
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleChange(name, value) {
    const { regExTest } = this.state;
    if (name === "regExTest") {
      this.validateRegEx(value);
    }
    if (name === "regEx") {
      this.setState({ [name]: value }, () => {
        this.validateRegEx(regExTest);
      });
      return false;
    }
    if (name === "validationType" && (value === "DATE" || value === "TIME")) {
      this.setState({ optional: "NO" });
    }

    this.setState({ [name]: value });
  }

  handleCheckbox(name) {
    this.setState({ [name]: !this.state[name] });
  }

  validateRegEx(regExTest) {
    const { regEx } = this.state;
    let regex;
    try {
      regex = new RegExp(regEx);
      this.setState({ isValidRegEx: true });
    } catch (e) {
      this.setState({ isValidRegEx: false });
      return false;
    }
    let isValidRegExTest = regex.test(regExTest);
    this.setState({ isValidRegExTest });
  }

  getDateTimeData(validationType) {
    const {
      dateFormat,
      defaultDate,
      dateType,
      futureDate,
      pastDate,
      referenceDate,
      timeFormat,
      defaultTime,
      timeType,
      futureTime,
      pastTime,
    } = this.state;
    const timeData = {
      timeFormat,
      defaultTime,
      timeType,
      futureTime,
      pastTime,
    };
    const dateData = {
      dateFormat,
      defaultDate,
      dateType,
      futureDate,
      pastDate,
      referenceDate: !(pastDate && futureDate) ? referenceDate : "",
    };
    if (validationType === "DATE") {
      return dateData;
    } else if (validationType === "TIME") {
      return timeData;
    }
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }
  handleStep(step, isSubmit = false, prevLang) {
    const stateObj = prevLang ? {} : { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
      stateObj.prevElementStructure = [
        { label: obj.attribute, value: `\${userInput.${obj.attribute}!}` },
        ...this.state.prevElementStructure,
      ];
    }
    if (step === 3 && this.optionRef) {
      const obj = this.optionRef.getOptions();
      if (!obj) return false;
      else {
        stateObj.dataQR = obj.dataQR;
        stateObj.payloadQR = obj.payloadQR;
      }
    }
    if (step === 4 && this.actionSetUserDataRef) {
      const obj = this.actionSetUserDataRef.getData();
      if (!obj) return false;
      stateObj.actionAttr = obj.skip ? null : obj.data;
      if (!obj.skip && obj.attrStructure) {
        stateObj.prevElementStructure = [...this.state.prevElementStructure, ...obj.attrStructure];
      }
    }
    if (step === 5 && this.actionDecisionRef) {
      const obj = this.actionDecisionRef.getData();
      if (!obj) return false;
      stateObj.actionDecision = obj.skip ? null : obj;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => this.updatePayload(prevLang, isSubmit ? this.handleSubmit : null));
  }

  handleSubmit() {
    const { handleSubmitElement, lang } = this.props;
    const {
      validationType,
      optional,
      attribute,
      prevElement,
      nextIntent,
      intent,
      regEx,
      rateFor,
      // payloadQR,
      // dataQR,
      waTemplate,
      legacy_message,
      skipIfAvailable,
      langPayload,
      actionAttr,
      actionDecision,
      currentIntent,
    } = this.state;

    const dateTimeData = this.getDateTimeData(validationType);

    const submitCb = () => {
      const langDataObj = {};
      const langPayloadObj = {};
      let defaultPayloadQR = [];
      const showLocationLanguageBtn = this.showLocationLanguageBtns();
      const showDateAndTimeLanguageBtn = this.showDateAndTimeLanguageBtn();
      const showBarcodeLanguageBtn = this.showBarcodeLanguageBtn();
      keys(langPayload).map(x => {
        const { dataQR, payloadQR, userPrompt, validationPrompt, allowBtn, denyBtn, submitBtn, scanBtn } =
          langPayload[x];
        const sQR = cloneDeep(dataQR);
        const sPQR = cloneDeep(payloadQR);

        if (x === lang.defaultLang) {
          defaultPayloadQR = cloneDeep(sPQR);
        }

        langDataObj[x] = {
          entity: "No",
          quick_replies: sQR,
          attributes: [
            {
              prompt: userPrompt,
              validator: validationType,
              optional,
              attribute_name: attribute,
              validator_prompt: validationPrompt,
              request_params: [
                {
                  dateTimeData: dateTimeData,
                  rateFor: validationType === "RATING" ? rateFor : undefined,
                  regEx: validationType === "REGEX" ? regEx : undefined,
                  skipIfAvailable,
                  attributeValue: skipIfAvailable ? `\${(userInput.${attribute})!}` : "",
                  allowBtn: showLocationLanguageBtn ? allowBtn : null,
                  denyBtn: showLocationLanguageBtn ? denyBtn : null,
                  submitBtn: showDateAndTimeLanguageBtn ? submitBtn : null,
                  scanBtn: showBarcodeLanguageBtn ? scanBtn : null,
                  // (validationType === 'REGEX' && regEx)
                },
              ],
            },
          ],
          next_intent: nextIntent,
          waTemplate,
          legacy_message,
        };

        if (isArray(actionAttr) || actionDecision) {
          langDataObj[x].customAction = {
            next_intent: nextIntent,
            current_intent: currentIntent,
            attribute_value: skipIfAvailable ? `\${(userInput.${attribute})!}` : `\${(payLoad.${attribute})!}`,
          };

          if (isArray(actionAttr)) {
            langDataObj[x].customAction.attributes = actionAttr;
          }
          if (actionDecision) {
            langDataObj[x].customAction.decision = actionDecision;
          }
        }

        langPayloadObj[x] = {
          userPrompt,
          validationPrompt,
          payloadQR: sPQR.map(p => getButtonPayload(p)),
          allowBtn: showLocationLanguageBtn ? allowBtn : null,
          denyBtn: showLocationLanguageBtn ? denyBtn : null,
          submitBtn: showDateAndTimeLanguageBtn ? submitBtn : null,
          scanBtn: showBarcodeLanguageBtn ? scanBtn : null,
        };
      });

      return { langDataObj, langPayloadObj, defaultPayloadQR };
    };

    const { langDataObj, langPayloadObj, defaultPayloadQR } = submitCb();
    const { userPrompt, validationPrompt, allowBtn, denyBtn, submitBtn, scanBtn } = langPayloadObj[lang.defaultLang];

    const aqr = get(langDataObj, `[${lang.defaultLang}].customAction.decision.qr`, []);
    const showLocationLanguageBtn = this.showLocationLanguageBtns();
    const showDateAndTimeLanguageBtn = this.showDateAndTimeLanguageBtn();
    const showBarcodeLanguageBtn = this.showBarcodeLanguageBtn();

    const payload = {
      intent,
      userPrompt,
      validationType,
      attribute,
      validationPrompt,
      nextIntent,
      optional,
      regEx,
      rateFor,
      oqr: defaultPayloadQR,
      qr: [...defaultPayloadQR, ...aqr],
      waTemplate,
      legacy_message,
      skipIfAvailable,
      ...dateTimeData,
      langPayload: langPayloadObj,
      actionAttr,
      actionDecision,
      currentIntent,
      allowBtn: showLocationLanguageBtn ? allowBtn : null,
      denyBtn: showLocationLanguageBtn ? denyBtn : null,
      submitBtn: showDateAndTimeLanguageBtn ? submitBtn : null,
      scanBtn: showBarcodeLanguageBtn ? scanBtn : null,
    };

    handleSubmitElement(langDataObj[lang.defaultLang], prevElement, payload, langDataObj);
  }
  renderRating() {
    const { rateFor } = this.state;
    const { lang } = this.props;
    return (
      <ElementForm className={lang.className} title="Rating" rowClassName="mb10">
        <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
          <Input
            type="radio"
            name="rateFor"
            outerClassName="float-left mr10 mb0"
            style={{ width: 20 }}
            value="5"
            checked={rateFor === "5"}
            onChangeFunc={this.handleChange}
          />
          For 5
        </label>
        <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
          <Input
            type="radio"
            name="rateFor"
            outerClassName="float-left mr10 mb0"
            style={{ width: 20 }}
            value="10"
            checked={rateFor === "10"}
            onChangeFunc={this.handleChange}
          />
          For 10
        </label>
      </ElementForm>
    );
  }
  renderDate() {
    const { dateFormat, defaultDate, dateType, futureDate, pastDate, referenceDate, prevElementStructure } = this.state;
    const { attributeList } = this.props;

    return (
      <React.Fragment>
        <ElementForm title="Date Format" rowClassName="mb10">
          <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="dateFormat"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="MM-DD-YYYY"
              checked={dateFormat === "MM-DD-YYYY"}
              onChangeFunc={this.handleChange}
            />
            MM-DD-YYYY
          </label>
          <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="dateFormat"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="DD-MM-YYYY"
              checked={dateFormat === "DD-MM-YYYY"}
              onChangeFunc={this.handleChange}
            />
            DD-MM-YYYY
          </label>
          <label className="mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="dateFormat"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="YYYY-MM-DD"
              checked={dateFormat === "YYYY-MM-DD"}
              onChangeFunc={this.handleChange}
            />
            YYYY-MM-DD
          </label>
        </ElementForm>
        <ElementForm title="Default Date" rowClassName="mb10">
          <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="defaultDate"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="none"
              checked={defaultDate === "none"}
              onChangeFunc={this.handleChange}
            />
            None
          </label>
          <label className="mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="defaultDate"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="current"
              checked={defaultDate === "current"}
              onChangeFunc={this.handleChange}
            />
            Current
          </label>
        </ElementForm>
        <ElementForm title="Type" rowClassName="mb10">
          <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="dateType"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="input"
              checked={dateType === "input"}
              onChangeFunc={this.handleChange}
            />
            Input
          </label>
          <label className="mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="dateType"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="datePicker"
              checked={dateType === "datePicker"}
              onChangeFunc={this.handleChange}
            />
            Date Picker
          </label>
        </ElementForm>
        {dateType === "datePicker" && (
          <ElementForm title="Allow Dates" rowClassName="mb10">
            <div className="d-flex">
              <label className="flex-shrink-0 mr20 mb0" style={{ lineHeight: "33px" }}>
                <Input
                  type="checkbox"
                  name="pastDate"
                  outerClassName="float-left mr10 mb0"
                  style={{ width: 20 }}
                  checked={pastDate}
                  onChangeFunc={this.handleCheckbox}
                />
                Past
              </label>
              <label className="flex-shrink-0 mb0" style={{ lineHeight: "33px" }}>
                <Input
                  type="checkbox"
                  name="futureDate"
                  outerClassName="float-left mr10 mb0"
                  style={{ width: 20 }}
                  checked={futureDate}
                  onChangeFunc={this.handleCheckbox}
                />
                Future
              </label>
              {!(pastDate && futureDate) && (
                <Input
                  name="referenceDate"
                  title="Reference Date"
                  placeholder="Enter Reference Date"
                  outerClassName="mb0 ml-3 w-100"
                  value={referenceDate}
                  onChangeFunc={this.handleChange}
                  appendIcon={
                    <SelectAttribute
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={value => this.setState({ referenceDate: value })}
                      setAttribute={[null, referenceDate]}
                    />
                  }
                />
              )}
            </div>
          </ElementForm>
        )}
      </React.Fragment>
    );
  }

  renderTime() {
    const { timeFormat, defaultTime, timeType, futureTime, pastTime } = this.state;
    return (
      <React.Fragment>
        <ElementForm title="Time Format" rowClassName="mb10">
          <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="timeFormat"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="HH:mm"
              checked={timeFormat === "HH:mm"}
              onChangeFunc={this.handleChange}
            />
            24 Hour
          </label>
          <label className="mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="timeFormat"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="hh:mm A"
              checked={timeFormat === "hh:mm A"}
              onChangeFunc={this.handleChange}
            />
            AM/PM
          </label>
        </ElementForm>
        <ElementForm title="Default Time" rowClassName="mb10">
          <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="defaultTime"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="none"
              checked={defaultTime === "none"}
              onChangeFunc={this.handleChange}
            />
            None
          </label>
          <label className="mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="defaultTime"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="current"
              checked={defaultTime === "current"}
              onChangeFunc={this.handleChange}
            />
            Current
          </label>
        </ElementForm>
        <ElementForm title="Type" rowClassName="mb10">
          <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="timeType"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="input"
              checked={timeType === "input"}
              onChangeFunc={this.handleChange}
            />
            Input
          </label>
          <label className="mb0" style={{ lineHeight: "33px" }}>
            <Input
              type="radio"
              name="timeType"
              outerClassName="float-left mr10 mb0"
              style={{ width: 20 }}
              value="timePicker"
              checked={timeType === "timePicker"}
              onChangeFunc={this.handleChange}
            />
            Time Picker
          </label>
        </ElementForm>
      </React.Fragment>
    );
  }

  renderIconTest(regExTest) {
    const { isValidRegExTest } = this.state;
    return (
      regExTest.length > 0 && (
        <span className="valid-regex-icon">
          {isValidRegExTest ? (
            <Tooltip placement="top" trigger={["hover"]} overlay={<span>Valid test string</span>}>
              <i className={`fa fa-check-circle text-success`}></i>
            </Tooltip>
          ) : (
            <Tooltip placement="top" trigger={["hover"]} overlay={<span>Invalid test string</span>}>
              <i className={`fa fa-exclamation-circle text-danger`}></i>
            </Tooltip>
          )}
        </span>
      )
    );
  }

  renderIconRegex(regEx) {
    const { isValidRegEx } = this.state;
    return (
      regEx.length > 0 && (
        <span className="valid-regex-icon">
          {isValidRegEx ? (
            <Tooltip placement="top" trigger={["hover"]} overlay={<span>Valid REGEX pattern</span>}>
              <i className={`fa fa-check-circle text-success`}></i>
            </Tooltip>
          ) : (
            <Tooltip placement="top" trigger={["hover"]} overlay={<span>Invalid REGEX pattern</span>}>
              <i className={`fa fa-exclamation-circle text-danger`}></i>
            </Tooltip>
          )}
        </span>
      )
    );
  }

  checkLanguageForBtns() {
    const { lang } = this.props;
    // if true, will allow language change input
    const checkLanguage = lang.list.length > 1 || (lang.list.length === 1 && lang.selectedLang !== "en");
    return checkLanguage;
  }

  showLocationLanguageBtns() {
    const { validationType } = this.state;
    return validationType === "LOCATION" && this.checkLanguageForBtns();
  }

  showDateAndTimeLanguageBtn() {
    const { validationType } = this.state;
    return (validationType === "DATE" || validationType === "TIME") && this.checkLanguageForBtns();
  }

  showBarcodeLanguageBtn() {
    const { validationType } = this.state;
    return validationType === "SCANNER" && this.checkLanguageForBtns();
  }

  renderRegEx() {
    const { regEx, regExTest, validationType, errors } = this.state;
    return (
      <React.Fragment>
        <ElementForm title="RegEx">
          <div className="reg-test">
            <Input
              name="regEx"
              title="RegEx"
              placeholder="Regular Expression"
              outerClassName="reg-test-input mb0"
              value={regEx}
              onChangeFunc={this.handleChange}
            />
            {this.renderIconRegex(regEx)}
          </div>
        </ElementForm>
        <ElementForm title="RegEx Test">
          <div className="reg-test">
            <Input
              name="regExTest"
              title="RegEx Test"
              placeholder="Enter string to test RegEx"
              outerClassName="reg-test-input mb0"
              value={regExTest}
              onChangeFunc={this.handleChange}
            />
            {this.renderIconTest(regExTest)}
          </div>
        </ElementForm>
      </React.Fragment>
    );
  }

  renderStep1() {
    const { botElementId, boxes, connectors, lang } = this.props;
    const {
      type,
      userPrompt,
      validationType,
      optional,
      attribute,
      prevElement,
      validationPrompt,
      errors,
      waTemplate,
      legacy_message,
      skipIfAvailable,
      allowBtn,
      denyBtn,
      submitBtn,
      scanBtn,
    } = this.state;
    const isDateTime = validationType === "DATE" || validationType === "TIME";
    const showLocationLanguageBtn = this.showLocationLanguageBtns();
    const showLangBtnForDateTime = this.showDateAndTimeLanguageBtn();
    const showBarcodeLanguageBtn = this.showBarcodeLanguageBtn();
    return (
      <div className="ele-content">
        <label>Get user input and set the value for an attribute or an entity.</label>
        <div className="b-form mt10">
          <ElementForm title="Type" rowClassName={`mb10 ${lang.className}`}>
            <label className="mr20 mb0" style={{ lineHeight: "33px" }}>
              <Input
                type="radio"
                name="type"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                value="standard"
                checked={type === "standard"}
                onChangeFunc={this.handleChange}
              />
              Standard
            </label>
            <label className="mb0" style={{ lineHeight: "33px" }}>
              <Input
                type="radio"
                name="type"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                value="entity"
                checked={type === "entity"}
                onChangeFunc={this.handleChange}
              />
              Entity
            </label>
          </ElementForm>
          <ElementForm title="User Prompt">
            <Input
              name="userPrompt"
              title="User Prompt"
              placeholder="Request user input with message"
              outerClassName="mb0"
              value={userPrompt}
              isReq={true}
              error={errors.userPrompt}
              onChangeFunc={this.handleChange}
              validationFunc={this.onInputValidate}
            />
          </ElementForm>
          <ElementForm rowClassName={lang.className} title="Validation Type">
            <Select
              name="validationType"
              placeholder="Validation Type"
              outerClassName="mb0"
              options={validationTypeList}
              value={validationType}
              isClearable={false}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          {!isDateTime && (
            <ElementForm title="Optional" rowClassName={`mb10 ${lang.className}`}>
              <Input
                type="checkbox"
                name="optional"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={optional === "YES"}
                onChangeFunc={(name, value, err, obj, e) =>
                  this.setState({ [name]: e.target.checked ? "YES" : "NO" }, () => {
                    const newErrors = { ...this.state.errors };
                    if (this.state.optional === "YES") {
                      delete newErrors.validationPrompt;
                    } else {
                      newErrors.validationPrompt = null;
                    }
                    this.setState({ errors: newErrors });
                  })
                }
              />
            </ElementForm>
          )}
          <div className={lang.className}>
            {validationType === "DATE" && this.renderDate()}
            {validationType === "TIME" && this.renderTime()}
            {validationType === "RATING" && this.renderRating()}
            {validationType === "REGEX" && this.renderRegEx()}
          </div>
          <ElementForm title="Validation Prompt">
            <Input
              name="validationPrompt"
              title="Validation Prompt"
              placeholder="Validation Prompt"
              outerClassName="mb0"
              value={validationPrompt}
              onChangeFunc={this.handleChange}
              {...(optional !== "YES"
                ? {
                    isReq: true,
                    error: errors.validationPrompt,
                    validationFunc: this.onInputValidate,
                  }
                : {})}
            />
          </ElementForm>
          <ElementForm rowClassName={lang.className} title="Attribute">
            <Input
              name="attribute"
              title="Attribute"
              placeholder="Attribute name"
              outerClassName="mb0"
              value={attribute}
              isReq={true}
              reqType="attribute"
              error={errors.attribute}
              onChangeFunc={this.handleChange}
              validationFunc={this.onInputValidate}
            />
            <label className="mb0">Attribute should not contain any symbols and whitespace.</label>
          </ElementForm>
          <ElementForm rowClassName={lang.className} prevElement>
            <SelectElement
              menuPlacement="top"
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>

          {showLocationLanguageBtn ? (
            <ElementForm title="">
              <Row>
                <Col md={6}>
                  <label>Allow Button Name</label>
                  <Input
                    name="allowBtn"
                    placeholder="Enter button name"
                    outerClassName="mb0"
                    value={allowBtn}
                    onChangeFunc={this.handleChange}
                  />
                </Col>
                <Col md={6}>
                  <label>Deny Button Name</label>
                  <Input
                    name="denyBtn"
                    placeholder="Enter button name"
                    outerClassName="mb0"
                    value={denyBtn}
                    onChangeFunc={this.handleChange}
                  />
                </Col>
              </Row>
            </ElementForm>
          ) : null}

          {showLangBtnForDateTime ? (
            <ElementForm title="">
              <Row>
                <Col md={6}>
                  <label>Submit Button Name</label>
                  <Input
                    name="submitBtn"
                    placeholder="Enter button name"
                    outerClassName="mb0"
                    value={submitBtn}
                    onChangeFunc={this.handleChange}
                  />
                </Col>
              </Row>
            </ElementForm>
          ) : null}

          {showBarcodeLanguageBtn ? (
            <ElementForm title="">
              <Row>
                <Col md={6}>
                  <label>Scan Button Name</label>
                  <Input
                    name="scanBtn"
                    placeholder="Enter button name"
                    outerClassName="mb0"
                    value={scanBtn}
                    onChangeFunc={this.handleChange}
                  />
                </Col>
              </Row>
            </ElementForm>
          ) : null}

          <AdvanceOptionsExpand
            waTemplate={waTemplate}
            legacy_message={legacy_message}
            skipIfAvailable={skipIfAvailable}
            onChangeFunc={this.onAdvanceOptionChange}
            displayList={["waTemplate", "legacy_message", "skipIfAvailable"]}
          />
        </div>
      </div>
    );
  }

  renderStep2() {
    const { prevElementStructure, payloadQR } = this.state;
    const { lang } = this.props;
    return (
      <div className="ele-content">
        <OptionsElement
          {...this.props}
          payloadQR={isArray(payloadQR) ? payloadQR : undefined}
          ref={e => (this.optionRef = e)}
          prevElementStructure={prevElementStructure}
          lang={lang}
        />
      </div>
    );
  }

  renderStep3() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId, lang } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={this.handleChange}
          lang={lang}
        />
      </div>
    );
  }

  renderStepSetAttr() {
    return (
      <ActionSetUserData
        {...this.props}
        value={this.state.actionAttr}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionSetUserDataRef = e)}
      />
    );
  }

  renderStepDecision() {
    return (
      <ActionDecision
        {...this.props}
        value={this.state.actionDecision}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionDecisionRef = e)}
      />
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId, lang } = this.props;

    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-req-user-data" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
          {step === 3 && this.renderStepSetAttr()}
          {step === 4 && this.renderStepDecision()}
          {step === 5 && this.renderStep3()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={() => this.updatePayload(lang, this.handleSubmit)}
          step={step}
          totalSteps={5}
          botElementId={botElementId}
          handleStep={this.handleStep}
          lang={lang}
        />
      </React.Fragment>
    );
  }
}

export default RequestUserData;
