import React from "react";
import { connect } from "react-redux";
import { Select, ScrollBox, Input, TextArea } from "../../../common/components";
import CommonUtils from "../../../utils/CommonUtils";
import { SelectElement, ElementForm, SubmitElement, SelectIntent, SelectAttribute } from "./components";
import { roleList, taskPriorityList, taskModeList, taskStatusList, isArray } from "../../../utils/GeneralUtils";
import { getAttrByPrevElement } from "../../../utils/BotUtils";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";

class Task extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAgent: "ANY||",
      taskType: null,
      subType: null,
      taskName: "",
      taskDescription: "",
      priority: taskPriorityList[0].value,
      taskStatus: taskStatusList[0].value,
      mode: taskModeList[0].value,
      intent: null,
      step: 1,
      userAgentList: [],
      prevElementStructure: [],
      nextIntent: "",
      subTypeList: [],
      departmentList: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
  }

  componentDidMount() {
    this.getAllUsers();
    this.getAllDepartment();
    const { boxes, botElementId, taskTypeList } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        userAgent,
        nextIntent,
        taskType,
        subType,
        intent,
        taskName,
        taskDescription,
        priority,
        taskStatus,
        taskDepartment,
        mode,
      } = box.payload;
      const stateObj = {
        userAgent,
        nextIntent,
        taskType,
        subType,
        intent,
        taskName,
        taskDescription,
        priority,
        taskStatus,
        taskDepartment,
        mode: mode || "task",
      };
      if (taskType) {
        const obj = taskTypeList.find(t => t.value === taskType);
        if (obj && obj.subTypeList) {
          stateObj.subTypeList = obj.subTypeList || [];
        }
      }
      this.setState(stateObj);
    }
  }

  getAllDepartment() {
    CommonUtils.getMerchantDepartment().then(response => {
      let departmentList = [];
      if (response.error) {
        this.setState({ departmentList });
        return false;
      }
      if (response.departments) {
        departmentList = response.departments.map(d => ({ label: d.deptName, value: d.deptId }));
      }
      this.setState({ departmentList });
    });
  }

  getAllUsers() {
    CommonUtils.getAllUsers().then(response => {
      if (response.error) {
        this.setState({ userAgentList: [] });
        return false;
      }
      const list = response.map(l => ({
        label: `${l.firstName} ${l.lastName} (${(this.props.roleList.find(x => x.value === l.role) || { label: l.role }).label})`,
        value: `${l.id}|${l.firstName || ""}|${l.lastName || ""}`,
      }));
      this.setState({ userAgentList: [{ label: "Any", value: "ANY||" }, ...list] });
    });
  }

  handleStep(step, isSubmit = false) {
    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      userAgent,
      prevElement,
      nextIntent,
      taskType,
      subType,
      intent,
      taskName,
      taskDescription,
      priority,
      taskStatus,
      taskDepartment,
      mode,
      departmentList,
    } = this.state;
    const { handleSubmitElement } = this.props;

    const userAgentData = userAgent.split("|");
    const selectedDept = departmentList.find(d => d.value == taskDepartment);
    const data = {
      input_type: "task",
      next_intent: nextIntent,
      request_params: [
        {
          sessionId: BOT_ATTRIBUTES.SESSION_ID,
          agentId: userAgentData[0],
          firstName: userAgentData[1],
          lastName: userAgentData[2],
          taskType,
          subType,
          taskName,
          taskDescription,
          priority,
          taskStatus,
          ...(selectedDept
            ? {
                department: {
                  name: selectedDept.label,
                  id: selectedDept.value,
                },
              }
            : []),
          mode,
        },
      ],
    };

    const payload = {
      userAgent,
      nextIntent,
      intent,
      agentId: userAgentData[0],
      firstName: userAgentData[1],
      lastName: userAgentData[2],
      taskType,
      subType,
      taskName,
      taskDescription,
      priority,
      taskStatus,
      taskDepartment,
      mode,
    };

    handleSubmitElement(data, prevElement, payload);
  }

  handleChange(name, value, err, ddlObj) {
    const stateObj = { [name]: value };
    if (name === "taskType") {
      stateObj.subType = null;
      stateObj.subTypeList = ddlObj.subTypeList || [];
    }
    this.setState(stateObj);
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  renderStep1() {
    const { boxes, botElementId, connectors, attributeList, taskTypeList } = this.props;
    const {
      userAgent,
      userAgentList,
      prevElement,
      taskType,
      subType,
      taskName,
      taskDescription,
      priority,
      taskStatus,
      mode,
      prevElementStructure,
      subTypeList,
      taskDepartment,
      departmentList,
    } = this.state;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Name">
            <Input
              name="taskName"
              placeholder="Enter Name"
              outerClassName="mb0"
              value={taskName}
              onChangeFunc={this.handleChange}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={[...prevElementStructure]}
                  onChangeFunc={value => this.handleChange("taskName", value)}
                  setAttribute={[null, taskName]}
                />
              }
            />
          </ElementForm>
          <ElementForm title="Type">
            <Select
              name="taskType"
              outerClassName="mb0"
              value={taskType}
              options={taskTypeList}
              isClearable={false}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          {isArray(subTypeList) && (
            <ElementForm title="Sub Type">
              <Select
                name="subType"
                outerClassName="mb0"
                value={subType}
                options={subTypeList}
                isClearable={false}
                onChangeFunc={this.handleChange}
              />
            </ElementForm>
          )}
          <ElementForm title="Description">
            <TextArea
              placeholder="Enter Description"
              name="taskDescription"
              value={taskDescription}
              isReq={true}
              onChangeFunc={this.handleChange}
              appendIcon={
                <SelectAttribute
                  outerAttriClass="text-area-icon"
                  attributeList={attributeList}
                  externalAttributes={[...prevElementStructure]}
                  onChangeFunc={(value, inputName) => this.handleChange(inputName, value)}
                  setAttribute={[null, taskDescription]}
                />
              }
            />
          </ElementForm>
          <ElementForm title="Agent">
            <Select
              name="userAgent"
              outerClassName="mb0"
              value={userAgent}
              options={userAgentList}
              isClearable={false}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Priority">
            <Select
              name="priority"
              outerClassName="mb0"
              value={priority}
              options={taskPriorityList}
              isClearable={false}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Status">
            <Select
              name="taskStatus"
              outerClassName="mb0"
              value={taskStatus}
              options={taskStatusList}
              placeholder="Select Status"
              isClearable={false}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Department">
            <Select
              name="taskDepartment"
              outerClassName="mb0"
              value={taskDepartment}
              options={departmentList}
              placeholder="Select Department"
              isClearable={false}
              menuPlacement="top"
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Mode">
            <Select
              name="mode"
              outerClassName="mb0"
              value={mode}
              options={taskModeList}
              isClearable={false}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-task" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    taskTypeList: state.common.taskTypeList.list,
    roleList: state.common.roleList,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Task);
