import React from "react";
import { Button, Input, ScrollBox, Select } from "../../../common/components";
import { ElementForm, SelectElement, SubmitElement, SelectIntent } from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { rpaEngineList } from "../../../utils/ConstUtils";

class RPAEngine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intent: null,
      step: 1,
      nextIntent: "",
      rpaEngine: null,
      botName: "",
      activeTab: "inputParams",
      inputParamsList: [{ key: "", value: "" }],
      outputParamsList: [{ key: "", value: "" }],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeElement = this.onChangeElement.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { intent, nextIntent, rpaEngine, botName, inputParamsList, outputParamsList } = box.payload;
      this.setState({ nextIntent, intent: intent || null, rpaEngine, botName, inputParamsList, outputParamsList });
    }
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const { intent, nextIntent, prevElement, rpaEngine, botName, inputParamsList, outputParamsList } = this.state;
    const { handleSubmitElement } = this.props;
    let data = {
      input_type: "rpa",
      next_intent: nextIntent,
      request_params: [
        {
          rpaEngine,
          botName,
          inputParamsList,
          outputParamsList,
        },
      ],
    };
    const payload = {
      intent,
      nextIntent,
      rpaEngine,
      botName,
      inputParamsList,
      outputParamsList,
    };
    handleSubmitElement(data, prevElement, payload);
  }

  onChangeElement(name, value) {
    this.setState({ [name]: value });
  }

  addFeature(listName) {
    let list = this.state[listName];
    list.push({
      key: "",
      value: "",
    });
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  removeFeature(listName, index) {
    let list = this.state[listName];
    list.splice(index, 1);
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  onChangeFeature(name, value, index, listName) {
    let list = this.state[listName];
    list[index][name] = value;
    this.setState({ [listName]: list });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  renderTab() {
    const { activeTab, inputParamsList, outputParamsList, listKey } = this.state;

    return (
      <div className="postman-head">
        <Nav className="nav-tab">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "inputParams" })}
              onClick={() => {
                this.toggle("inputParams");
              }}
            >
              {" "}
              Input Parameters
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "outputParams" })}
              onClick={() => {
                this.toggle("outputParams");
              }}
            >
              {" "}
              Output Parameters
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="inputParams">
            {activeTab === "inputParams" &&
              inputParamsList.map((obj, index) => {
                return (
                  <div key={`i${index}${listKey}`} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "inputParamsList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "inputParamsList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === inputParamsList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("inputParamsList")} />
                            ) : null}
                            {index !== 0 || inputParamsList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("inputParamsList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
          <TabPane tabId="outputParams">
            {activeTab === "outputParams" &&
              outputParamsList.map((obj, index) => {
                return (
                  <div key={`o${index}${listKey}`} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "outputParamsList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "outputParamsList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === outputParamsList.length - 1 ? (
                              <Button
                                plusBtn
                                className="mr10"
                                onClickFunc={() => this.addFeature("outputParamsList")}
                              />
                            ) : null}
                            {index !== 0 || outputParamsList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("outputParamsList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
        </TabContent>
      </div>
    );
  }

  renderStep1() {
    const { prevElement, rpaEngine, botName } = this.state;
    const { botElementId, boxes, connectors } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangeElement}
            />
          </ElementForm>
          <ElementForm title="RPA Engine">
            <Select
              name="rpaEngine"
              outerClassName="mb0"
              title="RPA Engine"
              outerWidth={300}
              value={rpaEngine}
              options={rpaEngineList}
              onChangeFunc={this.onChangeElement}
            />
          </ElementForm>

          <ElementForm title="Bot Name" frMaxWidth="300px">
            <Input
              name="botName"
              placeholder="Enter Bot Name"
              outerClassName="mb0"
              value={botName}
              onChangeFunc={this.onChangeElement}
            />
          </ElementForm>

          <ElementForm title="" frMaxWidth="unset">
            {this.renderTab()}
          </ElementForm>
        </div>
      </div>
    );
  }
  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-json-api" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          step={step}
          resetElement={resetElement}
          botElementId={botElementId}
          handleSubmit={this.handleSubmit}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default RPAEngine;
