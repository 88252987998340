import React from "react";
import { TextArea, Input, ScrollBox } from "../../../common/components";
import { getAttrByPrevElement } from "../../../utils/BotUtils";
import { SelectElement, SelectAttribute, ElementForm, SubmitElement, SelectIntent, OptionsElement } from "./components";
import { cloneDeep, isArray } from "lodash";
import { BOT_ATTRIBUTES, actionList } from "../../../utils/ConstUtils";

class CustomerRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPos: 0,
      rating: "",
      verifiedTag: "verified",
      title: "",
      likes: "",
      disLikes: "",
      name: "",
      comment: "",
      nextIntent: "",
      intent: null,
      step: 1,
      prevElementStructure: [],
      isAdvSettings: false,
      payloadQR: [],
      dataQR: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { rating, verifiedTag, title, likes, disLikes, name, comment, nextIntent, intent, qr } = box.payload;
      this.setState({
        rating,
        verifiedTag,
        title,
        likes,
        disLikes,
        name,
        comment,
        nextIntent,
        intent: intent || null,
        payloadQR: cloneDeep(qr),
      });
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 3) {
      const obj = this.optionRef.getOptions();
      if (!obj) return false;
      else {
        stateObj.dataQR = obj.dataQR;
        stateObj.payloadQR = obj.payloadQR;
      }
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      rating,
      verifiedTag,
      title,
      likes,
      disLikes,
      name,
      comment,
      prevElement,
      nextIntent,
      intent,
      payloadQR,
      dataQR,
    } = this.state;
    const { handleSubmitElement } = this.props;

    const data = {
      type: "userreview",
      request_params: [
        {
          rating,
          verifiedTag,
          title,
          likes,
          disLikes,
          name,
          description: comment,
          buttons: dataQR,
        },
      ],
      next_intent: nextIntent,
      input_type: "",
    };
    const payload = { rating, verifiedTag, title, likes, disLikes, name, comment, nextIntent, intent, qr: payloadQR };
    handleSubmitElement(data, prevElement, payload);
  }

  handleChange(name, value, e) {
    const stateObj = { [name]: value };
    this.setState(stateObj);
  }
  renderStep1() {
    const {
      rating,
      verifiedTag,
      title,
      likes,
      disLikes,
      name,
      comment,
      prevElement,
      selectPos,
      prevElementStructure,
      errors,
      isAdvSettings,
      isCustomEmailService,
    } = this.state;
    const { boxes, connectors, attributeList, botElementId } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              menuPlacement="top"
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Title">
            <Input
              type="text"
              placeholder="Title"
              name="title"
              outerClassName="mb0"
              value={title}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ title: value })}
                  setAttribute={[null, title]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Comment">
            <TextArea
              placeholder="Comment"
              name="comment"
              title="Comment"
              value={comment}
              appendIcon={
                <SelectAttribute
                  outerAttriClass="text-area-icon"
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ comment: value })}
                  setAttribute={[selectPos, comment]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Rating">
            <div className="position-relative">
              <Input
                placeholder="Rating"
                name="rating"
                outerClassName="mb0"
                title="Rating"
                value={rating}
                appendIcon={
                  <SelectAttribute
                    attributeList={attributeList}
                    externalAttributes={prevElementStructure}
                    onChangeFunc={value => this.setState({ rating: value })}
                    setAttribute={[null, rating]}
                  />
                }
                onChangeFunc={this.handleChange}
              />
            </div>
          </ElementForm>
          <ElementForm title="Likes">
            <Input
              placeholder="Likes"
              name="likes"
              outerClassName="mb0"
              value={likes}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ likes: value })}
                  setAttribute={[null, likes]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Dislikes">
            <Input
              placeholder="Dislikes"
              name="disLikes"
              outerClassName="mb0"
              value={disLikes}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ disLikes: value })}
                  setAttribute={[null, disLikes]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Name">
            <Input
              type="text"
              placeholder="Name"
              name="name"
              outerClassName="mb0"
              value={name}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ name: value })}
                  setAttribute={[null, name]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Verified Tag">
            <div className="position-relative">
              <Input
                placeholder="Verified Tag"
                name="verifiedTag"
                outerClassName="mb0"
                title="Rating"
                value={verifiedTag}
                appendIcon={
                  <SelectAttribute
                    attributeList={attributeList}
                    externalAttributes={prevElementStructure}
                    onChangeFunc={value => this.setState({ verifiedTag: value })}
                    setAttribute={[null, verifiedTag]}
                  />
                }
                onChangeFunc={this.handleChange}
              />
            </div>
          </ElementForm>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { prevElementStructure, payloadQR } = this.state;
    return (
      <div className="ele-content">
        <OptionsElement
          {...this.props}
          actions={actionList}
          payloadQR={isArray(payloadQR) ? payloadQR : undefined}
          ref={e => (this.optionRef = e)}
          prevElementStructure={prevElementStructure}
        />
      </div>
    );
  }

  renderStep3() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox scrollClass="bt-send-msg" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
          {step === 3 && this.renderStep3()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          totalSteps={3}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default CustomerRating;
