import React from "react";
import { TextArea, Input, ScrollBox } from "../../../common/components";
import { getAttrByPrevElement } from "../../../utils/BotUtils";
import { SelectElement, SelectAttribute, ElementForm, SubmitElement, SelectIntent } from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { Collapse } from "reactstrap";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";

class SendEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPos: 0,
      recipients: "",
      from: "",
      cc: "",
      bcc: "",
      replyTo: "",
      subject: "",
      message: "",
      nextIntent: "",
      intent: null,
      step: 1,
      prevElementStructure: [],
      isAdvSettings: false,
      isCustomEmailService: false,
      errors: {
        from: null,
        recipients: null,
        message: null,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { recipients, from, cc, bcc, replyTo, subject, message, nextIntent, intent, isCustomEmailService } =
        box.payload;
      this.setState({
        recipients,
        from,
        cc,
        bcc,
        replyTo,
        subject,
        message,
        nextIntent,
        intent: intent || null,
        isAdvSettings: isCustomEmailService,
        isCustomEmailService,
      });
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const obj = getFormDetails(this.state, this.onInputValidate);
    if (!obj) return false;

    if (isSubmit) step--;

    this.setState({ step }, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      recipients,
      from,
      cc,
      bcc,
      replyTo,
      subject,
      message,
      prevElement,
      nextIntent,
      intent,
      isCustomEmailService,
    } = this.state;
    const { handleSubmitElement } = this.props;

    const data = {
      input_type: "email",
      next_intent: nextIntent,
      request_params: [
        {
          attributes: [
            {
              recipient_email_address: recipients,
              cc_email_address: cc,
              bcc_email_address: bcc,
              from_address: from,
              reply_to: replyTo,
              subject,
              body: message,
              merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
              isCustomEmailService,
            },
          ],
        },
      ],
    };
    const payload = { recipients, from, cc, bcc, replyTo, subject, message, nextIntent, intent, isCustomEmailService };
    handleSubmitElement(data, prevElement, payload);
  }

  handleChange(name, value, e) {
    const stateObj = { [name]: value };
    if (name === "message") {
      stateObj.selectPos = e.target;
    }
    this.setState(stateObj);
  }
  renderStep1() {
    const {
      subject,
      recipients,
      from,
      cc,
      bcc,
      replyTo,
      message,
      prevElement,
      selectPos,
      prevElementStructure,
      errors,
      isAdvSettings,
      isCustomEmailService,
    } = this.state;
    const { boxes, connectors, attributeList, botElementId } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Add Recipients">
            <Input
              type="text"
              placeholder="Add Recipients (Use commas to add multiple recipients)."
              name="recipients"
              outerClassName="mb0"
              title="Recipients"
              value={recipients}
              isReq={true}
              error={errors.recipients}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={(value, inputName) =>
                    this.setState({ recipients: value }, () => {
                      this.onInputValidate(inputName, null);
                    })
                  }
                  setAttribute={[null, recipients]}
                />
              }
              validationFunc={this.onInputValidate}
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="From">
            <div className="position-relative">
              <Input
                type="text"
                placeholder="From"
                name="from"
                outerClassName="mb0"
                title="From"
                value={from}
                isReq={true}
                error={errors.from}
                appendIcon={
                  <SelectAttribute
                    attributeList={attributeList}
                    externalAttributes={prevElementStructure}
                    onChangeFunc={(value, inputName) =>
                      this.setState({ from: value }, () => {
                        this.onInputValidate(inputName, null);
                      })
                    }
                    setAttribute={[null, from]}
                  />
                }
                validationFunc={this.onInputValidate}
                onChangeFunc={this.handleChange}
              />
            </div>
          </ElementForm>
          <ElementForm title="Cc">
            <Input
              type="text"
              placeholder="Cc"
              name="cc"
              outerClassName="mb0"
              value={cc}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ cc: value })}
                  setAttribute={[null, cc]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Bcc">
            <Input
              type="text"
              placeholder="Bcc"
              name="bcc"
              outerClassName="mb0"
              value={bcc}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ bcc: value })}
                  setAttribute={[null, bcc]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Reply to">
            <Input
              type="text"
              placeholder="Reply to"
              name="replyTo"
              outerClassName="mb0"
              value={replyTo}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ replyTo: value })}
                  setAttribute={[null, replyTo]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Subject">
            <Input
              type="text"
              placeholder="Subject"
              name="subject"
              outerClassName="mb0"
              value={subject}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={value => this.setState({ subject: value })}
                  setAttribute={[null, subject]}
                />
              }
              onChangeFunc={this.handleChange}
            />
          </ElementForm>
          <ElementForm title="Message">
            <TextArea
              placeholder="Message"
              name="message"
              title="Message"
              value={message}
              isReq={true}
              error={errors.message}
              appendIcon={
                <SelectAttribute
                  outerAttriClass="text-area-icon"
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={(value, inputName) =>
                    this.setState({ message: value }, () => {
                      this.onInputValidate(inputName, null);
                    })
                  }
                  setAttribute={[selectPos, message]}
                />
              }
              validationFunc={this.onInputValidate}
              onChangeFunc={this.handleChange}
            />
            <label className="mt5">Allows sending HTML templates.</label>
          </ElementForm>
          <div className="adv-title">
            <label className="name mt10" style={{ lineHeight: "33px" }}>
              Advance Options
            </label>
            <i
              className={`fa ${isAdvSettings ? "fa-chevron-up" : "fa-chevron-down"}`}
              onClick={() => this.setState({ isAdvSettings: !isAdvSettings })}
            />
          </div>
          <div id="collapse">
            <Collapse isOpen={isAdvSettings}>
              <ElementForm title="Use Custom Email Service" rowClassName="mb10">
                <Input
                  type="checkbox"
                  name="isCustomEmailService"
                  outerClassName="float-left mr10 mb0"
                  style={{ width: 20 }}
                  checked={isCustomEmailService}
                  onChangeFunc={() => this.setState({ isCustomEmailService: !isCustomEmailService })}
                />
              </ElementForm>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox scrollClass="bt-send-msg" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default SendEmail;
