import React from "react";
import PropTypes from "prop-types";
import { Button as ButtonStrap } from "reactstrap";

const Button = props => {
  const {
    id,
    text,
    className,
    type,
    color,
    size,
    style,
    displayIcon,
    borderRadius,
    submitBtn,
    cancelBtn,
    createBtn,
    backBtn,
    prevBtn,
    nextBtn,
    editBtn,
    linkBtn,
    plusBtn,
    plusBtnDark,
    minusBtn,
    minusBtnDark,
    customIcon,
    refreshBtn,
    loading,
    onClickFunc,
    outline,
    title,
    isDisabled,
    refreshLoading,
    loadingText,
  } = props;

  const inputProps = { type, size, outline, title, color };
  if (className) inputProps.className = className;

  if (style) {
    inputProps.style = style;
  }

  if (borderRadius) {
    inputProps.style = { borderRadius: `${borderRadius}px` };
  }

  let icon = null;
  if (submitBtn) {
    icon = <i className="fa fa-dot-circle-o"></i>;
    inputProps.className = `${inputProps.className} cb cb-submit`;
  }
  if (cancelBtn) {
    icon = <i className="fa fa-ban"></i>;
    inputProps.className = `${inputProps.className} cb cb-cancel`;
  }
  if (backBtn) {
    icon = <i className="fa fa-arrow-left"></i>;
    inputProps.className = `${inputProps.className} cb cb-back`;
  }
  if (nextBtn) {
    icon = <i className="fa fa-chevron-circle-right"></i>;
    inputProps.className = `${inputProps.className} cb cb-back`;
  }
  if (prevBtn) {
    icon = <i className="fa fa-chevron-circle-left"></i>;
    inputProps.className = `${inputProps.className} cb cb-back`;
  }
  if (createBtn) {
    icon = <i className="fa fa-plus-circle"></i>;
    inputProps.className = `${inputProps.className} cb cb-submit`;
  }
  if (editBtn) {
    icon = <i className="fa fa-pencil-square-o"></i>;
    inputProps.className = `${inputProps.className} cb cb-edit`;
  }
  if (refreshBtn) {
    icon = <i className={`fa fa-refresh ${refreshLoading ? "fa-spin" : ""}`}></i>;
    inputProps.className = `${inputProps.className} cb cb-submit`;
  }
  if (linkBtn) {
    icon = null;
    inputProps.color = "link";
  }
  if (plusBtn) {
    icon = <i className="fa fa-plus"></i>;
    inputProps.className = `${inputProps.className} action-btn cb-back`;
  }
  if (plusBtnDark) {
    icon = <i className="fa fa-plus"></i>;
    inputProps.className = `${inputProps.className} action-btn cb-dark`;
  }
  if (minusBtnDark) {
    icon = <i className="fa fa-minus"></i>;
    inputProps.className = `${inputProps.className} action-btn cb-dark`;
  }
  if (minusBtn) {
    icon = <i className="fa fa-minus"></i>;
    inputProps.className = `${inputProps.className} action-btn cb-back`;
  }
  if (customIcon) icon = <i className={`fa ${customIcon}`}></i>;

  let buttonText = text;
  if (loading) {
    icon = <i className="fa fa-spinner fa-pulse fa-fw"></i>;
    buttonText = loadingText ? loadingText : "Please wait...";
  }

  if (!displayIcon) icon = null;

  if (color) {
    inputProps.color = color;
  }

  if (id) inputProps.id = id;

  if (loading || isDisabled) {
    inputProps.style = { ...(inputProps.style || {}), cursor: "not-allowed" };
  }

  return (
    <ButtonStrap {...inputProps} onClick={onClickFunc} disabled={loading || isDisabled}>
      {icon} {buttonText}
    </ButtonStrap>
  );
};

Button.defaultProps = {
  text: null,
  className: "",
  type: "button",
  color: "",
  size: "sm",
  submitBtn: false,
  cancelBtn: false,
  createBtn: false,
  backBtn: false,
  nextBtn: false,
  prevBtn: false,
  editBtn: false,
  title: "",
  linkBtn: false,
  plusBtn: false,
  minusBtn: false,
  customIcon: null,
  displayIcon: true,
  outline: false,
  loading: false,
  loadingText: "",
  borderRadius: null,
  style: null,
  id: null,
  onClickFunc: () => {},
  isDisabled: false,
};

Button.propTypes = {
  onClickFunc: PropTypes.func,
  type: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  submitBtn: PropTypes.bool,
  outline: PropTypes.bool,
  cancelBtn: PropTypes.bool,
  createBtn: PropTypes.bool,
  backBtn: PropTypes.bool,
  nextBtn: PropTypes.bool,
  prevBtn: PropTypes.bool,
  editBtn: PropTypes.bool,
  linkBtn: PropTypes.bool,
  customIcon: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  displayIcon: PropTypes.bool,
  borderRadius: PropTypes.number,
  style: PropTypes.any,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default Button;
