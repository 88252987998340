import React, { Component, memo } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import $ from "jquery";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css//perfect-scrollbar.css";

class ScrollBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      children: this.props.children,
    };
    this.box = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.children, nextProps.children)) {
      // if (!isEqual(this.props.keyId, nextProps.keyId)) {
      //   const container = document.querySelector(`.${this.props.scrollClass}`);
      //   if (container) container.scrollTop = 0;
      // }
      this.setState({ children: nextProps.children }, () => this.updateDimensions());
    }
  }

  // create resize event listener
  componentDidMount() {
    this.box = new PerfectScrollbar(`.${this.props.scrollClass}`);
    this.updateDimensions();
    window.addEventListener(
      "resize",
      function () {
        this.updateDimensions();
      }.bind(this)
    );
  }

  // remove resize event listener
  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  updateDimensions() {
    let boxHeight = this.props.boxHeight ? this.props.boxHeight : window.innerHeight - this.props.extraHeight;
    $(`.${this.props.scrollClass}`).css("height", boxHeight + "px");
    this.box.update();
  }

  render() {
    const { minHeight, className, scrollClass } = this.props;
    return (
      <div className={`${scrollClass} ${className ? className : ""}`} style={{ minHeight, position: "relative" }}>
        {this.state.children}
      </div>
    );
  }
}

ScrollBox.propTypes = {
  scrollClass: PropTypes.string,
  className: PropTypes.string,
  minHeight: PropTypes.number,
  extraHeight: PropTypes.number,
  boxHeight: PropTypes.any,
};

ScrollBox.defaultProps = {
  scrollClass: "scroll-box",
  className: "",
  minHeight: 200,
  extraHeight: 0,
  boxHeight: null,
};

export default memo(ScrollBox);
