import React from "react";
import { Button, Input, ScrollBox, TextArea, TextEditor } from "../../../common/components";
import { CLOUDINARY_DATA } from "../../../utils/Config";
import {
  SelectElement,
  SelectIntent,
  SelectAttribute,
  ElementForm,
  SubmitElement,
  OptionsElement,
  AdvanceOptionsExpand,
} from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { createCloudinaryWidget } from "../../../utils/GeneralUtils";
import { cloneDeep, isArray } from "lodash";
import { convertPayloadToData } from "../../../utils/BotUtils.js";

class SendAudio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      audioURL: "",
      intent: null,
      nextIntent: "",
      isRich: false,
      waTemplate: false,
      legacy_message: false,
      activity: false,
      help_text: "",
      header: "",
      footer: "",
      prevElementStructure: [],
      externalAttributes: [],
      step: 1,
      dataQR: [],
      payloadQR: [],
      errors: {
        audioURL: null,
      },
    };
    this.widget = null;
    this.openCloudinaryWidget = this.openCloudinaryWidget.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onAdvanceOptionChange = this.onAdvanceOptionChange.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const {
        text,
        url,
        intent,
        nextIntent,
        qr,
        isRich,
        waTemplate = false,
        legacy_message = false,
        activity = false,
        header,
        footer,
        help_text,
      } = box.payload;
      this.setState({
        text,
        audioURL: url,
        intent,
        nextIntent,
        isRich,
        waTemplate,
        legacy_message,
        activity,
        header,
        footer,
        help_text,
        payloadQR: cloneDeep(qr),
        dataQR: convertPayloadToData(qr, this.props).dataQR,
      });
    }
  }

  openCloudinaryWidget() {
    if (this.widget) {
      this.widget.open();
      return;
    }

    this.widget = createCloudinaryWidget(CLOUDINARY_DATA.UPLOAD_PRODUCT_PRESET, result => {
      if (result.event == "success") {
        this.setState({ audioURL: result.info.secure_url }, () => {
          setTimeout(() => {
            this.widget.close({ quiet: true });
          }, 300);
        });
      }
    });

    this.widget.open();
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  onAdvanceOptionChange(name, value, err, obj, e) {
    if (["waTemplate", "legacy_message", "activity"].includes(name)) {
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }
    if (step === 3) {
      const obj = this.optionRef.getOptions();
      if (!obj) return false;
      else {
        stateObj.dataQR = obj.dataQR;
        stateObj.payloadQR = obj.payloadQR;
      }
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      audioURL,
      text,
      prevElement,
      intent,
      nextIntent,
      payloadQR,
      dataQR,
      isRich,
      waTemplate,
      legacy_message,
      activity,
      header,
      footer,
      help_text,
    } = this.state;
    const { handleSubmitElement } = this.props;
    const data = {
      url: audioURL,
      next_intent: nextIntent,
      quick_replies: dataQR,
      waTemplate,
      legacy_message,
      header,
      footer,
      help_text,
    };
    const payload = {
      url: audioURL,
      intent,
      nextIntent,
      isRich,
      qr: payloadQR,
      waTemplate,
      legacy_message,
      activity,
      header,
      footer,
      help_text,
    };
    if (text) {
      data.text = text;
      payload.text = text;
    }
    handleSubmitElement(data, prevElement, payload);
  }

  renderStep1() {
    const {
      audioURL,
      prevElement,
      text,
      errors,
      isRich,
      prevElementStructure,
      selectPos,
      waTemplate,
      legacy_message,
      activity,
      header,
      footer,
      help_text,
    } = this.state;
    const { boxes, botElementId, connectors, attributeList } = this.props;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </ElementForm>
          <ElementForm title="URL">
            <div>
              <Input
                title="Audio URL"
                name="audioURL"
                placeholder="Enter audio file URL"
                className="form-control"
                outerClassName="float-left mb5"
                outerStyle={{ width: "calc(100% - 70px)" }}
                value={audioURL}
                isReq={true}
                error={errors.audioURL}
                onChangeFunc={(name, value) => this.setState({ [name]: value })}
                validationFunc={this.onInputValidate}
              />
              <Button text="Browse" className="btn-browse" onClickFunc={this.openCloudinaryWidget} />
              <div className="clearfix" />
            </div>
            <label className="mb0">Allows sending Audios. Supports mp3 upto 5MB in size</label>
          </ElementForm>
          <ElementForm title="Message">
            <div className="position-relative">
              {isRich ? (
                <TextEditor
                  title="Message"
                  name="text"
                  value={text}
                  // isReq={true}
                  // error={errors.text}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={value => this.setState({ text: value })}
                      setAttribute={[selectPos, text]}
                    />
                  }
                  // validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              ) : (
                <TextArea
                  title="Message"
                  value={text}
                  name="text"
                  placeholder="Welcome to Purple grids !!"
                  // isReq={true}
                  // error={errors.text}
                  appendIcon={
                    <SelectAttribute
                      outerAttriClass="text-area-icon"
                      attributeList={attributeList}
                      externalAttributes={prevElementStructure}
                      onChangeFunc={(value, inputName) =>
                        this.setState({ text: value }, () => this.onInputValidate(inputName, null))
                      }
                      setAttribute={[selectPos, text]}
                    />
                  }
                  // validationFunc={this.onInputValidate}
                  onChangeFunc={(name, value, e) => this.setState({ [name]: value, selectPos: e.target })}
                />
              )}
            </div>
            <label className="mb0" style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>

          <AdvanceOptionsExpand
            help_text={help_text}
            header={header}
            footer={footer}
            waTemplate={waTemplate}
            legacy_message={legacy_message}
            activity={activity}
            displayList={["waTemplate", "legacy_message", "activity", "help_text", "header", "footer"]}
            onChangeFunc={this.onAdvanceOptionChange}
          />
        </div>
      </div>
    );
  }

  renderStep2() {
    const { prevElementStructure, payloadQR, waTemplate } = this.state;
    const isInteractive = waTemplate;

    return (
      <div className="ele-content">
        <OptionsElement
          {...this.props}
          isInteractive={isInteractive}
          waTemplate
          payloadQR={isArray(payloadQR) ? payloadQR : undefined}
          ref={e => (this.optionRef = e)}
          prevElementStructure={prevElementStructure}
        />
      </div>
    );
  }

  renderStep3() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  render() {
    const { resetElement, botElementId } = this.props;
    const { step } = this.state;
    return (
      <React.Fragment>
        <ScrollBox key={step} scrollClass="bt-send-audio" className="adv-settings" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
          {step === 3 && this.renderStep3()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          step={step}
          totalSteps={3}
          botElementId={botElementId}
          handleStep={this.handleStep}
        />
      </React.Fragment>
    );
  }
}

export default SendAudio;
