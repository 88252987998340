import React, { Component } from "react";
import { leadStatus, getInternationalFormat, ignoreChannelForMerchant } from "./GeneralUtils";
import { getChannelImg, getEmojiFace, getChannelImgById } from "./IconUtils";
import { getRegExp } from "../common/components/helper";

export const phoneLink = (phone, country = null, defaultValue = "") => {
  const phoneNumber = phone ? (country ? getInternationalFormat(phone, country) : phone) : null;
  return phoneNumber && phoneNumber.trim() ? (
    <a className="ph-link" href={`tel:${phone}`}>
      <i className="fa fa-phone mr5"></i>
      {phoneNumber}
    </a>
  ) : (
    defaultValue
  );
};

export const emailLink = (email, defaultValue = "") => {
  return email ? (
    <a className="email-link" href={`mailto:${email}`}>
      <i className="fa fa-envelope mr5"></i>
      {email}
    </a>
  ) : (
    defaultValue
  );
};

export const uNameLink = (uName, defaultValue = "") => {
  const isMail = getRegExp("email").test(uName);
  return uName ? (
    <a className="email-link" href={isMail ? `mailto:${uName}` : undefined}>
      <i className="fa fa-user mr5"></i>
      {uName}
    </a>
  ) : (
    defaultValue
  );
};

export class EmailFormatter extends Component {
  render() {
    const { value } = this.props;
    return Array.isArray(value) && !value.includes(null) ? (
      <a className="email-link" href={`mailto:${value}`}>
        <i className="fa fa-envelope mr5"></i>
        {value}
      </a>
    ) : Array.isArray(value) ? (
      ""
    ) : (
      value && (
        <a className="email-link" href={`mailto:${value}`}>
          <i className="fa fa-envelope mr5"></i>
          {value}
        </a>
      )
    );
  }
}

export class PhoneFormatter extends Component {
  render() {
    const { value } = this.props;
    const phoneNumber = value ? (value.country ? getInternationalFormat(value.phone, value.country) : value.phone) : "";
    return value && value.phone && value.phone.trim() ? (
      <a className="ph-link ph-grid-link" href={`tel:${value.phone}`}>
        <i className="fa fa-phone mr5"></i>
        {phoneNumber}
      </a>
    ) : (
      ""
    );
  }
}

export class LeadStatusFormatter extends Component {
  render() {
    let style = leadStatus.new;
    switch (this.props.value) {
      case "VISITOR":
        style = leadStatus.new;
        break;
      case "NEW":
        style = leadStatus.new;
        break;
      case "PROSPECT":
        style = leadStatus.qualified;
        break;
      case "MQL":
        style = leadStatus.mql;
        break;
      case "SQL":
        style = leadStatus.sql;
        break;
      default:
        style = leadStatus.new;
        break;
    }
    let styleObj = { background: style.bg, color: style.color };
    if (this.props.ftSize) {
      styleObj.fontSize = this.props.ftSize;
    }
    return this.props.value ? (
      <span className="lead-status-style" style={styleObj}>
        {this.props.value === "VISITOR" ? "NEW" : this.props.value}
      </span>
    ) : (
      ""
    );
  }
}

export class UrlFormatter extends Component {
  render() {
    const url = this.props.value;
    return url ? (
      <a href={url} target="_blank" title={url}>
        {url}
      </a>
    ) : null;
  }
}

export class ChannelFormatter extends Component {
  render() {
    return this.props.value ? (
      <React.Fragment>
        <img className="channel-image mr5" src={getChannelImg(this.props.value)} />
        <span>{ignoreChannelForMerchant(this.props.value)}</span>
      </React.Fragment>
    ) : (
      ""
    );
  }
}
export class ChannelByIdFormatter extends Component {
  render() {
    const { value, link } = this.props;
    return value ? (
      <React.Fragment>
        {link ? (
          <a href={link} target="_blank">
            <img
              className="channel-image mr5"
              src={getChannelImgById(value.id)}
              title={value.type || ignoreChannelForMerchant(value.name) || ""}
            />
            <span>{ignoreChannelForMerchant(value.name)}</span>
          </a>
        ) : (
          <React.Fragment>
            <img
              className="channel-image mr5"
              src={getChannelImgById(value.id)}
              title={value.type || ignoreChannelForMerchant(value.name) || ""}
            />
            <span>{ignoreChannelForMerchant(value.name)}</span>
          </React.Fragment>
        )}
      </React.Fragment>
    ) : (
      ""
    );
  }
}

export class CompanyFormatter extends Component {
  render() {
    const company = this.props.value ? this.props.value : "";
    return getCompany(company);
  }
}

export const getCompany = company => {
  return company && company.startsWith("AS") ? company.slice(company.indexOf(" ") + 1) : company;
};

export class CSRFormatter extends Component {
  render() {
    const { value } = this.props;
    return (
      <img
        src={getEmojiFace(value)}
        title={`Score: ${value ? value.score.toFixed(2) : 0}`}
        width={"20px"}
        alt={`Score: ${value ? value.score.toFixed(2) : 0}`}
      />
    );
  }
}

export class AgentFormatter extends Component {
  render() {
    const agentObj = getAgentDetails(this.props.value);
    if (!agentObj.agent) return null;
    return (
      <div title={`${agentObj.humanAgent ? "User" : "AI"} Agent`}>
        <img
          height="25"
          width="25"
          className="img-avatar mr5"
          style={{ border: "1px solid #999" }}
          src={agentObj.agentImg}
        />
        {agentObj.agent}
      </div>
    );
  }
}

export const getAgentDetails = value => {
  const humanAgentImg = "/img/human-agent.png";
  const aiAgent =
    Array.isArray(value.engagements) && value.engagements.length > 0 && value.engagements[0].aiAgent
      ? value.engagements[0].aiAgent
      : "";

  let humanAgent = "";
  let humanAgentList = [];
  if (
    Array.isArray(value.engagements) &&
    value.engagements.length > 0 &&
    Array.isArray(value.engagements[0].allConversations) &&
    value.engagements[0].allConversations.length > 0 &&
    Array.isArray(value.engagements[0].allConversations[0].agent)
  ) {
    const agentObj = value.engagements[0].allConversations[0].agent.find(x => x.type === "User");
    humanAgent = agentObj ? `${agentObj.firstName || ""} ${agentObj.lastName || ""}`.trim() || agentObj.aiAgent : "";
    humanAgentList = value.engagements[0].allConversations[0].agent
      .filter(x => x.type === "User")
      .map(x => {
        x.firstName = x.firstName || "";
        x.lastName = x.lastName || "";
        x.fullName = `${x.firstName} ${x.lastName}`.trim();
        return x;
      });
  }

  let aiAgentImg = "/img/aiAgentIcon.png";
  if (
    Array.isArray(value.engagements) &&
    value.engagements.length > 0 &&
    Array.isArray(value.engagements[0].aiAgentImage) &&
    value.engagements[0].aiAgentImage.length > 0
  )
    aiAgentImg = value.engagements[0].aiAgentImage[0];

  return {
    aiAgent,
    humanAgentList,
    humanAgent,
    humanAgentImg,
    aiAgentImg,
    agent: humanAgent || aiAgent,
    agentImg: humanAgent ? humanAgentImg : aiAgentImg,
  };
};
