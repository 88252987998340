import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Label, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { handlePopup } from "./../../actions";
import { Input, Button, CKEditor } from "../../common/components";
import CommonUtils from "../../utils/CommonUtils";
import { getRandomNumber, isArray } from "../../utils/GeneralUtils";
import { get } from "lodash";

class SendEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEmail: props.fromEmail || get(props.merchantAttr, "address.email_addresses[0]", "info@purplegrids.com"),
      subject: props.subject,
      body: props.body,
      toEmail: props.toEmail,
      ccEmail: props.emailCC ? props.emailCC : "",
      bccEmail: "",
      showCc: props.emailCC ? true : false,
      showBcc: false,
      loading: false,
      attachments: [],
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCancel() {
    this.props.handlePopup();
  }

  async handleSubmit() {
    const { fromEmail, subject, body, toEmail, ccEmail, bccEmail, attachments } = this.state;

    this.setState({ loading: true });

    const uploadCommentFilesAPI = [];
    attachments.map(a => {
      const fileData = new FormData();
      fileData.append("file", a.file);
      uploadCommentFilesAPI.push(CommonUtils.uploadGCS(fileData, "email"));
    });

    const commentFileUploadRes = await Promise.all(uploadCommentFilesAPI);

    let attachmentContent = "";
    const commentAttachmentList = [];
    commentFileUploadRes.map((x, i) => {
      commentAttachmentList.push({
        type: attachments[i].file.type,
        value: {
          fileName: x.fileName,
          originalFileName: x.originalFileName,
          url: x.fileUrl,
        },
      });
      attachmentContent += `<br />📎 <a href="${x.fileUrl}" target="_blank">${x.originalFileName}</a>`;
    });

    const connector = {
      type: "Email",
      subject: subject || "",
      body: `${body}${attachmentContent}` || "",
      recipient_email_address: toEmail || "",
      from_address: fromEmail || "",
    };
    if (ccEmail) {
      connector.cc_email_address = ccEmail;
    }
    if (bccEmail) {
      connector.bcc_email_address = bccEmail;
    }

    const emailObj = {
      request_id: "1",
      gateway_actions: [{ id: "sendEmail", connector }],
    };

    CommonUtils.sendEmailAPI(emailObj).then(res => {
      this.setState({ loading: false });
      if (res.error) {
        this.props.handlePopup({ isError: true });
      }
      this.props.submitComments(connector);
      this.props.handlePopup({ isSuccess: true, message: "Email has been sent successfully" });
    });
  }

  renderAttachment(list, isLocal, deleteCB) {
    return isArray(list)
      ? list.map((a, i) => {
          const fileUrl = !isLocal && a.value && a.value.url ? a.value.url : undefined;
          const gcsName = !isLocal && a.value && a.value.fileName ? a.value.fileName : undefined;
          const originalName = isLocal
            ? a.file.name
            : a.value && a.value.originalFileName
              ? a.value.originalFileName
              : undefined;
          return (
            <div key={i} className="al-item mb10">
              <i className="fa fa-file mr-1 text-gray-400"></i>
              <a href={fileUrl} target="_blank">
                {originalName}
              </a>
              {deleteCB && <i className="fa fa-times" onClick={() => deleteCB(gcsName, i, a.id)} />}
            </div>
          );
        })
      : null;
  }

  onInputChange(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const { fromEmail, subject, body, toEmail, ccEmail, bccEmail, showCc, showBcc, loading, attachments } = this.state;
    return (
      <div className="bot-details">
        <ModalHeader>Send Email</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="3" className="text-right">
              <Label style={{ lineHeight: "30px" }}>From</Label>
            </Col>
            <Col md="8">
              <Label style={{ lineHeight: "30px" }}>{fromEmail}</Label>
              <Button
                text="Bcc"
                className="float-right px-0"
                linkBtn
                onClickFunc={() => this.setState({ showBcc: !showBcc })}
              />
              <Button
                text="Cc"
                className="float-right mr-2 px-0"
                linkBtn
                onClickFunc={() => this.setState({ showCc: !showCc })}
              />
            </Col>
          </Row>

          <Row>
            <Col md="3" className="text-right mb-1">
              <Label style={{ lineHeight: "30px" }}>To</Label>
            </Col>
            <Col md="8">
              <Input
                name="toEmail"
                title="toEmail"
                placeholder="To"
                value={toEmail}
                onChangeFunc={this.onInputChange}
              />
            </Col>
          </Row>

          {showCc && (
            <Row>
              <Col md="3" className="text-right mb-1">
                <Label style={{ lineHeight: "30px" }}>Cc</Label>
              </Col>
              <Col md="8">
                <Input name="ccEmail" title="Cc" placeholder="Cc" value={ccEmail} onChangeFunc={this.onInputChange} />
              </Col>
            </Row>
          )}
          {showBcc && (
            <Row>
              <Col md="3" className="text-right mb-1">
                <Label style={{ lineHeight: "30px" }}>Bcc</Label>
              </Col>
              <Col md="8">
                <Input
                  name="bccEmail"
                  title="Bcc"
                  placeholder="Bcc"
                  value={bccEmail}
                  onChangeFunc={this.onInputChange}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md="3" className="text-right mb-1">
              <Label style={{ lineHeight: "30px" }}>Subject</Label>
            </Col>
            <Col md="8">
              <Input
                name="subject"
                title="Subject"
                placeholder="Subject"
                value={subject}
                onChangeFunc={this.onInputChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md="3" className="text-right">
              <Label style={{ lineHeight: "30px" }}>Body</Label>
            </Col>
            <Col md="8">
              <CKEditor content={body} onChangeFunc={val => this.onInputChange("body", val)} />
              <div className="align-items-center d-flex position-relative">
                <i
                  className="cursor-pointer fa fa-ban comment-clip-sendemail mr30"
                  onClick={() => this.setState({ body: "" })}
                />
                <i
                  className="cursor-pointer fa fa-paperclip comment-clip-sendemail mr10"
                  onClick={() => this.attachmentRef.click()}
                />
                <input
                  type="file"
                  ref={e => (this.attachmentRef = e)}
                  className="d-none"
                  onChange={e => {
                    this.setState(
                      { attachments: [...attachments, { id: getRandomNumber(6), file: e.target.files[0] }] },
                      () => {
                        this.attachmentRef.value = "";
                      }
                    );
                  }}
                />
                <div className="flex-wrap sep-attachments-list">
                  {this.renderAttachment(attachments, true, (fileName, i, id) =>
                    this.setState({ attachments: attachments.filter(x => x.id !== id) })
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button submitBtn text="Send" loading={loading} onClickFunc={this.handleSubmit} />
          <Button cancelBtn text="Cancel" onClickFunc={this.handleCancel} />
        </ModalFooter>
      </div>
    );
  }
}

SendEmailModal.defaultProps = {
  toEmail: "",
  fromEmail: "",
  subject: "",
  body: "",
};

SendEmailModal.propTypes = {
  toEmail: PropTypes.string,
  fromEmail: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    merchantAttr: state.common.merchantAttr.data,
  };
};

const mapDispatchToProps = {
  handlePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailModal);
