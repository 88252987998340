import React from "react";
import { Input } from "../../../../../common/components";
import { Label } from "reactstrap";

const Image = props => {
  const { propertyList, onChangeProperties } = props;
  return (
    <React.Fragment>
      <div>
        <Label className="mb-1 fw-semibold">Source</Label>
        <Input
          type="text"
          placeholder="Enter Source"
          name="src"
          outerClassName="mb-2"
          value={propertyList.src}
          onChangeFunc={onChangeProperties}
        />
      </div>
      <div>
        <Label className="mb-1 fw-semibold">ALT</Label>
        <Input
          type="text"
          placeholder="Enter ALT"
          name="alt"
          outerClassName="mb-2"
          value={propertyList.alt}
          onChangeFunc={onChangeProperties}
        />
      </div>
    </React.Fragment>
  );
};

export default Image;
