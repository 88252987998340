import React from "react";
import { TextArea } from "../../../../../common/components";
import { Label } from "reactstrap";

const Paragraph = props => {
  const { propertyList, onChangeProperties } = props;

  return (
    <React.Fragment>
      <div>
        <Label className="mb-1 fw-semibold">Label</Label>
        <TextArea
          type="text"
          placeholder="Enter Label"
          name="label"
          outerClassName="mb-2"
          value={propertyList.label}
          onChangeFunc={onChangeProperties}
          rows={5}
        />
      </div>
    </React.Fragment>
  );
};

export default Paragraph;
