import React from "react";
import { Row, Col } from "reactstrap";
import { Button, TextArea, Select, Input, ScrollBox, TextEditor } from "../../../common/components";
import ImageUpload from "../../../common/components/ImageUpload";
import { getRandomNumber, styleList, isArray, addErrorObj } from "../../../utils/GeneralUtils";
import CommonUtils from "../../../utils/CommonUtils";
import {
  getAttrByPrevElement,
  getBotIntent,
  loadBotPayload,
  loadDefaultBotPayload,
  updateBotPayload,
} from "../../../utils/BotUtils";
import {
  SubmitElement,
  SelectElement,
  SelectIntent,
  SelectAttribute,
  ElementForm,
  AdvanceOptionsExpand,
} from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { actionList, BOT_ATTRIBUTES } from "../../../utils/ConstUtils";
import { cloneDeep, keys } from "lodash";
import Sortable from "sortablejs";

const initOptions = (id = getRandomNumber(6)) => ({
  id,
  value: "",
  title: "",
  type: "",
  attributes: [{ attributeKey: "", attributeValue: "" }],
  errors: {
    title: null,
    value: null,
  },
});

const initAttributes = () => [
  { attributeKey: "", attributeValue: "", errors: { attributeKey: null, attributeValue: null } },
];

const LANG_STRUCTURE = {
  text: "",
  help_text: "",
  carouselList: [],
  dynamicCarousel: {
    buttons: [],
    carouselAttributes: [],
  },
};

class SendCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waTemplate: false,
      legacy_message: false,
      activity: false,
      help_text: "",
      isDynamic: false,
      text: "",
      isRich: false,
      textTarget: 0,
      source: "static-card",
      style: "carousel",
      entityList: [],
      entity: null,
      carouselListKey: Date.now(),
      carouselList: [],
      dynamicCarousel: {
        image: "",
        title: "",
        titleTarget: null,
        description: "",
        descriptionTarget: null,

        location: "",
        locationTarget: null,
        availability: "",
        availabilityTarget: null,
        department: "",
        departmentTarget: null,
        languages: "",
        languagesTarget: null,
        timing: "",
        timingTarget: null,
        buttons: [],
        carouselAttributes: [],
        errors: {
          title: null,
          description: null,
        },
      },
      subScreen: false,
      subScreenAttri: false,
      selectedAction: null,
      listIndex: 0,
      carouselAttriListIndex: 0,
      buttonList: [],
      carouselAttriList: [],
      intent: null,
      nextIntent: "",
      step: 1,
      externalAttributes: [],
      prevElementStructure: [],
      langPayload: loadDefaultBotPayload(LANG_STRUCTURE, props.lang),
    };

    this.listRef = React.createRef();
    this.sourceList = [
      { label: "Static List - Add individual cards", value: "static-card" },
      { label: "Dynamic List", value: "dynamic-entity" },
    ];

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.onAdvanceOptionChange = this.onAdvanceOptionChange.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    this.getEntityList();
    this.initializeSortable();
    const { boxes, botElementId, lang } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      let {
        text,
        carousel,
        intent,
        source,
        style,
        entity,
        isDynamic,
        isRich,
        nextIntent,
        waTemplate = false,
        legacy_message = false,
        activity = false,
        help_text = "",
      } = box.payload;
      const errors = { title: null, description: null };
      carousel = addErrorObj(carousel, errors);

      let carouselList = cloneDeep(this.state.carouselList);
      if (!isDynamic) {
        carouselList = cloneDeep(carousel);
        carouselList.map(o => {
          o.buttons.map(b => {
            if (!b.attributes) {
              b.attributes = [{ attributeKey: "", attributeValue: "" }];
            }
          });
        });
      }

      let dynamicCarousel = cloneDeep(this.state.dynamicCarousel);
      if (isDynamic) {
        dynamicCarousel = cloneDeep(carousel)[0];
        dynamicCarousel.buttons.map(b => {
          if (!b.attributes) {
            b.attributes = [{ attributeKey: "", attributeValue: "" }];
          }
        });
        if (!isArray(dynamicCarousel.carouselAttributes)) {
          dynamicCarousel.carouselAttributes = [];
        }
      }

      this.setState(
        {
          text,
          carouselList,
          isRich,
          intent,
          nextIntent,
          style: style || this.state.style,
          source: source || this.state.source,
          entity: entity,
          dynamicCarousel,
          isDynamic: isDynamic ? true : false,
          waTemplate,
          legacy_message,
          activity,
          help_text,
        },
        () => this.setState({ ...loadBotPayload(LANG_STRUCTURE, box.payload, this.state, lang) })
      );
    }
  }

  componentWillUnmount() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  }

  initializeSortable() {
    if (this.listRef.current) {
      this.sortable = new Sortable(this.listRef.current, {
        onEnd: this.handleDrop,
        animation: 200,
        filter: ".non-draggable-item",
      });
    }
  }

  handleDrop(event) {
    this.setState(prevState => {
      const { oldIndex, newIndex } = event;
      const { carouselList } = prevState;
      const updatedList = [...carouselList];
      if (oldIndex < 0 || oldIndex >= updatedList.length || newIndex < 0 || newIndex >= updatedList.length) {
        event.from.insertBefore(event.item, event.from.children[event.oldIndex]);
        return;
      }
      const [removedItem] = updatedList.splice(oldIndex, 1);
      updatedList.splice(newIndex, 0, removedItem);
      return {
        carouselList: updatedList,
        carouselListKey: Date.now(),
      };
    });
  }

  updatePayload(prevLang = this.props.lang, callback = () => {}) {
    const props = {
      boxes: this.props.boxes,
      botVersion: this.props.botVersion,
      isInteractive: this.state.waTemplate,
    };
    const langPayload = updateBotPayload(LANG_STRUCTURE, this.state, prevLang, props);
    this.setState(
      {
        langPayload,
        ...langPayload[this.props.lang.selectedLang],
      },
      callback
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step) {
      this.initializeSortable();
    }
    if (this.props.lang.selectedLang !== prevProps.lang.selectedLang) {
      this.updatePayload(prevProps.lang);
    }
  }

  getEntityList() {
    CommonUtils.getEntityByMerchantId().then(response => {
      if (response.error) {
        this.setState({ entityList: [] });
        return false;
      }
      if (response && response.entities) {
        const list = response.entities.map(e => {
          return {
            entityId: e.entityId,
            entries: e.entries,
            name: e.name,
          };
        });
        let { entity } = this.state;
        if (entity) {
          this.onChangeEntity(
            entity,
            list.find(x => x.entityId === entity)
          );
        }
        this.setState({ entityList: list });
      }
    });
  }

  handleStep(step, isSubmit = false, prevLang) {
    const { isDynamic, dynamicCarousel, carouselList } = this.state;
    const carousel = cloneDeep(carouselList);
    if (isDynamic) {
      const obj = getFormDetails(dynamicCarousel, this.onInputValidate);
      if (!obj) return false;
    } else {
      let isError = false;
      if (carousel.length === 0) return false;
      carousel.map((a, i) => {
        const list = getFormDetails(a, (n, e) => this.onListValidate(n, e, i));
        if (!list) {
          isError = true;
          return false;
        }
      });

      if (isError) {
        return false;
      }
    }

    if (isSubmit) step--;

    this.setState({ step }, () => this.updatePayload(prevLang, isSubmit ? this.handleSubmit : null));
  }
  onListValidate(name, error, index) {
    const { carouselList } = this.state;
    carouselList[index]["errors"][name] = error;
    this.setState({ carouselList });
  }
  onButtonListValidate(name, error, index) {
    const { buttonList } = this.state;
    buttonList[index]["errors"][name] = error;
    this.setState({ buttonList });
  }
  onInputValidate(name, error) {
    //make it generic when other payment methods are added
    let { dynamicCarousel } = this.state;
    dynamicCarousel.errors[name] = error;
    this.setState({ dynamicCarousel });
  }

  handleSubmit() {
    const { handleSubmitElement, boxes, botVersion, lang } = this.props;
    const {
      prevElement,
      intent,
      source,
      style,
      entity,
      isDynamic,
      isRich,
      nextIntent,
      waTemplate,
      legacy_message,
      activity,
      langPayload,
    } = this.state;

    const submitCb = () => {
      const langDataObj = {};
      const langPayloadObj = {};
      let defaultCarousel = [];

      keys(langPayload).map(x => {
        const { text, help_text, carouselList, dynamicCarousel } = langPayload[x];

        langDataObj[x] = {
          text,
          help_text,
          elements: [],
          next_intent: nextIntent,
          waTemplate,
          legacy_message,
          pgLanguage: BOT_ATTRIBUTES.LANGUAGE,
        };

        langPayloadObj[x] = {
          text,
          help_text,
          carouselList: [],
        };

        let carousel = cloneDeep(carouselList);
        if (isDynamic) {
          carousel = [cloneDeep(dynamicCarousel)];
          delete carousel[0].descriptionTarget;
          delete carousel[0].titleTarget;
        }
        carousel.map(c => {
          delete c.descriptionTarget;
          delete c.titleTarget;
          delete c.locationTarget;
          delete c.availabilityTarget;
          delete c.departmentTarget;
          delete c.languagesTarget;
          delete c.timingTarget;
          if (style !== "appointmentCarousel") {
            delete c.location;
            delete c.availability;
            delete c.department;
            delete c.languages;
            delete c.timing;
          }
        });

        if (x === lang.defaultLang) {
          defaultCarousel = carousel;
        }
        carousel.map(c => {
          const obj = {
            image_url: c.image,
            title: c.title,
            subtitle: c.description,
            buttons: [],
            attributes: {},
          };
          const payloadObj = {
            title: c.title,
            description: c.description,
            buttons: [],
            attributes: {},
          };
          if (style === "appointmentCarousel") {
            obj.location = c.location;
            obj.availability = c.availability;
            obj.department = c.department;
            obj.languages = c.languages;
            obj.timing = c.timing;

            payloadObj.location = c.location;
            payloadObj.availability = c.availability;
            payloadObj.department = c.department;
            payloadObj.languages = c.languages;
            payloadObj.timing = c.timing;
          }
          c.buttons.map(b => {
            const btnObj = {
              title: b.title,
              type: b.type,
            };
            const btnPayloadObj = {
              title: b.title,
            };
            if (b.type === "web_url" || b.type === "web_url_self" || b.type === "call") {
              btnObj.url = b.value;
              b.attributes = cloneDeep(initOptions().attributes);
            } else {
              const element = boxes.find(box => box.id === b.value);
              const payload = {
                intent: getBotIntent(element, botVersion),
                displayString: b.title,
              };
              b.attributes.map(a => {
                if (a.attributeKey && a.attributeValue) {
                  payload[a.attributeKey] = a.attributeValue;

                  if (!isArray(btnPayloadObj.attributes)) {
                    btnPayloadObj.attributes = [];
                  }
                  btnPayloadObj.attributes.push({ attributeValue: a.attributeValue });
                }
              });
              btnObj.payload = JSON.stringify(payload);
            }
            obj.buttons.push(btnObj);
            payloadObj.buttons.push(btnPayloadObj);
          });

          isArray(c.carouselAttributes) &&
            c.carouselAttributes.map(p => {
              obj.attributes[p.attributeKey] = p.attributeValue;
              payloadObj.attributes[p.attributeKey] = p.attributeValue;
            });

          langDataObj[x].elements.push(obj);
          if (isDynamic) {
            langPayloadObj[x].dynamicCarousel = payloadObj;
          } else {
            langPayloadObj[x].carouselList.push(payloadObj);
          }
        });
      });

      return { langDataObj, langPayloadObj, defaultCarousel };
    };

    const { langDataObj, langPayloadObj, defaultCarousel } = submitCb();
    const { text, help_text } = langPayloadObj[lang.defaultLang];
    const payload = {
      carousel: defaultCarousel,
      text,
      help_text,
      intent,
      nextIntent,
      slideIndex: 0,
      carouselBox: true,
      isRich,
      style,
      source,
      entity: isDynamic ? entity : null,
      isDynamic,
      waTemplate,
      legacy_message,
      activity,
      langPayload: langPayloadObj,
    };

    handleSubmitElement(langDataObj[lang.defaultLang], prevElement, payload, langDataObj);
  }

  //#region onChange element

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onChangeEntity(value, obj) {
    const stateObj = {
      entity: value,
      externalAttributes: [],
    };
    if (obj && isArray(obj.entries)) {
      stateObj.externalAttributes = Object.keys(obj.entries[0]).map(x => ({
        label: `entity.${x}`,
        value: `{{entity_${value}.${x}}}`,
      }));
    }
    this.setState(stateObj);
  }

  //#endregion

  //#region attribute events

  addAttribute(btnIndex) {
    const list = cloneDeep(this.state.buttonList);
    list[btnIndex].attributes.push({ attributeKey: "", attributeValue: "" });
    this.setState({ buttonList: list });
  }

  removeAttribute(attrIndex, btnIndex) {
    const list = cloneDeep(this.state.buttonList);
    list[btnIndex].attributes.splice(attrIndex, 1);
    this.setState({ buttonList: list });
  }

  onChangeAttribute(name, value, attrIndex, buttonIndex) {
    const list = cloneDeep(this.state.buttonList);
    list[buttonIndex].attributes[attrIndex][name] = value;
    this.setState({ buttonList: list });
  }

  //#endregion

  //#region carousel change event

  addCarouselAttri() {
    const list = cloneDeep(this.state.carouselAttriList);
    list.push({ attributeKey: "", attributeValue: "" });
    this.setState({ carouselAttriList: list });
  }

  removeCarouselAttri(index) {
    const list = cloneDeep(this.state.carouselAttriList);
    list.splice(index, 1);
    this.setState({ carouselAttriList: list });
  }

  onChangeCarouselAttri(name, value, index) {
    const list = cloneDeep(this.state.carouselAttriList);
    list[index][name] = value;
    this.setState({ carouselAttriList: list });
  }

  onDynamicCarouselChange(name, value, e, isValid) {
    const carousel = { ...this.state.dynamicCarousel, [name]: value };
    if (isValid) {
      carousel.errors[name] = null;
    }
    if (e) {
      carousel[`${name}Target`] = e.target;
    }
    if (name === "image" && !value) {
      delete carousel.imageObj;
      carousel.image = "";
    }
    this.setState({ dynamicCarousel: carousel });
    if (name === "image" && value && (!value.startsWith("{") || !value.startsWith("$"))) {
      const image = new Image();
      image.src = value;
      image.onload = () => {
        carousel.imageObj = image;
        this.setState({ dynamicCarousel: carousel });
      };
    }
    if (name === "buttons") {
      this.setState({
        subScreen: true,
        buttonList: value.length ? [...value] : [cloneDeep(initOptions())],
      });
    }
    if (name === "carouselAttributes") {
      this.setState({
        subScreenAttri: true,
        carouselAttriList: isArray(value) ? [...value] : cloneDeep(initAttributes()),
      });
    }
  }

  removeFeature(listName, index) {
    let list = cloneDeep(this.state[listName]);
    list.splice(index, 1);
    this.setState({ [listName]: list, carouselListKey: Date.now() }, () => {
      if (listName === "carouselList") {
        this.updatePayload();
      }
    });
  }

  onChangeFeature(name, value, index, listName) {
    const list = cloneDeep(this.state[listName]);
    list[index][name] = value;
    if (name === "image" && !value) {
      delete list[index]["imageObj"];
    }
    this.setState({ [listName]: list });
    if (name === "image" && value) {
      const image = new Image();
      image.src = value;
      image.onload = () => {
        list[index]["imageObj"] = image;
        this.setState({ [listName]: list });
      };
    }

    if (name === "buttons") {
      this.setState({
        subScreen: true,
        listIndex: index,
        buttonList: value.length
          ? [...value]
          : [{ ...cloneDeep(initOptions()), title: this.state.style === "botMenu" ? list[index].title : "" }],
      });
    }
    if (name === "carouselAttributes") {
      this.setState({
        subScreenAttri: true,
        carouselAttriListIndex: index,
        carouselAttriList: isArray(value) ? [...value] : cloneDeep(initAttributes()),
      });
    }
  }

  onAdvanceOptionChange(name, value, err, obj, e) {
    if (["waTemplate", "legacy_message", "activity"].includes(name)) {
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: value });
    }
  }

  addButtonOrCarousel() {
    const { carouselList, buttonList, subScreen, carouselAttriList, subScreenAttri } = this.state;
    if (subScreen) {
      buttonList.push(cloneDeep(initOptions()));
    } else if (subScreenAttri) {
      carouselAttriList.push(cloneDeep(initAttributes()));
    } else {
      carouselList.push({
        id: getRandomNumber(6),
        image: null,
        title: "",
        description: "",
        buttons: [],
        carouselAttributes: [],
        errors: {
          title: null,
          description: null,
        },
      });
    }
    this.setState({ carouselList, carouselListKey: Date.now(), buttonList, carouselAttriList });
  }

  handleSaveCancel(save, isButton) {
    const {
      isDynamic,
      carouselList,
      dynamicCarousel,
      listIndex,
      buttonList,
      carouselAttriList,
      carouselAttriListIndex,
    } = this.state;
    if (save) {
      let buttons = cloneDeep(buttonList);
      buttons = buttons.filter(x => x.title || x.value);
      let isError = false;
      if (isButton && buttons.length > 0) {
        buttons.map((a, i) => {
          const list = getFormDetails(a, (n, e) => this.onButtonListValidate(n, e, i));
          if (!list) {
            isError = true;
            return false;
          }
        });
      }

      if (isError) {
        return false;
      }
      const filteredCarouselAttriList = carouselAttriList.filter(attr => attr.attributeKey !== "");
      if (isDynamic) {
        if (isButton) {
          dynamicCarousel.buttons = cloneDeep(buttons);
        } else {
          dynamicCarousel.carouselAttributes = cloneDeep(filteredCarouselAttriList);
        }
      } else {
        if (isButton) {
          carouselList[listIndex].buttons = cloneDeep(buttons);
        } else {
          carouselList[carouselAttriListIndex].carouselAttributes = cloneDeep(filteredCarouselAttriList);
        }
      }
    }
    this.setState({
      subScreen: false,
      subScreenAttri: false,
      buttonList: [],
      filteredCarouselAttriList: [],
      carouselList,
      dynamicCarousel,
    });
  }

  //#endregion

  //#region render elements

  addButton() {
    const { buttonList, carouselList, listIndex, isDynamic, externalAttributes, prevElementStructure, style } =
      this.state;
    const { boxes, botElementId, attributeList, lang } = this.props;
    return (
      <div className="ele-content">
        <label className="name">
          Add Buttons {!isDynamic && carouselList[listIndex].title && `(${carouselList[listIndex].title})`}
        </label>
        {buttonList &&
          buttonList.map((obj, index) => {
            if (!obj.errors) {
              obj.errors = {
                title: null,
              };
            }
            return (
              <div key={index}>
                <Row>
                  <Col sm={3}>
                    <div className="position-relative">
                      <Input
                        name="title"
                        title="Title"
                        isReq={true}
                        placeholder="Enter Title"
                        className="form-control pr35"
                        value={obj.title}
                        error={obj.errors.title}
                        appendIcon={
                          style !== "botMenu" && (
                            <SelectAttribute
                              attributeList={attributeList}
                              externalAttributes={[...externalAttributes, ...prevElementStructure]}
                              onChangeFunc={value => this.onChangeFeature("title", value, index, "buttonList")}
                              setAttribute={[null, obj.title]}
                            />
                          )
                        }
                        validationFunc={(n, e) => this.onButtonListValidate(n, e, index)}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                      />
                    </div>
                  </Col>
                  <Col className={lang.className} sm={4}>
                    <Select
                      outerWidth={0}
                      outerClassName=""
                      name="type"
                      placeholder="Select Action"
                      value={obj.type}
                      options={actionList}
                      smallSize
                      height={35}
                      onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                    />
                  </Col>
                  {obj.type === "postback" && (
                    <Col className={lang.className} sm={3}>
                      <SelectElement
                        outerWidth={0}
                        smallSize
                        height={35}
                        outerClassName=""
                        value={obj.value}
                        botElementId={botElementId}
                        boxes={boxes}
                        connectors={buttonList}
                        name="value"
                        placeholder="Select Node"
                        defaultSelected={false}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                      />
                    </Col>
                  )}
                  {(obj.type === "web_url" || obj.type === "web_url_self") && (
                    <Col className={lang.className} sm={3}>
                      <Input
                        name="value"
                        title="Enter url"
                        placeholder="Enter Url"
                        style={{ minWidth: "80px" }}
                        className="form-control pr35"
                        appendIcon={
                          <SelectAttribute
                            attributeList={attributeList}
                            externalAttributes={[...externalAttributes, ...prevElementStructure]}
                            onChangeFunc={value => this.onChangeFeature("value", value, index, "buttonList")}
                            setAttribute={[null, obj.value]}
                          />
                        }
                        value={obj.value}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                      />
                    </Col>
                  )}
                  {obj.type === "call" && (
                    <Col className={lang.className} sm={3}>
                      <Input
                        name="value"
                        title="Phone Number"
                        placeholder="Enter Phone Number"
                        style={{ minWidth: "80px" }}
                        isReq={true}
                        className="form-control pr35"
                        appendIcon={
                          <SelectAttribute
                            attributeList={attributeList}
                            externalAttributes={[...externalAttributes, ...prevElementStructure]}
                            onChangeFunc={value => this.onChangeFeature("value", value, index, "buttonList")}
                            setAttribute={[null, obj.value]}
                          />
                        }
                        error={obj.errors.value}
                        value={obj.value}
                        validationFunc={(n, e) => this.onButtonListValidate(n, e, index)}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "buttonList")}
                      />
                    </Col>
                  )}
                  {style !== "botMenu" && (
                    <Col sm={2}>
                      <div className={`btn-box mb5 ${lang.className}`} style={{ width: "60px" }}>
                        {index === buttonList.length - 1 ? (
                          <Button plusBtn className=" mr10" onClickFunc={() => this.addButtonOrCarousel()} />
                        ) : null}
                        {index !== 0 || buttonList.length > 1 ? (
                          <Button minusBtn onClickFunc={() => this.removeFeature("buttonList", index)} />
                        ) : null}
                      </div>
                    </Col>
                  )}
                </Row>
                {!(obj.type === "call" || obj.type === "web_url" || obj.type === "web_url_self") &&
                  obj.attributes.map((attr, attrIndex) => {
                    return (
                      <Row key={attrIndex}>
                        <Col sm={3} className="text-right">
                          <label className="name mt5">Set Attribute</label>
                        </Col>
                        <Col className={lang.className} sm={4}>
                          <Input
                            name="attributeKey"
                            placeholder="Enter Key"
                            value={attr.attributeKey}
                            onChangeFunc={(name, value) => this.onChangeAttribute(name, value, attrIndex, index)}
                          />
                        </Col>
                        <Col sm={3}>
                          <Input
                            name="attributeValue"
                            placeholder="Enter Value"
                            className="form-control pr35"
                            appendIcon={
                              <SelectAttribute
                                attributeList={attributeList}
                                externalAttributes={[...externalAttributes, ...prevElementStructure]}
                                onChangeFunc={value =>
                                  this.onChangeAttribute("attributeValue", value, attrIndex, index)
                                }
                                setAttribute={[null, attr.attributeValue]}
                              />
                            }
                            value={attr.attributeValue}
                            onChangeFunc={(name, value) => this.onChangeAttribute(name, value, attrIndex, index)}
                          />
                        </Col>
                        <Col className={lang.className} sm={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {attrIndex === obj.attributes.length - 1 ? (
                              <Button plusBtn className=" mr10" onClickFunc={() => this.addAttribute(index)} />
                            ) : null}
                            {attrIndex !== 0 || obj.attributes.length > 1 ? (
                              <Button minusBtn onClickFunc={() => this.removeAttribute(attrIndex, index)} />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            );
          })}
        <Button text="Save" submitBtn className="mr10" onClickFunc={() => this.handleSaveCancel(true, true)} />
        <Button text="Cancel" cancelBtn onClickFunc={() => this.handleSaveCancel(false, true)} />
      </div>
    );
  }

  renderCarouselAttri() {
    const {
      carouselAttriList,
      carouselList,
      carouselAttriListIndex,
      externalAttributes,
      prevElementStructure,
      source,
    } = this.state;
    const { boxes, botElementId, attributeList, lang } = this.props;
    return (
      <div className="ele-content">
        <label className="name">
          Add Attributes
          {source !== "dynamic-entity" &&
            carouselList[carouselAttriListIndex].title &&
            ` (${carouselList[carouselAttriListIndex].title})`}
        </label>
        {carouselAttriList &&
          carouselAttriList.map((obj, index) => {
            if (!obj.errors) {
              obj.errors = {
                title: null,
              };
            }
            return (
              <Row key={index}>
                <Col className={lang.className} sm={4}>
                  <Input
                    name="attributeKey"
                    placeholder="Enter Key"
                    value={obj.attributeKey}
                    onChangeFunc={(name, value) => this.onChangeCarouselAttri(name, value, index)}
                  />
                </Col>
                <Col sm={3}>
                  <Input
                    name="attributeValue"
                    placeholder="Enter Value"
                    className="form-control pr35"
                    appendIcon={
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onChangeCarouselAttri("attributeValue", value, index)}
                        setAttribute={[null, obj.attributeValue]}
                      />
                    }
                    value={obj.attributeValue}
                    onChangeFunc={(name, value) => this.onChangeCarouselAttri(name, value, index)}
                  />
                </Col>
                <Col className={lang.className} sm={2}>
                  <div className="btn-box mb5" style={{ width: "60px" }}>
                    {index === carouselAttriList.length - 1 ? (
                      <Button plusBtn className=" mr10" onClickFunc={() => this.addCarouselAttri()} />
                    ) : null}
                    {index !== 0 || carouselAttriList.length > 1 ? (
                      <Button minusBtn onClickFunc={() => this.removeCarouselAttri(index)} />
                    ) : null}
                  </div>
                </Col>
              </Row>
            );
          })}

        <Button text="Save" submitBtn className="mr10" onClickFunc={() => this.handleSaveCancel(true)} />
        <Button text="Cancel" cancelBtn onClickFunc={() => this.handleSaveCancel(false)} />
      </div>
    );
  }

  renderError(carousel) {
    const { errors } = carousel;
    if (errors) {
      if (errors.title && errors.description) return <span className="req-msg">Please enter Details.</span>;
      if (errors.title) return <span className="req-msg">Please enter Title.</span>;
      if (errors.description) return <span className="req-msg">Please enter Description.</span>;
      if (!errors.title && !errors.description) return "";
    }
  }

  getAttributesLength(attr) {
    if (!isArray(attr)) return 0;
    return attr.filter(x => x.attributeKey).length;
  }

  renderStaticCard() {
    const { carouselList, carouselListKey, style } = this.state;
    const { isSmallMenu, lang } = this.props;
    return (
      <React.Fragment>
        <div ref={this.listRef} className="row draggable">
          {carouselList.map((obj, index) => {
            return (
              <Col md={isSmallMenu ? 3 : 4} key={index + carouselListKey} className="mb10">
                <div className="c-box">
                  <span
                    className={`c-close ${lang.className}`}
                    onClick={() => this.removeFeature("carouselList", index)}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                  <ImageUpload
                    imagePath={obj.image}
                    defaultImg=""
                    isSmall={true}
                    outerClassName={`slider-img-box ${lang.className}`}
                    getImagePath={value => this.onChangeFeature("image", value, index, "carouselList")}
                  />
                  <Input
                    name="title"
                    placeholder="Enter Title"
                    value={obj.title}
                    title="Title"
                    isReq={true}
                    className="textBox"
                    outerClassName="mb0"
                    validationFunc={(n, e) => this.onListValidate(n, e, index)}
                    onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                  />
                  {style === "appointmentCarousel" ? (
                    <React.Fragment>
                      <Input
                        name="description"
                        placeholder="Enter Description"
                        value={obj.description}
                        isReq={true}
                        className="textBox"
                        outerClassName="mb0"
                        validationFunc={(n, e) => this.onListValidate(n, e, index)}
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                      />
                      <Input
                        name="location"
                        placeholder="Enter Location"
                        value={obj.location}
                        className="textBox"
                        outerClassName="mb0"
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                      />
                      <Input
                        name="availability"
                        placeholder="Enter Availability"
                        value={obj.availability}
                        className="textBox"
                        outerClassName="mb0"
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                      />
                      <Input
                        name="department"
                        placeholder="Enter Department"
                        value={obj.department}
                        className="textBox"
                        outerClassName="mb0"
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                      />
                      <Input
                        name="languages"
                        placeholder="Enter Languages"
                        value={obj.languages}
                        className="textBox"
                        outerClassName="mb0"
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                      />
                      <Input
                        name="timing"
                        placeholder="Enter Timing"
                        value={obj.timing}
                        className="textBox"
                        outerClassName="mb0"
                        onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                      />
                    </React.Fragment>
                  ) : (
                    <TextArea
                      rows={4}
                      placeholder="Enter Description"
                      name="description"
                      isReq={true}
                      outerClassName="mb0"
                      className="textBox"
                      value={obj.description}
                      validationFunc={(n, e) => this.onListValidate(n, e, index)}
                      onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "carouselList")}
                    />
                  )}
                  <Button
                    className="cb-submit"
                    text={
                      <>
                        Add Attributes
                        {isArray(carouselList[index].carouselAttributes) && (
                          <span className="badge badge-yellow badge-pill ml-1">
                            {this.getAttributesLength(carouselList[index].carouselAttributes)}
                          </span>
                        )}
                      </>
                    }
                    name="carouselAttributes"
                    onClickFunc={() =>
                      this.onChangeFeature(
                        "carouselAttributes",
                        carouselList[index].carouselAttributes,
                        index,
                        "carouselList"
                      )
                    }
                  />
                  <Button
                    className="cb-submit"
                    text={
                      <>
                        Add Buttons
                        {isArray(carouselList[index].buttons) && (
                          <span className="badge badge-yellow badge-pill ml-1">
                            {carouselList[index].buttons.length}
                          </span>
                        )}
                      </>
                    }
                    name="buttons"
                    onClickFunc={() =>
                      this.onChangeFeature("buttons", carouselList[index].buttons, index, "carouselList")
                    }
                  />
                </div>
                {this.renderError(obj)}
              </Col>
            );
          })}

          <Col md={isSmallMenu ? 3 : 4} className={`mb10 non-draggable-item ${lang.className}`}>
            <div className="c-box">
              <Button className="plus" text="+" onClickFunc={() => this.addButtonOrCarousel()} />
            </div>
          </Col>
        </div>
      </React.Fragment>
    );
  }

  renderDynamicEntity() {
    const { entityList, entity, dynamicCarousel, externalAttributes, style, prevElementStructure } = this.state;
    const { attributeList } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <div className="c-box">
              <div className="position-relative">
                {dynamicCarousel.image &&
                (dynamicCarousel.image.startsWith("{") || dynamicCarousel.image.startsWith("$")) ? (
                  <React.Fragment>
                    <Input
                      name="image"
                      placeholder="Enter Image"
                      value={dynamicCarousel.image}
                      className="textBox pr35 img-box align-items-center d-flex justify-content-center text-center slider-img-box"
                      outerClassName="mb0"
                      onChangeFunc={(name, value) => this.onDynamicCarouselChange(name, value)}
                    />
                    <Button
                      customIcon="fa-minus-circle"
                      className="select-attr-btn"
                      style={{ color: "#666" }}
                      onClickFunc={() => this.onDynamicCarouselChange("image", "")}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ImageUpload
                      imagePath={dynamicCarousel.image}
                      defaultImg=""
                      isSmall={true}
                      outerClassName="slider-img-box"
                      getImagePath={value => this.onDynamicCarouselChange("image", value)}
                    />
                    {style !== "botMenu" && !dynamicCarousel.image && (
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onDynamicCarouselChange("image", value)}
                        setAttribute={[null, dynamicCarousel.image]}
                      />
                    )}
                  </React.Fragment>
                )}
              </div>

              <div className="position-relative">
                <Input
                  name="title"
                  title="Title"
                  placeholder="Enter Title"
                  className="textBox pr35"
                  outerClassName="mb0"
                  isReq={true}
                  value={dynamicCarousel.title}
                  appendIcon={
                    style !== "botMenu" && (
                      <SelectAttribute
                        attributeList={attributeList}
                        externalAttributes={[...externalAttributes, ...prevElementStructure]}
                        onChangeFunc={value => this.onDynamicCarouselChange("title", value, null, true)}
                        setAttribute={[dynamicCarousel.titleTarget, dynamicCarousel.title]}
                      />
                    )
                  }
                  validationFunc={this.onInputValidate}
                  onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                  onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                />
              </div>

              {style === "appointmentCarousel" ? (
                <React.Fragment>
                  <div className="position-relative">
                    <Input
                      name="description"
                      placeholder="Enter Description"
                      value={dynamicCarousel.description}
                      className="textBox pr35"
                      outerClassName="mb0"
                      isReq={true}
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...externalAttributes, ...prevElementStructure]}
                          onChangeFunc={value => this.onDynamicCarouselChange("description", value, null, true)}
                          setAttribute={[dynamicCarousel.descriptionTarget, dynamicCarousel.description]}
                        />
                      }
                      validationFunc={this.onInputValidate}
                      onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                      onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                    />
                  </div>

                  <div className="position-relative">
                    <Input
                      name="location"
                      placeholder="Enter Location"
                      value={dynamicCarousel.location}
                      className="textBox pr35"
                      outerClassName="mb0"
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...externalAttributes, ...prevElementStructure]}
                          onChangeFunc={value => this.onDynamicCarouselChange("location", value)}
                          setAttribute={[dynamicCarousel.locationTarget, dynamicCarousel.location]}
                        />
                      }
                      onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                      onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                    />
                  </div>

                  <div className="position-relative">
                    <Input
                      name="availability"
                      placeholder="Enter Availability"
                      value={dynamicCarousel.availability}
                      className="textBox pr35"
                      outerClassName="mb0"
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...externalAttributes, ...prevElementStructure]}
                          onChangeFunc={value => this.onDynamicCarouselChange("availability", value)}
                          setAttribute={[dynamicCarousel.availabilityTarget, dynamicCarousel.availability]}
                        />
                      }
                      onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                      onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                    />
                  </div>

                  <div className="position-relative">
                    <Input
                      name="department"
                      placeholder="Enter Department"
                      value={dynamicCarousel.department}
                      className="textBox pr35"
                      outerClassName="mb0"
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...externalAttributes, ...prevElementStructure]}
                          onChangeFunc={value => this.onDynamicCarouselChange("department", value)}
                          setAttribute={[dynamicCarousel.departmentTarget, dynamicCarousel.department]}
                        />
                      }
                      onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                      onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                    />
                  </div>

                  <div className="position-relative">
                    <Input
                      name="languages"
                      placeholder="Enter Languages"
                      value={dynamicCarousel.languages}
                      className="textBox pr35"
                      outerClassName="mb0"
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...externalAttributes, ...prevElementStructure]}
                          onChangeFunc={value => this.onDynamicCarouselChange("languages", value)}
                          setAttribute={[dynamicCarousel.languagesTarget, dynamicCarousel.languages]}
                        />
                      }
                      onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                      onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                    />
                  </div>

                  <div className="position-relative">
                    <Input
                      name="timing"
                      placeholder="Enter Timing"
                      value={dynamicCarousel.timing}
                      className="textBox pr35"
                      outerClassName="mb0"
                      appendIcon={
                        <SelectAttribute
                          attributeList={attributeList}
                          externalAttributes={[...externalAttributes, ...prevElementStructure]}
                          onChangeFunc={value => this.onDynamicCarouselChange("timing", value)}
                          setAttribute={[dynamicCarousel.timingTarget, dynamicCarousel.timing]}
                        />
                      }
                      onClickFunc={e => this.onDynamicCarouselChange(e.target.name, e.target.value, e)}
                      onChangeFunc={(name, value, err, obj, e) => this.onDynamicCarouselChange(name, value, e)}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div className="position-relative">
                  <TextArea
                    value={dynamicCarousel.description}
                    placeholder="Enter Description"
                    rows={4}
                    name="description"
                    outerClassName="mb0"
                    isReq={true}
                    appendIcon={
                      style !== "botMenu" && (
                        <SelectAttribute
                          outerAttriClass="text-area-icon"
                          attributeList={attributeList}
                          externalAttributes={[...externalAttributes, ...prevElementStructure]}
                          onChangeFunc={value => this.onDynamicCarouselChange("description", value, null, true)}
                          setAttribute={[dynamicCarousel.descriptionTarget, dynamicCarousel.description]}
                        />
                      )
                    }
                    className="textBox"
                    validationFunc={this.onInputValidate}
                    onChangeFunc={(name, value, e) => this.onDynamicCarouselChange(name, value, e)}
                  />
                </div>
              )}
              <Button
                className="cb-submit"
                text={
                  <>
                    Add Attributes
                    {isArray(dynamicCarousel.carouselAttributes) && (
                      <span className="badge badge-yellow badge-pill ml-1">
                        {this.getAttributesLength(dynamicCarousel.carouselAttributes)}
                      </span>
                    )}
                  </>
                }
                name="carouselAttributes"
                onClickFunc={() =>
                  this.onDynamicCarouselChange("carouselAttributes", dynamicCarousel.carouselAttributes)
                }
              />

              <Button
                className="cb-submit"
                text={
                  <>
                    Add Buttons
                    {isArray(dynamicCarousel.buttons) && (
                      <span className="badge badge-yellow badge-pill ml-1">{dynamicCarousel.buttons.length}</span>
                    )}
                  </>
                }
                name="buttons"
                onClickFunc={() => this.onDynamicCarouselChange("buttons", dynamicCarousel.buttons)}
              />
            </div>
            {this.renderError(dynamicCarousel)}
          </Col>
          {style !== "botMenu" && (
            <Col md={8}>
              <Row>
                <Col md={6}></Col>
                <Col md={6}>
                  <label>Entity</label>
                  <Select
                    options={entityList}
                    name="entity"
                    value={entity}
                    labelKey="name"
                    valueKey="entityId"
                    onChangeFunc={(name, value, error, obj) => this.onChangeEntity(value, obj)}
                  />
                </Col>
              </Row>
              {/* <div>Entity list</div> */}
            </Col>
          )}
        </Row>
      </React.Fragment>
    );
  }

  //#endregion

  //#region render steps

  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId, lang } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
          lang={lang}
        />
      </div>
    );
  }

  renderStep1() {
    const {
      text,
      textTarget,
      source,
      prevElement,
      subScreen,
      subScreenAttri,
      style,
      externalAttributes,
      prevElementStructure,
      isRich,
      waTemplate,
      legacy_message,
      activity,
      help_text,
    } = this.state;
    const { boxes, botElementId, connectors, attributeList, lang } = this.props;

    if (subScreen) return this.addButton();
    if (subScreenAttri) return this.renderCarouselAttri();
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm title="Message">
            <div className="position-relative">
              {isRich ? (
                <TextEditor
                  title="Message"
                  name="text"
                  value={text}
                  appendIcon={
                    <SelectAttribute
                      attributeList={attributeList}
                      externalAttributes={[...externalAttributes, ...prevElementStructure]}
                      onChangeFunc={value => this.setState({ text: value })}
                      setAttribute={[textTarget, text]}
                    />
                  }
                  onChangeFunc={(name, value) => this.setState({ [name]: value })}
                />
              ) : (
                <Input
                  name="text"
                  placeholder="Enter message"
                  outerClassName="mb0"
                  appendIcon={
                    <SelectAttribute
                      attributeList={attributeList}
                      externalAttributes={[...externalAttributes, ...prevElementStructure]}
                      onChangeFunc={value => this.setState({ text: value })}
                      setAttribute={[textTarget, text]}
                    />
                  }
                  value={text}
                  onChangeFunc={(name, value, err, obj, e) => this.setState({ [name]: value, textTarget: e.target })}
                />
              )}
            </div>
            <label className={`mb0 ${lang.className}`} style={{ lineHeight: "33px" }}>
              Enable rich text support
              <Input
                type="checkbox"
                name="isRich"
                outerClassName="float-left mr10 mb0"
                style={{ width: 20 }}
                checked={isRich}
                onChangeFunc={() => this.setState(prevState => ({ isRich: !prevState.isRich }))}
              />
            </label>
          </ElementForm>
        </div>
        <Row className={`mb10 ${lang.className}`}>
          {style !== "botMenu" && (
            <Col md={4}>
              <label>Source</label>
              <Select
                options={this.sourceList}
                name="source"
                value={source}
                isClearable={false}
                onChangeFunc={(name, value) => this.setState({ [name]: value, isDynamic: value === "dynamic-entity" })}
              />
            </Col>
          )}
          <Col md={4}>
            <label>Previous Element</label>
            <SelectElement
              value={prevElement}
              botElementId={botElementId}
              boxes={boxes}
              connectors={connectors}
              outerWidth={0}
              outerClassName=""
              onChangeFunc={this.onChangePrevElement}
            />
          </Col>
          <Col md={4}>
            <label>Style</label>
            <Select
              options={styleList}
              name="style"
              value={style}
              isClearable={false}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </Col>
        </Row>
        {source === "static-card" ? this.renderStaticCard() : this.renderDynamicEntity()}

        <div className="b-form">
          <AdvanceOptionsExpand
            help_text={help_text}
            waTemplate={waTemplate}
            legacy_message={legacy_message}
            activity={activity}
            onChangeFunc={this.onAdvanceOptionChange}
            displayList={["waTemplate", "legacy_message", "activity", "help_text"]}
          />
        </div>
      </div>
    );
  }

  //#endregion

  render() {
    const { step, subScreen, subScreenAttri } = this.state;
    const { resetElement, botElementId, lang } = this.props;
    return (
      <React.Fragment>
        <ScrollBox key={step + subScreen + subScreenAttri} scrollClass="bt-send-carousel" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={() => this.updatePayload(lang, this.handleSubmit)}
          step={step}
          botElementId={botElementId}
          handleStep={this.handleStep}
          hideButtons={subScreen || subScreenAttri}
          lang={lang}
        />
      </React.Fragment>
    );
  }
}

export default SendCarousel;
