import React from "react";
import { Input, ScrollBox, Select } from "../../../common/components";
import { getAttrByPrevElement } from "../../../utils/BotUtils";
import { botPaymentMethods } from "../../../utils/GeneralUtils";
import { SelectElement, ElementForm, SubmitElement, SelectAttribute } from "./components";
import { getFormDetails } from "../../../common/components/helper.js";
import { MESSENGER_URL } from "../../../utils/Config";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: null,
      nextIntent: "",
      botPaymentMethods: botPaymentMethods,
      razorpay: {
        orderId: "",
        contactName: "",
        contactEmail: "",
        contactNumber: "",
        total: "",
        errors: {
          contactName: null,
          contactEmail: null,
          contactNumber: null,
          total: null,
        },
      },
      stripepay: {
        orderId: "",
        total: "",
        errors: {
          total: null,
        },
      },
      hyperpay: {
        text: "",
        contactNumber: "",
        firstName: "",
        lastName: "",
        email: "",
        street: "",
        city: "",
        state: "",
        country: "",
        postcode: "",
        orderId: "",
        total: "",
        errors: {
          total: null,
        },
      },
      noqoody: {
        text: "",
        orderId: "",
        contactName: "",
        contactEmail: "",
        contactNumber: "",
        total: "",
        errors: {
          contactName: null,
          contactEmail: null,
          contactNumber: null,
          total: null,
        },
      },
      prevElementStructure: [],
      errors: {
        paymentMethod: null,
      },
    };

    this.handleRazorpayChange = this.handleRazorpayChange.bind(this);
    this.handleNoqoodyChange = this.handleNoqoodyChange.bind(this);
    this.handleStripeChange = this.handleStripeChange.bind(this);
    this.handleHyperpayChange = this.handleHyperpayChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
  }

  componentDidMount() {
    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    if (box) {
      const { paymentMethod, nextIntent, razorpay, stripepay, hyperpay, noqoody } = box.payload;
      const stateObj = { paymentMethod: paymentMethod === "razorpay" ? "razorpayv2" : paymentMethod, nextIntent };
      if (razorpay) stateObj.razorpay = { ...razorpay, errors: { ...this.state.razorpay.errors } };
      else if (stripepay) stateObj.stripepay = { ...stripepay, errors: { ...this.state.stripepay.errors } };
      else if (hyperpay) stateObj.hyperpay = { ...hyperpay, errors: { ...this.state.hyperpay.errors } };
      else if (noqoody) stateObj.noqoody = { ...noqoody, errors: { ...this.state.noqoody.errors } };
      this.setState(stateObj);
    }
  }

  onChangePrevElement(name, value) {
    const { boxes, elementType } = this.props;
    this.setState({ [name]: value, prevElementStructure: getAttrByPrevElement(value, boxes, elementType) });
  }

  onInputValidate(name, error) {
    let { razorpay, stripepay, hyperpay, noqoody, errors, paymentMethod } = this.state;
    if (paymentMethod && name !== "paymentMethod") {
      if (paymentMethod === "razorpayv2") {
        razorpay.errors[name] = error;
        this.setState({ razorpay });
      } else if (paymentMethod === "stripepay") {
        stripepay.errors[name] = error;
        this.setState({ stripepay });
      } else if (paymentMethod === "hyperpay") {
        hyperpay.errors[name] = error;
        this.setState({ hyperpay });
      } else if (paymentMethod === "noqoody") {
        noqoody.errors[name] = error;
        this.setState({ noqoody });
      }
    }
    if (name === "paymentMethod") {
      errors[name] = error;
      this.setState({ errors });
    }
  }

  handleSubmit() {
    const { prevElement, paymentMethod, razorpay, stripepay, hyperpay, noqoody, nextIntent } = this.state;
    const { handleSubmitElement } = this.props;

    const obj = getFormDetails({ paymentMethod: paymentMethod, errors: this.state.errors }, this.onInputValidate);
    if (!obj) return false;

    if (paymentMethod === "razorpayv2") {
      const razorpayObj = getFormDetails(razorpay, this.onInputValidate);
      if (!razorpayObj) return false;
    } else if (paymentMethod === "stripepay") {
      const stripepayObj = getFormDetails(stripepay, this.onInputValidate);
      if (!stripepayObj) return false;
    } else if (paymentMethod === "hyperpay") {
      const hyperpayObj = getFormDetails(hyperpay, this.onInputValidate);
      if (!hyperpayObj) return false;
    } else if (paymentMethod === "noqoody") {
      const noqoodyObj = getFormDetails(noqoody, this.onInputValidate);
      if (!noqoodyObj) return false;
    }

    let data = {};

    const payload = {
      paymentMethod,
      nextIntent,
    };

    if (paymentMethod === "razorpayv2") {
      payload.razorpay = razorpay;
      data = {
        input_type: "razorpayv2",
        next_intent: nextIntent,
        request_params: [
          {
            merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
            sessionId: BOT_ATTRIBUTES.SESSION_ID,
            paymentURL: `${MESSENGER_URL}libs/razorpay.html?q=`,
            displayString: "Payment Status:",
            engagementId: BOT_ATTRIBUTES.ENGAGEMENT_ID,
            serviceId: BOT_ATTRIBUTES.SERVICE_ID,
            serviceName: BOT_ATTRIBUTES.SERVICE_NAME,
            contactName: razorpay.contactName,
            contactEmail: razorpay.contactEmail,
            contactNumber: razorpay.contactNumber,
            orderId: razorpay.orderId,
            total: razorpay.total,
          },
        ],
      };
    } else if (paymentMethod === "stripepay") {
      payload.stripepay = stripepay;
      data = {
        input_type: "stripepay",
        next_intent: nextIntent,
        request_params: [
          {
            orderId: stripepay.orderId,
            merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
            sessionId: BOT_ATTRIBUTES.SESSION_ID,
            paymentURL: `${MESSENGER_URL}libs/stripepay.html?q=`,
            displayString: "Payment Status:",
            engagementId: BOT_ATTRIBUTES.ENGAGEMENT_ID,
            serviceId: BOT_ATTRIBUTES.SERVICE_ID,
            serviceName: BOT_ATTRIBUTES.SERVICE_NAME,
            accessToken: BOT_ATTRIBUTES.ACCESS_TOKEN,
            total: stripepay.total,
          },
        ],
      };
    } else if (paymentMethod === "hyperpay") {
      payload.hyperpay = hyperpay;
      data = {
        input_type: "hyperpay",
        next_intent: nextIntent,
        request_params: [
          {
            orderId: hyperpay.orderId,
            merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
            sessionId: BOT_ATTRIBUTES.SESSION_ID,
            paymentURL: `${MESSENGER_URL}libs/hyperpay.html?q=`,
            displayString: "Payment Status:",
            engagementId: BOT_ATTRIBUTES.ENGAGEMENT_ID,
            serviceId: BOT_ATTRIBUTES.SERVICE_ID,
            serviceName: BOT_ATTRIBUTES.SERVICE_NAME,
            accessToken: BOT_ATTRIBUTES.ACCESS_TOKEN,
            firstName: hyperpay.firstName,
            lastName: hyperpay.lastName,
            email: hyperpay.email,
            street: hyperpay.street,
            city: hyperpay.city,
            state: hyperpay.state,
            country: hyperpay.country,
            postcode: hyperpay.postcode,
            contactNumber: hyperpay.contactNumber,
            total: hyperpay.total,
            text: hyperpay.text,
          },
        ],
      };
    } else if (paymentMethod === "noqoody") {
      payload.noqoody = noqoody;
      data = {
        input_type: "noqoody",
        next_intent: nextIntent,
        request_params: [
          {
            merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
            sessionId: BOT_ATTRIBUTES.SESSION_ID,
            paymentURL: `${MESSENGER_URL}libs/noqoodypay.html?q=`,
            displayString: "Payment Status:",
            engagementId: BOT_ATTRIBUTES.ENGAGEMENT_ID,
            serviceId: BOT_ATTRIBUTES.SERVICE_ID,
            serviceName: BOT_ATTRIBUTES.SERVICE_NAME,
            serverName: BOT_ATTRIBUTES.SERVER_NAME,
            text: noqoody.text,
            contactName: noqoody.contactName,
            contactEmail: noqoody.contactEmail,
            contactNumber: noqoody.contactNumber,
            orderId: noqoody.orderId,
            total: noqoody.total,
          },
        ],
      };
    }
    handleSubmitElement(data, prevElement, payload);
  }

  handleRazorpayChange(name, value, isValid) {
    let { errors } = this.state.razorpay;
    if (isValid) {
      errors[name] = null;
    }
    this.setState(prevState => ({ razorpay: { ...prevState.razorpay, [name]: value, errors } }));
  }

  handleStripeChange(name, value, isValid) {
    let { errors } = this.state.stripepay;
    if (isValid) {
      errors[name] = null;
    }
    this.setState(prevState => ({ stripepay: { ...prevState.stripepay, [name]: value, errors } }));
  }

  handleHyperpayChange(name, value, isValid) {
    let { errors } = this.state.hyperpay;
    if (isValid) {
      errors[name] = null;
    }
    this.setState(prevState => ({ hyperpay: { ...prevState.hyperpay, [name]: value, errors } }));
  }

  handleNoqoodyChange(name, value, isValid) {
    let { errors } = this.state.noqoody;
    if (isValid) {
      errors[name] = null;
    }
    this.setState(prevState => ({ noqoody: { ...prevState.noqoody, [name]: value, errors } }));
  }

  renderRazorpay() {
    const { razorpay, prevElementStructure } = this.state;
    const { attributeList } = this.props;
    return (
      <div className="b-form">
        <ElementForm title="Contact Name">
          <Input
            name="contactName"
            title="Contact Name"
            placeholder="Enter Contact Name"
            outerClassName="mb0"
            isReq={true}
            error={razorpay.errors.contactName}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleRazorpayChange(inputName, value, true);
                }}
                setAttribute={[null, razorpay.contactName]}
              />
            }
            value={razorpay.contactName}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleRazorpayChange}
          />
        </ElementForm>
        <ElementForm title="Contact Email">
          <Input
            name="contactEmail"
            title="Contact Email"
            placeholder="Enter Contact Email"
            outerClassName="mb0"
            isReq={true}
            error={razorpay.errors.contactEmail}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleRazorpayChange(inputName, value, true);
                }}
                setAttribute={[null, razorpay.contactEmail]}
              />
            }
            value={razorpay.contactEmail}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleRazorpayChange}
          />
        </ElementForm>
        <ElementForm title="Contact Number">
          <Input
            name="contactNumber"
            title="Contact Number"
            placeholder="Enter Contact Number"
            outerClassName="mb0"
            isReq={true}
            error={razorpay.errors.contactNumber}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleRazorpayChange(inputName, value, true);
                }}
                setAttribute={[null, razorpay.contactNumber]}
              />
            }
            value={razorpay.contactNumber}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleRazorpayChange}
          />
        </ElementForm>
        <ElementForm title="Total">
          <Input
            name="total"
            title="Total"
            placeholder="Enter Total"
            outerClassName="mb0"
            isReq={true}
            error={razorpay.errors.total}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleRazorpayChange(inputName, value, true);
                }}
                setAttribute={[null, razorpay.total]}
              />
            }
            value={razorpay.total}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleRazorpayChange}
          />
        </ElementForm>
        <ElementForm title="Order Id">
          <Input
            name="orderId"
            placeholder="Enter Order Id"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleRazorpayChange(inputName, value, true);
                }}
                setAttribute={[null, razorpay.orderId]}
              />
            }
            value={razorpay.orderId}
            onChangeFunc={this.handleRazorpayChange}
          />
        </ElementForm>
      </div>
    );
  }
  renderStripe() {
    const { stripepay, prevElementStructure, botPaymentMethods } = this.state;
    const { attributeList } = this.props;
    return (
      <div className="b-form">
        <ElementForm title="Total">
          <Input
            name="total"
            title="Total"
            placeholder="Enter Total"
            outerClassName="mb0"
            isReq={true}
            error={stripepay.errors.total}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleStripeChange(inputName, value, true);
                }}
                setAttribute={[null, stripepay.total]}
              />
            }
            value={stripepay.total}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleStripeChange}
          />
        </ElementForm>
        <ElementForm title="Order Id">
          <Input
            name="orderId"
            placeholder="Enter Order Id"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleStripeChange(inputName, value, true);
                }}
                setAttribute={[null, stripepay.orderId]}
              />
            }
            value={stripepay.orderId}
            onChangeFunc={this.handleStripeChange}
          />
        </ElementForm>
      </div>
    );
  }
  renderHyperpay() {
    const { hyperpay, prevElementStructure } = this.state;
    const { attributeList } = this.props;
    return (
      <div className="b-form">
        <ElementForm title="Text">
          <Input
            name="text"
            title="Text"
            placeholder="Enter Text"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.text]}
              />
            }
            value={hyperpay.text}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="First Name">
          <Input
            name="firstName"
            title="First Name"
            placeholder="Enter First Name"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.firstName]}
              />
            }
            value={hyperpay.firstName}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="Last Name">
          <Input
            name="lastName"
            title="Last Name"
            placeholder="Enter Last Name"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.lastName]}
              />
            }
            value={hyperpay.lastName}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="Email">
          <Input
            name="email"
            title="Email"
            placeholder="Enter Email"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.email]}
              />
            }
            value={hyperpay.email}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="Contact Number">
          <Input
            name="contactNumber"
            title="contactNumber"
            placeholder="Enter Contact Number"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.contactNumber]}
              />
            }
            value={hyperpay.contactNumber}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="Street">
          <Input
            name="street"
            title="Street"
            placeholder="Enter Street"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.street]}
              />
            }
            value={hyperpay.street}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="City">
          <Input
            name="city"
            title="City"
            placeholder="Enter City"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.city]}
              />
            }
            value={hyperpay.city}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="State">
          <Input
            name="state"
            title="State"
            placeholder="Enter State"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.state]}
              />
            }
            value={hyperpay.state}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="Country">
          <Input
            name="country"
            title="Country"
            placeholder="Enter Country"
            outerClassName="mb5"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.country]}
              />
            }
            value={hyperpay.country}
            onChangeFunc={this.handleHyperpayChange}
          />
          <label className="mb0">Allows country in two letter capitals; Eg: US, IN, AU</label>
        </ElementForm>
        <ElementForm title="Postcode">
          <Input
            name="postcode"
            title="Postcode"
            placeholder="Enter Postcode"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.postcode]}
              />
            }
            value={hyperpay.postcode}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="Total">
          <Input
            name="total"
            title="Total"
            placeholder="Enter Total"
            outerClassName="mb0"
            isReq={true}
            error={hyperpay.errors.total}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.total]}
              />
            }
            value={hyperpay.total}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
        <ElementForm title="Order Id">
          <Input
            name="orderId"
            placeholder="Enter Order Id"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleHyperpayChange(inputName, value, true);
                }}
                setAttribute={[null, hyperpay.orderId]}
              />
            }
            value={hyperpay.orderId}
            onChangeFunc={this.handleHyperpayChange}
          />
        </ElementForm>
      </div>
    );
  }
  renderNoqoody() {
    const { noqoody, prevElementStructure } = this.state;
    const { attributeList } = this.props;
    return (
      <div className="b-form">
        <ElementForm title="Text">
          <Input
            name="text"
            title="Text"
            placeholder="Enter Text"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleNoqoodyChange(inputName, value, true);
                }}
                setAttribute={[null, noqoody.text]}
              />
            }
            value={noqoody.text}
            onChangeFunc={this.handleNoqoodyChange}
          />
        </ElementForm>
        <ElementForm title="Contact Name">
          <Input
            name="contactName"
            title="Contact Name"
            placeholder="Enter Contact Name"
            outerClassName="mb0"
            isReq={true}
            error={noqoody.errors.contactName}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleNoqoodyChange(inputName, value, true);
                }}
                setAttribute={[null, noqoody.contactName]}
              />
            }
            value={noqoody.contactName}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleNoqoodyChange}
          />
        </ElementForm>
        <ElementForm title="Contact Email">
          <Input
            name="contactEmail"
            title="Contact Email"
            placeholder="Enter Contact Email"
            outerClassName="mb0"
            isReq={true}
            error={noqoody.errors.contactEmail}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleNoqoodyChange(inputName, value, true);
                }}
                setAttribute={[null, noqoody.contactEmail]}
              />
            }
            value={noqoody.contactEmail}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleNoqoodyChange}
          />
        </ElementForm>
        <ElementForm title="Contact Number">
          <Input
            name="contactNumber"
            title="Contact Number"
            placeholder="Enter Contact Number"
            outerClassName="mb0"
            isReq={true}
            error={noqoody.errors.contactNumber}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleNoqoodyChange(inputName, value, true);
                }}
                setAttribute={[null, noqoody.contactNumber]}
              />
            }
            value={noqoody.contactNumber}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleNoqoodyChange}
          />
        </ElementForm>
        <ElementForm title="Total">
          <Input
            name="total"
            title="Total"
            placeholder="Enter Total"
            outerClassName="mb0"
            isReq={true}
            error={noqoody.errors.total}
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleNoqoodyChange(inputName, value, true);
                }}
                setAttribute={[null, noqoody.total]}
              />
            }
            value={noqoody.total}
            validationFunc={this.onInputValidate}
            onChangeFunc={this.handleNoqoodyChange}
          />
        </ElementForm>
        <ElementForm title="Order Id">
          <Input
            name="orderId"
            placeholder="Enter Order Id"
            outerClassName="mb0"
            appendIcon={
              <SelectAttribute
                attributeList={attributeList}
                externalAttributes={prevElementStructure}
                onChangeFunc={(value, inputName) => {
                  this.handleNoqoodyChange(inputName, value, true);
                }}
                setAttribute={[null, noqoody.orderId]}
              />
            }
            value={noqoody.orderId}
            onChangeFunc={this.handleNoqoodyChange}
          />
        </ElementForm>
      </div>
    );
  }

  renderStep1() {
    const { boxes, botElementId, connectors } = this.props;
    const { prevElement, paymentMethod, errors, botPaymentMethods } = this.state;
    return (
      <div className="ele-content">
        <div className="b-form">
          <ElementForm prevElement>
            <SelectElement
              value={prevElement}
              boxes={boxes}
              botElementId={botElementId}
              connectors={connectors}
              onChangeFunc={this.onChangePrevElement}
            />
          </ElementForm>
          <ElementForm title="Payment Method">
            <Select
              name="paymentMethod"
              title="Payment Method"
              outerClassName="mb0"
              isReq={true}
              error={errors.paymentMethod}
              options={botPaymentMethods}
              value={paymentMethod}
              validationFunc={this.onInputValidate}
              onChangeFunc={(name, value) => this.setState({ [name]: value })}
            />
          </ElementForm>
          {paymentMethod === "razorpayv2" && this.renderRazorpay()}
          {paymentMethod === "stripepay" && this.renderStripe()}
          {paymentMethod === "hyperpay" && this.renderHyperpay()}
          {paymentMethod === "noqoody" && this.renderNoqoody()}
        </div>
      </div>
    );
  }

  render() {
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        <ScrollBox scrollClass="bt-send-image" boxHeight={400}>
          {this.renderStep1()}
        </ScrollBox>
        <SubmitElement
          resetElement={resetElement}
          handleSubmit={this.handleSubmit}
          totalSteps={1}
          botElementId={botElementId}
        />
      </React.Fragment>
    );
  }
}

export default Payment;
